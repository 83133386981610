import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import classes from './logDispense.module.scss';
import { useAppSelector } from 'store';
import ExpandedRowRenderer from './expandedRowRenderer';
import btn_plus from 'assets/images/btn_plus.png';
import btn_minus from 'assets/images/btn_minus.png';

const ConfirmationTable = ({ data,setPrintData }) => {
	const [expandRow, setExpandRow] = useState<any>([]);
	const [tabData, setTabData] = useState<any>([]);

		const ConfirmationColumns: any = [
			{
				title: <input type="checkbox" onClick={(e) => allCheckHandler(e)} />,
				dataIndex: 'checkbox',
				key: 'checkbox',
				render: (_, record) => (
					<input type="checkbox" checked={record.checked} onClick={(e) => onCheckHandler(e, record)} />
				),
				className: 'hideCol',
			},
			{
				title: 'Confirmation Number',
				dataIndex: 'Confirmation',
				key: 'Confirmation',
			},
			{
				title: 'Product Identification',
				dataIndex: 'ProductIdentification',
				key: 'ProductIdentification',
			},
			{
				title: 'Authorization Number',
				dataIndex: 'AuthorizationNumber',
				key: 'AuthorizationNumber',
			},
			{
				title: 'Authorization Status',
				dataIndex: 'AuthorizationStatus',
				key: 'AuthorizationStatus',
			},
			{
				title: 'Confirmation Date',
				dataIndex: 'ConfirmationDate',
				key: 'ConfirmationDate',
			},
			{
				title: 'Action Required',
				dataIndex: 'ActionRequired',
				key: 'ActionRequired',
			},
	];

	useEffect(() => {
		setTabData(data);
	}, []);

	useEffect(() => {
		if (tabData.length > 0) {
			const expandColumn: any = document.querySelector('.ant-table-row-expand-icon-cell');
			expandColumn.innerHTML = 'Patient Name';
			const printRows=tabData.filter((item)=>item.checked).map(x=>x.printRow);
			setPrintData(printRows);
		}
	}, [tabData]);


	const allCheckHandler = (e) => {
		setTabData((prev) => prev.map((item) => ({ ...item, checked: e.target.checked })));
	};

	const onCheckHandler = (e, record) => {
		setTabData((prev) =>
			prev.map((item) => {
				if (item.key === record.key) return { ...item, checked: e.target.checked };
				else return item;
			})
		);
	};

	return (
		tabData.length > 0 && (
			<Table
				size="small"
				className="confirmationTable"
				rowClassName={(record, index) => (index % 2 === 0 ? `table-row-dark` : `table-row-light`)}
				dataSource={tabData}
				columns={ConfirmationColumns}
				expandable={{
					expandedRowRender: ExpandedRowRenderer,
					expandIconColumnIndex: 7,
					expandedRowKeys: expandRow,
					onExpandedRowsChange: setExpandRow,
					expandIcon: ({ expanded, onExpand, record }) =>
						expanded ? (
							<div className={classes.expand} onClick={(e) => onExpand(record, e)}>
								{' '}
								<img className={classes.expand_icon} src={btn_minus} alt="plus_icon" />
								<a className={classes.links}>{record.patientName}</a>
							</div>
						) : (
							<div className={classes.expand} onClick={(e) => onExpand(record, e)}>
								{' '}
								<img className={classes.expand_icon} src={btn_plus} alt="plus_icon" />
								<a className={classes.links}>{record.patientName}</a>
							</div>
						),
				}}
				pagination={false}
			/>
		)
	);
};

export default ConfirmationTable;
