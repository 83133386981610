const payload={
    
  "username": '',
  "password": '',
  "first_name": '',
  "last_name": '',
  "email": '',
  "phone_number": '',
  "address": '',
  "city": '',
  "state": '',
  "zip_code": '',
  "country_code": '',
  "customer_id": '',
  "group_tag": '',
  "language": 'en-US',
  "security_question": '',
  "security_answer": '',
  "system_Id": '',
  "prescriber_user_id": ''
}

const formatPhoneNumber = (input) => {
  if(input){
    const numbers = input.toLowerCase().split('x')[0];
    return numbers;
  }

}

export const getUserPayload=(data)=>{

   payload.first_name=data.firstName || '';
   payload.last_name=data.lastName || '';
   payload.address=data.officeAddress || '';
   payload.city=data.city || '';
   payload.state=data.state || '';
   payload.zip_code=data.zipCode || '';
   payload.phone_number=formatPhoneNumber(data.phoneNumber) || '';
   payload.username=data.userName || '';
   payload.password=data.password || '';
   payload.email=data.email || '';
   payload.security_question=data.securityQuestion || '';
   payload.security_answer=data.securityAnswer || '';
   payload.group_tag=data.role || '';
   payload.prescriber_user_id = data.prescriberId || '';
   payload.system_Id=data.accessCode || '';
   return payload;
}