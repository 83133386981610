import React from 'react';
import PageBackground from 'components/PageBackground';
import classes from './manageAccount.module.scss'
import SessionTimeout from 'components/SessionTimeout';
import Success from 'components/Success';
import { useNavigate } from 'react-router-dom';

export const ManageAccountConfirmation = () => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate('/pharmacy/PharmacyHome', { replace: true });
    };
    return (
        <>
            <SessionTimeout />
            <Success label="Default Location Confirmation" btn={true} handleClick={handleContinue}>
                <div className={classes.confirmationDiv}>
                    <p className={classes.confirmationMsg}>
                        Your Default Location has been changed. Press the Continue button below to return to the Home screen.
                    </p>
                </div>
            </Success>
        </>
    )
}
