import Button from 'components/Button';
import Input from 'components/Input';
import SessionTimeout from 'components/SessionTimeout';
import { axiosInstance, axiosInstance_PDF } from 'constants/Config/axiosConfig';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { patientInformation, patientProgramList, setPPAFContent } from 'store/slices/appSlice';
import { getPatientDetailsEditPatient } from 'utils/getPatientDetailsEditPatient';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import classes from './PatientReactivation.module.scss';
import { ProgramConfig } from 'constants/Config/programsConfig';

// import Revise from './../../assets/images/btn_Revise.png';
// import Save from './../../assets/images/btn_Continue_Save.png';

const PrescriberPatientSearch = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();
    const [existingRC, setExistingRC] = useState(null);
    const [isPending, setIspending] = useState(false);
    const { patientSearchStore } = useAppSelector((state) => state.app);
    const { patientReactivationStore, patientCamundaStore } = useAppSelector((state) => state.app);

    const { selectedProgram } = useAppSelector((state) => state.app);

    const selectedLanguage = i18n.language;

    const product = selectedProgram.id;
    const mi = `${patientReactivationStore?.mi}`;
    const patientenrollstatus = patientCamundaStore?.patientPrescribers[0]?.status;
    const dob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`;
    const Patientdob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`.replace("00:00:00","");

  

    const handleRevise = () => {
        const prevRoute = sessionStorage.getItem('prevRoute')||'/prescriber/patientsearch'
        navigate(prevRoute);
    };

    const handlePPAFOnlineBtn = async (data) => {
        try {
            const response = await axiosInstance_PDF.get('/file-render/pdf/ppaf/consent-statements', {
                params: {
                    product: data.productType[0],
                    riskCategory: data.patientRiskCategory[0].riskCategoryName,
                    language: i18n.language,
                },
            });
            if (response.data.statusCode === 200) {
                const { signedByFirstName, signedByLastName, patientRelation, modifiedDate } =
                    patientCamundaStore.ppafSignSecurity;
                dispatch(setPPAFContent(response.data.data));
                dispatch(
                    patientProgramList({
                        checked: true,
                        firstName: signedByFirstName,
                        lastName: signedByLastName,
                        relation: patientRelation,
                        finalTreatmentArragement: true,
                        modifiedDate: modifiedDate,
                    })
                );
                navigate('/prescriber/patientagreementform', { replace: true });
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`Error getting pdf ${err}`);
        }
    };
    const handleSave = async () => {
        sessionStorage.removeItem('prevRoute')
        sessionStorage.removeItem('patientInfo')
        const response = await axiosInstance.post(`/referencedata`, { keys: ['ICD-10'] });
        const infoData = getPatientDetailsEditPatient(patientCamundaStore, response.data.data['ICD-10'],'WorkPartial',i18n.language);
        if (patientCamundaStore.camundaProcess.currentStepValue === 'PRESCRIBER_DIGITAL_SIGNATURE') {
            dispatch(
                patientInformation({
                    ...infoData,
                    flowType: 'WorkPartialCompleted',
                    newRisk: patientCamundaStore.patientRiskCategory[0].riskCategoryName,
                })
            );
            handlePPAFOnlineBtn(patientCamundaStore);
        } else {
            dispatch(patientInformation({ ...infoData, flowType: 'WorkPartial',newRisk: patientCamundaStore.patientRiskCategory[0].riskCategoryName, }));
            navigate('/prescriber/patientVerification');
        }
    };
    return (
        <>
        <SessionTimeout/>
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
            <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
            <div className={classes.contentDivider}></div>
            <div className={classes.mainContainer}>
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="firstName"
                    label={t('firstName')}
                    valueExist={patientCamundaStore?.patientNameFirst}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="lastName"
                    label={t('lastName')}
                    valueExist={patientCamundaStore?.patientNameLast}
                />
                <Input
                    type="text"
                    name="mi"
                    label={t('mi')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientCamundaStore?.patientMiddleInitial}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="DOB"
                    label={t('DOB')}
                    isRequired={true}
                    valueExist={Patientdob}
                />
                <Input
                    type="text"
                    name="patientIdNum"
                    // maxLength={9}
                    label={t('patientPin')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientCamundaStore?.patientExternalUniqueId}
                />

                <Input
                    type="text"
                    name="patientEnrollmentStatus"
                    label={t('patientenrollstatus')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientenrollstatus}
                />

                {/* <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationStatus"
                    label={t('authorizationStatus')}
                    valueExist={patientCamundaStore?.authorizationStatus || ''}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationNumber"
                    label={t('authorizationNumber')}
                    valueExist={patientCamundaStore?.authorizationNumber || ''}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="dateLastPrescriberSurveyCompleted"
                    label={t('dateLastPrescriberSurveyCompleted')}
                    valueExist={patientCamundaStore?.dateLastPrescriberSurveyCompleted || ''}
                /> */}

                <div className={classes.btn_group}>
                    <Button
                        clickFunction={handleSave}
                        className={getBtnClass(selectedProgram.id)}
                        width={116}
                        height={48}
                        size={18}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                    ></Button>
                    <Button
                        clickFunction={handleRevise}
                        className={getBtnClass(selectedProgram.id)}
                        width={266}
                        height={48}
                        size={18}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.searchNewPat : buttonContent.en.searchNewPat}
                    ></Button>
                </div>
            </div>
        </div>
        </>
    );
};

export default PrescriberPatientSearch;
