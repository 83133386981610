import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import ErrorModal from 'components/ErrorModal';
import SessionTimeout from 'components/SessionTimeout';
import classes from './PrescriptionFormLang.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import { fetchCurrentPrescriber, setPrescriptionFormData } from 'store/slices/appSlice';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';
const PrescriptionFormPresInfo = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [error, setError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const { selectedProgram, prescriptionFormData, prescriberDetails, prescriptionFormDetailsData } = useSelector((state: RootState) => state.app);
    const { currentUser, IsAuthenticated } = useAppSelector((state) => state.login);
    const { prescLicenseDetails: detailFromStore = {} } = prescriptionFormData || {};
    const { payloadData = {} } = prescriptionFormDetailsData || {};
    const [dropdown, setDropdown] = useState([]);
    const [primaryLocation, setPrimaryLocation] = useState('');
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        pin: '',
        mi: '',
        addressList: [''],
        selectedAddress: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        ext: '',
        fax: '',
        addressId: "",
        stateLicNumPresc: ''
    });
    useEffect(() => {
        reloadFunction();
    }, []);
    useEffect(() => {
        const payload =  
         currentUser?.systemId
            ? {
                prescriberId: currentUser?.systemId || currentUser.userinfo['custom:prescriber_user_id'],
            }
            : {
                onlineUserId: currentUser.userinfo?.username,
            };
        dispatch(fetchCurrentPrescriber(payload));
    }, [IsAuthenticated]);

    useEffect(() => {
        //from store
        if (Object.entries(detailFromStore)?.length > 0) {
            setValues((prev) => ({
                ...prev,
                firstName: detailFromStore.firstName,
                lastName: detailFromStore.lastName,
                pin: detailFromStore.pin,
                mi: detailFromStore.mi,
                addressList: detailFromStore.addressList,
                selectedAddress: detailFromStore.selectedAddress,
                city: detailFromStore.city,
                state: detailFromStore.state,
                zip: detailFromStore.zip,
                phone: detailFromStore.phone,
                ext: detailFromStore.ext,
                fax: detailFromStore.fax,
                addressId: detailFromStore.addressId,
                prescriberSystemId: detailFromStore.prescriberSystemId,
                stateLicNumPresc: detailFromStore.stateLicNumPresc,
            }));
        } else {

            if (Object.entries(payloadData)?.length > 0) {
                // After saveApi Call
                const prescLocApi = prescriberDetails.addressList?.find((obj) => Number(obj?.prescriberLocationSequence) === payloadData?.prescriberLocationId);

                setValues((prev) => ({
                    ...prev,
                    firstName: prescriberDetails.firstName,
                    lastName: prescriberDetails.lastName,
                    pin: prescriberDetails.pin,
                    mi: prescriberDetails.mi,
                    addressList: prescriberDetails.addressList,
                    selectedAddress: prescLocApi?.address,
                    city: prescLocApi?.city,
                    state: prescLocApi?.state,
                    zip: prescLocApi?.zip,
                    phone: prescLocApi?.phone,
                    ext: prescLocApi?.ext,
                    fax: prescLocApi?.fax,
                    addressId: prescLocApi?.prescriberLocationSequence,
                    prescriberSystemId: prescriberDetails.prescriberSystemId,
                    stateLicNumPresc: payloadData?.prescriberLicenseNo
                }));
            } else {
                // before API call
                setValues((prev) => ({
                    ...prev,
                    firstName: prescriberDetails.firstName,
                    lastName: prescriberDetails.lastName,
                    pin: prescriberDetails.pin,
                    mi: prescriberDetails.mi,
                    addressList: prescriberDetails.addressList,
                    selectedAddress: prescriberDetails.selectedAddress,
                    city: prescriberDetails.city,
                    state: prescriberDetails.state,
                    zip: prescriberDetails.zip,
                    phone: prescriberDetails.phone,
                    ext: prescriberDetails.ext,
                    fax: prescriberDetails.fax,
                    addressId: prescriberDetails.addressId,
                    prescriberSystemId: prescriberDetails.prescriberSystemId,
                }));
            }
        }
        if (prescriberDetails.addressList.length > 0) {
            let listAddr: any = [];
            prescriberDetails.addressList.forEach((item) => {
                if (item.primaryLocation === "Y") {
                    setPrimaryLocation(item.prescriberLocationSequence);
                }
                let addrVals = {
                    "valueId": item.prescriberLocationSequence,
                    "value": item.address
                }
                listAddr.push(addrVals)
            });
            setDropdown(listAddr);
        }
    }, [prescriberDetails]);

    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    const onAddressChange = (e) => {
        const { value } = e.target;
        let selectAddr = prescriberDetails.addressList.find((i) => i.prescriberLocationSequence === value.toString())
        setValues((prev) => ({
            ...prev,
            selectedAddress: selectAddr.address,
            city: selectAddr.city,
            state: selectAddr.state,
            zip: selectAddr.zip,
            phone: selectAddr.phone,
            ext: selectAddr.ext,
            fax: selectAddr.fax,
            addressId: value,
        }));
    };


    const fieldValidation = () => {
        const trimVal = { ...values };
        trimVal.stateLicNumPresc = trimVal.stateLicNumPresc.trim()
        setValues(trimVal);

        if (!trimVal.stateLicNumPresc) {
            setError('Please enter a Prescriber State License Number.');
            setCurrentFocus('stateLicNumPresc');
            return false;
        } else if (trimVal.stateLicNumPresc && !/[\-\.'_a-zA-Z0-9]$/.test(trimVal.stateLicNumPresc)) {
            setError('Please enter a valid State License Number.');
            setCurrentFocus('stateLicNumPresc');
            return false;
        } else {
            return true;
        }
    };


    const handleNext = () => {
        const isReqFieldNotEmpty = fieldValidation();
        if (isReqFieldNotEmpty) {
            dispatch(setPrescriptionFormData({ ...prescriptionFormData, prescLicenseDetails: values }));
            navigate('/prescriber/prescriptionformdosage');
        }
    }

    const handlePrev = () => {
        navigate('/prescriber/prescriptionFormLang');
    }

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        ['stateLicNumPresc'].includes(currentFocus) && inputElement.focus();
        ['stateLicNumPresc'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer}>
                <div>
                    <div className={classes.headerContainer}>
                        <div className={classes.generalRemsProgram} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                        }}></div>
                    </div>
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                <div className={classes.productHeader}>{t('prescriptionFormDetails')}</div>
                <div style={{ margin: '12px 0 3px 0px' }}> <span className={classes.headerNote} >{t('enterText')}</span> </div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>

                <div className={classes.contentContainer} >
                    <Input
                        key={'PrescriberFName'}
                        noInput={true}
                        valueExist={values.firstName}
                        type="text"
                        name={'PrescriberFName'}
                        label={t('presFName')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />
                    <Input
                        key={'PrescriberLName'}
                        noInput={true}
                        valueExist={values.lastName}
                        type="text"
                        name={'PrescriberLName'}
                        label={t('presLName')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />
                    <Input
                        key={'MI'}
                        noInput={true}
                        valueExist={values.mi}
                        type="text"
                        name={'MI'}
                        label={t('mi')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />

                    <Input
                        key={'stateLicNumPresc'}
                        type="text"
                        name="stateLicNumPresc"
                        label={t('stateLicNumPresc')}
                        isRequired={true}
                        maxLength={35}
                        onChangeHandler={handleChange}
                        value={values.stateLicNumPresc}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                        helpText={t('pinText')}
                        helpTextStyle={{
                            margin: '15px 0px 0px 0px',
                            fontSize: '12px'
                        }}
                    />

                    <div className={classes.containerPFD} style={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}>
                        <div className={inputStyles.label}>
                            <span className={inputStyles.mandatory}>{t('address')}:</span>
                        </div>
                        <div className={inputStyles.input}>
                            <select
                                name="address"
                                id="address"
                                onChange={onAddressChange}
                                value={values['addressId']}
                            >
                                {dropdown.map((i: any) => (
                                    <option key={i} value={i.valueId}>
                                        {i.value}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>


                    <Input
                        key={'City'}
                        noInput={true}
                        valueExist={values.city}
                        type="text"
                        name={'City'}
                        label={t('city')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />
                    <Input
                        key={'State'}
                        noInput={true}
                        valueExist={values.state}
                        type="text"
                        name={'State'}
                        label={t('state')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />
                    <Input
                        key={'Zip'}
                        noInput={true}
                        valueExist={values.zip}
                        type="text"
                        name={'Zip'}
                        label={t('zip4')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />

                    <Input
                        key={'Phone'}
                        noInput={true}
                        valueExist={values.phone}
                        type="text"
                        name={'Phone'}
                        label={t('phone')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />

                    <Input
                        key={'Fax'}
                        noInput={true}
                        valueExist={values.fax}
                        type="text"
                        name={'Fax'}
                        label={t('fax')}
                        containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                    />
                    <div className={classes.extInput}>
                        <Input
                            key={'Ext'}
                            noInput={true}
                            valueExist={values.ext}
                            type="text"
                            name={'Ext'}
                            label={t('ext')}
                            containerStyle={{ gridTemplateColumns: '0.85fr 1.3fr', margin: '2px' }}
                        />
                    </div>

                    <div className={classes.secButtonDivCls}>
                        <div className={classes.prevBtn}>
                            <Button
                                clickFunction={handlePrev}
                                className={getBtnClass(selectedProgram.id)}
                                width={112}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.previous : buttonContent.en.previous}
                            ></Button>
                        </div>
                        <div className={classes.nextBtn}>
                            <Button
                                clickFunction={handleNext}
                                className={getBtnClass(selectedProgram.id)}
                                width={112}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.next : buttonContent.en.next}
                            ></Button>
                        </div>
                    </div>

                </div>
            </div>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </>
    )
};

export default PrescriptionFormPresInfo;