import classes from '../logDispense.module.scss';
const ExpandRow = (record) => {

    const noInputStyle = { color: 'rgb(75, 96, 117)' };
    
     const expandValues = [
         {
             id: 1,
             label: 'Risk Category',
             value: record.RiskCategoryName,
         },
         {
             id: 2,
             label: `Patient's Date of Birth`,
             value: record.patientDob,
         },
         {
             id: 3,
             label: `Authorization Expiration Date`,
             value: record.authExpDate,
         },
         {
             id: 4,
             label: `Patient's Last Survey Date`,
             value: record.lastSurveyDate,
         },
         {
             id: 5,
             label: `Patient's Next Survey Available on`,
             value: record.nextSurveyDate,
         },
     ];
    
    
      return (
        <div style={{marginTop:'10px'}}>
            <div style={{marginTop:'10px'}}>
                {expandValues.map((item) => (
                    <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 'bold', width: '40%' }}>{item.label}</div>
                        <span>:</span>
                        <div style={{marginLeft:'5px'}}>{item.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpandRow;