import React, { useEffect, useRef, useState } from 'react';
import classes from './PrescriberSurveyInformation.module.scss';
import Input from 'components/Input';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { axiosInstanceNewUrl, axiosInstanceSurvey, axiosInstance_ORC } from '../../constants/Config/axiosConfig';
import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { CalendarOutlined } from '@ant-design/icons';
import { isValidDate } from 'utils/regex';
import './reactDatePicker.css';

import { getPrescriberSurveyQuestion } from 'pages/PrescriberSurveyInformation/getPrescriberSurveyQuestion';
import ErrorModal from 'components/ErrorModal';
import {
    conductPrescriberSurveyRequestBody,
    convertToEn,
} from 'pages/PrescriberSurveyInformation/conductPrescriberSurveyRequestBody';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {buttonContent, getBtnClass, getProdBtnClassDisabled} from '../../assets/objects/buttonContent';
import { setSurveyDataFromPatientDetails, setSurveyInfoFromRoute } from 'store/slices/appSlice';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';

const PrescriberSurveyInformation = () => {
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isPending, setIspending] = useState(false);
    const [showsubmitButton, setShowsubmitButton] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [submitSelected, setSubmitSelected] = useState(false);
    const [errorScreen, setErrorScreen] = useState(false);
    
    
    const {
        patientInformation,
        prescriberDetails,
        selectedProgram,
        prescriberUniqueID,
        prescriberSurveySearchStore,
        patientReactivationSpecialStore,
        patientResponse,
        patientId,
        surveyInfoFromRoute:surveyInfoData
    } = useAppSelector((state) => state.app);

    const { currentUser } = useAppSelector((state) => state.login);

    const selectedLanguage = i18n.language;
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);

    const Prescriber =
        `${prescriberDetails?.firstName} ${prescriberDetails?.lastName}` ||
        `${currentUser?.firstName} ${currentUser?.lastName}`;
    const product = selectedProgram.id;
    const Patient = `${patientInformation?.firstName} ${patientInformation?.lastName}`;

    const dob = `${patientInformation?.month} ${patientInformation?.date}, ${patientInformation?.year}`;
    const todaysDate = new Date();
    const formattedDate = todaysDate.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    });
    const convertedDate = moment(dob, 'MMM DD,YYYY').format('MM/DD/YYYY');

    const id = selectedLanguage === 'es' ? `_es` : '';


    let remsProgram = selectedProgram.label;

    const getProgramsName = (): string => {
       
        return mainMenu(selectedProgram.id);
    };
    
    let q;
    let formattedData;

    const [questions, setQuestions] = useState<any>(null);
    //Read Questions data from redux questions object from API response
    const [prescriberSurveyQuestion, setprescriberSurveyQuestion] = useState<any>(_.cloneDeep(surveyInfoData));
    const [surveyTitle, setSurveyTitle] = useState<any>()

    useEffect(() => {
        if (prescriberSurveyQuestion === 'program not active') {
            setErrorScreen(true);
            setErrorMessage(t('error_PV0450').replaceAll('{program}', getProgramsName()));
        } else if (prescriberSurveyQuestion.RiskCategory === undefined) {
            setErrorScreen(true);
            if(surveyInfoData?.surveryErrMessage){
                setErrorMessage(surveyInfoData?.surveryErrMessage);
            }
            else{
                setErrorMessage(surveyInfoData);
            }
           
        } else {
            if(selectedLanguage==='es'){
                handlePSInformationSpanish();

            }
            else{
                q =
                prescriberSurveyQuestion &&
                prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers?.map((v) => v.Question);
            formattedData = getPrescriberSurveyQuestion(q);
            setQuestions(formattedData);
            setSurveyTitle(prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers[0]?.Question
                ?.PossibleResponses[0]?.SurveyCode)

            }
            
        }
    }, []);
    useEffect(() => {
        reloadFunction();
    }, []);

    const handlePSInformationSpanish = async () => {
        setQuestions(null);
        setShowsubmitButton(false);
        let pp = prescriberSurveySearchStore?.data?.patientPrescribers;
        let apiPatientId, apiPrescriberId;
        if (pp) {
            apiPatientId =
                prescriberSurveySearchStore.data !== undefined
                    ? prescriberSurveySearchStore?.data?.patientId
                    : patientReactivationSpecialStore?.data?.patientDTO?.patientId;
            apiPrescriberId = prescriberDetails?.prescriberSystemId;
        } else {
            apiPatientId = patientId || patientReactivationSpecialStore?.data?.patientDTO?.patientId;
            apiPrescriberId = prescriberDetails?.prescriberSystemId;
        }
        const payload = {
            Channel: 'EXT',
            PrescriberId: apiPrescriberId,
            PatientId: apiPatientId,
            ProductCountryId: selectedProgram.productCountryId,
            LanguageId: '2',
        };

        let response = await axiosInstanceSurvey
            .post('/v1/surveys/ivr-validate-prescriber-survey-eligibility', payload)
            .then((res: any) => {
                if (res.data?.ResponseMessage === 'Success') {
                    let spanishData = res.data;
                    let spanishQuestion = spanishData.PrescriberSurvey.QuestionAnswers.map((v) => v.Question);
                    let formattedSpanishData = getPrescriberSurveyQuestion(spanishQuestion);
                    setQuestions(formattedSpanishData);
                    setSurveyTitle(res?.data?.PrescriberSurvey?.QuestionAnswers[0]?.Question
                        ?.PossibleResponses[0]?.SurveyCode)
                } else {
                    setErrorScreen(true);
                    setErrorMessage(res?.data?.SurveyIneligibleMessage.replaceAll('<ProductName>', getProgramsName()));
                }
            })
            .catch((error) => {
                setErrorMessage(error);
            });
    };
    const prescriberSurveyInformation = () => {
        i18n.changeLanguage('es');
        handlePSInformationSpanish();
    };

    const handleSave = async () => {
        setShowsubmitButton(false);
        setSubmitSelected(true);

        prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers.forEach((questionAnswer, index) => {
            questionAnswer.Answer.ResponseOption = questions[index].value.includes(',')
                ? moment(questions[index].value).format('MM/DD/YYYY')
                : convertToEn(questions[index]?.value)?.split(' ')[0];
        });
        prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers.forEach((questionAnswer, index) => {
            questionAnswer.Answer.ResponseDisplay = questions[index].value.includes(',')
                ? 'Date'
                : convertToEn(questions[index].value);
        });

        const PregTestDateEn = moment(
            questions?.find((i) => i?.question?.includes('most recent pregnancy test'))?.value
        ).format('YYYYMMDD');

        const PregTestDateEs = moment(
            questions?.find((i) => i?.question?.includes('fecha de la prueba de embarazo más reciente'))?.value
        ).format('YYYYMMDD'); //MM/DD/YYYY

        const PregTestDate = i18n.language !== 'es' ? PregTestDateEn : PregTestDateEs;
        const BMSPatientId =
            prescriberSurveySearchStore?.data !== undefined
                ? prescriberSurveySearchStore.data.patientId
                : patientId !== ''
                ? patientId
                : patientReactivationSpecialStore?.data?.patientDTO?.patientId;
        
        let filteredPrescriberSurveyQuestion = prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers.filter((questionAnswer, index) => 
            questionAnswer?.Answer?.ResponseOption !== ""
        );

        let deepCopyPrescriberSurveyQuestion = JSON.parse(JSON.stringify(prescriberSurveyQuestion))
        deepCopyPrescriberSurveyQuestion.PrescriberSurvey.QuestionAnswers = filteredPrescriberSurveyQuestion
        
        let copyQuestions = {...prescriberSurveyQuestion.PrescriberSurvey.QuestionAnswers = filteredPrescriberSurveyQuestion}
        let onlineUserId = currentUser?.onlineUserId
        const conductSurveyPayload: any = {
            prescriberDetails,
            BMSPatientId,
            selectedProgram,
            currentLanguage: i18n.language === 'es' ? 2 : 1,
            PregTestDate: PregTestDate !== 'Invalid date' ? PregTestDate : null,
            SurveyMetaDataID: prescriberSurveyQuestion.SurveyMetaDataID,
            onlineUserId
        };

        const questionsWithAnswers = conductPrescriberSurveyRequestBody(deepCopyPrescriberSurveyQuestion, conductSurveyPayload);
        //
        //Submit API CALL
        try {
            const response = await axiosInstanceSurvey.post(
                `/v1/surveys/conduct-prescriber-survey`,
                questionsWithAnswers
            );
            if (response) {
                if (response.data.ResponseStatus === '0') {
		        dispatch(setSurveyDataFromPatientDetails({}));
		        dispatch(setSurveyInfoFromRoute({}));
                sessionStorage.setItem('surveyConfirmData',JSON.stringify(response.data))
                navigate('/prescriber/prescribersurveyconfirmation');
                } else if (response.data?.ResponseStatus === '1') {
                    setErrorScreen(true);
                    if (response?.data?.SurveyIneligibleMessage !== undefined) {
                        setErrorMessage(
                            response?.data?.SurveyIneligibleMessage.replaceAll('<ProductName>', getProgramsName())
                        );
                    } else {
                        setErrorMessage(response.data?.ResponseMessage);
                    }
                } else {
                    setErrorScreen(true);
                    setErrorMessage(
                        response?.data?.SurveyIneligibleMessage.replaceAll('<ProductName>', getProgramsName())
                    );
                }
            }
        } catch (error) {
            navigate('/prescriber/SystemError');
            dispatch(setSurveyInfoFromRoute({}));
        }
        setSubmitSelected(false);
    };

    const [showCalendar, setShowCalendar] = useState(false);

    const [selected, setSelected] = useState('Please Select');
    const CustomInput = ({ type, value, onChange, options }) => {
        const handleSelectChange = (e) => {
            if (e.target.value === 'date') {
                setSelected(e.target.value);
                setStartDate(null);
                setShowCalendar(true);
            } else {
                setSelected(e.target.value);
                onChange(e);
                setShowCalendar(false);
            }
        };

        const handleDateChange = (e) => {
            if (e?.target === undefined) {
                setStartDate(e);
                onChange(e);
                return;
            } else {
                const checkDate = moment(e.target.value, 'MM/DD/YYYY').isValid();
                setStartDate(e.target.value);
                if (checkDate) {
                    onChange(e);
                } else {
                    setStartDate(null);
                    setError(t('dateErrorwithdate') + ' ' + e.target.value + '. ' + t('patientDateError'));
                    return;
                }
            }
        };

        if (type === 'DateDontKnow') {
            return (
                <>
                    <br />
                    <select value={selected} onChange={handleSelectChange} style={{ width: '136px' }}>
                        <option value="">{t('PleaseSelect')}</option>
                        <option value="date">{t('Date')}</option>
                        <option value="dontknow">{t('DontKnow')}</option>
                    </select>
                    &nbsp;
                    {showCalendar && (
                        <DatePicker
                            id="datepicker"
                            toggleCalendarOnIconClick
                            selected={startDate}
                            showIcon
                            onSelect={handleDateChange}
                            placeholderText="mm/dd/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="MM/dd/yyyy"
                            todayButton="Today"
                            icon={<CalendarOutlined style={{ marginLeft: '90px', marginTop: '-4px' }} />}
                            style={{ padding: '50px' }}
                            onBlur={handleDateChange}
                            minDate={new Date('01/01/1940')}
                            strictMode={true}
                        />
                    )}
                </>
            );
        } else {
            return (
                <>
                    <br />
                    <select value={value} onChange={onChange}>
                        <option value="">{t('PleaseSelect')}</option>
                        {options.map((option) => (
                            <option key={option.ID} value={option.ResponseOption}>
                                {option.ResponseOption}
                            </option>
                        ))}
                    </select>
                </>
            );
        }
    };

    const handleReset = () => {
        setQuestions(questions.map((q: any) => ({ ...q, value: '', show: false })));
    };

    const handleStartOver = () => {
        setShowsubmitButton(false);

        setShowCalendar(false);
        handleReset();
    };

    const handleErrorBtn = () => {
        setError('');
        setCurrentFocus('');
    };

    const [currentSequence, setCurrentSequence] = useState('1');

    const handleNext = (id) => {
        const checkQuestionType = questions.find((q) => q?.questionSequence === id);
        if (checkQuestionType.type === 'DateDontKnow' && showCalendar === true) {
            const inputDate = moment(checkQuestionType.value).isValid();
            const isFutureDate = moment(checkQuestionType.value).isAfter(moment(), 'day');
            if (!inputDate) {
                setError(t('patientDOBError'));
                setCurrentFocus('');
                return;
            } else if (isFutureDate) {
                setError(t('prescriberSurveyDateError'));
                setCurrentFocus('');
                return;
            } else {
                setError('');
                setQuestions(questions.map((q: any) => (q.questionSequence === id ? { ...q, show: true } : q)));
                setSelected('Please select');
                setShowCalendar(false);
                const allQuestionAnswered = questions.every((q) => q.value !== '');
                if (allQuestionAnswered) {
                    setShowsubmitButton(true);
                }
            }
        } else {
            setError('');
            const skipSequence: any =
                checkQuestionType.type !== 'DateDontKnow'
                    ? checkQuestionType.options.find((o) => o.ResponseOption === checkQuestionType.value).SkipToSequence
                    : null; //capture skip sequence
            if (skipSequence === null) {
                setQuestions(questions.map((q: any) => (q.questionSequence === id ? { ...q, show: true } : q)));
                setCurrentSequence(String(parseInt(checkQuestionType?.questionSequence) + 1));
            } else {
                //if skipsequence is 4, make all previous 3 questions skip to true
                let qcopy = JSON.parse(JSON.stringify(questions));
                for (let i = parseInt(currentSequence); i < parseInt(skipSequence); i++) {
                    let cI = qcopy.find((v) => v.questionSequence == i);
                    if (
                        Object.keys(cI || {}).length &&
                        parseInt(currentSequence) - parseInt(checkQuestionType?.questionSequence) === 0
                    ) {
                        cI.skip = false;
                        cI.show = true;
                    } else if (Object.keys(cI || {}).length) {
                        cI.skip = true;
                        cI.show = true;
                    }
                    setQuestions(qcopy);
                }
                setCurrentSequence(skipSequence);
            }
            setSelected('Please select');
            setShowCalendar(false);

            const allQuestionAnswered = questions[questions.length-1]?.value !== "";
            if (allQuestionAnswered) {
                setShowsubmitButton(true);
            }
        }
    };

    const handleChange = (id, newValue) => {
        if (newValue?.target === undefined) {
            setQuestions(
                questions.map((q) => (q.id === id ? { ...q, value: moment(newValue).format('MMMM D, YYYY') } : q))
            );
        } else if (moment(newValue.target.value).isValid()) {
            setQuestions(
                questions.map((q) =>
                    q.id === id ? { ...q, value: moment(newValue.target.value).format('MMMM D, YYYY') } : q
                )
            );
        } else {
            setQuestions(questions.map((q) => (q.id === id ? { ...q, value: newValue.target.value } : q)));
        }
    };

    return (
        <>
            <SessionTimeout />
            
            <div className={!errorScreen ? classes.container : classes.error}>
                <div className={`${classes.headerContainer} `}>
                    {product && <span className={classes.programlogo}
                    style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}
                    ></span>}

                    {i18n.language === 'en' && !errorScreen ? (
                        <div>
                            <Button
                                clickFunction={prescriberSurveyInformation}
                                className={getBtnClass(selectedProductData.id)}
                                width={110}
                                height={45}
                                size={18}
                                type="text"
                                buttonname={buttonContent.es.toSpanish}
                            ></Button>

                             


                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className={classes.headerDivider} 
                    style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}
                ></div>
                <div className={classes.productHeader}>{t('prescriberSurvey')}</div>
                <div className={classes.contentDivider}></div>
                <div className={!errorScreen ? classes.mainContainer : classes.errorContainer} ref={containerRef}>
                    {errorMessage && (
                        <Alert styles={{ margin: '10px 30px 5px 30px', fontSize: '10px' }} content={errorMessage} />
                    )}
                    {!errorScreen ? (
                        <div>
                            <p className={classes.infoHeader}>
                                {surveyTitle || prescriberSurveyQuestion?.PrescriberSurvey?.QuestionAnswers[0]?.Question
                                        ?.PossibleResponses[0]?.SurveyCode}
                            </p>
                            <div className={classes.contentContainer}>
                                <div className={classes.infoColumn1}>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <Input
                                            className={classes.infoInput}
                                            type="text"
                                            noInputStyle={noInputStyle}
                                            labelStyle={noInputStyle}
                                            noInput={true}
                                            name="Date"
                                            label={t('date')}
                                            valueExist={formattedDate}
                                        />
                                    </div>
                                    <div className={`${classes['prescriberInput' + id]}`}>
                                        <Input
                                            className={classes.infoInput}
                                            type="text"
                                            noInputStyle={noInputStyle}
                                            labelStyle={noInputStyle}
                                            noInput={true}
                                            name="Prescriber"
                                            label={t('prescriber')}
                                            valueExist={prescriberDetails.firstName + ' ' + prescriberDetails.lastName}
                                        />
                                    </div>
                                    <div style={{ paddingBottom: '10px' }}>
                                        {prescriberSurveySearchStore.data !== undefined ? (
                                            <Input
                                                className={classes.infoInput}
                                                type="text"
                                                noInputStyle={noInputStyle}
                                                labelStyle={noInputStyle}
                                                noInput={true}
                                                name="Patient"
                                                label={t('patient')}
                                                valueExist={
                                                    prescriberSurveySearchStore.data.patientNameFirst +
                                                    ' ' +
                                                    prescriberSurveySearchStore.data.patientNameLast
                                                }
                                            />
                                        ) : (
                                            <>
                                                {patientResponse.patientNameFirst !== undefined ? (
                                                    <Input
                                                        className={classes.infoInput}
                                                        type="text"
                                                        noInputStyle={noInputStyle}
                                                        labelStyle={noInputStyle}
                                                        noInput={true}
                                                        name="Patient"
                                                        label={t('patient')}
                                                        valueExist={
                                                            patientResponse.patientNameFirst +
                                                            ' ' +
                                                            patientResponse.patientNameLast
                                                        }
                                                    />
                                                ) : (
                                                    <Input
                                                        className={classes.infoInput}
                                                        type="text"
                                                        noInputStyle={noInputStyle}
                                                        labelStyle={noInputStyle}
                                                        noInput={true}
                                                        name="Patient"
                                                        label={t('patient')}
                                                        valueExist={
                                                            patientReactivationSpecialStore?.data?.patientDTO
                                                                ?.patientNameFirst +
                                                            ' ' +
                                                            patientReactivationSpecialStore?.data?.patientDTO
                                                                ?.patientNameLast
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.infoColumn2}>
                                    <div style={{ paddingBottom: '10px' }}>
                                        <Input
                                            type="text"
                                            noInputStyle={noInputStyle}
                                            labelStyle={noInputStyle}
                                            noInput={true}
                                            name="Prescriber identification number"
                                            label={t('prescriberPin')}
                                            isSurveyPage={true}
                                            valueExist={prescriberUniqueID}
                                        />
                                    </div>
                                    <div className={`${classes['patientNumber' + id]}`}>
                                        <Input
                                            type="text"
                                            noInputStyle={noInputStyle}
                                            labelStyle={noInputStyle}
                                            noInput={true}
                                            name="Patient identification number"
                                            label={t('patientPin')}
                                            isSurveyPage={true}
                                            valueExist={
                                                prescriberSurveySearchStore?.data !== undefined
                                                    ? prescriberSurveySearchStore?.data?.patientExternalUniqueId
                                                    : patientResponse.patientExternalUniqueId !== undefined
                                                    ? patientResponse.patientExternalUniqueId
                                                    : patientReactivationSpecialStore?.data?.patientDTO
                                                          ?.patientExternalUniqueId
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={classes.contentDivider}>
                                {/* QUESTIONS */}
                                <div className={classes.surveyQuestions}>
                                    {questions?.map((q, index) => (
                                        <div key={q.id} className={classes.questions}>
                                            <p className={classes.questionValues}>
                                                {index + 1}.
                                                <p
                                                    style={{ marginLeft: '10px', marginTop: '0px' }}
                                                    dangerouslySetInnerHTML={{ __html: q.question }}
                                                ></p>
                                            </p>
                                            <div className={classes.calenderValues}>
                                                {!q.show && (index === 0 || questions[index - 1].show !== false) ? (
                                                    <CustomInput
                                                        type={q.type}
                                                        value={q.value}
                                                        onChange={(e) => handleChange(q.id, e)}
                                                        options={q.options}
                                                    />
                                                ) : q.skip ? (
                                                    <></>
                                                ) : (
                                                    <p>
                                                        <strong>
                                                            {q.value.includes('-')
                                                                ? moment(q.value).format('MMMM D, YYYY')
                                                                : q?.value?.replace('dontknow', t('DontKnow'))}
                                                        </strong>
                                                    </p>
                                                )}
                                            </div>

                                            <div className={classes.btnValues}>
                                                {q.value !== '' && !q.show && (
                                                    <button
                                                    onClick={() => handleNext(q?.questionSequence)}
                                                    className={`${classes.btnSmall} ${classes['btn_Next_small_' + getBtnClass(selectedProductData.id)]}`}
                                                   
                                                >{i18n.language === 'es' ? buttonContent.es.next : buttonContent.en.next}</button>                                                

                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={classes.btnFooter}>
                                    <div className={classes.btn_group}>
                                        {!showsubmitButton ? (
                                            <Button
                                            className={getProdBtnClassDisabled(selectedProductData.id)}
                                            width={110}
                                            height={48}
                                            size={18}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.submit : buttonContent.en.submit}
                                            disabled={isPending}
                                        ></Button>
                                        ) : (
                                            <Button
                                            clickFunction={ handleSave  }
                                            className={getBtnClass(selectedProductData.id)}
                                            width={110}
                                            height={48}
                                            size={18}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.submit : buttonContent.en.submit}
                                            disabled={isPending}
                                        ></Button>
                                        )}
                                        <Button
                                                clickFunction={handleStartOver}
                                                className={getBtnClass(selectedProductData.id)}
                                                width={i18n.language === 'es' ? 240 : 135}
                                                height={48}
                                                size={18}
                                                type="text"
                                                buttonname={i18n.language === 'es' ? buttonContent.es.startOver : buttonContent.en.startOver}
                                                disabled={isPending}
                                            ></Button>
                                    </div>
                                </div>
                            </div>
                            {submitSelected && <small style={{ marginTop: '-10px' }}>{t('message_PM0830')}</small>}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
};

export default PrescriberSurveyInformation;