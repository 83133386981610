import SessionTimeout from 'components/SessionTimeout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store';
import { changePPAF, deletePrescriptionAuthDetails, deletePrescriptionDosage, deletePrescriptionFormDetailsData, setPrescriptionFormData } from 'store/slices/appSlice';
import classes from './PrescriptionForm.module.scss';
import { useNavigate } from 'react-router-dom';
import i18n from 'internationalization/i18next';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';

const PrescriptionForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { selectedProgram, patientResponse, prescriberSurveySearchStore, patientReactivationSpecialStore } =
        useSelector((state: RootState) => state.app);

    useEffect(() => {
        dispatch(changePPAF(true));
        i18n.changeLanguage('en')
    }, []);
    const routeToPrescriptionForm = (formType) => {
        let patientDto = {};
        dispatch(deletePrescriptionFormDetailsData({}));
        dispatch(deletePrescriptionDosage({}));
        dispatch(deletePrescriptionAuthDetails({}));
        if (Object.keys(patientResponse).length) {
            patientDto = patientResponse;
        } else {
            patientDto =
                prescriberSurveySearchStore?.statusCode === 200
                    ? prescriberSurveySearchStore?.data
                    : patientReactivationSpecialStore?.data?.patientDTO;
        }
        dispatch(setPrescriptionFormData({ formType, patientDto }));
        navigate('/prescriber/prescriptionformdetails');
    };
    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer}>
                <div>
                    <div className={classes.headerContainer}>
                        <div className={classes.generalRemsProgram} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                        }}></div>
                    </div>
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                <div className={classes.productHeader}>{t('prescriptionFormTitle')}</div>
                <div className={classes.contentContainerBtn}>
                     <div className={`${classes.btnWithImage} ${classes.smallIcons} ${classes.noMarP}`}>
                            {selectedProgram.id.includes('thalomid') ?
                                (<div className={`${classes.innerImage} ${classes.prodPrescriptionThal}`}></div>)
                                :
                                selectedProgram.id.includes('pomalyst') ?
                                    (<div className={`${classes.innerImage} ${classes.prodPrescriptionPom}`}></div>)
                                    :
                                    (<div className={`${classes.innerImage} ${classes.prodPrescriptionLen}`}></div>)}
                            <Button
                                clickFunction={() => routeToPrescriptionForm('StandardForm')}
                                className={getBtnClass(selectedProgram.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.stdPrescForm}
                                innerImage={true}
                            ></Button>
                        </div>
                        <div className={`${classes.btnWithImage} ${classes.smallIcons}`}>
                            {selectedProgram.id.includes('thalomid') ?
                                (<div className={`${classes.innerImage} ${classes.prodPrescriptionThal}`}></div>)
                                :
                                selectedProgram.id.includes('pomalyst') ?
                                    (<div className={`${classes.innerImage} ${classes.prodPrescriptionPom}`}></div>)
                                    :
                                    (<div className={`${classes.innerImage} ${classes.prodPrescriptionLen}`}></div>)}
                            <Button
                                clickFunction={() => routeToPrescriptionForm('VHAForm')}
                                className={getBtnClass(selectedProgram.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.vaPrescription}
                                innerImage={true}
                            ></Button>
                        </div>
                </div>
            </div>
        </>
    );
};

export default PrescriptionForm;
