export enum LogDispenseScreens {
    SelectDispense = 1,
    Review = 2,
    Confirmation = 3,
    Error = 4,
    DefaultLocation = 5,
}

interface AuthNo {
    errorMsg: string | null;
    auth_no: string;
}

interface Patient {
    errorMsg: string | null;
    patient_name: string;
}

interface Product_ID {
    errorMsg: string | null;
    product_id: string;
    programProductId?: number;
    ndcDescription?: string;
    dosageId?: number;    
    id?:number;
    value?: string;
    productCountryId?: number;
}

export interface Quantity {
    errorMsg: string | null;
    quantity: string;
}

export interface Product {
    id: number;
    product_id: Product_ID;
    quantity: Quantity;
    checked?: boolean;
}

export interface AuthDataType {
    id: number;
    checked: boolean;
    auth_no: AuthNo;
    patient_name: Patient;
    product: Product[];
    authResponse?: any;
    product_options: any[];
    location?: any;
}

export const Inactive_Pharmacy =
    'The pharmacy enrollment status is not active for the REMS Program with the entered authorization number.';

export const Inactive_Pharmacy_UI = 'The selected Pharmacy is not active for this product.';

export const authStatusMap = new Map([
    ['Active', 'Active'],
    ['Obsolete', 'Authorization Number is no longer valid'],
    ['Mismatched','Survey-Flagged'],
    ['Expired','Authorization Number Expired'],
    ['Patient Survey Required','Patient Survey Required'],
    ['Confirmed','Confirmed']
]);
