export const patientForm = {
    personalInfo: [
        {
            name: 'relation',
            label: 'Relation to the Patient',
            type: 'select',
            isRequired: true,
            defaultValue: 'Select One',
            helpText1: 'Select "Self" if you are the patient and are of legal age. Select "Authorized',
            helpText2: 'Representative" if you are signing the PPAF on behalf of the patient as a Legal',
            helpText3: 'Guardian, or have Power of Attorney for this patient.',
            errMsg: 'The Relation to the Patient is required. Please choose an option for the relation to Patient.',
            option: [
                {
                    value: 'Select One',
                },
                {
                    value: 'Self',
                },
                {
                    value: 'Authorized Representative',
                },
            ],
            spanishOption: [
                {
                    value: 'Seleccionar Uno',
                },
                {
                    value: 'Yo mismo',
                },
                {
                    value: 'Representante Autorizado',
                },
            ],
        },
        {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid First Name.',
            maxLength: 30,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Last Name.',
            maxLength: 30,
        },

        // {
        //     name: 'signature',
        //     label: 'Signature Question 1',
        //     type: 'select',
        //     isRequired: true,
        //     errMsg: '',
        //     option: [
        //         { value: 'Select a Question...' },
        //         { value: 'In what city were you born?' },
        //         { value: 'What are the last four digits of your SSN?' },
        //         { value: "What is your mother's maiden name?" },
        //         { value: 'What is your zip or postal code?' },
        //         { value: 'What was the name of your first pet?' },
        //         { value: 'What high school did you attend?' },
        //     ],
        // },
        // {
        //     name: 'answer',
        //     label: 'Answer',
        //     type: 'text',
        //     isRequired: true,
        //     errMsg: '',
        //     maxLength: 30,
        // },
        // {
        //     name: 'signature2',
        //     label: 'Signature Question 2',
        //     type: 'select',
        //     isRequired: true,
        //     errMsg: '',
        //     option: [
        //         { value: 'Select a Question...' },
        //         { value: "What is your father's middle name?" },
        //         { value: "What is your mother's middle name?" },
        //         { value: "What is your spouse's middle name?" },
        //         { value: 'What is your date of birth?' },
        //     ],
        // },
        // {
        //     name: 'answer2',
        //     label: 'Answer',
        //     type: 'text',
        //     isRequired: true,
        //     errMsg: '',
        //     maxLength: 30,
        // },
    ],
};
