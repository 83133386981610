import React, { useEffect, useState } from 'react';
import PageBackground from 'components/PageBackground';
import { useNavigate } from 'react-router-dom';
import classes from './manageAccount.module.scss';
import RightArrow from '../../../assets/images/btn_arrow_right.png';
import { useAppDispatch, useAppSelector } from 'store';
import { Table, Radio } from 'antd';
import Alert from '../../../components/Alert';
import Button from 'components/Button';
import { getActivePharmacyLocation, updatePharmacyLocation } from 'store/slices/appSlice';
import ErrorModal from 'components/ErrorModal';
import SessionTimeout from 'components/SessionTimeout';

const ManageAccount = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [tableData, setTableData] = useState<any>([]);
    const [error, setError] = useState('');
    const [errorMessage, seterrorMessage] = useState('');

    const { currentUserPharmacy } = useAppSelector((state) => state.login);
    const { activePharmacyLocation } = useAppSelector((state) => state.app);

    useEffect(() => {
        const payload = {
            channelId: 'ext',
            onlineUserName: currentUserPharmacy.onlineUserId,
        };
        dispatch(getActivePharmacyLocation(payload));
    }, [currentUserPharmacy]);

    const handleDefaultLocChange = (record) => {
        const updatedData = [...tableData];
        updatedData.forEach((item) => {
            item.defaultLocation = record.key === item.key ? true : false;
        });
        seterrorMessage('');
        setTableData(updatedData);
    };

    const columns = [
        {
            title: 'Default',
            dataIndex: 'defaultLocation',
            key: 'defaultLocation',
            render: (_, record) => (
                <Radio checked={record?.defaultLocation} onChange={() => handleDefaultLocChange(record)} />
            ),
        },
        {
            title: 'NCPDP Number',
            dataIndex: 'ncpdpNumber',
            key: 'ncpdpNumber',
        },
        {
            title: 'DEA Number',
            dataIndex: 'deaNumber',
            key: 'deaNumber',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
    ];

    useEffect(() => {
        const data: any[] = [];
        if (activePharmacyLocation.length > 0) {
            activePharmacyLocation?.map((pharmaLocation) => {
                data.push({
                    key: pharmaLocation?.pharmacyId,
                    ncpdpNumber: pharmaLocation?.ncpdp || '',
                    deaNumber: pharmaLocation?.deaNumber || '',
                    city: pharmaLocation?.city || '',
                    state: pharmaLocation?.state || '',
                    defaultLocation: pharmaLocation?.defaultLocation,
                    locationSequence: pharmaLocation?.pharmacyLocationSequence || '',
                    pharmacyId: pharmaLocation?.pharmacyId || '',
                });
            });
            setTableData(data);
        }
    }, [activePharmacyLocation]);

    const handleReset = () => {
        navigate('/pharmacy/manageresetpasswordPhar');
    };

    const handleSave = () => {
        const updatedData = tableData.find((item) => item.defaultLocation === true);
        if (updatedData && Object.keys(updatedData).length > 0) {
            const payload = {
                pharmacyId: updatedData?.pharmacyId || '',
                pharmacyLocationSequence: updatedData?.locationSequence || '',
                onlineUserName: currentUserPharmacy.onlineUserId,
                defaultLocation: updatedData?.defaultLocation,
            };
            dispatch(updatePharmacyLocation(payload)).then((e: any) => {
                if (e?.payload?.data?.message === 'SUCCESS') {
                    navigate('/pharmacy/manageAccountConfirmation');
                } else {
                    seterrorMessage(e?.payload ? e?.payload?.response?.data?.data?.errorMesssage?.messageKey : '');
                    window.scrollTo(0, 0);
                }
            });
        } else {
            setError('Please select a default Pharmacy location.');
            window.scrollTo(0, 0);
        }
    };
    const handleErrorBtn = () => {
        setError('');
    };

    return (
        <>
            <SessionTimeout />
            <div style={{ marginTop: '10px' }} className={classes.managePharmaAcc}>
                <PageBackground label={'Manage My Account - Pharmacist'}>
                    <div className={classes.mainContainer}>
                        {errorMessage && <Alert content={errorMessage} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.headerStyle} style={{ marginTop: '0' }}>
                            My Certification
                        </div>
                        <div className={classes.whiteBoxCont} style={{ width: '45%' }}>
                            <div
                                className={classes.leftBx}
                                style={{
                                    marginRight: '2%',
                                }}
                            >
                                <div>
                                    <div className={classes.blackBoldText} style={{ marginBottom: '10px' }}>
                                        My Certified Pharmacist Information
                                    </div>
                                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                        I am certified as:{' '}
                                        <span className={classes.blackBoldText}>{`${
                                            currentUserPharmacy?.firstName || ''
                                        } ${currentUserPharmacy?.lastName || ''}`}</span>
                                    </div>
                                    <div>
                                        User Name:{' '}
                                        <span className={classes.blackBoldText}>
                                            {currentUserPharmacy?.userinfo['cognito:username'] || ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.resetDiv}>
                                <div className={classes.clickLinks} onClick={handleReset}>
                                    Reset my password <img src={RightArrow} alt="right_arrow" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.headerStyle} style={{ marginTop: '10px' }}>
                            My Locations
                        </div>
                        <div className={`${classes.whiteBoxCont} ${classes.locationsTable}`}>
                            <div>{activePharmacyLocation?.length} Pharmacy Locations associated with your account.</div>
                            <div className={classes.locationsDiv}>
                                <div className={classes.tableDiv}>
                                    <Table
                                        size="small"
                                        rowClassName={(record, index) =>
                                            index % 2 === 0 ? `table-row-dark` : `table-row-light`
                                        }
                                        columns={columns}
                                        dataSource={tableData.length > 0 ? tableData : []}
                                        pagination={false}
                                        className={classes.tableStyle}
                                    />
                                </div>
                                <div style={{ width: '25%', marginTop: '20px' }}>
                                    <p>
                                        Please select a location as your default location, and click the Save button. If
                                        any Pharmacy locations are missing or incorrect, please contact the REMS Call
                                        Center at 1-888-423-5436.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.buttonWrapper}>
                            <Button
                                clickFunction={() => {
                                    handleSave();
                                }}
                                className="loginBtn"
                                type="text"
                                buttonname="Save"
                                size={14}
                                width={120}
                                height={40}
                            ></Button>
                        </div>
                    </div>
                </PageBackground>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
};

export default ManageAccount;
