import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import _ from 'lodash';
import { axiosInstance, axiosInstanceNewUrl, axiosInstanceSearch, axiosInstanceSurvey, axiosInstance_2, axiosInstance_ORC, axiosInstance_PDF } from 'constants/Config/axiosConfig';
import { patientInfoFields } from 'constants/patientInformationFields';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { authorizationSearch, getPrescription, patientInformation, patientPrescriberStore,resetpatientPrescriberStore, setPrescriptionDosage, setPrescriptionFormDetailsData, storePrescriptionData } from 'store/slices/appSlice';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import classes from './PrescriptionFormDetails.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import { Col, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
    isValidDirections,
    isValidQuantity
} from 'utils/regex';
import { savePrescriptionFormPayload } from 'data-models/savePrescriptionFormPayload';
import { pdfPrescriptionPayload } from 'data-models/prescriptionPDFpayload';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig, lenalidomide } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';
interface dropDownType {
    Dosage?: { dosageId: string; dosageDetails: string }[] | null;
}

const PrescriptionFormDosage =() =>{
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const {
        IsAuthenticated
    } = useSelector((state: RootState) => state.login);
    const {
        selectedProgram,
        patientInformation: patientFields,
        role,
        prescriptionFormDosage,
        prescriptionFormDetailsData,
        prescriptionFormData,
        prescriberDetails,
        prescriptionAuthDetails = {},
        prescAllDetails 
    } = useSelector((state: RootState) => state.app);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [servererror, setServerError] = useState('');
    const [dropdown, setDropdown] = useState<dropDownType>({ Dosage: []});
    const authorizationNumber = prescriptionFormDetailsData?.authNo;
    const authorizationStatus =prescriptionFormDetailsData?.authStatus;
    const [dosagePrograms,setDosagePrograms]= useState([]);
    const [values, setValues] = useState({
        prodSelection:"",
        dosage1:false,
        dosage2:false,
        dosageMg1:"",
        dosageMg2:"",
        qty1:"",
        qty2:"",
        directions:"",
        dispensigInfo:selectedProgram.id ==="rems_program_thalomid" ? "dispenseWritten":"",
        authNo:"",
        authStatus:""
    });
    const prescriberId = prescriberDetails?.prescriberSystemId;
    const patientId = prescriptionFormData?.patientDto?.patientId;
    const fetchDosage = async () => {
        
        try {
            const response = await axiosInstance.get(`/referencedata/dosage?productCountryId=${selectedProgram?.productCountryId}`);
            setDropdown(response?.data?.data);
        } catch (err) {
            console.error(`error fetching dosage ${err}`);
        }
    };
    useEffect(() => {
        reloadFunction();
    }, []);
    useEffect(() => {
        fetch('./static/resources/dosagePrograms.json')
        .then(function (res) {
            return res.json();
        })
        .then(function (data) {
            // store Data in State Data Variable            
            const prgmValue = data[selectedProgram.flag] || []
            setDosagePrograms(prgmValue)
        })
        .catch(function (err) {
            console.error(err, ' error');
        });
        fetchDosage();
        if(Object.entries(prescriptionFormDosage).length>0){
            const newFields = { ...prescriptionFormDosage };
            setValues((prev) => ({
                ...prev,
                ...newFields,
            }));
        }
        
        if(Object.entries(prescriptionAuthDetails)?.length === 0)
        {
            const authNumber = prescriptionFormData?.patientDto?.surveyData !==null? prescriptionFormData?.patientDto?.surveyData[0].authorizationNumber :"";
            if(authNumber){
                const payload = {
                    authorizationNumber:authNumber
                }
                dispatch(authorizationSearch({data:payload})).then((response:any)=>{
                    if(response?.payload?.status === 200){
                        if(response.payload?.data?.data?.length>0){
                            setValues((prev) => ({
                                ...prev,
                                authNo:(response.payload?.data?.data[0].authorizationNumber)?.substring(2) ||"",
                                authStatus:response.payload?.data?.data[0].authorizationStatus
                            }));
                        }
                        
                    }
                })
            }
            
        }
        setServerError('');
    },[dispatch]);
   

    const getAuthMessage =(status)=>{
        if(status === "Patient Survey Required" )
        {
            return t('authStatus_PD0355');
        }
        else if(status === "Mismatched" ||status === "Expired" ||status === "Confirmed" || status ==="Obsolete")
        {
            return t('authStatus_PD0350');
        }
        else{
            return "";
        }
    }
    const getAdobeText = () => (
        <span className={classes.adobeText}>
            The Print function requires Adobe<sup>®</sup> Reader<sup>®</sup>. Click here to download it for free.
        </span>
    );
    const handlePrev =() => {
        dispatch(setPrescriptionDosage({data:values}))
        navigate('/prescriber/prescriptionformpresinfo');
    }
    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        
        inputElement.focus();
       !['dosageMg1','dosageMg2'].includes(currentFocus) &&  inputElement.select();
        setError('');
        setCurrentFocus('');
    };
    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const validation =()=>{
        if(selectedProgram.id === "rems_program_lenalidomide" && values.prodSelection === "" )
        {
            setError(t('prdSel_PV0721'));
            setCurrentFocus(lenalidomide);
            scrollToView();
            return false;
        }
        else if(!values.dosage1 && !values.dosage2){
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosage1');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && !values.dosage2) && (values.dosageMg1 === "" || values.dosageMg1 === "select") ){
            
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg1');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && !values.dosage2) && (values.dosageMg1 === "" || values.dosageMg1 === "select") ){
            
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg1');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && !values.dosage2) && (!isValidQuantity(values.qty1)))
        {
            setError(t('quantity_PV0710'));
            setCurrentFocus('qty1');
            scrollToView();
            return false;
        }
        else if((!values.dosage1 && values.dosage2) && (values.dosageMg2 === "" || values.dosageMg2 === "select") ){
            
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg2');
            scrollToView();
            return false;
        }
        else if((!values.dosage1 && values.dosage2) && (values.dosageMg2 === "" || values.dosageMg2 === "select") ){
            
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg2');
            scrollToView();
            return false;
        }
        else if((!values.dosage1 && values.dosage2) && (!isValidQuantity(values.qty2)))
        {
            setError(t('quantity_PV0710'));
            setCurrentFocus('qty2');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && values.dosage2) &&  (values.dosageMg1 === "" || values.dosageMg1 === "select") )
        {
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg1');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && values.dosage2) &&  (values.dosageMg2 === "" || values.dosageMg2 === "select") )
        {
            setError(t('dosage_PV0715'));
            setCurrentFocus('dosageMg2');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && values.dosage2) && (!isValidQuantity(values.qty1)))
        {
            setError(t('quantity_PV0710'));
            setCurrentFocus('qty1');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && values.dosage2) && (!isValidQuantity(values.qty2)))
        {
            setError(t('quantity_PV0710'));
            setCurrentFocus('qty2');
            scrollToView();
            return false;
        }
        else if((values.dosage1 && values.dosage2) && (values.dosageMg1 === values.dosageMg2))
        {
            setError(t('dosage_PD0365'));
            setCurrentFocus('dosageMg2');
            scrollToView();
            return false;
        }
        else if(values.directions?.trim() === "")
        {
            setError(t('directions_PV0716'));
            setCurrentFocus('directions');
            scrollToView();
            return false;
        }
        else if(!isValidDirections(values.directions?.trim()))
        {
            setError('Please enter valid Dosage Directions.');
            setCurrentFocus('directions');
            scrollToView();
            return false;
        }
        else if(values.dispensigInfo === ""){
            setError(t('dispInfo_PV0720'));
            setCurrentFocus('dispenseWritten');
            scrollToView();
            return false;
        }
        else{
            return true;
        }
    }

    const titlepath = (path, name, blob) => {
        const blobUrl = URL.createObjectURL(blob);
        var prntWin = window.open();
        prntWin?.document.write("<html><head><title>" + name + "</title></head><body>"
            + '<embed width="100%" height="100%" name="plugin" src="' + path + '" '
            + 'type="application/pdf" internalinstanceid="21"></body></html>');
        prntWin?.document.close();
        window.URL.revokeObjectURL(blobUrl);
    };

    const handlePrint = async () => {
        const isValid = validation();
        setServerError('');
        if (isValid) {
            //Call save api
            try {
                //const saveApiResp = await axiosInstanceSurvey.post(`/v1/prescription`,
                const saveApiResp = await axiosInstanceSurvey.post(`/v1/prescription`,
                    savePrescriptionFormPayload(
                        selectedProgram,
                        prescriptionFormData,
                        prescriptionFormDetailsData,
                        prescriberDetails,
                        values,
                        dropdown?.Dosage,
                        prescAllDetails,
                        prescriptionAuthDetails,
                    )
                );
                if (saveApiResp?.data.statusCode === 201) {

                    dispatch(storePrescriptionData(saveApiResp?.data?.data));

                    // 2nd Api call for pdf
                    let responsePDF = await axiosInstance_PDF.post(`/file-render/pdf/prescription`,
                        pdfPrescriptionPayload(
                            selectedProgram,
                            prescriptionFormData,
                            prescriptionFormDetailsData,
                            prescriberDetails,
                            values,
                            dropdown?.Dosage,
                            prescAllDetails,
                            prescriptionAuthDetails,
                            prescriberId
                        ));

                    if (responsePDF?.data.statusCode === 200) {
                        const PdfBytes = responsePDF?.data.data?.pdf[0];
                        const binaryArr = atob(PdfBytes);
                        const arrayBuff = new Uint8Array(binaryArr.length);
                        for (let i = 0; i < binaryArr.length; i++) {
                            arrayBuff[i] = binaryArr.charCodeAt(i);
                        }
                        const blob = new Blob([arrayBuff], { type: 'application/pdf' });
                        titlepath(`${window.URL.createObjectURL(blob)}`, 'PatientPrescription.pdf', blob);
                    }else{
                        console.error(`PDF Api Error ${responsePDF}`);
                        setServerError('The system is not able to process the information at this time. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.');
                    }
                }

            } catch (err) {
                console.error(`error fetching dropdown ${err}`);
                setServerError('The system is not able to process the information at this time. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.');
            }
        }
    }
    const handleReturn=()=>{
        navigate('/prescriber/dashboard');
    }
    const handlePresInfo=()=>{
        window.open(selectedProgram.prescribing_info, 'LenalidomideREMS', 'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width=820,height=600'); return false;
    }
    const handlePrdSelectionOption=(value)=>{
        setValues((prev) => ({
            ...prev,
            prodSelection:value,
        }));
    }
    const handleDosageCheckbox =(name,e)=>{
        setValues((prev) => ({
            ...prev,
            [name]:e?.target?.checked,
        }))
    }
    const handleOnChange =(e)=>{
        const { name, value } = e.target;
            setValues((prev) => ({
                ...prev,
                [name]: value,
            }));
    }
    const handleDispenseInfo =(value)=>{
        setValues((prev) => ({
            ...prev,
            dispensigInfo: value,
        }));
    }
    return(
        <>
            <SessionTimeout />
            <div className={classes.mainContainer} ref={containerRef}>
            <div className={classes.headerContainer}>
                        {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                        <div className={classes.headerDivider} style={{
                            borderColor: `${ProgramConfig(selectedProgram.id).color}`
                        }}></div>
                    </div>
                <div className={classes.productHeader}>{t('prescriptionFormDetailsHeader')}</div>
                <div className={classes.headerNote}>
                    {t('prescriptionDosageHeader')}
                </div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                <div className={classes.contentContainer}>
                    {servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                    <div className={classes.containerDosage}>
                        <div className={classes.paddingClass}>
                            {t('presDosageFurtherInfo')}
                        </div>
                        {selectedProgram.id !== "rems_program_thalomid" ? 
                            (
                            <div className={classes.paddingClass}>
                                {`For further information on ${selectedProgram.label?.toUpperCase()}, please refer to the relevant `}
                                <span className={classes.prescInfo} onClick={handlePresInfo}>Prescribing Information</span>.
                            </div>
                            )
                            :
                            (<></>)
                        }
                        
                        
                        {dosagePrograms.length>0 &&
                        <div className={classes.paddingClass}>
                        <div>
                        <span className={classes.requiredPrd}>*</span> Product Selection
                        </div>
                        <div className={classes.radioBtnDispense}>
                            {dosagePrograms?.map((i: any,k)=>(
                                <>
                                    <input
                                        type="radio"
                                        value={i.name}
                                        name={i.name}
                                        id={i.name}
                                        checked={values['prodSelection'] === i.name}
                                        onChange={()=>  handlePrdSelectionOption(i.name)}
                                        className={classes.accentColor}
                                    />
                                    <span className={classes.prdSelSpan} dangerouslySetInnerHTML={{ __html:i.value}}></span>  
                                </>
                                
                            )
                            )}
                             
                           
                        </div>
                    </div>
                        }
                        
                        <div className={classes.paddingClass}>
                            <Row>
                                <Col span={9} className={classes.dosageCol}>
                                    <Row className={classes.dosageRow}>
                                        <Col span={8}>
                                            <div>
                                                <label className={classes.dosageLabel}>Dosage</label>
                                                <input
                                                    checked={values.dosage1}
                                                    id='dosage1'
                                                    name="dosage1"
                                                    type="checkbox"
                                                    className={classes.dosageCheckBox}
                                                     onChange={(e) =>
                                                        handleDosageCheckbox('dosage1',e)
                                                    } 
                                                   
                                                />
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div> 
                                                <span className={classes.required}>*</span>
                                                <select
                                                    className={classes.dosageDrpDwn}
                                                    name="dosageMg1"
                                                    id="dosageMg1"
                                                    onChange={handleOnChange}
                                                    value={values['dosageMg1']}
                                                        >
                                                    <option key={t('selectOne')} value={"select"}>
                                                        --
                                                    </option>
                                                    
                                                    {dropdown?.Dosage
                                                    ?
                                                    [
                                                        dropdown?.Dosage?.map((i) => (
                                                            <option key={i.dosageId} value={i.dosageDetails}>
                                                                {i.dosageDetails}
                                                            </option>
                                                        ))
                                                    ] :[]
                                                    }
                                                </select>
                                            
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div>
                                            <span className={classes.required}>*</span>
                                                <label className={classes.qtyLabel}>Qty</label>
                                                <input                                                   
                                                    type="text"
                                                    id="qty1"
                                                    name="qty1"
                                                    onChange={handleOnChange}
                                                    maxLength={4}
                                                    value={values.qty1}
                                                    className={classes.quantity}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={8}>
                                            <div>
                                               <label className={classes.dosageLabel}>Dosage</label>  
                                                <input
                                                    checked={values.dosage2}
                                                    id='dosage2'
                                                    name="dosage2"
                                                    type="checkbox"
                                                    className={classes.dosageCheckBox}
                                                    onChange={(e) =>
                                                        handleDosageCheckbox('dosage2',e)
                                                    } 
                                                     />
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div>
                                            {values.dosage2 && <span className={classes.required}>*</span>}
                                            <select
                                                className={classes.dosageDrpDwn}
                                                name="dosageMg2"
                                                id="dosageMg2"
                                                onChange={handleOnChange}
                                                value={values['dosageMg2']}
                                                    >
                                                <option key={t('selectOne')} value={"select"}>
                                                    --
                                                </option>
                                                
                                                {dropdown?.Dosage
                                                ?
                                                [
                                                    dropdown?.Dosage?.map((i) => (
                                                        <option key={i.dosageId} value={i.dosageDetails}>
                                                            {i.dosageDetails}
                                                        </option>
                                                    ))
                                                ] :[]
                                                }
                                            </select>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <div>
                                            {values.dosage2 && <span className={classes.required}>*</span>}
                                                <label className={classes.qtyLabel}>Qty</label>
                                                <input                                                   
                                                    type="text"
                                                    id="qty2"
                                                    name="qty2"
                                                    onChange={handleOnChange}
                                                    maxLength={4}
                                                    value={values.qty2}
                                                    className={classes.quantity}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={15} >
                                    <Row >
                                        <Col span={6} className={classes.directionsRow}>
                                            <div className={classes.directions} >
                                                <span className={classes.requiredOthr}>*</span>
                                                Directions
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            <TextArea
                                                rows={3}
                                                maxLength={200}
                                                name='directions'
                                                id='directions'
                                                value={values['directions']}
                                                onChange={handleOnChange}
                                                className={classes.directionsInput}
                                            />
                                        </Col>
                                    </Row>
                                  
                                </Col>
                            </Row>
                        </div>
                        <div className={classes.paddingClass}>
                            <div>
                                <span className={classes.requiredPrd}>*</span> Dispensing Information
                            </div>
                            <div className={classes.radioBtnDispense}>
                                <input
                                    type="radio"
                                    value="dispenseWritten"
                                    name="dispenseWritten"
                                    id="dispenseWritten"
                                    checked={values['dispensigInfo'] === 'dispenseWritten'}
                                    onChange={()=>  handleDispenseInfo("dispenseWritten")}
                                    className={classes.accentColor}
                                />
                                <span>{t('dispenseWritten')}</span>
                                <input
                                    type="radio"
                                    value="substitution"
                                    name="substitution"
                                    id="substitution"
                                    checked={values['dispensigInfo'] === 'substitution'}
                                    onChange={()=> handleDispenseInfo("substitution") }
                                    className={classes.shipDirect}
                                    
                                />
                                <span>{t('substitution')}</span>
                            </div>
                        </div>
                        <div className={classes.paddingClass}> 
                            <div>Authorization Number : {values.authNo}</div>                            
                        </div>
                        {
                            values.authNo !== "" ? 
                            getAuthMessage(values.authStatus ) ?
                            (
                                <div className={classes.authMessage}>
                                    {getAuthMessage(values.authStatus ) }
                                </div>
                            ) :(<></>)
                          
                            :
                            <div className={classes.authMessage}>
                               {t('authStatus_ PD0360')}
                            </div>
                         }
                        
                        <div className={classes.paddingClass}>
                            {t('printmessageDosage')}
                        </div>
                        <div>
                            <Row>
                                <Col span={4} className={classes.btnCol}>
                                    
                                    <div className={classes.dosageBtnPosition}>
                                        <Button
                                            clickFunction={handlePrev}
                                            className={getBtnClass(selectedProgram.id)}
                                            width={112}
                                            height={48}
                                            size={18}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.previous : buttonContent.en.previous}
                                        ></Button>
                                    </div>
                                </Col>
                                <Col span={4} className={classes.btnCol}>
                                   
                                    <div className={classes.dosageBtnPosition}>
                                        <Button
                                            clickFunction={handlePrint}
                                            className={getBtnClass(selectedProgram.id)}
                                            width={112}
                                            height={48}
                                            size={18}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.print : buttonContent.en.print}
                                        ></Button>
                                    </div>
                                </Col>
                                <Col span={10} className={classes.btnCol}>
                                    
                                    <div className={classes.dosageBtnPosition}>
                                        <Button
                                            clickFunction={handleReturn}
                                            className={getBtnClass(selectedProgram.id)}
                                            width={280}
                                            height={48}
                                            size={16}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.returnPrescDashboard : buttonContent.en.returnPrescDashboard}
                                        ></Button>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <p className={classes.adobeP}>{getAdobeText()}</p>
                                    <a
                                        href="https://get.adobe.com/reader/"
                                        target="blank"
                                        rel="noopener"
                                    >
                                        <div
                                            style={{ margin: '18px 0' }}
                                            className={classes.getAdobe}
                                        ></div>
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
}

export default PrescriptionFormDosage;