import { useEffect, useState } from 'react';
import classes from './patientFilterOptions.module.scss';
import findBtn from '../../../assets/images/btn_Find.png';
import resetBtn from '../../../assets/images/btn_Reset.png';
import { useAppDispatch, useAppSelector } from 'store';
import { prescriberPatientsSearch } from '../../../store/slices/appSlice';
import InvalidSearchError from '../InvalidSearchError';

const PatientFilterOptions = ({ setIsFiltered, isFindPatientTab, setIsSorted, isSorted }) => {
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    const [filterByText, setFilterByText] = useState('');
    const [isInvalidSearchError, setInvalidSearchError] = useState(false);
    const dispatch = useAppDispatch();

    const { currentUser } = useAppSelector((state) => state.login);
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails.prescriberId);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberNonPrescriberId = !nonPrescriber ? prescriberId : currentUser.nonPrescriberId;

    const filterOptions = [
        {
            filterVal: 0,
            filterText: 'Please Select...',
        },
        {
            filterVal: 1,
            filterText: 'Patient Last Name',
        },
        {
            filterVal: 2,
            filterText: 'Patient Identification Number',
        },
        {
            filterVal: 3,
            filterText: 'Prescriber Last Name',
        },
        {
            filterVal: 4,
            filterText: 'Authorization Number',
        },
    ];

    const invalidSearchError = () => {
        setInvalidSearchError(true);
    };

    let defaultFindPatientApiPayload: any = {
        pageNumber: 0,
        prescriberNonPrescriberId: prescriberNonPrescriberId,
        patientNameLast: null,
        assocPrescriberNameLast: null,
        assocPrescriberId: null,
        sortByField: null,
        sortByOrder: null,
        authorizationNumber: null,
        patientExtUniqueId: null,
        isPrescriber: !nonPrescriber,
    };

    const submitHandler = (e) => {
        e.preventDefault();

        if (filterByText === '' || !filterByText) {
            invalidSearchError();
            return;
        }

        let updatedPayload = { ...defaultFindPatientApiPayload };

        switch (parseInt(selectedFilterOption)) {
            case 1:
                updatedPayload = { ...defaultFindPatientApiPayload, patientNameLast: filterByText };
                break;
            case 2:
                updatedPayload = { ...defaultFindPatientApiPayload, patientExtUniqueId: filterByText };
                break;
            case 3:
                updatedPayload = { ...defaultFindPatientApiPayload, assocPrescriberNameLast: filterByText };
                break;
            case 4:
                updatedPayload = { ...defaultFindPatientApiPayload, authorizationNumber: filterByText };
                break;
            default:
                invalidSearchError();
                break;
        }
        setIsFiltered({isFilterApplied:true,filterPayload:updatedPayload});
        setIsSorted({...isSorted,isSorted:false});
    };

    useEffect(() => {
        if (isFindPatientTab) {
            setSelectedFilterOption('0');
            setFilterByText('');
        }
    }, [isFindPatientTab]);

    const handleSelectedFilterOption = (e) => {
        setSelectedFilterOption(e.target.value);
    };

    const resetFilters = () => {
        setSelectedFilterOption('0');
        setFilterByText('');
    };

    const resetFormHandler = (e) => {
        e.preventDefault();
        resetFilters();
        setIsFiltered({isFilterApplied:false,filterPayload:null});
        setIsSorted({...isSorted,isSorted:false});
    };

    const handleEnterPress = (e,type) => {
        if (e.key === 'Enter') {
            type == 'find' ? submitHandler(e) : resetFormHandler(e);
        }
    }

    return (
        <div className={classes.filterOptions}>
            {isInvalidSearchError && <InvalidSearchError closingPopUpHandler={setInvalidSearchError} />}
            <form onSubmit={submitHandler}>
                <div className="filterRowOne">
                    <p className="filterLabel">Filter by:</p>
                    <select value={selectedFilterOption} onChange={handleSelectedFilterOption}>
                        {filterOptions.map((each) => {
                            return (
                                <option key={each.filterVal} value={each.filterVal}>
                                    {each.filterText}
                                </option>
                            );
                        })}
                    </select>
                    <input
                        value={filterByText}
                        onChange={(e) => {
                            setFilterByText(e.target.value);
                        }}
                    />
                    <img
                        className="findBtn"
                        src={findBtn}
                        alt="find-button"
                        itemType="submit"
                        onClick={submitHandler}
                        tabIndex={0}
                        onKeyDown={(e)=>{handleEnterPress(e,'find')}}
                    />
                </div>
                <div className="filterRowTwo">
                    <img
                        className="resetBtn"
                        src={resetBtn}
                        alt="reset-button"
                        onClick={resetFormHandler}
                        tabIndex={0}
                        onKeyDown={(e)=>{handleEnterPress(e,'reset')}}
                    />
                </div>
            </form>
        </div>
    );
};

export default PatientFilterOptions;
