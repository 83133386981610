import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import {
    axiosInstance,
    axiosInstance_2,
    axiosInstance_PatientReactivation,
    axiosInstance_Patient_PartialPPAF
} from 'constants/Config/axiosConfig';
import { patientSearchFields } from 'constants/patientSearchFields';
import moment from 'moment';
import { useRef, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { patientCamundaStore, patientInformation, patientPrescriberStore, patientReactivationSpecialStore, patientReactivationStore, patientSearchStore, prescriberSurveySearchStore } from 'store/slices/appSlice';
import {
    isValidName,
    isValidPatientNum
} from 'utils/regex';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import classes from './PatientSearch.module.scss';

import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { getPatientDetailsEditPatient } from 'utils/getPatientDetailsEditPatient';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';
import {reloadFunction} from '../../utils/utilsCommon';


interface dropDownType {
    State?: { id: string; value: string }[] | null;
    'ICD-10': { id: string; value: string }[] | null;
}
const getDateArray = () => {
    return Array.from({ length: 31 }, (_, ind) => (ind + 1).toString().padStart(2, '0'));
};
const PatientSearch = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation()
    const record = sessionStorage.getItem('patientInfo') && JSON.parse(sessionStorage.getItem('patientInfo')||'')
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        patientIdNum: '',
        month: '',
        date: '',
        year: '',
        buttonName: record?.buttonName
    });
    const [oldRiskCategory, setOldRiskCategory] = useState('');

    const [servererror, setServerError] = useState('');
    const [nextpage, setNextPage] = useState('');
    const [continueError, setContinueError] = useState('')
    const [patientValidationError, setPatientValidationError] = useState('');
    const [riskError, setRiskError] = useState(false);
    const [dropdown, setDropdown] = useState<dropDownType>({ State: [], 'ICD-10': [] });
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const [allFunction, setAllFunction] = useState(false);
    const [locationButton, setLocationButton] = useState('');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { selectedProgram, patientInformation: patientFields, camundaInstance,prescriptionFormData } = useSelector((state: RootState) => state.app);
    const { currentUser, IsAuthenticated } = useAppSelector(
        (state) => state.login
    );
    // dispatch(camundaInstance);

    useEffect(() => {
        reloadFunction();
    }, []);
    dispatch(patientSearchStore(values));

    const handleOnChange = (e) => {
        servererror && setServerError('');
        patientValidationError && setPatientValidationError('');
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    
    const getProgramsName = (): string => {
             
        const isRegTrademark =
        ProgramConfig(selectedProgram.id)?.registeredTradermark === 'true'
            ? `<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`
            : '';
       return `${ProgramConfig(selectedProgram.id)?.title}${isRegTrademark}`
    };
    

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['month', 'date'].includes(currentFocus) &&
            inputElement.select();
        setError('');
        setCurrentFocus('');
        // const isDobValid = dobValidation();

    };

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const trimValues1 = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {

            newVals[field] = field === 'buttonName' ? newVals[field] : newVals[field].trim();
        });
        setValues(newVals);
        return newVals;
    };
    function hasSpecialChar(string) {
        var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        return regex.test(string);
    }


    let dateOfBirth = values.date + '-' + values.month.toUpperCase() + '-' + values.year;
    let remsProgram = selectedProgram.label;

    const checkPatientSearch = async (flow) => {
        let resp;
        resp = await axiosInstance_2.get(
            // `/diagnosis?patientGender=${getEngVal(values.sex)}&diagnosisCode=${values.diagnosis.split('-')[0]}`
            `/findPatientByLastName?lastName=${values.lastName.trim()}&patientExternalUniqueId=${values.patientIdNum}&dob=${dateOfBirth}&product=${remsProgram}`
        ).then(async (res) => {
            dispatch(patientPrescriberStore(res?.data));
            if (res.data.statusCode === 200) {
                if (flow === "EditPatient") {
                    const response = await axiosInstance.post(`/referencedata`, { keys: ['ICD-10'] });
                    const infoData = getPatientDetailsEditPatient(res.data.data, response.data.data['ICD-10'], 'EditPatient', 'en');
                    dispatch(patientInformation({ ...infoData, flowType: 'EditPatient' }));

                }
                sessionStorage.setItem('prevRoute',location.pathname)
                navigate(`/prescriber/prescriberpatientsearch`);
                return true;
            } 
           
            else if (res.data.data.errorCode === 'PD0330' ) {
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PD0330').replaceAll('{program}', getProgramsName()));
                }
            } else if (res.data.data.errorCode === 'PM0205') {
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PM0205').replaceAll('{program}', getProgramsName()));
                }
            }
            else if(res.data.data.errorCode === 'PV0450' )
             {
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));
                }
                // setContinueError(t('error_PD0450').replaceAll('{program}', getProgramsName()));

            } 
            else if(res.data.data.errorCode === 'PV0405' )
            {

                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PD0405').replaceAll('{program}', getProgramsName()));
                }
            }

            else {
                return false;
            }
        }).
            catch((error) => {
                if (error?.response?.status === 400) {
                    let errorMsg = '';
                    if (
                        error.response.data.data.errorMesssage?.code === 10133 ||
                        error.response.data.data.errorMesssage[0]?.code === 10143
                    ) {

                        if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                            setServerError(t('error_PD0330').replaceAll('{program}', getProgramsName()));
                        }
                    }
                }
               

            });
    }

    const PatientReactivation = async (Reactivate) => {

        const payload = {
            patientExternalUniqueId: values.patientIdNum,
            patientLastName: values.lastName.trim(),
            patientDob: dateOfBirth,
            patientProduct: remsProgram,
        };
        let resp;
        resp = await axiosInstance_PatientReactivation.post(
            `/validate-reactivation`,payload

        ).then((res) => {
            let apiResponse = res?.data
            if (res.data.statusCode === 200) {
                dispatch(patientReactivationStore(apiResponse));
                dispatch(patientReactivationSpecialStore([]));
                sessionStorage.setItem('prevRoute',location.pathname)
                navigate("/prescriber/patientreactivation");
                // return true;
            }
            else if (res.data.statusCode === 204) {
                dispatch(patientReactivationSpecialStore(apiResponse));
                dispatch(patientReactivationStore([]));
                sessionStorage.setItem('prevRoute',location.pathname)
                navigate("/prescriber/patientreactivation");
            }
           
            else if (res.data.data.errorCode === 'PV0450') {
                dispatch(patientReactivationSpecialStore([]))
                setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));
                // setContinueError(t('error_PD0450').replaceAll('{program}', getProgramsName()));

            }
            else {
                return false;
            }
        }).
            catch((error) => {
                if (error?.message === 'Request failed with status code 500') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                    }
                    let errorMsg = '';
                }
                else if (error?.message === 'Request failed with status code 404') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                    }


                }
                else {
                    console.error("error in Patient reactivation Flow", error);
                }
            });




    };

    const patientCamunda = async () => {
        
        const constructDOB = `${values.date}-${values.month.toUpperCase()}-${values.year.trim()}`;
        // const constructDOB = `${values.date}/01/${values.year.trim()}`;
        const payload = {
            patientExternalUniqueId: values.patientIdNum.trim(),
            patientLastName: values.lastName.trim(),
            patientDob: constructDOB.trim(),
            patientProduct: remsProgram,
        };
        let response;
        response = await axiosInstance_Patient_PartialPPAF
            .post('/get-patient-from-camunda-instance', payload)
            .then((res) => {
                if (res?.status === 200) {
                sessionStorage.setItem('prevRoute',location.pathname)
                    navigate('/prescriber/patientcamunda');
                    dispatch(patientCamundaStore(res?.data?.data))
                }
                
                else if
                    (res.data.data.errorCode === 'PV0450' ){
                    setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));

                } else {
                    console.error("error")
                }
            })
            .catch((err) => {
                if (err?.message === 'Request failed with status code 400') {
                    let errorMsg = '';
                    if (err?.response?.data?.statusCode === 1000) {
                        if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                            setServerError(t('error_PM0205'));
                        }
                        else if (!hasSpecialChar(values.patientIdNum)) {
                            setServerError(t('error_PM0205'));
                        }
                       }
                } else {
                    console.error("error in Patient Camunda Flow", err)
                }
            });

        return response;
    };


    const handleSubmit = async () => {
        await trimValues1()
        setServerError('');
        setRiskError(false);
        const sysDate = new Date()//.toISOString().split('T')[0];
        const { month, date, year } = values;
        const dob = month + '/' + date + '/' + year;
        const format = 'MMM/DD/YYYY';
        const reqFields = patientSearchFields.filter(
            (item) =>
                !(item.name === 'otherDiag' && values['diagnosis'] !== '000-OTHER') &&
                !(['surgical', 'natural', 'menstrating'].includes(item.name) && values['sex'] === t('male'))
        );

        for (let i of reqFields) {
            if (
                values[i.name].trim() === ''


            ) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (['lastName'].includes(i.name) && !isValidName(values[i.name])) {
                setCurrentFocus(i.name.trim());
                setError(t(i.errMsg));
                scrollToView();
                return;
            } else if (i.name === 'year' && !isValidPatientNum(values.year, 4)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            }

            else if (Number(year) <= 1900) {
                setError(t('patientDOBCombinationError'));
                setCurrentFocus('month');
                scrollToView();
                // return;
            }

            else if (moment(dob, format) > moment(sysDate) || !moment(dob, format, true).isValid()) {
                setError(t('patientDOBError'));
                setCurrentFocus('month');
                scrollToView();
            }

            else if (i.name === 'patientIdNum' && !isValidPatientNum(values.patientIdNum, 9)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();

                return;
            }
            else {
                setError('');
                setAllFunction(true);
            }
        }



    };
    const checkAPICall = () => {
        if (values.lastName === '' || values.patientIdNum === '' || values.date === '' || values.month === '' || values.year === '' || values.year <= '1900') {
            return false;
        }

        return true;

    }
    let source="Survey"
    const nonPrescriberSearch = async () => {
        let resp;
        resp = await axiosInstance_2.get(
            // `/validate-reactivation?patientExternalUniqueId=${values.patientIdNum}&product=${remsProgram}`
            `/findPatientByLastName?lastName=${values.lastName.trim()}&patientExternalUniqueId=${values.patientIdNum}&dob=${dateOfBirth}&product=${remsProgram}&source=${source}`

        ).then((res) => {
            let apiResponse = res?.data
            if (res.data.statusCode === 200) {
                dispatch(prescriberSurveySearchStore(apiResponse));
                dispatch(patientReactivationSpecialStore([]));
                sessionStorage.removeItem('patientInfo')
                navigate("/prescriber/prescribersurveypatientsearch");
                return true;
            }
           
            else if ((res.data.statusCode === 204 && res.data.data.errorCode === 'PD0330') ) {
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PD0330').replaceAll('{program}', getProgramsName()));
                }
            }
            else if (res.data.statusCode === 204) {
                dispatch(patientReactivationSpecialStore(apiResponse));
                dispatch(prescriberSurveySearchStore([]));
                sessionStorage.removeItem('patientInfo')
                navigate("/prescriber/prescribersurveypatientsearch");
            }
           
            else if (res.data.data.errorCode === 'PV0450'){
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));
                }
            }
            else {
                return false;
            }
        }).
            catch((error) => {
                if (error?.message === 'Request failed with status code 500') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                        let errorMsg = '';
                    }
                }
                else if (error?.message === 'Request failed with status code 404') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                    }


                }
                else {
                    console.error("error in Patient reactivation Flow", error)
                }
            });

    };

    const searchHandler = () => {
        if (IsAuthenticated && error === '') {
            
            let element = record?.buttonName;
            if(prescriptionFormData?.formType){
                element =prescriptionFormData?.formType
            }
            let checkRoute = !element && record?.split("/")[2]
            
            if (element) {
                switch (element || 'no data found') {
                    case "WorkPartial":
                        const val3 = checkAPICall();
                        val3 && patientCamunda();
                        break;
                    case "ViewSubmit":
                        const val = checkAPICall();
                        val && checkPatientSearch('ViewSubmit');
                        break;
                    case 'StandardForm':
                    case 'VHAForm':
                    case "EditPatient":
                        const val1 = checkAPICall();
                        val1 && checkPatientSearch(element);
                        break;
                    case "Reactivate":
                        const val2 = checkAPICall();
                        val2 && PatientReactivation('Reactivate');
                        break;
                    default:
                        return '';
                }
            }
            else if (checkRoute) {

                switch (checkRoute || 'no data found') {
                    case "partiallyCompletedPPAFSearch":
                        trimValues1();
                        const val3 = checkAPICall();
                        val3 && patientCamunda();
                        break;
                    case 'submittedPPAFSearch':
                        trimValues1();
                        const val = checkAPICall();
                        val && checkPatientSearch('ViewSubmit');
                        break;
                    case "patientAddressPPAFSearch":
                        trimValues1();
                        const val1 = checkAPICall();
                        val1 && checkPatientSearch('EditPatient');
                        break;
                    case "submittedPpafCreatedSearch":
                        trimValues1();
                        const val2 = checkAPICall();
                        val2 && PatientReactivation('Reactivate');
                        break;
                    default:
                        trimValues1();
                        const val4 = checkAPICall()
                        val4 && nonPrescriberSearch();
                        break;



                }
            }

            else {
                const val5 = checkAPICall()
                val5 && nonPrescriberSearch();
            }
        }
    }

    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer} ref={containerRef}>
            <div className={classes.headerContainer}>
                    {selectedProgram.id && <span className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></span>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                <div className={classes.contentContainer}>
                    {!error && servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                    {patientValidationError && (
                        <div
                            className={AlertClass.container}
                            style={{ margin: '10px 30px 5px 30px' }}
                            dangerouslySetInnerHTML={{ __html: patientValidationError }}
                        ></div>
                    )}
                    {riskError && (
                        <div className={classes.riskError}>
                            {t('patientRiskCategoryError1')}
                            <span dangerouslySetInnerHTML={{ __html: mainMenu(selectedProgram.id) }}></span>
                            {t('patientRiskCategoryError2')}
                        </div>
                    )}
                    <div style={{ paddingTop: 10 }}></div>

                    <Input
                        type="text"
                        name="lastName"
                        label={t('lastName')}
                        onChangeHandler={handleOnChange}
                        isRequired={true}
                        maxLength={30}
                        value={values['lastName']}
                    />
                    <div className={classes.helpLink_postion}>
                        <Input
                            type="text"
                            name="patientIdNum"
                            maxLength={9}
                            label={t('patientPin')}
                            isRequired={true}
                            helpText={t('helpTextPatientPin')}
                            onChangeHandler={handleOnChange}
                            value={values['patientIdNum']}
                        />
                    </div>
                    <div className={inputStyles.container}>
                        <div className={inputStyles.label}>
                            <span className={inputStyles.mandatory}>{t('DOB')}:</span>
                        </div>

                        <div className={inputStyles.input}>
                            <div>
                                <select
                                    style={{ width: 48 }}
                                    name="month"
                                    id="month"
                                    onChange={handleOnChange}
                                    value={values['month'] ? values['month'] : '---'}
                                >
                                    <option key={t('selectOne')} value={t('selectOne')}>
                                        ---
                                    </option>
                                    {moment.monthsShort().map((i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    style={{ width: 38, margin: '0 5px' }}
                                    name="date"
                                    id="date"
                                    onChange={handleOnChange}
                                    value={values['date'] ? values['date'] : '--'}
                                >
                                    <option key={t('selectOne')} value={t('selectOne')}>
                                        --
                                    </option>
                                    {getDateArray()?.map((i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    name="year"
                                    id="year"
                                    onChange={handleOnChange}
                                    maxLength={4}
                                    style={{ width: 60 }}
                                    value={values['year']}
                                    tabIndex={0}
                                />
                                <p className={inputStyles.helpText}>{t('helpTextDob')}</p>
                            </div>
                        </div>
                    </div>

                     <div className={classes.btnMargins}>
                        <Button
                                clickFunction={() => { handleSubmit();searchHandler(); }}
                                className={getBtnClass(selectedProgram.id)}
                                width={178}
                                height={42}
                                size={16}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.patientSearch : buttonContent.en.patientSearch}
                        ></Button>
                    </div>
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );

};


export default PatientSearch;
