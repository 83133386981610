import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import { useAppDispatch, useAppSelector } from '../../store';
import PageBackground from '../../components/PageBackground';
import classes from './accountConfirmation.module.scss';
import {
    changeHelpContentForAccount,
    createUserAccount,
    fetchCurrentPrescriber,
    resetUserAcc,
    saveNonPrescriber,
    setAccessCodeAPI,
} from '../../store/slices/appSlice';
import { staticFieldValue } from '../../constants/createAccountFormFields';
import ErrorModal from '../../components/ErrorModal';
import Alert from '../../components/Alert';
import Success from '../../components/Success';
import { accountConfirmationContent } from '../../assets/objects/accountConfirmContent';
import {
    axiosInstanceNonPrescriber,
    axiosInstancePrescriber,
    axiosInstancePrescriberUnsecure,
} from 'constants/Config/axiosConfig';
import { isValidNum } from 'utils/regex';
import { Role, RoleByGroup } from 'constants/Config/appConfig';
import { getUserPayload } from './../../utils/createUser';
import { resetApp, setPrescriberEnrollment } from 'store/slices/appSlice';
import {
    digitalSignatureAuth,
    setLogOut,
    setLoginDetails,
    setSysytemID,
    setaccessCodeFlag,
    updateSystemId,
} from 'store/slices/loginSlice';
import SessionTimeout from 'components/SessionTimeout';
import { authenticateErrCodeMapper, cognitoLoginErrMsg, congitoResMapper } from 'utils/congitoResMapper';
import { useTranslation } from 'react-i18next';
import { encryptData } from 'utils/encryptionWrapper';
import { reloadFunction } from 'utils/utilsCommon';

const AccountConfirmation = () => {
    const navigate = useNavigate();
    const accountValues = sessionStorage.getItem('prescriberAccnt')
        ? JSON.parse(sessionStorage.getItem('prescriberAccnt') || '')
        : null;
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [accessCodeErr, setAccessCodeErr] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [savedData, setSavedData] = useState<any>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [next, setNext] = useState(false);
    const [userCreateResponse, setuserCreateResponse] = useState<any>({});
    const { passwordField, accFormDetails, role, errorCreateAccount } = useAppSelector((state) => state.app);
    const { t } = useTranslation();

    const [currentFocus, setCurrentFocus] = useState('');
    const { currentUser, IsAuthenticated } = useAppSelector((state) => state.login);
    const [IsCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [loader,setLoader]=useState(false);
    const [values, setValues] = useState({
        username: '',
        password: '',
    });
    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };
    let prescriberId = '';

    const validatePrescriber = async () => {
        const reqBody = buildRequestBody();
        if (selectedOption === '') {
            scrollToView();
            setError(t('pleaseSelectRadio'));

            return;
        } else if (selectedOption === 'accesscode_num') {
            if (accessCode === '') {
                setError(t('enterAccessCode'));
            } else {
                let isValid = isValidNum(accessCode);
                if (isValid) {
                    const payload = {
                        username: currentUser.userinfo.username ? currentUser.userinfo.username : reqBody.userName,
                        first_name: currentUser.firstName ? currentUser.firstName : reqBody.firstName,
                        last_name: currentUser.lastName ? currentUser.lastName : reqBody.lastName,
                        email: reqBody.email ? reqBody.email : currentUser.userinfo.email,
                        phone_number: currentUser.userinfo.phone_number
                            ? currentUser.userinfo.phone_number.replace(/^\+1\s*|\s*/g, '')
                            : null,
                        address: reqBody.officeAddress ? reqBody.officeAddress : currentUser.userinfo.address.formatted,
                        city: reqBody.city ? reqBody.city : currentUser.userinfor['custom:city'],
                        state: reqBody.state ? reqBody.state : currentUser.userinfo['custom:state'],
                        zip_code: currentUser.userinfo['custom:zip_code']
                            ? currentUser.userinfo['custom:zip_code']
                            : reqBody.zipCode,
                        country_code: null,
                        customer_id: null,
                        group_tag: reqBody.groupTag ? reqBody.groupTag : currentUser.userinfo['custom:group_tag'],
                        language: reqBody.language ? reqBody.language : currentUser.userinfo['custom:language'],
                        system_Id: +accessCode,
                        prescriber_user_id: currentUser.userinfo.username
                            ? currentUser.userinfo.username
                            : reqBody.userName,
                    };
                    try {
                        const response: any = await axiosInstancePrescriberUnsecure.post(
                            '/validate-access-code',
                            payload
                        );

                        if (response.data?.statusCode !== 200) {
                            setAccessCodeErr(response.response.data.message);
                            setAccessCode('');
                            setSelectedOption('');
                        } else {
                            if (IsAuthenticated === false) {
                                const user_payload = getUserPayload({ ...reqBody, role: RoleByGroup.Prescriber });
                                dispatch(createUserAccount(user_payload)).then(async (res: any) => {
                                    if (res?.payload?.authResult?.AuthenticationResult?.AccessToken) {
                                        const userDetails = congitoResMapper(
                                            res?.payload?.authResult?.AuthenticationResult || {}
                                        );
                                        dispatch(
                                            setAccessCodeAPI({
                                                systemId: accessCode,
                                                onlineUserId: userDetails?.currentUser?.onlineUserId,
                                                token: res?.payload?.authResult?.AuthenticationResult?.AccessToken,
                                            })
                                        ).then((resp: any) => {
                                            if (resp?.payload?.data?.statusCode === 200) {
                                                dispatch(setLoginDetails(res?.payload?.authResult));
                                                dispatch(setSysytemID(accessCode));
                                                dispatch(changeHelpContentForAccount(true));
                                                dispatch(setaccessCodeFlag(true));
                                                setNext(true);
                                                dispatch(updateSystemId({ token: res?.payload?.authResult?.AuthenticationResult?.AccessToken, access: accessCode }))
                                            } else {
                                                sessionStorage.removeItem('prescriberAccnt');
                                                navigate('/prescriber/systemError', { replace: true });
                                            }
                                        });
                                    }
                                });
                            } else {
                                dispatch(updateSystemId({ token: currentUser?.accessToken, access: accessCode })).then(
                                    (res: any) => {
                                        dispatch(
                                            setAccessCodeAPI({
                                                systemId: accessCode,
                                                onlineUserId: currentUser?.onlineUserId,
                                                token: currentUser?.accessToken,
                                            })
                                        ).then((resp: any) => {
                                            if (resp?.payload?.data?.statusCode === 200) {
                                                dispatch(setSysytemID(accessCode));
                                                dispatch(changeHelpContentForAccount(true));
                                                dispatch(setaccessCodeFlag(true));
                                                setNext(true);
                                            } else {
                                                sessionStorage.removeItem('prescriberAccnt');
                                                navigate('/prescriber/systemError', { replace: true });
                                            }
                                        });
                                    }
                                );
                            }
                        }
                    } catch (err) {
                        console.error(`error ${err}`);
                    }
                } else {
                    setError(t('enterAccessCode'));
                }
            }
            scrollToView();
            return;
        } else if (selectedOption === 'non-prescriber') {
            const user_payload = getUserPayload({ ...reqBody, role: RoleByGroup.Prescriber });
            accountValues && dispatch(createUserAccount(user_payload));
            if (IsAuthenticated) {
                sessionStorage.removeItem('prescriberAccnt');
                navigate('/prescriber/Home', { replace: true });
            }
        } else {
            const user_payload = getUserPayload({ ...reqBody, role: RoleByGroup.Prescriber });
            accountValues && dispatch(createUserAccount(user_payload));
            if (IsAuthenticated) {
                dispatch(changeHelpContentForAccount(true));
                setNext(true);
            }
        }
    };
    const buildRequestBody = () => {
        return {
            userName: accFormDetails.userName.trim() || null,
            password: passwordField.password || null,
            firstName: accFormDetails.firstName.trim() || null,
            lastName: accFormDetails.lastName.trim() || null,
            officeAddress: accFormDetails.officeAddress.trim() || null,
            city: accFormDetails.city || null,
            state: accFormDetails.state || null,
            zipCode: accFormDetails.zipCode || null,
            email: accFormDetails.email.trim() || null,
            phoneNumber: accFormDetails.phoneNumber ? `+1${accFormDetails.phoneNumber.replace(/[ -]/g, '')}` : null,
            groupTag:
                role === Role.Prescriber
                    ? RoleByGroup['Prescriber']
                    : role === Role.NonPrescriber
                    ? RoleByGroup['NonPrescriber']
                    : null,
            language: 'en-US',
            securityAnswer: accFormDetails.securityAnswer.trim() || null,
            securityQuestion: accFormDetails.securityQuestion || null,
            accessCode: accessCode ? accessCode : null,
        };
    };
    const restDigitalSignature = () => {
        setValues({ username: '', password: '' });
        setIsCheckBoxChecked(false);
    };
    const digitalSignature = async () => {
        try {
            const response: any = await dispatch(digitalSignatureAuth(values));
            if (response?.payload?.AuthenticationResult?.AccessToken) {
                const authRes: any = congitoResMapper(response?.payload?.AuthenticationResult);
                if (authRes?.currentUser?.groupTag === RoleByGroup.Prescriber) {
                    const payload = authRes?.currentUser?.systemId
                        ? {
                              prescriberId: authRes?.currentUser?.systemId,
                          }
                        : {
                              onlineUserId: authRes?.currentUser?.userinfo?.username,
                          };
                    const findprescriber = await axiosInstanceNonPrescriber.post(
                        '/v1/prescriber/findprescriber',
                        encryptData(payload),
                        {
                            headers: {
                                Authorization: `Bearer ${response?.payload?.AuthenticationResult?.AccessToken}`,
                                'X-Encrypted': 'true',
                                'Content-Type': 'text/plain',
                            },
                        }
                        // { headers: { Authorization: `Bearer ${response?.payload?.AuthenticationResult?.AccessToken}` },'X-Encrypted' : 'true',
                        // 'Content-Type' : 'text/plain' }
                    );
                    if (findprescriber.status === 200) {
                        const enrollmentPayload: any[] = findprescriber.data?.data?.prescriberRegStatuses;
                        if (
                            enrollmentPayload?.filter((x) => x.registrationStatus.registrationStatus === 'Active')
                                .length > 0
                        ) {
                            const _prescriberId: number = findprescriber?.data?.data?.prescriberId;
                            let arrayEnrolledProgram: any = [];
                            enrollmentPayload.forEach((item) => {
                                let EnrolledProgram = {
                                    productName: item.id.productCountryId.product.productName,
                                    productCountryId: item.id.productCountryId.productCountryId,
                                    status: item.registrationStatus.registrationStatus,
                                };
                                arrayEnrolledProgram.push(EnrolledProgram);
                            });
                            let payload = [...arrayEnrolledProgram];
                            dispatch(setPrescriberEnrollment(payload));
                            prescriberId = _prescriberId.toString();
                            return true;
                        } else {
                            setAccessCodeErr(accountConfirmationContent.validationMessage.needToActiveProgram);
                            restDigitalSignature();
                            return false;
                        }
                    } else {
                        setAccessCodeErr(accountConfirmationContent.validationMessage.needToActiveProgram);
                        restDigitalSignature();
                        return false;
                    }
                } else {
                    setAccessCodeErr(accountConfirmationContent.validationMessage.nonprescriber);
                    restDigitalSignature();
                    return false;
                }
            } else if (response?.payload?.message) {
                setAccessCodeErr(cognitoLoginErrMsg(response?.payload?.message));
                restDigitalSignature();
                
                return false;
            } else {
                //403
                // setAccessCodeErr(response.data.message);
                // state.error = { message: response.data?.message };
                restDigitalSignature();
                return false;
            }
        } catch (err) {
            console.error(`error ${err}`);
            return false;
        }
    };
    const bulidSaveNonPrescriberPayload = () => {
        const prescriberIds: any = [parseFloat(prescriberId)];
        return {
            modifiedBy: 'SYSTEM',
            createdBy: 'SYSTEM',
            nonPrescriberFirstName: accFormDetails.firstName.trim() || null,
            nonPrescriberLastName: accFormDetails.lastName.trim() || null,
            onlineUserid: accFormDetails.userName.toString().toLowerCase() || null,
            nonPrescriberMiddleInitial: '',
            nonPrescriberEmailAddress: accFormDetails.email.trim() || null,
            nonPrescriberExtUniqueId1: null,
            nonPrescriberLocations: [
                {
                    modifiedBy: 'SYSTEM',
                    createdBy: 'SYSTEM',
                    id: null,
                    nonPrescriberLocationActivation: null,
                    nonPrescriberLocationDeactivationDate: null,
                    nonPrescriberOfficeName: 'Primary',
                    phoneNumber: accFormDetails.phoneNumber
                        ? `+1${accFormDetails.phoneNumber.replace(/[ -]/g, '')}`
                        : null,
                    faxNumber: null,
                    emailAddress: accFormDetails.email.trim() || null,
                    addressLine1: accFormDetails.officeAddress.trim() || null,
                    addressLine2: null,
                    addressLine3: null,
                    addressLine4: null,
                    locationVerified: null,
                    nonPrescriberCityNotVerified: null,
                    stateCodeNotVerified: null,
                    primaryLocation: 'Y',
                    postalCode: {
                        postalCodeId: null,
                        postalCode1: accFormDetails.zipCode || null,
                        city: accFormDetails.city || null,
                        state: {
                            stateId: null,
                            stateCode: accFormDetails.state || null,
                            stateName: null,
                        },
                    },
                },
            ],
            nonPrescriberTitleId: null,
            changeReason: null,
            deactivationDate: null,
            country: {
                countryId: 1,
                countryName: 'United States',
                countryCode: 'US',
            },
            language: {
                languageId: 1,
                iso_639_1_code: 'en',
                languageName: 'English',
            },
            prescriberIds: prescriberIds,
        };
    };

    const validateNonPrescriber = async () => {
        if (!IsCheckBoxChecked) {
            setError(accountConfirmationContent.validationMessage.termsandcondition);
            setCurrentFocus('digSignature');

            scrollToView();
            setLoader(false);
            return;
        } else if (values.username.length === 0) {
            setError(accountConfirmationContent.validationMessage.userName);
            setCurrentFocus('username');
            scrollToView();
            setLoader(false);
            return;
        } else if (values.password.length === 0) {
            setError(accountConfirmationContent.validationMessage.password);
            setCurrentFocus('password');
            scrollToView();
            setLoader(false);
            return;
        } else {
            try {
                const IsValidSignature = await digitalSignature();
                if (IsValidSignature) {
                    const reqBody = buildRequestBody();
                    const user_payload = getUserPayload({ ...reqBody, role: RoleByGroup.NonPrescriber, prescriberId });
                    accountValues &&
                        (await dispatch(createUserAccount(user_payload)).then((response) => {
                            dispatch(saveNonPrescriber(bulidSaveNonPrescriberPayload()));
                            setuserCreateResponse(response?.payload);
                        }));
                    setNext(true);
                    dispatch(changeHelpContentForAccount(true));
                    setLoader(false);
                }
                setLoader(false);
            } catch (err) {
                console.error(`error ${err}`);
                setLoader(false);
            }
        }
    };
    const handleContinue = async () => {
        if (role === Role.Prescriber) {
            validatePrescriber();
        } else if (role === Role.NonPrescriber) {
            setLoader(true);
            validateNonPrescriber();
        }
    };

    const handleErrorBtn = () => {
        if (error === t('enterAccessCode')) {
            const inputElement = document.getElementById('accesscode_num') as HTMLInputElement;
            inputElement.focus();
            inputElement.select();
        }
        /* else{
            const inputElement= document?.getElementById(currentFocus) as HTMLInputElement
            inputElement.focus();
           
        }  */
        setError('');
    };

    const onValueChange = (e) => {
        setError('');
        setAccessCodeErr('');
        setAccessCode('');
        setSelectedOption(e.target.value);
    };

    const onHandleAccess = (e) => {
        setAccessCode(e.target.value);
    };

    const handleRevise = () => {
        sessionStorage.removeItem('prescriberAccnt');
        navigate('/prescriber/createaccount', { replace: true });
    };

    const handleSuccessCtn = () => {
        sessionStorage.removeItem('prescriberAccnt');
        if (role === 'prescriber') {
            switch (selectedOption) {
                case 'no_accesscode':
                    dispatch(resetApp());
                    dispatch(setLogOut());
                    navigate('/prescriber/login', { replace: true });
                    break;
                case 'accesscode_num':
                    navigate('/prescriber/LoginHome', { replace: true });
                    break;
                default:
                    navigate('/prescriber/Home', { replace: true });
                    break;
            }
        } else {
            const userDets = congitoResMapper(userCreateResponse?.authResult?.AuthenticationResult || {});
            dispatch(setLoginDetails({data:userCreateResponse?.authResult,role:"prescriber"}));
            const payload = { prescriberId: userDets?.currentUser?.systemId };
            userDets?.currentUser?.systemId && dispatch(fetchCurrentPrescriber(payload));
            navigate('/prescriber/LoginHome', { replace: true });
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetUserAcc());
            dispatch(changeHelpContentForAccount(false));
        };
    }, [dispatch]);

    useEffect(() => {
        if (errorCreateAccount.message === 'Success' && ['non-prescriber', 'no_accesscode'].includes(selectedOption)) {
            dispatch(changeHelpContentForAccount(true));
            setNext(true);
        }
    }, [dispatch, errorCreateAccount, selectedOption]);

    useEffect(() => {
        const storedData = sessionStorage.getItem('accountData');
        if (storedData) {
            const item = JSON.parse(storedData);
            setSavedData(item);
        }
    }, []);

    useEffect(() => {
        reloadFunction();
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value.trim(),
        }));
    };
    const getSuccessMessage = (selectedOption) => {
        switch (selectedOption) {
            case 'no_accesscode':
                return accountConfirmationContent.successMessage.prescriberNoAccesscode;
            case 'accesscode_num':
                return accountConfirmationContent.successMessage.prescriberWithAccessCode;
            default:
                return accountConfirmationContent.successMessage.prescriber.replace(
                    '{0}',
                    accFormDetails.userName || currentUser?.userinfo?.username
                );
        }
    };

    return (
        <>
            <div ref={containerRef}>
                {!next ? (
                    <>
                        <SessionTimeout />

                        <PageBackground
                            label="User Name Creation Confirmation"
                            btn={true}
                            handleClick={handleContinue}
                            handleReviseBtn={IsAuthenticated ? undefined : handleRevise}
                            signOn={role === Role.NonPrescriber ? true : false}
                            loader={loader}
                        >
                            {role === Role.NonPrescriber ? (
                                <>
                                    <div className={classes.note}>
                                        Fields marked with an <span className={classes.required}>*</span> are required.
                                    </div>
                                </>
                            ) : (
                                <div></div>
                            )}

                            <div className={classes.contentContainer}>
                                <div className={classes.firstContainer}>
                                    <div className={classes.subHeader}>
                                        {role === Role.Prescriber
                                            ? accountConfirmationContent.title.prescriber
                                            : accountConfirmationContent.title.Nonprescriber}
                                    </div>
                                    {staticFieldValue.map((i) => (
                                        <Input
                                            key={i.name}
                                            noInput={true}
                                            valueExist={accFormDetails[i.name] || savedData[i.name]}
                                            type="text"
                                            name={i.name}
                                            label={i.label}
                                        />
                                    ))}
                                </div>
                                <div className={classes.secondContainer}>
                                    {(accessCodeErr ||
                                        (errorCreateAccount.message !== 'Success' &&
                                            errorCreateAccount.message !== null)) && (
                                        <Alert
                                            content={accessCodeErr || errorCreateAccount.message}
                                            styles={{ margin: '10px 20px 5px 20px' }}
                                        />
                                    )}

                                    {role === Role.Prescriber ? (
                                        <>
                                            <div className={`${classes.flex} ${classes.pd5}`}>
                                                <input
                                                    type="radio"
                                                    value="accesscode_num"
                                                    checked={selectedOption === 'accesscode_num'}
                                                    onChange={onValueChange}
                                                />
                                                <div className={classes.ml}>
                                                    <span className={classes.bold}>
                                                        {accountConfirmationContent.body[0].content.label}
                                                    </span>
                                                    <input
                                                        name="accesscode_num"
                                                        id="accesscode_num"
                                                        value={accessCode}
                                                        type="text"
                                                        size={20}
                                                        maxLength={7}
                                                        autoComplete="off"
                                                        onChange={onHandleAccess}
                                                        disabled={selectedOption !== 'accesscode_num'}
                                                        style={{ marginLeft: '22px' }}
                                                    />
                                                    <div className={classes.slimPad_T}>
                                                        {accountConfirmationContent.body[0].content.subText}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${classes.flex} ${classes.pd5}`}>
                                                <input
                                                    type="radio"
                                                    value="no_accesscode"
                                                    checked={selectedOption === 'no_accesscode'}
                                                    onChange={onValueChange}
                                                />
                                                <div className={classes.ml}>
                                                    <span className={classes.bold}>
                                                        {accountConfirmationContent.body[1].content.label}
                                                    </span>
                                                    <div className={classes.slimPad_T}>
                                                        {accountConfirmationContent.body[1].content.subText}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${classes.flex} ${classes.pd5}`}>
                                                <input
                                                    type="radio"
                                                    value="non-prescriber"
                                                    checked={selectedOption === 'non-prescriber'}
                                                    onChange={onValueChange}
                                                />
                                                <div className={classes.ml}>
                                                    <span className={classes.bold}>
                                                        {accountConfirmationContent.body[2].content.label}
                                                    </span>
                                                    <div className={classes.slimPad_T}>
                                                        {accountConfirmationContent.body[2].content.subText}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <p className={classes.digital_info}>
                                                {accountConfirmationContent.digitalSignature.information}
                                            </p>
                                            <div className={classes.subHeader}>
                                                <p className={classes.digital_sign}>Digital Signature:</p>
                                            </div>

                                            <div className={classes.digital_wrapper}>
                                                <div className={classes.digital_content}>
                                                    <div className={classes.checkbox_wrapper}>
                                                        <div className={classes.checkbox_content}>
                                                            <span style={{ color: '#7b0305', marginRight: '4px' }}>
                                                                *
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                onChange={() =>
                                                                    setIsCheckBoxChecked(!IsCheckBoxChecked)
                                                                }
                                                                checked={IsCheckBoxChecked}
                                                                id="digSignature"
                                                                name="digSignature"
                                                            />
                                                        </div>

                                                        <div className={classes.digital_term}>
                                                            {accountConfirmationContent.digitalSignature.Terms}
                                                        </div>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        name="username"
                                                        label="User Name"
                                                        onChangeHandler={onChangeHandler}
                                                        isRequired={true}
                                                        maxLength={20}
                                                        value={values.username?.trim()}
                                                        defaultValue={values.username?.trim()}
                                                    />
                                                    <Input
                                                        type="password"
                                                        value={values.password?.trim()}
                                                        defaultValue={values.password?.trim()}
                                                        name="password"
                                                        label="Password"
                                                        onChangeHandler={onChangeHandler}
                                                        isRequired={true}
                                                        maxLength={20}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </PageBackground>

                        {error && (
                            <ErrorModal error={error} handleContinue={handleErrorBtn} classNamesAccCreation={true} />
                        )}
                    </>
                ) : (
                    <Success label={'User Name Creation Confirmation'} btn={true} handleClick={handleSuccessCtn}>
                        <div style={{ padding: '49px 15px', fontWeight: 'bold' }}>
                            {role === 'prescriber' ? (
                                getSuccessMessage(selectedOption)
                            ) : (
                                <>{accountConfirmationContent.successMessage.nonprescriber}</>
                            )}
                        </div>
                    </Success>
                )}
            </div>
        </>
    );
};

export default AccountConfirmation;
