import React from 'react';
import classes from './logDispense.module.scss';
import Input from 'components/Input';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { getAdditionalPatientData, getPharmacyByPharmacyId } from 'store/slices/appSlice';
import { useAppDispatch } from 'store';

const ExpandedPatientRowRenderer = (record) => {
    const dispatch = useAppDispatch();
    const expandValues = [
        {
            id: 1,
            label: 'Risk Category',
            value: record.RiskCategoryName,
        },
        {
            id: 2,
            label: `Patient's Date of Birth`,
            value: record.patientDob,
        },
        {
            id: 3,
            label: `Authorization Expiration Date`,
            value: record.authExpDate,
        },
        {
            id: 4,
            label: `Patient's Last Survey Date`,
            value: record.lastSurveyDate,
        },
        {
            id: 5,
            label: `Patient's Next Survey Available on`,
            value: record.nextSurveyDate,
        },
    ];
    const noInputStyle = { color: 'rgb(75, 96, 117)' };

    return (
        <div className={classes.expandedRow} >
            <div className={classes.expandedRow_inputs1} style={{width:'100%'}}>
                {expandValues.length && expandValues.map((item) => (
                    <div className={classes.expandedRow_inputs1_item}>
                        <div className={classes.expandedRow_inputs1_label} style={{width:'31%'}}>{item.label}</div>
                        <span className={classes.expandedRow_inputs1_colon}  style={{marginRight:'-96px'}}>:</span>
                        <div className={classes.expandedRow_inputs1_value}>{item.value}</div>
                    </div>
                ))}
            </div>
          
        </div>
    );
};

export default ExpandedPatientRowRenderer;

