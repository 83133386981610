import { Col, Row } from 'antd';
import classes from './PatientDetails.module.scss';


const PatientSurveyPopup = ({ setOpenModal, data,showExt=false }) => {
    const { addressLine1, addressLine2, postalCode, pharmacyPhoneNumber, pharmacyFaxNumber } =
        data.pharmacyLocations[0];

    const addressArr = [
        addressLine1,
        addressLine2,
        postalCode.city,
        postalCode.state.stateName,
        postalCode.postalCode1,
    ];

    return (
            <div className={classes.modalOverlay} onClick={() => setOpenModal(false)}>
            <dialog className={classes.mainmodalContainer}>
                <div className={classes.whiteInfoContainer}>
                    <div className={classes.heading}> Pharmacy Location:</div>
                    <div className={classes.bigPad}>
                        <button className={classes.closeBtn} onClick={() => setOpenModal(false)}>
                            {' '}
                        </button>
                        <Row className={classes.rowTop}>
                            <Col span={5} className={classes.label}>Name:</Col>
                            <Col span={18} className={classes.value}>{data.pharmacyName}</Col>
                        </Row>
                        <Row className={classes.rowTop}>
                            <Col span={5} className={classes.label}>Address:</Col>
                            <Col span={18} className={classes.value}>{addressArr.join(' ')}</Col>
                        </Row>
                        <Row className={classes.rowTop}>
                            <Col span={5} className={classes.label}>Phone:</Col>
                            <Col span={18} className={classes.value}>{showExt ? pharmacyPhoneNumber:pharmacyPhoneNumber?.split('x')[0]}</Col>
                        </Row>
                        <Row className={classes.rowTop}>
                            <Col span={5} className={classes.label}>Fax:</Col>
                            <Col span={18} className={classes.value}>{pharmacyFaxNumber}</Col>
                        </Row>
                    </div>
                </div>
            </dialog>
            </div>
    );
};

export default PatientSurveyPopup;
