import SessionTimeout from 'components/SessionTimeout';
import Success from 'components/Success';
import { Role } from 'constants/Config/appConfig';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { store, useAppDispatch, useAppSelector } from 'store';
import { resetApp } from 'store/slices/appSlice';
import { setLogOut } from 'store/slices/loginSlice';

const ResetPasswordConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { role } = useAppSelector((state) => state.app);
    const isPharmacy = role === Role.Pharmacist;

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const handleContinue = () => {
        clearStore();
        dispatch(resetApp());
        dispatch(setLogOut());
        if (isPharmacy) {
            navigate('/pharmacy/login', { replace: true });
        } else {
            navigate('/prescriber/login', { replace: true });
        }
    };

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <>
            <SessionTimeout />
            <Success label="Reset Temporary Password Confirmation" btn={true} handleClick={handleContinue}>
                <p style={{ fontWeight: 'bold', margin: '20px 0' }}>
                    Your password has been changed. Press the Continue button below to return to the Login page.
                </p>
            </Success>
        </>
    );
};

export default ResetPasswordConfirmation;
