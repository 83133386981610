import { Col, Row } from 'antd';
import classes from './Calendar.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import moment from 'moment';
import ErrorModal from 'components/ErrorModal';
import { setPrescriberCalendarActionItemPayload, setPrescriberDashboardCalenderDynamicData } from 'store/slices/appSlice';

const Calendar =({setIsViewCalendarClicked, isViewCalendarClicked, prescribersIds, selectedAuthStatus,setCalError })=>{
    const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];
    const months=['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
    const today = new Date();//'August 09,2024'
   const weeks = ['Week 1','Week 2','Week 3','Week 4','Week 5'];
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [error, setError] = useState('');
   const { calendarData } = useAppSelector((state) => state.app);
    let currentDays:any = [];
    const { currentUser } = useAppSelector((state) => state.login);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails?.prescriberId);


    const getMonday=(d) => {
        d = new Date(d);
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sundayreturn new Date(d.setDate(diff));
        return  new Date(d.setDate(diff))
    }

    const handleErrorBtn = () => {       
        setError('');
    };
      
      const getCount =(date)=>{
        const formattedDate = moment(date).format('MM/DD/YYYY');
        const calData = calendarData;
        let count =calData.find(x => x.calendarDate === formattedDate)?.actionCount;
        return calendarData.find(x => x.calendarDate === formattedDate)?.actionCount || 0;
      }
      
      const firstDayOfMonth = getMonday(today);
      const weekdayOfFirstDay = firstDayOfMonth.getDay();
    for (let day = 0; day < 35; day++) {
      if (day === 0 && weekdayOfFirstDay === 0) {
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
      } else if (day === 0) {
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day ));
      } else {
        firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
      }
  
      let calendarDay : any = {
        currentMonth: (firstDayOfMonth.getMonth() === today.getMonth()),
        date: (new Date(firstDayOfMonth)),
        month: firstDayOfMonth.getMonth(),
        number:firstDayOfMonth < today ? "....." : firstDayOfMonth.getDate().toString().padStart(2, "0"),
        selected: (firstDayOfMonth.toDateString() === today.toDateString()),
        year: firstDayOfMonth.getFullYear(),
        count :firstDayOfMonth < today ?"" :  getCount(firstDayOfMonth)
      }
  
      currentDays.push(calendarDay);
    }
    const actionItemClick= async (day) => {
        if(day.count === 0)
        {           
            setCalError();
        }
        else if(day.count === "")
        {
            return false;
        }
        else{
            const payload = {
                startDate: moment(day.date).format('MM/DD/YYYY'),
                endDate: moment(day.date).format('MM/DD/YYYY'),
                prescriberIds: prescribersIds,
                loggedInPrescId: !nonPrescriber ? prescriberId : null,
                loggedInNonPrescId: nonPrescriber ? currentUser.nonPrescriberId : null,
                productId: null,
                prescriberLastName: null,
                authorizationNumber: null,
                authorizationStatusIds: selectedAuthStatus?.length > 0 ? selectedAuthStatus : null,
                sortByField: null,
                sortByOrder: null,
                prescriber: !nonPrescriber,
            } 
            dispatch(setPrescriberCalendarActionItemPayload({payload, prescriberIds: prescribersIds, authStatusIds: selectedAuthStatus, isFromCalender:true, payloadDate: day.date }));
        }
    };


    useEffect(() => {
        if (currentDays.length === 35 && isViewCalendarClicked) {
            dispatch(setPrescriberDashboardCalenderDynamicData(currentDays));
            setIsViewCalendarClicked(false);
        }
    }, [dispatch, isViewCalendarClicked]);

    return( <>
        <div className={classes.calendar}>
            <div className={classes.calendarbody}>
                <Row>
                    <Col span={2}>
                        <div className={classes.tablecontent}>
                            <div className={classes.weekdayWeek}></div>
                        </div>
                        <div className={classes.weektablecontent}>
                            {
                                weeks.map((weekday) => {
                                    return <div className={classes.weekHeader}><div>{weekday}</div></div>
                                })
                            }
                       
                        </div>
                    </Col>
                    <Col span={22}>
                        <div className={classes.tablecontent}>
                            {
                            weekDays.map((weekday) => {
                                return <div className={classes.weekday}><p>{weekday}</p></div>
                            })
                            }
                        </div>
                        <div className={classes.tablecontent}>
                        {
                            currentDays.map((day) => {
                            return (
                                day.selected ? <>
                                    <div className={classes.calendarday } onClick={()=>actionItemClick(day)}>
                                    <p>{day.number}</p>
                                        {day.count !== ""                                        
                                        ? 
                                        <div className={`${classes.presItems} ${classes.selected}`} >{day.count}</div>
                                        :
                                        <></>
                                        }
                                        
                                    </div>
                                    </>:<>
                                    <div className={classes.calendarday} onClick={()=>actionItemClick(day)}>
                                        {day.number==="....." ?<span>{day.number}</span>:<p>{day.number}</p>}
                                        {day.count !== ""                                        
                                        ? 
                                        <div className={classes.presItems} >{day.count}</div>
                                        :
                                        <></>
                                        }
                                        
                                    </div>
                                    </>
                                
                            
                            )
                            })
                        }
                        </div>
                    </Col>
                </Row>
                
            </div>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    </>)
}
export default Calendar;