import React, { useEffect, useState } from 'react';
import classes from './PatientVerification.module.scss';
import Input from 'components/Input';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { changeHelpContent, setPatientData, setPatientRisk } from 'store/slices/appSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getPatientReqBody } from './../../utils/patientSave';
import { axiosInstance_ORC } from './../../constants/Config/axiosConfig';
import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';
// import Revise from './../../assets/images/btn_Revise.png';
// import Save from './../../assets/images/btn_Continue_Save.png';

const PatientVerification = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();
    const [existingRC, setExistingRC] = useState(null);
    const [isPending, setIspending] = useState(false);

    const getOldRisk = (riskArr) => {
        return riskArr.reduce((max, current) =>
            new Date(current.effectiveDate) > new Date(max.effectiveDate) ? current : max
        );
    };

    const {
        patientInformation,
        prescriberDetails,
        accFormDetails,
        selectedProgram,
        prescriberUniqueID,
        patientReactivationStore,
        patientCamundaStore,
        patientResponse
    } = useAppSelector((state) => state.app);

    const { currentUser } = useAppSelector((state) => state.login);

    const selectedLanguage = i18n.language;

    const Prescriber =
        `${prescriberDetails?.firstName} ${prescriberDetails?.lastName}` ||
        `${currentUser?.firstName} ${currentUser?.lastName}`;

    const product = selectedProgram.id;

    const dob = `${patientInformation?.month} ${patientInformation?.date}, ${patientInformation?.year}`;

    const convertedDate = moment(dob, 'MMM DD,YYYY').format('MM/DD/YYYY');


    const productType = ProgramConfig(product).label;


    useEffect(() => {
        return () => {
            dispatch(changeHelpContent('patientVerification'));
        };
    }, [dispatch]);

    useEffect(() => {
        if (!patientInformation.oldRisk || patientInformation?.oldRisk?.length === 0) {
            setExistingRC(null);
            return;
        } else if (patientInformation?.oldRisk?.length === 1) {
            setExistingRC(patientInformation.oldRisk[0].riskCategoryName);
            dispatch(setPatientRisk(patientInformation.oldRisk[0].riskCategoryName));
        } else {
            const existingRisk = getOldRisk(patientInformation.oldRisk);
            setExistingRC(existingRisk?.riskCategoryName);
            dispatch(setPatientRisk(existingRisk?.riskCategoryName));
        }
    }, []);

    const handleRevise = () => {
        navigate('/prescriber/patientInformation', { replace: true });
    };

    const SavePatient = async (data) => {
        // navigate('/prescriber/ppafSelection', { replace: true });

        try {
            const response = await axiosInstance_ORC.post(`/patient/register`, data);
            if (response.data.statusCode === 200) {
                dispatch(setPatientData(response.data.data));
                navigate('/prescriber/ppafSelection', { replace: true });
                setIspending(false);
                //navigate('/prescriber/Success');
                // dispatch(changeHelpContent('Success'));
                // setIsSuccess(true);
            } else {
                //dispatch(changeHelpContent('Success'));
                setIspending(false);
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            setIspending(false);
            //dispatch(changeHelpContent('Success'));
            navigate('/prescriber/SystemError');
        }
    };

    const handleSave = () => {
        

        const selectedLocationSequence = prescriberDetails.addressId;
        
        setIspending(true);
        let otherIds = {};
        if (
            patientInformation.flowType &&
            ['Reactivate', 'WorkPartial', 'WorkPartialCompleted'].includes(patientInformation.flowType)
        ) {
            const payloadObj =
                patientInformation.flowType === 'Reactivate' ? patientReactivationStore?.data : patientCamundaStore;
            otherIds = {
                languageId: payloadObj.language.languageId,
                patientLocationSeq: payloadObj.patientLocations[0].patientLocationSeq,
                patientPrescriberSeq: payloadObj.patientPrescribers[0].patientPrescriberSeq,
                prescriberLocationSeq: prescriberDetails.addressList.find(o=> o.primaryLocation === 'Y').prescriberLocationSequence,
                patientRegistrationFormSeq: payloadObj.patientRegistrationForms[0].patientRegistrationFormSeq,
                statusLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].registrationStatus?.lookupId,
                reasonLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].callReason?.lookupId,
                changeLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].reasonForChange?.lookupId,
                deactivateLookupId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChange?.lookupId,
                reactivateLookupId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].reactivateReasonChange?.lookupId,
                registrationStatusId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].registrationStatusId,
                deactivateReasonChangeId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChangeId,
                conditionId: payloadObj.patientConditions.find(o=> !o.patientConditionEndDate)?.patientConditionId?.conditionId,
                riskCategoryId: patientInformation.riskCategoryId,
                riskCategorySeq: (patientInformation.newRisk && existingRC && existingRC !== patientInformation.newRisk) ? null : payloadObj.patientRiskCategory[0].riskCategoryId?.riskCategorySeq ,
            };
        } else otherIds = { prescriberLocationSeq: selectedLocationSequence,
            patientLocationSeq: patientInformation?.flowType === 'Reenroll' ? patientResponse?.patientLocations[0]?.patientLocationSeq :null,
        
        };
        const reqObj = getPatientReqBody({
            ...patientInformation,
            dob: convertedDate,
            prescriberExtUniqueId: currentUser.systemId || prescriberDetails.prescriberSystemId,
            productType,
            menstrating: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.menstrating === 'No' ? 'N' : 'Y'),
            surgical: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.surgical === 'No' ? 'N' : 'Y'),
            natural: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.natural === 'No' ? 'N' : 'Y'),
            productCountryId: selectedProgram?.productCountryId,
            action: 'Save',
            locale: i18n.language,
            ...otherIds,
        });
        SavePatient(reqObj);
    };
    return (
        <>
            <SessionTimeout />
            <div className={classes.container}>
            <div className={classes.headerContainer}>
                    {product && <span className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(product).headerImage}`)})`
                    }}></span>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(product).color}`
                    }}></div>
                </div>
                {/* <div className={classes.header}></div> */}
                <div className={classes.productHeader}>{t('patientInfoVerify')}</div>
                <div className={classes.contentDivider}></div>
                <div className={classes.mainContainer}>
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="Patient"
                        label={t('patient')}
                        valueExist={patientInformation?.firstName + ' ' + patientInformation?.lastName || ''}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="DOB"
                        label={t('DOB')}
                        valueExist={dob || ''}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="RiskCategory"
                        label={t('riskCategory')}
                        valueExist={patientInformation?.newRisk || ''}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="Diagnosis"
                        label={t('diagnosis')}
                        valueExist={patientInformation?.diagnosis || ''}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="Prescriber"
                        label={t('prescriber')}
                        valueExist={Prescriber}
                    />
                    {patientInformation.newRisk && existingRC && existingRC !== patientInformation.newRisk && (
                        <p className={classes.risk_warning}>
                            {t('riskChangeLine1')} <strong>{existingRC}.</strong>
                            <br /> {t('riskChangeLine2')} <strong>{patientInformation?.newRisk}.</strong>
                            <br />
                            {t('riskChangeLine3')}{' '}
                        </p>
                    )}
                    <div className={classes.btn_group}>
                        {/* <img src={Save} /> */}
                        <Button
                                clickFunction={handleSave}
                                className={getBtnClass(selectedProgram.id)}
                                width={265}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.continueSave : buttonContent.en.continueSave}
                                disabled={isPending}
                            ></Button>

                        <Button
                                clickFunction={handleRevise}
                                className={getBtnClass(selectedProgram.id)}
                                width={115}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.revise : buttonContent.en.revise}
                            ></Button>
                        {/* <img src={Revise} /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientVerification;
