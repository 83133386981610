import moment from 'moment';

export const printAction = (data) => {
    const { patientData } = data ;
    const getPgmName = (name) => name?.toUpperCase() === 'THALOMID' ? name?.toUpperCase() : name.charAt(0) + name.slice(1).toLowerCase();
    const authStatusMap = (status) => {
        const PrescLink = `<p> Prescriber Survey Required </p>`;
        switch (status) {
            case 'Active':
                return 'Active-Ready for Pharmacy';
            case 'Expired':
                return `Expired- ${PrescLink}`;
            case 'Confirmed':
                return `${PrescLink}`;
            case 'Obsolete':
                return `Cancelled- ${PrescLink}`;
            case 'Mismatched':
                return `Survey-Flagged: <p> Call REMS Call Center </p>`;
            case null:
                return `${PrescLink}`;
            default:
                return `${status}`;
        }
    };

    return `
    <html>
        <head>
            <title>${window.location.href}</title>
            <style>
          
            html{
                overflow-y: auto;
            }

            body{
                height: 95vh;
                overflow-y: auto;
                margin:0;
                @media print{
                    height: auto;
                }
            }
                .tablewidth{
                    @media print{
                        width: 719px
                }
            }
                tbody tr th,
                tbody tr td{
                    font - size:14px;
            }
                .header{
                    font-size:2em;
                    transform: translateY(15px);
            }
                .bottom{
                    font - size: 13px;
            }
                .actionTitle {
                    font-size: 16px;
                    margin: 0;
            }
                .subheading{
                    display:flex; border-top: 2px solid black; margin:5px -5px 0 -5px;
                    p{
                        width: 42% !important; margin: 0 0 -5px 0; padding: 4px 0 4px 4px; border-right: 2px solid black;
                        &:last-child{
                            border-right: 0;
                        }
                    }                                       
            }   

            th{
                    &:nth-child(4) {
                        width: 35% ;
                        padding-right:0;
                    }
                    &:first-child{
                        width: 18%;                       
                    }
                    &:nth-child(3){
                        width: 29% !important;
                        @media print{
                            width: 32% !important;
                        } 
                    }
                    &:nth-child(2), &:last-child{
                        width: 13% !important;
                    }
                    
            }
            
            tr {
                min-height: 38px;
                max-height: max-content;

                td { 
                    p{
                        margin: 0;
                    }  
                    &:nth-child(3) {
                        padding:0;
                        height: 38px;
                        .subBody{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            div{
                                height: 100%; 
                                text- align: center;
                                padding-left: 4px;
                                &:first-child {
                                    border-right: 2px solid black;
                                    width: 42%;
                                }
                            }
                        }
                    } 
                }               
            }            
            </style>
        </head>
        <body>
            <div class="header">
                <span class="icon logo left"></span>
                <div align="center">Prescriber Dashboard</div>
                <p align="center" class="actionTitle">Prescription Action Items</p> <br>
                    <div class="buffer clear"></div>
            </div>

            <table width="735px" cellpadding="5" cellspacing="0" style="border-color:black;" border="1" align="center" class="tablewidth">
                <thead>
                    <tr valign="top">
                        ${data.headings.map((item)=>{
                            return `<th align="left">
                            ${item.name}
                            ${item?.subHeading?.length>0 ? 
                                 `<div class="subheading">
                                 ${item.subHeading.map((sub)=>`<p>${sub}</p>`).join('')}
                                 </div>`                              
                                 : ``}
                            </th>`}).join('')
                        }
                    </tr>
                </thead>

                <tbody>
                ${!!patientData?.length ?
                    patientData.map((item)=>{
                    return ` 
                    <tr valign="top" class="rowTable">
                        <td align="left"> ${item?.patientNameLast?.toUpperCase() + ', '} ${item?.patientNameFirst?.toUpperCase() + ' '} </td>
                        <td align="left"> ${getPgmName(item?.productName?.split(' ')?.[0])} </td>
                        <td align="left"> 
                            <div class="subBody">
                                <div>${item?.authorizationNumber ? item.authorizationNumber?.substring(2) : ``}</div>
                                <div> ${item?.authorizationExpirationDate ? moment(item.authorizationExpirationDate).format('MMM DD, YYYY') : ``}</div>
                            </div>
                        </td>
                        <td align="left"> ${authStatusMap(item?.authorizationStatus)} </td>
                        <td align="left"> ${item?.assocPrescriberNameLast + ', ' + item?.assocPrescriberNameFirst} </td>
                    </tr>`}).join('')
                    :
                    `<tr valign="top" class="rowTable">
                        <td colspan="14" align="center"> ${data.errMsg} </td>
                    </tr>  `
                } 
                </tbody>

            </table>
            <br>
            <br>
            <div class="bottom" align="center">Bristol Myers Squibb</div>
        </body>
    </html>`
        ;

}