import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classess from './dashboardHelpPageContent.module.scss';
import { AuthStatus, DashboardLinks, Firstlist, Seclist, } from './ListContent';

const DashboardHelpPagesContent = ({ tabType }) => {
    const { t } = useTranslation();
    const [tabName, setTabName] = useState('');

    useEffect(() => {
        const tab = tabType.split('/')[1];
        setTabName(tab);
    }, [tabType]);

    const authDetailsTable = () => {
        return (<table>
            <thead>
                <tr>
                    <th>{t('authorizationStatus')}</th>
                    <th>{t('StatusActionReq')}</th>
                    <th>{t('meaning')}</th>
                    <th>{t('timeDashboard')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{AuthStatus.active}</td>
                    <td>Active - Ready for Pharmacy</td>
                    <td>The prescription is authorized and ready to be dispensed by the pharmacy.</td>
                    <td>Stays in active status until it's confirmed, expired, or obsolete.</td>
                </tr>
                <tr>
                    <td>{AuthStatus.obsolete}</td>
                    <td>Canceled - {AuthStatus.prescSR}</td>
                    <td>The authorization number was manually canceled.</td>
                    <td>Will display for 12 months from the date the last authorization number was generated.</td>
                </tr>
                <tr>
                    <td>{AuthStatus.confirmed} (Dispensed)</td>
                    <td>{AuthStatus.prescSR}</td>
                    <td>The pharmacy has dispensed the prescription.</td>
                    <td>Will display once there are 7 days or less remaining on the Rx. Will be removed from the dashboard after 12 months from the date the last authorization number was confirmed.</td>
                </tr>
                <tr>
                    <td>{AuthStatus.expired}</td>
                    <td>Expired - {AuthStatus.prescSR}</td>
                    <td>The authorization number has expired and the prescriber must complete another survey to get a new authorization number.</td>
                    <td>Stays indefinitely until an action is taken. The REMS Call Center will manually obsolete or capture historical confirmation on these authorization numbers on a scheduled basis.</td>
                </tr>
                <tr>
                    <td>{AuthStatus.mismatched}</td>
                    <td>Survey-Flagged: Call REMS Call Center</td>
                    <td>There is an issue with the survey</td>
                    <td>7 days for Females of Reproductive Potential 30 days for all other categories</td>
                </tr>
                <tr>
                    <td>{AuthStatus.psr}</td>
                    <td>Patient Survey Required</td>
                    <td>The patient must complete a patient survey for the authorization to be complete.</td>
                    <td>7 days for Females of Reproductive Potential 30 days for all other categories</td>
                </tr>
                <tr>
                    <td>{AuthStatus.newPatient}</td>
                    <td>{AuthStatus.prescSR}</td>
                    <td>This is the patient's first prescription</td>
                    <td>12 months.</td>
                </tr>
            </tbody>
        </table>
        )
    };

    if (tabName == DashboardLinks.reviewAuth) {
        return (
            <section className={classess.helpContent}>
                <header className={classess.textUnderline}>
                    <b>Review Authorization</b>
                </header>
                <main>
                    Review Authorization provides the ability to display the authorization number details. You will only
                    be able to view the authorization numbers that you are associated with. Enter an Authorization
                    Number and click/tap the Search button to view information regarding the Authorization Number.
                    Click/tap the Write Prescription button to complete the prescription form.
                </main>
                <footer>©2024 Bristol-Myers Squibb Company</footer>
            </section>
        );
    }

    if(tabName == DashboardLinks.calendar){
        return(
            <section className={classess.helpContent}>
            <main>
            <div className="helpContainer pad">
                <b>Prescriber Calendar</b>
                <ul>
                    <li>The Prescriber Calendar provides the ability to display the Prescription Action Items in a calendar view.</li>
                    <li>You will only be able to view the patients that you are associated with.</li>
                    <li>Click/tap on the red underlined number that is displayed within the current day to view and complete the available Prescription Action items.</li>
                    <li>Click/tap on the black number that is displayed on a future date to view upcoming Prescription Action Items.</li>
                </ul>
            </div>
            </main>
            <footer>©2024 Bristol-Myers Squibb Company</footer>
        </section>
        )
    }

    else if (tabName == DashboardLinks.actionItem || tabName == DashboardLinks.findAPatient || tabType == DashboardLinks.dashboard) {
        return (
            <section className={classess.helpContent}>
                <header>
                    <b>Prescription Action Items</b>
                </header>
                <main>
                    <div className={classess.actionItemMain}>
                        <ul>
                            {Firstlist.map((i, index) => (
                                <li key={index} dangerouslySetInnerHTML={{ __html: i }}></li>
                            ))}
                        </ul>
                        <div className={classess.tableContainer}> {authDetailsTable()} </div>
                        <div className={classess.secListDiv}>
                            <ul>
                                <li>Hyperlinks are also provided to directly access a specific REMS function. For example "{AuthStatus.prescSR}".</li>
                                <li>Prescriber Name (Last) - The prescriber of record for the patient will be displayed.</li>
                            </ul>
                            <p>Find A Patient</p>
                            <ul>
                                {Seclist.map((i, index) => (
                                    <li key={index} dangerouslySetInnerHTML={{ __html: i }}></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </main>
                <footer>©2024 Bristol-Myers Squibb Company</footer>
            </section>
        );
    }
    else if (tabName == DashboardLinks.reports) {
        if(window.location.hash.includes('reports/expireReport')){
            return (
                <section className={classess.helpContent}>
                <header className={classess.textUnderline}>
                    <b>Authorization Numbers to Expire Report</b>
                </header>
                <main>
                    <div className={classess.actionItemMain}>
                       <p>This report provides the ability to display the authorization numbers that are going to expire within a specified date range. You will only be able to view your patients and patients of Prescribers that you are associated with. Select the date range, REMS Program(s) and Prescriber(s) for the report.</p>
                    </div>
                </main>
                <footer>©2024 Bristol-Myers Squibb Company</footer>
            </section>
            )
        } else if (window.location?.hash?.includes('reports/historicalReport')) {
            return (
                <section className={classess.helpContent}>
                    <header className={classess.textUnderline}>
                        <b>Historical Authorization Report</b>
                    </header>
                    <main>
                        <div className={classess.actionItemMain}>
                            <p>This report provides the ability to run a historical report based on authorization status and date range to show all patient(s), authorization status(es) and program(s). You will only be able to view your patients and patients of Prescribers that you are associated with. Select the date range, Authorization Status, REMS Program(s) and Prescriber(s) for the report.</p>
                        </div>
                    </main>
                    <footer>©2024 Bristol-Myers Squibb Company</footer>
                </section>
            )
        } else if(window.location.hash.includes('reports/hiddenPatientReport')){
            return (
                <section className={classess.helpContent}>
                <header className={classess.textUnderline}>
                    <b>Hidden Patient Report</b>
                </header>
                <main>
                    <div className={classess.actionItemMain}>
                       <p>This report provides the ability view your hidden patients and if needed, click on the patient name to unhide them. You will only be able to view your patients and patients of Prescribers that you are associated with. Select the REMS Program(s) and Prescriber(s) for the report.</p>
                    </div>
                </main>
                <footer>©2024 Bristol-Myers Squibb Company</footer>
            </section>
            )
        } 
        else{
        return (
            <section className={classess.helpContent}>
                <header>
                    <b>Reports</b>
                </header>
                <main>
                    <div className={classess.actionItemMain}>
                       <p>Select the Authorization Numbers to Expire Report to create a report of authorization numbers that are going to expire within a specified date range.</p>
                       <p>Select the Historical Authorization Report to create a report of historical authorizations bases on the authorization status, program, and date range.</p>
                       <p>Select the Hidden Patient Report to create a report to view your hidden patients.</p>
                    </div>
                </main>
                <footer>©2024 Bristol-Myers Squibb Company</footer>
            </section>
        );
        }
    }

    return null;
};

export default DashboardHelpPagesContent;
