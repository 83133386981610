import GeneralPateintDetailsTable from './GeneralPatientAuthTable';
import DetailedPatientAuthTable from './DetailedPatientAuthTable';
import { useAppSelector } from 'store';
import "./prescriberDashboardReviewAuth.module.scss"

const PrescriberDashboardReviewAuth = () => {
    const { authorizationData } = useAppSelector((state) => state.app);
    if (!authorizationData || authorizationData.length === 0) {
        return;
    } else {
        return (
            <>
                <GeneralPateintDetailsTable authData={authorizationData[0]} />
                <DetailedPatientAuthTable authData={authorizationData[0]}/>
            </>
        );
    }
};

export default PrescriberDashboardReviewAuth;
