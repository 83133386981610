/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import Alert from 'components/Alert';
import Button from 'components/Button';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import { productEnrolmentFields } from 'constants/productEnrolmentFields';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { setProductEnrolmentData, findPrescriberPINisValid } from 'store/slices/appSlice';
import { useAppSelector } from '../../store';

import {
    alphaNumeric,
    isValidAttention,
    isValidCity,
    isValidEmail,
    isValidME,
    isValidMI,
    isValidOfficeName,
    isValidPhoneNumber,
    isValidStreetAddress,
    isValidZip,
    primaryPhone,
} from '../../utils/regex';
import classes from '../createaccount/createaccount.module.scss';
import styles from './ProductRegistration.module.scss';
import { axiosInstance, axiosInstanceNewUrl, axiosInstanceSearch } from './../../constants/Config/axiosConfig';
import SessionTimeout from 'components/SessionTimeout';
import i18n from 'internationalization/i18next';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import { ProgramConfig } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';

interface dropDownType {
    State?: { id: string; value: string }[] | null;
    SecurityQuestion?: { id: string; value: string }[] | null;
    Degree?: { id: string; value: string }[] | null;
    Specialty?: { id: string; value: string }[] | null;
}

const ProductRegistration = () => {
    const dispatch = useDispatch();
    const dispatchApp = useAppDispatch();
    const navigate = useNavigate();
    const {
        accFormDetails,
        enrolledProducts,
        role,
        selectedProgram,
        isSubmitAndPrint,
        isAccount,
        prescriberUniqueID,
        productEnrolmentData,
        prescriberDetails,
    } = useAppSelector((state) => state.app);

    const [dropdown, setDropdown] = useState<dropDownType>({ State: [], Degree: [], Specialty: [] });
    const userInfo = JSON.parse(sessionStorage.getItem('accountData') || '[]');
    const { prescriberEnrollment, errorFindPrescriber, isPrescriberIdNumValid } = useAppSelector((state) => state.app);
    const [values, setValues] = useState({
        degree: '',
        specialty: '',
        pi_number: '',
        officeName: '',
        attention: '',
        officeAddress: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax: '',
        email: '',
        MI: '',
        meNumber: '',
    });
    const [secondaryValues, setSecondaryValues] = useState({
        officeName2: '',
        attention2: '',
        address2: '',
        city2: '',
        state2: '',
        zip2: '',
        phone2: '',
        fax2: '',
        email2: '',
    });

    useEffect(() => {
        const productSavedData = Object.fromEntries(
            Object.entries(productEnrolmentData).filter(
                ([key, value]) => value !== '' && value !== null && key !== 'showAsterisk'
            )
        );
        /* setValues((prev) => ({
            ...prev,
            officeAddress: prescriberDetails.officeName,
            city: prescriberDetails.city,
            state: prescriberDetails.state,
            zip: prescriberDetails.zip,
            phone: prescriberDetails.phoneNumber,
            email: prescriberDetails.email,
            pi_number: prescriberUniqueID,
            ...productSavedData,
        })); */

        setValues((prev) => ({
            ...prev,
            officeAddress: prescriberDetails.selectedAddress
                ? prescriberDetails.selectedAddress
                : accFormDetails.officeAddress,
            city: prescriberDetails.city ? prescriberDetails.city : accFormDetails.city,
            state: prescriberDetails.state ? prescriberDetails.state : accFormDetails.state,
            zip: prescriberDetails.zip ? prescriberDetails.zip : accFormDetails.zipCode,
            phone: prescriberDetails.phone ? prescriberDetails.phone : accFormDetails.phoneNumber,
            email: prescriberDetails.email ? prescriberDetails.email : accFormDetails.email,
            pi_number: prescriberUniqueID,
            meNumber: prescriberDetails.meNumber || null,
            MI: prescriberDetails.mi,
            ...productSavedData,
        }));

        // const productSecondaryData = Object.fromEntries(
        //     Object.entries(productEnrolmentData).filter(
        //         ([key, value]) => value !== '' && value !== null && key.endsWith('2')
        //     )
        // );

        // setSecondaryValues((prev) => ({
        //     ...prev,
        //     ...productSecondaryData,
        // }));
        /*  if( prescriberUniqueID.length>0 ){
            setValues((prev) => ({
                ...prev,
                pi_number: prescriberUniqueID,
            }));
            const piNumberElement=document.getElementById('pi_number') as HTMLInputElement;
            piNumberElement.value=prescriberUniqueID; 
        }  */
    }, [productEnrolmentData, prescriberDetails]); //accFormDetails is replaced with prescriberDetails,
    const [error, setError] = useState('');
    const [servererror, setServerError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');

    const [showAsterisk, setShowAsterisk] = useState(false);
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const ref = useRef(null);

    const fetchDropdown = async () => {
        const reqBody = {
            keys: ['Degree', 'Specialty'],
        };
        try {
            const response = await axiosInstance.post(`/referencedata`, reqBody);
            fetchDatafromS3json().then((res) => {
                setDropdown({...response.data.data, State: res?.state });
            });
        } catch (err) {
            console.error(`error fetching dropdown ${err}`);
        }
    };
    useEffect(() => {
        fetchDropdown();
    }, []);

    const handleOnChange = (e) => {
        servererror && setServerError('');
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const secOffcChangeHandler = (e) => {
        error && setError('');
        const { name, value } = e.target;
        setSecondaryValues((prev) => ({
            ...prev,
            [name]: value === 'Select a State' ? '' : value,
        }));
    };
    const onBlurHandler = () => {
        const { state2, ...newArray } = secondaryValues;
        if (secondaryValues.state2 !== 'Select a State') {
            const arrayLengthCheck = Object.values(secondaryValues).some((value) => value !== '');
            setShowAsterisk(arrayLengthCheck);
        } else {
            const arrayLengthCheck = Object.values(newArray).some((value) => value !== '');
            setShowAsterisk(arrayLengthCheck);
        }
    };
    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const secondaryOfficeList = [
        {
            name: 'officeName2',
            maxLength: 20,
            label: 'Office Name',
            type: 'text',
            isRequired: showAsterisk,
            errMsg: 'Please enter the Office Name for the Secondary Address.',
        },
        {
            name: 'attention2',
            label: 'Attention',
            type: 'text',
            isRequired: showAsterisk,
            errMsg: 'Please enter the Attention information for the Secondary Address.',
        },
        {
            name: 'address2',
            label: 'Office Street Address',
            type: 'text',
            isRequired: showAsterisk,
            errMsg: 'Please enter the Street Address for the Secondary Address.',
        },
        {
            name: 'city2',
            label: 'City',
            type: 'text',
            isRequired: showAsterisk,
            errMsg: 'Please enter the City for the Secondary Address.',
        },
        {
            name: 'state2',
            label: 'State',
            type: 'select',
            isRequired: showAsterisk,
            errMsg: 'Please select a State for the Secondary Address.',
        },
        {
            name: 'zip2',
            label: 'Zip',
            type: 'text',
            isRequired: showAsterisk,
            helpText: 'e.g., xxxxx',
            maxLength: 5,
            errMsg: 'Please enter a Zip Code for the Secondary Address.',
        },
        {
            name: 'phone2',
            label: 'Phone Number',
            type: 'text',
            isRequired: showAsterisk,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            maxLength: 12,
            errMsg: 'Please enter a valid Phone Number for the Secondary Address.',
        },
        {
            name: 'fax2',
            maxLength: 12,
            label: 'Fax',
            type: 'text',
            isRequired: showAsterisk,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            errMsg: 'Please enter a valid Fax Number for the Secondary Address.',
        },
        {
            name: 'email2',
            label: 'Email',
            type: 'text',
            isRequired: showAsterisk,
            helpText: 'yourname@abccompany.com',
            errMsg: 'Please enter an Email Address for the Secondary Address.',
        },
    ];
    const checkAddress = async (values) => {
        const stateId = dropdown?.State?.find((o) => o.value === values.state.trim())?.id;
        if (stateId) {
            const addressPayload = { zipCode: values.zip, city: values.city.toUpperCase().trim(), state: +stateId };
            let response;
            response = await axiosInstanceSearch.post(`/validate/address`, addressPayload);
            if (response?.data?.message === 'Success') {
                return true;
            } else {
                setServerError(response.response.data.data.errorMesssage.messageKey);
                scrollToView();
                return false;
            }
        } else {
            scrollToView();
        }
    };

    useEffect(() => {
        reloadFunction();
    }, []);

    const findDuplicatePIN = (response) => {
        if (response.payload.status === 204) {
            return false;
        } else if (response.payload.status === 200) {
            let isProgramPresent = false;
            let activePrograms = false;
            let presFname = response.payload.data.data.prescriberNameFirst;
            let presLname = response.payload.data.data.prescriberNameLast;
            let enrolledPrograms: any = [];
            let currentfirstName = prescriberDetails.firstName
                ? prescriberDetails.firstName
                : accFormDetails.firstName || userInfo.firstName;
            let currentlastName = prescriberDetails.lastName
                ? prescriberDetails.lastName
                : accFormDetails.lastName || userInfo.lastName;
            if (response.payload.data.data.prescriberRegStatuses.length >= 0) {
                const enrollmentPayload = response.payload.data.data.prescriberRegStatuses;
                enrollmentPayload.forEach((item) => {
                    if (item.registrationStatus.registrationStatus.toLowerCase() === 'active') {
                        activePrograms = true;
                        if (
                            item.id.productCountryId.product.productName.toLowerCase() ===
                            selectedProgram.label.toLowerCase()
                        ) {
                            isProgramPresent = true;
                        }
                    }

                    enrolledPrograms.push({
                        program :item.id.productCountryId.product.productName.toLowerCase(),
                        status:item.registrationStatus.registrationStatus.toLowerCase()
                    });
                });
            }

            if(enrolledPrograms.find(x=> x.program.toLowerCase() === selectedProgram.label.toLowerCase())){
                return '300';
            }
            else{
                if(enrolledPrograms.length >= 1)
                {
                    if (presFname != currentfirstName || presLname != currentlastName) {
                        return '271';
                    } else {
                        return false;
                    }
                }
                else{
                    return false;
                }
            }

            /* if (isProgramPresent) {
                return '300';
               
                /* if(enrolledPrograms.find(x=> x.program.toLowerCase() === selectedProgram.label.toLowerCase()).status === "active")
                {
                    return '300';
                }
                else{
                    if (presFname != currentfirstName || presLname != currentlastName) {
                        return '271';
                    } else {
                        return false;
                    }
                } */
            
               
                    //if(enrolledPrograms.find(x=> x.program.toLowerCase() === selectedProgram.label.toLowerCase())){
                       

               // }
                

                /* if (presFname === currentfirstName && presLname === currentlastName) {
                    return '300';
                } else {
                    // return false;
                    if (activePrograms) {
                        if (presFname != currentfirstName || presLname != currentlastName) {
                            return '271';
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } */
            /*} else {
                if (activePrograms) {
                    if (presFname != currentfirstName || presLname != currentlastName) {
                        return '271';
                    } else {
                        return false;
                    }
                }
                else{
                    return false;
                }
            } */
        } else {
            return false;
        }
    };

    const trimValues = () => {
        const newVals = { ...values };

        Object.keys(newVals).forEach((field) => {
            if (newVals[field] != undefined) {
                newVals[field] = newVals[field]?.trim();
            }
        });
        const newSecVals = { ...secondaryValues };
        Object.keys(newSecVals).forEach((field) => {
            if (newSecVals[field] != undefined) {
                newSecVals[field] = newSecVals[field]?.trim();
            }
        });
        setValues(newVals);
        setSecondaryValues(newSecVals);
        return newVals;
    };

    const handleSubmit = async () => {
        await trimValues();
        setServerError('');

        const requiredLabels = [
            ...productEnrolmentFields.personalInfo.filter((i) => i.isRequired),
            ...productEnrolmentFields.primaryOfc.filter((i) => i.isRequired),
            ...secondaryOfficeList.filter((i) => i.isRequired),
        ].map((i) => ({ name: i.name, label: i.label, errMsg: i.errMsg }));

        if (values.MI !== '' && values.MI && !isValidMI(values.MI)) {
            setError('Please enter a valid Middle Initial.');
            setCurrentFocus('MI');
            scrollToView();
            return;
        }
        if (values.meNumber !== '' && values.meNumber && !isValidME(values.meNumber)) {
            setError('Please enter a valid ME No.');
            setCurrentFocus('meNumber');
            scrollToView();
            return;
        }
        for (let i of requiredLabels) {
            if (
                values[i.name] === '' ||
                values[i.name] === 'Select a State' ||
                values[i.name] === 'State' ||
                values[i.name] === 'Select' ||
                values[i.name] === 'Select a Specialty' ||
                secondaryValues[i.name] === 'Select a State' ||
                secondaryValues[i.name] === ''
            ) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (values.MI !== '' && values.MI && !isValidMI(values.MI)) {
                setError('Please enter a valid Middle Initial.');
                setCurrentFocus('MI');
                scrollToView();
                return;
            } else if (i.name === 'pi_number' && values.pi_number !== '') {
                if (alphaNumeric(values.pi_number)) {
                    if (prescriberUniqueID.length === 0) {
                        const userPayload = values.pi_number.toString();
                        const getPrescriberData = await dispatchApp(findPrescriberPINisValid(userPayload)).then(
                            (response) => {
                                return response;
                            }
                        );
                        const isDuplicatPIN = await findDuplicatePIN(getPrescriberData);
                        if (isDuplicatPIN) {
                            if (isDuplicatPIN === '300') {
                                setServerError(
                                    'Prescriber is already certified for the selected program. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                                );
                            } else {
                                setServerError(
                                    'Sorry, we are unable to complete the certification at this time. There is a problem with the Prescriber Identification number you provided. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET'
                                );
                            }
                            setValues((prev) => ({
                                ...prev,
                                [i.name]: '',
                            }));
                            const piNumberElement = document.getElementById(i.name) as HTMLInputElement;
                            piNumberElement.value = '';
                            setCurrentFocus(i.name);
                            scrollToView();
                            return;
                        }
                    }
                } else {
                    setError('Please enter a valid Prescriber Identification Number.');
                    /* setServerError(
                        'Prescriber is already certified for the selected program. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                    ); */
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (values.meNumber !== '' && values.meNumber && !isValidME(values.meNumber)) {
                setError('Please enter a valid ME No.');
                setCurrentFocus('meNumber');
                scrollToView();
                return;
            } else if (i.name === 'officeName') {
                if (values.officeName.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.officeName.trim() !== '' && !isValidOfficeName(values.officeName.trim())) {
                    setError('Please enter a valid Office Name for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'attention') {
                if (values.attention.trim() == '') {
                    setError(i.errMsg);
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
                if (values.attention.trim() !== '' && !isValidAttention(values.attention.trim())) {
                    setError('Please enter a valid Attention information for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'officeAddress') {
                if (values.officeAddress.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.officeAddress.trim() !== '' && !isValidStreetAddress(values.officeAddress.trim())) {
                    setError('Please enter a valid Street Address for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'city') {
                if (values.city.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.city.trim() !== '' && !isValidCity(values.city.trim())) {
                    setError('Please enter a valid City for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'zip') {
                if (values.zip.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.zip.trim() !== '' && !isValidZip(values.zip.trim())) {
                    setError('Please enter a valid Zip Code for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'phone') {
                if (values.phone === null || values.phone.trim() === '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.phone.trim() !== '' && !isValidPhoneNumber(values.phone)) {
                    setError('Please enter a valid Phone Number for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'fax') {
                if (values.fax.trim() === '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.fax.trim() !== '' && !isValidPhoneNumber(values.fax.trim())) {
                    setError('Please enter a valid Fax Number for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'email') {
                if (values.email.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (values.email.trim() !== '' && !isValidEmail(values.email.trim())) {
                    setError('Please enter a valid Email Address for the Primary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'officeName2') {
                if (secondaryValues.officeName2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (
                    secondaryValues.officeName2.trim() !== '' &&
                    !isValidOfficeName(secondaryValues.officeName2.trim())
                ) {
                    setError('Please enter a valid Office Name for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'attention2') {
                if (secondaryValues.attention2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.attention2.trim() !== '' && !isValidAttention(secondaryValues.attention2.trim())) {
                    setError('Please enter a valid Attention information for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'address2') {
                if (secondaryValues.address2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.address2.trim() !== '' && !isValidStreetAddress(secondaryValues.address2.trim())) {
                    setError('Please enter a valid Street Address for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'city2') {
                if (secondaryValues.city2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.city2.trim() !== '' && !isValidCity(secondaryValues.city2.trim())) {
                    setError('Please enter a valid City for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'zip2') {
                if (secondaryValues.zip2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.zip2.trim() !== '' && !isValidZip(secondaryValues.zip2.trim())) {
                    setError('Please enter a valid Zip Code for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            }
            // else if (i.name === 'phone2' && !isValidPhoneNumber(secondaryValues.phone2.trim())) {
            //     setCurrentFocus(i.name);
            //     setError(i.errMsg);
            //     scrollToView();
            //     return;
            // }
            // else if (
            //     i.name === 'fax2' &&
            //     secondaryValues.fax2.trim() !== '' &&
            //     !isValidPhoneNumber(secondaryValues.fax2.trim())
            // ) {
            //     setCurrentFocus(i.name);
            //     setError(i.errMsg);
            //     scrollToView();
            //     return;
            // }
            else if (i.name === 'phone2') {
                if (secondaryValues.phone2 === null || secondaryValues.phone2?.trim() === '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.phone2?.trim() !== '' && !isValidPhoneNumber(secondaryValues.phone2.trim())) {
                    setError('Please enter a valid Phone Number for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'fax2') {
                if (secondaryValues.fax2.trim() === '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.fax2.trim() !== '' && !isValidPhoneNumber(secondaryValues.fax2.trim())) {
                    setError('Please enter a valid Fax Number for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'email2') {
                if (secondaryValues.email2.trim() == '') {
                    setCurrentFocus(i.name);
                    setError(i.errMsg);
                    scrollToView();
                    return;
                }
                if (secondaryValues.email2.trim() !== '' && !isValidEmail(secondaryValues.email2.trim())) {
                    setError('Please enter a valid Email Address for the Secondary Address.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else {
                setError('');
            }
        }
       
        let validateAddr = await checkAddress({ city: values.city, zip: values.zip, state: values.state });
        if (validateAddr && !servererror && secondaryValues.officeName2) {
            validateAddr = await checkAddress({
                city: secondaryValues.city2,
                zip: secondaryValues.zip2,
                state: secondaryValues.state2,
            });
        }
        if (!error && !servererror && validateAddr) {
            const dispatchedData = [
                {
                    ...values,
                    ...secondaryValues,
                    showAsterisk: showAsterisk,
                    image: selectedProductData.id,
                    label: selectedProductData.label,
                },
            ];
            sessionStorage.setItem('productaccDetails', JSON.stringify(values));
            sessionStorage.setItem('productaccSecDetails', JSON.stringify(secondaryValues));
            dispatch(setProductEnrolmentData(dispatchedData));
            navigate('/prescriber/productSignUp');
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['degree', 'specialty', 'state', 'state2'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    const showPINhelp = () => {
        window.open(
            `${window.location.origin}/#/headerHelp?role=${role}&helpRef=productRegistration&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
            `${window.location.origin}/#/headerHelp?role=${role}&helpRef=productRegistration&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
            'location=yes,height=379,width=808,scrollbars=yes,status=yes'
        );
    };

        return (
            <>
                <SessionTimeout />
                <div className={classes.mainContainer} ref={containerRef}>
                    <div>
                        <div className={classes.headerContainer}>
                            <span className={classes.icon}></span>
                            <div className={classes.programLogo}>
                                <div className={classes.title}>New Prescriber Enrollment</div>
                            </div>
                        </div>
                    </div>
                    {selectedProgram.id && (
                        <div
                            className={styles.generalProgramLogo}
                            style={{
                                backgroundImage: `url(${require(
                                    `../../assets/images/${ProgramConfig(selectedProgram.id)?.prodRegistrationImage}`
                                )})`,
                            }}
                        ></div>
                    )}
                    <div style={{ margin: '12px 0 3px 0px' }}>
                        <span className={classes.bold}>Please enter your details and click the Continue button.</span>
                    </div>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {servererror && <Alert content={servererror} />}
                        <div className={classes.formHeader}>Prescriber Information</div>
                        <Input
                            type="hidden"
                            name="firstName"
                            label="First Name"
                            inputvalue={
                                prescriberDetails.firstName
                                    ? prescriberDetails.firstName
                                    : accFormDetails.firstName || userInfo.firstName
                            }
                            isRequired={true}
                            className="fontBold"
                        />
                        <Input
                            type="hidden"
                            name="lastName"
                            label="Last Name"
                            inputvalue={
                                prescriberDetails.lastName
                                    ? prescriberDetails.lastName
                                    : accFormDetails.lastName || userInfo.lastName
                            }
                            isRequired={true}
                            className="fontBold"
                        />
                        {values['MI'] !== '' && prescriberDetails.mi !== '' ? (
                            <Input
                                type="hidden"
                                name="MI"
                                label="MI"
                                inputvalue={values['MI']}
                                isRequired={false}
                                className="fontBold"
                            />
                        ) : (
                            <Input
                                type="text"
                                maxLength={1}
                                name="MI"
                                label="MI"
                                onChangeHandler={handleOnChange}
                                value={values['MI']}
                            />
                        )}

                        <Input
                            type="select"
                            name="degree"
                            label="Degree"
                            isRequired={true}
                            onChangeHandler={handleOnChange}
                            defaultValue={productEnrolmentData.degree || 'Select'}
                            options={
                                dropdown?.Degree
                                    ? [
                                          { value: 'Select' } as { value: string },

                                          ...dropdown?.Degree?.map((item) => ({ value: item.value })),
                                      ]
                                    : []
                            }
                        />
                        <Input
                            type="select"
                            name="specialty"
                            label="Specialty"
                            isRequired={true}
                            onChangeHandler={handleOnChange}
                            defaultValue={productEnrolmentData.specialty || 'Select a Specialty'}
                            options={
                                dropdown?.Specialty
                                    ? [
                                          { value: 'Select a Specialty' } as {
                                              value: string;
                                          },

                                          ...dropdown?.Specialty?.map((item) => ({ value: item.value })),
                                      ]
                                    : []
                            }
                        />
                        <div className={styles.pinInput}>
                            {prescriberUniqueID.length > 0 ? (
                                <Input
                                    type="text"
                                    name="pi_number"
                                    maxLength={10}
                                    label="Prescriber Identification Number"
                                    isRequired={true}
                                    helpText={'DEA, Social Security Number, NPI or other 9-10 digit number'}
                                    helpText2={'e.g., AB1234567 or 123456789 or 1234567890'}
                                    onChangeHandler={handleOnChange}
                                    value={values['pi_number']}
                                    noInput={values['pi_number'].trim().length > 0 ? true : false}
                                />
                            ) : (
                                <Input
                                    type="text"
                                    name="pi_number"
                                    maxLength={10}
                                    label="Prescriber Identification Number"
                                    isRequired={true}
                                    helpText={'DEA, Social Security Number, NPI or other 9-10 digit number'}
                                    helpText2={'e.g., AB1234567 or 123456789 or 1234567890'}
                                    onChangeHandler={handleOnChange}
                                />
                            )}

                            <span
                                onClick={showPINhelp}
                                className={prescriberUniqueID.length > 0 ? styles.helpLinkNoInput : styles.helpLink}
                            >
                                Click here for help
                            </span>
                        </div>

                        <Input
                            maxLength={40}
                            type="text"
                            name="meNumber"
                            label="ME No"
                            onChangeHandler={handleOnChange}
                            defaultValue={prescriberDetails['meNumber'] || ''}
                        />
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.formHeader}>Primary Office</div>
                        <Input
                            type="text"
                            name="officeName"
                            label="Office Name"
                            maxLength={100}
                            onChangeHandler={handleOnChange}
                            isRequired={true}
                            value={values['officeName']}
                        />
                        <Input
                            type="text"
                            name="attention"
                            label="Attention"
                            onChangeHandler={handleOnChange}
                            isRequired={true}
                            maxLength={30}
                            value={values['attention']}
                        />
                        <Input
                            type="text"
                            name="officeAddress"
                            label="Office Street Address"
                            maxLength={50}
                            defaultValue={
                                prescriberDetails.selectedAddress
                                    ? prescriberDetails.selectedAddress
                                    : accFormDetails.officeAddress
                            }
                            onChangeHandler={handleOnChange}
                            isRequired={true}
                            value={values['officeAddress']}
                        />
                        <Input
                            type="text"
                            isRequired={true}
                            name="city"
                            label="City"
                            defaultValue={prescriberDetails.city ? prescriberDetails.city : accFormDetails.city}
                            onChangeHandler={handleOnChange}
                            value={values['city']}
                        />

                        <Input
                            type="select"
                            name="state"
                            label="State"
                            defaultValue={prescriberDetails.state ? prescriberDetails.state : accFormDetails.state}
                            isRequired={true}
                            onChangeHandler={handleOnChange}
                            options={
                                dropdown?.State
                                    ? [
                                          { value: 'Select a State' } as { value: string },
                                          ...dropdown?.State?.map((item) => ({ value: item.value })),
                                      ]
                                    : []
                            }
                        />
                        <Input
                            type="text"
                            name="zip"
                            label="Zip Code"
                            defaultValue={prescriberDetails.zip ? prescriberDetails.zip : accFormDetails.zipCode}
                            isRequired={true}
                            maxLength={5}
                            helpText={'e.g., xxxxx'}
                            onChangeHandler={handleOnChange}
                            value={values['zip']}
                        />
                        <Input
                            type="text"
                            name="phone"
                            label="Phone"
                            maxLength={12}
                            defaultValue={
                                prescriberDetails.phone ? prescriberDetails.phone : accFormDetails.phoneNumber
                            }
                            isRequired={true}
                            helpText={'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx'}
                            onChangeHandler={handleOnChange}
                            value={values['phone']}
                        />
                        <Input
                            type="text"
                            name="fax"
                            label="Fax"
                            maxLength={12}
                            isRequired={true}
                            helpText={'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx'}
                            onChangeHandler={handleOnChange}
                            value={values['fax']}
                        />
                        <Input
                            type="text"
                            name="email"
                            label="Email"
                            maxLength={60}
                            defaultValue={prescriberDetails.email ? prescriberDetails.email : accFormDetails.email}
                            isRequired={true}
                            helpText={'yourname@abccompany.com'}
                            onChangeHandler={handleOnChange}
                            value={values['email']}
                        />
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.formHeader}>Secondary Office</div>
                        <Input
                            type="text"
                            name="officeName2"
                            label="Office Name"
                            maxLength={100}
                            onChangeHandler={secOffcChangeHandler}
                            isRequired={showAsterisk}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['officeName2']}
                        />
                        <Input
                            type="text"
                            name="attention2"
                            label="Attention"
                            onChangeHandler={secOffcChangeHandler}
                            isRequired={showAsterisk}
                            onBlurHandler={onBlurHandler}
                            maxLength={30}
                            value={secondaryValues['attention2']}
                        />
                        <Input
                            type="text"
                            name="address2"
                            label="Office Street Address"
                            maxLength={50}
                            onChangeHandler={secOffcChangeHandler}
                            isRequired={showAsterisk}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['address2']}
                        />
                        <Input
                            type="text"
                            isRequired={showAsterisk}
                            name="city2"
                            label="City"
                            onChangeHandler={secOffcChangeHandler}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['city2']}
                        />
                        <Input
                            type="select"
                            name="state2"
                            label="State"
                            isRequired={showAsterisk}
                            //defaultValue={productEnrolmentData.state2}
                            onChangeHandler={secOffcChangeHandler}
                            options={
                                dropdown?.State
                                    ? [
                                          { value: 'Select a State' } as { value: string },
                                          ...dropdown?.State?.map((item) => ({ value: item.value })),
                                      ]
                                    : []
                            }
                            onBlurHandler={onBlurHandler}
                        />
                        <Input
                            type="text"
                            name="zip2"
                            label="Zip Code"
                            isRequired={showAsterisk}
                            helpText={'e.g., xxxxx'}
                            maxLength={5}
                            onChangeHandler={secOffcChangeHandler}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['zip2']}
                        />
                        <Input
                            type="text"
                            name="phone2"
                            label="Phone"
                            maxLength={12}
                            isRequired={showAsterisk}
                            helpText={'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx'}
                            onChangeHandler={secOffcChangeHandler}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['phone2']}
                        />
                        <Input
                            type="text"
                            name="fax2"
                            label="Fax"
                            maxLength={12}
                            isRequired={showAsterisk}
                            helpText={'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx'}
                            onChangeHandler={secOffcChangeHandler}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['fax2']}
                        />
                        <Input
                            type="text"
                            name="email2"
                            label="Email"
                            maxLength={60}
                            isRequired={showAsterisk}
                            helpText={'yourname@abccompany.com'}
                            onChangeHandler={secOffcChangeHandler}
                            onBlurHandler={onBlurHandler}
                            value={secondaryValues['email2']}
                        />
                    </div>
                    <Button
                        clickFunction={handleSubmit}
                        className="buttonStyles"
                        width={169}
                        size={18}
                        height={48}
                        type="text"
                        buttonname="Continue"
                    />
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </>
        );
};
export default ProductRegistration;
