import Button from 'components/Button';
import PendingEnrollmentModal from 'components/PendingEnrollment';
import SessionTimeout from 'components/SessionTimeout';
import { RoleByGroup } from 'constants/Config/appConfig';
import { axiosInstancePrescriber } from 'constants/Config/axiosConfig';
import {
    lenalidomide,
    
    ProgramConfig,
    ProgramConfigIMG,
    PROGRAMS,
    thalomid,
} from 'constants/Config/programsConfig';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { changePPAF, setPrescriptionFormData } from 'store/slices/appSlice';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import { useAppSelector } from '../../store';
import classes from './mainMenu.module.scss';
const MainMenu = () => {
    const navigate = useNavigate();
    const { selectedProgram } = useAppSelector((state) => state.app);
    const [pendingEnrollment, setPendingEnrollment] = useState('');
    const { currentUser } = useAppSelector((state) => state.login);
    const modalRef = useRef<HTMLDialogElement | null>(null);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);
    /* const mainMenuHeader = {
        'rems_program_thalomid': `<span style="font-weight: bold;">THALOMID REMS</span><span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span> Main Menu`,
        'rems_program_lenalidomide': `<span style="font-weight: bold;">Lenalidomide REMS</span> Main Menu`,
        'rems_program_pomalyst': `<span style="font-weight: bold;">Pomalidomide REMS</span> Main Menu`,

    }; */
    const mainMenu = (id) => {
        const isRegTrademark =
            ProgramConfig(id)?.registeredTradermark === true
                ? `<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`
                : '';
        const headerMenu = `<span style="font-weight: bold;">${ProgramConfig(id)
            ?.title}</span>${isRegTrademark} Main Menu`;

        return headerMenu;
    };
    const prescriberValidationMessage = (id) => {
        const isRegTrademark =
            ProgramConfig(id)?.registeredTradermark === true
                ? `<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`
                : '';
        const validationMsg = `<span style="font-weight: bold;">${ProgramConfig(id)?.title}</span>${isRegTrademark}`;
        return validationMsg;
    };

    useEffect(() => {
        dispatch(changePPAF(false));
        dispatch(setPrescriptionFormData({}));
    }, []);

    const validatePrescriber = async () => {
        /* const prescriberValidation = {
            rems_program_thalomid: `<span style="font-weight: bold;">THALOMID REMS</span><span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`,
            rems_program_lenalidomide: `<span style="font-weight: bold;">Lenalidomide REMS</span>`,
            rems_program_pomalyst: `<span style="font-weight: bold;">Pomalidomide REMS</span>`,
        }; */
        const prescriberValidationMsg = `<span> ${t('error_PD0406_1')}${prescriberValidationMessage(selectedProgram.id)}
        ${t('error_PD0406_2')}</span>`;
        let isValid = true;
        const prescriberId = currentUser.systemId;
        const userPayload = {
            prescriberId: currentUser.userinfo['custom:prescriber_user_id'] || prescriberId,
        };
        const response = await axiosInstancePrescriber
            .post(`/v1/prescriber/findprescriber`, userPayload)
            .then((res) => {
                const enrollmentPayload = res?.data?.data?.prescriberRegStatuses;
                let programSelected = selectedProductData.id.split('_').pop();
                enrollmentPayload.forEach((item) => {
                    if (programSelected.toLowerCase() === item.id.productCountryId.product.productName.toLowerCase()) {
                        const status = currentUser.groupTag.includes('NonPrescriber')
                            ? 'active'
                            : item.registrationStatus.registrationStatus.toLowerCase();
                        if (status === 'pending' || status === 'deactivated') {
                            isValid = false;
                            setPendingEnrollment(prescriberValidationMsg);
                        }
                    }
                });
            })
            .catch((error) => {
                isValid = false;
            });

        return isValid;
    };

    const callPhysicianOfficeDetails = async () => {
        //check prescriber validation for all menu btn clicks
        const isValid: any = await validatePrescriber();
        if (isValid) {
            dispatch(changePPAF(true));
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/physicianSearch');
            } else {
                navigate('/prescriber/PhysicianOfficeDetails');
            }
        }
    };
    const callPhysicianOfficeDetailsSpanish = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            i18n.changeLanguage('es');
            dispatch(changePPAF(true));
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/physicianSearch');
            } else {
                navigate('/prescriber/PhysicianOfficeDetails');
            }
        }
    };
    const submittedPPAF = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            //updated only
            dispatch(changePPAF(true));
            navigate('/prescriber/ppafWorkMenu');
        }
    };
    const submittedPPAFEs = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            dispatch(changePPAF(true));
            i18n.changeLanguage('es');
            navigate('/prescriber/ppafWorkMenu');
        }
    };
    const prescriberSurvey = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            dispatch(changePPAF(true));
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/PrescriberSearch');
            } else {
                navigate('/prescriber/prescribersurveysearch');
            }
        }
    };

    const prescriberSurveyes = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            dispatch(changePPAF(true));
            i18n.changeLanguage('es');
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/PrescriberSearch');
            } else {
                navigate('/prescriber/prescribersurveysearch');
            }
        }
    };

    const standardPrescription = async () => {
        const isValid: any = await validatePrescriber();
        if (isValid) {
            dispatch(changePPAF(true));
            const record = { buttonName: 'StandardForm' };
            dispatch(setPrescriptionFormData({ formType: record.buttonName }));
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/PrescriberSearch');
            } else {
                sessionStorage.setItem('patientInfo',JSON.stringify(record))
                navigate('/prescriber/PatientSearch');
            }
        }
    };

    const vaPrescription = async () => {
        const isValid: any = await validatePrescriber();

        if (isValid) {
            dispatch(changePPAF(true));
            const record = { buttonName: 'VHAForm' };
            dispatch(setPrescriptionFormData({ formType: record.buttonName }));
            if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
                navigate('/prescriber/PrescriberSearch');
            } else {
                sessionStorage.setItem('patientInfo',JSON.stringify(record))
                navigate('/prescriber/PatientSearch');
            }
        }
    };

    const handlePenEnrollOutsideClick = (e) => {
        if (e.target.className.indexOf('pendingEnrollment_modalContainer') === 0) {
            setPendingEnrollment('');
        }
        e.stopPropagation();
    };

    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer}>
                <div>
                    <div className={classes.headerContainer}>
                        <div className={classes.headerRemsProgram} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProductData.id).headerImage}`)})`
                        }}></div>
                        <div
                            className={classes.title}
                            dangerouslySetInnerHTML={{ __html: mainMenu(selectedProductData.id) }}
                        ></div>
                    </div>
                    <div className={`${classes['headerDivider_' + selectedProductData.id]}`}></div>
                </div>
                {pendingEnrollment && (
                    <PendingEnrollmentModal
                        message=""
                        handleOutsideClick={(e) => handlePenEnrollOutsideClick(e)}
                        prescriberValidationMsg={pendingEnrollment}
                    />
                )}
                <div className={classes.contentContainer}>
                    <div>
                        <div className={`${classes.btnWithImage} ${classes.noMarP}`}>
                            <div className={`${classes.innerImage} ${classes['prod_' + ProgramConfig(selectedProductData.id).label]}`}></div>
                            <Button
                                clickFunction={callPhysicianOfficeDetails}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.newppaf}
                                innerImage={true}
                            ></Button>
                        </div>
                        <div className={classes.btnWithImage}>
                            <div
                                className={`${classes.innerImage} ${
                                    classes['prodSubmittedPPAF_' +ProgramConfig(selectedProductData.id).label]
                                }`}
                            ></div>
                            <Button
                                clickFunction={submittedPPAF}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.savedPPaf}
                                subText={'(' + buttonContent.en.clickMore + ')'}
                                innerImage={true}
                            ></Button>
                        </div>
                        <div className={classes.btnWithImage}>
                        <div
                                className={`${classes.innerImage} ${classes['prodSurvey_' + ProgramConfig(selectedProductData.id).label]}`}
                            ></div>
                            <Button
                                clickFunction={prescriberSurvey}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.presSurvey}
                                subText={'(' + buttonContent.en.clickMorePrescSurvey + ')'}
                                innerImage={true}
                            ></Button>
                        </div>
                        <div className={`${classes.btnWithImage} ${classes.smallIcons} ${classes.noMarP}`}>
                            <div
                                className={`${classes.innerImage} ${
                                    classes['prodPrescription_' +  ProgramConfig(selectedProductData.id).label]}
                                }`}
                            ></div>
                            <Button
                                clickFunction={standardPrescription}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.stdPrescForm}
                                innerImage={true}
                            ></Button>
                        </div>
                        <div className={`${classes.btnWithImage} ${classes.smallIcons}`}>
                            <div
                                className={`${classes.innerImage} ${
                                    classes['prodPrescription_' +  ProgramConfig(selectedProductData.id).label]}
                                }`}
                            ></div>
                            <Button
                                clickFunction={vaPrescription}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.en.vaPrescription}
                                innerImage={true}
                            ></Button>
                        </div>
                    </div>
                    <div>
                        <div className={classes.btnWithImage}>
                        <div className={`${classes.innerImage} ${classes['prod_' + ProgramConfig(selectedProductData.id).label]}`}></div>
                            <Button
                                clickFunction={callPhysicianOfficeDetailsSpanish}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.es.newppaf}
                                innerImage={true}
                            ></Button>
                        </div>

                        <div className={classes.btnWithImage}>
                            <div
                                className={`${classes.innerImage} ${
                                    classes['prodSubmittedPPAF_' + ProgramConfig(selectedProductData.id).label]}`}
                            ></div>
                            <Button
                                clickFunction={submittedPPAFEs}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.es.savedPPaf}
                                subText={'(' + buttonContent.es.clickMore + ' )'}
                                innerImage={true}
                            ></Button>
                        </div>

                        <div className={classes.btnWithImage}>
                            <div
                                className={`${classes.innerImage} ${classes['prodSurvey_' + ProgramConfig(selectedProductData.id).label]}`}
                            ></div>
                            <Button
                                clickFunction={prescriberSurveyes}
                                className={getBtnClass(selectedProductData.id)}
                                width={380}
                                height={108}
                                size={18}
                                type="text"
                                buttonname={buttonContent.es.presSurvey}
                                subText={'(' + buttonContent.es.prescSurveySubText + ')'}
                                innerImage={true}
                            ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainMenu;
