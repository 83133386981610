import classes from './prescriberDashboardCalender.module.scss';
import viewCalenderBtnImg from '../../assets/images/btn_ViewCalendar.png';
import { useAppDispatch, useAppSelector } from 'store';
import { useEffect, useRef, useState } from 'react';
import {
    getPrescAssociation,
    getNonPrescAssociation,
    fetchAuthStatusForPrescriberCalender,
    prescriberCalendarInfo,
    setViewCalendarData,
    deleteCalendarData,
    storeCalenderActionitemInfo
} from 'store/slices/appSlice';
import { authStatusCustomMapping } from 'utils/dashboardStatusMapping';
import Calendar from './Calendar';
import Button from 'components/Button';
import ErrorModal from './ErrorModalCalendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { printCalenderAction } from './printCalenderAction';
import { axiosInstanceSearch } from 'constants/Config/axiosConfig';
import { reloadFunction } from 'utils/utilsCommon';

const PrescriberDashboardCalender = ({tabNumb, setShowPrintBtnCalender, showPrintBtnCalender}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { currentUser } = useAppSelector((state) => state.login);
    const onlineUserId = currentUser.onlineUserId;
    const prescriberId = currentUser?.systemId || currentUser.userinfo['custom:prescriber_user_id'];
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const { firstName, lastName, onlineUserid, prescriberSystemId } = useAppSelector(
        (state) => state.app.prescriberDetails
    );
    const selfPrescriber = {
        prescriberId: nonPrescriber ? currentUser?.nonPrescriberId : prescriberSystemId,
        firstName: nonPrescriber ? currentUser?.firstName : firstName,
        lastName: nonPrescriber ? currentUser?.lastName :  lastName,
        onlineUserId: nonPrescriber ? currentUser?.onlineUserId : onlineUserid,
    };

    const initialAssociatedPrescribers = nonPrescriber ? [] : [{ ...selfPrescriber }];
    const [error, setError] = useState('');
    const [calerror, setCalendError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [totalAuthStatus,setTotalAuthStatus] = useState([]);
    const [associatedPrescribers, setAssociatedPrescribers] = useState<any>(initialAssociatedPrescribers);
    const [checkNoData,setCheckNoData]=useState(false);
    const [authStatuses, setAuthStatues] = useState<any>([]);
    const [selectedPrescribers, setSelectedPrescribers] = useState<any>([]);
    const [selectAllPrescribers, setSelectAllPrescribers] = useState<any>(false);
    const [selectedAuthStatuses, setSelectedAuthStatuses] = useState<any[]>([]);
    const [selectAllAuthStatuses, setSelectAllAuthStatuses] = useState<any>(false);
    const [isNoRecords,setIsNoRecords]= useState(false);
    const [isCalendar,setIsCalendar]= useState(false);
    const [isviewCalendar,setViewCalendar]=useState(false);
    const [calendarData,setCalendarData] = useState({});
    const [prescribersIds, setPrescribersIds] = useState<any>([]);
    const nonPrescriberId = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberUserId = useAppSelector((state) => state.app.prescAllDetails.prescriberId);
    const { prescriberDashboardCalenderDynamicData } = useAppSelector((state) => state.app);
    const [isViewCalendarClicked, setIsViewCalendarClicked] = useState<any>(false);
   
    const prescriberNonPrescriberAssociatedPrescribersApiHandler = (payload, thunkCaller,isPrescriber) => {
        dispatch(thunkCaller({ data: payload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                const newPrescribers = isPrescriber ? e.payload?.data.data.parentPrescribers : e.payload?.data.data.associatedPrescribers;
                const existingIds = new Set(associatedPrescribers.map((each) => each.prescriberId));
                const uniquePrescribers = newPrescribers.filter((each) => !existingIds.has(each.prescriberId));
                setAssociatedPrescribers((prev) => [...prev, ...uniquePrescribers]);
                setCheckNoData(true);
            } else if (e?.payload && e?.payload?.response?.data?.statusCode === 400) {
                if (!isPrescriber && e?.payload?.response?.data?.message === 'No associations was found') {
                    setAssociatedPrescribers([]);
                    setCheckNoData(true);
                }
            }
            else {
                return;
            }
        });
    };
    useEffect(() => {        
        setIsNoRecords(false);
        setIsCalendar(false);
        setViewCalendar(false);
        setSelectAllAuthStatuses(false);        
        setSelectedAuthStatuses([]);
        setSelectAllPrescribers(false);
        setSelectedPrescribers([]);
        dispatch(deleteCalendarData({}))
    }, [tabNumb]);
    
    
    useEffect(() => {
        if (!nonPrescriber) {
            const payload = {
                prescriberId: prescriberId,
            };
            prescriberNonPrescriberAssociatedPrescribersApiHandler(payload, getPrescAssociation,true);
        }
        if (nonPrescriber) {
            const payloadNonpresc = {
                onlineUserId: onlineUserId,
            };
            prescriberNonPrescriberAssociatedPrescribersApiHandler(payloadNonpresc, getNonPrescAssociation,false);
        }
        dispatch(fetchAuthStatusForPrescriberCalender(['AuthorizationStatus'])).then((res: any) => {
            if (res.payload?.status === 200) {
               
                let authStatus :any=[];
                authStatus.push(0);
                res.payload?.data.data.AuthorizationStatus.forEach(v => { authStatus.push(parseInt(v.id)) })
                setTotalAuthStatus(authStatus)
                setAuthStatues(authStatusCustomMapping(res.payload?.data.data.AuthorizationStatus, 'calender'));
            } else {
                return;
            }
        });
       
    }, []);

    useEffect(() => {
        if (selectedPrescribers.length === associatedPrescribers.length && associatedPrescribers.length > 0) {
            setSelectAllPrescribers(true);
        } else {
            setSelectAllPrescribers(false);
        }
    }, [selectedPrescribers, associatedPrescribers]);

    useEffect(() => {
        if (selectedAuthStatuses.length === authStatuses.length && authStatuses.length > 0) {
            setSelectAllAuthStatuses(true);
        } else {
            setSelectAllAuthStatuses(false);
        }
    }, [selectedAuthStatuses, authStatuses]);
    useEffect(() => {
        reloadFunction();
    }, []);

    const toggleSelectPrescriber = (prescriber: any) => {
        setSelectedPrescribers((prevSelected) => {
            const isSelected = prevSelected.some((each) => each.prescriberId === prescriber.prescriberId);
           
            if (isSelected) {
                return prevSelected.filter((each) => each.prescriberId !== prescriber.prescriberId);
            } else {
                return [...prevSelected, prescriber];
            }
        });
    };

    const handleSelectAllChangeOfPrescribers = (event) => {
        const checked = event.target.checked;
        setSelectAllPrescribers(checked);
        if (checked) {
            setSelectedPrescribers(associatedPrescribers);
        } else {
            setSelectedPrescribers([]);
        }
    };

    const toggleSelectAuthStatus = (authStatus: any) => {
        setSelectedAuthStatuses((prevSelected) => {
            const isSelected = prevSelected.some((each) => each.id === authStatus.id);
            if (isSelected) {
                return prevSelected.filter((each) => each.id !== authStatus.id);
            } else {
                return [...prevSelected, authStatus];
            }
        });
    };

    const handleSelectAllAuthStatusesChange = (event) => {
        const checked = event.target.checked;
        setSelectAllAuthStatuses(checked);
        if (checked) {
            setSelectedAuthStatuses(authStatuses);
        } else {
            setSelectedAuthStatuses([]);
        }
    };

    if (nonPrescriber && associatedPrescribers.length < 1 && checkNoData) {
        return (
            <div className={classes.prescriberDashboardCalender}>
                <div className={classes.noRecordsFound}>
                    <span>No Records Available.</span>
                </div>
            </div>
        );
    }
    const getMonday=(d) => {
      d = new Date(d);
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sundayreturn new Date(d.setDate(diff));
      return  new Date(d.setDate(diff))
    }

    const statusMapping = () => {
        let authStatus: any = []
        selectedAuthStatuses.forEach(e => {
            if (e.id.length > 1) {
                if(e.value=== "Prescriber Survey Required")
                {
                    authStatus.push(0)//Set 0 for Prescriber survey required to get null Auth records
                }
                e.id.forEach(v => { authStatus.push(parseInt(v)) })
            }
            else {
                authStatus.push(parseInt(e.id));
            }
        })
        return authStatus
    };

    
     const viewCalendar=()=>{
      
      if(selectedPrescribers.length>0)
      {
        
        
        let prescribers:any =[];
        selectedPrescribers.forEach(e=> {
          prescribers.push(e.prescriberId);
        });
        setPrescribersIds(prescribers);
        let authStatus = statusMapping();
        const firstDayOfMonth = getMonday(new Date());
        const lastDay =getMonday(new Date());
        lastDay.setDate(lastDay.getDate() + 35);
       
        const payload ={ 
          "startDate": moment(new Date()).format('MM/DD/YYYY'),
          "endDate": moment(lastDay).format('MM/DD/YYYY'),
          "prescriberIds": prescribers,
          "loggedInUserId": !nonPrescriberId ? prescriberUserId : currentUser.nonPrescriberId,
          "authStatusIds": authStatus.length >0 ? authStatus:totalAuthStatus,
          "prescriber":!nonPrescriberId ? true :false
        } 
        dispatch(prescriberCalendarInfo({data:payload})).then((response: any) =>{
          if(response?.payload?.status === 200)
          {
            if(response?.payload?.data?.data?.length>0){
                setIsViewCalendarClicked(true);
                setShowPrintBtnCalender(true);
              const respData = response?.payload?.data?.data;
              const isCount = respData.find(x=> x.actionCount > 0) || null;
              if(isCount !== null )
              {
                setIsCalendar(true);              
                setIsNoRecords(false);
                setViewCalendar(true);
                dispatch(setViewCalendarData({data:response?.payload?.data?.data}))
              }
              else{
                setIsCalendar(true);
                setViewCalendar(false);
                setIsNoRecords(true);
              }
              
            }
            else{
              setIsCalendar(true);
              setViewCalendar(false);
              setIsNoRecords(true);
            }
          }
          else{
            console.error("Api Failed");
            navigate('/prescriber/SystemError');
          }
        })
      }
      else{
        setIsCalendar(false);
        setError('Please select one or more Prescribers.');
      }
     }
     const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const handleErrorBtn = () => {       
      setError('');
      setCurrentFocus('');
      scrollToView()
  };
  const handleCalErrorBtn = () => {       
    
    setCalendError('')
    setCurrentFocus('');
};


    const printThisPageHandler = () => {
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML: any = printCalenderAction(prescriberDashboardCalenderDynamicData);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };
    const setCalError = ()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setError('No prescription action items are due.')
    }

    return (
      <>
        
      <div ref={containerRef} className={classes.prescriberDashboardCalender}>
            {tabNumb && tabNumb == 3 && showPrintBtnCalender && (
                <span className={`${classes.calenderPrintBtn}`} onClick={printThisPageHandler}>
                    Print this page <b>&#x1F892;</b>
                </span>
            )}
            <div className="top">
                <i>
                    Fields marked with an <span>*</span> are required.
                </i>
            </div>
            <div className="bottom">
                <div className="prescriberSelectOptions">
                    <div className="labelTexts">
                        <sup className={classes.required}>*</sup>
                        <p>Prescriber(s):</p>
                    </div>
                    <div className="associatedPrescriberSelection">
                        <div>
                            <input
                                type="checkbox"
                                id="selectAllPrescribers"
                                checked={selectAllPrescribers}
                                onChange={handleSelectAllChangeOfPrescribers}
                            />
                            <label htmlFor="selectAllPrescribers">Select All</label>
                        </div>
                        {associatedPrescribers.map((each) => (
                            <div key={each.prescriberId}>
                                <input
                                    type="checkbox"
                                    id={`prescriber-${each.prescriberId}`}
                                    checked={selectedPrescribers.some(
                                        (selected) => selected.prescriberId === each.prescriberId
                                    )}
                                    onChange={() => toggleSelectPrescriber(each)}
                                />
                                <label htmlFor={`prescriber-${each.prescriberId}`}>
                                    {each.firstName} {each.lastName}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="statusesSelectOptions">
                    <div className="labelTexts">
                        <p>Status(es):</p>
                    </div>
                    <div className="statusRefrenceData">
                        <div>
                            <input
                                type="checkbox"
                                id="selectAllAuthStatuses"
                                checked={selectAllAuthStatuses}
                                onChange={handleSelectAllAuthStatusesChange}
                            />
                            <label htmlFor="selectAllAuthStatuses">Select All</label>
                        </div>
                        {authStatuses.map((each) => (
                            <div key={each.id}>
                                <input
                                    type="checkbox"
                                    id={`authStatus-${each.id}`}
                                    checked={selectedAuthStatuses.some((selected) => selected.id === each.id)}
                                    onChange={() => toggleSelectAuthStatus(each)}
                                />
                                <label htmlFor={`authStatus-${each.id}`}>{each.value}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="viewCalenderBtn">
                   
                    <Button
                      clickFunction={viewCalendar}
                      className="buttonStyles"
                      type="text"
                      width={150}
                      height={46}
                      size={17}
                      buttonname="View Calendar"
                    />
                </div>
            </div>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} classNamesAccCreation={true}/>}
        </div>
        {isCalendar &&
          <div className={classes.contentContainer} >
           {isviewCalendar &&
                <Calendar setIsViewCalendarClicked={setIsViewCalendarClicked} isViewCalendarClicked={isViewCalendarClicked}
                    prescribersIds={prescribersIds}
                    selectedAuthStatus={statusMapping()}
                    setCalError = {setCalError}                
                />
            }
            {isNoRecords && <div className={classes.noRecords}>No Records Found.</div>}
          </div>
        }
     
      
      </>
        
    );
};

export default PrescriberDashboardCalender;
