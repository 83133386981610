import { useTranslation } from 'react-i18next';
import classes from './PatientSurvey.module.scss';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { searchPatientSurvey, updatePatientSurvey } from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { PatientSurveyScreens, patientDetails } from './PatientSurveyType';
import Alert from 'components/Alert';
import { useState,useEffect } from 'react';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import IMG from '../../assets/images/img_REMS_Companion_App_CRM_Survey_Login.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isValidName, isValidPatientNum } from 'utils/regex';
import moment from 'moment';
import Button from 'components/Button';
import {buttonContent} from '../../assets/objects/buttonContent';
import { reloadFunction } from 'utils/utilsCommon';

const Search = ({ setScreen }) => {
    const initialFormState = {
        firstName: '',
        lastName: '',
        patientIdentification: ''
    };
    const { patientSurvey } = useAppSelector((state) => state.app);
    const [alertMessage, setAlertMessage] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState("");
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [values, setValues] = useState(initialFormState);
    const [isError, setIsError] = useState(false);
    const [errorField, setErrorField] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');

    const maxLength = 9;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const isSurveyAction = searchParams.get('action') === 'patientSurveyAction';

    const handleButtonClick = () => {
        i18n.changeLanguage('es')
        const url = '/prescriber/patientSurvey';
        navigate(url);
        setAlertMessage('');
        setValues(initialFormState);
    };

    const handleValidationErrors = (fieldName) => {
        setErrorField(fieldName);
        setIsError(true);
        setCurrentFocus(fieldName)
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement?.focus();        
        inputElement?.select();
    };

    const validationOnContinue = (): boolean => {
        let isValidate: boolean = false;
        if (values.firstName === '' || !isValidName(values.firstName)) {
            setErrorPopUp(t("error_PV0690"))
            handleValidationErrors('firstName')
        }
        else if (values.lastName === '' || !isValidName(values.lastName)) {
            setErrorPopUp(t("error_PV0685"))
            handleValidationErrors('lastName')
        }
        else if (values.patientIdentification === '' || !isValidPatientNum(values.patientIdentification, maxLength)) {
            setErrorPopUp(t("patientIdentificationVal"));
            handleValidationErrors('patientIdentification')
        } else {
            setIsError(false);
            setErrorField('');
            isValidate = true;
        }
        return isValidate;

    }

    const handleSearch = () => {
        if (validationOnContinue()) {

            const payload = {
                patientFirstName: values.firstName.trim(),
                patientLastName: values.lastName.trim(),
                patientExtUniqueId: values.patientIdentification.trim(),
                language: i18n.language
            }

            dispatch(searchPatientSurvey(payload)).then(response => {
                searchResponseHandler(response.payload);
            })

        }
    }
    const searchResponseHandler = (response) => {
        if (response.status === 200) {
            setScreen(PatientSurveyScreens.SelectProduct);
        }
        else if (response.status === 204 || response.status === 202) {
            setAlertMessage(t('surveyEligibleMsg'));
        } else if (response.response.status === 400) {
            if (response?.response?.data?.data?.errorMesssage?.code === 9001 || response?.response?.data?.data?.errorMesssage?.code === 9002 || response?.response?.data?.data?.errorMesssage?.code === 9003) {
                setAlertMessage(t('error_PD0040'))
            } else if (response?.response?.data?.data?.errorMesssage?.code === 9004) {
                setAlertMessage(t("error_PM0165").replace("<Date>", moment(response?.response?.data?.data?.errorMesssage?.messageKey).format('MM/DD/YYYY')));
            } else if (response?.response?.data?.data?.errorMesssage?.code === 9009) {
                setAlertMessage(t('surveyEligibleMsg'));
            } else if (response?.response?.data?.data?.errorMesssage?.code === 9000) {
                setAlertMessage(t('activeEnrollmentError'));
            }
            else {
                setAlertMessage(t('error_PD0040'))
            }
        } else if (response.status === 500) {
            navigate('/prescriber/systemError', { replace: true });
        }
        setValues(initialFormState);
    }
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrorField('');
        setIsError(false);
        setCurrentFocus('');
    };
    const handleContinue = () => {
        setErrorPopUp('');
        if (isError) {
            handleValidationErrors(errorField);
        }
    };
    useEffect(() => {
        reloadFunction();
    }, []);

    return (
        <div className={classes.search}>
            <PageBackground label={t("patientSurvey")}>
                <div>
                    {ErrorPopUp && <ErrorModal error={ErrorPopUp} handleContinue={handleContinue} />}
                    <div className={classes.requiredNote}>{t('fieldsMarkedWith')} <span className={classes.required}>*</span> {t('areRequired')}</div>
                    <div className={`${classes.contentContainer}  ${classes[i18n.language]}`}>
                        {alertMessage && (<Alert content={alertMessage} styles={{ margin: '10px 50px 5px 50px' }} />)}
                        <div className={classes.flexContent}>
                            <h1 className={classes.instruction1}>
                                {t('message_PM0605')}
                            </h1>
                            {isSurveyAction && (
                                <button onClick={handleButtonClick} className={classes.extraButton}>
                                </button>
                            )}
                        </div>

                        {patientDetails.map((row, index) => {
                            return (
                                <div className={classes.formGroup}>
                                    <Input
                                        onChangeHandler={handleOnChange}
                                        styleupdate={true}
                                        value={values[row.name]}
                                        type={row.type}
                                        name={row.name}
                                        isRequired={row.isRequired}
                                        className="fontColor"
                                        maxLength={row.maxLength}
                                        label={t(row.label)}
                                        labelStyle={{ color: '#193878' }}
                                        helpText={t(row.helpText)}
                                        helpTextStyle={{
                                            color: '#193878',
                                            position: 'relative',
                                            left: '182px',
                                            bottom: '16px',
                                            fontWeight: '100',
                                            fontSize: '10px'
                                        }}
                                        isLabelSeperated={false}
                                    />

                                </div>
                            );
                        })}
                        <h1 className={classes.instruction2}>
                            {t('message_PM0610')}</h1>

                        {i18n.language === 'en' && <div className={classes.surveyImage}>
                            <img src={IMG} />
                        </div>}

                    </div>
                    <div className={`${classes.actionButton} ${classes.ml6px}`}>
                        <Button
                                clickFunction={() => handleSearch()}
                                className="mr10"
                                width={i18n.language === 'es' ? 230:170}
                                height={45}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.startSurvey : buttonContent.en.startSurvey}
                            ></Button>
                    </div>
                </div>
                <SessionTimeout />
            </PageBackground>
        </div>
    )
}

export default Search;