export const isValidPassword = (input) => {
    const regex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w_@+.,/:;&#\s-]{8,20}$/;
    return regex.test(input);
};
export const isValidPasswordPhar = (input) => {
    const regex =  /^(?=.*[a-z])(?=.*\d)[\w_@+.,/:;&#\s-]{8,20}$/;
    return regex.test(input);
};

export const isValidTempPassword = (input) => {
    const regex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w_@+.,/:;&#\s-]$/;
    return regex.test(input);
};
export const isValidDate = (value) => {
   // var regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}$/;
   var regex =/^(?:(?:(?::0[13578]|1[02])\/(?:0[1-9]|[12]\d|3[01])|(?:0[469]|11)\/(?:0[1-9]|[12]\d|30)|02\/(?:0[1-9]|1\d|2[0-8]))\/\d{4}|02\/29\/(?:\d{2}(?:0[48]|[2468][048]|[13579][26])|\d{2}(?:00|04|08|[2468][048]|[13579][26])))$/;

    return regex.test(value);
};

export const isValidEmail = (email) => {
    var regex =  /^[-._0-9a-zA-Z]+@(?!\.)(?!.*\.\.)[-a-zA-Z0-9]+(\.[a-zA-Z0-9]{2,})+$/gm;
    return regex.test(email);
};

export const isValidPhoneNumber = (number) => {
    var regex = /^([1-9]\d{2}-\d{3}-\d{4}|[1-9]\d{2}\s\d{3}\s\d{4}|[1-9]\d{9})$/;
    return regex.test(number);
};
export const isValidZip = (number) => {
    var regex = /^\d{5}$/;
    return regex.test(number);
};
export const isValidUserName = (name) => {
    var regex = /^[\w\-_']{4,}$/;
    return regex.test(name);
};
export const isValidName = (name) => {
    var regex = /^[\w\-'. ]+$/;
    return regex.test(name);
};
export const isValidCity = (name) => {
    var regex = /^[\w&()\-./\s]+$/;
    return regex.test(name);
};
export const alphaNumeric = (name) => {
    var regex = /^\d{9}$|^\d{10}$|^[a-zA-Z]{2}\d{7}$/;
    return regex.test(name);
};
export const deaAlphaNumeric = (name) => {
    var regex = /^[a-zA-Z]{2}\d{7}$/;
    return regex.test(name);
};
export const primaryPhone = (name) => {
    var regex = /^\d{10}$/;
    return regex.test(name);
};
export const isValidAddr = (name) => {
    var regex = /^[a-zA-Z0-9#&'(),\-./\s]+$/;
    return regex.test(name);
};
export const isValidMI = (name) => {
    var regex = /^[a-zA-Z]{1}$/;
    return regex.test(name);
};
export const isValidME = (name) => {
    var regex = /^[\w"#&'(),\-./:;@_\s]+$/;
    return regex.test(name);
};
export const isValidOfficeName = (name) => {
    var regex = /^[a-zA-Z0-9 &#',./:;@_]+$/;
    return regex.test(name);
};
export const isValidAttention = (name) => {
    var regex = /^[a-zA-Z0-9 &#',./:;@_]+$/;
    return regex.test(name);
};
export const isValidStreetAddress = (name) => {
    var regex = /^[ #&'(),-./0-9@A-Za-z]+$/;
    return regex.test(name);
};
export const isValidNum = (value) => {
    var regex = /^[0-9]+$/;
    return regex.test(value)
};
export const isValidPatientNum = (value,max) => {
    var regex = /^\d+$/;
    return regex.test(value) && value.length === max
};
export const isValidOtherDiag = (value) => {
    var regex = /^[&'*\dA-Za-z _:;]+$/;
    return regex.test(value)
};
export const isValidSystemId = (name) => {
    var regex = /^\d+$/;
    return regex.test(name);
};
export const isValidMckessonAcc = (value) =>{
    var regex = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
    return regex.test(value)
}
export const isValidQuantity = (number) => {
    var regex = /^[0-9]+$/;
    return regex.test(number);
};
export const isValidDirections = (name) => {
    var regex = /^[a-zA-Z0-9 .,;:_/()@#&'"\-\\]+$/; 
    return regex.test(name);
};