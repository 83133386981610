import axios from 'axios';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { axiosInstance, axiosInstanceSurvey, axiosInstance_2 } from 'constants/Config/axiosConfig';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { getConfirmedDispensation, resetDispenseHistory, setPatientData, setPatientId } from 'store/slices/appSlice';
import AuthorizationDetails from './AuthorizationDetails';
import DispenseDetails from './DispenseDetails';
import classes from './PatientDetails.module.scss';
import PatientEnrollments from './PatientEnrollments';
import PatientInfo from './PatientInfo';

const PatientDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { patientResponse, prescriberEnrollment, selectedPatient } = useSelector((state: RootState) => state.app);
    const { currentUser } = useSelector((state: RootState) => state.login);
    const [refdata, setRefdata] = useState([]);
    const [authDetails, setAuthDetails] = useState([]);
    const [patientRespData, setPatientRespData] = useState({});
    const [hideUnhideDetails, sethideUnhideDetails] = useState([]);
    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const isNonPresc = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');

    useEffect(() => {
        const fetchData = async () => {
            const refApi = axiosInstance.post(`/referencedata`, { keys: ['ICD-10'] });
            const patientApi = axiosInstance_2.post('/findPatient', {
                patientExternalUniqueId: selectedPatient.patientExtUniqueId,
            });
            const authApi = axiosInstanceSurvey.get(
                `/v1/dispensation/get-current-authorizations?patientId=${selectedPatient.patientId}`
            );
            const hideUnhideAPI = axiosInstanceSurvey.get(
                isNonPresc
                    ? `/v1/presc-patient?patientId=${selectedPatient.patientId}&nonPrescriberId=${currentUser?.nonPrescriberId}`
                    : `/v1/presc-patient?patientId=${selectedPatient.patientId}&prescriberId=${currentUser?.systemId}`
            );

            try {
                const [refResp, patientResp, authResp, hideUnhideResp] = await axios.all([
                    refApi,
                    patientApi,
                    authApi,
                    hideUnhideAPI,
                ]);
                setAuthDetails(authResp.data.statusCode === 204 ? [] : authResp.data.data);
                setRefdata(refResp.data.data);
                if (patientResp.data.message === 'SUCCESS') {
                    dispatch(setPatientData(patientResp.data.data));
                    dispatch(setPatientId(patientResp.data.data.patientId));
                    setPatientRespData(patientResp.data.data)
                }
                sethideUnhideDetails(hideUnhideResp?.data.statusCode === 200 ? hideUnhideResp.data.data : []);
            } catch (err) {
                console.error('Api Error');
            }
        };
        fetchData();
        dispatch(resetDispenseHistory())
    }, []);

    const getPatientName = () => {
        return (patientResponse.patientNameFirst + ' ' + patientResponse.patientNameLast).toUpperCase();
    };

    const returnToDashBoard = () => {
        navigate('/prescriber/dashboard');
    };

    return (
        <div ref={containerRef}>
            {!!Object.keys(patientRespData).length && (
                <PageBackground label="Patient Details" >
                    <SessionTimeout />
                    <div className={classes.action_btn}>
                        <button onClick={returnToDashBoard} className={classes.return_to_dashboard_btn} />
                    </div>
                    <div className={classes.content_container} >
                        <h3 className={classes.title}>Patient: {getPatientName()}</h3>
                        <PatientInfo patientDto={patientResponse} />
                        <PatientEnrollments
                            patientDto={patientResponse}
                            refdata={{ ...refdata, programs: prescriberEnrollment }}
                            getPatientName={getPatientName}
                            APIDetails={hideUnhideDetails}
                        />
                        <AuthorizationDetails authDetails={authDetails} patientDTO={patientResponse} />
                        <DispenseDetails selectedPatient={selectedPatient} scrollTo={scrollToView} />
                    </div>
                </PageBackground>
            )}
        </div>
    );
};

export default PatientDetails;
