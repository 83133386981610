import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './reactDatePicker.css';
import btnClosePopup from '../../assets/images/btnClosePopup.png';
import img_calendar from '../../assets/images/img_calendar.png';

// CustomDatePicker Component
const CustomDatePicker = ({ selectedDate, setSelectedDate, label="", id }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsOpen(false);
    };

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const renderCustomCalendar = (props) => (
        <div  style={{ backgroundColor: isOpen ? "#fff" : ""}}> 
            {props.children}
            <div
                onClick={() => setIsOpen(false)}
            >
                <span>
                    <img src={btnClosePopup} alt="text" className='btnClosePopup'/>
                </span>
            </div>
        </div>
    );

    return (
        <div>
            <label className='mandatory'>{label}</label>
            <span onClick={handleButtonClick}>
                <img src={img_calendar} alt="text" style={{marginTop: '10px', marginRight: '10px', marginLeft: '10px'}} className='imgCalendar'/> 
            </span>
            <DatePicker
                id={id}
                selected={selectedDate}
                onChange={handleDateChange}
                open={isOpen}
                onClickOutside={() => setIsOpen(false)}
                calendarContainer={renderCustomCalendar}
                showMonthDropdown
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                popperPlacement="bottom"
                dropdownMode="select"
            />
        </div>
    );
};

export default CustomDatePicker;
