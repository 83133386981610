import { Role } from 'constants/Config/appConfig';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store';
import indexedDBStore from 'store/indexedDBStore';

const PrivateRoute = ({ children }) => {
    const navigate = useNavigate();
    const { IsAuthenticated } = useAppSelector((state) => state.login);
    const { role } = useAppSelector((state) => state.app);
    const location = useLocation();
    useEffect(()=>{
 
        window.history.pushState(null, window.location.href);
         
        window.onpopstate = function () {
        window.history.pushState(null, window.location.href);
         
        }
        })
    const checkSession = async() => {
        const loginState = await indexedDBStore.getItem(`${location?.pathname?.split('/')[1]}User`);
        return loginState?.currentUser?.accessToken;
    }

    useEffect(() => {
        if (!IsAuthenticated && !checkSession()) {
            if (role === Role.Pharmacist) {
                navigate('/pharmacy/login', { replace: true });
                return;
            }
            navigate('/prescriber/login', { replace: true });
            return;
        }
    }, [IsAuthenticated, navigate]);

    return children;
};

export default PrivateRoute;
