export const reloadFunction = () => {
    const handleBeforeUnload = (event) => {
        const refreshReq=[
            'prescriber/prescribersurveysearch',
            'prescriber/patientInformation',
            'prescriber/patientagreementform',
            'prescriber/prescribersurveyinformation',
            'prescriber/patientSurvey',
            'prescriber/partiallyCompletedPPAFSearch',
            'prescriber/submittedPPAFSearch',
            'prescriber/patientAddressPPAFSearch',
            'prescriber/submittedPpafCreatedSearch',
            'prescriber/PatientSearch',
            'prescriber/prescriptionformdetails',
            'prescriber/prescriptionFormLang',
            'prescriber/prescriptionformpresinfo',
            'prescriber/prescriptionformdosage',
            'prescriber/dashboard/prescription-action-items',
            'prescriber/dashboard/find-a-patient',
            'prescriber/dashboard/prescriber-calendar',
            'prescriber/dashboard/review-authorization',
            'prescriber/dashboard/reports/expireReport',
            'prescriber/dashboard/reports/historicalReport',
            'prescriber/dashboard/reports/hiddenPatientReport',
            'prescriber/patientSurvey',
            'prescriber/patientSurvey?action=patientSurveyAction',
            'pharmacy/logdispensehome',
            'pharmacy/reviewAuthorization',
            'pharmacy/createAccount',
            'prescriber/forgotPassword',
            'prescriber/securityVerification',
            'prescriber/securityVerification',
            'prescriber/resetTempPassword',
            'prescriber/manageresetpassword',
            'pharmacy/manageresetpasswordPhar',
            'prescriber/createaccount',
            'prescriber/createaccountconfirmation',
            'prescriber/productRegistration',
            'prescriber/productSignUp',
            'prescriber/createAssociation',
        ]
        const pageUrl =  window?.location?.hash?.substring(2);
       
        if ( refreshReq.includes(pageUrl)){
                event.preventDefault();
                event.returnValue = '';
        }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
}