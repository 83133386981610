import Button from 'components/Button';
import classes from './login.module.scss';
import { useState, useEffect } from 'react';
import { persistStore } from 'redux-persist';
import { useNavigate } from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from 'store';
import {
    resetApp,
    setLoginPage,
    handlePrescriber,
    setAccFormDetails,
    resetPatientSurvey,
    fetchCurrentPrescriber,
    resetProgram,
    resetPatientInformation,
    resetpatientPrescriberStore,
    deleteIsWritePrescription,
    changePPAF,
} from 'store/slices/appSlice';
import ErrorModal from '../../components/ErrorModal';
import { Role, RoleByGroup } from 'constants/Config/appConfig';
import {
    setLogOut,
    setErrorLogin,
    authenticateUser,
    setpopUpTemplate,
    setaccessCodeFlag,
    setEnrollmentStatusError,
    setResetUserName,
} from 'store/slices/loginSlice';
import Alert from 'components/Alert';
import i18n from 'internationalization/i18next';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import indexedDBStore from 'store/indexedDBStore';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import MigratePopUp from 'components/MigratePopUp';
import { LOGIN_ERR_MSG, MAX_LOGIN_FAILURE } from 'utils/constant';
import { congitoResMapper } from 'utils/congitoResMapper';

const Login = () => {
    const { error, IsAuthenticated, currentUser, resetUserName, currentUserPharmacy } = useAppSelector(
        (state) => state.login
    );
    const [errorForm, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loginPage } = useAppSelector((state) => state.app);
    const routeMaps = [{ route: '/prescriber/prescriberSelection', nextStep: 'Prescriber_Selection_Sreen' }];
    const checkRoutes = routeMaps.find((path) => path.nextStep === 'Prescriber_Selection_Sreen');
    const path = checkRoutes ? checkRoutes.route : '/prescriber/login';
    const [loginFlag, setLoginFlag] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [loginErr, setLoginErr] = useState('');
    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loginFailCount, setLoginFailCount] = useState(0);

    const [values, setValues] = useState({
        username: '',
        password: '',
    });
    sessionStorage.removeItem('documentTitle');
    sessionStorage.removeItem('contactUsBody');

    const focusField = (name) => {
        const inputElement = document?.getElementById(name) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
    };

    useEffect(() => {
        const handleBrowserBack = () => {
            if (loginPage) {
                window.onpopstate = null;
                navigate('/prescriber/login', { replace: true });
            } else {
                window.onpopstate = null;
            }
        };
        window.onpopstate = handleBrowserBack;
    }, []);
    useEffect(() => {
        window.history.pushState(null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, window.location.href);
        };
    });

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(setErrorLogin(''));

        const trimvalues = { ...values };
        Object.keys(trimvalues).forEach((field) => {
            trimvalues[field] = trimvalues[field].trim();
        });
        setValues(trimvalues);

        if (trimvalues.username.length === 0) {
            setError('Please enter your User Name.');
            return;
        } else if (trimvalues.password.length === 0) {
            setError('Please enter your Password.');
            return;
        } else {
            setLoader(true);
            dispatch(authenticateUser({ username: trimvalues.username, password: trimvalues.password })).then(
                (res: any) => {
                    if (res?.payload?.message === LOGIN_ERR_MSG) {
                        if (loginFailCount >= MAX_LOGIN_FAILURE - 1) setShowModal(true);
                        setLoginFailCount((prev) => prev + 1);
                    }
                    if (
                        res?.payload?.$metadata?.httpStatusCode === 401 ||
                        res?.payload?.$metadata?.httpStatusCode === 403
                    ) {
                        navigate('/prescriber/SystemError');
                    }
                    if (res?.meta?.requestStatus === 'fulfilled') {
                        dispatch(setResetUserName({ ...resetUserName, prescriber: values.username }));
                        if (res?.payload?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                            dispatch(handlePrescriber(Role.Prescriber));

                            navigate('/prescriber/resetSecurity', {
                                replace: true,
                                state: { userName: values.username },
                            });
                        } else {
                            const authRes = congitoResMapper(res?.payload?.AuthenticationResult || {});
                            indexedDBStore.setItem('prescriberUser', {
                                currentUser: { ...authRes?.currentUser, isAuthenticated: true },
                            });
                            setLoginFlag(true);
                        }
                    }
                    setLoader(false);
                }
            );
        }
    };

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const updateUserinfo = () => {
        const storeData = {
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            officeAddress: currentUser?.userinfo?.address?.formatted,
            city: currentUser.userinfo['custom:city'],
            state: currentUser.userinfo['custom:state'],
            zipCode: currentUser.userinfo['custom:zip_code'],
            phoneNumber: currentUser.userinfo?.phone_number
                ? currentUser.userinfo?.phone_number?.replace(/^\+1\s*|\s*/g, '')
                : null,
            email: currentUser.userinfo.email,
            userName: currentUser.userinfo.username,
            securityQuestion: currentUser.userinfo['custom:security_question'],
            securityAnswer: currentUser.userinfo['custom:security_answer'],
        };
        sessionStorage.setItem('accountData', JSON.stringify(storeData));
        dispatch(setAccFormDetails(storeData));
        if (currentUser.groupTag?.toLowerCase()?.includes('nonprescriber')) {
            dispatch(handlePrescriber(Role.NonPrescriber));
        } else {
            dispatch(handlePrescriber(Role.Prescriber));
        }
    };
    const loginRedirection = async () => {
        const loginState = await indexedDBStore.getItem('prescriberUser');
        if (IsAuthenticated && loginState?.currentUser?.accessToken) {
            await indexedDBStore.setItem('prescriberUser', { currentUser: { ...currentUser, isAuthenticated: true } });
            if (currentUser?.userStatus === 'FORCE_CHANGE_PASSWORD') {
                if (currentUser.groupTag !== RoleByGroup.Pharmacist) {
                    dispatch(setResetUserName({ ...resetUserName, prescriber: values.username }));
                    navigate('/prescriber/resetSecurity', { replace: true });
                    dispatch(setLoginPage(false));
                    return;
                } else {
                    setLoginErr(
                        'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.'
                    );
                    return;
                }
            }
            await updateUserinfo();
            switch (currentUser.groupTag.toLowerCase()) {
                case RoleByGroup.Prescriber.toLowerCase(): {
                    if (
                        currentUser.systemId === undefined ||
                        currentUser.systemId === '' ||
                        currentUser.systemId === null
                    ) {
                        navigate('/prescriber/createaccountconfirmation', { replace: true });
                        dispatch(setLoginPage(false));
                    } else {
                        const payload = currentUser?.systemId
                            ? {
                                  prescriberId: currentUser?.systemId,
                              }
                            : {
                                  onlineUserId: currentUser.userinfo?.username,
                              };
                        dispatch(setaccessCodeFlag(true));
                        dispatch(fetchCurrentPrescriber(payload)).then((response: any) => {
                            if (response.payload.status === 200) {
                                const enrollmentPayload: any[] = response.payload.data?.data?.prescriberRegStatuses;
                                if (
                                    enrollmentPayload?.filter(
                                        (x) => x.registrationStatus.registrationStatus === 'Active'
                                    ).length > 0
                                ) {
                                    navigate('/prescriber/LoginHome', { replace: true });
                                    dispatch(setLoginPage(false));
                                } else {
                                    navigate('/prescriber/systemError', { replace: true });
                                    dispatch(setLoginPage(false));
                                    dispatch(setEnrollmentStatusError(true));
                                }
                            } else {
                                navigate('/prescriber/systemError', { replace: true });
                            }
                        });
                    }
                    break;
                }
                case RoleByGroup.NonPrescriber.toLowerCase(): {
                    const payload = { prescriberId: currentUser.userinfo['custom:prescriber_user_id'] };
                    dispatch(fetchCurrentPrescriber(payload)).then((response: any) => {
                        if (response?.payload?.status === 200) {
                            const enrollmentPayload: any[] = response.payload.data?.data?.prescriberRegStatuses;
                            if (
                                enrollmentPayload?.filter((x) => x.registrationStatus.registrationStatus === 'Active')
                                    .length > 0
                            ) {
                                navigate('/prescriber/LoginHome', { replace: true });
                                dispatch(setLoginPage(false));
                            } else {
                                navigate('/prescriber/systemError', { replace: true });
                                dispatch(setLoginPage(false));
                                dispatch(setEnrollmentStatusError(true));
                            }
                        }
                    });
                    break;
                }
                case RoleByGroup.Pharmacist.toLowerCase(): {
                    setLoginErr(
                        'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.'
                    );
                    break;
                }
            }
        } else {
            //setLoginFlag(true);
        }
    };
    useEffect(() => {
        i18n.changeLanguage('en');
        loginRedirection();
    }, [IsAuthenticated, loginFlag, currentUser]);

    const handleOnChange = (e) => {
        errorForm && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const togglePswd = () => {
        setVisible(!isVisible);
    };

    const handleErrorBtn = () => {
        const focusName = errorForm.includes('password') ? 'password' : 'username';
        focusField(focusName);
        setError('');
    };

    const onForgotPswdClick = () => {
        navigate('/prescriber/forgotPassword', { replace: true });
        dispatch(setLoginPage(false));
    };

    useEffect(() => {
        if (error.message || loginErr) {
            setValues({
                username: '',
                password: '',
            });
        }
    }, [error.message, loginErr]);

    async function fetchData() {
        try {
            const response = await fetch('./resources/popup.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData().then((res) => dispatch(setpopUpTemplate(res?.popup)));
    }, []);

    return (
        <>
            <MigratePopUp showModal={showModal} setShowModal={setShowModal} />
            <div className={classes.loginContentContainer}>
                <div className={classes.loginForm}>
                    <div className={classes.commonStyles}>
                        <div className={classes.heading1}>Prescriber Login</div>
                        <div className={classes.toLogin}>To login to your account: </div>
                        {(error.message || loginErr) && (
                            <Alert
                                content={error.message || loginErr}
                                styles={{ fontSize: '11px', margin: '10px 20px 5px 20px' }}
                            />
                        )}
                        <div className={classes.formGroup}>
                            <label className={classes.logincontent} htmlFor="username">
                                User Name
                            </label>
                            <input
                                onKeyDown={(e) => e.key === 'Enter' && handleLogin(e)}
                                type="text"
                                id="username"
                                name="username"
                                onChange={handleOnChange}
                                maxLength={20}
                                value={values.username}
                                className={classes.loginValue}
                            />
                        </div>
                        <div className={classes.formGroup}>
                            <label className={classes.logincontent} htmlFor="password">
                                Password
                            </label>
                            <div className={classes.inputContainer}>
                                <div
                                    className={classes.passwordContainer}
                                    onBlur={(e) => {
                                        !e.relatedTarget
                                            ? e.target.id === 'password' && setVisible(false)
                                            : e.relatedTarget.id !== 'password' && setVisible(false);
                                    }}
                                >
                                    <input
                                        onKeyDown={(e) => e.key === 'Enter' && handleLogin(e)}
                                        id="password"
                                        name="password"
                                        onChange={handleOnChange}
                                        type={isVisible ? 'text' : 'password'}
                                        maxLength={20}
                                        value={values.password}
                                    />
                                    {values?.password !== '' && (
                                        <button id="password" className={classes.pswdIcon} onClick={togglePswd}>
                                            {isVisible ? <VscEyeClosed /> : <VscEye />}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.submitbutton}>
                            <div
                                onClick={onForgotPswdClick}
                                onKeyDown={(e) => e.key === 'Enter' && onForgotPswdClick()}
                                tabIndex={0}
                                className={classes.forgotPassword}
                            >
                                Forgot Password?
                            </div>
                            {loader ? (
                                <div className={classes.loader}>
                                    <Flex align="center" gap="middle">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                                    </Flex>
                                </div>
                            ) : (
                                <Button
                                    className="buttonStyles"
                                    type="text"
                                    clickFunction={handleLogin}
                                    handleKeyPress={true}
                                    buttonname="Login"
                                    width={96}
                                    height={36}
                                    size={14}
                                ></Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className={classes.buttonWrapper}>
                    <Button
                        clickFunction={() => {
                            navigate(path);
                            dispatch(setLoginPage(false));
                        }}
                        className="loginBtn"
                        type="text"
                        buttonname="Create User Account"
                        size={14}
                        width={188}
                        height={36}
                    ></Button>
                    <Button
                        clickFunction={() => {
                            i18n.changeLanguage('en');
                            dispatch(resetProgram());
                            dispatch(resetPatientSurvey());
                            navigate('/prescriber/patientSurvey', { replace: true });
                            dispatch(setLoginPage(false));
                        }}
                        className="loginBtn"
                        width={188}
                        size={14}
                        height={36}
                        type="text"
                        buttonname="Patient Surveys"
                    ></Button>
                    <Button
                        clickFunction={() => {
                            i18n.changeLanguage('es');
                            dispatch(resetProgram());
                            dispatch(resetPatientSurvey());
                            navigate('/prescriber/patientSurvey', { replace: true });
                            dispatch(setLoginPage(false));
                        }}
                        className="loginBtn"
                        width={188}
                        size={14}
                        height={36}
                        type="text"
                        buttonname="Encuesta del paciente"
                    ></Button>
                </div>
                {errorForm && <ErrorModal error={errorForm} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
};

export default Login;
