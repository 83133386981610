const mainmanu =()=>{
    //return window.location.href.toString().toLowerCase().replace('ppafselection','MainMenu');
    return "../#/prescriber/MainMenu";
}

export const ppfselectionContentEng = {
    body:[
        {
            order:1,
            content:`The patient information has been saved, but has not yet been completely processed.`
        },
        {
            order:2,
            content:`To continue processing the PPAF online, select the "Process the PPAF online" button below. The form <br>will be presented for electronic signatures and electronic submission to Bristol Myers Squibb for processing.`
        },
        {
            order:3,
            content:`Press "Create a FAXable PPAF" to generate a printable PPAF to FAX to Bristol Myers Squibb. You must <br>have a printer connected to print the FAXable PPAF.`
        },
        {
            order:4,
            content:`If interrupted, the information entered may be retrieved from the Main Menu option "Work with a <br>Saved/Submitted PPAF".`
        },
        {
            order:5,
            content:`To return to the Main Menu, <a class="bold underline" href="${mainmanu()}">click here</a> or select the "Main Menu" button above.`
        }

    ],
    subheader:'PPAF Selection'
}

export const ppfselectionContentSpanish = {
    body:[
        {
            order:1,
            content:`La información del paciente se ha guardado, pero no ha sido aún procesada de forma completa.`
        },
        {
            order:2,
            content:`Para continuar el procesamiento del PPAF (Formulario de acuerdo entre paciente y médico) por Internet, <br>seleccione el botón "Procesar el PPAF por internet" que se encuentra abajo. Este formulario será <br>presentado para firmas electrónicas y envío electrónico a Bristol Myers Squibb para su procesamiento.`
        },
        {
            order:3,
            content:`Oprima "Crear un PPAF para enviar por FAX" para generar un PPAF que se pueda imprimir y enviar por <br>FAX a Bristol Myers Squibb. Debe tener una impresora conectada para imprimir un PPAF enviar por FAX.`
        },
        {
            order:4,
            content:`Si se interrumpe, la información ingresada puede recuperarse de la opción del Menú principal "Trabajar <br>con un PPAF guardado/enviado".`
        },
        {
            order:5,
            content:`Para regresar al Menú principal,  <a class="bold underline" href="${mainmanu()}">haga clic aquí</a> o seleccione el botón de "Menú principal" que se encuentra <br>arriba.`
        }

    ],
    subheader:'Selección de PPAF'
}