import React, { useEffect, useState } from 'react';
import classes from './PPAFSelection.module.scss';
import { ppfselectionContentEng, ppfselectionContentSpanish } from './../../assets/objects/ppafSelectionContent';
import { useNavigate } from 'react-router-dom';
import { axiosInstance_PDF } from 'constants/Config/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { setPPAFContent } from 'store/slices/appSlice';
import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';

const PPAFSelection = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const { selectedPatientProgram } = useAppSelector((state) => state.app);
    const ppfselectionContent = i18n.language === 'en' ? ppfselectionContentEng : ppfselectionContentSpanish;
    const { patientResponse, patientInformation } = useSelector((state: RootState) => state.app);

    const checkYesNo = (value: string) => {
        return value.toLowerCase() === 'no' || value.toLowerCase() === 'n' ? 'N' : 'Y';
    };

    const handleCreateFaxiblePPAFBtn = async () => {
        try {
            var _patientResponse = JSON.parse(JSON.stringify(patientResponse));
            let reqBody: any = {
                patientDto: {
                    ..._patientResponse,
                    channelId: 'ext',
                    patientRiskCategoryDto: {
                        gender: _patientResponse.patientGender,
                        riskCategory: patientInformation.newRisk,
                        menstruating: checkYesNo(patientInformation.menstrating),
                        naturalMenopause: checkYesNo(patientInformation.natural),
                        riskCategoryDescription: patientInformation.newRisk,
                        surgicalMenopause: checkYesNo(patientInformation.surgical),
                    },
                },
                notificationFax: null,
                notificationEmail: null,
                currentStep: 'enrollment',
                action: 'print',
                patientSignature: null,
                patientSignatureDate: null,
                prescriberSignature: null,
                prescriberSignatureDate: null,
                language: i18n.language,
                signatory: null,
            };
            const response = await axiosInstance_PDF.post('/file-render/pdf/ppaf', reqBody);
            if (response.data.statusCode === 200) {
                sessionStorage.setItem('baseString', JSON.stringify(response.data.data.pdf));
                sessionStorage.setItem('fileName', JSON.stringify('patientPPAF'));

                window.open('/#/pdfViewer', '_blank');
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`Error getting pdf ${err}`);
        }
    };
    const productType = ProgramConfig(selectedPatientProgram.id).label;
      

    const handlePPAFOnlineBtn = async () => {
        try {
            const response = await axiosInstance_PDF.get('/file-render/pdf/ppaf/consent-statements', {
                params: {
                    product: productType,
                    riskCategory: patientInformation.newRisk,
                    language: i18n.language,
                },
            });
            if (response.data.statusCode === 200) {
                dispatch(setPPAFContent(response.data.data));
                navigate('/prescriber/patientagreementform', { replace: true });
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`Error getting pdf ${err}`);
        }
    };

    return (
        <>
            <SessionTimeout />
            <div className={`${classes.ppafSelection} ${classes[selectedPatientProgram.id]} `}>
                <div className={`${classes.mainContainer} ${classes[i18n.language]}`}>
                <div className={classes.headerContainer}>
                    {selectedPatientProgram.id && <span className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedPatientProgram.id).headerImage}`)})`
                    }}></span>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedPatientProgram.id).color}`
                    }}></div>
                </div>
                    <div className={classes.productHeader}>{ppfselectionContent.subheader}</div>
                    <div className={classes.contentDivider}></div>
                    <div className={classes.bodyContainer}>
                        <div className={classes.bodyContent}>
                            {ppfselectionContent.body.map((item) => (
                                <p key={item.order} dangerouslySetInnerHTML={{ __html: item.content }}></p>
                            ))}
                            <div className={classes.buffer}></div>
                            <div className={classes.actionbutton}>
                                <Button
                                clickFunction={handlePPAFOnlineBtn}
                                className={getBtnClass(selectedPatientProgram.id)}
                                width={i18n.language === 'es'? 330 : 265}
                                height={i18n.language === 'es'? 105 : 48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.ppafOnline : buttonContent.en.ppafOnline}
                               
                            ></Button>

                        <Button
                                clickFunction={handleCreateFaxiblePPAFBtn}
                                className={getBtnClass(selectedPatientProgram.id)}
                                width={i18n.language === 'es'? 330 : 265}
                                height={i18n.language === 'es'? 105 : 48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.faxablePpaf : buttonContent.en.faxablePpaf}
                            ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PPAFSelection;
