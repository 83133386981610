import PageBackground from "components/PageBackground"
import { LogDispenseScreens } from "./logDispenseType"
import classes from "./logDispense.module.scss"
import { Navigate, useNavigate } from "react-router"
const DefaultLocation = () => {
	const navigate=useNavigate()
	const handleContinue = () => {
		navigate('/pharmacy/manageAccount')
	}

    return (
        <PageBackground label={"Reset Default Location"} btn={true} handleClick={handleContinue}>
           <div className={classes.contentContainer}>                    
                    <div className={classes.contentPadding}>
                        <div className={classes.textPadding}>
                        <div className={classes.instructions}>The default Pharmacy Location is no longer available.</div>
                        <div className={classes.instructions}>Please click Continue to proceed to the Manage My Account page and select a new default Pharmacy Location.</div>                        
                        </div>
                    </div>
                </div>
        </PageBackground>
    )
}

export default DefaultLocation;
