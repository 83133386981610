import moment from 'moment';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { changePPAF, changeProgram, setIsWritePrescription, setPatientData, setSurveyDataFromPatientDetails, setSurveyInfoFromRoute } from 'store/slices/appSlice';
import classes from './PatientDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { axiosInstanceSearch, axiosInstanceSurvey } from 'constants/Config/axiosConfig';
import rightArrow from "../../assets/images/btn_arrow_right.png";
import { useEffect, useState } from 'react';
import { PROGRAMS } from 'constants/Config/programsConfig';
import PendingEnrollmentModal from 'components/PendingEnrollment';
import EnrollmentModal from 'components/EnrollmentModal';
import { bodyContent } from 'assets/objects/LoginHome';
import { RoleByGroup } from 'constants/Config/appConfig';


const AuthorizationDetails = ({ authDetails, patientDTO }) => {
    const dispatch = useAppDispatch();
    const programList : any = PROGRAMS;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { prescriberDetails, selectedPatient, prescriberEnrollment } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const [displayProd, setDisplayProd] = useState<any[]>([]);
    const [pendingEnrollment, setPendingEnrollment] = useState(false);
    const [enrollmentMessage, setEnrollmentMessage] = useState<any>('');
    const [notEnrolled, setNotEnrolled] = useState(false);

    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails?.prescriberId);

    const changePgmCase = (name) => (name === 'Thalomid' ? name.toUpperCase() : name);


    useEffect(() => {
        const data = tableData();
        setDisplayProd(data);
    }, [patientDTO, authDetails]);


    const tableData = () => {
        let tableobj: any = [];
        let regArr = patientDTO?.patientRegistrationForms;

        if (regArr?.length > 0) {
            regArr?.filter((ob) => ob.dateEnded === null)
                ?.sort((a, b) => a?.productCountry?.product?.productName.localeCompare(b?.productCountry?.product?.productName))
                ?.map((item) => {
                    // Getting authdata of particular prog, if authAPI has data or will be false.
                    const pmgAuthData = (!!authDetails?.length) ? authDetails.find(
                        (authObj) => authObj?.productCountry?.productCountryId === item.productCountry?.productCountryId) : false;
                        
                    // And accordingly updating the pgmObj (that has initial PatientDTO data)
                  
                    let singlePgmObj = {
                        productName: item?.productCountry?.product?.productName,
                        name: changePgmCase(item?.productCountry?.product?.productName) + ' (' + item?.patientRegStatus?.[0]?.registrationStatus.lookupValue + ')',
                        number: pmgAuthData ? (pmgAuthData.authorizationNumber?.slice(2) || '') : '',
                        authDate: pmgAuthData ? (pmgAuthData?.effectiveDate ? moment(pmgAuthData.effectiveDate).format('MMM DD, YYYY') : '') : '',
                        expDate: pmgAuthData ? (pmgAuthData?.activeStatusExpirationDate ? moment(pmgAuthData.activeStatusExpirationDate).format('MMM DD, YYYY') : '') : '',
                        authStatus: pmgAuthData ? (pmgAuthData?.authorizationStatus?.lookupValue || '') : 'Prescriber Survey Required',
                        patientStatus: item?.patientRegStatus?.[0]?.registrationStatus.lookupValue || '',
                        daysSupply: pmgAuthData ? (pmgAuthData?.daysSupply || '') : '',
                        productCountryId: pmgAuthData?.productCountry?.productCountryId || item.productCountry?.productCountryId,
                        outstandingAuth: pmgAuthData ? pmgAuthData?.hasOutstandingAuth : '',
                        authage: pmgAuthData ? pmgAuthData?.authAge : '',
                        riskC: patientDTO?.patientRiskCategory?.find((risk) => !risk.endDate)?.riskCategoryName || '',
                        prescriberId:pmgAuthData ? pmgAuthData?.prescriberId:'',
                        dispensationDate: pmgAuthData ?  (pmgAuthData?.dispensationDate ? moment(pmgAuthData.dispensationDate).format('MMM DD, YYYY') : '') : '',
                    };

                    //If active then push the obj
                    if (singlePgmObj?.patientStatus === 'Active') {
                        // if patient = Active, authstatus = Obsolete & authage > 365, then dont push singlePgmObj
                        if (
                            !(singlePgmObj.authStatus === 'Obsolete' &&
                                singlePgmObj.authage >= 366)
                        )
                            return tableobj?.push(singlePgmObj);
                        else
                            return [...tableobj];
                    }
                    else {
                        //if authnumb there, then only push for pending/deactive patient 
                        if (!!singlePgmObj?.number) return tableobj?.push(singlePgmObj)
                        else
                            return [...tableobj];
                    }
                });
        } else {
            // if no rems prog in PatientDTO => 'No records found'
            tableobj = [];
        }

        return tableobj;
    };


    const enableWrtPreBtn = (patientStatus, authStatus, daysSupply, authDate, outstandingAuth, authage, riskC, dispensationDate) => {
        const todaysDate = moment()
        const checkPrescriberSurveyRequired = daysSupply - todaysDate.diff(dispensationDate, 'days')
        if (patientStatus === 'Active') {
            switch (authStatus) {
                case 'Active':
                    return [true, 'Active-Ready for Pharmacy'];
                case 'Patient Survey Required':
                    return [true, authStatus];
                case 'Obsolete':
                    return [false, 'Cancelled-'];
                case 'Confirmed':
                    if (checkPrescriberSurveyRequired <= 7) return [false, 'Prescriber Survey Required'];
                    else
                    return [false, 'Pharmacy Confirmed Rx'];               
                case 'Mismatched':
                    return [true, 'Survey-Flagged:'];
                case 'Expired':
                    return [false, 'Expired-'];
                default:
                    return [false, authStatus];
            }
        } else if (patientStatus === 'Pending' || patientStatus === 'Deactivated') {
            return [false, 'Call REMS Call Center'];
        } else return [false, authStatus];
    };

    const actionLink = (status: string, row:any) => {
        if (['Cancelled-', 'Expired-'].includes(status)) {
            return (<>
                {status}
                <p className={`${classes.PSRLink} ${classes.marginLink}`} onClick={() => { goToSurvey(row) }} >Prescriber Survey Required <img src={rightArrow} alt="right-arrow" /></p>
            </>);
        } else if (['Survey-Flagged:'].includes(status)) {
            return (<>
                {status}
                <p className={`${classes.PSRLink} ${classes.marginLink}`} onClick={() => { onHandleContactUs() }} >Call REMS Call Center <img src={rightArrow} alt="right-arrow" /></p>
            </>);
        } else if (['Prescriber Survey Required', 'Call REMS Call Center'].includes(status)) {
            return (
                <p className={classes.PSRLink}
                    onClick={() => { status === 'Call REMS Call Center' ? onHandleContactUs() : goToSurvey(row) }}>
                    {status} <img src={rightArrow} alt="right-arrow" />
                </p>
            );
        } else return (<p>{status}</p>);
    };

    const goToSurvey = async (row) => {
        const payload = {
            systemId: prescriberId,
            program: row?.productName,
            enrolmentStatus: 'Active',
        };
        try {
            const resp = await axiosInstanceSearch.post('/prescriberSearch', payload);
            if(RoleByGroup.NonPrescriber !== currentUser.groupTag) {
            if (!resp.data.data?.length) {
                if (prescriberEnrollment?.filter((ob) => ob?.productCountryId === row?.productCountryId)?.length > 0) {
                    // pending or deactivate
                    setPendingEnrollment(true); 
                } else {
                    // not enrolled for pgm
                    setEnrollmentMessage(bodyContent.programs?.find((pgm) => pgm.name?.split(' ')[0]?.toLowerCase() === row?.productName.toLowerCase())?.title?.split('For ')?.[1]);
                    const selectedPgm = programList.find((i) => i.productCountryId ===  row?.productCountryId);
                    dispatch(changeProgram(selectedPgm));
                    setNotEnrolled(true);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                PSRSurveyAPI(row?.productCountryId);
            }
        } else {
            PSRSurveyAPI(row?.productCountryId);
        }
        } catch (err) {
            console.error(err);
        }
    };

    const navigateToPrescriberSurvery = (data) => {
        if(nonPrescriber){
            dispatch(setSurveyDataFromPatientDetails(data))
            navigate('/prescriber/PrescriberSearch');
        }else{
            dispatch(setSurveyInfoFromRoute(data));
            dispatch(changePPAF(true));
            navigate('/prescriber/prescribersurveyinformation');
        }
    }

    const findPrescSystemId = (productId) => {
    let prescriber =  patientDTO?.patientPrescribers?.filter((product, i) => product?.status?.toLowerCase() === 'active' && product?.productCountryId === productId)
        return prescriber[0].prescriberId
    }

    const PSRSurveyAPI = async (productId) => {
        dispatch(changeProgram(PROGRAMS.find((i) => i.productCountryId === productId)));
        const payload = {
            Channel: 'EXT',
            PrescriberId: findPrescSystemId(productId),
            PatientId: patientDTO?.patientId,
            ProductCountryId: productId,
            LanguageId: i18n.language === 'es' ? 2 : 1,
        };
        let response = await axiosInstanceSurvey
            .post('/v1/surveys/ivr-validate-prescriber-survey-eligibility', payload)
            .then((res: any) => {
                if (res.data?.ResponseStatus === '0') {
                    navigateToPrescriberSurvery(res.data)
                } else if (res.data?.ResponseStatus === '1') {
                    if (res.data.SurveyIneligibleMessage) {
                        navigateToPrescriberSurvery({surveryErrMessage: res?.data?.SurveyIneligibleMessage})
                    } else {
                        const errorMessage = 'program not active';
                        navigateToPrescriberSurvery({surveryErrMessage:errorMessage})
                    }
                }
            })
            .catch((error) => {
                navigate('/prescriber/SystemError');
            });
    };

    const onHandleContactUs = () => {
        window.open(
            `${window.location.origin}/#/ContactUs`,
            'contactUs',
            'location=yes,height=400,width=820,scrollbars=yes,status=yes'
        );
    };

    const handlePrescriptionLink = (productId, authorizationNumber,prescriberId) => {       
        
        dispatch(changeProgram(PROGRAMS.find((i) => i.productCountryId === productId)));
        const surveyData = [{ authorizationNumber }]
        dispatch(setPatientData({ ...patientDTO, surveyData }));
        if(nonPrescriber)
        {
            dispatch(setIsWritePrescription(true));
            navigate('/prescriber/PrescriberSearch');
        }
        else{
            navigate('/prescriber/prescriptionForm');
        }
        
    };

    const handlePenEnrollOutsideClick = (e) => {
        if (e.target.className.indexOf('pendingEnrollment_modalContainer') === 0) {
            setPendingEnrollment(false);
        }
        e.stopPropagation();
    };

    const handleEnrollmentCancel = () => {
        setNotEnrolled(false);
    };

    const handleEnrollmentContinue = () => {
        navigate('/prescriber/productRegistration');
    };

    return (
        <div className={classes.auth_info}>
             {pendingEnrollment && (
                <PendingEnrollmentModal message="" handleOutsideClick={(e) => handlePenEnrollOutsideClick(e)} />
            )}
            {notEnrolled && (
                <EnrollmentModal
                    message={enrollmentMessage}
                    handleCancel={handleEnrollmentCancel}
                    handleContinue={handleEnrollmentContinue}
                />
            )}
            <p className={classes.sub_title}>Current Authorizations</p>
            <table className={classes.common_table}>
                <thead>
                    <th>REMS Program</th>
                    <th>
                        <p className={classes.cell_title}>Authorization</p>
                        <div>
                            <p>Number</p>
                            <p>Date Authorized</p>
                            <p>Expires</p>
                        </div>
                    </th>
                    <th>
                        <span className={classes.important}>!</span>Status and Action Required
                    </th>
                    <th>Prescription Form</th>
                </thead>
                <tbody>
                    {!!displayProd.length ? displayProd?.map((row) => {
                      
                        const [isbtnEnable, tableStatus] = enableWrtPreBtn(
                            row?.patientStatus, row?.authStatus, row?.daysSupply, row?.authDate, row?.outstandingAuth, row?.authage, row?.riskC, row?.dispensationDate
                        );
                        return (
                            <tr>
                                <td>{row.name}</td>
                                <td>
                                    <div>
                                        <p>{row.number}</p>
                                        <p>{row.authDate}</p>
                                        <p>{row.expDate}</p>
                                    </div>
                                </td>
                                <td> {actionLink(tableStatus, row)} </td>
                                <td>
                                    <button
                                        tabIndex={0}
                                        className={isbtnEnable ? classes.activeBtn : classes.disabledBtn}
                                        onClick={() => handlePrescriptionLink(row.productCountryId, row.number,row.prescriberId)}
                                        disabled={!isbtnEnable}
                                    />
                                </td>
                            </tr>)
                    }) : <tr><td colSpan={5} className={classes.empty_data}>{t('noRecords')}</td></tr>}
                </tbody>
            </table>
        </div>
    );
};

export default AuthorizationDetails;
