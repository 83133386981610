export const authStatusCustomMapping = (responseData, flag) => {
    let arr: any = [];
    if(responseData?.length>0){
        arr = [...responseData, { id: '0', value: 'null', code: 'null' }]
    };
    const responseArray = (flag === 'actionitem') ? arr : responseData || [];
    const mapping = {
        'Patient Survey Required': 'Patient Survey Required',
        Mismatched: 'Survey Flagged: Call REMS Call Center',
        Active: 'Active-Ready for Pharmacy',
        Obsolete: 'Prescriber Survey Required',
        Confirmed: 'Prescriber Survey Required',
        Expired: 'Prescriber Survey Required',
        null: 'Prescriber Survey Required'
    };

    const mappedArray = responseArray?.reduce((acc, item) => {
        const mappedValue = mapping[item.code];
        
        if (acc[mappedValue]) {
            if (mappedValue === 'Prescriber Survey Required') {
                acc[mappedValue].id?.push(Number(item.id));
                acc[mappedValue].code?.push(item.code);
            } else {
                acc[mappedValue].id = Number(item.id);
                acc[mappedValue].code = item.code;
            }
        } else {
            acc[mappedValue] = {
                value: mappedValue,
                id: mappedValue === 'Prescriber Survey Required' ? [Number(item.id)] : Number(item.id),
                code: mappedValue === 'Prescriber Survey Required' ? [item.code] : item.code,
            };
        }
        return acc;
    }, {});

    const mappedAuthStatuses = Object.values(mappedArray);
    mappedAuthStatuses?.sort((a: any, b: any) => a.value?.localeCompare(b.value));
    return mappedAuthStatuses;
}