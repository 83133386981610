import { getStringBetween, getURL } from 'utils/getStringBetween';

let HASHKEY, ENCRYPTION_KEY, CLIENT_ID;

const urlKeys = [
    'BASE_URL_EXP',
    'BASE_URL_ORC',
    'BASE_URL_PDF',
    'BASE_URL',
    'BASE_URL_AUTH',
    'BASE_URL_PATIENT',
    'BASE_URL_PATIENT_ORC',
    'BASE_URL_VIEW_PPAF',
    'BASE_URL_ORCHAPI',
    'BASE_URL_SEARCH',
    'BASE_URL_SURVEY',
    'BASE_URL_PHARMACY',
    'BASE_URL_PRESCRIBER',
    'COGNITO_USERNAME',
    'COGNITO_USERDETAILS',
    'BASE_URL_PRESCRIBER_UNSECURE',
    'BASE_URL_PRESCRIBER_UNSECURE_VALIDATE_PHARMACY',
    'BASE_URL_PHARMACY_UNSECURE',
    'BASE_URL_SURVEY_UNSECURE',
    'BASE_URL_SEARCH_UNSECURE',
    'BASE_URL_ENTERPRISE_UNSECURE',
];
let url = window.location.origin;
const hostIdentifier = ['test', 'dev2', 'test2', 'val'];
const prodEnvCheck = url.includes('remsprod') || url.includes('bmsremspatientsafety');
const checkEnv= prodEnvCheck ? 'prod' : getStringBetween(url, 'rems', '.web');
const currentEnv = checkEnv ==='' ? getURL(url,hostIdentifier,checkEnv) : checkEnv;
const isUAT = currentEnv === 'prod' ? '' : currentEnv === 'val' ? '-uat' : '-dev';
const isCurrentEnvFlag = prodEnvCheck ? true : hostIdentifier.includes(currentEnv);


const APIURLS = urlKeys?.reduce((acc, key) => {
    const envVariable = process.env[`REACT_APP_${key}`];
    if (envVariable) {
        acc[key] = envVariable
            .replace('_ENV_', isCurrentEnvFlag ? currentEnv : 'dev')
            .replace('_HOST_ENV_', isCurrentEnvFlag ? isUAT : '-dev')
            .replace('_WEBKIT_', url.includes('localhost') ? 'https://remsdev2.web-dev.bms.com' : url )
    }
    return acc;
}, {});

switch (currentEnv) {
    case 'prod':
        HASHKEY = process.env.REACT_APP_HASHKEY_PROD;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY_PROD;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID_PROD;
        break;
    case 'dev':
        HASHKEY = process.env.REACT_APP_HASHKEY;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID;
        break;
    case 'dev2':
        HASHKEY = process.env.REACT_APP_HASHKEY_DIT2;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY_DIT2;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID_DIT2;
        break;
    case 'test':
        HASHKEY = process.env.REACT_APP_HASHKEY;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID_TEST1;
        break;
    case 'test2':
        HASHKEY = process.env.REACT_APP_HASHKEY;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID_TEST2;
        break;
    case 'val':
        HASHKEY = process.env.REACT_APP_HASHKEY_VAL;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY_VAL;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID_VAL;
        break;
    default:
        HASHKEY = process.env.REACT_APP_HASHKEY;
        ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
        CLIENT_ID = process.env.REACT_APP_SDKCLIENTID;
}

const RoleByGroup = {
    Prescriber: 'GBL-AP-User-GRMP-Prescriber',
    NonPrescriber: 'GBL-AP-User-GRMP-NonPrescriber',
    Pharmacist: 'GBL-AP-User-GRMP-Pharmacist',
    Patient: 'GBL-AP-User-GRMP-Patient',
};

enum Role {
    Prescriber = 'prescriber',
    NonPrescriber = 'nonPrescriber',
    Pharmacist = 'pharmacist',
}

export { RoleByGroup, Role, HASHKEY, ENCRYPTION_KEY, CLIENT_ID, APIURLS };