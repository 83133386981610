export const accountContent = {
    help: '',
    header: [
        
        {
        order: 1,
        content: `Please enter your first name and last name <span style="font-weight: bold">exactly as seen in the information provided</span> by your REMS Representative to ensure the successful creation of your online account.`,
    },
    {
        order: 2,
        content: `If you do not have this information available, please call <span style="font-weight: bold">the REMS Call Center at 1-888-423-5436</span> for assistance<br/><span style="font-weight: bold">Monday through Friday 8:00 AM to 8:00 PM ET.</span>`,
    }
 ,
        ],
    body: [
        {
            order: 1,
            content: {
                title: 'Personal Information',
            },
        },
        {
            order: 2,
            content: {
                title: 'Account Information',
                subHeader: `Bristol Myers Squibb is committed to maintaining visitors' privacy and securing information. The guidelines for User Name and Password creation are strictly enforced.`,
            },
        },
    ],
};
