import React, { useRef, useState } from 'react';
import classes from './enrollmentModal.module.scss';
import Button from 'components/Button';

interface ModalProps {
	message: string;
	data: any
	handleContinue: () => void;
	handleCancel: () => void;
	isCancelPopUp: boolean;
}


const LocationModal: React.FC<ModalProps> = ({ handleContinue, handleCancel, message, data, isCancelPopUp }) => {
	const modalRef = useRef<HTMLDialogElement | null>(null);

	return isCancelPopUp ? (
		<div className={classes.modalContainer}>
			<dialog ref={modalRef} className={classes.mainContainer}>

				<div className={classes.whiteInfoContainer}>
					<p className={classes.title}>Pharmacy Location</p>
					<button className={classes.closeBtn} onClick={handleContinue}>
						X
					</button>
					<div className={classes.bigPad}>




						<div className={classes.locationbox}>
							<div className={classes.expandedRow_inputs} style={{ width: '100%' }}>
								{Object.entries(data).map((item: any) => (
									<div className={classes.expandedRow_inputs_item}>
										<div className={classes.expandedRow_inputs_label} >{item[0]}</div>
										<span className={classes.expandedRow_inputs_colon} >:</span>
										<div className={classes.expandedRow_inputs_value}>{item[1]}</div>
									</div>
								))}
							</div>
						</div>
					</div>

				</div>
			</dialog>
		</div>
	) : '';
};

export default LocationModal;
