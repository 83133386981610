export const ppfConfirmationContent = {
    body:[
        {
            order:1,
            content:`1. If a patient survey is required, please have your patient complete the survey now.`
        },
        {
            order:2,
            content:`2. Complete the Prescriber survey.`
        },
        {
            order:3,
            content:`3. Write the prescription and send to the pharmacy.`
        },
       

    ],
    subheader:'Patient-Physician Agreement Confirmation',
}