import { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.mjs';
import classes from './pdfViewer.module.scss';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { CiZoomIn, CiZoomOut } from 'react-icons/ci';
import { TbDownload } from 'react-icons/tb';
import { MdLocalPrintshop } from 'react-icons/md';
import { Tooltip, Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
pdfjs.GlobalWorkerOptions.workerSrc ='./pdf.worker.min.js';

const PdfViewer = () => {
    const pageRefs = useRef<any>([]);
    const thumbNailRefs = useRef<any>([]);
    const pdfContainerRef = useRef<any>([]);
    const [pdfDoc, setPdfDoc] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [scale, setScale] = useState(1.0);
    const [thumbnails, setThumbnails] = useState([]);
    const [pdfUrl, setPdfUrl] = useState<any>('');
    const [fileName, setFilename] = useState<any>();
    const [showThumbNail, setShowThumbNail] = useState(false);
    const byte = sessionStorage.getItem('baseString') || '';

    useEffect(() => {
        setFilename(JSON.parse(sessionStorage.getItem('fileName') || JSON.stringify('file')));

        const binaryArr = atob(JSON.parse(byte));
        const arrayBuff = new Uint8Array(binaryArr.length);
        for (let i = 0; i < binaryArr.length; i++) {
            arrayBuff[i] = binaryArr.charCodeAt(i);
        }
        const blobObj = new Blob([arrayBuff], { type: 'application/pdf' });
        const objURL = URL.createObjectURL(blobObj);
        setPdfUrl(objURL);
        if (objURL) {
            const loadPdf = async () => {
                const loadingTask = pdfjsLib.getDocument({ url: objURL });

                const pdf = await loadingTask.promise;
                setPdfDoc(pdf);
                setTotalPages(pdf.numPages);
                await generateThumbnails(pdf);
            };
            loadPdf();
        }
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            sessionStorage.removeItem('baseString');
            sessionStorage.removeItem('fileName');
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [byte]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (+e.target.value > 0 && +e.target.value <= totalPages) scrollToPage(+e.target.value);
        }
    };

    const scrollToPage = (pageNumber) => {
        pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth' });
        thumbNailRefs.current[pageNumber - 1]?.scrollIntoView({ behavior: 'smooth' });
        setCurrentPage(pageNumber);
    };

    const generateThumbnails = async (pdf) => {
        const thumbnailArray: any = [];
        const renderScale = scale * 3.0;
        for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: renderScale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;
            thumbnailArray.push(canvas.toDataURL());
            setThumbnails(thumbnailArray);
        }
    };

    const downloadPdf = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = fileName;
        link.click();
    };

    const handlescroll = () => {
        if (!pageRefs.current) return;
        let visiblepage = 1;
        const containerTop = pdfContainerRef.current.scrollTop;
        const conatinerBottom = containerTop + pdfContainerRef.current.clientHeight;

        pageRefs.current.forEach((page, index) => {
            const pageTop = page.offsetTop;
            const pageBottom = pageTop + page.clientHeight;
            if (pageTop <= conatinerBottom && pageBottom >= containerTop) {
                visiblepage = index + 1;
            }
        });
        setCurrentPage(visiblepage);
    };

    const printPdf = async () => {
        if (!pdfDoc) return;
        const printWindow = window.open('', fileName, 'location=yes,height=550,width=760,scrollbars=yes,status=yes');
        printWindow?.document.write(`
            <html><head><title>${fileName}</title>
            <style>
            @media print{
            @page{
            margin:0;
            }
            body{
            margin :0;
            padding:0;
            }}
            </style>
            </head></html><body>`);
        printWindow?.document.write("<div id='print-content'></div>");
        printWindow?.document.write('</body></html>');
        const printContent = printWindow?.document.getElementById('print-content');
        for (let i = 1; i <= pdfDoc.numPages; i++) {
            const page = await pdfDoc.getPage(i);
            const viewport = page.getViewport({ scale: 1, rotation: 0 });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            await page.render({ canvasContext: context, viewport }).promise;
            const img = document.createElement('img');
            img.src = canvas.toDataURL();
            img.style.display = 'block';
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.pageBreakAfter = 'always';
            printContent?.appendChild(img);
        }
        printWindow?.document.close();
        setTimeout(() => {
            printWindow?.focus();
            printWindow?.print();
            printWindow?.close();
        }, 0);
    };

    const onLoadSuccess = ({ numPages }) => setTotalPages(numPages);

    return (
        <div className={classes.container}>
            <div className={classes.thumbNail}>
                <div className={classes.thumbNail_section}>
                    <div className={classes.thumbNail_icon} onClick={() => setShowThumbNail((prev) => !prev)}>
                        <Tooltip
                            placement="right"
                            title={'Thumbnails'}
                            rootClassName={classes.tooltipClass}
                            style={{ color: '#000000' }}
                            color={'#ffffff'}
                        >
                            <HiOutlineSquares2X2 className={classes.icon} />
                        </Tooltip>
                    </div>
                    <div className={showThumbNail ? classes.thumbNail_container : ''}>
                        {' '}
                        {showThumbNail &&
                            thumbnails.map((thumbnail, index) => (
                                <div className={classes.thumbNail_content}>
                                    <img
                                        className={
                                            currentPage === index + 1
                                                ? classes.thumbNail_image_selected
                                                : classes.thumbNail_image
                                        }
                                        key={index}
                                        ref={(el) => (thumbNailRefs.current[index] = el)}
                                        src={thumbnail}
                                        alt={'thumbnail'}
                                        onClick={() => {
                                            scrollToPage(index + 1);
                                        }}
                                    />
                                    <div className={classes.thumbNail_index}>{index + 1}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <div className={classes.document}>
                <div className={classes.document_navs}>
                    <p>{fileName}</p>
                    <div className={classes.document_navs_input}>
                        <input onKeyDown={handleKeyDown} placeholder="Go to Page"></input>
                        <span>{`${currentPage} / ${totalPages}`}</span>
                    </div>

                    <div
                        onClick={() =>
                            setScale((prev) => {
                                if (prev >= 1.5) return prev;
                                else return prev + 0.2;
                            })
                        }
                    >
                        <Tooltip
                            placement="bottom"
                            title={'Zoom In'}
                            rootClassName={classes.tooltipClass}
                            style={{ color: '#000000' }}
                            color={'#ffffff'}
                        >
                            <CiZoomIn className={classes.icon} />
                        </Tooltip>
                    </div>
                    <div
                        onClick={() =>
                            setScale((prev) => {
                                if (prev <= 0.4) return prev;
                                else return prev - 0.2;
                            })
                        }
                    >
                        <Tooltip
                            placement="bottom"
                            title={'Zoom Out'}
                            rootClassName={classes.tooltipClass}
                            style={{ color: '#000000' }}
                            color={'#ffffff'}
                        >
                            <CiZoomOut className={classes.icon} />
                        </Tooltip>
                    </div>

                    <div onClick={printPdf}>
                        <Tooltip
                            placement="bottom"
                            title={'Print'}
                            rootClassName={classes.tooltipClass}
                            style={{ color: '#000000' }}
                            color={'#ffffff'}
                        >
                            <MdLocalPrintshop className={classes.icon} />
                        </Tooltip>
                    </div>

                    <div onClick={downloadPdf}>
                        <Tooltip
                            placement="bottom"
                            title={'Download'}
                            rootClassName={classes.tooltipClass}
                            style={{ color: '#000000' }}
                            color={'#ffffff'}
                        >
                            <TbDownload className={classes.icon} />
                        </Tooltip>
                    </div>
                </div>
                <div className={classes.document_content} ref={pdfContainerRef} onScroll={handlescroll}>
                    {pdfUrl ? (
                        <Document file={pdfUrl} onLoadSuccess={onLoadSuccess}>
                            {Array.from(new Array(totalPages), (el, index) => (
                                <div className={classes.document_page} ref={(el) => (pageRefs.current[index] = el)}>
                                    <Page key={index} pageNumber={index + 1} scale={scale} />
                                </div>
                            ))}
                        </Document>
                    ) : (
                        <div className={classes.loader}>
                            {' '}
                            <Flex align="center" gap="middle">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                            </Flex>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;
