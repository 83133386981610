import moment from 'moment-timezone';
export const getReportsPrintPopUpTemplate = (content) => {
    const date = moment().tz('America/New_York');
    const fdate = date.format('ddd MMM DD HH:mm:ss z YYYY');

    const patientListTableRenderer = () => {
        const patientRows = () => {
            let rows;
            if (content && content.length > 0) {
                rows = content
                    .map((each) => {
                        return `
                        <tr class="odd">
                        <td class="startRow" style="text-align: left;">
                        ${each.patientLastName && each.patientLastName},
                        <br />
                        ${each.patientFirstName && each.patientFirstName}
                        </td>
                        <td>
                        ${each.remsProgram
                            && each.remsProgram}
                        </td>
                        <td>
                        ${each.authorizationDate && each.authorizationDate}
                        </td>
                        <td>
                        ${each.authorizationNumber && each.authorizationNumber}
                        </td>
                        <td>
                        ${each.authorizationStatus && each.authorizationStatus}
                        </td>
                        <td>
                            ${each.authorizationAge && each.authorizationAge}
                        </td>
                        <td>
                            ${each.authorizationExpirationDate && each.authorizationExpirationDate}
                        </td>
                        <td>
                        ${each.assocPrescriberLastName && each.assocPrescriberLastName},
                        <br />
                        ${each.assocPrescriberFirstName && each.assocPrescriberFirstName}
                        </td>
                    </tr>

                `;
                    })
                    .join('');
            } else {
                if (content.length === 0) {
                    rows = `<tr class="odd">
                    <td colspan="14" align="center">
                        No Records Found.                  
                    </td>
                </tr>`;
                }
            }

            return rows;
        };

        return `
        <table width="815px" cellpadding="0" cellspacing="0" border="0" align="center">
		<colgroup>
			<col width="9px">
			<col width="798px">
			<col width="9px">
		</colgroup>
		<tbody><tr class="mainContentContainerTopBorder">
			<td><span class="leftCorner"></span></td>
			<td><span class="middleEdge"></span></td>
			<td><span class="rightCorner"></span></td>
		</tr>
		<tr>
			<td colspan="3">
				<table width="815px" cellpadding="0" cellspacing="0" border="0" class="mainContentContainer">
					<tbody><tr>
						<td>&nbsp;</td>
						<td>
							<div class="clear"></div>
							<br>
							<table width="759px" cellpadding="0" cellspacing="0" border="0">
								<tbody><tr>
									<td colspan="3" class="tabbedGradientContainer">
									<table width="755px" cellpadding="0" cellspacing="0" border="0" align="center">
											<tbody><tr>
												<td width="10px">&nbsp;</td>
												<td>
													<table width="735px" cellpadding="5" cellspacing="0" style="border-color:black;" border="1" class="dataTable">
														<tbody><tr valign="top" class="splitHeader">
															<th align="left" class="topHeader" rowspan="2">
																Patient Name
															</th>
															<th align="left" class="topHeader" rowspan="2">
																REMS Program
															</th>
															<th align="center" colspan="5" class="topHeader">
																Authorization
															</th>
															<th align="left" rowspan="2">
																Prescriber
															</th>
														</tr>
														<tr>	
															<th align="left" class="topHeader">
																Date
															</th>
															<th align="left" class="topHeader">
																Number
															</th>
															<th align="left" class="topHeader">
																Status
															</th>
															<th align="left" class="topHeader">
																Age
															</th>
															<th align="left" class="topHeader">
																Expiration Date
															</th>
														</tr>
														
															${patientRows()}
														
														
													</tbody></table><!--
													 Display pagination bar only more than 1 page 
													
												--></td>
												<td width="10px">&nbsp;</td>
											</tr>
										</tbody></table>
									</td>
								</tr>
								</tbody></table>
							<div class="buffer">&nbsp;</div>
						</td>
						<td>&nbsp;</td>
					</tr>
				</tbody></table>
			</td>
		</tr>
		
	</tbody></table>
            `;
    };

    return `
        <html>
            <head>
                <title>${window.location.href}</title>
                <style>
                    tbody tr th, 
                    tbody tr td{
                        font-size:14px;
                    } 
                    .bottom{
                        font-size:13px;
                    }
                    .date {
                        margin-top: -10px;
                        font-size: 9px;
                        text-align: center;
                    }
                </style>
            </head>
            <body>
            <div class="header">
            <span class="icon logo left"></span>
            <div align="center" style="font-size: 2em;">Authorization Numbers to Expire Report </div>
            <div align="center">
                ${fdate}
            </div>
            <div class="buffer clear"></div>
        </div>
                ${patientListTableRenderer()}
                <br>
                <br>
                <div class="bottom" align="center">Bristol Myers Squibb</div>
            </body>
        </html>
        `;
};
