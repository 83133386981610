import React, { ReactNode } from 'react';
import PageBackground from '../PageBackground';
import classes from './Success.module.scss';
import Alert from 'components/Alert';

export type successProps = {
    label: string;
    btn?: boolean;
    product?: boolean;
    productLogo?: string;
    children: ReactNode;
    handleClick?: () => void;
    fromComp?: string;
};

const Success = ({ label, btn, product, productLogo, children, handleClick, fromComp='' }: successProps) => {
    return (
        <div>
            <PageBackground label={label} btn={btn} product={productLogo} handleClick={handleClick} fromComp={fromComp}>
                <div className={classes.bannerContent}>
                    {/* <Alert content='Prescriber is already certified for the selected program. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET. '/> */}
                    {children}
                </div>
            </PageBackground>
        </div>
    );
};

export default Success;
