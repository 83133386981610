export const reqBody={
    "modifiedBy": null,
    "modifiedDate": null,
    "createdBy": null,
    "createdDate": null,
    "channelId": "ccr",
    "prescriberNameFirst": "Chinu2",
    "prescriberNameLast": "Das",
    "prescriberMiddleInitial": null,
    "prescriberEmailAddress": null,
    "prescriberExtUniqueId1": "466301122",
    "prescriberExtUniqueId2": null,
    "oneNotificationPerDay": "N",
    "deactivationDate": null,
    "products": [
      {
        "name": "Lenalidomide",
        "status": "Pending"
      }
    ],
    "prescriberLocations": [
      {
        "modifiedBy": null,
        "modifiedDate": null,
        "createdBy": null,
        "createdDate": null,
        "channelId": null,
        "id": null,
        "prescriberOfficeName": "off1",
        "phoneNumber": "9999876543",
        "mobilePhone": null,
        "attention": null,
        "addressStatus": null,
        "addressLine1": "96 Jonathan Lucas St.",
        "addressLine2": null,
        "addressLine3": null,
        "addressLine4": null,
        "prescriberCityNotVerified": null,
        "emailAddress": null,
        "postalCode1NotVerified": null,
        "primaryLocation": "Y",
        "postalCode": {
          "postalCodeId": null,
          "postalCode1": "29425",
          "city": "CHARLESTON",
          "state": {
            "stateId": null,
            "stateCode": "SC",
            "stateName": null
          }
        },
        "deactivationDate": null,
        "prescriberLocationActivation": null,
        "prescriberLocationDeactDate": null,
        "faxNumber": "9999999991"
      }
    ],
    "degrees": [
      {
        "degreeId": null,
        "degree": "Fellow",
        "deactivationDate": null
      }
    ],
    "specialty": {
      "specialtyId": null,
      "specialtyName": "HO",
      "specialtyCode": null,
      "description": null
    },
    "prescriberTitle": null,
    "country": null,
    "language": null,
    "changeReason": null,
     "prescriberRegStatuses": [
        {
          "modifiedBy": null,
          "modifiedDate": null,
          "createdBy": null,
          "createdDate": null,
          "channelId": null,
          "id": {
            "prescriberId": null,
            "productCountryId": {
              "productCountryId": null,
              "riskManagementProgram": null,
              "riskManagementIndicator": null,
              "dispenseHoldCheck": null,
              "distributionType": null,
              "productSiteUrl": null,
              "product": {
                "productId": null,
                "productName": "Lenalidomide",
                "productCode": null,
                "productTradeName": null,
                "productGenericName": null,
                "comsOpProductId": null,
                "deactivationDate": null,
                "productGroup": {
                  "productGroupId": null,
                  "productGroupName": null
                },
                "productStatus": null
              }
            }
          },
          "registrationStatus": null,
          "deactivateReasonChangeId": null,
          "reasonForChangeId": null,
          "dateEffective": null,
          "registrationDate": null,
          "initialRegistrationDate": null,
          "prescriberCommTypeId": null,
          "notification21Days": null,
          "notification14Days": null,
          "notification7Days": null,
          "notificationEmail": null,
          "notificationFax": null,
          "notificationEmail2": null,
          "notificationEmail3": null,
          "notifyByEmail": null,
          "notifyByFax": null
        }
      ]
  }