export const multipleDispenseCreatePayload = (authorizationsDetails, dropdowns, pharmacyId,currentUser) => {
	const payload = authorizationsDetails.map(authDetails => {
		return {
			dispensationId: null,
			dispensationTypeId: {
				lookupId: dropdowns.find(o => o.code === 'Real-time')?.id,
				lookupCategory: "dispensation_type",
				lookupCode: null,
				lookupValue: dropdowns.find(o => o.code === 'Real-time')?.value,
			},
			dispensationCommTypeId: 0,//need actual value
			pharmacyId: authDetails.authResponse.pharmacyId,
			prescriberId: authDetails.authResponse.authorization.prescriberId,
			patientId: authDetails.authResponse.patientId,
			inUseBy: null,
			authorizationId: authDetails.authResponse.authorization.authorizationId,
			authorizationNumber: authDetails.authResponse.authorizationNumber,
			channelId: 'ext',
			createdBy: currentUser?.onlineUserId || '',
			prescriptionDate: authDetails.authResponse.authorization.prescriptionDate,
			productRiskClassCategoryId: {
				productCountryId: {
					productCountryId: authDetails.authResponse.authorization.productCountryId,
					riskManagementProgram: authDetails.authResponse.authorization.riskManagementProgram,
					riskManagementIndicator: null,
					dispenseHoldCheck: null,
					distributionType: null,
					productSiteUrl: null,
					product: {
						productId: authDetails.authResponse.productId,//
						productName: authDetails.authResponse.authorization.productName,
						productCode: null,
						productTradeName: null,
						productGenericName: null,
						comsOpProductId: null,
						deactivationDate: null,
						productGroup: null,
						productStatus: null
					}
				},
				riskClassCategoryId: {
					lookupId: authDetails.authResponse.authorization.patientRiskCategoryId,
					lookupCategory: "risk_class_category",
					lookupCode: null,
					lookupValue: authDetails.authResponse.authorization.patientRiskCategoryName,
				}
			},
			freeGoodsDispenseFlag: authDetails.authResponse.authorization.fgApprovalStatus,
			investigationNumber: null,
			dispenseSequences: authDetails.product.map((item) => (
				{
					dispenseSequenceId: null,
					dispensationQuantity: Number(item?.quantity.quantity),
					dispensation: null,
					dosageId: {
						dosageId: item?.product_id?.dosageId,
						dosageStrength: null,
						dosageDetails: null,
						countryId: {
							countryId: authDetails.authResponse.authorization.countryId,
							countryName: authDetails.authResponse.authorization.authorizationcountryName,
							countryCode: authDetails.authResponse.authorization.countryCode,
						},
						languageId: {
							languageId: 1,
							iso_639_1_code: "en",
							languageName: "English",
							otherLanguage: null
						}
					},
					ndcId: {
						ndcId: item?.product_id?.id,
						programProductId: item?.product_id?.productProgramId,						
						ndcDescription: item?.product_id?.value
					}
				}
			)),
			dispensationDeviation: null,
			dispensationHistory: null
		}
	});

	return payload;
}


