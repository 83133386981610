import { set, get, del } from 'idb-keyval';
const indexedDBStore = {
    setItem: (key, val) => {
        return set(key, val);
    },
    getItem: (key, val) => {
        return get(key, val);
    },
    removeItem: (key) => {
        return del(key);
    },
};

export default indexedDBStore;
