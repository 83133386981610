import React, { useRef, useState } from 'react';
import classes from './promptModal.module.scss';
import Button from 'components/Button';

interface ModalProps {
	handleContinue: () => void;
	handleCancel:() => void;
}


const PromptModal = ({ handleContinue,handleCancel, content }) => {
	const modalRef = useRef<HTMLDialogElement | null>(null);

	return (
		<div className={classes.modalContainer}>
			<dialog ref={modalRef} className={classes.mainContainer}>
				<div className={classes.whiteInfoContainer}>
					<div className={classes.content}>
					<>
						<div>{content}</div>
						
						</>

					</div>
					<div className={classes.btnContainer}>
						<Button
							clickFunction={handleContinue}
							className="buttonStyles"
							type="text"
							width={130}
							height={39}
							size={18}
							buttonname="Continue"
						/>
						<Button
							clickFunction={handleCancel}
							className="buttonStyles"
							type="text"
							width={130}
							height={39}
							size={18}
							buttonname="Cancel"
						/>
					</div>
				</div>
			</dialog>
		</div>
	);
};

export default PromptModal;
