import { ProgramConfig, lenalidomide, pomalidomide, thalomid, PROGRAMS } from 'constants/Config/programsConfig';
const PomalystTextToPomalidomide = 'Pomalidomide';
export const headContent = [
    {
        order: 1,
        content: `Click on a button below to access the corresponding REMS menu of operations for that REMS program: enroll a patient, access an existing or save a new Patient-Physician Agreement Form, complete a prescriber survey or write a prescription.`,
    },
];

const getPrograms = () => {
    let prgms = [];
    PROGRAMS.forEach((i) => {
        prgms.push({
            id: i.id,
            name: i.title,
            title: `For <span style="font-weight: bold;">${i.title}${
                i.registeredTradermark ? `<sup>®</sup>` : ''
            }</span>`,

            link: i.link,
            content1: `Please see ${i.productCountryId === 1 ? 'relevant' : ''} <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('${i.loginContentLink}', 'ProductEducation', 'location=0,status=1,scrollbars=1,resizable=yes, width=815,height=600'); return false;">Prescribing Information</a>, including${i.productCountryId !== 1 ? ':' : ''} Boxed WARNINGS, CONTRAINDICATIONS, ${i.productCountryId === 1 ? 'WARNINGS AND' : ''} PRECAUTIONS, and ADVERSE REACTIONS.`,
            content2: `Visit <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://${
                i.link
            }', '${i.title.replace(
                ' ',
                ''
            )}', 'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width='+screen.availWidth*(11/12)+',height=600'); return false;">${
                i.link
            }</a>, to learn more about the ${i.title}${
                i.registeredTradermark ? `<sup>®</sup>` : ''
            } program.`,
        });
    });
    return prgms;
};
export const bodyContent = {
    programs: getPrograms(),
    gradientContainer: [
        {
            order: 1,
            content: `The Prescriber Dashboard is an optional resource that displays the status of patients under your care for a specific Bristol Myers Squibb-administered REMS program. A patient search function is also included to access detailed patient history information.`,
        },
        {
            order: 2,
            content: `Select the "Manage My Account" button to view your REMS online account information.`,
        },
    ],
    buttons: {
        prescriberDashboard: 'Prescriber Dashboard',
        manangeMyAccount: 'Manange My Account',
    },
};
/* export const bodyContent = {
    programs: [
        {
            id: "rems_program_lenalidomide",
            name: 'Lenalidomide REMS',
            title: `For <span style="font-weight: bold;">Lenalidomide REMS</span>`,
            link: 'www.LenalidomideREMS.com',
            content1: `Please see relevant <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://dailymed.nlm.nih.gov/dailymed/search.cfm?labeltype=all&amp;query=lenalidomide&amp;audience=professional', 'ProductEducation', 'location=0,status=1,scrollbars=1,resizable=yes, width=815,height=600'); return false;">Prescribing Information</a>, including Boxed WARNINGS, CONTRAINDICATIONS, WARNINGS AND PRECAUTIONS, and ADVERSE REACTIONS.`,
            content2: `Visit <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://www.lenalidomiderems.com', 'LenalidomideREMS', 'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width='+screen.availWidth*(11/12)+',height=600'); return false;">www.LenalidomideREMS.com</a>, to learn more about the Lenalidomide REMS program.`
        },
        {
            id: "rems_program_pomalyst",
            name: `${PomalystTextToPomalidomide} REMS`,
            title: `For <span style="font-weight: bold;">Pomalidomide REMS</span>`,
            link: `www.${PomalystTextToPomalidomide}REMS.com`, 
            content1: `Please see <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://packageinserts.bms.com/pi/pi_pomalyst.pdf', 'ProductEducation', 'location=0,status=1,scrollbars=1,resizable=yes, width=815,height=600'); return false;">Prescribing Information</a>, including: Boxed WARNINGS, CONTRAINDICATIONS, PRECAUTIONS, and ADVERSE REACTIONS.`,
            content2:`Visit <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://www.${PomalystTextToPomalidomide}REMS.com', 'PomalidomideREMS', 'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width='+screen.availWidth*(11/12)+',height=600'); return false;">www.${PomalystTextToPomalidomide}REMS.com</a>, to learn more about the ${PomalystTextToPomalidomide} REMS program.`
        },
        {
            id: "rems_program_thalomid",
            name: 'THALOMID REMS',
            title: `For <span style="font-weight: bold;">THALOMID REMS</span><span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`,
            link: 'www.THALOMIDREMS.com',
            content1: `Please see <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://packageinserts.bms.com/pi/pi_thalomid.pdf', 'ProductEducation', 'location=0,status=1,scrollbars=1,resizable=yes, width=815,height=600'); return false;">Prescribing Information</a>, including: Boxed WARNINGS, CONTRAINDICATIONS, PRECAUTIONS, and ADVERSE REACTIONS.`,
            content2:`Visit <a class="underline" href="javascript: void(0);" onclick="javascript: window.open('https://www.THALOMIDREMS.com', 'THALOMIDREMS', 'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width='+screen.availWidth*(11/12)+',height=600'); return false;">www.THALOMIDREMS.com</a>, to learn more about the THALOMID REMS<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span> program.`
        }
    ],
    gradientContainer:[
        {
            order:1,
            content:`The Prescriber Dashboard is an optional resource that displays the status of patients under your care for a specific Bristol Myers Squibb-administered REMS program. A patient search function is also included to access detailed patient history information.`
        },
        {
            order:2,
            content:`Select the "Manage My Account" button to view your REMS online account information.`
        }
    ],
    buttons:{
        prescriberDashboard:'Prescriber Dashboard',
        manangeMyAccount:'Manange My Account',
    }
} */
