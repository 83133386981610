import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { changePPAF } from 'store/slices/appSlice';
import classes from '../PPAFWorkMenu/PPAFWorkMenu.module.scss';
import { useAppSelector } from '../../store';
import { RoleByGroup } from 'constants/Config/appConfig';
import SessionTimeout from 'components/SessionTimeout';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';

const PPAFWorkMenu = () => {
    const { selectedProgram } = useAppSelector((state) => state.app);
    const { currentUser, IsAuthenticated } = useAppSelector(
        (state) => state.login
    );
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);

    const buttonImageMenu_English = [
        {
            product: selectedProductData.id,
            index: 1,
            buttonName: 'WorkPartial',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.en.partiallyComPpaf,
            subText:'',
            icon:'ppaf',
            width:395,
            height:85
        },
        {
            product: selectedProductData.id,
            index: 2,
            buttonName: 'ViewSubmit',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.en.viewPpaf,
            subText:'',
            icon:'ppaf',
            width:395,
            height:85
        },
        {
            product: selectedProductData.id,
            index: 3,
            buttonName: 'EditPatient',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.en.editPatAdd,
            subText:'',
            icon:'ppaf',
            width:395,
            height:85
        },
        {
            product: selectedProductData.id,
            index: 4,
            buttonName: 'Reactivate',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.en.createNewPpaf,
            subText:'('+buttonContent.en.subTxtCreateNewPpaf+')',
            icon:'create',
            width:395,
            height:85
        }
    ];

    const buttonImageMenu_Spanish = [
        {
            product: selectedProductData.id,
            index: 1,
            buttonName: 'WorkPartial',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.es.partiallyComPpaf,
            subText:buttonContent.en.partiallyComPpaf,
            icon:'ppaf',
            width:550,
            height:130
        },
        {
            product: selectedProductData.id,
            index: 2,
            buttonName: 'ViewSubmit',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.es.viewPpaf,
            subText:buttonContent.en.viewPpaf,
            icon:'ppaf',
            width:550,
            height:130
        },
        {
            product: selectedProductData.id,
            index: 3,
            buttonName: 'EditPatient',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.es.editPatAdd,
            subText:buttonContent.en.editPatAdd,
            icon:'ppaf',
            width:550,
            height:130
        },
        {
            product: selectedProductData.id,
            index: 4,
            buttonName: 'Reactivate',
            productCategory: ['rems_program_lenalidomide'],
            text:buttonContent.es.createNewPpaf,
            subText:buttonContent.es.subTxtCreateNewPpaf,
            icon:'create',
            width:550,
            height:130
        },

      
    ];

    const getProduct = (language: string) => {
        if (language === 'es') return buttonImageMenu_Spanish;
        else if (language === 'en') return buttonImageMenu_English;
    };
    let buttonImageMenu: any;
    buttonImageMenu = getProduct(i18n.language);

    const buttonClickHandler = (record) => {
        // navigate('/prescriber/submittedPPAFSearch',{state:record});
        if(IsAuthenticated){
            sessionStorage.setItem('patientInfo',JSON.stringify(record))
                switch (record.buttonName) {
                    case 'WorkPartial':
                      navigate("/prescriber/partiallyCompletedPPAFSearch",{state:record})
                      break;
                    case 'ViewSubmit':
                      navigate("/prescriber/submittedPPAFSearch",{state:record})
                      break;
                    case  'EditPatient':
                        navigate("/prescriber/patientAddressPPAFSearch",{state:record})
                      break;
                      case  'Reactivate':
                         navigate("/prescriber/submittedPpafCreatedSearch",{state:record})
                      break;
                    default:
                  }            
        }
    };

    return (
        <>
        <SessionTimeout/>
            <div className={classes.mainContainer}>
                <div className={`${classes[i18n.language]} ${classes.mb20}`}>
                    <div className={classes.generalProgramLogo} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProductData.id).headerImage}`)})`
                        }} ></div>
                    <div className={classes.headerDivider} style={{
                                    borderColor: `${ProgramConfig(selectedProductData.id).color}`}}></div>
                    {buttonImageMenu &&
                        buttonImageMenu.length &&
                        buttonImageMenu
                            .map((row) => {
                                return (
                                    <div className={`${classes.button_wrapper}`} key={row.text}>
                                        <div className={classes.btnWithImage}>
                                            {row.icon && row.icon=='ppaf'?
                                                <div className={`${classes.innerImage} ${classes['prodSubmittedPPAF_'+ ProgramConfig(row.product).label]}`}></div> 
                                                :
                                                <div className={`${classes.innerImage} ${classes['prod_'+ ProgramConfig(row.product).label]}`}></div>
                                             
                                                    }
                                                <Button
                                                    clickFunction={() => buttonClickHandler(row)}
                                                    className={getBtnClass(row.product)}
                                                    width={row.width}
                                                    height={row.height}
                                                    size={18}
                                                    type="text"
                                                    buttonname={row.text}
                                                    subText={row.subText}
                                                    innerImage={true}
                                                ></Button>
                                        </div>
                                    </div>
                                );
                            })}
                </div>
            </div>
        </>
    );
};

export default PPAFWorkMenu;
