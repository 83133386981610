import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input';
import classes from './createaccount.module.scss';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    checkAccountDetailsValidation,
    resetAccValidation,
    setAccFormDetails,
    setAccountPwdDetails,
    handlePrescriber,
    createUserAccount,
} from '../../store/slices/appSlice';
import { createAccFormFields, requiredLabels } from '../../constants/createAccountFormFields';
import ErrorModal from '../../components/ErrorModal';
import Alert from '../../components/Alert';
import {
    isValidPassword,
    isValidEmail,
    isValidPhoneNumber,
    isValidZip,
    isValidName,
    isValidUserName,
    isValidCity,
    isValidAddr,
} from '../../utils/regex';
import { accountContent } from '../../assets/objects/createAccountContent';
import PageBackground from 'components/PageBackground';
import { axiosInstance } from 'constants/Config/axiosConfig';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import { reloadFunction } from 'utils/utilsCommon';

interface dropDownType {
    State?: { id: string; value: string }[] | null;
    SecurityQuestion?: { id: string; value: string }[] | null;
    Degree?: { id: string; value: string }[] | null;
    Specialty?: { id: string; value: string }[] | null;
}

const CreateAccount = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { accFormDetails, errorValidation, role, errorCreateAccount } = useAppSelector((state) => state.app);
    const [dropdown, setDropdown] = useState<dropDownType>({ State: [], SecurityQuestion: [] });
    const [currentFocus, setCurrentFocus] = useState('');
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        officeAddress: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        userName: '',
        password: '',
        verifyPassword: '',
        email: '',
        securityQuestion: '',
        securityAnswer: '',       
    });
    const [error, setError] = useState('');

    useEffect(() => {
        setValues((prev) => ({ ...prev, ...accFormDetails }));
        const existing = sessionStorage.getItem('accountData');
        if (existing) {
            setValues((prev) => ({ ...prev, ...JSON.parse(existing) }));
        }
    }, [accFormDetails]);

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        if (errorValidation.message === 'Validation Error') {
            setValues((prev) => ({ ...prev, password: '', verifyPassword: '' }));
        }
    }, [errorValidation]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const fetchDropdown = async () => {
        fetchDatafromS3json().then((res) => {
            setDropdown({ State: res?.state , SecurityQuestion: res?.securityQuestion });
        });
    };

    useEffect(() => {
        dispatch(handlePrescriber(sessionStorage.getItem('role')));
        fetchDropdown();
    }, []);

    const handleOnChange = (e) => {
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const checkPasswordMatch = () => {
        const { password, firstName, lastName, userName } = values;
        return (
            password.toLowerCase().includes(firstName.toLowerCase()) ||
            password.toLowerCase().includes(lastName.toLowerCase()) ||
            password.toLowerCase().includes(userName.toLowerCase())
        );
    };
    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            if (!['password', 'verifyPassword', 'state', 'securityQuestion'].includes(field)) {
                newVals[field] = newVals[field].trim();
            }
        });
        setValues(newVals);
        return newVals;
    };
    const handleSubmit = async () => {
        trimValues();
        for (let i of requiredLabels) {
            if (
                values[i.name] === '' ||
                values[i.name] === 'Select a State' ||
                values[i.name] === 'Select a Question' ||
                (!['password', 'verifyPassword'].includes(i.name) && values[i.name].trim() === '')
            ) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (['firstName', 'lastName'].includes(i.name) && !isValidName(values[i.name])) {
                setCurrentFocus(i.name);
                setError(i.errMsg);
                scrollToView();
                return;
            } else if (i.name === 'officeAddress' && !isValidAddr(values.officeAddress)) {
                setCurrentFocus(i.name);
                setError(i.errMsg);
                scrollToView();
                return;
            } else if (i.name === 'city' && !isValidCity(values.city)) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'zipCode' && !isValidZip(values.zipCode)) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (values.phoneNumber !== '' && !isValidPhoneNumber(values.phoneNumber)) {
                setError('Please enter a Phone Number.');
                setCurrentFocus('phoneNumber');
                scrollToView();
                return;
            } else if (i.name === 'userName') {
                if (values.userName.trim().length < 4 || values.userName.trim().length > 20) {
                    setError('User name must be between 4 and 20 characters.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                } else if (!isValidUserName(values.userName.trim())) {
                    setError('Please enter a valid User Name.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'password' && (!isValidPassword(values.password) || checkPasswordMatch())) {
                setError(
                    `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name.`
                );
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'verifyPassword' && values.password !== values.verifyPassword) {
                setError(`The new passwords you have entered do not match. Please enter your new passwords again.`);
                scrollToView();
                setCurrentFocus(i.name);
                return;
            } else if (i.name === 'email' && !isValidEmail(values.email.trim())) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (
                i.name === 'securityAnswer' &&
                (values.securityAnswer.includes('<') || values.securityAnswer.includes('>'))
            ) {
                setError('Please enter a valid Security Answer');
                scrollToView();
                setCurrentFocus(i.name);
                return;
            } else {
                setError('');
            }
        }
        const { password, verifyPassword, ...data } = trimValues();
        dispatch(setAccFormDetails(data));
        sessionStorage.setItem('accountData', JSON.stringify(data));
        sessionStorage.setItem('pass', password);
        dispatch(
            setAccountPwdDetails({
                password: values.password,
            })
        );
        const stateId = dropdown.State?.find((o) => o.value === values.state)?.id;
        dispatch(checkAccountDetailsValidation({ values, stateId }));
        // const user_payload = getUserPayload({ ...values, role });
        // dispatch(createUserAccount(user_payload));
    };

    useEffect(() => {
        if (errorValidation.message === 'Success') {
            dispatch(resetAccValidation());
            sessionStorage.setItem('prescriberAccnt',JSON.stringify(values))
            navigate('/prescriber/createaccountconfirmation', { replace: true });
        } else if (errorValidation.message) {
            scrollToView();
        } else if (errorCreateAccount.message !== 'Success' && errorCreateAccount.message !== null) {
            scrollToView();
        }
    }, [dispatch, errorValidation, navigate, errorCreateAccount]);

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['state', 'securityQuestion'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    return (
        <>
            <PageBackground label="Create User Name and Password" btn={true} handleClick={handleSubmit}>
                <>
                    {role === 'prescriber' ? (
                        accountContent.header.map((i) => (
                            <div
                                ref={containerRef}
                                style={{ margin: '12px 0 0 0' }}
                                key={i.order}
                                dangerouslySetInnerHTML={{ __html: i.content }}
                            ></div>
                        ))
                    ) : (
                        <div ref={containerRef} style={{ margin: '12px 0 0 0' }}>
                            Please enter your information below to begin the online account creation process.
                        </div>
                    )}
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>

                    <div className={classes.contentContainer}>
                        {errorValidation.message === 'Validation Error' && (
                            <Alert
                                content={errorValidation.errorMsg || errorCreateAccount.message}
                                styles={{ margin: '10px 20px 5px 20px' }}
                            />
                        )}
                        <div className={classes.formHeader}>{accountContent.body[0].content.title}</div>
                        {createAccFormFields.personalInfo.map((i) => {
                            if (i.name === 'state') {
                                return (
                                    <Input
                                        key={i.name}
                                        type="select"
                                        name="state"
                                        label="State"
                                        isRequired={true}
                                        onChangeHandler={handleOnChange}
                                        options={
                                            dropdown?.State
                                                ? [
                                                      { value: 'Select a State' } as { value: string },
                                                      ...dropdown?.State?.map((item) => ({ value: item.value })),
                                                  ]
                                                : []
                                        }
                                        defaultValue={accFormDetails[i.name] || values[i.name]}
                                    />
                                );
                            } else {
                                return (
                                    <Input
                                        key={i.name}
                                        type={i.type}
                                        name={i.name}
                                        label={i.label}
                                        helpText={i.helpText}
                                        maxLength={i.maxLength}
                                        isRequired={i.isRequired}
                                        onChangeHandler={handleOnChange}
                                        value={values[i.name]}
                                        defaultValue={accFormDetails[i.name]}
                                    />
                                );
                            }
                        })}
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.formHeader}>{accountContent.body[1].content.title}</div>
                        <div className={classes.formSubHeader}>{accountContent.body[1].content?.subHeader}</div>
                        {createAccFormFields.primaryOfc.map((i) => {
                            if (i.name === 'securityQuestion') {
                                return (
                                    <Input
                                        key={i.name}
                                        type="select"
                                        isRequired={true}
                                        name="securityQuestion"
                                        options={
                                            dropdown?.SecurityQuestion
                                                ? [
                                                      { value: 'Select a Question' } as { value: string },
                                                      ...dropdown?.SecurityQuestion?.map((item) => ({
                                                          value: item.value,
                                                      })),
                                                  ]
                                                : []
                                        }
                                        label="Security Question"
                                        style={{ width: '268px' }}
                                        onChangeHandler={handleOnChange}
                                        defaultValue={accFormDetails[i.name] || values[i.name]}
                                    />
                                );
                            } else {
                                return (
                                    <Input
                                        key={i.name}
                                        type={i.type}
                                        name={i.name}
                                        label={i.label}
                                        helpText={i.helpText}
                                        maxLength={i.maxLength}
                                        isRequired={i.isRequired}
                                        onChangeHandler={handleOnChange}
                                        defaultValue={accFormDetails[i.name]}
                                        value={values[i.name]}
                                    />
                                );
                            }
                        })}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </>
    );
};

export default CreateAccount;
