export const createAccFormFields = {
    personalInfo: [
        {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid First Name.',
            maxLength: 30,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Last Name.',
            maxLength: 30,
        },
    ],  
       
    personalId: [
        {
            name: 'NCPDPNumber',
            label: 'NCPDP Number',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid NCPDP Number.',
            maxLength: 7,
        },
        {
            name: 'DEANumber',
            label: 'DEA Number',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid DEA number.',
            maxLength: 9,
        },
      
    ],
    primaryOfc: [
        {
            name: 'userName',
            maxLength: 20,
            label: 'User Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter your User Name.',
            helpText:
                'Subsequent use of the Username and Password you choose will constitute a hand-written signature in the Pharmacy Portal for Bristol Myers Squibb-administered REMS programs. Your user name must be between four (4) and twenty (20) characters in length.',
        },
        {
            name: 'password',
            maxLength: 20,
            label: 'Password',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter your Password.',
            helpText:
                'Please choose a password that is eight (8) or more characters in length. Passwords must include at least one (1) upper case letter (e.g. A-Z), (1) lower case letter (e.g. a-z) and (1) number. Please note: passwords cannot contain your first name, last name, or user name.',
        },
        {
            name: 'verifyPassword',
            maxLength: 20,
            label: 'Verify Password',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter the Password confirmation.',
        },
        {
            name: 'email',
            maxLength: 60,
            label: 'Email Address',
            type: 'text',
            isRequired: true,
            helpText: 'yourname@abccompany.com',
            errMsg: 'Please enter a valid Email Address.',
        },
        {
            name: 'securityQuestion',
            label: 'Security Question',
            type: 'select',
            isRequired: true,
            errMsg: 'Please select a Security Question.',
        },
        {
            name: 'securityAnswer',
            maxLength: 50,
            label: 'Your Answer',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter your Security Answer.',
        },
    ],
    systemID: [
        {
            name: 'accessCode',
            label: 'Access Code',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Access Code.',
            maxLength: 7,
        },
    ],
    OfficeDetails: [
        {
            name: 'AddressLine1',
            label: 'Address Line 1',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Office Address.',
            maxLength: 50,
        },
        {
            name: 'AddressLine2',
            label: 'Address Line 2',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Office Address.',
            maxLength: 50,
        },
        {
            name: 'city',
            label: 'City',
            type: 'text',
            isRequired: true,
            maxLength: 30,
            errMsg: 'Please enter a valid City.',
        },
        {
            name: 'state',
            label: 'State',
            type: 'select',
            isRequired: true,
            errMsg: 'Please select a State.',
        },
        {
            name: 'zipCode',
            label: 'Zip Code',
            type: 'text',
            isRequired: true,
            helpText: 'e.g., xxxxx',
            maxLength: 5,
            errMsg: 'Please enter a valid Zip Code.',
        },
        {
            name: 'phoneNumber',
            label: 'Phone Number',
            type: 'text',
            isRequired: false,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            maxLength: 12,
            errMsg: 'Please enter a Phone Number.',
        }
    ]
};


export const requiredLabels = [
    ...createAccFormFields.personalInfo.filter((i) => i.isRequired),
    ...createAccFormFields.systemID.filter((i) => i.isRequired),
    ...createAccFormFields.personalId.filter((i) => i.isRequired),  
    ...createAccFormFields.primaryOfc.filter((i) => i.isRequired),
].map((i) => ({ name: i.name, label: i.label, errMsg: i.errMsg }));

export const staticFieldValue = [...createAccFormFields.personalInfo,...createAccFormFields.personalId,...createAccFormFields.OfficeDetails,...createAccFormFields.systemID,...createAccFormFields.primaryOfc].filter(
    (i) => i.name !== 'password' && i.name !== 'verifyPassword'
);