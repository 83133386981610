import { RootState, useAppDispatch } from 'store';
import { hiddenReportAPI, storeActionType } from 'store/slices/appSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import classes from './PatientDetails.module.scss';
import { axiosInstanceSurvey } from 'constants/Config/axiosConfig';

interface IhideUnhideProps {
    setShowHideUnhideModal: any;
    pName: any;
    pgmVisibility: any;
    setPgmVisibility: any;
    getAPIDetails: any;
    setFetchReport?: any;
}

const PopupHideUnhide = ({ setShowHideUnhideModal, pName, pgmVisibility, setPgmVisibility, getAPIDetails, setFetchReport }: IhideUnhideProps) => {

    const dispatch = useAppDispatch();
    const { actionTypes, selectedPatient } = useSelector((state: RootState) => state.app);
    const { currentUser } = useSelector((state: RootState) => state.login);
    const { hideAPIPost = [], fromComp = '', hiddenRepPayload = {} } = actionTypes;
    const isNonPresc = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const handelFilterID = (prodId) => {
        let filterId: any;
        if (getAPIDetails?.length > 0) {
            const objPgm = getAPIDetails?.find((obj) => { return obj?.productCountryId === actionTypes?.progId });
            filterId =
                isNonPresc
                    ? objPgm?.nonPrescriberPatientFilterId
                    : objPgm?.prescPatientFilterId;
        } else {
            if (hideAPIPost?.length > 0 && hideAPIPost?.filter((obj) => obj.pgmId === prodId).length > 0) {
                filterId = hideAPIPost?.find((obj) => obj.pgmId === prodId)?.filterID;
            } else
                filterId = null;
        }
        return filterId;
    }

    const handleContinue = async () => {
        const payload = {
            modifiedBy: currentUser.userinfo.username || null,
            createdBy: currentUser.userinfo.username || null,
            prescriberPatientFilterId: isNonPresc ? null : handelFilterID(actionTypes.progId),
            prescriberId: isNonPresc ? null : Number(currentUser?.systemId),
            "nonPrescriberPatientFilterId": isNonPresc ? handelFilterID(actionTypes.progId) : null,
            "nonPrescriberId": isNonPresc ? (currentUser?.nonPrescriberId) : null,
            patientId: selectedPatient?.patientId,
            actionFlag: pgmVisibility?.find((obj) => obj.id === actionTypes.progId)?.isVisible ? 'Y' : 'N',
            productCountryId: {
                productCountryId: actionTypes?.progId,
                product: {
                    productId: actionTypes?.progId,
                }
            }
        };
        try {
            const response = await axiosInstanceSurvey.post(`/v1/presc-patient`, payload);
            if (response.data.statusCode === 200) {
                const changePgmFlag = pgmVisibility.map((obj) => {
                    if (obj.id === actionTypes.progId) {
                        return { ...obj, isVisible: !obj['isVisible'] };
                    }
                    return obj;
                });
                setPgmVisibility(changePgmFlag);
                dispatch(storeActionType({
                    ...actionTypes,
                    btnType: '',
                    hideAPIPost: (hideAPIPost?.length > 0 && hideAPIPost?.filter((obj) => obj.pgmId === actionTypes.progId).length > 0) ? [...hideAPIPost] : [...hideAPIPost, {
                        pgmId: response?.data?.data?.productCountryId,
                        filterID: isNonPresc ? (response?.data?.data?.nonPrescriberPatientFilterId) : response?.data?.data?.prescPatientFilterId,
                        actionFlag: response?.data?.data?.actionFlag,
                    }]
                }));

                if (fromComp === 'HiddenReport') {
                    dispatch(hiddenReportAPI({ data: hiddenRepPayload })).then((e: any) => {
                        if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                            setFetchReport(e.payload?.data?.data);
                        }
                    });
                }

                setShowHideUnhideModal(false);
            }
        } catch (err) {
            console.error('post Api Error');
        }
    };

    const handleCancel = () => {
        dispatch(storeActionType({ ...actionTypes, btnType: '' }));
        setShowHideUnhideModal(false);
    };


    return (
        <div>
            <dialog className={classes.hideUnhideModal}>
                <div className={classes.whiteInfoContainer}>

                    <div className={classes.bigPadding}>
                        <div>You have selected to <span dangerouslySetInnerHTML={{ __html: actionTypes.btnType }}></span> this patient (<span>{pName()}</span>) from the Prescription Action Items. Do you wish to continue?</div>
                    </div>
                    <div className={classes.btnContainer}>
                        <button
                            onClick={handleContinue}
                            className={`${classes.btn} ${classes.continueBtn}`}
                        />
                        <button
                            onClick={handleCancel}
                            className={`${classes.btn} ${classes.cancelBtn}`}
                        />
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default PopupHideUnhide;
