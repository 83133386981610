import React from 'react';
import classes from './Alert.module.scss';

const Alert = ({ content, styles }: { content: string; styles?: any }) => {
    return (
        (content?.length > 0) && (
            <div className={classes.container} style={styles}>
                <span dangerouslySetInnerHTML={{ __html: content }}></span>               
            </div>
        )
    );
};

export default Alert;
