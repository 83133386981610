import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import { prescriberSearchFields } from 'constants/prescriberSearchFields';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { fetchCurrentPrescriber, patientSearchStore } from 'store/slices/appSlice';
import {
    alphaNumeric,
} from 'utils/regex';
import Input from '../../components/Input';
import classes from './PrescriberSearch.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';

interface dropDownType {
    State?: { id: string; value: string }[] | null;
    'ICD-10': { id: string; value: string }[] | null;
}


const PrescriberSearch = (props) => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        prescriberIdNum: '',
    });
 
    const [servererror, setServerError] = useState('');
    const [riskError, setRiskError] = useState(false);
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedProgram } = useSelector((state: RootState) => state.app);

    const handleOnChange = (e) => {
        servererror && setServerError('');
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            newVals[field] = newVals[field].trim();
        });
        setValues(newVals);
        return newVals;
    };

    const handleSubmit = async () => {
        await trimValues();
        setServerError('');
        setRiskError(false);
        const fields = prescriberSearchFields;
        const reqFields = prescriberSearchFields

        for (let i of reqFields) {
            if (i.name === 'prescriberIdNum' && !alphaNumeric(values.prescriberIdNum.trim()) && (values.prescriberIdNum.trim().length) > 0) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name)
                scrollToView();              
                return;
            } else if (i.name === 'prescriberIdNum' && !alphaNumeric(values.prescriberIdNum.trim())) {
                setError(t(i.errMsg).replace("valid", ""));
                setCurrentFocus(i.name);
                scrollToView();              
                return;
            } 
            else {
                setError('');
                const payload = {
                    prescriberExtUniqueId: values.prescriberIdNum.trim(),
                };
                dispatch(fetchCurrentPrescriber(payload))
                .then((res:any) => {
                    if(res?.payload.status === 204){
                        setServerError(t('prescriberSearchNotFoundError'));
                        setValues(prev => ({...prev, prescriberIdNum: ''}))
                    }else {
                        //with location pass the current url
                       sessionStorage.setItem('prevRoute',location.pathname)
                        navigate('/prescriber/prescriberSearchResult');
                    }

                })
            }
        
        }
    };

    return (
        <>
        <SessionTimeout/>
        <div className={classes.mainContainer} ref={containerRef}>
                <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
            <div className={classes.productHeader}>{t('prescriberSearchHeader')}</div>
            <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
            <div className={classes.contentContainer}>
                {servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                <div style={{ paddingTop: 10 }}></div>
                <div className={classes.helpLink_postion}>
                    <Input
                        type="text"
                        name="prescriberIdNum"
                        maxLength={10}
                        label={t('prescriberPin')}
                        isRequired={true}
                        helpText={t('helpTextPrescriberSearch1')}
                        helpText2={t('helpTextPrescriberSearch2')}
                        onChangeHandler={handleOnChange}
                        value={values['prescriberIdNum']}
                        width='103px'
                        containerStyle={{ gridTemplateColumns: '0.9fr 1.3fr'}}
                    />
                </div>

                <div className={classes.btnMargin}>
                    <Button
                            clickFunction={handleSubmit}
                            className={getBtnClass(selectedProgram.id)}
                            width={i18n.language === 'es' ? 328 : 195}
                            height={45}
                            size={16}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.prescriberSearch : buttonContent.en.prescriberSearch}
                    ></Button>

                </div>
            </div>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
        </>
    );
};

export default PrescriberSearch;
