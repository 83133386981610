
export const getHTMLTemplate=(content)=>{
    return `<html>
    <head>
    <title>REMS Pharmacy Portal</title>
    <style>
    .ant-table {
            border-collapse: collapse;
            border-spacing: 0;
            width: fit-content;
            margin:0 auto;
            border: 2px solid black;
                td{
                    padding:15 0 15;
                }
                .dispense_details{
                    td{
                        padding:0 0 0 10px;
                        width: 100%;
                    }
                    margin-left:30px;
                }
                    }

        .ant-table th,
        .ant-table td {
            border-top: 2px solid black;
            border-left: 2px solid black;
            text-align:left; 
            padding-left:10px;
            }
                .ant-table th:first-child, .ant-table td:first-child {
                    border-left: none;
                }
                .ant-table tr:first-child th {
                    border-top: none;

                }

                .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
                    margin-block: 0;
                    margin-inline: 0;
                }
    </style>
    </head>
    <body>
        ${content} 
    </body>
    </html>`
}
