import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { Role } from 'constants/Config/appConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { createAssociation } from 'store/slices/appSlice';
import classes from './AssociationConfirmation.module.scss';

interface dropDownType {
    SecurityQuestion?: { id: string; value: string }[] | null;
}

const AssociationConfirmation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [apiError, setApiError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');

    const { role, associationData } = useAppSelector((state) => state.app);
    const { onlineUserId, systemId } = useAppSelector((state) => state.login.currentUser)
    const { userName, firstName, lastName, city, state } = associationData.data
    const isPharmacy = role === Role.Pharmacist;

    const handleContinue = () => {
        if (submitted) {
            navigate('/prescriber/manageMyAccount');
        } else {
            const payload = {
                associationType: associationData?.state,
                prescriberId: systemId,
                associatedPrescriberId: associationData?.state === 'presc' ? associationData?.data?.associatedId : null,
                associatedNonPrescriberId: associationData?.state === 'non_presc' ? associationData?.data?.associatedId : null,
                createdByUser: onlineUserId,
            };

            //dispatch submit call 
            dispatch(createAssociation(payload)).then((res: any) => {
                if(res?.payload?.status !== 200){
                    setApiError(res.payload?.response?.data?.message)
                }else{
                    setSubmitted(true)
                }
            })
        }
    };

    const handleCancel = () => {
        navigate('/prescriber/manageMyAccount');
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        setError('');
        setCurrentFocus('');
    };

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {};
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const switchHeading = () => {
        if(associationData?.state === 'presc'){
            if(submitted){
                return 'Prescriber Association Confirmation'
            }else {
                return 'Create Prescriber Association'
            }
        }else{
            if(submitted){
                return 'Non-prescriber Association Confirmation'
            }else {
                return 'Create Non-prescriber Association'
            }
        }
    }
    return (
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label={switchHeading()}
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={submitted ? undefined : handleCancel}
            >
                <>
                    <div className={classes.contentContainer}>
                        {apiError && <Alert content={apiError} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.formHeader}>
                            {!submitted ? (
                                <>
                                    {associationData?.state === 'non_presc' ? (
                                        <p>
                                            Selecting Continue will create an association with the Non-prescriber. This
                                            Non-prescriber will be able to view your patients in the Prescriber
                                            Dashboard.
                                        </p>
                                    ) : (
                                        <>
                                            <p>
                                                Selecting Continue will create an association with the Prescriber. This
                                                Prescriber will be able to view your patients in the Prescriber
                                                dashboard.
                                            </p>
                                            <p>
                                                Please note: You will not be able to view this Prescriber's patients in
                                                your dashboard until he/she associates to you.
                                            </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <p style={{ marginTop: '10px' }}>
                                        Your {associationData?.state === 'non_presc' ? 'Non-prescriber': 'Prescriber' } association with {firstName} {lastName} has been created.
                                    </p>
                                    <p> Please click the Continue button below to return to Manage My Account.</p>
                                </>
                            )}
                        </div>
                        {submitted ? (
                            <></>
                        ) : (
                            <>
                                <div style={{ marginTop: '10px' }} className={classes.container}>
                                    <div className={classes.label}>
                                        <span>User Name:</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {userName}
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <div className={classes.label}>
                                        <span>First Name:</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {firstName}
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <div className={classes.label}>
                                        <span>Last Name:</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {lastName}
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <div className={classes.label}>
                                        <span>City:</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {city}
                                    </div>
                                </div>
                                <div className={classes.container}>
                                    <div className={classes.label}>
                                        <span>State:</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {state}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default AssociationConfirmation;
