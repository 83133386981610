import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import {
    axiosInstance_2
} from 'constants/Config/axiosConfig';
import { patientSearchFields } from 'constants/patientSearchFields';
import moment from 'moment';
import { useRef, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { patientReactivationSpecialStore, patientSearchStore, prescriberSurveySearchStore } from 'store/slices/appSlice';
import {
    isValidName,
    isValidPatientNum
} from 'utils/regex';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import classes from './PrescriberSurveySearch.module.scss';

import Button from 'components/Button';
import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import { ProgramConfig,mainMenu } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';



interface dropDownType {
    State?: { id: string; value: string }[] | null;
    'ICD-10': { id: string; value: string }[] | null;
}
const getDateArray = () => {
    return Array.from({ length: 31 }, (_, ind) => (ind + 1).toString().padStart(2, '0'));
};



const PrescriberSurveySearch = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        patientIdNum: '',
        month: '',
        date: '',
        year: '',
    });

    const [servererror, setServerError] = useState('');
    const [patientValidationError, setPatientValidationError] = useState('');
    const [riskError, setRiskError] = useState(false);
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const containerRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { selectedProgram} = useSelector((state: RootState) => state.app);

    dispatch(patientSearchStore(values));

    const handleOnChange = (e) => {
        servererror && setServerError('');
        patientValidationError && setPatientValidationError('');
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
       reloadFunction();
    }, []);
    const getProgramsName = (): string => {

        return mainMenu(selectedProgram.id);
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['month', 'date'].includes(currentFocus) &&
            inputElement.select();
        setError('');
        setCurrentFocus('');
        // const isDobValid = dobValidation();

    };

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const trimValues1 = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {

            newVals[field] = field === 'buttonName' ? newVals[field] : newVals[field].trim();
        });
        setValues(newVals);
        return newVals;
    };
    function hasSpecialChar(string) {
        var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        return regex.test(string);
    }


    let dateOfBirth = values.date + '-' + values.month.toUpperCase() + '-' + values.year;
    let remsProgram = selectedProgram.label;
    let source = "Survey"
    const PrescriberSurvey = async () => {
        let resp;
        resp = await axiosInstance_2.get(
            // `/diagnosis?patientGender=${getEngVal(values.sex)}&diagnosisCode=${values.diagnosis.split('-')[0]}`
            `/findPatientByLastName?lastName=${values.lastName.trim()}&patientExternalUniqueId=${values.patientIdNum}&dob=${dateOfBirth}&product=${remsProgram}&source=${source}`
        ).then(async (res) => {
            let apiResponse = res?.data

            if (res.data.statusCode === 200) {
                dispatch(prescriberSurveySearchStore(apiResponse));
                dispatch(patientReactivationSpecialStore([]));
                navigate("/prescriber/prescribersurveypatientsearch");
                return true;
            }

                else if ((res.data.statusCode === 204 && res.data.data.errorCode === 'PD0330') ){
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PD0330').replaceAll('{program}', getProgramsName()));
                }
            }
            else if (res.data.statusCode === 204) {

                dispatch(patientReactivationSpecialStore(apiResponse));
                dispatch(prescriberSurveySearchStore([]));
                navigate("/prescriber/prescribersurveypatientsearch");
            }
           
            else if (res.data.data.errorCode === 'PV0450' ) {
                if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                    setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));
                }
            }
            else {
                return false;
            }
        }).
            catch((error) => {
                if (error?.message === 'Request failed with status code 500') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                        let errorMsg = '';
                    }
                }
                else if (error?.message === 'Request failed with status code 404') {
                    if (!hasSpecialChar(values.lastName) && !hasSpecialChar(values.patientIdNum)) {
                        setServerError(t('error_PD0330'));
                    }


                }
                else {
                    console.error("error in prescriber survey Flow", error)
                }
            });

    };
    const handleSubmit = async () => {
        await trimValues1()
        setServerError('');
        setRiskError(false);
        const fields = patientSearchFields;
        const sysDate = new Date()//.toISOString().split('T')[0];
        const { month, date, year } = values;
        const dob = month + '/' + date + '/' + year;
        const format = 'MMM/DD/YYYY';
        const reqFields = patientSearchFields.filter(
            (item) =>
                !(item.name === 'otherDiag' && values['diagnosis'] !== '000-OTHER') &&
                !(['surgical', 'natural', 'menstrating'].includes(item.name) && values['sex'] === t('male'))
        );

        for (let i of reqFields) {
            if (
                values[i.name].trim() === ''


            ) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (['lastName'].includes(i.name) && !isValidName(values[i.name])) {
                setCurrentFocus(i.name.trim());
                setError(t(i.errMsg));
                scrollToView();
                return;
            } else if (i.name === 'year' && !isValidPatientNum(values.year, 4)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            }

            else if (Number(year) <= 1900) {
                setError(t('patientDOBCombinationError'));
                setCurrentFocus('month');
                scrollToView();
                // return;
            }

            else if (moment(dob, format) > moment(sysDate) || !moment(dob, format, true).isValid()) {
                setError(t('patientDOBError'));
                setCurrentFocus('month');
                scrollToView();
            }

            else if (i.name === 'patientIdNum' && !isValidPatientNum(values.patientIdNum, 9)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();

                return;
            }
            else {
                setError('');
            }
        }
    };

    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer} ref={containerRef}>
                <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                <div className={classes.contentContainer}>
                    {!error && servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                    {patientValidationError && (
                        <div
                            className={AlertClass.container}
                            style={{ margin: '10px 30px 5px 30px' }}
                            dangerouslySetInnerHTML={{ __html: patientValidationError }}
                        ></div>
                    )}
                    {riskError && (
                        <div className={classes.riskError}>
                            {t('patientRiskCategoryError1')}
                            <span dangerouslySetInnerHTML={{ __html: mainMenu(selectedProgram.id) }}></span>
                            {t('patientRiskCategoryError2')}
                        </div>
                    )}
                    <div style={{ paddingTop: 10 }}></div>

                    <Input
                        type="text"
                        name="lastName"
                        label={t('lastName')}
                        onChangeHandler={handleOnChange}
                        isRequired={true}
                        maxLength={30}
                        value={values['lastName']}
                    />
                    <div className={classes.helpLink_postion}>
                        <Input
                            type="text"
                            name="patientIdNum"
                            maxLength={9}
                            label={t('patientPin')}
                            isRequired={true}
                            helpText={t('helpTextPatientPin')}
                            onChangeHandler={handleOnChange}
                            value={values['patientIdNum']}
                        />
                    </div>
                    <div className={inputStyles.container}>
                        <div className={inputStyles.label}>
                            <span className={inputStyles.mandatory}>{t('DOB')}:</span>
                        </div>

                        <div className={inputStyles.input}>
                            <div>
                                <select
                                    style={{ width: 48 }}
                                    name="month"
                                    id="month"
                                    onChange={handleOnChange}
                                    value={values['month'] ? values['month'] : '---'}
                                >
                                    <option key={t('selectOne')} value={t('selectOne')}>
                                        ---
                                    </option>
                                    {moment.monthsShort().map((i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    style={{ width: 38, margin: '0 5px' }}
                                    name="date"
                                    id="date"
                                    onChange={handleOnChange}
                                    value={values['date'] ? values['date'] : '--'}
                                >
                                    <option key={t('selectOne')} value={t('selectOne')}>
                                        --
                                    </option>
                                    {getDateArray()?.map((i) => (
                                        <option key={i} value={i}>
                                            {i}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    name="year"
                                    id="year"
                                    onChange={handleOnChange}
                                    maxLength={4}
                                    style={{ width: 60 }}
                                    value={values['year']}
                                    tabIndex={0}
                                />
                                <p className={inputStyles.helpText}>{t('helpTextDob')}</p>
                            </div>
                        </div>
                    </div>

                    <div className={classes.btnMargins}>
                        <Button
                                clickFunction={() => { handleSubmit(); PrescriberSurvey(); }}
                                className={getBtnClass(selectedProgram.id)}
                                width={178}
                                height={42}
                                size={16}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.patientSearch : buttonContent.en.patientSearch}
                        ></Button>
                    </div>
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );

};


export default PrescriberSurveySearch;