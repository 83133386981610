import { trimPrefix } from 'utils/trimPrefix';
import classes from './generalPatientAuthData.module.scss';
import { formatDate } from 'utils/formatDate';

const GeneralPateintAuthData = ({ authData }) => {
    const {
        patientNameFirst = null,
        patientNameLast = null,
        productName = null,
        authCreatedDate = null,
        authorizationNumber = null,
        authorizationStatus = null,
        authAge = null,
        activeStatusExpirationDate = null,
    } = authData;
   
    const showAuthStatus = (status) => {
        return status.toLowerCase() === 'mismatched'
            ? 'Survey-Flagged'
            : status.toLowerCase() === 'obsolete'
            ? 'Authorization Number is no longer valid'
            : status.toLowerCase() === 'expired'
            ? 'Authorization Number Expired'
            : status;
    };

    return (
        <div className={classes.generalTable}>
            <table>
                <tr>
                    <th>
                        Patient <br /> Name
                    </th>
                    <th>
                        REMS <br /> Program
                    </th>
                    <th>Authorization Date</th>
                    <th>Authorization Number</th>
                    <th>Authorization Status</th>
                    <th>Authorization Age</th>
                    <th>Authorization&nbsp;&nbsp;&nbsp;&nbsp; Expiration Date</th>
                </tr>
                <tr>
                    <td>{patientNameFirst + ' ' + patientNameLast}</td>
                    <td>{productName && productName}</td>
                    <td>{authCreatedDate && formatDate(authCreatedDate)}</td>
                    <td>{authorizationNumber && trimPrefix(authorizationNumber)}</td>
                    <td>{authorizationStatus && showAuthStatus(authorizationStatus)}</td>
                    <td>{authAge && Math.abs(authAge)}</td>
                    <td>{activeStatusExpirationDate && formatDate(activeStatusExpirationDate)}</td>
                </tr>
            </table>
        </div>
    );
};

export default GeneralPateintAuthData;
