import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { changeHelpContent, changePPAF, setSurveyInfoFromRoute } from 'store/slices/appSlice';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import { axiosInstanceNewUrl, axiosInstanceSurvey } from './../../constants/Config/axiosConfig';
import classes from './PrescriberSurveyPatientSearch.module.scss';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';

// import Revise from './../../assets/images/btn_Revise.png';
// import Save from './../../assets/images/btn_Continue_Save.png';

const PrescriberSurveyPatientSearch = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();
    const [existingRC, setExistingRC] = useState(null);
    const [errorMessage, setErrorMessage] = useState("")


    const { patientSearchStore, patientPrescriberStore, patientReactivationStore, patientReactivationSpecialStore } = useAppSelector((state) => state.app);

    const { patientInformation, accFormDetails, selectedProgram, prescriberUniqueID, prescriberSurveySearchStore, prescriberDetails } = useAppSelector(
        (state) => state.app
    );

    const { currentUser, } = useAppSelector((state) => state.login);

    const selectedLanguage = i18n.language;

    const Prescriber =
        `${accFormDetails?.firstName} ${accFormDetails?.lastName}` ||
        `${currentUser?.firstName} ${currentUser?.lastName}`;

    const product = selectedProgram.id;
    const mi = `${patientInformation?.mi}`;
    const patientPin = `${patientSearchStore?.patientIdNum}`;
    const patientenrollstatus = `${prescriberSurveySearchStore?.data?.patientRegistrationForms[0]?.patientRegStatus[0]?.registrationStatus?.lookupValue}`;
    const dob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`;
    const Patientdob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`.replace("00:00:00", "");
    const convertedDate = moment(dob, 'MMM DD,YYYY').format('MM/DD/YYYY');


    const localData = prescriberSurveySearchStore?.statusCode === 200 ?
        (prescriberSurveySearchStore?.data) :
        (patientReactivationSpecialStore?.data?.patientDTO);

    useEffect(() => {
        return () => {
            dispatch(changeHelpContent('patientVerification'));
        };
    }, [dispatch]);
    const handlePSInformation = async () => {
        let pp;
        let checkData = Object.keys(prescriberSurveySearchStore).length > 0
        if (checkData) {
            pp = prescriberSurveySearchStore?.data?.patientPrescribers
        } else {
            pp = patientReactivationSpecialStore?.data?.patientDTO?.patientPrescribers;
        }

        if (pp) {
            const apiPatientId = prescriberSurveySearchStore.data !== undefined ? prescriberSurveySearchStore?.data?.patientId : patientReactivationSpecialStore?.data?.patientDTO?.patientId;
            const apiPrescriberId = prescriberDetails?.prescriberSystemId;
            const apiProductCountryId = selectedProgram?.productCountryId;
            const apiLanguageId = i18n.language === "es" ? 2 : 1

            const payload = {
                Channel: "EXT",
                PrescriberId: apiPrescriberId,
                PatientId: apiPatientId,
                ProductCountryId: apiProductCountryId,
                LanguageId: apiLanguageId

            }

            let response = await axiosInstanceSurvey
                .post('/v1/surveys/ivr-validate-prescriber-survey-eligibility', payload)
                .then((res: any) => {
                    if (res.data?.ResponseStatus === "0") {
                        dispatch(setSurveyInfoFromRoute(res.data))
                        dispatch(changePPAF(true));
                        navigate("/prescriber/prescribersurveyinformation")
                    } else if (res.data?.ResponseStatus === "0" && res?.data?.SurveyIneligibleMessage !== undefined) {
                        const surveyMessage = res?.data?.SurveyIneligibleMessage.replaceAll('<ProductName>', getProgramsName())
                        dispatch(setSurveyInfoFromRoute(surveyMessage))
                        dispatch(changePPAF(true));
                        navigate("/prescriber/prescribersurveyinformation")
                    } else {
                        if (res?.data?.SurveyIneligibleMessage !== undefined) {
                            setErrorMessage(res?.data?.SurveyIneligibleMessage.replaceAll('<ProductName>', getProgramsName()))
                        } else {
                            setErrorMessage(t('error_PV0450').replaceAll('{program}', getProgramsName()));
                        }
                    }

                })
                .catch((error) => {
                    setErrorMessage(error)
                });
        } else {
            alert('Data Corrupted')
        }

    }

    let remsProgram = selectedProgram.label;
    let selectedRemsProgramCountryId = selectedProgram.productCountryId;
    const getProgramsName = (): string => {

        return mainMenu(selectedProgram.id);
    };
    const handleRevise = () => {
        navigate(`/prescriber/prescribersurveysearch`);
    };
    const handleSave = () => {
        handlePSInformation()
    };
    const authStatuslang = i18n.language === "es" ? 1 : 0;
    // let surveyResponse = localData?.surveyData[0];
    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                    backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                }}></div>}
                <div className={classes.headerDivider} style={{
                    borderColor: `${ProgramConfig(selectedProgram.id).color}`
                }}></div>
            </div>
            <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
            <div className={classes.contentDivider}>
                <div className={classes.mainContainer}>
                    {errorMessage && <Alert styles={{ margin: '10px 30px 5px 30px', fontSize: "10px" }} content={errorMessage} />}
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="firstName"
                        label={t('firstName')}
                        valueExist={localData?.patientNameFirst}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="lastName"
                        label={t('lastName')}
                        valueExist={localData?.patientNameLast}
                    />
                    <Input
                        type="text"
                        name="mi"
                        label={t('mi')}
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        valueExist={localData?.patientMiddleInitial}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="DOB"
                        label={t('DOB')}
                        isRequired={true}
                        valueExist={Patientdob}

                    />
                    <Input
                        type="text"
                        name="patientIdNum"
                        // maxLength={9}
                        label={t('patientPin')}
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        valueExist={localData?.patientExternalUniqueId}
                    />


                    <Input
                        type="text"
                        name="patientEnrollmentStatus"
                        label={t('patientenrollstatus')}
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        valueExist={localData?.patientRegistrationForms?.filter(form => form.productCountry.productCountryId === selectedRemsProgramCountryId)?.[0]?.patientRegStatus[0]?.registrationStatus?.lookupValue}
                    />

                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="authorizationStatus"
                        label={t('authorizationStatus')}
                        // valueExist={localData?.surveyData[0]?.authorizationStatus || ''}
                        valueExist={localData?.surveyData ? localData?.surveyData[0]?.authorizationStatus?.split('||')[authStatuslang] : null}
                    // .split('||')[1]
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="authorizationNumber"
                        label={t('authorizationNumber')}
                        valueExist={localData?.surveyData ? localData?.surveyData[0]?.authorizationNumber?.substring(2) : ''}
                    />
                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="dateLastPrescriberSurveyCompleted"
                        label={t('dateLastPrescriberSurveyCompleted')}
                        valueExist={localData?.surveyData ? moment(localData?.surveyData[0]?.prescriberSurveyCompletedDate).format('MMMM DD, YYYY') !== 'Invalid date' ? moment(localData?.surveyData[0]?.prescriberSurveyCompletedDate).format('MMMM DD, YYYY') : '' : ''}
                    />
                    {patientInformation.newRisk && existingRC && existingRC !== patientInformation.newRisk && (
                        <p className={classes.risk_warning}>
                            {t('riskChangeLine1')} <strong>{existingRC}.</strong>
                            <br /> {t('riskChangeLine2')} <strong>{patientInformation?.newRisk}.</strong>
                            <br />
                            {t('riskChangeLine3')}{' '}
                        </p>
                    )}
                    <div className={classes.btn_group}>



                        <Button
                            clickFunction={handleSave}
                            className={getBtnClass(selectedProgram.id)}
                            width={i18n.language === 'es' ? 260 : 190}
                            height={45}
                            size={18}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.presSurvey : buttonContent.en.presSurvey}

                        ></Button>
                        <Button
                            clickFunction={handleRevise}
                            className={getBtnClass(selectedProgram.id)}
                            width={266}
                            height={45}
                            size={18}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.searchNewPat : buttonContent.en.searchNewPat}

                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrescriberSurveyPatientSearch;
