import {ProgramConfig,lenalidomide,pomalidomide,thalomid} from 'constants/Config/programsConfig';
const registerIcon = '<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>';
const symbol = '<span>&#39</span>';

export const PatientAgreementLen_Spanish = {
    body: {
        formHeading: `Formulario de acuerdo entre paciente y médico para ${ProgramConfig('rems_program_lenalidomide').title}`,
        bodynote: 'Lea detenidamente las afirmaciones siguientes.',
        fields: `<p>Los campos marcados con un <span style="color: #7b0305">*</span> son obligatorios.</p>`,
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Su proveedor de atención médica le ha recetado lenalidomida a su hijo.* Lenalidomida está disponible únicamente a través de un programa especial llamado ${ProgramConfig('rems_program_lenalidomide').title} (Estrategia de evaluación y mitigación de riesgos de lenalidomida). Para recibir lenalidomida, debe estar inscrito en ${ProgramConfig('rems_program_lenalidomide').title} y aceptar seguir los requisitos. ${ProgramConfig('rems_program_lenalidomide').title} cubre lo siguiente:</p>`,
                riskCatergory: [
                    'Male Child',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Su proveedor de atención médica le ha recetado lenalidomida a su hija.* Lenalidomida está disponible únicamente a través de un programa especial llamado ${ProgramConfig('rems_program_lenalidomide').title} (Estrategia de evaluación y mitigación de riesgos de lenalidomida). Para recibir lenalidomida, debe estar inscrita en ${ProgramConfig('rems_program_lenalidomide').title} y aceptar seguir los requisitos. ${ProgramConfig('rems_program_lenalidomide').title} cubre lo siguiente:</p>`,
                riskCatergory: [
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Su proveedor de atención médica le ha recetado lenalidomida a usted. Lenalidomida está disponible únicamente a través de un programa especial llamado ${ProgramConfig('rems_program_lenalidomide').title} (Estrategia de evaluación y mitigación de riesgos de lenalidomida). Para recibir lenalidomida, debe estar inscrito en ${ProgramConfig('rems_program_lenalidomide').title} y aceptar seguir los requisitos. ${ProgramConfig('rems_program_lenalidomide').title} cubre lo siguiente:</p>`,
                riskCatergory: [
                    'Adult Male',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Su proveedor de atención médica le ha recetado lenalidomida a usted. Lenalidomida está disponible únicamente a través de un programa especial llamado ${ProgramConfig('rems_program_lenalidomide').title} (Estrategia de evaluación y mitigación de riesgos de lenalidomida). Para recibir lenalidomida, debe estar inscrita en ${ProgramConfig('rems_program_lenalidomide').title} y aceptar seguir los requisitos. ${ProgramConfig('rems_program_lenalidomide').title} cubre lo siguiente:</p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Su proveedor de atención médica le ha recetado lenalidomida a usted. Lenalidomida está disponible 
                únicamente a través de un programa especial llamado ${ProgramConfig('rems_program_lenalidomide').title} (Estrategia de evaluación y 
                mitigación de riesgos de lenalidomida). Para recibir lenalidomida, debe inscribirse en ${ProgramConfig('rems_program_lenalidomide').title} 
                y aceptar los siguientes requisitos. ${ProgramConfig('rems_program_lenalidomide').title} cubre lo siguiente:</p>`,
                riskCatergory: [
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            
            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomida)</li><li style="padding-left: 0px;"><span class="bold">lenalidomida genérica</span></li></ul>                
                </span>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">Si una mujer con la que su hijo mantiene relaciones sexuales está embarazada o queda embarazada mientras su hijo está tomando lenalidomida, es importante que sepa que el bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Male Child',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">
                Si su hija está embarazada o queda embarazada mientras está tomando lenalidomida, es importante que sepa que su bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Female Child of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">
                Todo bebé en gestación de una menor que toma lenalidomida puede tener defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">
                Si una mujer con la que mantiene relaciones sexuales está embarazada o queda embarazada mientras usted está tomando lenalidomida, es importante que sepa que el bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Adult Male',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">
                Si está embarazada o queda embarazada mientras está tomando lenalidomida, es importante que sepa que su bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p class="bold">
                El bebé en gestación de una mujer que toma lenalidomida puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                Lenalidomida aumenta las probabilidades de que se formen coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>`,
                riskCatergory: [
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 4,
                content: ` <p>Para obtener más información, consulte la Guía del medicamento de lenalidomida.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 5,
                content: ` <p class="bold">INSTRUCCIONES</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span>que su hijo inicie su tratamiento con lenalidomida, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [
                    'Male Child',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span>que su hija inicie su tratamiento con lenalidomida, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> iniciar su tratamiento con lenalidomida, es necesario que haga lo siguiente:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: ` <p><span class="bold">1.</span> Llene las secciones 1 y 2 de este formulario y coloque su firma y la fecha al final del formulario.
                </p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 8,
                content: ` <p><span class="bold">2.</span> Lea los materiales de ${ProgramConfig('rems_program_lenalidomide').title} contenidos en el <span class="bold">Paquete de recursos para el paciente.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 9,
                content: ` <p><span class="bold">3.</span> Conserve una copia de este formulario para sus registros.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: `<p class="bold">Representantes autorizados:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: `<p>Si el representante autorizado no tiene un poder notarial,<span class="bold"> se debe presentar una carta firmada y fechada por el profesional que receta el medicamento, con el membrete de este, a ${ProgramConfig('rems_program_lenalidomide').title}, junto con el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_lenalidomide').title}.</span> Esta carta debe incluir: una declaración que explique que el paciente incapaz no tiene capacidad para completar el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_lenalidomide').title}, incluida una identificación de la afección médica que causa la incapacidad; el nombre y la dirección del representante autorizado; la relación del representante autorizado con el paciente; y un enunciado que indique que el representante autorizado se hace responsable que el paciente cumpla con ${ProgramConfig('rems_program_lenalidomide').title} y que está autorizado para dar su consentimiento para que se realice el tratamiento con lenalidomida en nombre del paciente.
            </p>`,
                riskCatergory: [
                    'Adult Male',
                ],
            },
            {
                order: 11,
                content: `<p>Si el representante autorizado no tiene un poder notarial,<span class="bold"> se debe presentar una carta firmada y fechada por el profesional que receta el medicamento, con el membrete de este, a ${ProgramConfig('rems_program_lenalidomide').title}, junto con el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_lenalidomide').title}.</span> Esta carta debe incluir: una declaración que explique que la paciente incapaz no tiene capacidad para completar el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_lenalidomide').title}, incluida una identificación de la afección médica que causa la incapacidad; el nombre y la dirección del representante autorizado; la relación del representante autorizado con la paciente; y un enunciado que indique que el representante autorizado se hace responsable de que la paciente cumpla con ${ProgramConfig('rems_program_lenalidomide').title} y que está autorizado para dar su consentimiento para que se realice el tratamiento con lenalidomida en nombre de la paciente. </p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: ` <p>Si desea obtener más información, visite <span class="bold">www.LenalidomideREMS.com,</span> o llame a ${ProgramConfig('rems_program_lenalidomide').title} al 
                <b>1-888-423-5436.</b></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 13,
                content: ` <p><b>*</b>A lo largo de este formulario, la palabra hija incluye a cualquier menor del que usted sea padre, madre o tutor legal.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
        ],
        patientArgeement: {
            note: `Lea detenidamente las afirmaciones siguientes. Marque la casilla de verificación junto a cada una de las afirmaciones.`,
            header: `Sección 1. Acuerdo del paciente`,
            subtitle: `Entiendo y confirmo que:`,
            arrgements: [],
        },

        authorization: {
            header: `Sección 2. Autorización`,
            disclaimers: [
                {
                    order: 0,
                    content: `<style> .bold {
                        font-weight: bold;
                    }
                    .italic {
                        font-style: italic;
                    }
                    .legalSymbol {
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    }</style>`,
                    riskCatergory: [
                        'Male Child',
                        'Adult Male',
                        'Female Child of Reproductive Potential',
                        'Adult Female of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<span class="bold" >Entiendo que mi información será compartida con el Programa ${ProgramConfig('rems_program_lenalidomide').title}. Los manufactureros de lenalidomida también pueden utilizar la información para propósitos de negocios, en la medida en que lo permita la ley aplicable.
                    </span>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<span class="bold" >Entiendo que la información de mi hija será compartida con el Programa ${ProgramConfig('rems_program_lenalidomide').title}. Los manufactureros de lenalidomida también pueden utilizar la información para propósitos de negocios, en la medida en que lo permita la ley aplicable.
                     </span>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ],
                },
                {
                    order: 110,
                    content: `<span class="bold" >Entiendo que la información de mi hijo será compartida con el Programa ${ProgramConfig('rems_program_lenalidomide').title}. Los manufactureros de lenalidomida también pueden utilizar la información para propósitos de negocios, en la medida en que lo permita la ley aplicable.
                    </span>`,
                    riskCatergory: ['Male Child'],
                },
            ],
            arrgements: [
                {
                    order: 1,
                    content: `Al firmar este formulario, <b>autorizo a que mi proveedor de atención médica inicie mi tratamiento con
                    lenalidomida</b>`,
                },
            ],
            body: ``,
            footer: `El programa REMS de la lenalidomida cubre tanto REVLIMID (lenalidomida) como los productos genéricos de la
            lenalidomida. Los fabricantes de lenalidomida tienen un acuerdo contractual para la administración del programa
            REMS de la lenalidomida. Todos los fabricantes conservan la responsabilidad de las acciones descritas en el
            programa REMS.`,
        },
        authorizationTreatment: {
            header: `Sección 3. Autorización para iniciar el tratamiento`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hijo no sigue todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title} no podrá recibir lenalidomida. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento se pondrá en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).
                    </p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente al paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado al paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento del menor con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la entera y mutua satisfacción del paciente, del padre/madre/tutor legal, y de la persona que receta. El paciente, el padre/madre/tutor legal, y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                    riskCatergory: [
                        'Male Child',
                    ],
                }, {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title} no podré recibir lenalidomida. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).
                    </p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente al paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado al paciente si tiene preguntas relativas a su tratamiento con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la mutua satisfacción del paciente y de la persona que receta. Tanto el paciente como la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                    riskCatergory: [
                        'Adult Male',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hija no sigue todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title} no podrá recibir lenalidomida. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento será puesta en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).
                    </p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para las mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente y a su padre/madre/tutor legal si tiene preguntas relativas al tratamiento de la menor con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la entera y mutua satisfacción de la paciente, del padre/madre/tutor legal, y de la persona que receta. La paciente, el padre/madre/tutor legal, y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title} no podré recibir lenalidomida. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente si tiene preguntas relativas a su tratamiento con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la mutua satisfacción de la paciente y la persona que receta. Tanto la paciente como la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                    riskCatergory: [
                        'Adult Female of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hija no sigue todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title} no podrá recibir lenalidomida. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento será puesta en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).
                    </p>
                    <p class="bold">
                    Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para las mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento de la menor con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la mutua satisfacción de la paciente, del padre/madre/tutor legal, y la persona que receta. La paciente, el padre/madre/tutor legal, y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                    riskCatergory: [
                        'Female Child Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas a ${ProgramConfig('rems_program_lenalidomide').title}, no podré recibir lenalidomida. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de lenalidomida y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente si tiene preguntas relativas a su tratamiento con lenalidomida (lo que incluye medidas anticonceptivas adecuadas) y ha respondido tales preguntas a la mutua satisfacción de la paciente y la persona que receta. Tanto la paciente como la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                    riskCatergory: [
                        'Adult Female Not of Reproductive Potential',
                    ],
                },


            ],
            arrgement: {
                order: 1,
                content: `<span class="bold">Me gustaría recibir materiales educativos de ${ProgramConfig('rems_program_lenalidomide').title}. Envíe los materiales por correo
                a la dirección proporcionada en este Formulario de acuerdo entre paciente y médico.</span>`,
            },

            TermsAndConditon: `Le he explicado de forma completa al paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, 
            especialmente los posibles riesgos para mujeres con capacidad de concebir. He preguntado si el paciente tenía
            alguna pregunta con respecto al tratamiento con lenalidomida (lo que incluye métodos anticonceptivos adecuados) y 
            he respondido a esas preguntas en la medida de mis posibilidades. Cumpliré con todas mi obligaciones y
            responsabilidades como Prescriptor certificado en virtud de los programas ${ProgramConfig('rems_program_lenalidomide').title}.
            <span class="legalSymbol"></span>`,
        },
    },
};

export const PatientAgreementPol = {
    body: {
        formHeading: `${ProgramConfig('rems_program_pomalyst').title} Patient-Physician Agreement Form`,
        formHeading: `${ProgramConfig('rems_program_pomalyst').title} Patient-Physician Agreement Form`,
        fields: `<p> Fields marked with an <span style="color: #7b0305">*</span> are required. </p>`,
        bodynote: 'Please read the following statements carefully.',
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 91,
                content: `<p class="bold">
                Your healthcare provider has prescribed ${pomalidomide} for your child.* ${ProgramConfig('rems_program_pomalyst').label} is available only through a special program 
                called ${ProgramConfig('rems_program_pomalyst').label} Risk Evaluation and Mitigation Strategy (REMS). To receive 
                ${pomalidomide}, you must be enrolled in  ${ProgramConfig('rems_program_pomalyst').title} and agree to follow the requirements. 
                ${ProgramConfig('rems_program_pomalyst').title} covers:
                </p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p style="font-weight: bold">Your healthcare provider has prescribed ${pomalidomide} for you. ${ProgramConfig('rems_program_pomalyst').label} is available only through a special program 
                called ${ProgramConfig('rems_program_pomalyst').label} Risk Evaluation and Mitigation Strategy (REMS). To receive 
                ${pomalidomide}, you must be enrolled in  ${ProgramConfig('rems_program_pomalyst').title} and agree to follow the requirements. 
                ${ProgramConfig('rems_program_pomalyst').title} covers:</p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Adult Male',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">Generic ${pomalidomide}</span></li></ul>
                </span>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 2,
                content: `<p class="bold">If your child is pregnant or becomes pregnant while taking ${pomalidomide}, it is important to know that the unborn
                baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Female Child of Reproductive Potential'],
            },
            {
                order: 2,
                content: `<p class="bold">If you are pregnant or become pregnant while taking ${pomalidomide}, it is important for you to know that your   
                unborn baby can have severe birth defects or even die.</p>
                </span>`,
                riskCatergory: ['Adult Female of Reproductive Potential'],
            },
            {
                order: 2,
                content: `<p class="bold">
    Any unborn baby of a girl taking ${pomalidomide} can have severe birth defects or even die.</p>
    </p>`,
                riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 2,
                content: `<p class="bold">
    Any unborn baby of a female taking ${pomalidomide} can have severe birth defects or even die.</p>
    </p>`,
                riskCatergory: [
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 2,
                content: `<p style="font-weight: bold">If a female you have sex with is pregnant or becomes pregnant by you while you are taking ${pomalidomide}, it is  important for you to know that your unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Adult Male'],
            },
            {
                order: 2,
                content: `<p class="bold">If a female your child has sex with is pregnant or becomes pregnant by your child while he is taking
                ${pomalidomide}, it is important to know that the unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Male Child'],
            },
            {
                order: 3,
                content: `<p>Blood clots in your arteries (heart attacks and strokes), veins (deep vein thrombosis) and lungs (pulmonary embolism) can  happen if you take ${pomalidomide}. </p>`,
                riskCatergory: [
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Male Child',
                ],
            },
            {
                order: 4,
                content: `<p>For more information, please see the ${ProgramConfig('rems_program_pomalyst').label} Medication Guide.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 5,
                content: `<p class="bold">INSTRUCTIONS</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Before</span> <span style="font-weight: italic">starting your treatment with ${pomalidomide}, you will need to:</span></p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 96,
                content: `<p class="italic"><span class="bold">Before </span>your child starts treatment with <span style="font-style: italic">${pomalidomide}</span>, you will need to:</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: ` <p><span class="bold">1.</span> Complete sections 1 and 2 of this form and sign and date at the bottom of the form.
                </p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },

            {
                order: 8,
                content: `<p><span class="bold">2.</span> Read the ${ProgramConfig('rems_program_pomalyst').title} materials contained in the <span class="bold">Patient
                Resource Pack.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 9,
                content: `<p><span class="bold">3.</span> Keep a copy of this form for your records.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: `<p>For more information, visit <span class="bold">www.PomalidomideREMS.com,</span> or call the REMS Call Center
                at <span class="bold">1-888-423-5436.</span></p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential'
                   
                ],
            },
            {
                order: 97,
                content: ` <p>*Throughout this form, the word <span class="italic">child</span> includes any child of whom you are the parent or guardian.</p>`,

                riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: ` <p class="bold">Authorized Representatives:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: ` <p>If the authorized representative does not have the power of attorney, <span class="bold">a signed and dated
                letter from the prescriber, on the prescriber's letterhead, must be submitted to the REMS Call Center, along
                with the ${ProgramConfig('rems_program_pomalyst').title} Patient-Physician Agreement Form.</span> This letter must contain the
            following: a statement that the incompetent patient lacks the capacity to complete the ${ProgramConfig('rems_program_pomalyst').title} 
            Patient-Physician Agreement Form, including identification of the medical condition causing the incapacity; the
            name and address of the authorized representative; the authorized representative's relationship to the patient;
            and an opinion that the authorized representative accepts responsibility for the patient's compliance with
            ${ProgramConfig('rems_program_pomalyst').title} and is authorized to consent to treatment with ${pomalidomide} on behalf of the patient.</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },

            {
                order: 12,
                content: `<p>For more information, visit <span class="bold">${ProgramConfig('rems_program_pomalyst').link}</span>, or call the REMS Call Center
                at <span class="bold">1-888-423-5436.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Adult Female Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 97,
                content: ` <p>*Throughout this form, the word <span class="italic">child</span> includes any child of whom you are the parent or guardian.</p>`,

                riskCatergory: ['Male Child'],
            },
        ],
        patientArgeement: {
            note: 'Please read the following statements carefully. Mark the checkbox next to each statement.',
            header: `Section 1. Patient Agreement`,
            subtitle: `I understand and confirm that:`,
            arrgements: [],
        },
        authorization: {
            header: `Section 2. Authorization`,
            disclaimers: [
                {
                    order: 0,
                    content: `<style> .bold {
                        font-weight: bold;
                    }
                    .italic {
                        font-style: italic;
                    }
                    .legalSymbol {
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    }</style>`,
                    riskCatergory: [
                        'Male Child',
                        'Adult Male',
                        'Female Child of Reproductive Potential',
                        'Adult Female of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<span class="bold" >I understand that my information will be shared with the ${ProgramConfig('rems_program_pomalyst').title} Program.
                    ${ProgramConfig('rems_program_pomalyst').label} manufacturers may also use the information for business purposes, to the extent permitted by 
                    applicable law.
                    </span>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 110,
                    content: `<span class="bold" >I understand that my child’s information will be shared with the ${ProgramConfig('rems_program_pomalyst').title} Program.
                    ${ProgramConfig('rems_program_pomalyst').label} manufacturers may also use the information for business purposes, to the extent 
                    permitted by applicable law.
                    </span>`,
                    riskCatergory: ['Male Child', 
                                'Female Child of Reproductive Potential',
                                'Female Child Not of Reproductive Potential',
                    ],
                },
            ],
            arrgements: [
                {
                    order: 1,
                    content: `Upon signing this form, <b>I authorize my healthcare provider to begin my treatment with ${pomalidomide}</b>`,
                },
            ],
            body: ``,
            footer: `The ${ProgramConfig('rems_program_pomalyst').title} includes both POMALYST® (${pomalidomide}) and generic ${pomalidomide} products. The ${pomalidomide} manufacturers have a contractual agreement for administration of the ${ProgramConfig('rems_program_pomalyst').title}. All manufacturers retain responsibility for the actions described in the REMS.`,
        },
        authorizationTreatment: {
            header: `Section 3. Authorization to Start Treatment`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I
                    understand that if my child does not follow all of the instructions regarding ${ProgramConfig('rems_program_pomalyst').title}, he
                    will not be able to receive ${pomalidomide}. I also understand that the information we provide on this form and as
                    part of the surveys we will complete during treatment will be known by the manufacturers of ${pomalidomide} and the
                    Food and Drug Administration (FDA).</p>`,
                    riskCatergory: ['Male Child'],
                },
                {
                    order: 1,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I
                    understand that if my child does not follow all of the instructions regarding ${ProgramConfig('rems_program_pomalyst').title}, she
                    will not be able to receive ${pomalidomide}. I also understand that the information we provide on this form and as
                    part of the surveys we will complete during treatment will be known by the manufacturers of ${pomalidomide} and the
                    Food and Drug Administration (FDA).</p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ],
                },
                {
                    order: 110,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I
                    understand that if I do not follow all of the instructions regarding ${ProgramConfig('rems_program_pomalyst').title}, I will not be
                    able to receive ${pomalidomide}. I also understand that the information I provide on this form and as part of the
                    surveys I will complete during treatment will be known by the manufacturers of ${pomalidomide} and the Food and Drug
                    Administration (FDA).
                    </p>`,
                    riskCatergory: ['Adult Male', 'Adult Female Not of Reproductive Potential', 'Adult Female of Reproductive Potential'],
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Male'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the
                    treatment described above, especially the potential risks to females who can get pregnant. The
                    prescriber has asked the patient if he has any questions regarding his treatment with ${pomalidomide} 
                    (including appropriate birth control methods) and has answered those questions to the patient's and
                    prescriber's mutual satisfaction. Both patient and prescriber certify that they will comply with all of
                    their obligations and responsibilities as described under ${ProgramConfig('rems_program_pomalyst').title}.
                    </p>`,
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Female of Reproductive Potential'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the
                    treatment described above, especially the potential risks to females who can get pregnant. The
                    prescriber has asked the patient if she has any questions regarding her treatment with ${pomalidomide},
                    including the use of two methods of effective birth control (at least one highly effective method and
                    one effective method) at the same time, and has answered those questions to the patient's and
                    prescriber's mutual satisfaction. Both patient and prescriber certify that they will comply with all of
                    their obligations and responsibilities as described under ${ProgramConfig('rems_program_pomalyst').title}.
                    </p>`,
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Female Not of Reproductive Potential'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the
                    treatment described above, especially the potential risks to females who can get pregnant. The
                    prescriber has asked the patient if she has any questions regarding her treatment with ${pomalidomide}
                    and has answered those questions to the patient's and prescriber's mutual satisfaction. Both patient
                    and prescriber certify that they will comply with all of their obligations and responsibilities as
                    described under ${ProgramConfig('rems_program_pomalyst').title}.</p>`,
                },
                {
                    order: 21,
                    riskCatergory: ['Male Child'],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and his parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                     can get pregnant. The prescriber has asked the patient and his parent/guardian if they have any
                    questions regarding the child's treatment with ${pomalidomide} (including appropriate birth control
                    methods) and has answered those questions to the patient's, parent/guardian's, and prescriber's
                    mutual satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with
                    all of their obligations and responsibilities as described under ${ProgramConfig('rems_program_pomalyst').title}.
                    </p>`,
                },
                {
                    order: 21,
                    riskCatergory: ['Female Child of Reproductive Potential'],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                    can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any
                    questions regarding the child's treatment with ${pomalidomide}, including the use of two methods of
                    effective birth control (at least one highly effective method and one effective method) at the same
                    time, and has answered those questions to the patient's, parent/guardian's, and prescriber's mutual
                    satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with all of
                    their obligations and responsibilities as described under ${ProgramConfig('rems_program_pomalyst').title}.
                    </p>`,
                },
                {
                    order: 21,
                    riskCatergory: ['Female Child Not of Reproductive Potential'],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                    can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any
                    questions regarding the child's treatment with ${pomalidomide} and has answered those questions to the
                    patient's, parent/guardian's, and prescriber's mutual satisfaction. The patient, parent/guardian, and
                    prescriber certify that they will comply with all of their obligations and responsibilities as described
                    under ${ProgramConfig('rems_program_pomalyst').title}.
                    </p>`,
                },
            ],
            arrgement: {
                order: 1,
                content: `<span class="bold">I would like to receive ${ProgramConfig('rems_program_pomalyst').title}&nbsp;educational materials. Please mail materials to the address provided on this Patient-Physician Agreement Form.</span>`,
            },
            TermsAndConditon: `I have fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the 
            potential risks to females of reproductive potential. I have asked if the patient has any questions regarding the 
            treatment with ${pomalidomide} (including appropriate birth control methods) and have answered those questions to the 
            best of my ability. I will comply with all of my obligations and responsibilities as a prescriber certified under 
            ${ProgramConfig('rems_program_pomalyst').title}.`,
        },
    },
};

export const PatientAgreementLn = {
    body: {
        formHeading: `${ProgramConfig('rems_program_lenalidomide').title} Patient-Physician Agreement Form`,
        fields: `<p> Fields marked with an <span style="color: #7b0305">*</span> are required. </p>`,
        bodynote: 'Please read the following statements carefully.',
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Your healthcare provider has prescribed ${lenalidomide} for you. ${ProgramConfig('rems_program_lenalidomide').label} is available only through a 
                special program called ${ProgramConfig('rems_program_lenalidomide').title} (Risk Evaluation and Mitigation Strategy). To receive 
                ${lenalidomide}, you must be enrolled in ${ProgramConfig('rems_program_lenalidomide').title} and agree to follow the requirements.
                ${ProgramConfig('rems_program_lenalidomide').title} covers:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 91,
                content: `<p class="bold">Your healthcare provider has prescribed ${lenalidomide} for your child.* ${ProgramConfig('rems_program_lenalidomide').label} is available only through a special program called ${ProgramConfig('rems_program_lenalidomide').title} (Risk Evaluation and Mitigation Strategy). To  receive ${lenalidomide}, you must be enrolled in ${ProgramConfig('rems_program_lenalidomide').title} and agree to follow the requirements.</br>${ProgramConfig('rems_program_lenalidomide').title} covers:</br></p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            // {
            //     order: 92,
            //     content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomide)</li><li style="padding-left: 0px;"><span class="bold">Generic ${lenalidomide}</span></li></ul>
            //     <p class="bold">If a female you have sex with is pregnant or becomes pregnant by you while you are taking ${lenalidomide}, </br>
            //     it is important for you to know that your unborn baby can have severe birth defects or even die.</p>
            //     </span>`,
            //     riskCatergory: ['Male Child'],
            // },
            {
                order: 2,
                content: `<p class="bold">If a female your child has sex with is pregnant or becomes pregnant by your child while he is taking
                ${lenalidomide}, it is important to know that the unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Male Child'],
            },
            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomide)</li><li style="padding-left: 0px;"><span class="bold">Generic ${lenalidomide}</span></li></ul>
                <p class="bold">If a female you have sex with is pregnant or becomes pregnant by you while you are taking ${lenalidomide}, 
                it is important for you to know that your unborn baby can have severe birth defects or even die.</p>
                </span>`,
                riskCatergory: ['Adult Male'],
            },
            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomide)</li><li style="padding-left: 0px;"><span class="bold">Generic ${lenalidomide}</span></li></ul>
                <p class="bold">If you are pregnant or become pregnant while taking ${lenalidomide}, it is important for you to know that
                your unborn baby can have severe birth defects or even die.</p>
                </span>`,
                riskCatergory: ['Adult Female of Reproductive Potential'],
            },
            // Any unborn baby of a girl taking ${lenalidomide} can have severe birth defects or even die.
            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomide)</li><li style="padding-left: 0px;"><span class="bold">Generic ${lenalidomide}</span></li></ul>
                <p class="bold">If your child is pregnant or becomes pregnant while taking ${lenalidomide}, it is important to know that the
                unborn baby can have severe birth defects or even die.</p>
                </span>`,
                riskCatergory: ['Female Child of Reproductive Potential'],
            },

            {
                order: 2,
                content: `<span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">REVLIMID<span class="legalSymbol">®</span> (lenalidomide)</li><li style="padding-left: 0px;"><span class="bold">Generic ${lenalidomide}</span></li></ul>
    Any unborn baby of a girl taking ${lenalidomide} can have severe birth defects or even die.</p>
    </span>`,
                riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p>${ProgramConfig('rems_program_lenalidomide').label} causes a higher chance for blood clots in your veins (deep vein thrombosis) and lungs (pulmonary 
                    embolism). </p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 4,
                content: ` <p>For more information, please see the ${ProgramConfig('rems_program_lenalidomide').label} Medication Guide.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },

            {
                order: 5,
                content: ` <p class="bold">INSTRUCTIONS</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Before</span> starting your treatment with ${lenalidomide}, you will need to:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 96,
                content: `<p class="italic"><span class="bold">Before </span>your child starts treatment with ${lenalidomide}, you will need to:</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: ` <p><span class="bold">1.</span> Complete sections 1 and 2 of this form and sign and date at the bottom of the form.
                </p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 8,
                content: ` <p><span class="bold">2.</span> Read the ${ProgramConfig('rems_program_lenalidomide').title} materials contained in the <span class="bold">Patient
                Resource Pack.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 9,
                content: ` <p><span class="bold">3.</span> Keep a copy of this form for your records.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: `<p class="bold">Authorized Representatives:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: `<p>If the authorized representative does not have the power of attorney, <span class="bold">a signed and dated
                letter from the prescriber, on the prescriber's letterhead, must be submitted to ${ProgramConfig('rems_program_lenalidomide').title}, along
                with the ${ProgramConfig('rems_program_lenalidomide').title} Patient-Physician Agreement Form.</span> This letter must contain the following:
            a statement that the incompetent patient lacks the capacity to complete the ${ProgramConfig('rems_program_lenalidomide').title} Patient-Physician
            Agreement Form, including identification of the medical condition causing the incapacity; the name and address
            of the authorized representative; the authorized representative's relationship to the patient; and an opinion
            that the authorized representative accepts responsibility for the patient's compliance with ${ProgramConfig('rems_program_lenalidomide').title}
            and is authorized to consent to treatment with ${lenalidomide} on behalf of the patient.</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: ` <p>For more information, visit <span class="bold">www.LenalidomideREMS.com</span>, or call ${ProgramConfig('rems_program_lenalidomide').title} at
                <span class="bold">1-888-423-5436.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 97,
                content: ` <p>*Throughout this form, the word <span class="italic">child</span> includes any child of whom you are the parent or guardian.</p>`,

                riskCatergory: [
                    'Male Child',
                    'Female Child Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                ],
            },
        ],
        patientArgeement: {
            note: 'Please read the following statements carefully. Mark the checkbox next to each statement.',
            header: `Section 1. Patient Agreement`,
            subtitle: `I understand and confirm that:`,
            arrgements: [],
        },
        authorization: {
            header: `Section 2. Authorization`,
            disclaimers: [
                {
                    order: 0,
                    content: `<style> .bold {
                        font-weight: bold;
                    }
                    .italic {
                        font-style: italic;
                    }
                    .legalSymbol {
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    }</style>`,
                    riskCatergory: [
                        'Male Child',
                        'Adult Male',
                        'Female Child of Reproductive Potential',
                        'Adult Female of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<span class="bold" >I understand that my information will be shared with the  ${ProgramConfig('rems_program_lenalidomide').title}&nbsp;Program. ${ProgramConfig('rems_program_lenalidomide').label} 
                    manufacturers may also use the information for business purposes, to the extent permitted by 
                    applicable law.
                    </span>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 110,
                    content: `<span class="bold" >I understand that my child's information will be shared with the ${ProgramConfig('rems_program_lenalidomide').title} Program. ${ProgramConfig('rems_program_lenalidomide').label} manufacturers may also use the information for business purposes, to the extent
                    permitted by applicable law.
                    </span>`,
                    riskCatergory: [
                        'Male Child',
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ],
                },
            ],
            arrgements: [
                {
                    order: 1,
                    content: `Upon signing this form, <b>I authorize my healthcare provider to begin my treatment with ${lenalidomide}</b>`,
                },
            ],
            body: ``,
            footer: `The ${ProgramConfig('rems_program_lenalidomide').title} includes both REVLIMID${registerIcon} (lenalidomide) and generic ${lenalidomide} products. The 
            ${lenalidomide} manufacturers have a contractual agreement for administration of the ${ProgramConfig('rems_program_lenalidomide').title}. All 
            manufacturers retain responsibility for the actions described in the REMS.`,
        },
        authorizationTreatment: {
            header: `Section 3. Authorization to Start Treatment`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I 
                   understand that if I do not follow all of the instructions regarding ${ProgramConfig('rems_program_lenalidomide').title}, I will not be able to receive 
                   ${lenalidomide}. I also understand that the information I provide on this form and as part of the surveys I will 
                   complete during treatment will be known by the manufacturers of ${lenalidomide} and the Food and Drug 
                   Administration (FDA). </p>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 110,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I
                    understand that if my child does not follow all of the instructions regarding ${ProgramConfig('rems_program_lenalidomide').title}, he will not be
                    able to receive ${lenalidomide}. I also understand that the information we provide on this form and as part of the
                    surveys we will complete during treatment will be known by the manufacturers of ${lenalidomide} and the Food and
                    Drug Administration (FDA).
                     </p>`,
                    riskCatergory: ['Male Child'],
                },
                {
                    order: 110,
                    content: `<p>I have read the information on this form or it has been read aloud to me in the language of my choice. I
                    understand that if my child does not follow all of the instructions regarding ${ProgramConfig('rems_program_lenalidomide').title}, she will not be
                    able to receive ${lenalidomide}. I also understand that the information we provide on this form and as part of the
                    surveys we will complete during treatment will be known by the manufacturers of ${lenalidomide} and the Food and
                    Drug Administration (FDA).
                     </p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ],
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Male'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the 
                    treatment described above, especially the potential risks to females who can get pregnant. The 
                    prescriber has asked the patient if he has any questions regarding his treatment 
                    with ${lenalidomide} (including appropriate birth control methods) and has answered those questions to the patient's and 
                    prescriber's mutual satisfaction. Both patient and prescriber certify that they will comply with all of 
                    their obligations and responsibilities as described under ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Female of Reproductive Potential'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the
                    treatment described above, especially the potential risks to females who can get pregnant. The
                    prescriber has asked the patient if she has any questions regarding her treatment with ${lenalidomide},
                    including the use of two methods of effective birth control (at least one highly effective method and
                        one effective method) at the same time, and has answered those questions to the patient's and
                        prescriber's mutual satisfaction. Both patient and prescriber certify that they will comply with all of
                        their obligations and responsibilities as described under ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                },
                {
                    order: 2,
                    riskCatergory: ['Adult Female Not of Reproductive Potential'],
                    content: `<p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the
                    treatment described above, especially the potential risks to females who can get pregnant. The
                    prescriber has asked the patient if she has any questions regarding her treatment with ${lenalidomide}
                    and has answered those questions to the patient's and prescriber's mutual satisfaction. Both patient
                    and prescriber certify that they will comply with all of their obligations and responsibilities as
                    described under ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                },
                {
                    order: 21,
                    riskCatergory: ['Female Child of Reproductive Potential'],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                    can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any
                    questions regarding the child's treatment with ${lenalidomide}, including the use of two methods of
                    effective birth control (at least one highly effective method and one effective method) at the same
                    time, and has answered those questions to the patient's, parent/guardian's, and prescriber's mutual
                    satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with all of
                    their obligations and responsibilities as described under ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                },
                {
                    order: 21,
                    riskCatergory: [
                        'Male Child',
                    ],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and his parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                    can get pregnant. The prescriber has asked the patient and his parent/guardian if they have any
                    questions regarding the child's treatment with ${lenalidomide} (including appropriate birth control
                    methods) and has answered those questions to the patient's, parent/guardian's, and prescriber's
                    mutual satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with
                    all of their obligations and responsibilities as described under ${ProgramConfig('rems_program_lenalidomide').title}.
                    </p>`,
                },
                {
                    order: 21,
                    riskCatergory: [
                        'Female Child Not of Reproductive Potential',
                    ],
                    content: `<p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature,
                    purpose, and risks of the treatment described above, especially the potential risks to females who
                    can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any
                    questions regarding the child's treatment with ${lenalidomide} and has answered those questions to the
                    patient's, parent/guardian's, and prescriber's mutual satisfaction. The patient, parent/guardian, and
                    prescriber certify that they will comply with all of their obligations and responsibilities as described
                    under ${ProgramConfig('rems_program_lenalidomide').title}.</p>`,
                },
            ],
            arrgement: {
                order: 1,
                content: `<span class="bold">I would like to receive ${ProgramConfig('rems_program_lenalidomide').title} educational materials. Please mail materials to the
                 address provided on this Patient-Physician Agreement Form.</span>`,
            },

            TermsAndConditon: `I have fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the 
            potential risks to females of reproductive potential. I have asked if the patient has any questions regarding the 
            treatment with ${lenalidomide} (including appropriate birth control methods) and have answered those questions to the 
            best of my ability. I will comply with all of my obligations and responsibilities as a prescriber certified under 
            ${ProgramConfig('rems_program_lenalidomide').title}.`,
        },
    },
};
export const PatientAgreementTh = {
    
    body: {
        formHeading: `${ProgramConfig('rems_program_thalomid').title}${registerIcon} Patient-Physician Agreement Form`,
        fields: `<p> Fields marked with an <span style="color: #7b0305">*</span> are required. </p>`,
        bodynote: 'Please read the following statements carefully.',
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Your healthcare provider has prescribed ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> for you. ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} is available only through a 
                restricted distribution program called ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Before 
                taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, you must read and agree to all of the instructions in the ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span> program.</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p class="bold">Your healthcare provider has prescribed ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> for your child.* ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} is available only through a 
                restricted distribution program called ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Before 
                taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, patients must read and agree to all of the instructions in the ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span> program.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 2,
                content: `<p class="bold">If a female you have sex with is pregnant or becomes pregnant by you while you are taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, it is important for you to know that your unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Adult Male'],
            },
            {
                order: 2,
                content: `<p class="bold">If a female your child has sex with is pregnant or becomes pregnant by your child while he is taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, it is important to know that the unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Male Child'],
            },
            {
                order: 2,
                content: `<p class="bold">Any unborn baby of a girl taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} can have severe birth defects or even die.</p>`,
                riskCatergory: ['Female Child Not of Reproductive Potential'],
            },
            {
                order: 2,
                content: `<p class="bold">If your child is pregnant or becomes pregnant while taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, it is important to know that the unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Female Child of Reproductive Potential'],
            },
            {
                order: 2,
                content: `<p class="bold">If you are pregnant or become pregnant while taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, it is important for you to know that your unborn baby can have severe birth defects or even die.</p>`,
                riskCatergory: ['Adult Female of Reproductive Potential'],
            },
            {
                order: 2,
                content: `<p class="bold">Any unborn baby of a female taking ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} can have severe birth defects or even die.</p>`,
                riskCatergory: ['Adult Female Not of Reproductive Potential'],
            },
            {
                order: 3,
                content: `<p>${ProgramConfig('rems_program_thalomid').label.toUpperCase()} causes a higher chance for blood clots in your veins (deep vein thrombosis) and lungs (pulmonary embolism). </p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',                   
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 3,
                content: `<p>${ProgramConfig('rems_program_thalomid').label.toUpperCase()} causes a higher chance for blood clots in your veins (deep vein thrombosis) and lungs (pulmonary embolism).</p>`,
                riskCatergory: ['Adult Female of Reproductive Potential'],
            },
            {
                order: 4,
                content: `<p>For more information, please see the ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Medication Guide.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 5,
                content: `<p class="bold">INSTRUCTIONS</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Before</span> starting your treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, you will need to:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Before</span> your child starts treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, you will need to:</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: `<p><span class="bold">1.</span> Complete sections 1 and 2 of this form and sign and date at the bottom of the form.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 8,
                content: `<p><span class="bold">2.</span> Read the ${ProgramConfig('rems_program_thalomid').title} materials contained in the <span class="bold">Patient Resource Pack.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 9,
                content: `<p><span class="bold">3.</span> Keep a copy of this form for your records.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: `<p class="bold">Authorized Representatives:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: `<p>If the authorized representative does not have the power of attorney, <span class="bold">a signed and dated letter from the prescriber, 
                on the prescriber's letterhead, must be submitted to the REMS Call Center, along with the ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} 
                (thalidomide) Patient-Physician Agreement Form.</span> This letter must contain the following: a statement that the 
                incompetent patient lacks the capacity to complete the ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (thalidomide) Patient-Physician Agreement Form, 
                including identification of the medical condition causing the incapacity; the name and address of the authorized 
                representative; the authorized representative's relationship to the patient; and an opinion that the authorized 
                representative accepts responsibility for the patient's compliance with the ${ProgramConfig('rems_program_thalomid').title} program and is authorized to 
                consent to treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} on behalf of the patient.</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: `<p>For more information, visit <span class="bold">www.BMSREMSPatientSafety.com</span>, or call the REMS Call Center at <span class="bold">1-888-423-5436.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 13,
                content: `<p>*Throughout this form, the word <span class="italic">child</span> includes any child of whom you are the parent or guardian.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
        ],
        patientArgeement: {
            note: 'Please read the following statements carefully. Mark the checkbox next to each statement.',
            header: `Section 1. Patient Agreement`,
            subtitle: `I understand and confirm that:`,
            arrgements: [],
        },
        authorization: {
            header: `Section 2. Authorization`,
            disclaimers: [
                {
                    order: 1,
                    content: `<span class="bold" >I understand that my information will be shared with Bristol Myers Squibb for the 
                ${ProgramConfig('rems_program_thalomid').title}${registerIcon} Program. Bristol Myers Squibb may also use the information for business purposes, to the extent 
                permitted by applicable law.
                </span>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<span class="bold" >I understand that my child’s information will be shared with Bristol Myers Squibb for the ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span> Program.
                    Bristol Myers Squibb may also use the information for business purposes, to the extent permitted by applicable law.
                </span>`,
                    riskCatergory: [
                        'Male Child',
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ],
                },
            ],
            arrgements: [
                {
                    order: 1,
                    content: `Upon signing this form, <b>I authorize my healthcare provider to begin my treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}</b>`,
                },
            ],
            body: ``,
            footer: `${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and ${ProgramConfig('rems_program_thalomid').title} are registered trademarks of Celgene Corporation, a Bristol-Myers Squibb company.`,
        },
        authorizationTreatment: {
            header: `Section 3. Authorization to Start Treatment`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>
                    I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if my child does not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program, he will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon}. I also understand that the information we provide on this form and as part of the surveys we will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).</p>
                    <p class="bold">
                    I agree that the prescriber has fully explained to the patient and his parent/guardian the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient and his parent/guardian if they have any questions regarding the child’s treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (including appropriate birth control methods) and has answered those questions to the patient’s, parent/guardian’s, and prescriber’s mutual satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program.</p>`,
                    riskCatergory: ['Male Child'],
                },
                {
                    order: 1,
                    content: `<p>
                    I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if my child does not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program, she will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon}. I also understand that the information we provide on this form and as part of the surveys we will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).
                    </p>
                    <p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any questions regarding the child’s treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, including the use of two methods of effective birth control (at least one highly effective method and one effective method) at the same time, and has answered those questions to the patient’s, parent/guardian’s, and prescriber’s mutual satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program.
                    </p>`,
                    riskCatergory: ['Female Child of Reproductive Potential'],
                },
                {
                    order: 1,
                    content: `<p>
                    I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if my child does not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span> program, she will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} . I also understand that the information we provide on this form and as part of the surveys we will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).</p>
                    <p class="bold">
                    I agree that the prescriber has fully explained to the patient and her parent/guardian the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient and her parent/guardian if they have any questions regarding the child’s treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and has answered those questions to the patient’s, parent/guardian’s, and prescriber’s mutual satisfaction. The patient, parent/guardian, and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program.
                    </p>`,
                    riskCatergory: ['Female Child Not of Reproductive Potential'],
                },
                {
                    order: 1,
                    content: `
                    <p>I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if I do not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program, I will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon}. I also understand that the information I provide on this form and as part of the surveys I will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).</p>
                    <p class="bold"> I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient if she has any questions regarding her treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and has answered those questions to the patient’s and prescriber’s mutual satisfaction. Both patient and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program.
                    </p>`,
                    riskCatergory: ['Adult Female Not of Reproductive Potential'],
                },
                {
                    order: 1,
                    content: `
                    <p>I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if I do not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program, I will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon}. I also understand that the information I provide on this form and as part of the surveys I will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).</p>
                    <p class="bold">I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient if he has any questions regarding his treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (including appropriate birth control methods) and has answered those questions to the patient’s and prescriber’s mutual satisfaction. Both patient and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program. </p>`,
                    riskCatergory: ['Adult Male'],
                },
                {
                    order: 1,
                    content: `
                    <p>I have read the information on this form or it has been read aloud to me in the language of my choice. I understand that if I do not follow all of the instructions regarding the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program, I will not be able to receive ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} . I also understand that the information I provide on this form and as part of the surveys I will complete during treatment will be known by the manufacturer of ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} and the Food and Drug Administration (FDA).</p>
                    <p class="bold"> I agree that the prescriber has fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the potential risks to females who can get pregnant. The prescriber has asked the patient if she has any questions regarding her treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, including the use of two methods of effective birth control (at least one highly effective method and one effective method) at the same time, and has answered those questions to the patient’s and prescriber’s mutual satisfaction. Both patient and prescriber certify that they will comply with all of their obligations and responsibilities as described under the ${ProgramConfig('rems_program_thalomid').title} program.</p>`,
                    riskCatergory: ['Adult Female of Reproductive Potential'],
                },
            ],
            arrgement: {
                order: 1,
                content: `<span class="bold"> I would like to receive ${ProgramConfig('rems_program_thalomid').title}${registerIcon} educational materials. Please mail materials to the
                address provided on this Patient-Physician Agreement Form.</span>`,
            },

            TermsAndConditon: `I have fully explained to the patient the nature, purpose, and risks of the treatment described above, especially the 
            potential risks to females of reproductive potential. I have asked if the patient has any questions regarding the 
            treatment with ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (including appropriate birth control methods) and have answered those questions to the 
            best of my ability. I will comply with all of my obligations and responsibilities as a prescriber certified under 
            ${ProgramConfig('rems_program_thalomid').title}${registerIcon}</span>.`,
        },
    },
};

export const PatientAgreementPol_Spanish = {
    body: {
        formHeading: `Formulario de acuerdo paciente-médico del programa ${ProgramConfig('rems_program_pomalyst').title}`,
        fields: `<p>Los campos marcados con un<span style="color: #7b0305">*</span> son obligatorios. </p>`,
        bodynote: 'Lea con atención las siguientes afirmaciones.',
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide} a su hijo*. La ${pomalidomide} solo está disponible a través de un programa especial llamado ${ProgramConfig('rems_program_pomalyst').title} (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">Si una mujer con la que su hijo mantiene relaciones sexuales está embarazada o queda embarazada 
                de su hijo mientras él está tomando ${pomalidomide}, es importante que sepa que el feto puede sufrir 
                defectos de nacimiento graves o incluso morir.</p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas 
                (trombosis venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [
                    'Male Child',
                ],
            }, 
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide} a su hija*. La ${pomalidomide} solo está disponible a través de un programa especial llamado ${ProgramConfig('rems_program_pomalyst').title} (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">Si su hija está embarazada o queda embarazada mientras esté tomando ${pomalidomide}, es importante que 
                sepa que el feto puede sufrir defectos de nacimiento graves o incluso morir.</p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas (trombosis 
                    venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [                    
                    'Female Child of Reproductive Potential', 
                ],
            },
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide} a su hija*. La ${pomalidomide} solo está disponible a través de un programa especial llamado ${ProgramConfig('rems_program_pomalyst').title} (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">El feto de una joven que esté tomando ${pomalidomide} puede tener defectos de nacimiento graves o incluso morir.</p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas (trombosis 
                    venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                ],
            }, 
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide}. La ${pomalidomide} solo está disponible a través de un programa especial llamado ${ProgramConfig('rems_program_pomalyst').title} (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} de la cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">Si una mujer con la que mantiene relaciones sexuales está embarazada o queda embarazada de usted 
                mientras usted está tomando ${pomalidomide}, es importante que sepa que el feto puede sufrir defectos de nacimiento graves o incluso morir.</p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas (trombosis 
                venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [                   
                    'Adult Male',                                    
                ],
            }, 
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide}. La ${pomalidomide} solo está disponible a través de un programa especial llamado ${ProgramConfig('rems_program_pomalyst').title} (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">Si está embarazada o queda embarazada mientras está tomando ${pomalidomide}, es importante  que sepa que el 
                feto puede sufrir defectos de nacimiento graves o incluso morir.</p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas (trombosis 
                    venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [                   
                    'Adult Female of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${pomalidomide}. La ${pomalidomide} solo está disponible a través de un programa especial llamado REMS (Estrategia de evaluación y mitigación de riesgos). Para recibir ${pomalidomide}, debe inscribirse en el programa ${ProgramConfig('rems_program_pomalyst').title} y aceptar seguir los requisitos. El programa ${ProgramConfig('rems_program_pomalyst').title} cubre:</p><span class="bold"><ul style="padding-left: 50px;"><li style="padding-left: 0px;">POMALYST<span class="legalSymbol">®</span> (${pomalidomide})</li><li style="padding-left: 0px;"><span class="bold">${ProgramConfig('rems_program_pomalyst').label} genérica</span></li></ul>
                </span>
                <p style="font-weight: bold">El feto de una mujer que esté tomando ${pomalidomide} puede tener defectos de nacimiento graves o incluso 
                morir. </p>
                <p>Pueden formarse coágulos de sangre en sus arterias (ataques cardíacos y accidentes cerebrovasculares), venas (trombosis 
                    venosa profunda) y pulmones (embolia pulmonar) si toma ${pomalidomide}.</p>`,
                riskCatergory: [                   
                    'Adult Female Not of Reproductive Potential',                   
                ],
            },
            {
                order: 1,
                content: `<p style="font-weight: bold"> Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_pomalyst').label} a usted. ${ProgramConfig('rems_program_pomalyst').label} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_pomalyst').label} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_pomalyst').label}, debe leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_pomalyst').title}.</p>
                <p style="font-weight: bold">El bebé en gestación de una mujer que toma ${ProgramConfig('rems_program_pomalyst').label} puede sufrir defectos de nacimiento graves incluso morir.</p>
                <p>Si toma ${ProgramConfig('rems_program_pomalyst').label}, se puede producir coágulos de sangre en las arterias (ataques cardiacos y accidentes cerebrovasculares), las venas (trombosis venosa profunda) y los pulmones (embolia pulmonar).</p>`,
                riskCatergory: [],
            },
            {
                order: 4,
                content: `<p>Si desea obtener más información, consulte la Guía del medicamento de la ${pomalidomide}.</p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Male',
                    'Male Child',
                ],
            },
            {
                order: 5,
                content: `<p class="bold">INSTRUCCIONES</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> que su hijo inicie su tratamiento con ${pomalidomide}, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [
                    'Male Child',                  
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> que su hija inicie su tratamiento con ${pomalidomide}, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [                    
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential', 
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> iniciar su tratamiento con ${ProgramConfig('rems_program_pomalyst').label}, es necesario que haga lo siguiente:</p>`,
                riskCatergory: [                    
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> iniciar su tratamiento con ${pomalidomide}, es necesario que haga lo siguiente:</p>`,
                riskCatergory: [                    
                    'Adult Female of Reproductive Potential', 
                    'Adult Female Not of Reproductive Potential', 
                    'Adult Male',                                                     
                ],
            },
            {
                order: 7,
                content: ` <p><span class="bold">1.</span> Llene las secciones 1 y 2 de este formulario y coloque su firma y la fecha en la página 6.
                </p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: ` <p><span class="bold">1.</span> Llene las secciones 1 y 2 de este formulario y coloque su firma y la fecha en la página 5.
                </p>`,
                riskCatergory: [
                    'Adult Female Not of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Male',
                    'Male Child',
                ],
            },
            {
                order: 8,
                content: `<p><span class="bold">2.</span> Lea los materiales del programa ${ProgramConfig('rems_program_pomalyst').title} contenidos en el <span class="bold"> Paquete de recursos para el paciente. </span></p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Male Child',
                ],
            },
            {
                order: 8,
                content: `<p><span class="bold">2.</span> Lea los materiales del programa ${ProgramConfig('rems_program_pomalyst').title} de la contenidos en el <span class="bold"> Paquete de recursos para el paciente. </span></p>`,
                riskCatergory: [
                    'Adult Male',              
                ],
            },
            {
                order: 9,
                content: `<p><span class="bold">3. </span>Conserve una copia de este formulario para sus registros.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: ` <p class="bold">Representantes autorizados:</p>`,
                riskCatergory: [                   
                    'Adult Male',                   
                    'Adult Female of Reproductive Potential',                   
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: ` <p>Si el representante autorizado no tiene un poder notarial, <b>se debe presentar una carta firmada y fechada por el profesional que receta el medicamento, con el membrete de este, en el Centro de Llamadas de REMS, junto con el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_pomalyst').label} (pomalidomida).</b> Esta carta debe incluir: una declaración que explique que el paciente incapaz no tiene capacidad para completar el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_pomalyst').label} (pomalidomida), incluida una identificación de la afección médica que causa la incapacidad; el nombre y la dirección del representante autorizado; la relación del representante autorizado con el paciente; y un enunciado que indique que el representante autorizado se hace responsable que el paciente cumpla con el programa ${ProgramConfig('rems_program_pomalyst').title} y que está autorizado para dar su consentimiento para que se realice el tratamiento con ${ProgramConfig('rems_program_pomalyst').label} en nombre del paciente.</p>`,
                riskCatergory: [                    
                ],
            },
            {
                order: 11,
                content: ` <p>Si el representante autorizado no cuenta con el poder notarial, <span class="bold">se debe enviar al centro de atención del programa REMS una 
                carta firmada y fechada del médico que receta, con membrete de este, junto con el Formulario de acuerdo paciente-médico 
                del programa ${ProgramConfig('rems_program_pomalyst').title}.</span> La carta debe contener lo siguiente: una declaración que afirme que el paciente 
                incompetente carece de la capacidad para completar el Formulario de acuerdo paciente-médico del programa ${ProgramConfig('rems_program_pomalyst').title}, que incluya la afección médica que causa tal incapacidad; el nombre y la dirección del representante autorizado; la relación 
                que existe entre el representante autorizado y el paciente; y una declaración que manifieste que el representante autorizado asume 
                la responsabilidad del cumplimiento del programa ${ProgramConfig('rems_program_pomalyst').title} por parte del paciente y que está autorizado a dar su 
                consentimiento para el tratamiento con ${pomalidomide} en nombre del paciente.</p>`,
                riskCatergory: [                    
                    'Adult Female of Reproductive Potential',                   
                    'Adult Female Not of Reproductive Potential',
                    'Adult Male',                    
                ],
            },
            {
                order: 12,
                content: `<p>Para obtener más información, visite <span class="bold"> www.BMSREMSPatientSafety.com, </span>
                o llame al Centro de Llamadas de REMS al <span class="bold">1-</span><span class="bold">888-423-5436.</span></p>`,
                riskCatergory: [
                ],
            },
            {
                order: 12,
                content: `<p>Para obtener más información, visite <span class="bold"> ${ProgramConfig('rems_program_pomalyst').link}, </span>o llame al centro de atención del programa REMS al <span class="bold">1-</span><span class="bold">888-423-5436.</span></p>`,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Male',
                    'Male Child',  
                ],
            },
            {
                order: 13,
                content: `<p><b>*</b> A lo largo de este formulario, la palabra <i>hijo</i> incluye a cualquier menor del que usted sea el padre/la madre o el tutor legal.</p>`,
                riskCatergory: [
                    'Male Child',                   
                ],
            },
            {
                order: 13,
                content: `<p><b>*</b>A lo largo de este formulario, la palabra <i>hija</i> incluye a cualquier menor del que usted sea padre/madre o tutor legal.</p>`,
                riskCatergory: [
                    'Female Child of Reproductive Potential', 
                    'Female Child Not of Reproductive Potential',                                    
                ],
            },
        ],
        patientArgeement: {
            note: `Lea con atención las siguientes afirmaciones. Marque la casilla (con una “X”) si está de acuerdo con la afirmación correspondiente. 
            No ponga una marca ni escriba fuera de las áreas designadas.`,
            header: `Sección 1. Acuerdo del paciente`,
            subtitle: `Entiendo y confirmo que:`,
            arrgements: [],
        },
        authorization: {
            header: `Sección 2. Autorización`,
            disclaimers: [
                {
                    order: 0,
                    content: `<style> .bold {
                        font-weight: bold;
                    }
                    .italic {
                        font-style: italic;
                    }
                    .legalSymbol {
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    }</style>`,
                    riskCatergory: [
                        'Male Child',
                        'Adult Male',
                        'Female Child of Reproductive Potential',
                        'Adult Female of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p class="bold" >Entiendo que la información de mi hija será compartida con Bristol Myers Squibb para el Programa ${ProgramConfig('rems_program_pomalyst').label}. Bristol Myers Squibb también puede utilizar la información para propositos de negocios, en la medida en que lo permita la ley aplicable.
                    </p>`,
                    riskCatergory: [                        
                    ],
                },
                {
                    order: 1,
                    content: `<p class="bold" >Entiendo que mi información se compartirá con el programa REMS de la pomalidomida. 
                    <br>Los fabricantes de pomalidomida también pueden utilizar la información con fines comerciales, en la medida 
                    permitida por</p>`,
                    riskCatergory: [
                        'Adult Male',                        
                    ],
                },
                {
                    order: 1,
                    content: `<p> Entiendo que mi información se compartirá con el programa ${ProgramConfig('rems_program_pomalyst').title}.
                    <br>Los fabricantes de ${pomalidomide} también pueden utilizar la información con fines comerciales, en la medida 
                    permitida por la legislación vigente.</p>`,
                    riskCatergory: [
                        'Adult Female of Reproductive Potential', 
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p> Entiendo que la información de mi hija se compartirá con el Programa ${ProgramConfig('rems_program_pomalyst').title}.
                    <br>Los fabricantes de ${pomalidomide} también pueden utilizar la información con fines comerciales, 
                    en la medida permitida por la legislación vigente. </p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p> Entiendo que la información de mi hijo se compartirá con el Programa ${ProgramConfig('rems_program_pomalyst').title}.
                    <br>Los fabricantes de ${pomalidomide} también pueden utilizar la información con fines comerciales, 
                    en la medida permitida por la legislación vigente. </p>`,
                    riskCatergory: [
                        'Male Child'
                    ],
                },
                {
                    order: 1,
                    content: `<p> Entiendo que la información de mi hija se compartirá con el programa ${ProgramConfig('rems_program_pomalyst').title}.
                    <br>Los fabricantes de ${pomalidomide} también pueden utilizar la información con fines comerciales, 
                    en la medida permitida por la legislación vigente. </p>`,
                    riskCatergory: [
                        'Female Child Not of Reproductive Potential',                                                                                          
                    ],
                },
                
            ],
            arrgements: [
                {
                    order: 1,
                    content: `Al firmar este formulario,</b><b> autorizo a que mi proveedor de atención médica inicie mi tratamiento con ${ProgramConfig('rems_program_pomalyst').label}</b>`,
                },
            ],
            body: ``,
            footer: `El programa ${ProgramConfig('rems_program_pomalyst').title} incluye POMALYST® (${pomalidomide}) y productos genéricos de la 
            ${pomalidomide}. Los fabricantes de ${pomalidomide} tienen un acuerdo contractual para la administración del 
            programa ${ProgramConfig('rems_program_pomalyst').title}. Todos los fabricantes conservan la responsabilidad de las acciones descritas 
            en el programa REMS.`,
        },
        authorizationTreatment: {
            header: `Sección 3. Autorización para iniciar el tratamiento`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si mi hijo 
                    no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podrá recibir ${pomalidomide}. También entiendo 
                    que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el 
                    tratamiento será de conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food 
                    and Drug Administration, FDA). 
                    <p class="bold">Acepto que el médico que receta el medicamento ha explicado plenamente al paciente y a su padre/madre/tutor 
                    legal la naturaleza, el propósito y los riesgos del tratamiento antes descrito, especialmente los posibles riesgos 
                    para mujeres que pueden quedar embarazadas. El médico que receta ha preguntado al paciente y a su 
                    padre/madre/tutor legal si tienen preguntas relativas al tratamiento del menor con ${pomalidomide} (lo que 
                    incluye los métodos anticonceptivos adecuados) y ha respondido tales preguntas a la entera y mutua 
                    satisfacción del paciente, del padre/madre/tutor legal, y del médico que receta. El paciente, el 
                    padre/madre/tutor legal y el médico que receta certifican que cumplirán con todas sus obligaciones y 
                    responsabilidades según se describen en el programa ${ProgramConfig('rems_program_pomalyst').title}. </p>`,
                    riskCatergory: [
                        'Male Child',                        
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si mi hija 
                    no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podrá recibir ${pomalidomide}. También entiendo 
                    que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el 
                    tratamiento será de conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food 
                    and Drug Administration, FDA). </p>
                    <p>Acepto que el médico que receta el medicamento ha explicado plenamente a la paciente y a su 
                    padre/madre/tutor legal la naturaleza, el propósito y los riesgos del tratamiento antes descrito, especialmente 
                    los posibles riesgos para mujeres que pueden quedar embarazadas. El médico que receta ha preguntado a la 
                    paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento de la menor con 
                    ${pomalidomide}, incluido el uso de dos métodos anticonceptivos eficaces (al menos un método altamente eficaz y 
                    un método eficaz) al mismo tiempo, y ha respondido tales preguntas a la entera y mutua satisfacción de la 
                    paciente, del padre/madre/tutor legal, y del médico que receta. La paciente, el padre/madre/tutor legal y el 
                    médico que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen 
                    en el programa ${ProgramConfig('rems_program_pomalyst').title}. </p>`,
                    riskCatergory: [                        
                        'Female Child of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si mi hija 
                    no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podrá recibir ${pomalidomide}. También entiendo 
                    que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el 
                    tratamiento será de conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food 
                    and Drug Administration, FDA). </p>
                    <p>Acepto que el médico que receta el medicamento ha explicado plenamente a la paciente y a su 
                    padre/madre/tutor legal la naturaleza, el propósito y los riesgos del tratamiento antes descrito, especialmente 
                    los posibles riesgos para mujeres que pueden quedar embarazadas. El médico que receta ha preguntado a la 
                    paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento de la menor con 
                    ${pomalidomide} y ha respondido tales preguntas a la entera y mutua satisfacción de la paciente, del 
                    padre/madre/tutor legal y del médico que receta. La paciente, el padre/madre/tutor legal y el médico que 
                    receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el 
                    programa ${ProgramConfig('rems_program_pomalyst').title}.</p>`,
                    riskCatergory: [                        
                        'Female Child Not of Reproductive Potential',                       
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si no 
                    sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podré recibir ${pomalidomide}. También entiendo que 
                    la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento será de 
                    conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food and Drug 
                    Administration, FDA).</p>
                    <p class="bold">Acepto que el médico que receta el medicamento ha explicado plenamente al paciente la naturaleza, el 
                    propósito y los riesgos del tratamiento antes descrito, especialmente los posibles riesgos para mujeres que 
                    pueden quedar embarazadas. El médico que recetó ha preguntado a la paciente si tiene alguna pregunta sobre 
                    su tratamiento con ${pomalidomide}, incluido el uso de dos métodos anticonceptivos eficaces (al menos un 
                    método altamente eficaz y un método eficaz) al mismo tiempo, y ha respondido dichas preguntas a la 
                    satisfacción mutua de la paciente y del médico que receta. Tanto la paciente como el médico que receta 
                    certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa 
                    ${ProgramConfig('rems_program_pomalyst').title}.</p>`,
                    riskCatergory: [                       
                        'Adult Female of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si no 
                    sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podré recibir ${pomalidomide}. También entiendo que 
                    la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento será de 
                    conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food and Drug 
                    Administration, FDA).</p>
                    <p class="bold">Acepto que el médico que receta el medicamento ha explicado plenamente al paciente la naturaleza, el 
                    propósito y los riesgos del tratamiento antes descrito, especialmente los posibles riesgos para mujeres que 
                    pueden quedar embarazadas. El médico que receta ha preguntado a la paciente si tiene preguntas relativas a 
                    su tratamiento con ${pomalidomide} y ha respondido tales preguntas a la entera y mutua satisfacción de la 
                    paciente y del médico que receta. Tanto la paciente como el médico que receta certifican que cumplirán con 
                    todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_pomalyst').title}.</p>`,
                    riskCatergory: [                       
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que, si no 
                    sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_pomalyst').title}, no podré recibir ${pomalidomide}. También entiendo que 
                    la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento será de 
                    conocimiento del fabricante de ${pomalidomide} y de la Administración de Alimentos y Medicamentos (Food and Drug 
                    Administration, FDA).</p>
                    <p class="bold">Acepto que el médico que receta el medicamento ha explicado plenamente al paciente la naturaleza, el 
                    propósito y los riesgos del tratamiento antes descrito, especialmente los posibles riesgos para mujeres que 
                    pueden quedar embarazadas. El médico que receta ha preguntado al paciente si tiene preguntas relativas a 
                    su tratamiento con ${pomalidomide} (lo que incluye los métodos anticonceptivos adecuados) y ha respondido 
                    tales preguntas a la entera y mutua satisfacción del paciente y del médico que receta. Tanto el paciente como 
                    el médico que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se 
                    describen en el programa ${ProgramConfig('rems_program_pomalyst').title}.</p>`,
                    riskCatergory: [                       
                        'Adult Male',                         
                    ],
                },
                
            ],
            arrgement: {
                order: 1,
                content: `<span class="bold">Me gustaría recibir materiales educativos de ${ProgramConfig('rems_program_pomalyst').title}. Envíe los materiales por correo a la
                    dirección proporcionada en este Formulario de acuerdo entre paciente y médico.</span>`,
            },

            TermsAndConditon: `Le he explicado de forma completa al paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito,
            especialmente los posibles riesgos para mujeres con capacidad de concebir. He preguntado si el paciente tenía
            alguna pregunta con respecto al tratamiento con ${pomalidomide} (lo que incluye métodos anticonceptivos adecuados) y 
            he respondido a esas preguntas en la medida de mis posibilidades. Cumpliré con todas mi obligaciones y
            responsabilidades como Prescriptor certificado en virtud de los programas ${ProgramConfig('rems_program_pomalyst').title}.`,
        },
    },
};
export const PatientAgreementTh_Spanish = {  
    body: {        
        formHeading: `Formulario de acuerdo entre paciente y médico para ${ProgramConfig('rems_program_thalomid').title}${registerIcon}`,
        fields: `<p> Los campos marcados con un <span style='color: #7b0305'>*</span> son obligatorios. </p>`,
        bodynote: "Lea detenidamente las afirmaciones siguientes.",
        disclaimers: [
            {
                order: 0,
                content: `<style> .bold {
                    font-weight: bold;
                }
                .italic {
                    font-style: italic;
                }
                .legalSymbol {
                    font-size: 0.8em;
                    position: relative;
                    top: -0.5em;
                }</style>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a usted. ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, los pacientes deben leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                Si una mujer con la que mantiene relaciones sexuales está embarazada o queda embarazada mientras usted está tomando ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es importante que sepa que el bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>
                `,
                riskCatergory: [
                    'Adult Male',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a usted. ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, debe leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                Si está embarazada o queda embarazada mientras está tomando ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es importante que sepa que su bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>
                `,
                riskCatergory: [
                    'Adult Female of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a usted. ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, debe leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                El bebé en gestación de una mujer que toma ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} puede sufrir defectos de nacimiento graves incluso morir.
                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).

                </p>
                `,
                riskCatergory: [
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a su hijo.* ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, los pacientes deben leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                Si una mujer con la que su hijo mantiene relaciones sexuales está embarazada o queda embarazada de su hijo mientras él está tomando ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es importante que sepa que el bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>
                `,
                riskCatergory: [
                    'Male Child',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a su hija.* ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, las pacientes deben leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                Si su hija está embarazada o queda embarazada mientras está tomando ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es importante que sepa que su bebé en gestación puede sufrir defectos de nacimiento graves o incluso morir.

                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>
                `,
                riskCatergory: [
                    'Female Child of Reproductive Potential',
                ],
            },
            {
                order: 1,
                content: `
                <p class="bold">
                Su proveedor de atención médica le ha recetado ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}<span class="legalSymbol">®</span> a su hija.* ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} está disponible únicamente a través de un programa de distribución restringida llamado Estrategia de evaluación y mitigación de riesgos de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} Risk Evaluation and Mitigation Strategy (REMS). Antes de tomar ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, las pacientes deben leer y aceptar todas las instrucciones del programa ${ProgramConfig('rems_program_thalomid').title}<span class="legalSymbol">®</span>. 
                </p>
                <p class="bold">
                Todo bebé en gestación de una menor que toma ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} puede tener defectos de nacimiento graves o incluso morir.
                </p>
                <p>
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} aumenta las probabilidades de presentar coágulos de sangre en las venas (trombosis venosa profunda) y en los pulmones (embolia pulmonar).
                </p>
                `, riskCatergory: [
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 4,
                content: `<p>Para obtener más información, consulte la Guía del medicamento de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 5,
                content: `<p class="bold">INSTRUCCIONES</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> que su hijo inicie su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [
                    'Male Child',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> que su hija inicie su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es necesario que usted haga lo siguiente:</p>`,
                riskCatergory: [
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
            {
                order: 6,
                content: `<p class="italic"><span class="bold">Antes de</span> iniciar su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}, es necesario que haga lo siguiente:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 7,
                content: `<p><span class="bold">1.</span> Llene las secciones 1 y 2 de este formulario y coloque su firma y la fecha al final del formulario.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 8,
                content: `<p><span class="bold">2.</span> Lea los materiales de ${ProgramConfig('rems_program_thalomid').title} contenidos en el <span class="bold">Paquete de recursos para el paciente.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 9,
                content: `<p><span class="bold">3.</span> Conserve una copia de este formulario para sus registros.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 10,
                content: `<p class="bold">Representantes autorizados:</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 11,
                content: `<p>Si el representante autorizado no tiene un poder notarial, <span class="bold">se debe presentar una carta firmada y fechada por el 
                profesional que receta el medicamento, con el membrete de este, al Centro de Llamadas de REMS, junto con 
                el Formulario de acuerdo paciente-médico de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (talidomida).</span> Esta carta debe incluir: una declaración 
                que explique que el paciente incapaz no tiene capacidad para completar el Formulario de acuerdo paciente-médico de
                ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (talidomida), incluida una identificación de la afección médica que causa la incapacidad; el nombre y la
                dirección del representante autorizado; la relación del representante autorizado con el paciente; y un enunciado que 
                indique que el representante autorizado se hace responsable que el paciente cumpla con el programa ${ProgramConfig('rems_program_thalomid').title} y
                que está autorizado para dar su consentimiento para que se realice el tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} en nombre del
                paciente.</p>`,
                riskCatergory: [
                    'Adult Male',
                    'Adult Female of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: `<p>Para obtener más información, visite <span class="bold">www.BMSREMSPatientSafety.com</span>, o llame al Centro de Llamadas de REMS al <span class="bold">1-888-423-5436.</span></p>`,
                riskCatergory: [
                    'Male Child',
                    'Adult Male',
                    'Female Child of Reproductive Potential',
                    'Adult Female of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                    'Adult Female Not of Reproductive Potential',
                ],
            },
            {
                order: 12,
                content: `<p><b>*</b>A lo largo de este formulario, la palabra hija incluye a cualquier menor del que usted sea padre, madre o tutor legal.</p>`,
                riskCatergory: [
                    'Male Child',
                    'Female Child of Reproductive Potential',
                    'Female Child Not of Reproductive Potential',
                ],
            },
        ],
        patientArgeement: {
            note: 'Lea detenidamente las afirmaciones siguientes. Marque la casilla de verificación junto a cada una de las afirmaciones.',
            header: `Sección 1. Acuerdo del paciente`,
            subtitle: `Entiendo y confirmo que:`,
            arrgements: [],
        },
        authorization: {
            header: `Sección 2. Autorización`,
            disclaimers: [
                {
                    order: 0,
                    content: `<style> .bold {
                        font-weight: bold;
                    }
                    .italic {
                        font-style: italic;
                    }
                    .legalSymbol {
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    }</style>`,
                    riskCatergory: [
                        'Male Child',
                        'Adult Male',
                        'Female Child of Reproductive Potential',
                        'Adult Female of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p class="bold" >Entiendo que la información de mi hijo será compartida con Bristol Myers Squibb para el Programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}. Bristol Myers Squibb también puede utilizar la información para propositos de negocios, en la medida en que lo permita la ley aplicable.
                    </p>`,
                    riskCatergory: ['Male Child'],

                },
                {
                    order: 110,
                    content: `<p class="bold" > Entiendo que la información de mi hija será compartida con Bristol Myers Squibb para el Programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}. Bristol Myers Squibb también puede utilizar la información para propositos de negocios, en la medida en que lo permita la ley aplicable.</p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                        'Female Child Not of Reproductive Potential',
                    ]

                },
                {
                    order: 110,
                    content: `<p class="bold" > Entiendo que mi información será compartida con Bristol Myers Squibb para el Programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}. Bristol Myers Squibb también puede utilizar la información para propositos de negocios, en la medida en que lo permita la ley aplicable.</p>`,
                    riskCatergory: [
                        'Adult Male',
                        'Adult Female of Reproductive Potential',
                        'Adult Female Not of Reproductive Potential',
                    ],
                },
            ],
            arrgements: [],
            body: ``,
            footer: `${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y ${ProgramConfig('rems_program_thalomid').title} son marcas comerciales registradas de Celgene Corporation, una compania de Bristol-Myers Squibb.`,
        },
        authorizationTreatment: {
            header: `Sección 3. Autorización para iniciar el tratamiento`,
            subcontents: [
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hijo no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podrá recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento se pondrá en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente al paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado al paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento del menor con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} (lo que incluye medidas anticonceptivas apropiadas) y ha respondido tales preguntas a la entera y mutua satisfacción del paciente, del padre/madre/tutor legal, y de la persona que receta. El paciente, el padre/madre/tutor legal, y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.</p>`,
                    riskCatergory: [
                        'Male Child',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hija no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podrá recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento será puesta en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para las mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente y a su padre/madre/tutor legal si tiene preguntas relativas al tratamiento de la menor con THALOMID®, incluyendo el uso de dos métodos anticonceptivos eficaces (al menos un método altamente eficaz y un método eficaz) al mismo tiempo y ha respondido tales preguntas a la entera y mutua satisfacción de la paciente, del padre/madre/tutor legal, y de la persona que receta. La paciente, el padre/madre/tutor legal, y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.</p>`,
                    riskCatergory: [
                        'Female Child of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si mi hija no sigue todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podrá recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcionemos en este formulario y como parte de los cuestionarios que llenaremos durante el tratamiento será puesta en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta ha explicado plenamente a la paciente y a su padre/madre/tutor legal la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para las mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente y a su padre/madre/tutor legal si tienen preguntas relativas al tratamiento de la menor con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} y ha respondido tales preguntas a la mutua satisfacción de la paciente, del padre/madre/tutor legal, y de la persona que receta. La paciente, el padre/madre/tutor legal y la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.
                    </p>`,
                    riskCatergory: [
                        'Female Child Not of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podré recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente al paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado al paciente si tiene preguntas relativas a su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} (lo que incluye medidas anticonceptivas apropiadas) y ha respondido tales preguntas a la mutua satisfacción del paciente y de la persona que receta. Tanto el paciente como la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.</p>`,
                    riskCatergory: [
                        'Adult Male',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podré recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que la persona que receta el medicamento ha explicado plenamente a la paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. La persona que receta ha preguntado a la paciente si tiene preguntas relativas a su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} , incluyendo el uso de dos métodos anticonceptivos eficaces (al menos un método altamente eficaz y un método eficaz) al mismo tiempo y ha respondido tales preguntas a la mutua satisfacción de la paciente y la persona que receta. Tanto la paciente como la persona que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.
                    </p>`,
                    riskCatergory: [
                        'Adult Female of Reproductive Potential',
                    ],
                },
                {
                    order: 1,
                    content: `<p>He leído la información de este formulario o me ha sido leída en voz alta en el idioma de mi preferencia. Entiendo que si no sigo todas las instrucciones relativas al programa ${ProgramConfig('rems_program_thalomid').title} no podré recibir ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}. También entiendo que la información que proporcione en este formulario y como parte de los cuestionarios que llenaré durante el tratamiento se pondrá en conocimiento del fabricante de ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} y de la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA).</p>
                    <p class="bold">Acepto que el médico que receta el medicamento ha explicado plenamente a la paciente la naturaleza, el propósito y los riesgos del tratamiento antes descrito, especialmente los posibles riesgos para mujeres que pueden quedar embarazadas. El médico que receta ha preguntado a la paciente si tiene preguntas relativas a su tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()}${registerIcon} y ha respondido tales preguntas a la entera y mutua satisfacción de la paciente y del médico que receta. Tanto la paciente como el médico que receta certifican que cumplirán con todas sus obligaciones y responsabilidades según se describen en el programa ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.</p>`,
                    riskCatergory: [
                        'Adult Female Not of Reproductive Potential',
                    ],
                }
            ],
            arrgement: {
                order: 1,
                content: `<span class="bold"> Me gustaría recibir materiales educativos de ${ProgramConfig('rems_program_thalomid').title}${registerIcon}. Envíe los materiales por correo a
                la dirección proporcionada en este Formulario de acuerdo entre paciente y médico.</span>`,
            },
            TermsAndConditon: `Le he explicado de forma completa al paciente la naturaleza, propósito y riesgos del tratamiento arriba descrito, 
            especialmente los posibles riesgos para mujeres con capacidad de concebir. He preguntado si el paciente tenía 
            alguna pregunta con respecto al tratamiento con ${ProgramConfig('rems_program_thalomid').label.toUpperCase()} (lo que incluye métodos anticonceptivos adecuados) y 
            he respondido a esas preguntas en la medida de mis posibilidades. Cumpliré con todas mi obligaciones y 
            responsabilidades como Prescriptor certificado en virtud de los programas ${ProgramConfig('rems_program_thalomid').title}${registerIcon}.`,
        },
    },
};
