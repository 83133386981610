export const logDispenseConfirmation = {
    body: [
        {
            order: 1,
            content: `All Authorization Numbers for Female Patients of Reproductive Potential are valid for 7 days from the date of the last pregnancy test. All other Authorization Numbers are valid for 30 days from the date the Authorization Number is obtained. Please write the Confirmation Number and date obtained on the patient’s prescription for your records. For Females of Reproductive Potential, product must be shipped the same day the Confirmation Number was obtained. For all other Risk Categories, product must be shipped within 24 hours of obtaining the Confirmation Number. Please contact the REMS Call Center toll free at 1-888-423-5436 if unable to ship within the required time-frame.`,
        },
        {
            order: 2,
            content: `Please Note: Upon entering this screen, you have 15 minutes to document your confirmation numbers and click one of the buttons below, or you will be logged out of the system.`,
        },
    ],
    subheader: '',
};
