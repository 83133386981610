import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { Role } from 'constants/Config/appConfig';
import { resetSecurityFields } from 'constants/createAccountFormFields';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { store, useAppDispatch, useAppSelector } from 'store';
import { resetApp } from 'store/slices/appSlice';
import { setLogOut, setUpdatePharmacyPayload } from 'store/slices/loginSlice';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import classes from './ForgotPassword.module.scss';

interface dropDownType {
    SecurityQuestion?: { id: string; value: string }[] | null;
}

const ResetSecurity = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const { currentUser, resetUserName, currentUserPharmacy } = useAppSelector((state) => state.login);
    const [currentFocus, setCurrentFocus] = useState('');
    const [dropdown, setDropdown] = useState<dropDownType>({ SecurityQuestion: [] });
    const { role } = useAppSelector((state) => state.app);
    const isPharmacy = role === Role.Pharmacist;
    const userNameToReset = resetUserName?.[isPharmacy ? 'pharmacy' : 'prescriber'];
    const [values, setValues] = useState({
        securityQuestion: '',
        securityAnswer: '',
    });

    const handleOnChange = (e) => {
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            if (!['securityQuestion'].includes(field)) {
                newVals[field] = newVals[field].trim();
            }
        });
        setValues(newVals);
        return newVals;
    };

    const handleContinue = () => {
        trimValues();
        for (let i of resetSecurityFields) {
            if (values[i.name] === '' || values[i.name].includes('Select a Question')) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                return;
            } else if (
                i.name === 'securityAnswer' &&
                (values.securityAnswer.includes('<') || values.securityAnswer.includes('>'))
            ) {
                setError('Please enter a valid Security Answer');
                setCurrentFocus(i.name);
                return;
            }
        }

        if (isPharmacy) {
            const payload = {
                securityAnswer: values.securityAnswer,
                securityQuestion: values.securityQuestion,
            };
            dispatch(setUpdatePharmacyPayload(payload));
            navigate('/pharmacy/pharmacyResetTemp', {
                replace: true,
                state: {
                    values: {
                        ...values,
                        securityAnswer: values.securityAnswer,
                        userName: currentUserPharmacy?.onlineUserId || userNameToReset,
                    },
                },
            });
        } else {
            const payload = {
                securityAnswer: values.securityAnswer,
                securityQuestion: values.securityQuestion,
            };
            dispatch(setUpdatePharmacyPayload(payload));
            navigate('/prescriber/resetTempPassword', {
                replace: true,
                state: {
                    values: {
                        ...values,
                        securityAnswer: values.securityAnswer,
                        userName: userNameToReset || '',
                    },
                },
            });
        }
    };

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const handleCancel = () => {
        clearStore();
        dispatch(resetApp());
        dispatch(setLogOut());
        if (isPharmacy) {
            navigate('/pharmacy/login', { replace: true });
        } else {
            navigate('/prescriber/login', { replace: true });
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        setError('');
        setCurrentFocus('');
    };

    const fetchDropdown = async () => {
        fetchDatafromS3json().then((res) => {
            setDropdown({ SecurityQuestion: res?.securityQuestion });
        });
    };

    useEffect(() => {
        fetchDropdown();
    }, []);

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);


    return (
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label="Reset Security Question"
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={handleCancel}
            >
                <>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {apiError && (
                            <Alert
                                content={
                                    'You have entered an incorrect user name. Please try again. If you feel that the name you have entered is correct, but you cannot proceed to the next screen, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                                }
                                styles={{ margin: '10px 20px 5px 20px' }}
                            />
                        )}
                        <div className={classes.formHeader}>
                            You have logged in with a Temporary Password, and are required to reset your Security
                            Question. Please select a new Security Question and Answer.
                        </div>
                        <div className={classes.container}>
                            <div className={classes.label}>
                                <span>User Name:</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                                {(isPharmacy ? currentUserPharmacy?.onlineUserId || userNameToReset : currentUser?.onlineUserId) ||
                                    userNameToReset}
                            </div>
                        </div>
                        {resetSecurityFields.map((i) => {
                            if (i.name === 'securityQuestion') {
                                return (
                                    <Input
                                        key={i.name}
                                        type="select"
                                        isRequired={true}
                                        name="securityQuestion"
                                        value={values.securityQuestion}
                                        options={
                                            dropdown?.SecurityQuestion
                                                ? [
                                                      { value: 'Select a Question...' } as { value: string },
                                                      ...dropdown?.SecurityQuestion?.map((item) => ({
                                                          value: item.value,
                                                      })),
                                                  ]
                                                : []
                                        }
                                        label="Security Question"
                                        labelStyle={{ fontWeight: 'bold' }}
                                        width={'268px'}
                                        onChangeHandler={handleOnChange}
                                    />
                                );
                            } else {
                                return (
                                    <Input
                                        key={i.name}
                                        type={i.type}
                                        name={i.name}
                                        label={i.label}
                                        value={values[i.name]}
                                        maxLength={i.maxLength}
                                        isRequired={i.isRequired}
                                        width={i.name === 'email' ? '260px' : '268px'}
                                        onChangeHandler={handleOnChange}
                                        labelStyle={{ fontWeight: 'bold' }}
                                    />
                                );
                            }
                        })}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default ResetSecurity;
