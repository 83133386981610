import classes from './prescriberDashboardReportsHome.module.scss';
import { Link} from 'react-router-dom';


const PrescriberDashboardReportsHome = () => {
    return (
        <div className={classes.reportsPage}>
            <p className={classes.textBold}>Please select a report type.</p>
            <div className={classes.sideMargins}>
                <div className={classes.pt10px}>
                    <Link to='/prescriber/dashboard/reports/expireReport'>Authorization Numbers to Expire Report</Link>
                    <p>This report provides the ability to display the authorization numbers that are going to expire within a specific date range.</p>
                </div>
                <div className={classes.pt10px}>
                    <Link to='/prescriber/dashboard/reports/historicalReport'>Historical Authorization Report</Link>
                    <p>This report provides the ability to run a historical report based on authorization status and date range to show all patient(s), authorization status(es) and program(s).</p>
                </div>
                <div className={classes.pt10px}>
                    <Link to='/prescriber/dashboard/reports/hiddenPatientReport'>Hidden Patient Report</Link>
                    <p>This report provides the ability to view your hidden patients and if needed, click on the Unhide button next to the patient's name.</p>
                </div>
            </div>
        </div>
    );
};
export default PrescriberDashboardReportsHome;
