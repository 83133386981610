import React, { useRef } from 'react';
import classes from './SessionTimeoutModal.module.scss';

const SessionTimeoutModal = ({ children }) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);

    return (
        <div className={classes.timeoutModalContainer}>
            <dialog ref={modalRef} className={classes.mainContainer}>
                {children}
            </dialog>
        </div>
    );
};

export default SessionTimeoutModal;
