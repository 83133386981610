import React, { useEffect } from 'react';
import classes from './footer.module.scss';
import { useAppSelector } from 'store';

const Footer = () => {
    const { role } = useAppSelector((state) => state.app);
    document.title = '';
    let contactUsText='';
    useEffect(() => {

        if (role === "pharmacist") {
            document.title = "REMS Pharmacy Portal";
            contactUsText = "Pharmacy Portal";
        }
        else {
            document.title = "REMS Prescriber Portal";
            contactUsText = "REMS Patient Safety website";
        }
        sessionStorage.setItem("documentTitle", document.title);
        sessionStorage.setItem("contactUsBody", contactUsText);

    }, [role])


    const onHandleContactUs = () => {
        window.open(
            `${window.location.origin}/#/ContactUs`,
            'contactUs',
            'location=yes,height=600,width=820,scrollbars=yes,status=yes'
        );

    };

    const onHandleTerms = () => {
        window.open(
            `https://www.bms.com/legal-notice.html`,
            'terms',
            'location=yes,height=600,width=820,scrollbars=yes,status=yes'
        );
    };

    const onGetCurrentYear = () => {
        const year: number = (new Date()).getFullYear();
        return year;
    }

    const onHandlePrivacyPolicy = () => {
        window.open(
            `https://www.bms.com/privacy-policy.html`,
            'policy',
            'location=yes,height=600,width=820,scrollbars=yes,status=yes'
        );
    };

    return (
        <div className={classes.container}>
            <div className={classes.footer1}>
                <span tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && onHandleContactUs()} onClick={onHandleContactUs}>Contact Us</span>
                <span tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && onHandleTerms()} onClick={onHandleTerms}>Terms of Use</span>
                <span tabIndex={0} onKeyDown={(e) => e.key === 'Enter' && onHandlePrivacyPolicy()} onClick={onHandlePrivacyPolicy}>Privacy Policy</span>
            </div>
            <FooterCopyRight />
        </div>
    );
};

export const FooterCopyRight = () => {
    const onGetCurrentYear = () => {
        const year: number = (new Date()).getFullYear();
        return year;
    }
    return (
        <div className={classes.containerCopyright}>
            <div className={classes.footer2}>
                <span>©{onGetCurrentYear()} Bristol-Myers Squibb Company</span>
            </div>
        </div>
    )
}

export default Footer;
