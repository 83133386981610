import React, { useState, useEffect } from 'react';
import PopUp from 'components/PopUp';
import { axiosInstance, axiosInstance_ORCHAPI } from 'constants/Config/axiosConfig';
import { setClosedPopUpTemplate } from 'store/slices/loginSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { current } from '@reduxjs/toolkit';
import { activePharmacy as ActivePharmacy } from 'store/slices/appSlice';

const DynamicPopUp = () => {
    const [popUpData, setPopUpData] = useState<any>([]);
    const [filtered, setFiltered] = useState<any>([]);
    const [finalCopy, setFinalCopy] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const [showAcknowledge, setShowAcknowledge] = useState(true);
    const dispatch = useAppDispatch();

    const { eligiableProduct } = useAppSelector((state) => state.app.patientSurvey);
    const { IsAuthenticated, popUpTemplate, closedPopUpTemplate, currentUserPharmacy } = useAppSelector((state) => state.login);
    const { activePharmacy } = useAppSelector((state) => state.app);

    useEffect(() => {
        if (popUpData && popUpTemplate && popUpData.length > 0 && popUpTemplate.length > 0) {
            const filteredArray = popUpTemplate.filter(
                (itemA) => popUpData?.some((itemB) => itemB.functionName === itemA.name)
            );
            setFiltered(filteredArray);
        }
    }, [popUpData, popUpTemplate]);
    

    const iterateHandle = (value, acknowledged) => {
    let userName = currentUserPharmacy?.userinfo?.username;
    let groupTag = currentUserPharmacy?.groupTag;
    let products = activePharmacy?.data?.map(item=>item.id?.productCountryId?.product?.productName);
        let findIndex = filtered.findIndex((x) => x.name === value.name);
        if (findIndex !== -1) {
            let shallowCopy = JSON.parse(JSON.stringify(filtered));
            shallowCopy[findIndex].closed = value.closed;
            dispatch(setClosedPopUpTemplate([...shallowCopy]));
            setFiltered([...shallowCopy]);
            setModal(false);
        }
        if (acknowledged) {
            const acknowlegeRequest = {
                // celgeneId: currentUser?.systemId,
                // contactType: 'Patient',
                // enrolledProducts: null,
                // functionName: value.name,
                // communicationType: 'REMS Portal',
                // countryId: {
                //     countryId: 1,
                //     countryName: null,
                //     countryCode: null,
                // },
                // languageId: {
                //     languageId: 1,
                //     iso_639_1_code: null,
                //     languageName: null,
                //     otherLanguage: null,
                // },
                
                    celgeneId: currentUserPharmacy?.systemId,
                    userName: userName,
                    groupTag: groupTag,
                    functionName: value.name,
                    countryId: {
                      countryId: 1,
                      countryName: "string",
                      countryCode: "string"
                    },
                    languageId: {
                      languageId: 1,
                      iso_639_1_code: "string",
                      languageName: "string",
                      otherLanguage: "string"
                    }
                  
            };
            submitAcknowledgement(acknowlegeRequest);
        }
    };

    const getPayloadForPopUp = (arr) => {
        if (arr.some((value) => value.closed === 'false')) {
            let FinalArray = arr.find((value) => value.closed === 'false');
            let Data = popUpData.filter((value) => value.functionName === FinalArray.name);
            if (Data.length > 0 && Data[0].acknowledgementFlag === 'Y') {

                setShowAcknowledge(true);
            } else {
                setShowAcknowledge(false);
            }
            setFinalCopy([FinalArray]);
            setModal(true);
        }
    };

    useEffect(() => {
        if (closedPopUpTemplate.length > 0) {
            getPayloadForPopUp(closedPopUpTemplate);
        } else {
            getPayloadForPopUp(filtered);
        }
    }, [filtered, closedPopUpTemplate]);

    

    let userName = currentUserPharmacy?.userinfo?.username;
    let groupTag = currentUserPharmacy?.groupTag;
    let products = activePharmacy?.data?.map(item=>item.id?.productCountryId?.product?.productName);


    const getDynamicPopUp = async () => {
        try {
            const payload={
                'username':userName,
                'groupTag':groupTag
            };
            const response = await axiosInstance_ORCHAPI.post(`/acknowledgement/pharmacy/getNotification`,payload);
            setPopUpData(response?.data?.data);
        } catch {}
    };

    const submitAcknowledgement = async (acknowlegeRequest) => {
        try {
            const response = await axiosInstance_ORCHAPI.post(`/acknowledgement/save`, acknowlegeRequest);
        } catch {}
    };

    useEffect(() => {
        getDynamicPopUp();
    }, []);

    return (
        finalCopy &&
        finalCopy[0] &&
        finalCopy.map((value) => {
            // return <PopUp pop={value} iterateHandle={iterateHandle} modal={modal} acknowledge={false} />;
            return <PopUp pop={value} iterateHandle={iterateHandle} modal={modal} acknowledge={showAcknowledge} />;
            
        })
    );
};

export default DynamicPopUp;
