import { useTranslation } from 'react-i18next';
import classes from './PatientSurvey.module.scss';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { patientEligibilityProduct, updatePatientSurvey } from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { PatientSurveyFlowType, PatientSurveyScreens, products } from './PatientSurveyType';
import { useEffect, useState } from 'react';
import ErrorScreen from './ErrorScreen';
import DynamicPopUp from './DynamicPopUp';
import Button from 'components/Button';
import { buttonContent } from 'assets/objects/buttonContent';
import { ProgramConfig, ProgramConfigByName } from 'constants/Config/programsConfig';

const SelectProduct = ({ setScreen }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { patientSurvey } = useAppSelector((state) => state.app);
    const { eligiableProduct } = useAppSelector((state) => state.app.patientSurvey);
    const [error, setError] = useState('');
    const [surveyProduct, setSurveyProduct] = useState(
        eligiableProduct.products
            .filter((x) => {
                return x.status === 'eligible';
            })
            .sort((a, b) => a.name.localeCompare(b.name))
    );
    const urlNavigate = (item) => {
        window.open(
            `https://${item.link}`,
            item.name,
            'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width=' +
                window.screen.availWidth * (11 / 12) +
                ',height=600'
        );
    };

    const handleCancal = () => {
        setScreen(PatientSurveyScreens.Search);
    };

    const searchResponseHandler = (response) => {
        if (response.status === 200) {
            setScreen(PatientSurveyScreens.Survey);
        } else if (response.status === 204 || response.status === 202) {
            dispatch(updatePatientSurvey({ ...patientSurvey, businessErrorCode: { code: response.status } }));
            setScreen(PatientSurveyScreens.Error);
        } else if (response.response.status === 400) {
            dispatch(
                updatePatientSurvey({
                    ...patientSurvey,
                    businessErrorCode: response?.response?.data?.data?.errorMesssage,
                })
            );
            setScreen(PatientSurveyScreens.Error);
        }
    };

    const getSurvayDetails = (productName: string) => {
        const payload = {
            patientExtUniqueId: patientSurvey?.eligiableProduct?.patientExtUniqueId,
            language: i18n.language,
        };
        dispatch(patientEligibilityProduct({ payload, productName })).then((response) => {
            searchResponseHandler(response.payload);
        });
    };

    const productElemnet = (productName) => {
        const product = products()?.filter((item) => {
            return item.name.toLocaleLowerCase() === productName.toLocaleLowerCase();
        })[0];
       
        return (
            <div className={`${classes.product} ${classes[product.name]}`}>
                <div className={classes.bigPad_T}>
                    <div className={classes.bodyText}>
                        {t('for')}{' '}
                        <span className={classes.bold}>                            
                           {/*  {
                             product.name === 'Thalomid' ? <>
                             <span>{`${t(product.name).toUpperCase()} REMS`} </span> <span className={classes.legalSymbol}>®</span></>:
                             <span>{`${t(product.name)} REMS`}</span>
                            } */}
                            {ProgramConfigByName(product.name)?.title}
                            {ProgramConfigByName(product.name)?.registeredTradermark ? <span className={classes.legalSymbol}>®</span> :<></>}
                        </span>
                        <br />
                        <div className={classes.buffer}></div>
                        <a onClick={() => getSurvayDetails(productName)}>
                            <span className={classes.generalPrgmLogo}
                            style={{
                                backgroundImage: `url(${require(`../../assets/images/${ProgramConfigByName(product?.name).imageName}`)})`
                            }}
                            ></span>
                        </a>
                    </div>
                </div>
                <div className={classes.bigPad_T}>
                    <div className={classes.link}>
                        <a
                            onClick={() => {
                                urlNavigate(product);
                            }}
                            dangerouslySetInnerHTML={{ __html: t(product.linkText) }}
                        >                           
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (surveyProduct.length === 1) {
            getSurvayDetails(surveyProduct[0].name);
        }
    }, []);

    return (
        <div className={classes.search}>
            {surveyProduct?.length > 1 && (
                <>
                   
                    {
                        (patientSurvey.flowType === PatientSurveyFlowType.SearchPage || patientSurvey.flowType === PatientSurveyFlowType.HomePage) &&
                        <DynamicPopUp />
                    }
                    <PageBackground label={t('chooseProgram')}>
                        <div className={classes[i18n.language]}>
                            <div className={classes.chooseProgramTitle}>{t('chooseProgramMessage')}</div>
                            <div className={classes.contentContainer}>
                                <div>
                                    {surveyProduct.map((product) => {
                                        return productElemnet(product.name);
                                    })}
                                </div>
                            </div>
                            <div className={classes.actionButton}>                               
                                <Button
                                    clickFunction={() => handleCancal()}
                                    className={'buttonStyles'}
                                    width={115}
                                    height={45}
                                    size={18}
                                    type="text"
                                    buttonname={
                                        i18n.language === 'es' ? buttonContent.es.cancel : buttonContent.en.cancel
                                    }
                                ></Button>
                            </div>
                        </div>
                        <SessionTimeout />
                    </PageBackground>
                </>
            )}
        </div>
    );
};

export default SelectProduct;
