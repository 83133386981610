import classes from './detailedPatientAuthData.module.scss';
import { formatDate } from 'utils/formatDate';
import greyedWritePrescriptionBtn from '../../../../assets/images/btn_grey_write_prescription.png';
import activeWritePrescriptionBtn from '../../../../assets/images/btn_write_prescription_blue_.png';
import printBtn from '../../../../assets/images/btn_Phar_ReviewAuth_Print.png';
import finishBtn from '../../../../assets/images/btn_Phar_ReviewAuth_Finish.png';
import logDispenseBtn from '../../../../assets/images/btn_Phar_ReviewAuth_logDispense.png';
import { useEffect, useState } from 'react';
import { getPharmacyLocation, clearPharmacyLocation, setPatientData, changeProgram } from 'store/slices/appSlice';
import { FaWindowClose } from 'react-icons/fa';
import { axiosInstance_2 } from 'constants/Config/axiosConfig';
import { useNavigate } from 'react-router-dom';
import {PROGRAMS,ProgramConfig} from 'constants/Config/programsConfig';
import { trimPrefix } from 'utils/trimPrefix';
import {getReviewAuthPrintPopUpTemplate} from "../../../PrescriberDashboardReviewAuth/getReviewAuthPrintPopUpTemplate"
import { useAppDispatch, useAppSelector } from 'store';


const DetailedPatientAuthData = ({ authData }) => {
    const {
        patientDob = null,
        patientRiskCategoryName = null,
        patientLastSurveyDate = null,
        patientNextEarliestSurveyDate = null,
        confirmationNumber = null,
        dispenseCreatedDate = null,
        cancellationNumber = null,
        cancelledDate = null,
        dispensationSequences = null,
        authorizationStatus = null,
        authorizationStatusId = null,
        pharmacyId = null,
        patientExternalUniqueId = null,
        productCountryId = null,
    } = authData;

    const dispatch = useAppDispatch();

    const [pharmacyDetails, setPharmacyDetails] = useState<any>(null);
    const [isPharmacyDetailsClicked, setIsPharmacyDetailsClicked] = useState(false);

    const navigate = useNavigate();
    const { authorizationData } = useAppSelector((state) => state.app);
    const { pharmacyLocation } = useAppSelector((state) => state.app);
    
    useEffect(() => {
        if (!pharmacyId) {
            setPharmacyDetails(null);
            return;
        } else {
            const payload = {
                channelId: 'ext',
                pharmacyId: pharmacyId,
            };
            dispatch(getPharmacyLocation(payload)).then((e: any) => {
                if (e.payload?.data?.statusCode === 200) {
                    const pharmacyDetails = {
                        pharmacyName: e.payload?.data?.data?.pharmacyName,
                        pharmacyInfo: e.payload?.data?.data?.pharmacyLocations[0],
                        pharmacyNCPDP: e.payload?.data?.data?.pharmacyExternalId1,
                        pharmacyDEA:  e.payload?.data?.data?.pharmacyExternalId2
                    };
                    setPharmacyDetails(pharmacyDetails);
                } else {
                    setPharmacyDetails(null);
                    dispatch(clearPharmacyLocation);
                }
            });
        }
    }, [pharmacyId]);

    const pharmacyDetailsToggler = () => {
        setIsPharmacyDetailsClicked(true);
    };


    const handleFinish = async () => {
        navigate('/pharmacy/PharmacyHome', { replace: true });
    };

    const handlePrint = async () => {
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML = getReviewAuthPrintPopUpTemplate(authorizationData, pharmacyLocation);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };

    const handleLogDispense = async () => {
        navigate('/pharmacy/logdispensehome', { replace: true });

    };

    const isLogDispenseBtnActive = () => {
        if (authorizationStatus) {
            if (
                authorizationStatus.toLowerCase() === 'active'
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const emptyRow = () => {
        return (
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        );
    };

    return (
        <div className={classes.detailedPatientAuthData}>
            <div className={classes.topRow}>
                <table className="leftCol">
                    <tr className="field">
                        <td className="label">Patient's Date of Birth:</td>
                        <td className="value">{patientDob && formatDate(patientDob)}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">Risk Category: </td>
                        <td className="value" style={{ width: 200 }}>
                            {patientRiskCategoryName && patientRiskCategoryName}
                        </td>
                    </tr>
                    <tr className="field">
                        <td className="label">Patient's Last Survey Date:</td>
                        <td className="value">{patientLastSurveyDate && formatDate(patientLastSurveyDate)}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">
                            Patient's Next Survey Available <br />
                            On:{' '}
                        </td>
                        <td className="value">
                            {patientNextEarliestSurveyDate && formatDate(patientNextEarliestSurveyDate)}
                        </td>
                    </tr>
                </table>

                <table className="rightCol">
                    <tr className="field">
                        <td className="label">Confirmation Number:</td>
                        <td className="value"> {authorizationStatusId===10 && confirmationNumber && trimPrefix(confirmationNumber)} </td>
                    </tr>
                    <tr className="field">
                        <td className="label">
                            Date Confirmation <br /> Number was Obtained:{' '}
                        </td>
                        <td className="value"> {authorizationStatusId===10 && dispensationSequences && dispensationSequences.length > 0 && formatDate(dispensationSequences[0]?.dispensationDate)} </td>
                    </tr>
                    <tr className="field">
                        <td className="label">Cancellation Number: </td>
                        <td className="value">{(authorizationStatusId===9 || authorizationStatusId===12) && cancellationNumber && cancellationNumber}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">Cancellation Date: </td>
                        <td className="value">{(authorizationStatusId===9 || authorizationStatusId===12) && cancelledDate && formatDate(cancelledDate)}</td>
                    </tr>
                </table>
            </div>

            <div className={classes.middleRow}>
                <div className="dispenseTable">
                    <p className="label">Dispense Details</p>
                    <table>
                        <tr>
                            <th>Product Identification</th>
                            <th>Quantity</th>
                        </tr>
                        {dispensationSequences && dispensationSequences.length > 0
                            ? dispensationSequences.filter((each)=>{
                                return !each.deactivationDate;
                            }).map((each) => {
                                  return (
                                      <tr key={each.ndcId}>
                                          <td>{each.ndcId?.ndcDescription && each.ndcId?.ndcDescription}</td>
                                          <td>{each?.dispensationQuantity && each?.dispensationQuantity}</td>
                                      </tr>
                                  );
                              })
                            : emptyRow()}
                    </table>
                </div>
                <div className="pharmacyHyperLink" style={{ position: 'relative',  left: '14px' }}>
                    <p className="label">Pharmacy Location:</p>
                    {authorizationStatus==="Confirmed" && pharmacyDetails && (
                        <div>
                            <p className="pharmacyName" onClick={pharmacyDetailsToggler}>
                                {/* {pharmacyDetails?.pharmacyName} */}
                                <span>{pharmacyDetails?.pharmacyNCPDP ? pharmacyDetails?.pharmacyNCPDP : pharmacyDetails?.pharmacyDEA }</span> - <span>{pharmacyDetails?.pharmacyInfo?.postalCode?.city}</span>, <span>{pharmacyDetails?.pharmacyInfo?.postalCode?.state.stateCode}</span>
                            </p>
                            {isPharmacyDetailsClicked && (
                                <div className="pharmacyDetailsPopUp">
                                    <table>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{pharmacyDetails?.pharmacyName}</td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.addressLine1
                                                        ? pharmacyDetails.pharmacyInfo.addressLine1 + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.addressLine2
                                                        ? pharmacyDetails.pharmacyInfo.addressLine2 + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.addressLine3
                                                        ? pharmacyDetails.pharmacyInfo.addressLine3 + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.addressLine4
                                                        ? pharmacyDetails.pharmacyInfo.addressLine4 + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.postalCode?.city
                                                        ? pharmacyDetails.pharmacyInfo.postalCode.city + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.postalCode?.state?.stateName
                                                        ? pharmacyDetails.pharmacyInfo.postalCode.state.stateName + ' '
                                                        : ''}
                                                </span>
                                                <span>
                                                    {pharmacyDetails?.pharmacyInfo?.postalCode?.postalCode1
                                                        ? pharmacyDetails.pharmacyInfo.postalCode.postalCode1 + ' '
                                                        : ''}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td>{pharmacyDetails?.pharmacyInfo?.pharmacyPhoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Fax:</td>
                                            <td>{pharmacyDetails?.pharmacyInfo?.pharmacyFaxNumber}</td>
                                        </tr>
                                    </table>
                                    <div
                                        className="closeIcon"
                                        onClick={() => {
                                            setIsPharmacyDetailsClicked(false);
                                        }}
                                    >
                                        <FaWindowClose color="#4b6075" />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>         
        </div>
        
    );
};

export default DetailedPatientAuthData;
