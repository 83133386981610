
export const Firstlist = [
    `To sort your list of notifications within the dashboard click the Filter by dropdown menu. You can filter by REMS Program or Prescriber Last Name:
    <div style=\"margin-left:20px\"> <li>If you choose to filter by REMS Program, you must select a REMS Program from the dropdown menu to the right of the Filter by menu.</li> </div>
    <div style=\"margin-left:20px\"> <li>If you choose to filter by Prescriber Last Name, you must enter the desired Prescriber Last Name in the text box to the right of the Filter by menu.</li> </div>
    `,
    `To execute any of the Filter by functions, click the Find button.`,
    `To reset or remove the filter, click the Reset button.`,
    `Ten records are displayed per page. You can access additional pages by using the navigation provided at the bottom left of your screen:`,
    `Columns that display the blue up and down arrows can be sorted either alphabetically or chronologically depending on the data in the column. The blue down arrow is for descending order. The blue up arrow is for ascending order. The default view is sorted by Authorization Number in descending order.`,
    `Patient Name (Last) - The patient name is displayed in last name, first name format. The list will only contain the names of patients that have an action item or notification attached to their record.`,
    `REMS Program - The REMS Program name is displayed. If a patient is currently enrolled for more than one REMS Program and has notifications for both REMS Programs, there will be 2 listings on the dashboard for the patient.`,
    `Authorization Number - The most recent Authorization Number for this patient is displayed.`,
    `Expires - The date that the authorization is scheduled to expire is displayed.
     <div style=\"margin: 15px 20px 5px 20px\"> <li>! Status and Action Required - Depending upon the Authorization Status, a required action will be displayed in this column:</li> </div>`,
];

export const Seclist = [
    `Search for a patient by using the Filter by function or the navigation controls at the bottom left of the screen.`,
    `If you choose to use the Filter by function, you must select one of the following sort criteria from the dropdown menu:
    <div style=\"margin-left:20px\"> <li>Patient Last Name - Enter the last name of the patient in the search box to the right of the Filter by dropdown menu.</li> </div>
    <div style=\"margin-left:20px\"> <li>Patient Identification Number - Enter the 9-digit patient identification number in the search box to the right of the Filter by dropdown menu.</li> </div>
    <div style=\"margin-left:20px\"> <li>Prescriber Last Name - Enter the desired Prescriber Last Name in the text box.</li> </div>
    `,
    `Click the Find button to execute your search.`,
    `Click the Reset button to clear the search results and/or initiate another search.`,
    `Click on the patient's name to access his/her full profile and treatment history.`,
    `Click the Home button to access the REMS Patient Safety Home screen.`,
    `Please, do not click your browser's Back button as this can cause a page loading or processing error.`,
    `Do you have a question regarding the REMS Patient Safety website for Bristol Myers Squibb-administered REMS programs? Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.`,
];

export const AuthStatus = {
    active: 'Active',
    obsolete: 'Obsolete',
    confirmed: 'Confirmed',
    expired: 'Expired',
    mismatched: 'Mismatched',
    psr: 'Patient Survey Required',
    prescSR: 'Prescriber Survey Required',
    newPatient: 'New Patient'
};

export const DashboardLinks = {
    actionItem: 'prescription-action-items',
    findAPatient: 'find-a-patient',
    calendar: 'prescriber-calendar',
    reviewAuth: 'review-authorization',
    reports: 'reports',
    dashboard: 'dashboard',
};
