export const ppfConfirmationContentES = {
    body:[
        // {
        //     order:1,
        //     content:`The patient is now successfully enrolled for Lenalidomide REMS. Please follow the steps detailed below:`
        // },
        {
            order:1,
            content:`1. Si se requiere una encuesta del paciente, por favor pídale al paciente que complete la encuesta ahora. `
        },
        {
            order:2,
            content:`2. Complete la encuesta de Prescriptor. `
        },
        {
            order:2,
            content:`3. Escriba la receta y envíela a la farmacia. `
        },
       

    ],
    subheader:'Confirmación del acuerdo entre paciente y médico',
}