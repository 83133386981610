import { useAppSelector } from 'store';
import { reqBody } from './../constants/enrollment';

const locObj = reqBody.prescriberLocations[0];


export const setRequestBody = (presData, PrimaryOff, SecondaryOff, userName, prescriberId, product, prescriberDetails) => {

  const existingId = prescriberDetails?.addressList?.filter((item) => item.primaryLocation === 'Y')

  const editedData = {
    ...existingId[0],
    prescriberLocationSequence: 0,
  }

  const checkPrimaryAddEdit = () =>{
    if (Object.keys(prescriberDetails).length > 0 && Object.keys(PrimaryOff).length > 0) {
      const prescriberAddressDetailsData = {
        officeName: prescriberDetails?.officeName || '',
        attention: prescriberDetails?.attention || '',
        officeAddress: prescriberDetails?.addressList?.find(i=>i.primaryLocation==='Y')?.address || '',
        city: prescriberDetails?.city || '',
        state: prescriberDetails?.state || '',
        zip: prescriberDetails?.zip || '',
        phone: prescriberDetails?.phone || '',
        fax: prescriberDetails?.fax || '',
        email: prescriberDetails?.email || '',
      }
      const primaryOffDetails = {
        officeName: PrimaryOff?.prescriberOfficeName || '',
        attention: PrimaryOff?.attention || '',
        officeAddress: PrimaryOff?.addressLine1 || '',
        city: PrimaryOff?.postalCode?.city || '',
        state: PrimaryOff?.postalCode?.state?.stateCode || '',
        zip: PrimaryOff?.postalCode?.postalCode1 || '',
        phone: PrimaryOff?.phoneNumber || '',
        fax: PrimaryOff?.faxNumber || '',
        email: PrimaryOff?.emailAddress || '',
      }

      const propertiesToCompare = ['officeName', 'attention', 'officeAddress', 'city', 'state', 'zip', 'phone', 'fax', 'email']
      const isPrimaryAddEdited = propertiesToCompare?.some((data, index) => primaryOffDetails?.[data] !== prescriberAddressDetailsData?.[data]);
      return isPrimaryAddEdited ? editedData : existingId[0];  
    }
  }
  const location1 = { ...locObj, ...PrimaryOff, id: existingId && existingId.length ? checkPrimaryAddEdit() : null };

  const location2 = { ...locObj, ...SecondaryOff };

  const prescriberLocations = location2.postalCode.postalCode1 !== null ? [{ ...location1 }, { ...location2 }] : [{ ...location1 }];
  const prescriberRegStatuses = [
    {
      "modifiedBy": null,
      "modifiedDate": null,
      "createdBy": null,
      "createdDate": null,
      "channelId": null,
      "id": {
        "prescriberId": null,
        "productCountryId": {
          "productCountryId": null,
          "riskManagementProgram": null,
          "riskManagementIndicator": null,
          "dispenseHoldCheck": null,
          "distributionType": null,
          "productSiteUrl": null,
          "product": {
            "productId": null,
            "productName": product,
            "productCode": null,
            "productTradeName": null,
            "productGenericName": null,
            "comsOpProductId": null,
            "deactivationDate": null,
            "productGroup": {
              "productGroupId": null,
              "productGroupName": null
            },
            "productStatus": null
          }
        }
      },
      "registrationStatus": null,
      "deactivateReasonChangeId": null,
      "reasonForChangeId": null,
      "dateEffective": null,
      "registrationDate": null,
      "initialRegistrationDate": null,
      "prescriberCommTypeId": null,
      "notification21Days": null,
      "notification14Days": null,
      "notification7Days": null,
      "notificationEmail": null,
      "notificationFax": null,
      "notificationEmail2": null,
      "notificationEmail3": null,
      "notifyByEmail": null,
      "notifyByFax": null
    }
  ]

  return { ...reqBody, ...presData, prescriberLocations, prescriberRegStatuses, prescriberId, onlineUserid: null }



}