import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import SessionTimeout from 'components/SessionTimeout';
import moment from 'moment';
import { PatientSurveyFlowType, PatientSurveyScreens } from 'pages/PatientSurvey/PatientSurveyType';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from 'store';
import { patientInformation as PatientInfo, resetPatientSurvey, setPatientData, setPatientRisk, updatePatientSurvey } from 'store/slices/appSlice';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import classes from './PrescriberSurveyConfirmation.module.scss';
import { ProgramConfig } from 'constants/Config/programsConfig';

// import Survey from './../../assets/images/btn_Patient_Survey.png';
// import Print from './../../assets/images/btn_Print.png';

const PrescriberSurveyConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        patientInformation,
        patientResponse,
        patientProgramList: patientArray,
        selectedProgram,
        prescriberDetails,
        prescriberSurveySearchStore,
        patientSurvey,
        patientReactivationSpecialStore,
        prescriptionPaylod
    } = useAppSelector((state) => state.app);

    const { currentUser } = useAppSelector((state) => state.login);

    const [errorMessage, setErrorMessage] = useState('');
    const noInputStyle = { color: '#000' };
    const product = selectedProgram.id;
    const conductResponseSurvey = sessionStorage.getItem('surveyConfirmData') && JSON.parse(sessionStorage.getItem('surveyConfirmData')||'')
    const { t, i18n } = useTranslation();
    const todaysDate=new Date();
    const formattedDate=todaysDate.toLocaleDateString('en-US',{
        month:'long',
        day:'numeric',
        year:'numeric'
    });
    const selectedLanguage = i18n.language;


    const id = selectedLanguage === 'es' ? `es` : '';

    
    const Prescriber =  `${prescriberDetails?.firstName?.length > 0 ? prescriberDetails?.firstName : currentUser.firstName} ${prescriberDetails?.lastName?.length>0 ? prescriberDetails?.lastName : currentUser.lastName}`;
    
    const [conductPrescriberResponse, setConductPrescriberResponse] =  useState<any>(null)
    useEffect(() => {
        setConductPrescriberResponse(conductResponseSurvey)
    }, []);
   
    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        // content:()=>contentToPrint.current,
        documentTitle: "User Name Creation",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    const handlePrintBtn = () => {
        handlePrint(null, () => contentToPrint.current);
    };
    const handleDashboard=()=>{
        navigate('/prescriber/dashboard/')
        sessionStorage.removeItem('surveyConfirmData')
    }

    const handlePatientSurvey =()=>{
        const _eligiableProduct = {           
            "patientExtUniqueId":  prescriberSurveySearchStore?.data !== undefined
                                    ? prescriberSurveySearchStore?.data?.patientExternalUniqueId
                                    : patientResponse.patientExternalUniqueId !== undefined
                                    ? patientResponse.patientExternalUniqueId
                                    : patientReactivationSpecialStore?.data?.patientDTO
                                        ?.patientExternalUniqueId,            
            "products": [
                {
                    "name": selectedProgram.label,
                    "status": "eligible",
                    "surveyType": "I"
                }
            ]
        }
        dispatch(resetPatientSurvey());
        dispatch(updatePatientSurvey({...patientSurvey,
            eligiableProduct:_eligiableProduct,
            localNavigation:PatientSurveyScreens.SelectProduct,
            flowType:PatientSurveyFlowType.PrescriberSurveyPage
        }));
        navigate('/prescriber/patientSurvey', { replace: true });
        sessionStorage.removeItem('surveyConfirmData')
    }

   
    const disclaimer =conductPrescriberResponse?.SurveyDisclaimer || '';
 
    const prevDate = moment(conductPrescriberResponse?.PatientSurveyDate)?.format('MMMM DD, YYYY');
    const nextDate = moment(conductPrescriberResponse?.NextEarliestPatientSurveyDate)?.format('MMMM DD, YYYY');
 
    let modifiedDisclaimer = disclaimer.replace(
        '[DATE1]',
        `${prevDate}`
    );
    modifiedDisclaimer = modifiedDisclaimer.replace(
        '[DATE2]',
        `${nextDate}`
    );
    modifiedDisclaimer = modifiedDisclaimer.replace('<Authorization Number>', conductPrescriberResponse?.AuthorizationNumber.substring(2));
     let modifiedDisclaimerEs = modifiedDisclaimer.replace('<Número de autorización>', conductPrescriberResponse?.AuthorizationNumber.substring(2));
    const htmlString = { __html: i18n.language !== "en" ? modifiedDisclaimerEs :  modifiedDisclaimer };
    
    const routeToPrescriptionForm = () => {
        const patientDto =
            prescriberSurveySearchStore?.data !== undefined
                ? prescriberSurveySearchStore?.data
                : patientResponse.patientExternalUniqueId !== undefined
                ? patientResponse
                : patientReactivationSpecialStore?.data?.patientDTO;
        const surveyData = [{ authorizationNumber: conductPrescriberResponse?.AuthorizationNumber }];
        dispatch(setPatientData({ ...patientDto, surveyData }));
        navigate('/prescriber/prescriptionForm');
        sessionStorage.removeItem('surveyConfirmData')
    }
    
    return (
        <>
            <SessionTimeout />
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                {/* <div className={classes.header}></div> */}
                <div className={classes.productHeader}>
                    {t('prescriberSurveyConfirmation')}
                </div>
                <div className={classes.contentDivider}></div>
                <div className={classes.mainContainer}>
                    <div className={classes.mainContainer_inputs}>
                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="Patient"
                            label={t('todaysDate')}
                            // valueExist={patientInformation?.firstName + ' ' + patientInformation?.lastName || ''}
                            valueExist={formattedDate}
                        />
                         {prescriberSurveySearchStore.data !== undefined ? (
                                            <Input
                                                className={classes.infoInput}
                                                type="text"
                                                noInputStyle={noInputStyle}
                                                labelStyle={noInputStyle}
                                                noInput={true}
                                                name="Patient"
                                                label={t('PatientName')}
                                                valueExist={
                                                    prescriberSurveySearchStore.data?.patientNameFirst +
                                                    ' ' +
                                                    prescriberSurveySearchStore.data?.patientNameLast
                                                }
                                            />
                                        ) : (
                                            <>
                                                {patientResponse.patientNameFirst !== undefined ? (
                                                    <Input
                                                        className={classes.infoInput}
                                                        type="text"
                                                        noInputStyle={noInputStyle}
                                                        labelStyle={noInputStyle}
                                                        noInput={true}
                                                        name="Patient"
                                                        label={t('PatientName')}
                                                        valueExist={patientResponse.patientNameFirst + ' ' + patientResponse.patientNameLast}
                                                    />
                                                ) : (
                                                    <Input
                                                        className={classes.infoInput}
                                                        type="text"
                                                        noInputStyle={noInputStyle}
                                                        labelStyle={noInputStyle}
                                                        noInput={true}
                                                        name="Patient"
                                                        label={t('PatientName')}
                                                        valueExist={patientReactivationSpecialStore?.data?.patientDTO?.patientNameFirst + ' ' + patientReactivationSpecialStore?.data?.patientDTO?.patientNameLast}
                                                    />
                                                )}
                                            </>
                                        )}

                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="authorizationNumber"
                            label={t('authorizationNumber')}
                            valueExist={conductPrescriberResponse?.AuthorizationNumber.substring(2)}
                        />
                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="thisAuthorizationisValidFor"
                            label={t('thisAuthorizationisValidFor')}
                            valueExist={conductPrescriberResponse?.AuthorizationValidFor}
                        />
                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="authorizationStatus"
                            label={t('authorizationStatus')}
                            valueExist={conductPrescriberResponse?.AuthorizationStatus}
                        />
                    </div>
                 <strong> <p dangerouslySetInnerHTML={htmlString} style={{fontSize:'12px'}}></p> </strong>

                    {/* buttons  */}
                    {errorMessage === 'concurrentFailure' ? (
                        <div className={classes.flag_btn}>
                             <Button
                                                clickFunction={() => {
                                                    navigate('/prescriber/MainMenu');
                                                    sessionStorage.removeItem('surveyConfirmData')
                                                    dispatch(PatientInfo({}));
                                                    dispatch(setPatientRisk(null));
                                                    dispatch(setPatientData({}));
                                                }}
                                                className={getBtnClass(selectedProgram.id)}
                                                width={i18n.language === 'es' ? 240 : 135}
                                                height={48}
                                                size={18}
                                                type="text"
                                                buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                                               
                                            ></Button>
                        </div>
                    ) : (
                        <>
                            <div className={classes.btn_group}>
                                  <Button
                                    clickFunction={()=>handlePatientSurvey()}
                                    className={getBtnClass(selectedProgram.id)}
                                    width={i18n.language === 'es' ? 140 : 186}
                                    height={48}
                                    size={17}
                                    type="text"
                                    buttonname={i18n.language === 'es' ? buttonContent.es.patSurvey : buttonContent.en.patSurvey}  
                                ></Button>
                                <Button
                                    clickFunction={routeToPrescriptionForm}
                                    className={getBtnClass(selectedProgram.id)}
                                    width={188}
                                    height={48}
                                    size={17}
                                    type="text"
                                    buttonname={buttonContent.en.writePres}  
                                ></Button>
                                <Button
                                    clickFunction={handlePrintBtn}
                                    className={getBtnClass(selectedProgram.id)}
                                    width={106}
                                    height={48}
                                    size={17}
                                    type="text"
                                    buttonname={i18n.language === 'es' ? buttonContent.es.print : buttonContent.en.print}  
                                ></Button>
                                <Button
                                    clickFunction={handleDashboard}
                                    className={getBtnClass(selectedProgram.id)}
                                    width={256}
                                    height={48}
                                    size={17}
                                    type="text"
                                    buttonname={buttonContent.en.gotoPresDashboard}  
                                ></Button>
                            </div>
                            <div className={`${classes['btn_text_' + id]}`}>
                                <p>Patient Survey</p>
                                <p>Print</p>
                            </div>
                        </>
                    )}
                </div>
                <div style={{ display: 'none' }}>
                    <div ref={contentToPrint}>
                        <div className={classes.printContent}>
                            <div className={classes.headerprint}>{t('prescriberSurveyConfirmation')}</div>
                            <div className={classes.tblcont}>
                                <table className={classes.tableprint}>
                                    <tbody>
                                        <tr>
                                            <td className={classes.tableLabel}>
                                                <strong>{t('todaysDate')}:</strong>{' '}
                                            </td>
                                            <td className={classes.tableValue}>{formattedDate}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLabel}>
                                                <strong>{t('PatientName')}:</strong>{' '}
                                            </td>
                                            <td className={classes.tableValue}>
                                                    {prescriberSurveySearchStore.data !== undefined ? (
                                            <p>
                                                   {prescriberSurveySearchStore.data?.patientNameFirst +
                                                    ' ' +
                                                    prescriberSurveySearchStore.data?.patientNameLast}
                                            </p>
                                        ) : (
                                            <>
                                                {patientResponse.patientNameFirst !== undefined ? (
                                                    <p> 
                                                        {patientResponse.patientNameFirst + ' ' + patientResponse.patientNameLast}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {patientReactivationSpecialStore?.data?.patientDTO?.patientNameFirst + ' ' + patientReactivationSpecialStore?.data?.patientDTO?.patientNameLast}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLabel}>
                                                <strong>{t('authorizationNumber')}:</strong>{' '}
                                            </td>
                                            <td className={classes.tableValue}>{conductPrescriberResponse?.AuthorizationNumber.substring(2)}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLabel}>
                                                <strong>{t('thisAuthorizationisValidFor')}:</strong>{' '}
                                            </td>
                                            <td className={classes.tableValue}>{conductPrescriberResponse?.AuthorizationValidFor}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tableLabel}>
                                                <strong>{t('authorizationStatus')}:</strong>{' '}
                                            </td>
                                            <td className={classes.tableValue}>{conductPrescriberResponse?.AuthorizationStatus}</td>
                                        </tr>
                                        
                                    </tbody>
                                   
                                </table>
                                <p dangerouslySetInnerHTML={htmlString} style={{fontSize:'12px',margin:'5px 30px 30px 30px'}}></p>
                            </div>
                           
                        </div>
                        <p style={{textAlign:'center'}}>Bristol Myers Squibb</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrescriberSurveyConfirmation;
