import { useTranslation } from 'react-i18next';
import classes from './productBackground.module.scss'
import { ReactNode } from 'react';
import Button from 'components/Button';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import { ProgramConfigByName } from 'constants/Config/programsConfig';
interface IProductBackground {
    product: string;
    subTitle?: string;
    children: ReactNode;
    IsSpanish?: boolean;
    handleButtonClick?: () => void;
}
const ProductBackground = ({ product, subTitle, children,
    IsSpanish = false,
    handleButtonClick, }: IProductBackground) => {
    const { t, i18n } = useTranslation();

    const getButtonClassName = (product) => {      
        return ProgramConfigByName(product)?.id
        
    }
    return (
        <>
            <>
                <div className={`${classes.ppafSelection} ${classes[product?.toLocaleLowerCase()]} `}>
                    <div className={`${classes.mainContainer} ${classes[i18n.language]}`} >
                        <div className={`${classes.headerContainer} `}>
                            <div className={classes.titleButtonContainer}>
                                {product && <span className={classes.generalProgramlogo}
                                style={{
                                    backgroundImage: `url(${require(`../../assets/images/${ProgramConfigByName(product).headerImage}`)})`
                                }}
                                ></span>}
                                {IsSpanish && (
                                    <>
                                   <div className={classes.spanishButton}>
                                    <Button
                                        clickFunction={handleButtonClick}
                                        className={getBtnClass(getButtonClassName(product))}
                                        width={130}
                                        height={52}
                                        size={18}
                                        type="text"
                                        buttonname={ buttonContent.es.toSpanish}
                                    ></Button>
                                    </div>
                                    </>
                                    
                                    )}
                            </div>
                            <div className={classes.generalHeaderDivider}
                                style={{
                                    borderColor: `${ProgramConfigByName(product)?.color}`
                                }}
                            ></div>
                        </div>
                        <div className={classes.productHeader}>{subTitle}</div>

                        <div className={classes.contentDivider}></div>
                        <div className={classes.bodyContainer}>
                            <div className={classes.bodyContent}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default ProductBackground;

