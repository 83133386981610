import React, { useEffect,useState } from 'react';
import classes from './ContactUs.module.scss';
import { FooterCopyRight } from 'components/Footer';
import { useAppSelector } from 'store';
import MigratePopUp from 'components/MigratePopUp';




const ContactUs = () => {
    const [showModal, setShowModal] = useState(true);
    const { role } = useAppSelector((state) => state.app);
    let contactUsText = sessionStorage.getItem("contactUsBody") || 'REMS Patient Safety website';
    return (
        <>
        <MigratePopUp showModal={showModal} setShowModal={setShowModal} />
        <div className={classes.layout}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerContainer}>
                        <span className={classes.icon}></span>
                        <div className={classes.title}>Contact Us</div>
                    </div>
                </div>
                <div className={classes.helpContainer}>
                    <div className={classes.bold}>
                        If you have questions concerning the {`${contactUsText}`} for Bristol Myers
                        Squibb-administered REMS programs, please call the REMS Call Center at 1-888-423-5436 for
                        assistance Monday through Friday 8:00 AM to 8:00 PM ET.
                    </div>
                </div>
                <FooterCopyRight />
            </div>
        </div>
        </>
        
    );
};

export default ContactUs;
