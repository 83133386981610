import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import classes from './patientListPagination.module.scss';
import { prescriberPatientsSearch } from 'store/slices/appSlice';

const PatientListPagination = ({ isFiltered, isFindPatientTab, isSorted, setIsFiltered }) => {
    const dispatch = useAppDispatch();
    const patientList = useAppSelector((state) => state.app.patientList);
    const { totalPages } = patientList;

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPageList, setCurrentPageList] = useState<any>({});
    const [pageGrid, setPageGrid] = useState<any>([]);

    const totalPageSets = pageGrid.length;
    const { prescriberDashboardFindPatientIsLoading } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails.prescriberId);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberNonPrescriberId = !nonPrescriber ? prescriberId : currentUser.nonPrescriberId;

    useEffect(() => {
        const pageNumbersList: any = [];
        const maxPagesToDisplayAtTime = 5;
        const pagesArrayBuilder = () => {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbersList.push(i);
            }
            return pageNumbersList;
        };
        const pagesArray = pagesArrayBuilder();
        const subArraysOf5Pages = dividePagesIntoSetOf5(pagesArray, maxPagesToDisplayAtTime);
        if (subArraysOf5Pages.length >= 1) {
            setPageGrid(subArraysOf5Pages);
        }
    }, [totalPages]);

    useEffect(() => {
        if (pageGrid.length >= 1) {
            setCurrentPageList({ pages: pageGrid[0], set: 1 });
        }
    }, [pageGrid]);

    useEffect(() => {
        if (isFiltered.isFilterApplied) {
            if (!isSorted.isSorted) {
                const payload = { ...isFiltered.filterPayload, pageNumber: currentPageNumber - 1 };
                dispatch(prescriberPatientsSearch(payload));
            } else {
                const payload = {
                    ...isFiltered.filterPayload,
                    pageNumber: currentPageNumber - 1,
                    sortByField: isSorted.sortByField,
                    sortByOrder: isSorted.sortByOrder,
                };
                dispatch(prescriberPatientsSearch(payload));
            }
        }

        if (!isFiltered.isFilterApplied && !isSorted.isSorted) {
            defaultListView();
        }

        if (isSorted.isSorted && !isFiltered.isFilterApplied) {
            const payload = {
                prescriberNonPrescriberId: prescriberNonPrescriberId,
                isPrescriber: !nonPrescriber,
                pageNumber: currentPageNumber - 1,
                sortByField: isSorted.sortByField,
                sortByOrder: isSorted.sortByOrder,
            };
            dispatch(prescriberPatientsSearch(payload));
        }
    }, [currentPageNumber, isSorted]);

    const defaultListView = () => {
        const payload = {
            prescriberNonPrescriberId: prescriberNonPrescriberId,
            isPrescriber: !nonPrescriber,
            pageNumber: currentPageNumber - 1,
            sortByField: 'PatientNameLast',
            sortByOrder: 'ASC',
        };
        dispatch(prescriberPatientsSearch(payload));
    };

    useEffect(() => {
        if (isFindPatientTab || !isFiltered.isFilterApplied) {
            pageGrid[0] && setCurrentPageNumber(pageGrid[0][0]);
            pageGrid[0] && setCurrentPageList({ pages: pageGrid[0], set: 1 });
        }
    }, [isFindPatientTab, isFiltered, pageGrid]);

    useEffect(() => {
        if (isFindPatientTab) {
            setIsFiltered({ isFilterApplied: false, filterPayload: null });
            defaultListView();
        }
    }, [isFindPatientTab]);

    const dividePagesIntoSetOf5 = (array, groupSize) => {
        if (array.length <= groupSize) {
            return [array];
        } else {
            const subArrays: any = [];
            for (let i = 0; i < array.length; i += groupSize) {
                const subArray = array.slice(i, i + groupSize);
                subArrays.push(subArray);
            }
            return subArrays;
        }
    };

    const currentPageClickHandler = (number) => {
        setCurrentPageNumber(number);
    };

    const previousPageClickHandler = (e) => {
        e.stopPropagation();
        if (currentPageNumber !== 1) {
            setCurrentPageNumber(currentPageNumber - 1);
            if (currentPageNumber == currentPageList.pages[0]) {
                prevSetLoaderHelper(true);
            }
        } else {
            return;
        }
    };

    const nextPageClickHandler = (e) => {
        e.stopPropagation();
        if (currentPageNumber !== totalPages) {
            setCurrentPageNumber(currentPageNumber + 1);
            if (currentPageNumber == currentPageList.pages[currentPageList.pages.length - 1]) {
                nextSetLoderHelper();
            }
        } else {
            return;
        }
    };

    const nextSetLoderHelper = () => {
        if (currentPageList.set < totalPageSets) {
            setCurrentPageList({ pages: pageGrid[currentPageList.set - 1 + 1], set: currentPageList.set + 1 });
            const firstNumberOfCurrentSet = pageGrid[currentPageList.set - 1 + 1][0];
            setCurrentPageNumber(firstNumberOfCurrentSet);
        }
    };

    const loadNextSet = (e) => {
        e.stopPropagation();
        nextSetLoderHelper();
    };

    const prevSetLoaderHelper = (prevBtnClick = false) => {
        if (currentPageList.set > 1) {
            setCurrentPageList({ pages: pageGrid[currentPageList.set - 1 - 1], set: currentPageList.set - 1 });
            const firstNumberOfCurrentSet = prevBtnClick
                ? pageGrid[currentPageList.set - 1 - 1][pageGrid[currentPageList.set - 1 - 1].length - 1]
                : pageGrid[currentPageList.set - 1 - 1][0];
            setCurrentPageNumber(firstNumberOfCurrentSet);
        }
    };

    const loadPrevSet = (e) => {
        e.stopPropagation();
        prevSetLoaderHelper();
    };

    const renderPagination = () => {
        if (prescriberDashboardFindPatientIsLoading) {
            return null;
        } else {
            return (
                <>
                    <nav>
                        {((currentPageList?.pages && currentPageList?.pages?.length > 1) ||
                            (pageGrid.length && pageGrid.length > 1 && pageGrid[pageGrid.length - 1].length == 1)) && (
                            <ul className={classes.pagination}>
                                {currentPageNumber > 1 && (
                                    <li>
                                        <a onClick={previousPageClickHandler}>
                                            <i className={`${classes.icon} ${classes.leftArrow}`}></i> prev
                                            {currentPageList.set > 1 && <span onClick={loadPrevSet}>&nbsp;...</span>}
                                        </a>
                                    </li>
                                )}
                                {currentPageList?.pages?.map((each) => {
                                    return (
                                        <li
                                            key={each}
                                            className={`${
                                                currentPageNumber == each ? `${classes.active}` : 'inactive'
                                            }`}
                                        >
                                            <a
                                                onClick={() => {
                                                    currentPageClickHandler(each);
                                                }}
                                            >
                                                <span>{each}</span>{' '}
                                                {currentPageList.pages &&
                                                    each !==
                                                        currentPageList.pages[currentPageList.pages.length - 1] && (
                                                        <span className="rightStand">|</span>
                                                    )}
                                            </a>
                                        </li>
                                    );
                                })}
                                {currentPageNumber < totalPages && (
                                    <li>
                                        <a onClick={nextPageClickHandler}>
                                            {currentPageList.set < totalPageSets && (
                                                <span onClick={loadNextSet}>...&nbsp;</span>
                                            )}
                                            next <i className={`${classes.icon} ${classes.rightArrow}`}></i>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        )}
                    </nav>
                </>
            );
        }
    };

    return <>{renderPagination()}</>;
};

export default PatientListPagination;
