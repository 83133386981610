import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../components/ErrorModal';
import styles from './prescriberSelection.module.scss';
import PageBackground from '../../components/PageBackground';
import { PRESCRIBERSELECTIONLIST } from '../../constants/Config/textConfig';
import { useAppDispatch } from 'store';
import { handlePrescriber } from 'store/slices/appSlice';
import { Role } from 'constants/Config/appConfig';
import SessionTimeout from 'components/SessionTimeout';


const PrescriberRadio = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [selecteRadioOption, setSelectedOption] = useState('');

    const handleInputChange = (event: any) => {
        setSelectedOption(event.target.value);
    };

    const handleContinue = () => {
        if (selecteRadioOption === '') {
            setError('Please select either the Prescriber or Non-prescriber option.');
            return;
        }
        sessionStorage.setItem('role',selecteRadioOption === '1' ? Role.Prescriber : Role.NonPrescriber);
        sessionStorage.removeItem('pass');
        sessionStorage.removeItem('accountData');
        sessionStorage.removeItem("productaccDetails");
        sessionStorage.removeItem("productSecDetails");
        dispatch(handlePrescriber(selecteRadioOption === '1' ? Role.Prescriber : Role.NonPrescriber));
        navigate('/prescriber/createaccount', { replace: true });
    };

    const handleErrorBtn = () => {
        setError('');
    };

    return (
        <>
            <SessionTimeout/>
            <PageBackground label="Create User Name and Password" btn={true} handleClick={handleContinue}>
                <div className={styles.bannerContent}>
                    <h4 className={styles.subHeading}>Please enter your details and click the Continue button.</h4>
                    <p className={styles.selectOption}>I am</p>
                    {PRESCRIBERSELECTIONLIST.map((row) => (
                        <div key={row.id} className={`${styles.flex} ${styles.pd5}`}>
                            <input
                                onChange={handleInputChange}
                                checked={selecteRadioOption === row.id}
                                type="radio"
                                className="radio"
                                name={row.label}
                                value={row.id}
                                id={row.id}
                            />
                            <div className={styles.ml}>
                                <label className={styles.subHeading} htmlFor={row.label}>
                                    {row.label}
                                </label>
                                <p className={styles.labelContent}>{row.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </>
    );
};

export default PrescriberRadio;
