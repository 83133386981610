import moment from 'moment';
import classes from './PatientDetails.module.scss';
import { useState } from 'react';
import { axiosInstanceSurvey } from 'constants/Config/axiosConfig';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import PatientSurveyPopup from './PopupSurvey';

const PatientInfo = ({ patientDto }) => {
    const [openModal, setOpenModal] = useState(false);
    const [surveyInfo, setSurveyInfo] = useState([]);
    const { selectedPatient } = useSelector((state: RootState) => state.app);

    const getFormattedDob = () => moment(patientDto?.patientDob, 'DD-MMM-YYYY').format('MMM DD, YYYY');
    const getRiskCategory = () => patientDto?.patientRiskCategory?.find((risk) => !risk.endDate)?.riskCategoryName;

    const handlepopup = async () => {
        try {
            const resp = await axiosInstanceSurvey.get(
                `/v1/surveys/get-patient-survey-dates?patientId=${selectedPatient.patientId}&prescriberId=${selectedPatient.assocPrescriberId}`);
            if (resp.data.statusCode === 200) {
                setSurveyInfo(resp.data.data)
                setOpenModal(true);
            }else if(resp.data.statusCode === 204){
                setSurveyInfo([])
                setOpenModal(true);
            }
        }
        catch (err) {
            console.error('Api Error');
        }
    };

    return (
        <div className={classes.patient_info}>
            <p>
                Patient Identification Number: <span>{patientDto.patientExternalUniqueId}</span>
            </p>
            <p>
                Date of Birth: <span>{getFormattedDob()}</span>
            </p>
            <p>
                Risk Category: <span>{getRiskCategory()}</span>
            </p>
            <p>
                <a tabIndex={0} onClick={() => handlepopup()} style={{ cursor: 'pointer' }}>Patient Survey Dates</a>
            </p>
            {openModal && <PatientSurveyPopup surveyInfo={surveyInfo} setOpenModal={setOpenModal} />}
        </div>
    );
};

export default PatientInfo;
