import React, { useRef, useState } from 'react';
import classes from './cancelModal.module.scss';
import Button from 'components/Button';

interface ModalProps {
	handleContinue: () => void;
	handleCancel:() => void;
}


const CancelModal: React.FC<ModalProps> = ({ handleContinue,handleCancel }) => {
	const modalRef = useRef<HTMLDialogElement | null>(null);

	return (
		<div className={classes.modalContainer}>
			<dialog ref={modalRef} className={classes.mainContainer}>
				<div className={classes.whiteInfoContainer}>
					<div className={classes.content}>
					<><b>
						<div>If you cancel the Log Dispense, all your entered information will be lost.</div>
						<div style={{margin:'10px 0px 10px 0px'}}>Select Continue to proceed to the Home Page.</div>
						</b></>

					</div>
					<div className={classes.btnContainer}>
						<Button
							clickFunction={handleContinue}
							className="buttonStyles"
							type="text"
							width={130}
							height={39}
							size={18}
							buttonname="Continue"
						/>
						<Button
							clickFunction={handleCancel}
							className="buttonStyles"
							type="text"
							width={130}
							height={39}
							size={18}
							buttonname="Cancel"
						/>
					</div>
				</div>
			</dialog>
		</div>
	);
};

export default CancelModal;
