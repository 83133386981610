import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import indexedDBStore from 'store/indexedDBStore';
import { showPV0030Msg } from 'store/slices/appSlice';
import { encryptData } from 'utils/encryptionWrapper';
import { APIURLS } from './appConfig';

const {
    BASE_URL_EXP,
    BASE_URL_ORC,
    BASE_URL_PDF,
    BASE_URL,
    COGNITO_USERNAME,
    COGNITO_USERDETAILS,
    BASE_URL_AUTH,
    BASE_URL_PATIENT,
    BASE_URL_PATIENT_ORC,
    BASE_URL_VIEW_PPAF,
    BASE_URL_SEARCH,
    BASE_URL_SURVEY,
    BASE_URL_PHARMACY,
    BASE_URL_PRESCRIBER,
    BASE_URL_ORCHAPI,
    BASE_URL_PRESCRIBER_UNSECURE,
    BASE_URL_PHARMACY_UNSECURE,
    BASE_URL_SURVEY_UNSECURE,
    BASE_URL_PRESCRIBER_UNSECURE_VALIDATE_PHARMACY,
    BASE_URL_SEARCH_UNSECURE,
    BASE_URL_ENTERPRISE_UNSECURE,
} = APIURLS;
export const axiosInstance = axios.create({
    baseURL: BASE_URL_EXP,
});

export const axiosInstanceSearch = axios.create({
    baseURL: BASE_URL_SEARCH,
});

export const axiosInstanceSurvey = axios.create({
    baseURL: BASE_URL_SURVEY,
});

export const axiosInstancePharmacy = axios.create({
    baseURL: BASE_URL_PHARMACY,
});

export const axiosInstancePrescriber = axios.create({
    baseURL: BASE_URL_PRESCRIBER,
});
export const axiosInstanceNonPrescriber = axios.create({
    baseURL: BASE_URL_PRESCRIBER,
});

export const axiosInstance_2 = axios.create({
    baseURL: BASE_URL_PATIENT,
});

export const axiosInstance_PatientReactivation = axios.create({
    baseURL: BASE_URL_PATIENT_ORC,
});
export const axiosInstance_Patient_PartialPPAF = axios.create({
    baseURL: BASE_URL_PATIENT_ORC,
});
export const axiosInstance_PatientViewPPAF = axios.create({
    baseURL: BASE_URL_VIEW_PPAF,
});

export const axiosInstance_ORC = axios.create({
    baseURL: BASE_URL_ORC,
});

export const axiosInstance_PDF = axios.create({
    baseURL: BASE_URL_PDF,
});

export const axiosInstanceNewUrl = axios.create({
    baseURL: BASE_URL,
});
export const axiosInstanceCognitoUrl = axios.create({
    baseURL: COGNITO_USERNAME,
});

export const axiosInstance_AUTH_COGNITO = axios.create({
    baseURL: COGNITO_USERDETAILS,
});

export const axiosInstance_AUTH = axios.create({
    baseURL: BASE_URL_AUTH,
});

export const axiosInstance_ORCHAPI = axios.create({
    baseURL: BASE_URL_ORCHAPI,
});
// unsecure
export const axiosInstancePrescriberUnsecure = axios.create({
    baseURL: BASE_URL_PRESCRIBER_UNSECURE,
});
export const axiosInstanceValidatePharmacyUnsecure = axios.create({
    baseURL: BASE_URL_PRESCRIBER_UNSECURE_VALIDATE_PHARMACY,
});
export const axiosInstanceSearchUnsecure = axios.create({
    baseURL: BASE_URL_SEARCH_UNSECURE,
});

export const axiosInstanceSurveyUnsecure = axios.create({
    baseURL: BASE_URL_SURVEY_UNSECURE,
});

export const axiosInstancePharmacyUnsecure = axios.create({
    baseURL: BASE_URL_PHARMACY_UNSECURE,
});
export const axiosInstanceEnterpriseUnsecure = axios.create({
    baseURL: BASE_URL_ENTERPRISE_UNSECURE,
});

const apiInstances = [
    axiosInstanceNewUrl,
    axiosInstanceSearch,
    axiosInstance_ORC,
    axiosInstance,
    axiosInstancePharmacy,
    axiosInstanceSurvey,
    axiosInstance_2,
    axiosInstance_PatientReactivation,
    axiosInstance_Patient_PartialPPAF,
    axiosInstance_PatientViewPPAF,
    axiosInstance_PDF,
    axiosInstance_ORCHAPI,
    axiosInstancePrescriber,
    axiosInstance_AUTH,
    axiosInstancePharmacyUnsecure,
    axiosInstancePrescriberUnsecure,
    axiosInstanceSurveyUnsecure,
    axiosInstanceEnterpriseUnsecure,
    // axiosInstanceValidatePharmacyUnsecure
];

const urlToBeOmitted = [    
    '/referencedata',
    'validate/address',
    '/validate-access-code',
    '/surveys/patient',
    '/check-ppaf-flag',
    '/setnewpassword'
];
const urlStringOmitted = ['/diagnosis'];
export const useAxiosInterceptor = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isEncryptFlag = JSON.parse(process.env.REACT_APP_ENCRYPTION_FLAG || true);
    let canceltoken;
    const group = window.location.href?.includes('pharmacy') ? 'pharmacy' : 'prescriber';
    const systemErrPath = `/${group}/SystemError`;
    const systemloginPath = `/${group}/login`;
    const createNewToken = () => {
        canceltoken = axios.CancelToken.source();
    };
    const reqInterceptorToken = {
        request: async (config) => {
            if (config) {
                createNewToken();
                const loginState = await indexedDBStore.getItem(`${group}User`);
                const token = loginState?.currentUser?.accessToken;
                if (isEncryptFlag) {
                    if (!urlToBeOmitted.some((api) => config?.url?.includes(api)) && config?.baseURL && token) {
                        if (config.method && config.method.toUpperCase() !== 'GET') {
                            if (config.data && !config.headers['X-Encrypted']) {
                                if (config.data) {
                                    try {
                                        console.log('Data before encryption:', config.data);
                                        const encryptedData = encryptData(config.data);
                                        console.log('Data after encryption:', encryptedData);
                                        config.data = encryptedData;
                                        config.headers['X-Encrypted'] = 'true';
                                        config.headers['Content-Type'] = 'text/plain';
                                    } catch (error) {
                                        console.error('Encryption failed:', error);
                                        return Promise.reject(error);
                                    }
                                }
                            }
                            if (config.url && config.url.includes('?') && !config.headers['X-Encrypted']) {
                                if (
                                    !urlStringOmitted.some((api) => config?.url?.includes(api)) &&
                                    config.url &&
                                    config.url.includes('?')
                                ) {
                                    try {
                                        const [baseUrl, queryString] = config.url.split('?');
                                        if (queryString) {
                                            console.log(' before encryption:', queryString);
                                            const encryptedQueryString = encryptData(queryString);
                                            console.log(' after encryption:', encryptedQueryString);
                                            config.url = `${baseUrl}?${encryptedQueryString}`;
                                            config.headers['X-Encrypted'] = 'true';
                                            config.headers['Content-Type'] = 'text/plain';
                                            if (!config.data) {
                                                config.data = '';
                                            }
                                        }
                                    } catch (error) {
                                        console.error('Encryption failed:', error);
                                        return Promise.reject(error);
                                    }
                                }
                            }
                        }
                    }
                }
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                } else if (config?.baseURL.includes('/auth/')) {
                    canceltoken.cancel('Routing to login');
                    navigate(systemloginPath);
                    return new Promise(() => {});
                }
                config.cancelToken = canceltoken.token;
                return config;
            }
        },
        error: (error) => Promise.reject(error),
    };

    const applyInterceptorInstance = (instance) => {
        instance.interceptors.request.use(reqInterceptorToken.request, reqInterceptorToken.error);
    };
    apiInstances.forEach(applyInterceptorInstance);

    useEffect(() => {
        const intercetpor = axiosInstance_2.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        //navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance_AUTH.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.axiosInstance_AUTH?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance_AUTH_COGNITO.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.axiosInstance_AUTH_COGNITO?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (!(error.response.status === 400 || error.response.status === 417)) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance_ORC.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    error?.response?.status === 400 &&
                    error?.response?.data?.message ===
                        'Prescriber is already certified for the selected program. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                ) {
                    dispatch(showPV0030Msg(true));
                } else if (!(error?.response?.status === 400 || error?.response?.status === 417)) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance_PDF.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (!(error.response.status === 400 || error.response.status === 417)) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstance_ORCHAPI.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.axiosInstance_AUTH?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstanceNewUrl.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);
    // unsecure
    useEffect(() => {
        const intercetpor = axiosInstancePrescriberUnsecure.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstanceSearchUnsecure.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstancePharmacyUnsecure.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstanceCognitoUrl.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);
    useEffect(() => {
        const intercetpor = axiosInstancePrescriber.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstanceSearch.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstancePharmacy.interceptors.response.use(
            (response) => {
                switch (response?.data?.statusCode) {
                    case 500:
                        navigate(systemErrPath);
                        break;
                    case 503:
                        navigate(systemErrPath);
                        break;
                    default:
                        return response;
                }
                return response;
            },
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios?.interceptors?.response?.eject(intercetpor);
        };
    }, []);

    useEffect(() => {
        const intercetpor = axiosInstanceSurvey.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error?.code === 'ERR_NETWORK') {
                    navigate(systemErrPath);
                } else if (
                    !(
                        error.response?.status === 400 ||
                        error.response?.status === 404 ||
                        error.response?.status === 417
                    )
                ) {
                    navigate(systemErrPath);
                } else return error;
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.response.eject(intercetpor);
        };
    }, []);
};

export default function AxiosNavigation() {
    useAxiosInterceptor();
    return <></>;
}
