import {ProgramConfig,pomalidomide} from 'constants/Config/programsConfig';
const registerIcon = '<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>'
const response = {
    help: 'Help page content',
    header: 'Confirmation',
    body: [
        {
            order: 1,
            content: `<div style="padding-top: 10px;padding-bottom: 10px">
                 Thank you for certifying in the ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} program. You are authorized to prescribe ${pomalidomide.toUpperCase()}${registerIcon} (pomalidomide) after you receive the ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} program materials.
                </div>`,
        },

        ,
        {
            order: 2,

            content: `<div style="padding-top: 10px;padding-bottom: 10px;font-weight:bold">
                 All requirements of the ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} program must be followed as listed on your certification card and described in the materials you will soon receive via mail. Your prescriptions for ${pomalidomide.toUpperCase()} are to be processed by a certified pharmacy within the Bristol Myers Squibb Pharmacy Network.
                </div>`,
        },

        {
            order: 3,
            content: `<div style="padding-top: 10px;padding-bottom: 10px">
                  If you have questions about the procedures required for prescribing ${pomalidomide.toUpperCase()}, please call 1-888-423-5436 to obtain the contact information of your REMS Representative.
                </div>`,
        },

        {
            order: 4,
            content: `<div style="padding-top: 10px;padding-bottom: 10px;font-weight:bold">
                 Please see relevant ${pomalidomide.toUpperCase()} Prescribing Information for further information on the Boxed WARNINGS, CONTRAINDICATIONS, PRECAUTIONS, and Adverse Reactions.
                </div>`,
        },
    ],
    images: [{ link1: 'rems_program_pomalyst' }],
};

export default response;
