import i18n from 'internationalization/i18next';
import React,{ useEffect} from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import router from 'routes/routesConfig';
import './AntdCustomStyles.scss';
import { Helmet } from 'react-helmet';

const DynaTraceScript = () => {
    let scriptSrc;
   
    if ((window.location.href.includes('remsprod') || (window.location.href.includes('bmsremspatientsafety')))){
        scriptSrc = 'https://js-cdn.dynatrace.com/jstag/157944990f8/bf45822esr/891bfc02741e1bb0_complete.js';
    }
    return (
        <Helmet>
            <script src={scriptSrc} type="text/javascript" crossOrigin="anonymous"></script>
        </Helmet>
    );
};
const App = () => {
    i18n.use(initReactI18next).init({
        resources: {
            en: { translation: require('./assets/translation/en.json') },
            es: { translation: require('./assets/translation/es.json') },
        },
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
    
    return (
        <>
            {/* <DynaTraceScript /> */}
            <RouterProvider router={router} />
        </>
    );
};

export default App;
