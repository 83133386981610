// HelpPageURL ContentMapper 
async function fetchData() {
    try {
        const response = await fetch('./static/resources/helpContentEnglish.json');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

const helpContentData = await fetchData().then((res) =>  res);
export const helpContent = {
    prescriberSelection: helpContentData.prescriberSelection,
    createaccount: helpContentData.personalAndAccountInfo,
    createaccountconfirmationprescriber: helpContentData.createAccountConfirmationPrescriber,
    createaccountconfirmationnonprescriber: helpContentData.createAccountConfirmationNonPrescriber,
    createaccountsuccessprescriber: helpContentData.userNameCreationConfirm,
    createaccountsuccessnonprescriber: helpContentData.userNameCreationConfirm,
    Home: helpContentData.chooseProgram,
    LoginHome:helpContentData.LoginHome, 
    productRegistration: helpContentData.productRegistration,
    productSignUp: helpContentData.productSignUp,
    productSignAndContinue: helpContentData.productSignAndContinue,
    SystemError: helpContentData.SystemError,
    Success: helpContentData.Success,
    forgotPassword: helpContentData.forgotPassword,
    securityVerification: helpContentData.securityVerification,
    forgotPasswordConfirmation: helpContentData.forgotPasswordConfirmation,
    resetSecurity: helpContentData.resetSecQues,
    resetTempPassword: helpContentData.resetTempPassword,
    MainMenu: helpContentData.Home,
    patientVerification: helpContentData.patientVerification,
    resetPasswordConfirmation: helpContentData.resetPasswordConfirmation,
    ppafConfirmation:helpContentData.ppafConfirmation,
    PhysicianOfficeDetails: helpContentData.PhysicianOfficeDetails,
    patientInformation : helpContentData.patientInformation,
    submittedPPAFSearchprescriber:helpContentData.submittedPPAFSearch,
    submittedPPAFSearchnonprescriber: helpContentData.physicianSearch,
    patientagreementform: helpContentData.patientagreementform,
    patientagreementformPrescriberSign: helpContentData.patientagreementformPrescriberSign,
    ppafSelection: helpContentData.ppafSelection,
    PrescriberSearch: helpContentData.physicianSearch,
    prescriberSearch: helpContentData.physicianSearch,
    physicianSearch: helpContentData.physicianSearch,
    prescriberSearchResult: helpContentData.physicianSearchResult,
    ppafWorkMenu: helpContentData.ppafWorkMenu,
    createaccount: helpContentData.createaccount,
    SystemError: helpContentData.SystemError,
    partiallycompletedppafsearchprescriber: helpContentData.partiallyCompletedPPAFSearch,
    partiallycompletedppafsearchnonprescriber: helpContentData.physicianSearch,
    patientaddressppafsearchprescriber: helpContentData.partiallyCompletedPPAFSearch,
    patientaddressppafsearchnonprescriber: helpContentData.physicianSearch,
    submittedppafcreatedsearchprescriber: helpContentData.partiallyCompletedPPAFSearch,
    submittedppafcreatedsearchnonprescriber: helpContentData.physicianSearch,
    patientInformationEditPatient:helpContentData.patientInformationEditPatient,
    createAccount: helpContentData.createAccount,
    createaccountconfirmationpharmacist: helpContentData.createaccountconfirmationpharmacist,
    PharmacyHome: helpContentData.pharmacyHome,
    createaccountsuccesspharmacist: helpContentData.createaccountsuccesspharmacist,
    patientSurveySearch:helpContentData.patientSurveySearch,
    patientSurveySelectProduct:helpContentData.patientSurveySelectProduct,
    patientSurveySurvey:helpContentData.patientSurveySurvey,
    patientSurveyReview:helpContentData.patientSurveyReview,
    patientSurveyDisclaimer:helpContentData.patientSurveyDisclaimer,
    patientSurveyRestart:helpContentData.patientSurveyRestart,
    prescribersurveyinformation: helpContentData.prescriberSurveyInformation,
    prescribersurveyconfirmation: helpContentData.prescriberSurveyConfirmation,
    patientSurveyError:helpContentData.patientSurveyError,
    pharmacyForgot: helpContentData.forgotPasswordPharmacy,
    pharmacySecurity: helpContentData.securityVerificationPharmacy,
    pharmacyConfirmation:helpContentData.forgotPasswordConfirmationPharmacy,
    pharmacyResetSecurity:helpContentData.resetSecurityPharmacy,
    pharmacyResetTemp:helpContentData.resetTempPasswordPharmacy,
    pharmacyResetConfirmation:helpContentData.resetPasswordConfirmationPharmacy,
    patientcamunda : helpContentData.patientCamunda,
    prescriberpatientsearch : helpContentData.patientSearch,
    patientreactivation: helpContentData.patientSearch,
    patientSearch: helpContentData.submittedPPAFSearch,
    prescribersurveypatientsearch:helpContentData.patientSearch,
    prescribersurveysearch:helpContentData.submittedPPAFSearch,
    PatientSearch:helpContentData.submittedPPAFSearch,
    prescriptionForm:helpContentData.prescriptionForm,
    prescriberpatientsearchprescriber : helpContentData.patientSearch,
    prescriberpatientsearchnonprescriber : helpContentData.patientSearch,
    educationAndCounseling:helpContentData.pharmacyEducationCounseling,
    logDispenseSelectDispense:helpContentData.logDispenseSelectDispense,
    logDispenseReview:helpContentData.logDispenseReview,
    logDispenseConfirmation:helpContentData.logDispenseConfirmation,
    prescriptionFormDetailsStandard:helpContentData.prescriptionFormDetailsStandard,
    prescriptionFormDetailsVHA:helpContentData.prescriptionFormDetailsVHA,
    prescriptionFormLang:helpContentData.prescriptionFormLang,
    prescriptionformpresinfo: helpContentData.prescriptionformpresinfo,
    prescriptionformdosage: helpContentData.prescriptionformdosage,
    createAssociation: helpContentData.CreateAssociation,
    associationConfirmation: helpContentData.associationConfirmation,
    prescribermanageMyAccount:helpContentData.prescribermanageMyAccount,
    nonPrescribermanageMyAccount:helpContentData.nonPrescribermanageMyAccount,
    reviewAuthorization:helpContentData.pharmacyReviewAuth,
    patientdetails:helpContentData.patientdetails,
    manageresetpassword:helpContentData.manageresetpassword,
    manageresetpasswordconfirmation:helpContentData.manageresetpasswordconfirmation,
    manageAccount:helpContentData.manageAccount,
    manageresetpasswordPhar:helpContentData.manageresetpasswordPhar,
    manageresetpasswordconfirmationPhar:helpContentData.manageresetpasswordconfirmationPhar,
    manageAccountConfirmation: helpContentData.manageAccountConfirmation,
};
