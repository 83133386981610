import React, { useEffect, useState } from 'react';
import Button from './../Button';
import classes from './header.module.scss';
import { useAppSelector, useAppDispatch, store } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    resetApp,
    changePPAF,
    resetProgram,
    resetPatientInformation,
    setPatientData,
    setPatientRisk,
    showPV0030Msg,
    resetpatientPrescriberStore,
    setPrescriptionPaylod,
    setActionItemInfo,
    setProductEnrolmentData,
    setSurveyDataFromPatientDetails,
    deleteIsWritePrescription,
} from 'store/slices/appSlice';
import { setLogOut, setLogOutPharmacy } from 'store/slices/loginSlice';
import persistStore from 'redux-persist/es/persistStore';
import { RoleByGroup } from 'constants/Config/appConfig';
import { useTranslation } from 'react-i18next';
import { json } from 'stream/consumers';
import { PatientSurveyFlowType, PatientSurveyScreens } from 'pages/PatientSurvey/PatientSurveyType';
import { LogDispenseScreens } from 'pages/Pharmacy/logDispense/logDispenseType';
import indexedDBStore from 'store/indexedDBStore';
import { logOut } from 'utils/authService';

const Header = () => {
    const {
        role,
        selectedProgram,
        isSubmitAndPrint,
        isAccount,
        isPPAF,
        upatedHelpContentKey,
        patientInformation,
        pharmacyAuthenticate,
        prescriptionFormData,
    } = useAppSelector((state) => state.app);
    const { currentUser, IsAuthenticated, IsAuthenticatedPharmacy, accessCodeFlag, currentUserPharmacy } =
        useAppSelector((state) => state.login);
    const { prescriberDetails } = useAppSelector((state) => state.app);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
    });
    const { localNavigation } = useAppSelector((state) => state.app.patientSurvey);
    const { logMultipleDispense } = useAppSelector((state) => state.app);
    useEffect(() => {
        setValues((prev) => ({
            ...prev,
            firstName:
                RoleByGroup.NonPrescriber === currentUser.groupTag
                    ? currentUser.firstName
                    : prescriberDetails.firstName.length > 0
                      ? prescriberDetails.firstName
                      : currentUser.firstName,
            lastName:
                RoleByGroup.NonPrescriber === currentUser.groupTag
                    ? currentUser.lastName
                    : prescriberDetails.lastName.length > 0
                      ? prescriberDetails.lastName
                      : currentUser.lastName,
        }));
    }, [IsAuthenticated, IsAuthenticatedPharmacy]);
    let path = '';

    const onHandleHelp = () => {
        const _path = location.pathname.split('/')[2];
        const pageUrlName = location.pathname.split('/')[2].toLocaleLowerCase();
        if (pageUrlName === 'submittedppafsearch') {
            path = 'submittedPPAFSearch' + role.toString().toLowerCase();
        } else if (pageUrlName === 'partiallycompletedppafsearch') {
            path = 'partiallycompletedppafsearch' + role.toString().toLowerCase();
        } else if (pageUrlName === 'patientaddressppafsearch') {
            path = 'patientaddressppafsearch' + role.toString().toLowerCase();
        } else if (pageUrlName === 'submittedppafcreatedsearch') {
            path = 'submittedppafcreatedsearch' + role.toString().toLowerCase();
        } else if (pageUrlName === 'createaccountconfirmation') {
            path = (isAccount ? isAccount : 'createaccountsuccess') + role.toString().toLowerCase();
        } else if (pageUrlName === 'prescriberpatientsearch') {
            path = 'prescriberpatientsearch' + role.toString().toLowerCase();
        } else if (pageUrlName === 'productsignup') {
            path = isSubmitAndPrint;
        } else if (pageUrlName === 'patientagreementform') {
            path = upatedHelpContentKey ? upatedHelpContentKey : pageUrlName;
        } else if (pageUrlName === 'patientinformation') {
            if (patientInformation?.flowType === 'EditPatient') path = _path + patientInformation?.flowType;
            else path = _path;
        } else if (pageUrlName === 'patientsurvey') {
            path = 'patientSurvey' + PatientSurveyScreens[localNavigation];
        } else if (pageUrlName === 'logdispensehome') {
            path = 'logDispense' + LogDispenseScreens[logMultipleDispense.localNavigation];
        } else if (pageUrlName === 'prescriptionformdetails') {
            if (prescriptionFormData?.formType === 'VHAForm') {
                path = 'prescriptionFormDetailsVHA';
            } else {
                path = 'prescriptionFormDetailsStandard';
            }
        } else if (pageUrlName === 'dashboard') {
            const pathArray = window.location.hash.split('/');
            const arrayWithOnlyDashboardPaths = pathArray.slice(2);
            const dashoboardTabItems = arrayWithOnlyDashboardPaths.join('/');
            path = dashoboardTabItems;
        } else {
            path = _path;
        }

        if (path) {
            window.open(
                `${window.location.origin}/#/headerHelp?role=${role}&helpRef=${path}&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
                `${window.location.origin}/#/headerHelp?role=${role}&helpRef=${path}&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
                'location=yes,height=379,width=808,scrollbars=yes,status=yes'
            );
        }
    };

    const onHandleHome = () => {
        dispatch(resetPatientInformation());
        dispatch(resetpatientPrescriberStore());
        dispatch(showPV0030Msg(false));
        dispatch(setPatientRisk(null));
        dispatch(setPrescriptionPaylod({}));
        dispatch(setPatientData({}));
        dispatch(setSurveyDataFromPatientDetails({}));
        dispatch(setActionItemInfo({}));
        dispatch(setProductEnrolmentData({}));
        dispatch(deleteIsWritePrescription(false));

        if (IsAuthenticated && window.location.href.includes('prescriber')) {
            switch (currentUser.groupTag) {
                case RoleByGroup.Prescriber: {
                    if (
                        location.pathname.split('/')[2] === 'createaccountconfirmation' ||
                        location.pathname.split('/')[2] === 'Home' ||
                        location.pathname.split('/')[2] === 'systemError'
                    ) {
                        dispatch(resetProgram());
                        dispatch(resetApp());
                        logOutClear();
                        i18n.changeLanguage('en');
                        navigate('/prescriber/login', { replace: true });
                    } else if (!accessCodeFlag) {
                        if (
                            currentUser.systemId === undefined ||
                            currentUser.systemId === '' ||
                            currentUser.systemId === null
                        ) {
                            if (
                                location.pathname.split('/')[2] === 'productRegistration' ||
                                location.pathname.split('/')[2] === 'productSignUp'
                            ) {
                                logOutClear();
                            }
                        }
                        dispatch(resetProgram());
                        navigate('/prescriber/login', { state: { replace: true } });
                    } else {
                        if (
                            currentUser.systemId === undefined ||
                            currentUser.systemId === '' ||
                            currentUser.systemId === null
                        ) {
                            if (
                                location.pathname.split('/')[2] === 'productRegistration' ||
                                location.pathname.split('/')[2] === 'productSignUp'
                            ) {
                                logOutClear();
                                dispatch(resetProgram());
                                navigate('/prescriber/login', { state: { replace: true } });
                            }
                        } else {
                            dispatch(resetProgram());
                            navigate('/prescriber/LoginHome', { replace: true });
                        }
                    }
                    break;
                }
                case RoleByGroup.NonPrescriber: {
                    if (location.pathname.split('/')[2] === 'systemError') {
                        dispatch(resetProgram());
                        dispatch(resetApp());
                        navigate('/prescriber/login', { replace: true });
                    } else {
                        dispatch(resetProgram());
                        navigate('/prescriber/LoginHome', { replace: true });
                    }
                    break;
                }
            }
            i18n.changeLanguage('en');
            dispatch(changePPAF(false));
        } else if (IsAuthenticatedPharmacy && window.location.href.includes('pharmacy')) {
            switch (currentUserPharmacy.groupTag) {
                case RoleByGroup.Pharmacist: {
                    if (
                        location.pathname.split('/')[2] === 'CreateAccountConfirmation' ||
                        location.pathname.split('/')[2] === 'systemError'
                    ) {
                        if (pharmacyAuthenticate) {
                            navigate('/pharmacy/PharmacyHome', { replace: true });
                        } else {
                            dispatch(resetProgram());
                            dispatch(resetApp());
                            i18n.changeLanguage('en');
                            logOutClearPharmacy();
                            navigate('/pharmacy/Login', { replace: true });
                        }
                    } else {
                        dispatch(resetProgram());
                        navigate('/pharmacy/PharmacyHome', { replace: true });
                    }
                    break;
                }
            }
            i18n.changeLanguage('en');
            dispatch(changePPAF(false));
        } else {
            if (location.pathname.split('/')[1] == 'prescriber') {
                navigate('/prescriber/login', { replace: true });
            } else {
                logOutClearPharmacy();
                navigate('/pharmacy/login', { replace: true });
            }
        }
    };

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const logOutClear = () => {
        // clearStore();
        dispatch(resetApp());
        dispatch(setLogOut());
        dispatch(resetPatientInformation());
        dispatch(resetpatientPrescriberStore());
        dispatch(deleteIsWritePrescription(false));
        i18n.changeLanguage('en');
        dispatch(changePPAF(false));
        indexedDBStore.removeItem(`prescriberUser`);
    };

    const logOutClearPharmacy = () => {
        // clearStore();
        dispatch(resetApp());
        dispatch(setLogOutPharmacy());
        indexedDBStore.removeItem(`pharmacyUser`);
    };
    const onHandleLogout = async () => {
        const loginState = await indexedDBStore.getItem(`${location?.pathname?.split('/')[1]}User`);
        if (!location.pathname.includes('prescriber/patientSurvey')) {
            if (loginState?.currentUser?.accessToken) {
                await logOut(loginState?.currentUser?.accessToken);
            }
        }

        if (location.pathname.includes('pharmacy')) {
            navigate('/pharmacy/login', { replace: true });
            logOutClearPharmacy();
        } else {
            navigate('/prescriber/login', { replace: true });
            logOutClear();
        }
    };
    const onHandleMainMenu = () => {
        i18n.changeLanguage('en');
        dispatch(resetPatientInformation());
        dispatch(resetpatientPrescriberStore());
        dispatch(setPatientRisk(null));
        dispatch(setPatientData({}));
        dispatch(deleteIsWritePrescription(false));
        navigate('/prescriber/MainMenu', { replace: true });
    };

    const Islogout = () => {
        let result = false;
        if (location.pathname.includes('prescriber/patientSurvey')) {
            result = !(localNavigation == 0 || localNavigation == 1);
        } else if (IsAuthenticated && window.location.href.includes('prescriber')) {
            switch (currentUser.groupTag) {
                case RoleByGroup.Prescriber: {
                    if (location.pathname.split('/')[2] === 'createaccountconfirmation') {
                        result = false;
                    } else if (!accessCodeFlag) {
                        result = false;
                    } else {
                        result = true;
                    }
                    break;
                }
                case RoleByGroup.NonPrescriber: {
                    result = true;
                    break;
                }
            }
        } else if (IsAuthenticatedPharmacy && window.location.href.includes('pharmacy')) {
            switch (currentUserPharmacy.groupTag) {
                case RoleByGroup.Pharmacist: {
                    if (location.pathname.split('/')[2] === 'CreateAccountConfirmation') {
                        result = true;
                    } else {
                        result = true;
                    }
                    break;
                }
            }
        }
        return result;
    };

    const IsHome = () => {
        if (location.pathname.includes('prescriber/patientSurvey')) {
            if (IsAuthenticated) return true;
            else return localNavigation == 0 || localNavigation == PatientSurveyFlowType.SearchPage;
        } else {
            return true;
        }
    };

    const loggedInUser = () => {
        const isPharmacy = window.location.href.includes('pharmacy');
        const user = `${isPharmacy ? currentUserPharmacy.firstName : currentUser.firstName ? currentUser.firstName : prescriberDetails.firstName.length > 0 ? prescriberDetails.firstName : currentUser.firstName} ${isPharmacy ? currentUserPharmacy.lastName : currentUser.lastName ? currentUser.lastName : prescriberDetails.lastName.length > 0 ? prescriberDetails.lastName : currentUser.lastName}`;
        return user;
    };

    return (
        <div className={classes.header}>
            {Islogout() &&
                location.pathname.split('/')[2] !== 'systemError' &&
                currentUser?.userStatus !== 'FORCE_CHANGE_PASSWORD' && (
                    <span className={classes.headerUserName}>{loggedInUser()}</span>
                )}
            <div className={classes.headerRight}>
                {IsHome() && (
                    <Button
                        className="headerBtn"
                        type="text"
                        buttonname={t('homeBtn')}
                        name="Home"
                        clickFunction={onHandleHome}
                    ></Button>
                )}

                {isPPAF && (
                    <Button
                        className="headerBtn"
                        type="text"
                        buttonname={t('mainMenuBtn')}
                        name="Main Menu"
                        clickFunction={onHandleMainMenu}
                    ></Button>
                )}
                <Button
                    className="headerBtn"
                    type="text"
                    buttonname={t('helpBtn')}
                    name="Help"
                    clickFunction={onHandleHelp}
                ></Button>
                {Islogout() &&
                    location.pathname.split('/')[2] !== 'systemError' &&
                    currentUser?.userStatus !== 'FORCE_CHANGE_PASSWORD' && (
                        <Button
                            className="yellowButtons"
                            type="text"
                            name="Logout"
                            buttonname={t('logoutBtn')}
                            clickFunction={onHandleLogout}
                        ></Button>
                    )}
            </div>
        </div>
    );
};
export default Header;
