import { Outlet, createHashRouter } from 'react-router-dom';
import Layout from '../layout';
import Login from '../pages/Login';
import Error from '../pages/Error';
import PrivateRoute from './privateRoute';
import HeaderHelp from '../pages/HeaderHelp';
import LandingPage from '../pages/LandingPage/LandingPage';
import PrescriberHome from 'pages/PrescriberHome';
import CreateAccount from '../pages/createaccount';
import RootErrorBoundary from '../components/RootErrorBoundary';
import PrescriberRadio from '../pages/PrescriberSelection/PrescriberSelection';
import AccountConfirmation from '../pages/AccountConfirmation/AccountConfirmation';
import ContactUs from '../pages/ContactUs';
import ProductRegistration from '../pages/ProductRegistration/ProductRegistration';
import SystemError from '../components/SystemError';
import Success from '../components/Success';
import ProductRegSignUp from '../pages/ProductRegSignup/ProductRegSignup';
import response from './../assets/objects/SuccessContent';
import PinHelp from 'pages/pinHelp';
import AxiosNavigation from 'constants/Config/axiosConfig';
import LoginHome from '../pages/LoginHome';
import ForgotPassword from 'pages/ForgotPassword';
import SecurityVerification from 'pages/ForgotPassword/SecurityVerification';
import ForgotPasswordConfirmation from 'pages/ForgotPassword/ForgotPasswordConfirmation';
import ResetSecurity from 'pages/ForgotPassword/ResetSecurity';
import ResetTempPswd from 'pages/ForgotPassword/ResetTempPswd';
import ResetPasswordConfirmation from 'pages/ForgotPassword/ResetPasswordConfirmation';
import PPAFSelection from 'pages/PPAFSelection/PPAFSelection';
import PatientAgreementForm from 'pages/PatientEnrollment/PatientAgreementForm';
import PhysicianOfficeDetails from 'pages/PhysicianOfficeDetails';
import MainMenu from 'pages/MainMenu';
import PatientInformation from 'pages/PatientInformation/PatientInformation';
import PatientVerification from 'pages/PatientVerification';
import PPAFConfirmation from 'pages/PPAFConfirmation';
import PPAFWorkMenu from 'pages/PPAFWorkMenu/PPAFWorkMenu';
import PatientSearch from 'pages/PatientSearch/PatientSearch';
import PrescriberPatientSearch from 'pages/PrescriberPatientSearch/PrescriberPatientSearch';
import PrescriberSearchResult from 'pages/PrescriberSearchResult';
import PrescriberSearch from 'pages/PrescriberSearch/PrescriberSearch';
import PrescriberNonPrescriber from 'pages/PrescriberNonPrescriber';
import PatientReactivation from 'pages/PrescriberPatientSearch/PatientReactivation';
import PatientCamunda from 'pages/PrescriberPatientSearch/PatientCamunda';
import PharmacyLogin from 'pages/Pharmacy/Login';
import CreatePharmacyAccount from '../pages/Pharmacy/createpharmacyaccount';
import PharmacyAccountConfirmation from '../pages/Pharmacy/PharmacyAccountConfirmation';
import PrescriberSurveyInformation from 'pages/PrescriberSurveyInformation/PrescriberSurveyInformation';
import PrescriberSurveyConfirmation from 'pages/PrescriberSurveyConfirmation/PrescriberSurveyConfirmation';
import PrescriberSurveyPatientSearch from 'pages/prescribersurveypatientsearch/PrescriberSurveyPatientSearch';
import PrescriberSurveySearch from 'pages/PrescriberSurveySearch/PrescriberSurveySearch';
import PatientSurvey from 'pages/PatientSurvey/PatientSurvey';
//import PdfViewer from 'utils/pdfViewer';
import PdfViewer from 'components/PdfViewer';
import PharmacyHome from 'pages/Pharmacy/PharmacyHome/PharmacyHome';
import EducationAndCounseling from 'pages/Pharmacy/EducationAndCounseling';
import LogDispense from 'pages/Pharmacy/logDispense';
import ManageAccount from 'pages/Pharmacy/ManageAccount';
import PrescriberDashboard from 'pages/PrescriberDashboard';
import PrescriptionForm from 'pages/PrescriberForm/PrescriptionForm';
import PrescriptionFormLang from 'pages/PrescriptionFormDetails/PrescriptionFormLang';
import PrescriptionFormDetails from 'pages/PrescriptionForms/PrescriptionFormDetails';
import PrescriptionFormPresInfo from 'pages/PrescriptionFormDetails/PrescriptionFormPrescInfo';
import ProtectedRoute from './protectedRoute';
import PrescriptionFormDosage from 'pages/PrescriptionForms/PrescriptionFormDosage';
import PrescriberManageAccount from 'pages/PrescriberManage/ManageAccount';
import CreateAssociation from 'pages/PrescriberManage/CreateAssociation';
import PatientDetails from 'pages/PatientDetails/PatientDetails';
import ReviewAuthorization from 'pages/Pharmacy/ReviewAuthorization';
import AssociationConfirmation from 'pages/PrescriberManage/AssociationConfirmation';
import ResetPasswordConfirmationManage from 'pages/PrescriberManage/ManageResetPassword/ResetPasswordConfirmationManage';
import ResetPswdManage from 'pages/PrescriberManage/ManageResetPassword/ResetPswdManage';
import ResetPswdManagePhar from 'pages/Pharmacy/ManageResetPasswordPhar/ResetPswdManagePhar';
import ResetPswdManageConfirmationPhar from 'pages/Pharmacy/ManageResetPasswordPhar/ResetPswdManageConfirmationPhar';
import { ManageAccountConfirmation } from 'pages/Pharmacy/ManageAccount/ManageAccountConfirmation';
import ActiveProductSystemError from '../components/SystemError/ActiveProductSystemError';

const prescriberDashboardElement = () => {
    return (
        <PrivateRoute>
            <PrescriberDashboard />
        </PrivateRoute>
    );
};

const router = createHashRouter([
    {
        path: '/',
        element: <LandingPage />,
    },
    {
        path: '/pdfViewer',
        errorElement: <Error />,
        element: <PdfViewer />,
    },
    {
        path: '/prescriber',
        element: (
             <ProtectedRoute>
            <>
                <Layout /> <AxiosNavigation />
                </>
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <Outlet />,
                errorElement: <RootErrorBoundary />,
                children: [
                    {
                        path: 'login',
                        element: <Login />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'patientVerification',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PatientVerification />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'dashboard',
                        errorElement: <Error />,
                        element: prescriberDashboardElement(),
                        children: [
                            {
                                path: 'prescription-action-items',
                                element: prescriberDashboardElement(),
                                errorElement: <Error />,
                            },
                            {
                                path: 'find-a-patient',
                                element: prescriberDashboardElement(),
                                errorElement: <Error />,
                            },
                            {
                                path: 'prescriber-calendar',
                                element: prescriberDashboardElement(),
                                errorElement: <Error />,
                            },
                            {
                                path: 'review-authorization',
                                element: prescriberDashboardElement(),
                                errorElement: <Error />,
                            },
                            {
                                path: 'reports',
                                element: prescriberDashboardElement(),
                                errorElement: <Error />,
                                children:[
                                    {
                                        path: 'expireReport',
                                        element:prescriberDashboardElement(),
                                        errorElement: <Error />,
                                    },
                                    {
                                        path: 'historicalReport',
                                        element:prescriberDashboardElement(),
                                        errorElement: <Error />,
                                    },
                                    {
                                        path: 'hiddenPatientReport',
                                        element:prescriberDashboardElement(),
                                        errorElement: <Error />,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        path: 'manageMyAccount',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberManageAccount />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'ppafConfirmation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PPAFConfirmation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'forgotPassword',
                        element: <ForgotPassword />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'securityVerification',
                        element: <SecurityVerification />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'forgotPasswordConfirmation',
                        element: <ForgotPasswordConfirmation />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'resetSecurity',
                        element: <ResetSecurity />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'resetTempPassword',
                        element: <ResetTempPswd />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'resetPasswordConfirmation',
                        element: <ResetPasswordConfirmation />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'SystemError',
                        element: <SystemError />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'manageresetpassword',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                               <ResetPswdManage />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'manageresetpasswordconfirmation',
                        element: <ResetPasswordConfirmationManage />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'Success',
                        element: (
                            <Success
                                label="Confirmation"
                                btn={true}
                                product={true}
                                productLogo={response.images[0].link1}
                            >
                                {response.body.map((item) => (
                                    <div
                                        key={item?.order}
                                        dangerouslySetInnerHTML={{ __html: item?.content || '' }}
                                    ></div>
                                ))}
                            </Success>
                        ),
                        errorElement: <Error />,
                    },
                    {
                        path: 'createaccount',
                        element: <CreateAccount />,
                        errorElement: <Error />,
                        caseSensitive: true,
                    },
                    {
                        path: 'prescriberSelection',
                        element: <PrescriberRadio />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'associationConfirmation',
                        element: <AssociationConfirmation />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'LoginHome',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <LoginHome />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'createAssociation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <CreateAssociation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'Home',
                        errorElement: <Error />,
                        element: <PrescriberHome />,
                    },
                    {
                        path: 'createaccountconfirmation',
                        errorElement: <Error />,
                        element: <AccountConfirmation />,
                    },
                    {
                        path: 'productRegistration',
                        errorElement: <Error />,
                        element: <ProductRegistration />,
                    },
                    {
                        path: 'productSignUp',
                        errorElement: <Error />,
                        element: <ProductRegSignUp />,
                    },

                    {
                        path: 'ppafSelection',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PPAFSelection />
                            </PrivateRoute>
                        ),
                    },

                    {
                        path: 'patientagreementform',
                        element: <PatientAgreementForm />,
                        errorElement: <Error />,
                        caseSensitive: true,
                    },
                    {
                        path: 'PhysicianOfficeDetails',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PhysicianOfficeDetails />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'ppafWorkMenu',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PPAFWorkMenu />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'patientInformation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PatientInformation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'PatientSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PatientSearch />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'partiallyCompletedPPAFSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberNonPrescriber />
                            </PrivateRoute>
                        ),
                    },

                    {
                        path: 'submittedPPAFSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberNonPrescriber />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'submittedPpafCreatedSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberNonPrescriber />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'patientAddressPPAFSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberNonPrescriber />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescriberpatientsearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberPatientSearch />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescribersurveyinformation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSurveyInformation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescribersurveyconfirmation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSurveyConfirmation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescribersurveypatientsearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSurveyPatientSearch />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescribersurveysearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSurveySearch />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'patientreactivation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PatientReactivation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'patientcamunda',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PatientCamunda />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'physicianSearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSearch />
                            </PrivateRoute>
                        ),
                    },

                    {
                        path: 'prescribersearch',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSearch />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'prescribersearchResult',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PrescriberSearchResult />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'MainMenu',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <MainMenu />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'patientagreementform',
                        element: <PatientAgreementForm />,
                        errorElement: <Error />,
                        caseSensitive: true,
                    },
                    {
                        path: 'patientSurvey',
                        errorElement: <Error />,
                        element: <PatientSurvey />,
                    },
                    {
                        path: 'prescriptionForm',
                        errorElement: <Error />,
                        element: <PrescriptionForm />,
                    },
                    {
                        path: 'prescriptionFormLang',
                        errorElement: <Error />,
                        element: <PrescriptionFormLang />,
                    },
                    {
                        path: 'prescriptionformdetails',
                        errorElement: <Error />,
                        element: <PrescriptionFormDetails />,
                    },
                    {
                        path: 'prescriptionformpresinfo',
                        errorElement: <Error />,
                        element: <PrescriptionFormPresInfo />,
                    },
                    {
                        path: 'prescriptionformdosage',
                        errorElement: <Error />,
                        element: <PrescriptionFormDosage />,
                    },
                    {
                        path: 'patientdetails',
                        errorElement: <Error />,
                        element: <PatientDetails />,
                    },
                ],
            },
        ],
    },
    {
        path: '/patient',
        element: (
            <ProtectedRoute>
                <Layout /> <AxiosNavigation />
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <Outlet />,
                errorElement: <RootErrorBoundary />,
                children: [
                    {
                        path: 'login',
                        element: <p>Login</p>,
                        errorElement: <Error />,
                    },
                ],
            },
        ],
    },
    {
        path: '/pharmacy',
        element: (
            <ProtectedRoute>
                <Layout /> <AxiosNavigation />
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <Outlet />,
                errorElement: <RootErrorBoundary />,
                children: [
                    {
                        path: 'Login',
                        element: <PharmacyLogin />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'SystemError',
                        element: <SystemError />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacyForgot',
                        element: <ForgotPassword />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacySecurity',
                        element: <SecurityVerification />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacyConfirmation',
                        element: <ForgotPasswordConfirmation />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacyResetSecurity',
                        element: <ResetSecurity />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacyResetTemp',
                        element: <ResetTempPswd />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'pharmacyResetConfirmation',
                        element: <ResetPasswordConfirmation />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'createAccount',
                        element: <CreatePharmacyAccount />,
                        errorElement: <Error />,
                    },
                    {
                        path: 'CreateAccountConfirmation',
                        errorElement: <Error />,
                        element: <PharmacyAccountConfirmation />,
                    },
                    {
                        path: 'reviewAuthorization',
                        errorElement: <Error />,
                        element: <ReviewAuthorization />,
                    },
                    {
                        path: 'educationAndCounseling',
                        errorElement: <Error />,
                        element: <EducationAndCounseling />,
                    },
                    {
                        path: 'PharmacyHome',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <PharmacyHome />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'logdispensehome',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <LogDispense />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'manageAccount',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <ManageAccount />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'manageAccountConfirmation',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                                <ManageAccountConfirmation />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'manageresetpasswordPhar',
                        errorElement: <Error />,
                        element: (
                            <PrivateRoute>
                               <ResetPswdManagePhar />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: 'manageresetpasswordconfirmationPhar',
                        element: <ResetPswdManageConfirmationPhar />,
                        errorElement: <Error />,
                    },
                ],
            },
        ],
    },
    {
        path: 'headerHelp',
        element: <HeaderHelp />,
        errorElement: <Error />,
    },
    {
        path: 'SystemError',
        element: <SystemError />,
        errorElement: <Error />,
    },
    {
        path: 'ActiveProductsSystemError',
        element: <ActiveProductSystemError />,
        errorElement: <Error />,
    },
    {
        path: 'help',
        element: <PinHelp />,
        errorElement: <Error />,
    },
    {
        path: 'ContactUs',
        element: <ContactUs />,
        errorElement: <Error />,
    },
    {
        path: '*',
        element: <p>Page not found</p>,
        errorElement: <Error />,
    },
]);

export default router;
