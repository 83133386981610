import React, { useState } from 'react';
import classes from './Input.module.scss';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

export type InputProps = {
    style?: any;
    containerStyle?:any;
    type: string;
    width?: string;
    name: string;
    label: string;
    value?: string;
    valueId?:string;
    helpText?: string;
    helpText2?: string;
    helpText3?: string;
    noInput?: boolean;
    maxLength?: number;
    valueExist?: string;
    isRequired?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    styleupdate?: boolean;
    className?: string;
    labelStyle?: {};
    helpTextStyle?:{};
    inputvalue?: string;
    options?: { value: string }[];
    options_PO?: { value: string,valueId :string }[];
    onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    onBlurHandler?: () => void;
    noInputStyle?:{};
    containerClassName?:string;
    isSurveyPage?:boolean;
    authPage?:boolean;
    isLabelSeperated?:boolean;
};

const Input = ({
    name,
    type,
    width,
    label,
    style,
    value,
    valueId,
    options,
    options_PO,
    noInput,
    containerStyle,
    helpText,
    maxLength,
    valueExist,
    isRequired,
    defaultValue,
    className,
    disabled,
    helpText2,
    helpText3,
    helpTextStyle,
    onChangeHandler,
    inputvalue,
    labelStyle,
    onBlurHandler,
    styleupdate,
    noInputStyle,
    containerClassName,
    isSurveyPage,
    authPage = false,
    isLabelSeperated=true
}: InputProps) => {
    const isMandatory = isRequired ? classes.mandatory : '';
    const [isVisible, setVisible] = useState(false);

    const togglePswd = () => {
        setVisible(!isVisible);
    };
    const customText=noInputStyle ? noInputStyle : {}

    return (
        <div className={containerClassName ? classes.containerPFD :(isSurveyPage? classes.containerSurvey:authPage ? classes.containerAuth : classes.container) } style={{ color: styleupdate ? '#000' : '#193878',...containerStyle }}>
            <div className={`${classes.label} ${authPage ? classes.authlable : ''}`}>
                <span style={labelStyle ? labelStyle : {}} className={`${isMandatory}`}  dangerouslySetInnerHTML={{
                          __html: isLabelSeperated ? `${label}:` : label
                      }}>
                   {/* // {label}: */}
                </span>
            </div>

            <div className={className ? classes[className] : classes.input}>
                {className && type === 'hidden' ? (
                    <>
                    <label>{inputvalue?inputvalue:value}</label>
                    {(helpText && name==="patientIdNum") && <p className={classes.helpText} style={{fontWeight:400}}>{helpText}</p>}
                    </>

                ) : type === 'select' ? (
                    <>
                        <select
                            onBlur={onBlurHandler}
                            id={name}
                            style={style}
                            name={name}
                            onChange={onChangeHandler}
                            defaultValue={defaultValue}
                            value={value}
                            disabled={disabled}
                        >

                            {
                                name==="address_PO" ? 
                                options_PO?.map((i) => (
                                    <option key={i.valueId} selected={i.valueId === defaultValue} value={i.valueId}>
                                        {i.value}
                                    </option>
                                ))
                                :
                            options?.map((i) => (
                                <option key={i.value} selected={i.value === defaultValue} value={i.value}>
                                    {i.value}
                                </option>
                            ))}
                        </select>
                        {helpText && <p className={classes.helpTextSel}>{helpText}</p>}
                        {helpText2 && <p className={classes.helpTextSel}>{helpText2}</p>}
                        {helpText3 && <p className={classes.helpTextSel}>{helpText3}</p>}
                    </>
                ) : (
                    <>
                        {noInput ? (
                            name === 'securityAnswer' ? (
                                <span style={{ fontSize: '19px', fontWeight: 'bold', lineHeight: '1px' }}>
                                     {valueExist && ('.'.repeat(10))}
                                    
                                </span>
                            ) : (
                                <>
                                {name === "pi_number" ? (
                                    <span style={{ fontWeight: 'bold' }}>{value}</span>

                                ):(
                                <>
                                    <span style={{ fontWeight: 'bold' ,...customText}}>{valueExist}</span>
                                    </>
                                )
                                }
                                
                                {helpText &&  <p className={name === "PIN" ?classes.helpTextPIN:classes.helpText}>{helpText}</p>}
                                {helpText2 && <p className={classes.helpText}>{helpText2}</p>}
                                </>
                            )
                        ) : (
                            <>
                                {type === 'password' ? (
                                    <div className={classes.inputContainer}>
                                        <div
                                            className={classes.passwordContainer}
                                            onBlur={(e) => {
                                                !e.relatedTarget
                                                    ? e.target.id === name && setVisible(false)
                                                    : e.relatedTarget.id !== name && setVisible(false);
                                            }}
                                        >
                                            <input
                                                id={name}
                                                name={name}
                                                onChange={onChangeHandler}
                                                type={isVisible ? 'text' : 'password'}
                                                maxLength={maxLength}
                                                size={20}
                                                style={{ width }}
                                                value={value}
                                                defaultValue={name === 'securityAnswer' ? defaultValue : ''}
                                                autoComplete='new-password'
                                            />
                                            {value !== '' && (
                                                <button
                                                    id={name}
                                                    className={width ? classes.psdIc : classes.pswdIcon}
                                                    onClick={togglePswd}
                                                >
                                                    {isVisible ? <VscEyeClosed /> : <VscEye />}
                                                </button>
                                            )}
                                        </div>
                                        {helpText && (
                                            <p
                                                className={classes.helpText}
                                                style={helpTextStyle}
                                                dangerouslySetInnerHTML={{ __html: helpText }}
                                            ></p>
                                        )}
                                    </div>
                                ) : (
                                    <div className={classes.inputContainer}>
                                        <input
                                            onBlur={onBlurHandler}
                                            name={name}
                                            onChange={onChangeHandler}
                                            type={type}
                                            id={name}
                                            maxLength={maxLength}
                                            size={20}
                                            style={{...style, ...{ width: width || 'initial'}}}
                                            defaultValue={defaultValue}
                                            value={value}
                                            tabIndex={0}
                                            autoComplete='new-password'
                                        />

                                        {helpText && <p className={classes.helpText} style={helpTextStyle}>{helpText}</p>}
                                        {helpText2 && <p className={classes.helpText}>{helpText2}</p>}
                                        {helpText3 && <p className={classes.helpText}>{helpText3}</p>}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Input;
