import ProductBackground from "components/ProductBackground/productBackground";
import classes from "./PatientSurvey.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PatientSurveyFlowType, PatientSurveyScreens, PossibleResponses } from "./PatientSurveyType";
import { useAppDispatch, useAppSelector } from "store";
import { changeProgram, patientEligibilityProduct, updatePatientSurvey } from "store/slices/appSlice";
import DynamicPopUp from "./DynamicPopUp";
import {PROGRAMS,ProgramConfig} from 'constants/Config/programsConfig';
import Button from "components/Button";
import { buttonContent } from "assets/objects/buttonContent";
import {reloadFunction} from '../../utils/utilsCommon';


const DynamicQues = ({ setScreen }) => {
  const { t, i18n } = useTranslation();
  const programList :any = PROGRAMS;
  const dispatch = useAppDispatch();
  const { patientSurvey } = useAppSelector((state) => state.app);
  const [IsSpanish, setIsSpanish] = useState(patientSurvey.flowType === PatientSurveyFlowType.PatientEnrollmentPage || patientSurvey.flowType === PatientSurveyFlowType.PrescriberSurveyPage);
  const { selectedSurveyProduct, questionSequence, updatedSurveyPayload } = useAppSelector(
    (state) => state.app.patientSurvey
  );
  const [selectedQuestion, setSelectedQuestion] = useState(
    selectedSurveyProduct?.PatientSurvey?.QuestionAnswers.find((x) => {
      return x.Question.QuestionSequence === questionSequence;
    })
  );


  const getSurvayDetails = (productName: string) => {
    const payload = {
      patientExtUniqueId: patientSurvey?.eligiableProduct?.patientExtUniqueId,
      language: i18n.language
    }
    dispatch(patientEligibilityProduct({ payload, productName })).then((response) => {
      searchResponseHandler(response.payload);
    });
  }

  const searchResponseHandler = (response) => {
    if (response?.status === 200) {
      setSelectedQuestion(response?.data?.data?.PatientSurvey?.QuestionAnswers.find((x) => {
        return x.Question.QuestionSequence === 1;
      }));
    }
    else if (response?.status === 204 || response?.status === 202) {
      dispatch(updatePatientSurvey({ ...patientSurvey, businessErrorCode: { code: response.status } }));
      setScreen(PatientSurveyScreens.Error);
    }
    else if (response?.response?.status === 400) {
      dispatch(updatePatientSurvey({ ...patientSurvey, businessErrorCode: response?.response?.data?.data?.errorMesssage }));
      setScreen(PatientSurveyScreens.Error);
    }
  }

  const handleButtonClick = () => {
    i18n.changeLanguage('es');
    getSurvayDetails(selectedSurveyProduct?.PatientSurvey?.ProductName);
    setIsSpanish(false);
  };

  const handleRestart = () => {
    setScreen(PatientSurveyScreens.Restart)
  };

  useEffect(() => {
    dispatch(changeProgram(programList.find((i) => i.productCountryId === selectedSurveyProduct.PatientSurvey.ProductId)));
  }, [])

  useEffect(() => {
    dispatch(updatePatientSurvey({
      ...patientSurvey,
      questionSequence: selectedQuestion?.Question?.QuestionSequence
    }));
  }, [selectedQuestion])
  useEffect(() => {
    reloadFunction();
}, []);
  
  const handleAnsClick = (possibleResponses) => {
    setSelectedQuestion((prev) => {
      return {
        ...prev,
        Answer: {
          ResponseOption: possibleResponses.ResponseOptionValue,
          ResponseDisplay: possibleResponses.ResponseOption,
        },
      };
    });
  };

  const pushPayload = () => {
    const _previousQue = [...updatedSurveyPayload.PatientSurvey.QuestionAnswers];
    _previousQue.push(selectedQuestion);

    dispatch(
      updatePatientSurvey({
        ...patientSurvey,
        updatedSurveyPayload: {
          ...updatedSurveyPayload,
          PatientSurvey: { ...updatedSurveyPayload.PatientSurvey, QuestionAnswers: _previousQue },
        },
      })
    );
  };

  const handleClickToContinue = () => {
    const seletedItem = selectedQuestion.Question.PossibleResponses.find((x) => {
      return x.ResponseOption === selectedQuestion?.Answer?.ResponseDisplay;
    });
    if (selectedSurveyProduct.PatientSurvey.QuestionAnswers.length === seletedItem.QuestionSequence) {
      pushPayload();
      setScreen(PatientSurveyScreens.Review);
    } else if (seletedItem.SkipQuestionFlag === 'Y') {
      pushPayload();
      const checkQuestions = selectedSurveyProduct.PatientSurvey.QuestionAnswers.find((x) => {
        return x.Question.QuestionSequence === Number(seletedItem?.SkipToSequence);
      })
      if(!checkQuestions){
        setScreen(PatientSurveyScreens.Review);
      }else {
      setSelectedQuestion(
        selectedSurveyProduct.PatientSurvey.QuestionAnswers.find((x) => {
          return x.Question.QuestionSequence === Number(seletedItem?.SkipToSequence);
        })
      );
      }
    } else {
      pushPayload();
      setSelectedQuestion(
        selectedSurveyProduct.PatientSurvey.QuestionAnswers.find((x) => {
          return x.Question.QuestionSequence === seletedItem.QuestionSequence + 1;
        })
      );
    }
  };

  return (
    <div className={classes.dynamicQues}>
      {
        (patientSurvey.flowType === PatientSurveyFlowType.SearchPage || patientSurvey.flowType === PatientSurveyFlowType.HomePage) &&
        <DynamicPopUp />
      }

      <ProductBackground
        product={selectedSurveyProduct?.PatientSurvey?.ProductName}
        subTitle={t('patientSurvey')}
        IsSpanish={IsSpanish && i18n.language === "en"}
        handleButtonClick={handleButtonClick} >
        {selectedQuestion && (
          <div className={`${classes[i18n.language]} ${classes.bodyContainer}`}>
            <div className={classes.restartSurveyBtn}>              
              <Button
                    width={i18n.language === 'en' ? 168 : 320}
                    height={45}
                    size={18}
                    clickFunction={() => {
                      handleRestart();
                    }}
                    buttonname={i18n.language === 'en' ? buttonContent.en.restart : buttonContent.es.restart}
                    type="text"
                    className={`buttonStyles`}
                />
            </div>
            <div >
              <span className={classes.surveyText}>{`${updatedSurveyPayload?.PatientSurvey?.QuestionAnswers?.length + 1
                }. ${selectedQuestion?.Question?.QuestionText}`}</span>
            </div>
            <div className={classes.dynamicButton}>
              {(selectedQuestion?.Question?.ResponseOptionType === 'YesNoDontKnow' ||
                selectedQuestion?.Question?.ResponseOptionType === 'YesNo') &&
                selectedQuestion?.Question?.PossibleResponses.map((item) => {
                  switch (item.ResponseOption) {
                    case 'Yes':
                    case "Sí":
                      return (
                        <Button
                              width={118}
                              height={57}
                              size={26}
                              clickFunction={() => {
                                handleAnsClick(item);
                              }}
                              buttonname={i18n.language === 'en' ? buttonContent.en.yes : buttonContent.es.yes}
                              type="text"
                              className={
                                  item.ResponseOption === selectedQuestion?.Answer?.ResponseDisplay
                                      ? 'selectStyle'
                                      : 'buttonStyles'
                              }
                          />
                      );
                    case 'No':
                      return (
                        <Button
                              width={118}
                              height={57}
                              size={26}
                              clickFunction={() => {
                                handleAnsClick(item);
                              }}
                              buttonname={i18n.language === 'en' ? buttonContent.en.no : buttonContent.es.no}
                              type="text"
                              className={
                                  item.ResponseOption === selectedQuestion?.Answer?.ResponseDisplay
                                      ? 'selectStyle'
                                      : 'buttonStyles'
                              }
                          />
                      );
                    case "Don't know":
                    case "No sé":
                      return (
                        <Button
                              width={269}
                              height={57}
                              size={26}
                              clickFunction={() => {
                                handleAnsClick(item);
                              }}
                              buttonname={i18n.language === 'en' ? buttonContent.en.dontKnow : buttonContent.es.dontKnow}
                              type="text"
                              className={
                                  item.ResponseOption === selectedQuestion?.Answer?.ResponseDisplay
                                      ? 'selectStyle'
                                      : 'buttonStyles'
                              }
                          />
                      );
                  }
                })}
            </div>
            <div>
            {selectedQuestion?.Answer?.ResponseDisplay && (
                    <Button
                    width={i18n.language === 'en'?486:658}
                    height={57}
                    size={26}
                    clickFunction={() => {
                      handleClickToContinue();
                    }}
                    buttonname={i18n.language === 'en' ? buttonContent.en.clickHereToContinue : buttonContent.es.clickHereToContinue}
                    type="text"
                    className={'buttonStyles'}
                />
              )}
            </div>
          </div>
        )}
      </ProductBackground>
    </div>
  );
};

export default DynamicQues;
