export const conductPrescriberSurveyRequestBody = (answers, conductSurveyPayload) => {
    return {
        UserId: conductSurveyPayload.prescriberDetails.onlineUserid || conductSurveyPayload.onlineUserId,
        BMSPrescriberId: conductSurveyPayload.prescriberDetails.prescriberSystemId,
        BMSPatientId: conductSurveyPayload.BMSPatientId,
        ProductId: conductSurveyPayload.selectedProgram.productCountryId,
        LanguageId: conductSurveyPayload.currentLanguage,
        PregTestDate: conductSurveyPayload.PregTestDate,
        CommunicationType: 'REMS Portal',
        PrescriberSurvey: answers.PrescriberSurvey,
        SurveyMetaDataID: conductSurveyPayload?.SurveyMetaDataID,
    };
};


export const convertToEn = (value) => {
    if(value.includes('Si')){
        return value.replace('Si','Yes')
    }
    if(value.includes('dia')){
        return value.replace('dia','day')
    }
    if(value.includes("No sé")){
        return value.replace("No sé","Don't know")
    }
    if(value.includes('dias')){
        return value.replace('dias','days')
    }
    if(value.includes('Fetcha')){
        return value.replace('Fetcha','Date')
    }
    return value
}