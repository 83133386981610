import React, { useRef } from 'react';
import classes from './errorModal.module.scss';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

interface ModalProps {
    error: string;
    handleContinue: () => void;
    classNamesAccCreation?:boolean;
}

const ErrorModal: React.FC<ModalProps> = ({ handleContinue, error ,classNamesAccCreation}) => {
    const { t} = useTranslation();
    const modalRef = useRef<HTMLDialogElement | null>(null);
    const mainContainerClassName =classNamesAccCreation === true ? classes.mainContainerAccCreation : classes.mainContainer;
    return (
        <div className={classes.modalContainer}>
            <dialog ref={modalRef} className={mainContainerClassName}>
                <div className={classes.whiteInfoContainer}>
                    <div className={classes.header}>Error</div>
                    <div className={classes.bigPad}>
                        <b>{t('errorHeading')}</b>
                        <ul>
                            <li>{error}</li>
                        </ul>
                    </div>
                    <div className={classes.btnContainer}>
                        <Button
                            clickFunction={handleContinue}
                            className="buttonStyles"
                            type="text"
                            width={131}
                            height={39}
                            size={18}
                            buttonname={t('continue')}
                        />
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default ErrorModal;
