import { useRef, useState } from 'react';
import classes from './popUpModal.module.scss';
import { useTranslation } from 'react-i18next';

const PopUp = ({ pop, iterateHandle, modal, acknowledge }) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);
    const [showModal, setShowModal] = useState(true);
    const [checked, setChecked] = useState(false);
    const { t, i18n } = useTranslation();

    if (modal && !showModal) {
        setShowModal(true);
        setChecked(false);
    }

    const closeHandle = (value) => {
        setShowModal(false);
        let change = { ...value, closed: 'true' };
        iterateHandle(change, false);
    };

    const submitModalPopup = (value) => {
        setChecked(!checked);
        setTimeout(() => {
            setShowModal(false);
        }, 1000);
        //setShowModal(false);
        let change = { ...value, closed: 'true' };
        iterateHandle(change, true);
    };

    return (
        showModal && (
            <div className={classes.modalContainer}>
                <dialog ref={modalRef} className={classes.mainContainerAccCreation}>
                    <button className={classes.closeBtn} onClick={() => closeHandle(pop)}>
                        X
                    </button>

                    <div>
                        <p dangerouslySetInnerHTML={{ __html: pop.Template }}></p>
                        {acknowledge && (
                            <div className={classes.subText}>
                                <input
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    checked={checked}
                                    onClick={() => submitModalPopup(pop)}
                                />
                                {i18n.language?.toLowerCase() === 'es'
                                    ? 'Acepto esta afirmación'
                                    : `I acknowledge this.`}
                            </div>
                        )}
                    </div>
                </dialog>
            </div>
        )
    );
};

export default PopUp;
