import React from 'react';
import PageBackground from '../PageBackground';
import classes from './SystemError.module.scss';
import warning from './../../assets/images/img_Warning_Exclamation.png';
import { useAppSelector } from 'store';
import Alert from 'components/Alert';
import { useTranslation } from 'react-i18next';
import Footer from 'components/Footer';

const ActiveProductSystemError = () => {
    const { t, i18n } = useTranslation();
    const showPV0030Msg = useAppSelector((state) => state.app.showPV0030Msg);
    return (
        <div className={classes.activeProductsErrorContainer}>
            <div className={classes.activeProductsSubContainer}>
            <PageBackground label={t('SystemErrorTitle')}>
                <div className={classes.bannerContent}>
                    <div className={classes.instructions_bold}>
                        <span>
                            <img className={classes.warning} src={warning} alt="warning_img" />
                        </span>
                        <span>{t('SystemErrorDescription')}</span>
                    </div>
                    <div className={classes.mainContent}>
                        <div className={classes.instructions_bold}>
                            {showPV0030Msg ? t('error_PV0030') : t('error_PV0075')}                           
                        </div>
                    </div>
                </div>
            </PageBackground>
            </div>
            <Footer />
        </div>
    )
};

export default ActiveProductSystemError;
