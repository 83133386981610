import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProgramConfig, ProgramConfigIMG, PROGRAMS } from 'constants/Config/programsConfig';
import Footer from '../../components/Footer';
import classes from './LandingPage.module.scss';
import { Role } from 'constants/Config/appConfig';
import i18n from 'internationalization/i18next';
import { useAppDispatch } from 'store';
import indexedDBStore from 'store/indexedDBStore';
import { handlePrescriber, resetPatientSurvey, resetProgram } from 'store/slices/appSlice';
import { bodyContent, headContent } from '../../assets/objects/LandingPageContent';
import MigratePopUp from 'components/MigratePopUp';

const IMG4 = './static/resources/img_REMS_Companion_App_CRM_Login.png';

const LandingPage = () => {
    const dispatch = useAppDispatch();
    const getData: any = {
        programs: PROGRAMS,
    };

    useEffect(() => {
        dispatch(resetProgram());
        dispatch(resetPatientSurvey());
        // Fetch Function
    }, []);
    sessionStorage.removeItem('documentTitle');
    sessionStorage.removeItem('contactUsBody');

    const urlNavigate = (item) => {
        window.open(
            `https://${item.link}`,
            item.name,
            'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width=' +
                window.screen.availWidth * (11 / 12) +
                ',height=600'
        );
    };

    const checkSession = async (item) => {
        const pharmacyLabel = 'Pharmacies';
        const patientLabel = 'Pharmacies';
        let path = item.url;
        if (item.name === patientLabel) {
            dispatch(resetProgram());
            dispatch(resetPatientSurvey());
            i18n.changeLanguage('en');
        }
        dispatch(handlePrescriber(item.name === pharmacyLabel ? Role.Pharmacist : Role.Prescriber));
        const loginState = await indexedDBStore.getItem(`${item.btnName.toLowerCase()}User`);

        if (loginState?.currentUser?.isAuthenticated) {
            path = item.homeurl;
        }
        window.open('/#' + path, '_blank');
    };

    return (
        <>
            {/* <MigratePopUp /> */}
            <div className={classes.layout}>
                <div className={classes.container}>
                    <div className={classes.contentPadding}>
                        <div className={classes.textPadding}>
                            {headContent.slice(0, 3).map((item) => (
                                <p key={item.order} dangerouslySetInnerHTML={{ __html: item.content }}></p>
                            ))}
                        </div>
                        <div className={classes.flexContainer}>
                            {getData?.programs?.map((item) => (
                                <div key={item.title} className={classes.programContainer}>
                                    <img
                                        tabIndex={0}
                                        onClick={() => urlNavigate(item)}
                                        src={require(`../../assets/images/${ProgramConfigIMG(item.id)}`)}
                                        alt={item.title}
                                    />
                                    <p>Visit</p>
                                    <p>
                                        <span tabIndex={0} onClick={() => urlNavigate(item)}>
                                            {item.link}
                                        </span>
                                        , to learn more about the {item.title}
                                        {(item.title === ProgramConfig('rems_program_thalomid')?.title.toUpperCase() ||
                                            item.title ===
                                                ProgramConfig('rems_program_pomalyst')?.title.toUpperCase()) && (
                                            <span
                                                style={{
                                                    fontSize: '0.8em',
                                                    position: 'relative',
                                                    top: '-0.5em',
                                                    color: '#193878',
                                                    textDecoration: 'none',
                                                    cursor: 'default',
                                                }}
                                            >
                                                ®
                                            </span>
                                        )}{' '}
                                        program.
                                    </p>
                                </div>
                            ))}
                        </div>
                        <p className={classes.textPadding}>{headContent[3].content}</p>
                        <div className={classes.flexContainer}>
                            {bodyContent.tasks.map((item) => (
                                <div key={item.name} className={classes.taskContainer}>
                                    <p>
                                        <span>{item.name}:</span>
                                        {item.content}
                                    </p>
                                    {item.url ? (
                                        <button
                                            onClick={() => {
                                                checkSession(item);
                                                return false;
                                            }}
                                            className={classes.link}
                                            type="button"
                                        >
                                            {item.btnName}
                                        </button>
                                    ) : (
                                        <Link
                                            className={`${classes.link} ${classes.disableLink}`}
                                            to={'#'}
                                            target="_blank"
                                        >
                                            {item.btnName}
                                        </Link>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={classes.survey}>
                            <img src={IMG4} alt="survey" />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default LandingPage;
