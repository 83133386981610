import React, { useEffect, useState } from 'react';
import classes from './logDispense.module.scss';
import { Table, Input, Checkbox, Tooltip, AutoComplete, Select } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { AuthDataType } from './logDispenseType';
import infoIcon from '../../../assets/images/infoIcon.png';
import { option } from 'yargs';
import { useAppDispatch, useAppSelector } from 'store';
 
export type AuthDispenseTableProps = {
    data;
    allChecked;
    onSelectAll?: (e: any) => void;
    setAllChecked?: () => void;
    onCheckHandler?: () => void;
    setRowValues?: () => void;
    onBlurHandler?: () => void;
    setProductQuantity?: () => void;
    setProductIdentificationOnchange?: () => void;
    setProductIdentificationOnSelect?: () => void;
    addSubRowHandler?: () => void;
    deleteSubRowHandler?: () => void;   
};
const SelectDispenseTable = ({
    data,
    onSelectAll,
    onCheckHandler,
    setRowValues,
    setLocation,
    setProductQuantity,
    setProductIdentificationOnchange,
    setProductIdentificationOnSelect,
    addSubRowHandler,
    locationOptions,
    }) => {
    const { Column } = Table;

    const { authorizationData } = useAppSelector((state) => state.app);
 
    const productQuantity = (): any[] => {
        let startnumber = 28;
        const productOptions: any[] = [];
        while (startnumber <= 700) {
            productOptions.push({ value: startnumber, label: startnumber });
            startnumber = startnumber + 28;
        }
        return productOptions;
    };
    const column: any = [
        {
            title: (
                <div className={classes.fields}>
                    <input
                        id="headerDispenseRow_checkbox"
                        type="checkbox"
                        onChange={(e) => {
                            onSelectAll(e);
                        }}
                        checked={data?.every((prod) => prod?.product?.every((x) => x.checked))}
                        name="dispenserow_checkbox_selectall"
                    />
                </div>
            ),
            dataIndex: 'checkbox',
            key: 'checkbox',
            render: (text, record: AuthDataType, index) => (
                <div key={index} className={classes.inputs}>
                    {record?.product?.map((item, index) => (
                        <div key={index} className={classes.fields}>
                            <input
                                key={item.id}
                                id={`checkbox_${record.id}_${item.id}`}
                                checked={item.checked}
                                onChange={(e) => onCheckHandler(record, e, item, index)}
                                type="checkbox"
                                name="dispenserow_checkbox"
                            />
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: (
                <div>
                    <span className={classes.space} />
                    Pharmacy Location
                </div>
            ),
            dataIndex: 'PharmacyLocation',
            key: 'PharmacyLocation',
            render: (item, record, index) => (
                <div key={index} className={classes.fields}>
                    {record?.authResponse?.dispensationError?.code === 9020 ? (
                        <span className={classes.required}>*</span>
                    ) : (
                        <span className={classes.space}></span>
                    )}
                    <select
                        autoComplete="off"
                        id={`PharmacyLocation_${record.id}`}
                        className={classes.pharmacyLocationDropdown}
                        value={record.location.value}
                        onChange={(e) => setLocation(record, e)}
                        name="dispense_location_rd_0"
                    >
                        {locationOptions.map((x) => (
                            <option key={x.value} value={x.value} selected={x.value === record.location.value}>
                                {x.value}
                            </option>
                        ))}
                    </select>
                </div>
            ),
        },
        {
            title: (
                <div>
                    <span className={classes.space} />
                    Authorization #
                </div>
            ),
            dataIndex: 'Authorization',
            key: 'Authorization',
            render: (item, record, index) => (
                <div key={index} className={`${classes.fields} ${classes.authorization}`}>
                    {' '}
                    {record?.authResponse?.dispensationError?.code === 9028 ||
                    record?.authResponse?.dispensationError?.code === 9027 ||
                    record?.authResponse?.dispensationError?.code === 9019 ? (
                        <span className={classes.required}>*</span>
                    ) : (
                        <span className={classes.space}></span>
                    )}
                    <input
                        autoComplete="off"
                        id={`Authorization_${record.id}`}
                        name={`Authorization_${record.id}`}
                        type="text"
                        onChange={(e) => setRowValues(record, e, 'auth_no')}
                        size={8}
                        maxLength={10}
                        value= {record.auth_no.auth_no}
                    />
                </div>
            ),
        },
        {
            title: (
                <div className={classes.tooltip}>
                    Product Identification<span className={`${classes.icon} ${classes.infoSymbol}`}></span>
                    <span className={classes.tooltiptext}>
                        Begin typing the first 3 characters of the product name or NDC code in the Product
                        Identification textbox to list available Product Identification selections.
                    </span>
                </div>
            ),
            dataIndex: 'ProductIdentification',
            key: 'ProductIdentification',
            render: (text, record: AuthDataType, index) => (
                <div key={index} className={classes.inputs}>
                    {record?.product?.map((item, index) => (
                        <div key={index}>
                            <AutoComplete
                                aria-autocomplete="none"
                                id={`ProductIdentification_${record.id}_${item.id}`}
                                className={classes.customDropdown}
                                style={{ width: 230 }}
                                popupMatchSelectWidth={false}
                                options={
                                         item.product_id.product_id.length > 2
                                            ? record.product_options
                                            : []                                       
                                }
                                value={item.product_id.product_id}
                                onChange={(e, val) => {
                                    setProductIdentificationOnchange(record, item, e);
                                }}    
                                onSelect={(e, option) => {                                    
                                    setProductIdentificationOnSelect(record, item, option, e);
                                }}
                                filterOption={(inputValue, option) =>
                                    option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            render: (text, record: AuthDataType, index) => (
                <div key={index} className={classes.inputs}>
                    {record?.product?.map((item, index) =>
                        item?.product_id?.productCountryId === 2 ? (
                            <div key={index}>
                                <select
                                    autoComplete="off"
                                    id={`Quantity_${record.id}_${item.id}`}
                                    name={`Quantity_${record.id}_${item.id}`}
                                    className={classes.quantityDropdown}
                                    value={item.quantity.quantity}
                                    onChange={(e) => setProductQuantity(record, item, e.target.value)}
                                >
                                    {productQuantity().map((x) => (
                                        <option
                                            key={x.value}
                                            value={x.value}
                                            selected={x.value === item.quantity.quantity}
                                        >
                                            {x.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <div key={index} className={classes.fields}>
                                <input
                                    autoComplete="off"
                                    id={`Quantity_${record.id}_${item.id}`}
                                    maxLength={4}
                                    type="text"
                                    name={`Quantity_${record.id}_${item.id}`}
                                    value={item.quantity.quantity}
                                    onChange={(e) => setProductQuantity(record, item, e.target.value)}
                                    className={classes.quantityTextbox}
                                />
                            </div>
                        )
                    )}
                </div>
            ),
        },
        {
            title: 'Add Prod ID',
            dataIndex: 'AddProdID',
            key: 'AddProdID',
            render: (text, record: AuthDataType, index) => (
                <div key={index} className={classes.inputs}>
                    {record?.product?.map(
                        (item, index) =>
                            index === 0 && (
                                <div key={index} className={classes.fields} style={{ width: '74px' }}>
                                    <a
                                        id={`AddProdID_${record.id}_${item.id}`}
                                        onClick={() => {
                                            record?.product.length < 3 && addSubRowHandler(record, item);
                                        }}
                                    >
                                        Add Prod ID
                                    </a>
                                </div>
                            )
                    )}
                </div>
            ),
        },
    ];

    return (
        <div className={classes.logDispenseTableDiv}>
            <Table
                rowClassName={(record, index) => (index % 2 === 0 ? `table-row-dark` : `table-row-light`)}
                size="small"
                pagination={false}
                dataSource={data}
                columns={column}
                bordered
            />
        </div>
    );
};

export default SelectDispenseTable;
