export const fetchDatafromS3json = () => {

    const fetchValues = async () => {
        try {
            const [quesResp, statelistResp] = await Promise.all([
                fetch('./resources/question.json'),
                fetch('./resources/statelist.json')
            ]);

            if (!quesResp.ok) {
                throw new Error(`HTTP error! status: ${quesResp.status}`);
            }
            else if (!statelistResp.ok) {
                throw new Error(`HTTP error! status: ${statelistResp.status}`);
            }

            const quesData = await quesResp.json();
            const stateData = await statelistResp.json();

            return {
                state: stateData,
                securityQuestion: quesData
            };
        } catch (error) {
            console.error('Error:', error);
        }
    }

   return fetchValues();
};