import SessionTimeout from 'components/SessionTimeout';
import Success from 'components/Success';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';

const ResetPasswordConfirmationManage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { role } = useAppSelector((state) => state.app);

    const handleContinue = () => {
        navigate('/prescriber/manageMyAccount', { replace: true });
    };

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <>
            <SessionTimeout />
            <Success label="Reset Password Confirmation" btn={true} handleClick={handleContinue}>
                <p style={{ fontWeight: 'bold', margin: '20px 0' }}>
                    Your password has been changed. Press the Continue button below to return to Manage My Account.
                </p>
            </Success>
        </>
    );
};

export default ResetPasswordConfirmationManage;