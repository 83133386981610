import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

const languagedetector:any = {
    type: 'languageDetector',
    detect: () => sessionStorage.getItem('i18nextLng') || 'en',
    init: () => {},
    cacheUserLanguage: (lng) => sessionStorage.setItem('i18nextLng', lng),
};

i18n.use(initReactI18next)
    .use(languagedetector)
    .use(HttpApi)
    .init({
        fallbackLng: 'en',
        detection: {
            caches: [],
        },

        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: { useSuspense: false },

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
