import React from 'react';
import classes from './button.module.scss';
import left from '../../assets/images/btn-left-corner.png';
import right from '../../assets/images/btn-right-corner.png';

const Button = (props: any) => {
    return (
        <>
        {['Home','Help','Logout','Main Menu'].includes(props.name) ?
         <button  tabIndex={0} className={classes[props.className]} onClick={props.clickFunction} disabled={props.disabled}>
         {props.buttonname}
     </button>:
        <div className={classes.contain} style={{ width: props.width}} tabIndex={0} onKeyDown={(e)=> e.key === 'Enter' && props.clickFunction(e)}>
          
            {props.innerImage ?(
                 <div className={classes.btnWithImgContainer}>
                <button style={{ width: props.width - 4, height: props.height - 5 }} tabIndex={1}
                className={classes[props.className]}
                onClick={props.clickFunction} disabled={props.disabled}> <div className={classes.glossybtn}></div>
               <div className={classes.insideText}>
                <p style={{ fontSize:props.size}}>{props.buttonname}</p>
               {props.subText && <span className={classes.btnSubText}>{props.subText}</span> }
               </div>
                 </button>
               
            </div>
                
            ):(
            <button
                style={{ width: props.width - 4, height: props.height - 5 }} tabIndex={1}
                className={classes[props.className]}
                onClick={props.clickFunction} disabled={props.disabled}
            >
                <div className={classes.glossybtn}></div>
                <p style={{ margin: 0,fontSize:props.size }}>{props.buttonname}</p>
            </button>
            )}
            
        </div>}
        </>
    );
};

export default Button;
