import { jwtDecode } from 'jwt-decode';

export const congitoSignUP_payload = ({
    city,
    state,
    email,
    first_name,
    last_name,
    phone_number,
    group_tag,
    zip_code,
    country_code,
    address,
    password,
    username,
    language,
    system_Id,
    security_answer,
    security_question,
    prescriber_user_id,
}) => {
    return {
        city: city,
        state: state,
        email: email,
        fName: first_name,
        lName: last_name,
        phNum: phone_number,
        grpTag: group_tag,
        zipCode: zip_code,
        country: country_code,
        address: address,
        password: password,
        userName: username,
        language: language,
        system_Id: system_Id,
        security_answer: security_answer,
        security_question: security_question,
        prescriber_user_id: prescriber_user_id,
    };
};

export const cognitoLoginErrMsg = (res) => {
    if (res?.includes('Incorrect username or password')) {
        return 'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.';
    } else if (res?.includes('PreAuthentication failed')){
        return 'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.';
    } else if (res?.toLowerCase().includes('socket timeout')){
        return 'Maximum retry limit reached. Timeout error occured. Please try again after 15 minutes.';
    } else if (res?.includes('User does not exist')) {
        return 'The User Name was not found.';
    } else if (res?.includes('User is disabled')) {
        return 'We’re sorry your account has been locked due to unsuccessful login attempts; or one (1) year of inactivity. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.';
    } else {
        return res;
    }
};

export const authenticateErrCodeMapper = (res) => {
    if (res?.includes('Incorrect username or password')) {
        return 101;
    } else if (res?.includes('User does not exist')) {
        return 100;
    } else if (res?.includes('User is disabled')) {
        return 103;
    }
}

export const congitoResMapper = (res) => {
    const userDets =
        res?.AccessToken && res?.IdToken ? { ...jwtDecode(res?.AccessToken), ...jwtDecode(res?.IdToken) } : {};
    const isAuthenticated = true;
    const currentUser = {
        onlineUserId: userDets?.username || '',
        firstName: userDets?.given_name || '',
        lastName: userDets?.family_name || '',
        groupTag: userDets?.['custom:group_tag'] || '',
        systemId: userDets?.['custom:system_Id'] || '',
        userStatus: 'CONFIRMED', //res?.userStatus || '', // need to get the value
        accessToken: res?.AccessToken || '',
        idToken: res?.IdToken || '',
        refreshToken: res?.RefreshToken || '',
        userinfo: {...userDets, firstName: userDets?.given_name || '', lastName: userDets?.family_name || '' },
    };

    return {
        userDets,
        currentUser,
        isAuthenticated,
    };
};
