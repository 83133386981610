import React, { useState, useEffect } from 'react';
import PopUp from 'components/PopUp';
import { axiosInstance, axiosInstanceSearchUnsecure } from 'constants/Config/axiosConfig';
import { setClosedPopUpTemplate } from 'store/slices/loginSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';

const DynamicPopUp = () => {
    const [popUpData, setPopUpData] = useState<any>([]);
    const [filtered, setFiltered] = useState<any>([]);
    const [finalCopy, setFinalCopy] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const [showAcknowledge, setShowAcknowledge] = useState(true);
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();

    const { eligiableProduct } = useAppSelector((state) => state.app.patientSurvey);
    const { IsAuthenticated, popUpTemplate, closedPopUpTemplate } = useAppSelector((state) => state.login);

    useEffect(() => {
        if (popUpData && popUpTemplate && popUpData.length > 0 && popUpTemplate.length > 0) {
            const filteredArray = popUpTemplate.filter((itemA) =>
                popUpData?.some((itemB) => itemB.functionName === itemA.name)
            );
            setFiltered(filteredArray);
        }
    }, [popUpData, popUpTemplate]);

    const iterateHandle = (value, acknowledged) => {
        let findIndex = filtered.findIndex((x) => x.name === value.name);
        if (findIndex !== -1) {
            let shallowCopy = JSON.parse(JSON.stringify(filtered));
            shallowCopy[findIndex].closed = value.closed;
            dispatch(setClosedPopUpTemplate([...shallowCopy]));
            setFiltered([...shallowCopy]);
            setModal(false);
        }
        if (acknowledged) {
            const acknowlegeRequest = {
                celgeneId: eligiableProduct.patientId,
                contactType: 'Patient',
                enrolledProducts: null,
                functionName: value.name.replace('-es',''),
                communicationType: 'REMS Portal',
                countryId: {
                    countryId: 1,
                    countryName: null,
                    countryCode: null,
                },
                languageId: {
                    languageId: 1,
                    iso_639_1_code: null,
                    languageName: null,
                    otherLanguage: null,
                },
            };
            submitAcknowledgement(acknowlegeRequest);
        }
    };

    const getPayloadForPopUp = (arr) => {
        if (arr.some((value) => value.closed === 'false')) {
            let FinalArray = arr.find((value) => value.closed === 'false');
            let Data = popUpData.filter((value) => value.functionName === FinalArray.name);
            if (Data.length > 0 && Data[0].acknowledgementFlag === 'Y') {
                setShowAcknowledge(true);
            } else {
                setShowAcknowledge(false);
            }
            setFinalCopy([FinalArray]);
            setModal(true);
        }
    };

    useEffect(() => {
        if (closedPopUpTemplate.length > 0) {
            getPayloadForPopUp(closedPopUpTemplate);
        } else {
            getPayloadForPopUp(filtered);
        }
    }, [filtered, closedPopUpTemplate]);

    const reqBody = {
        celgeneId: eligiableProduct.patientId,
        contactType: 'Patient',
        enrolledProducts: eligiableProduct.enrolledProduct,
        functionName: null,
        communicationType: 'REMS Portal',
        countryId: null,
        languageId:
            i18n.language?.toLowerCase() === 'es'
                ? { languageId: 2, iso_639_1_code: 'es', languageName: 'Spanish', otherLanguage: null }
                : { languageId: 1, iso_639_1_code: 'en', languageName: 'English', otherLanguage: null },
    };

    const getDynamicPopUp = async () => {
        try {
            const response = await axiosInstanceSearchUnsecure.post(
                `/exp-api/v1/surveys/patient/notification`,
                reqBody
            );
            setPopUpData(
                response?.data?.data.map((i) => ({
                    ...i,
                    functionName: `${i.functionName}${i18n.language?.toLowerCase() === 'es' ? '-es' : ''}`,
                }))
            );
        } catch {}
    };

    const submitAcknowledgement = async (acknowlegeRequest) => {
        try {
            const response = await axiosInstanceSearchUnsecure.post(
                `/exp-api/v1/surveys/patient/acknowledgement`,
                acknowlegeRequest
            );
        } catch {}
    };

    useEffect(() => {
        getDynamicPopUp();
    }, []);

    return (
        finalCopy &&
        finalCopy[0] &&
        finalCopy.map((value) => {
            return <PopUp pop={value} iterateHandle={iterateHandle} modal={modal} acknowledge={true} />;
        })
    );
};

export default DynamicPopUp;
