import { axiosInstanceNewUrl, axiosInstancePharmacy, axiosInstanceSurvey } from 'constants/Config/axiosConfig';
import { useEffect, useState } from 'react';
import classes from './PatientDetails.module.scss';
import PopupDispenseHistory from './PopupDispenseHistory';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfirmedDispensation } from 'store/slices/appSlice';
import DispenseListPagination from './DispenseListPagination';
import { useTranslation } from 'react-i18next';

const DispenseDetails = ({ selectedPatient, scrollTo }) => {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [dispenseData, setDispenseData] = useState<any>([]);
    const [pharmacyData, setPharmacyData] = useState<any>({});
    const dispenseHistoryList = useAppSelector((state) => state.app.dispenseHistoryList);
    const getFormattedDob = (date) => moment(date).format('MMM DD, YYYY');
    const { t } = useTranslation();

    const openPharmacyModal = async (pharmacyId) => {
        try {
            const response = await axiosInstancePharmacy.post(`/v1/pharmacy/find-pharmacy`, {
                pharmacyId: pharmacyId,
                channelId: 'ext',
            });
            if (response.data.statusCode === 200) {
                setOpenModal(true);
                setPharmacyData(response.data.data);
                scrollTo();
            }
        } catch (err) {
            console.error('Api Error');
        }
    };

  const filteredData = !!dispenseHistoryList?.content?.length ? dispenseData.sort(
        (a: any, b: any) => new Date(b['dispenseCreatedDate'])?.getTime() - new Date(a['dispenseCreatedDate'])?.getTime())
        ?.filter((item)=>item.cancellationNumber===null) : dispenseHistoryList?.content; //sort createdate & filter out cancelled dispenses
 
    return (
        <div className={classes.dispense_info}>
            {openModal && <PopupDispenseHistory setOpenModal={setOpenModal} data={pharmacyData}></PopupDispenseHistory>}
            <p className={classes.sub_title}>Dispense History</p>
            <table className={classes.common_table}>
                <thead>
                    <th>Authorization Number</th>
                    <th>Date Dispensed</th>
                    <th>Product Identification</th>
                    <th>Quantity Dispensed</th>
                    <th>Pharmacy</th>
                </thead>
                <tbody>
                    {!!dispenseHistoryList?.content?.length ? (
                        dispenseHistoryList?.content?.map((pgm) => {
                            const sequence = pgm?.dispensationSequences;
                            return sequence?.map((seq)=>{
                                return (
                                    <tr>
                                        <td>{(pgm?.authorizationNumber?.slice(2) || '')}</td>
                                        <td>{getFormattedDob(seq?.dispensationDate)}</td>
                                        <td>{seq?.ndcId?.ndcDescription}</td>
                                        <td>{seq?.dispensationQuantity}</td>
                                        <td>
                                            <a onClick={() => openPharmacyModal(pgm.pharmacyId)}>{pgm.pharmacyName}</a>
                                        </td>
                                    </tr>
                                );
                            })
                            
                        })
                    ) : (
                        <tr>
                            <td colSpan={5} className={classes.empty_data}>
                                {t('noRecords')}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="pagination">
                <DispenseListPagination />
            </div>

        </div>
    );
};

export default DispenseDetails;
