import classes from './detailedPatientAuthTable.module.scss';
import { formatDate } from 'utils/formatDate';
import greyedWritePrescriptionBtn from '../../../assets/images/btn_grey_write_prescription.png';
import activeWritePrescriptionBtn from '../../../assets/images/btn_write_prescription_blue_.png';
import { useEffect, useState } from 'react';
import { getPharmacyLocation, clearPharmacyLocation, setPatientData, changeProgram, setIsWritePrescription } from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { FaWindowClose } from 'react-icons/fa';
import { axiosInstance_2 } from 'constants/Config/axiosConfig';
import { useNavigate } from 'react-router-dom';
import {PROGRAMS,ProgramConfig} from 'constants/Config/programsConfig';
import { trimPrefix } from 'utils/trimPrefix';

const DetailedPatientAuthTable = ({ authData }) => {
    const programList:any= PROGRAMS;
    const {
        patientDob = null,
        patientRiskCategoryName = null,
        patientLastSurveyDate = null,
        patientNextEarliestSurveyDate = null,
        confirmationNumber = null,
        dispenseCreatedDate = null,
        cancellationNumber = null,
        cancelledDate = null,
        dispensationSequences = null,
        authorizationStatus = null,
        pharmacyId = null,
        patientExternalUniqueId = null,
        productCountryId = null,
        authorizationNumber = null,
    } = authData;

    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.login);

    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const [pharmacyDetails, setPharmacyDetails] = useState<any>(null);
    const [isPharmacyDetailsClicked, setIsPharmacyDetailsClicked] = useState(false);
 

    const navigate = useNavigate();

    useEffect(() => {
        if (!pharmacyId) {
            return;
        } else {
            const payload = {
                channelId: 'ext',
                pharmacyId: pharmacyId,
            };
            dispatch(getPharmacyLocation(payload)).then((e: any) => {
                if (e.payload?.data?.statusCode === 200) {
                    const pharmacyDetails = {
                        pharmacyName: e.payload?.data?.data?.pharmacyName,
                        pharmacyInfo: e.payload?.data?.data?.pharmacyLocations[0],
                    };
                    setPharmacyDetails(pharmacyDetails);
                } else {
                    setPharmacyDetails(null);
                    dispatch(clearPharmacyLocation);
                }
            });
        }
    }, [pharmacyId]);

    const pharmacyDetailsToggler = () => {
        setIsPharmacyDetailsClicked(true);
    };

    const handleWritePrescription = async () => {
        try {
            const resp = await axiosInstance_2.post('/findPatient', {
                patientExternalUniqueId: patientExternalUniqueId,
            });
            if (resp.data.message === 'SUCCESS') {
                const surveyData= [{authorizationNumber}]
                dispatch(setPatientData({...resp.data.data,surveyData}));
                dispatch(changeProgram(programList.find((i) => i.productCountryId === productCountryId)));
                if(nonPrescriber)
                {
                    dispatch(setIsWritePrescription(true));
                    navigate('/prescriber/PrescriberSearch');
                }
                else{
                    navigate('/prescriber/prescriptionForm');
                }
                
            }
        } catch (error) {
            navigate('/prescriber/SystemError');
        }
    };

    const isWritePrescriptionBtnActive = () => {
        if (authorizationStatus) {
            if (
                authorizationStatus.toLowerCase() === 'patient survey required' ||
                authorizationStatus.toLowerCase() === 'active' ||
                authorizationStatus.toLowerCase() === 'mismatched'
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const emptyRow = () => {
        return (
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        );
    };

    return (
        <div className={classes.detailedPatientAuthData}>
            <div className={classes.topRow}>
                <table className="leftCol">
                    <tr className="field">
                        <td className="label">Patient's Date of Birth:</td>
                        <td className="value">{patientDob && formatDate(patientDob)}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">Risk Category: </td>
                        <td className="value" style={{ width: 200 }}>
                            {patientRiskCategoryName && patientRiskCategoryName}
                        </td>
                    </tr>
                    <tr className="field">
                        <td className="label">Patient's Last Survey Date:</td>
                        <td className="value">{patientLastSurveyDate && formatDate(patientLastSurveyDate)}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">
                            Patient's Next Survey Available <br />
                            On:{' '}
                        </td>
                        <td className="value">
                            {patientNextEarliestSurveyDate && formatDate(patientNextEarliestSurveyDate)}
                        </td>
                    </tr>
                </table>

                <table className="rightCol">
                    <tr className="field">
                        <td className="label">Confirmation Number:</td>
                        <td className="value"> {!cancellationNumber ? (confirmationNumber && trimPrefix(confirmationNumber)) : ' '} </td>
                    </tr>
                    <tr className="field">
                        <td className="label">
                            Date Confirmation <br /> Number was Obtained:{' '}
                        </td>
                        <td className="value"> {!cancellationNumber ? dispensationSequences && dispensationSequences.length > 0 && formatDate(dispensationSequences[0]?.dispensationDate): ' '} </td>
                    </tr>
                    <tr className="field">
                        <td className="label">Cancellation Number: </td>
                        <td className="value">{cancellationNumber && cancellationNumber}</td>
                    </tr>
                    <tr className="field">
                        <td className="label">Cancellation Date: </td>
                        <td className="value">{cancelledDate && formatDate(cancelledDate)}</td>
                    </tr>
                </table>
            </div>

            <div className={classes.middleRow}>
                <div className="dispenseTable">
                    <p className="label">Dispense Details</p>
                    <table>
                        <tr>
                            <th>Product Identification</th>
                            <th>Quantity</th>
                        </tr>
                        {dispensationSequences && dispensationSequences.length > 0
                            ? dispensationSequences.filter((each)=>{
                                return !each.deactivationDate;
                            }).map((each) => {
                                  return (
                                      <tr key={each.dispensationId}>
                                          <td>{each.ndcId?.ndcDescription && each.ndcId?.ndcDescription}</td>
                                          <td>{each?.dispensationQuantity && each?.dispensationQuantity}</td>
                                      </tr>
                                  );
                              })
                            : emptyRow()}
                    </table>
                </div>
                <div className="pharmacyHyperLink" style={{ position: 'relative' }}>
                    <p className="label">Pharmacy Location</p>
                    {pharmacyDetails && (
                        <div>
                            <p className="pharmacyName" onClick={pharmacyDetailsToggler}>
                                {pharmacyDetails?.pharmacyName}
                            </p>
                            {isPharmacyDetailsClicked && (
                                <div className="pharmacyDetailsPopUp">
                                    <table>
                                        <tr>
                                            <td>Name:</td>
                                            <td>{pharmacyDetails?.pharmacyName}</td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.addressLine1 &&
                                                        pharmacyDetails.pharmacyInfo.addressLine1 + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.addressLine2 &&
                                                        pharmacyDetails.pharmacyInfo.addressLine2 + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.addressLine3 &&
                                                        pharmacyDetails.pharmacyInfo.addressLine3 + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.addressLine4 &&
                                                        pharmacyDetails.pharmacyInfo.addressLine4 + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.postalCode.city &&
                                                        pharmacyDetails.pharmacyInfo.postalCode.city + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.postalCode.state.stateName &&
                                                        pharmacyDetails.pharmacyInfo.postalCode.state.stateName + ' '}
                                                </span>
                                                <span>
                                                    {pharmacyDetails.pharmacyInfo.postalCode.postalCode1 &&
                                                        pharmacyDetails.pharmacyInfo.postalCode.postalCode1 + ' '}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td>{pharmacyDetails?.pharmacyInfo?.pharmacyPhoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Fax:</td>
                                            <td>{pharmacyDetails?.pharmacyInfo?.pharmacyFaxNumber}</td>
                                        </tr>
                                    </table>
                                    <div
                                        className="closeIcon"
                                        onClick={() => {
                                            setIsPharmacyDetailsClicked(false);
                                        }}
                                    >
                                        <FaWindowClose color="#4b6075" />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className={classes.bottomRow}>
                <div className={classes.buttonWrapper}>
                    {isWritePrescriptionBtnActive() ? (
                        <div className="activeButton" style={{ cursor: 'pointer' }} onClick={handleWritePrescription}>
                            <img src={activeWritePrescriptionBtn} alt="active write prescription button" />
                        </div>
                    ) : (
                        <div className="disabledButton" style={{ cursor: 'pointer' }}>
                            <img src={greyedWritePrescriptionBtn} alt="disabled write prescription button" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetailedPatientAuthTable;
