import CancelModal from "components/CancelModal";
import ReviewWaitScreen from "components/ReviewWaitScreen";
import SessionTimeout from "components/SessionTimeout";
import WaitScreen from "components/WaitScreen";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import { resetLogMultipleDispense, setLogMultipleDispense } from "store/slices/appSlice";
import Confirmation from "./confirmation";
import DefaultLocation from "./defaultLocation";
import classes from "./logDispense.module.scss";
import { LogDispenseScreens } from "./logDispenseType";
import Review from "./review";
import SelectDispense from "./selectDispense";
import { reloadFunction } from "utils/utilsCommon";
const LogDispense = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { logMultipleDispense, activePharmacy } = useAppSelector((state) => state.app);
	const { multipleDispensesTable, localNavigation } = logMultipleDispense;
	const [screen, setScreen] = useState(localNavigation === 1 ? LogDispenseScreens.SelectDispense : localNavigation);
	const [showCancel, setShowCancel] = useState(false);
	const [IsWait, setIsWait] = useState(false);
	const [ReviewIsWait, setReviewIsWait] = useState(false);

	useEffect(() => {
		dispatch(
			setLogMultipleDispense({
				...logMultipleDispense,
				localNavigation: screen,
			})
		);
	}, [screen]);
	
	const handleCancelBtn = () => {
		setShowCancel(true);
	}

	const handleCancel = () => {
		setShowCancel(false);
	};

	const handleContinue = () => {
		dispatch(resetLogMultipleDispense());
		navigate('/pharmacy/PharmacyHome');
	};
	useEffect(() => {
        reloadFunction();
    }, []);

	return (
		<>
		<SessionTimeout />
			{showCancel && <CancelModal handleCancel={handleCancel} handleContinue={handleContinue} />}
            {IsWait && <WaitScreen  />}
			{ReviewIsWait && <ReviewWaitScreen  />}
			<div className={classes.logDispense}>
				{screen === LogDispenseScreens.SelectDispense && <SelectDispense handleCancelBtn={handleCancelBtn} setScreen={setScreen} setIsWait={setIsWait}/>}
				{screen === LogDispenseScreens.Review && <Review handleCancelBtn={handleCancelBtn} setScreen={setScreen} setIsWait={setReviewIsWait}/>}
				{screen === LogDispenseScreens.Confirmation && <Confirmation handleCancelBtn={handleCancelBtn} setScreen={setScreen} />}
				{screen === LogDispenseScreens.DefaultLocation && <DefaultLocation />}
			</div>
		</>
	)
}

export default LogDispense;
