import React, { useEffect, useState } from 'react';
import classes from './ppafConfim.module.scss';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import {
    setPatientData,
    setPatientRisk,
    patientInformation as PatientInfo,
    resetPatientSurvey,
    updatePatientSurvey,
    prescriberSurveySearchStore,
    setPatientId,
    setSurveyInfoFromRoute,
    changePPAF,
} from 'store/slices/appSlice';
import Input from 'components/Input';
import { ppfConfirmationContent } from '../../assets/objects/ppafConfirmationContent';
import { ppfConfirmationContentES } from 'assets/objects/ppafConfirmationContentES';
import Alert from 'components/Alert';
import { pdfConverter } from 'utils/byteToPdfConvert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosInstanceSurvey, axiosInstance_2, axiosInstance_PDF } from 'constants/Config/axiosConfig';
import moment from 'moment';
import { ppafConfirmation } from 'assets/helpContent/Spanish/ppafConfirmationSpanish';
import SessionTimeout from 'components/SessionTimeout';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { PatientSurveyFlowType, PatientSurveyScreens } from 'pages/PatientSurvey/PatientSurveyType';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';

// import Survey from './../../assets/images/btn_Patient_Survey.png';
// import Print from './../../assets/images/btn_Print.png';

const PPAFConfirmation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        patientInformation,
        patientResponse,
        patientProgramList: patientArray,
        selectedProgram,
        existingPatientRisk,
        accFormDetails,
        errorValidatePatient,
        prescriberDetails,
        patientSurvey,
    } = useAppSelector((state) => state.app);

    const { currentUser } = useAppSelector((state) => state.login);

    const [errorMessage, setErrorMessage] = useState('');
    const noInputStyle = { color: '#000' };
    const product = selectedProgram.id;
    const { t, i18n } = useTranslation();

    const selectedLanguage = i18n.language;

    

    const productType = mainMenu(product);

    const Prescriber = `${
        prescriberDetails?.firstName?.length > 0 ? prescriberDetails?.firstName : currentUser.firstName
    } ${prescriberDetails?.lastName?.length > 0 ? prescriberDetails?.lastName : currentUser.lastName}`;

    const getContentType = () => {
        return selectedLanguage === 'en' ? ppfConfirmationContent : ppfConfirmationContentES;
    };

    // const age = 22;
    // const riskValues = `${patientInformation.menstrating === 'Yes' ? 'Y' : 'N'}${
    //     patientInformation.surgical === 'Yes' ? 'Y' : 'N'
    // }${patientInformation.natural === 'Yes' ? 'Y' : 'N'}`;

    // const getRiskFlag = () => {
    //     if (
    //         (age >= 10 && age < 18 && ['NNN', 'NNY', 'NYN', 'NYY'].includes(riskValues)) ||
    //         (age < 10 && ['YNN', 'NYN', 'NNY', 'NYY'].includes(riskValues)) ||
    //         (age >= 18 && age < 50 && ['NYN', 'NNY', 'NYY'].includes(riskValues)) ||
    //         (age >= 60 && (['YNN', 'NNN'].includes(riskValues) || patientInformation.menstrating === 'Yes'))
    //     )
    //         setRiskFlag(true);
    //     else return;
    // };

    // useEffect(() => {
    //     return () => {
    //         dispatch(changeHelpContent('ppafConfirmation'));
    //     };
    // }, [dispatch]);

    // useEffect(() => {
    //     getRiskFlag();
    // }, []);
    useEffect(() => {
        const checkagreementPageError = sessionStorage.getItem('ppafConfirm')
        if (!Object.keys(patientResponse).length) {
            setErrorMessage('concurrentFailure');
        }
        if(checkagreementPageError?.includes('Failure')){
            setErrorMessage('concurrentFailure');
        }
    }, []);

    const checkYesNo = (value: string) => {
        return value.toLowerCase() === 'no' || value.toLowerCase() === 'n' ? 'N' : 'Y';
    };

    const getSignatory = () => {
        switch (patientArray.relation) {
            case 'Authorized Representative':
                return 'authorizedSignatory';
            case 'Self':
                return 'self';
            case 'Representante Autorizado':
                return 'authorizedSignatory';
            case 'Yo mismo':
                return 'self';
            default:
                return '';
        }
    };
    const handlePatientSurvey = async () => {
        const _eligiableProduct = {
            patientExtUniqueId: patientResponse.patientExternalUniqueId,
            products: [
                {
                    name: selectedProgram.label,
                    status: 'eligible',
                    surveyType: 'I',
                },
            ],
        };
        dispatch(resetPatientSurvey());
        dispatch(
            updatePatientSurvey({
                ...patientSurvey,
                eligiableProduct: _eligiableProduct,
                localNavigation: PatientSurveyScreens.SelectProduct,
                flowType: PatientSurveyFlowType.PatientEnrollmentPage,
            })
        );
        navigate('/prescriber/patientSurvey', { replace: true });
    };
    const handlePrint = async () => {
        const _prescriberSignature: string = `${
            prescriberDetails?.firstName?.length > 0 ? prescriberDetails?.firstName : currentUser.firstName
        } ${prescriberDetails?.lastName?.length > 0 ? prescriberDetails?.lastName : currentUser.lastName}`;

        try {
            let reqBody: any = {
                patientDto: {
                    ...patientResponse,
                    channelId: 'ext',
                    patientRiskCategoryDto: {
                        gender: patientResponse.patientGender,
                        riskCategory: patientInformation.newRisk,
                        menstruating: checkYesNo(patientInformation.menstrating),
                        naturalMenopause: checkYesNo(patientInformation.natural),
                        riskCategoryDescription: patientInformation.newRisk,
                        surgicalMenopause: checkYesNo(patientInformation.surgical),
                    },
                },
                notificationFax: null,
                notificationEmail: null,
                currentStep: 'prescriberSignature',
                signatory: getSignatory(),
                action: 'print',
                patientSignature: `${patientArray.firstName} ${patientArray?.lastName}`,
                patientSignatureDate: moment(new Date()).format('YYYY-MM-DD'),
                prescriberSignature: _prescriberSignature,
                prescriberSignatureDate: moment(new Date()).format('YYYY-MM-DD'),
                language: i18n.language,
                receiveEducationalMaterial: patientArray.finalTreatmentArragement,
            };
            //Hard code//
            //reqBody.patientDto.patientId = reqBody.patientDto.patientId ? reqBody.patientDto.patientId : 243130;
            //reqBody.patientDto.patientPrescribers[0].patientId =  reqBody.patientDto.patientId;
            /**********************/
            const response = await axiosInstance_PDF.post('/file-render/pdf/ppaf', reqBody);
            if (response.data.statusCode === 200) {
                //pdfConverter(response.data.data.pdf);
                sessionStorage.setItem('baseString', JSON.stringify(response.data.data.pdf));
                sessionStorage.setItem('fileName', JSON.stringify('patientPartialPPAF'));

                window.open('/#/pdfViewer', '_blank');
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`Error getting pdf ${err}`);
        }
    };

    const renderError = (productType, errorMessage) => {
        if (errorMessage === 'concurrentFailure') {
            return (
                <Alert
                    content={`${t('concurrentFailure1')} ${productType} ${t('concurrentFailure2')}`}
                   /*  ${
                        productType === 'THALOMID REMS'
                            ? `<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span>`
                            : `<span></span>`
                    }  */
                    styles={{
                        margin: '10px 20px 5px 20px',
                        textAlign: 'center',
                        fontSize: '0.65em',
                        fontWeight: 'bold',
                    }}
                />
            );
        } else {
            return (
                <Alert
                    content={`${t('riskFlag1')} ${productType} ${t('riskFlag2')}`}
                    styles={{
                        margin: '10px 20px 5px 20px',
                        textAlign: 'center',
                        fontSize: '0.65em',
                        fontWeight: 'bold',
                    }}
                />
            );
        }
    };

    const handlePrescriberSurvey = async () => {
        const resp = await axiosInstance_2.post('/findPatient', {
            patientExternalUniqueId: patientResponse.patientExternalUniqueId,
        });
        if (resp.data.message === 'SUCCESS') {
            dispatch(prescriberSurveySearchStore({}));
            dispatch(setPatientData(resp.data.data));
            dispatch(setPatientId(resp.data.data.patientId));
            const payload = {
                Channel: 'EXT',
                PrescriberId: prescriberDetails?.prescriberSystemId,
                PatientId: resp.data.data.patientId,
                ProductCountryId: selectedProgram?.productCountryId,
                LanguageId: i18n.language === 'es' ? 2 : 1,
            };
            let response = await axiosInstanceSurvey
                .post('/v1/surveys/ivr-validate-prescriber-survey-eligibility', payload)
                .then((res: any) => {
                    if (res.data?.ResponseStatus === '0') {
                        //Store response received in redux instead of passing it this way
                        dispatch(setSurveyInfoFromRoute(res.data))
                        dispatch(changePPAF(true));
                        navigate('/prescriber/prescribersurveyinformation');
                    } else if (res.data?.ResponseStatus === '1') {
                        dispatch(changePPAF(true));
                        if (res.data.SurveyIneligibleMessage) {
                        dispatch(setSurveyInfoFromRoute(res?.data?.SurveyIneligibleMessage))
                            navigate('/prescriber/prescribersurveyinformation');
                        } else {
                            const errorMessage = 'program not active';
                            dispatch(setSurveyInfoFromRoute(errorMessage))

                            navigate('/prescriber/prescribersurveyinformation');
                        }
                    }
                })
                .catch((error) => {
                    navigate('/prescriber/SystemError');
                });
        }
    };

    const routeToPrescriptionForm = async() => {
        const resp = await axiosInstance_2.post('/findPatient', {
            patientExternalUniqueId: patientResponse.patientExternalUniqueId,
        });
        if (resp.data.message === 'SUCCESS') {
            dispatch(setPatientData(resp.data.data));
            navigate('/prescriber/prescriptionForm')}
        }
    
    ;

    return (
        <>
            <SessionTimeout />
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    {product && <span className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(product).headerImage}`)})`
                    }}></span>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(product).color}`
                    }}></div>
                </div>
                {/* <div className={classes.header}></div> */}
                <div className={classes.productHeader}>
                    {!patientInformation?.riskFlag ? getContentType().subheader : t('ppafSubheaderFlagged')}
                </div>
                <div className={classes.contentDivider}></div>
                <div className={classes.mainContainer}>
                    <div className={classes.mainContainer_inputs}>
                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="Patient"
                            label={t('patient')}
                            valueExist={patientInformation?.firstName + ' ' + patientInformation?.lastName || ''}
                        />
                        <Input
                            type="text"
                            noInputStyle={noInputStyle}
                            labelStyle={noInputStyle}
                            noInput={true}
                            name="Prescriber"
                            label={t('prescriber')}
                            valueExist={Prescriber}
                        />
                    </div>

                    {patientInformation?.riskFlag || errorMessage ? (
                        renderError(productType, errorMessage)
                    ) : (
                        <div className={classes.textContainer}>
                            <div className={classes.textContent}>
                                {t('ppafSuccess1')}
                                {/* {productType}
                                {productType === 'THALOMID REMS' && (
                                    <span style={{ top: '-0.5em', position: 'relative', fontSize: '0.8em' }}>®</span>
                                )}
 */}                            <span dangerouslySetInnerHTML={{ __html: productType }}></span>
                                {t('ppafSuccess2')}
                            </div>
                            {getContentType().body.map((item) => (
                                <div
                                    className={classes.textContent}
                                    key={item.order}
                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                ></div>
                            ))}
                            {/* <div className={classes.textContent}>
                    The patient is now successfully enrolled for Lenalidomide REMS. Please follow the steps detailed
                    below:
                </div>
                <div className={classes.textContent}>
                    1. If a patient survey is required, please have your patient complete the survey now.
                </div>
                <div className={classes.textContent}>2. Complete the Prescriber survey.</div>
                <div className={classes.textContent}>3. Write the prescription and send to the pharmacy.</div> */}
                        </div>
                    )}

                    {patientInformation?.riskFlag &&
                        patientInformation.newRisk &&
                        existingPatientRisk &&
                        existingPatientRisk !== patientInformation.newRisk && (
                            <p className={classes.risk_warning}>
                                {t('ppafRiskChange1')} <strong>{existingPatientRisk} </strong>
                                {t('ppafRiskChange2')}
                                <br />
                                {t('ppafRiskChange3')}
                            </p>
                        )}

                    {/* buttons  */}
                    {errorMessage === 'concurrentFailure' ? (
                        <div className={classes.flag_btn}>
                            <Button
                                clickFunction={() => {
                                    navigate('/prescriber/MainMenu');
                                    dispatch(PatientInfo({}));
                                    dispatch(setPatientRisk(null));
                                    dispatch(setPatientData({}));
                                }}
                                className={getBtnClass(selectedProgram.id)}
                                width={107}
                                height={49}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                            ></Button>
                        </div>
                    ) : (
                        <div>
                            {patientInformation?.riskFlag ? (
                                <div className={classes.btn_group}>
                                    <div className={classes.flag_btn}>
                                        
                                        <Button
                                            clickFunction={() => {
                                                navigate('/prescriber/MainMenu');
                                                dispatch(PatientInfo({}));
                                                dispatch(setPatientRisk(null));
                                                dispatch(setPatientData({}));
                                            }}
                                            className={getBtnClass(selectedProgram.id)}
                                            width={107}
                                            height={49}
                                            size={18}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                                        ></Button>
                                    </div>
                                    <div className={classes.print_flag}>
                                       
                                        <Button
                                            clickFunction={handlePrint}
                                            className={getBtnClass(selectedProgram.id)}
                                            width={120}
                                            height={47}
                                            size={16}
                                            type="text"
                                            buttonname={i18n.language === 'es' ? buttonContent.es.print : buttonContent.en.print}
                                    ></Button>
                                        <div className={classes.AdobeInformation}>
                                            <a href="https://get.adobe.com/reader/" target="_blank">
                                                <span className={classes.getAdobeReaderBtn}></span>
                                            </a>
                                            <p>
                                                {t('print')}
                                                <span className={classes.legalSymbol}>®</span> {t('reader')}
                                                <span className={classes.legalSymbol}>®</span>. {t('download')}
                                            </p>
                                            {selectedLanguage === 'es' && (
                                                <p>
                                                    {t('print_en')}
                                                    <span className={classes.legalSymbol}>®</span> {t('reader_en')}
                                                    <span className={classes.legalSymbol}>®</span>. {t('download_en')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className={classes.btn_group}>
                                        
                                            <Button
                                                    clickFunction={() => handlePatientSurvey()}
                                                    className={getBtnClass(selectedProgram.id)}
                                                    width={154}
                                                    height={47}
                                                    size={16}
                                                    type="text"
                                                    buttonname={i18n.language === 'es' ? buttonContent.es.patSurvey : buttonContent.en.patSurvey}
                                                ></Button>
                                      
                                                <Button
                                                        clickFunction={handlePrescriberSurvey}
                                                        className={getBtnClass(selectedProgram.id)}
                                                        width={260}
                                                        height={47}
                                                        size={16}
                                                        type="text"
                                                        buttonname={i18n.language === 'es' ? buttonContent.es.presSurvey : buttonContent.en.presSurvey}
                                                ></Button>
                                       
                                        
                                                <Button
                                                    clickFunction={routeToPrescriptionForm}
                                                    className={getBtnClass(selectedProgram.id)}
                                                    width={193}
                                                    height={47}
                                                    size={16}
                                                    type="text"
                                                    buttonname={buttonContent.en.writePres}
                                                ></Button>
                                    </div>
                                    <div className={classes.print}>
                                        
                                                <Button
                                                    clickFunction={handlePrint}
                                                    className={getBtnClass(selectedProgram.id)}
                                                    width={120}
                                                    height={47}
                                                    size={16}
                                                    type="text"
                                                    buttonname={i18n.language === 'es' ? buttonContent.es.print : buttonContent.en.print}
                                            ></Button>
                                        <div className={classes.AdobeInformation}>
                                            <a href="https://get.adobe.com/reader/" target="_blank">
                                                <span className={classes.getAdobeReaderBtn}></span>
                                            </a>
                                            <p>
                                                {t('print')}
                                                <span className={classes.legalSymbol}>®</span> {t('reader')}
                                                <span className={classes.legalSymbol}>®</span>. {t('download')}
                                            </p>
                                            {selectedLanguage === 'es' && (
                                                <p>
                                                    {t('print_en')}
                                                    <span className={classes.legalSymbol}>®</span> {t('reader_en')}
                                                    <span className={classes.legalSymbol}>®</span>. {t('download_en')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PPAFConfirmation;
