import ProductBackground from 'components/ProductBackground/productBackground';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import classes from './PatientSurvey.module.scss';
import { PatientSurveyFlowType, PatientSurveyScreens } from './PatientSurveyType';
import { patientConductSurvey, searchPatientSurvey, updatePatientSurvey } from 'store/slices/appSlice';
import { useState } from 'react';

import { useNavigate } from 'react-router';
import Button from 'components/Button';
import { buttonContent } from 'assets/objects/buttonContent';
const Review = ({ setScreen }) => {
    const { t, i18n } = useTranslation();
    const { patientSurvey } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const { eligiableProduct } = useAppSelector((state) => state.app.patientSurvey);
    const [submitSelected, setSubmitSelected] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleRestart = () => {
        setScreen(PatientSurveyScreens.Restart);
    };

    const updateEligibleProduct = () => {
        const { patientFirstName, patientLastName, patientExtUniqueId } = eligiableProduct;
        const payload = {
            patientFirstName: patientFirstName,
            patientLastName: patientLastName,
            patientExtUniqueId: patientExtUniqueId,
            language: i18n.language,
            createdBy: currentUser?.userinfo?.email || null,
            ModifiedBy: currentUser?.userinfo?.email || null,
        };
        dispatch(searchPatientSurvey(payload))
            .then(() => {
                setScreen(PatientSurveyScreens.Disclaimer);
            })
            .catch(() => {
                setScreen(PatientSurveyScreens.Disclaimer);
            });
    };

    const searchResponseHandler = (response) => {
        if (response.code === 'ERR_NETWORK') {
            navigate('/prescriber/SystemError');
        } else if (response.status === 200) {
            if (
                patientSurvey.flowType === PatientSurveyFlowType.SearchPage ||
                patientSurvey.flowType === PatientSurveyFlowType.HomePage
            ) {
                updateEligibleProduct();
            } else {
                setScreen(PatientSurveyScreens.Disclaimer);
            }
        } else if (response.response.status === 400) {
            dispatch(
                updatePatientSurvey({
                    ...patientSurvey,
                    businessErrorCode: response?.response?.data?.data?.errorMesssage,
                })
            );
            setScreen(PatientSurveyScreens.Error);
        } else {
            navigate('/prescriber/SystemError');
        }
    };

    const handleSubmit = () => {
        setSubmitSelected(true);
        const _payload = {
            ...patientSurvey?.updatedSurveyPayload,
            PatientSurvey: {
                ...patientSurvey?.updatedSurveyPayload.PatientSurvey,
                IsPatientFlag: 'Y',
            },
            ChannelId: 'ext',
            Language: i18n.language,
        };
        dispatch(patientConductSurvey(_payload))
            .then((response) => {
                searchResponseHandler(response.payload);
                setSubmitSelected(false);
            })
            .catch(() => {
                setSubmitSelected(false);
            });
    };

    const { updatedSurveyPayload, selectedSurveyProduct } = useAppSelector((state) => state.app.patientSurvey);
    return (
        <div className={classes.restartSurvey}>
            <ProductBackground
                product={selectedSurveyProduct?.PatientSurvey?.ProductName}
                subTitle={t('patientSurveyReview')}
            >
                <div className={`${classes[i18n.language]} ${classes.bodyContainer}`}>
                    <div>
                        {updatedSurveyPayload?.PatientSurvey?.QuestionAnswers?.map((item, index) => {
                            return (
                                <>
                                    <div className={classes.question}>
                                        {`${index + 1}. ${item?.Question?.QuestionText}`}
                                    </div>
                                    <div className={classes.surveyAnswer}>
                                        {`${t('yourAnswer')}: ${item?.Answer?.ResponseDisplay}`}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className={classes.reviewButtons}>
                        <Button
                            width={118}
                            height={45}
                            size={18}
                            clickFunction={() => {
                                handleSubmit();
                            }}
                            buttonname={i18n.language === 'en' ? buttonContent.en.submit : buttonContent.es.submit}
                            type="text"
                            className={submitSelected ? 'selectStyle' : 'buttonStyles'}    
                            disabled={submitSelected}                        
                        />
                        <Button
                            width={i18n.language === 'en' ? 294 : 336}
                            height={45}
                            size={18}
                            clickFunction={() => {
                                handleRestart();
                            }}
                            buttonname={
                                i18n.language === 'en'
                                    ? buttonContent.en.cancelAndRestartSurvey
                                    : buttonContent.es.cancelAndRestartSurvey
                            }
                            type="text"
                            className={'buttonStyles'}
                        />
                    </div>
                    {submitSelected && <p className={classes.contineSubmissionMsg}>{t('message_PM0830')}</p>}
                </div>
            </ProductBackground>
        </div>
    );
};

export default Review;
