import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './prescriberHome.module.scss';
import { openWindow } from '../../utils/openWindow';
import { useAppDispatch, useAppSelector } from 'store';
import { changeProgram, prescriberHomeThunk } from '../../store/slices/appSlice';
import {PROGRAMS,ProgramConfig, ProgramConfigIMG} from 'constants/Config/programsConfig';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';

const PrescriberHome = () => {
    const programList:any = PROGRAMS;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { prescriberHome, prescAllDetails } = useAppSelector((state) => state.app);

    useEffect(() => {
        dispatch(prescriberHomeThunk('prescriberHome'));
    }, [dispatch]);

    const onProgramClick = (program) => {
        if(!program?.disabled){
            dispatch(changeProgram(programList.find((i) => i.id === program?.id)));
            navigate('/prescriber/productRegistration');
        }
    };

    const onPrescribingInfoClick = (url) => {
        openWindow(
            url,
            '',
            `location=0,status=1,scrollbars=1,resizable=yes,width=${window.screen.availWidth * (11 / 12)},height=600`
        );
        return false;
    };

    const onMedicationGuideClick = (url) => {
        openWindow(
            url,
            '',
            `location=0,status=1,scrollbars=1,resizable=yes,width=${window.screen.availWidth * (11 / 12)},height=600`
        );
        return false;
    };

    const checkProgramEnabled = (label) => {
        if(Object.keys(prescAllDetails).length > 0){
            return prescAllDetails?.products.find(product => product?.name.toLowerCase() === label.toLowerCase())
        }else{
            return false;
        }
    }
   
    const programsEnabled = programList.map((program, index) =>
    ({
        ...program,
        disabled: checkProgramEnabled(program.label)
    })
    )
   

    return (
        <div style={{ marginTop: '10px' }}>
            <PageBackground label="Choose Program">
                <>
                <SessionTimeout/>
                    <div
                        className={classes.subHeader}
                        dangerouslySetInnerHTML={{ __html: prescriberHome.header[0]?.content }}
                    ></div>
                    <div className={classes.contentContainer}>
                        {prescriberHome?.body?.map((program, index) => (
                            <div className={classes.column} key={program.order}>
                                <div className={classes.productDisclaimerText}>
                                    <div
                                        className={classes.center}
                                        style={{ padding: '12px', paddingBottom: '16px' }}
                                        dangerouslySetInnerHTML={{ __html: program?.title }}
                                    ></div>

                                    <div className={classes.center}>
                                        <div tabIndex={0} onClick={() => onProgramClick(programsEnabled[index])}>
                                            <span
                                                className={`${programsEnabled[index].disabled ? classes.disabledProgram : ''} ${classes[programList[index].id]} ${classes.programImg}`}
                                                style={{
                                                    backgroundImage: `url(${require(`../../assets/images/${ProgramConfigIMG(programList[index].id)}`)})`
                                                }}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className={classes.productDisclaimerContainer}>
                                        <div className={classes.pb5} >
                                            <span
                                                dangerouslySetInnerHTML={{ __html: program?.content?.disclaimer1 }}
                                            ></span>
                                            <span tabIndex={0}
                                                onClick={() => onPrescribingInfoClick(program?.prescriberInfoURL)}
                                                className={classes.links}
                                            >
                                                Prescribing Information
                                            </span>{' '}
                                            {program?.content?.disclaimer2}
                                        </div>
                                        <div className={classes.slimPad}>
                                            <b>{program?.content?.lists?.title}</b>
                                            <br />
                                            <br />
                                            <ul className={classes.ul}>
                                                {program?.content?.lists?.content.map((i) => (
                                                    <li key={i} dangerouslySetInnerHTML={{ __html: i }}></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={`${classes.slimPad} ${classes.center}`}>
                                        <div tabIndex={0}
                                            className={`${classes.links} ${classes.noDecoration}`}
                                            onClick={() => onPrescribingInfoClick(program?.prescriberInfoURL)}
                                        >
                                            Prescribing Information&nbsp;
                                            <span className={classes.rightArrow}></span>
                                        </div>
                                    </div>
                                    <div className={`${classes.slimPad} ${classes.center}`}>
                                        <div tabIndex={0}
                                            className={`${classes.links} ${classes.noDecoration}`}
                                            onClick={() => onMedicationGuideClick(program?.medicationURL)}
                                        >
                                            Medication Guide&nbsp;<span className={classes.rightArrow}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            </PageBackground>
        </div>
    );
};

export default PrescriberHome;
