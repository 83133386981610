import React, { ReactNode, useEffect, useRef } from 'react';
import classes from './PageBackground.module.scss';
import Button from './../Button';
import { ProgramConfig, ProgramConfigIMG } from 'constants/Config/programsConfig';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export type PageBgProps = {
    btn?: boolean;
    label: string;
    product?: string;
    children: ReactNode;
    handleClick?: () => void;
    handleReviseBtn?: () => void;
    handleCancelBtn?: () => void;
    handlePrintBtn?: () => void;
    signOn?: boolean;
    pharmacy?: boolean;
    dispense?: boolean;
    fromComp?: string;
    loader?:boolean;
};

const PageBackground = ({
    label,
    children,
    product,
    btn,
    handleClick,
    handleReviseBtn,
    handleCancelBtn,
    handlePrintBtn,
    signOn,
    pharmacy,
    dispense,
    fromComp,
    loader
}: PageBgProps) => {
  
    return (
        <div
            className={`${pharmacy ? classes.eccpContainer : dispense ? classes.dispense : ''} ${
                classes.mainContainer
            }`}
        >
            <div className={classes.header}>
                <div className={classes.headerContainer}>
                    <span className={classes.icon}></span>
                    <div className={classes.programLogo}>
                        <div className={pharmacy ? classes.pharmacytitle : classes.title}>{label}</div>
                        {product && <span className={` ${classes.img_icon}`}
                        style={{
                            backgroundImage: fromComp === 'ProductRegSignUpConf' 
                            ? `url(${require(`../../assets/images/${ProgramConfig(product)?.prodRegistrationImage}`)})` 
                            : `url(${require(`../../assets/images/${ProgramConfigIMG(product)}`)})`
                        }}
                        ></span>}
                    </div>
                </div>
            </div>
            {children}
            <div className={classes.flexContain}>
                {handleReviseBtn && (
                    <Button
                        clickFunction={handleReviseBtn}
                        className="mr10"
                        width={119}
                        height={48}
                        size={18}
                        type="text"
                        buttonname="Revise"
                    ></Button>
                )}
                {btn && (<>
                    <Button
                        clickFunction={handleClick}
                        className="buttonStyles"
                        type="text"
                        width={signOn === true ? 200 : 169}
                        height={48}
                        size={18}
                        buttonname={signOn === true ? 'Sign and Continue' : 'Continue'}
                        disabled={loader}
                    ></Button>
                    { loader && ( 
                        <div className={classes.loader}>
                            <Flex align="center" gap="middle">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                            </Flex>
                        </div>)
                    }

                    </>
                )}
                {handleCancelBtn && (
                    <Button
                        clickFunction={handleCancelBtn}
                        className="mr10"
                        width={119}
                        height={48}
                        size={18}
                        type="text"
                        buttonname="Cancel"
                    ></Button>
                )}
                {handlePrintBtn && (
                    <Button
                        clickFunction={handlePrintBtn}
                        className="mr10"
                        width={119}
                        height={48}
                        size={18}
                        type="text"
                        buttonname="Print"
                    ></Button>
                )}
            </div>
        </div>
    );
};

export default PageBackground;
