import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import _ from 'lodash';
import { axiosInstance, axiosInstanceNewUrl, axiosInstanceSearch, axiosInstance_2, axiosInstance_ORC } from 'constants/Config/axiosConfig';
import { patientInfoFields } from 'constants/patientInformationFields';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { getPrescription, patientInformation, patientPrescriberStore,resetpatientPrescriberStore, setPrescriptionFormData, setPrescriptionFormDetailsData } from 'store/slices/appSlice';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import classes from './PrescriptionFormDetails.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import DatePicker from 'react-datepicker';
import { CalendarOutlined } from '@ant-design/icons';
import { isValidDate, isValidMckessonAcc } from 'utils/regex';
import './reactDatePicker.css';
import {
    isValidAddr,
    isValidCity,
    isValidMI,
    isValidName,
    isValidOtherDiag,
    isValidPatientNum,
    isValidPhoneNumber,
    isValidZip,
} from 'utils/regex';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import { ProgramConfig } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';

interface dropDownType {
    State?: { id: string; value: string }[] | null;
}

const PrescriptionFormDetails =()=>{
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const {
        IsAuthenticated
    } = useSelector((state: RootState) => state.login);
    const {
        selectedProgram,
        patientInformation: patientFields,
        role,
        isSubmitAndPrint,
        isAccount,
        patientPrescriberStore ,
        prescriptionFormData,
        prescriberDetails,
        prescriptionFormDetailsData
        
    } = useSelector((state: RootState) => state.app);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [dropdown, setDropdown] = useState<dropDownType>({ State: []});
    const [selectedShipOption,setSelectedShipOption] = useState('shipVha')
    const [showShipDirect,setShowShipDirect] = useState(false);
    const selectedFormIsVHA =prescriptionFormData?.formType === "VHAForm" ? true : false; 
    const [servererror, setServerError] = useState('');
    const [values, setValues] = useState({
        dateRxNeeded : new Date(),
        firstName:'',
        lastName:'',
        dob:'',
        pin:'',
        homeAddress1:'',
        homeAddress2:'',
        homePhone:'',
        vhaName:'',
        vhaAddress:'',
        vhaCity:'',
        vhaState:'',
        vhaZip:'',
        vhaExt:'',
        vhaPharmName:'',
        vhaPharmPhone:'',
        vhaPhaFax:'',
        mckAccNo:'',
        shipVHAorDirect:'',
        shipAddress:'',
        shipCity:'',
        shipState:'',
        shipZip:'',
        shipExt:'',
        shipPhone:'',
        authNo:"",
        authStatus:"",
        payloadData:{}
    });
    
    const prescriberId = prescriberDetails?.prescriberSystemId;
    
    const patientId = prescriptionFormData?.patientDto?.patientId;
    const productCountryId = selectedProgram?.productCountryId;
    const handleShipOption = (value)=>{
        setSelectedShipOption(value);
        if(value === "shipDirect"){
            setShowShipDirect(true);
            setValues((prev) => ({
                ...prev,
                shipVHAorDirect: "SHIP_TO_PATIENT",
            }));
        }
        else{
            setShowShipDirect(false);
            setValues((prev) => ({
                ...prev,
                shipVHAorDirect: "SHIP_TO_VHA",
            }));
        }
    }
    const fetchDropdown = async () => {
        fetchDatafromS3json().then((res) => {
            setDropdown({ State: res?.state });
        });
    };
    useEffect(() => {
            //setShowShipDirect(!selectedFormIsVHA);
            fetchDropdown();
            if(Object.entries(prescriptionFormDetailsData).length === 0)
            {
                setValues((prev) => ({
                    ...prev,
                    shipVHAorDirect:!selectedFormIsVHA ?  "SHIP_TO_PATIENT" : "SHIP_TO_VHA",
                }));
                const payload = {
                    patientId :patientId,
                    prescriberId :prescriberId,
                    productCountryId :productCountryId,
                    dropdown:dropdown
                }
                dispatch(getPrescription({data:payload})).then((response:any) =>{
                    if(response?.payload?.status === 204)
                    {
                        const patientDTO = prescriptionFormData?.patientDto;  
                        const patientAddress2 = patientDTO?.patientLocations[0]?.postalCode?.city +", " +
                        patientDTO?.patientLocations[0]?.postalCode?.state?.stateCode +" " +
                        patientDTO?.patientLocations[0]?.postalCode?.postalCode1;
                        setValues((prev) => ({
                            ...prev,    
                            
                            firstName:patientDTO?.patientNameFirst ||"",                  
                            lastName:patientDTO?.patientNameLast ||"",
                            dob:patientDTO?.patientDob || "",
                            pin:patientDTO?.patientExternalUniqueId || "",
                            homeAddress1:patientDTO?.patientLocations[0]?.addressLine1 || "",
                            homeAddress2:patientAddress2,
                            homePhone:patientDTO?.patientLocations[0]?.phoneNumber || "",
                            shipAddress : patientDTO?.patientLocations[0]?.addressLine1 || "",
                            shipCity: patientDTO?.patientLocations[0]?.postalCode?.city|| "",
                            shipState : patientDTO?.patientLocations[0]?.postalCode?.state?.stateCode ||"",
                            shipZip :patientDTO?.patientLocations[0]?.postalCode?.postalCode1||"",
                            shipPhone:patientDTO?.patientLocations[0]?.phoneNumber || "",
                            authNo:"",
                            authStatus:"",
                            shipVHAorDirect:!selectedFormIsVHA ?  "SHIP_TO_PATIENT" : "SHIP_TO_VHA",
                            payloadData: {},
                            
                        }));
                       
                        setShowShipDirect(!selectedFormIsVHA);
                        if(selectedFormIsVHA)
                        {
                            setSelectedShipOption('shipVha');
                        }
                        else
                        {
                            setShowShipDirect(true);
                            setSelectedShipOption('shipDirect');
                        }
     
                    }
                    else if(response?.payload?.status === 200)
                    {
                        const shipVal = response.payload?.data?.data?.vhaShippingType;//response.payload?.data?.data?.prescriptionTypeId?.lookupValue;

                        if(selectedFormIsVHA)
                        {
                            if(shipVal === "SHIP_TO_VHA" || shipVal === "" || shipVal=== null)
                            {
                                setSelectedShipOption('shipVha');
                            }
                            else
                            {
                                setShowShipDirect(true);
                                setSelectedShipOption('shipDirect');
                            }
                        }
                        else
                        {
                            setShowShipDirect(true);
                            setSelectedShipOption('shipDirect');
                        }
                       
                    }
                   
                });
            }
            else{
                const newFields = { ...prescriptionFormDetailsData };
                setValues((prev) => ({
                    ...prev,
                    ...newFields,
                }));
                if(newFields?.shipVHAorDirect === "SHIP_TO_VHA" || newFields?.shipVHAorDirect === "" ||newFields?.shipVHAorDirect === null)
                {
                    setShowShipDirect(false);
                    setSelectedShipOption('shipVha');
                }
                else
                {
                    setShowShipDirect(true);
                    setSelectedShipOption('shipDirect');
                }
                if(!selectedFormIsVHA){
                    setShowShipDirect(true);
                    setSelectedShipOption('shipDirect');
                }
            }
           
        }, []);
       
        useEffect(() => {
            const newFields = { ...prescriptionFormDetailsData };
                setValues((prev) => ({
                    ...prev,
                    ...newFields,
                }));
                if(newFields?.shipVHAorDirect === "SHIP_TO_VHA" || newFields?.shipVHAorDirect === "" ||newFields?.shipVHAorDirect === null)
                {
                    setShowShipDirect(false);
                    setSelectedShipOption('shipVha');
                }
                else
                {
                    setShowShipDirect(true);
                    setSelectedShipOption('shipDirect');
                }

                if(!selectedFormIsVHA){
                    setShowShipDirect(true);
                    setSelectedShipOption('shipDirect');
                }
                
         },[prescriptionFormDetailsData]);
         useEffect(() => {
           reloadFunction();
        }, []);

    useEffect(() => {  
        if(!IsAuthenticated) { 
            navigate('/prescriber/login', { replace: true });
           }

        }, [IsAuthenticated]);
        const handleOnChange = (e) => {
           
            error && setError('');
            const { name, value } = e.target;
            setValues((prev) => ({
                ...prev,
                [name]: value,
            }));
        };
         const handleDateChange = (e) => {

            if(e?.target === undefined){
                const checkDate = moment(e, 'MM/DD/YYYY').isValid();
                const year =moment(e).year()
                if (checkDate && (year <= 9999)) {
                    setValues((prev) => ({
                        ...prev,
                        dateRxNeeded: e,
                    }));
                    return
                } else {
                    e=moment(new Date(), 'MM/DD/YYYY');
                    setValues((prev) => ({
                        ...prev,
                        dateRxNeeded: e,
                    }));
                    setError(t('patientDOBError'));
                    
                    setCurrentFocus('dateRxNeeded1');
                    scrollToView();
                    return;
                }
                
            }else{
                const checkDate = moment(e.target.value, 'MM/DD/YYYY').isValid();
                const year =moment(e.target.value).year()
                if (checkDate && (year <= 9999)) {
                    const { name, value } = e.target;
                    setValues((prev) => ({
                        ...prev,
                        dateRxNeeded: value,
                    }));
                    return
                } else {
                    e.target.value = moment(new Date()).format("MM/DD/YYYY");
                    setValues((prev) => ({
                        ...prev,
                        dateRxNeeded: e.target.value,
                    }));
                    setError( t('patientDOBError'));
                    
                    setCurrentFocus('dateRxNeeded1');
                    scrollToView();
                    return;
                }

                
            }
        }; 
        const scrollToView = () => {
            containerRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        };
        const trimValues = () => {
            const newVals = { ...values };
            Object.keys(newVals).forEach((field) => {
                newVals[field] = !['dateRxNeeded','payloadData'].includes(field) ? newVals[field]?.trim() : newVals[field];
            });
            setValues(newVals);
            return newVals;
        };
        
        const checkAddressVHA = async () => {
            const stateId = dropdown?.State?.find((o) => o.value === values.vhaState.trim())?.id;
            if (stateId) {
                const addressPayload = { zipCode: values.vhaZip, city: values.vhaCity.toUpperCase().trim(), state: +stateId };
                let response;
                response = await axiosInstanceSearch.post(`/validate/address`, addressPayload);
                if (response?.data?.message === 'Success') {
                    return true;
                } else {
                    setServerError(t('patientZipCombinationError'));
                    scrollToView();
                    return false;
                }
            } else {
                scrollToView();
                return false;
            }
        };

        const checkAddressShipping = async () => {
            const stateId = dropdown?.State?.find((o) => o.value === values.shipState.trim())?.id;
            if (stateId) {
                const addressPayload = { zipCode: values.shipZip, city: values.shipCity.toUpperCase().trim(), state: +stateId };
                let response;
                response = await axiosInstanceSearch.post(`/validate/address`, addressPayload);
                if (response?.data?.message === 'Success') {
                    return true;
                } else {
                    setServerError(t('patientZipCombinationError'));
                    scrollToView();
                    return false;
                }
            } else {
                scrollToView();
                return false;
            }
        };
        const dateValidation =()=>{
            
            const todayDate = moment().format('MM/DD/YYYY');
            const rxDate=  moment(values.dateRxNeeded).format('MM/DD/YYYY');
            if(values.dateRxNeeded !==null || values.dateRxNeeded!== "")
            {
                if(moment(rxDate).isSameOrAfter(todayDate,'day'))
                {                   
                    return true;
                }
                else{
                    return false;
                }
            }
            else{               
                return false;
            }
        }

    const validation=()=>{
        const checkDateRx= dateValidation();
        if(!checkDateRx)
        {
            if(values.dateRxNeeded ===null ){
                setError(t('dateRX_PV0436'));
            }
            else{
                setError(t('dateRX_other'));
            }
            
            setCurrentFocus('dateRxNeeded');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidName(values.vhaName.trim())){
            setError(t('vhaName_PV0415'));
            setCurrentFocus('vhaName');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidAddr(values.vhaAddress.trim()))
        {            
            setError(t('patientAddressError'));
            setCurrentFocus('vhaAddress');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidCity(values.vhaCity.trim()))
        {
            setError(t('patientCityError'));
            setCurrentFocus('vhaCity');
            scrollToView();
            return; 
        }
        else if(selectedFormIsVHA && (values.vhaState === "" || values.vhaState === "Select a State"))
        {
            setError(t('patientStateError'));
            setCurrentFocus('vhaState');
            scrollToView();
            return; 
        }
        
        else if(selectedFormIsVHA && !isValidZip(values.vhaZip.trim()))
        {
            setError(t('patientZipError'));
            setCurrentFocus('vhaZip');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidName(values.vhaPharmName.trim())){
            setError(t('vhaPharmName_PV0420'));
            setCurrentFocus('vhaPharmName');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidPhoneNumber(values.vhaPharmPhone.trim())){
            setError(t('patientPhoneError'));
            setCurrentFocus('vhaPharmPhone');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidPhoneNumber(values.vhaPhaFax.trim())){
            setError(t('fax_errMsg'));
            setCurrentFocus('vhaPhaFax');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && !isValidName(values.vhaPhaFax.trim())){
            setError(t('fax_errMsg'));
            setCurrentFocus('vhaPhaFax');
            scrollToView();
            return false;
        }
        else if(selectedFormIsVHA && values.mckAccNo.length >0 && !isValidMckessonAcc(values.mckAccNo)){
            setError(t('mackessonAccValidation'));
            setCurrentFocus('mckAccNo');
            scrollToView();
            return false;
        }
        else if(showShipDirect  && !isValidAddr(values.shipAddress))
        {            
            setError(t('patientAddressError'));
            setCurrentFocus('shipAddress');
            scrollToView();
            return false;
        }
        else if(showShipDirect && !isValidCity(values.shipCity.trim()))
        {
            setError(t('patientCityError'));
            setCurrentFocus('shipCity');
            scrollToView();
            return; 
        }
        else if(showShipDirect && (values.shipState === "" || values.shipState === "Select a State"))
        {
            setError(t('patientStateError'));
            setCurrentFocus('shipState');
            scrollToView();
            return; 
        }        
        else if(showShipDirect && !isValidZip(values.shipZip.trim()))
        {
            setError(t('patientZipError'));
            setCurrentFocus('shipZip');
            scrollToView();
            return false;
        }
        else if(showShipDirect && !isValidPhoneNumber(values.shipPhone.trim())){
            setError(t('patientPhoneError'));
            setCurrentFocus('shipPhone');
            scrollToView();
            return false;
        }
        else{
            return true;
        }
    }
    const handleNext=async ()=>{
        trimValues();
        setServerError('')
        const validationCheck =await validation();
        
        if(validationCheck)
        {      
            const vhaAddrValidation = selectedFormIsVHA ? await checkAddressVHA(): true;
            const shipDirectValidation = showShipDirect ? await checkAddressShipping() : true;
            if(vhaAddrValidation && shipDirectValidation)
            {
                dispatch(setPrescriptionFormDetailsData({
                    data:values
                }))
                dispatch(setPrescriptionFormData({ ...prescriptionFormData, stateCodes: dropdown?.State }));
                navigate('/prescriber/prescriptionFormLang');
            }
        }
    }
    const handleErrorBtn = () => {
        if(currentFocus === "dateRxNeeded1"){
            setValues((prev) => ({
                ...prev,
                dateRxNeeded: new Date(),
            }));
            const inputElement = document?.getElementById("dateRxNeeded") as HTMLInputElement;        
            inputElement.focus();
        }
        else{
            const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;        
            inputElement.focus();
           !['vhaState','shipState'].includes(currentFocus) &&  inputElement.select();
        }
        
        setError('');
        setCurrentFocus('');
    };
        return(
            <>
                <SessionTimeout />
                <div className={classes.mainContainer} ref={containerRef}>
                    <div className={classes.headerContainer}>
                        {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                        }}></div>}
                        <div className={classes.headerDivider} style={{
                            borderColor: `${ProgramConfig(selectedProgram.id).color}`
                        }}></div>
                    </div> 
                    <div className={classes.productHeader}>{t('prescriptionFormDetailsHeader')}</div>
                    <div className={classes.headerNote}>
                        {t('pfdHeaderNote')}
                    </div>
                    <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                    <div className={classes.headerDescription}>
                       {
                        selectedFormIsVHA ?(
                            <>
                            <div>{t('pfd_DescriptionLine1')}</div>
                            <div>{t('pfd_DescriptionLine2')}</div>
                            <div>{t('pfd_DescriptionLine3')}</div>
                            </>
                        ):(
                            <>
                            <div>{t('pfd_StandardLine1')}</div>
                            <div>{t('pfd_StandardLine2')}</div>
                            <div>{t('pfd_StandardLine3')}</div>
                            </>
                        )
                       }
                        
                    </div>
                    <div className={classes.contentContainer}>
                    {servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                        <div className={classes.container}>
                            <div className={classes.label}>
                                <span className={classes.mandatory}>{t('dateRx')}:</span>
                            </div>
                            <div className={ classes.input}>                                
                                <DatePicker
                                    id="dateRxNeeded"
                                    name="dateRxNeeded"
                                    toggleCalendarOnIconClick
                                    selected={values['dateRxNeeded']}
                                    showIcon
                                   /*  onChange={handleDateChange} */
                                    onSelect={handleDateChange}
                                    placeholderText="mm/dd/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="MM/dd/yyyy"
                                    todayButton="Today"
                                    icon={<CalendarOutlined style={{ marginLeft: '90px', marginTop: '-4px' }} />}
                                    style={{ padding: '50px' }}
                                    onBlur={handleDateChange}
                                    minDate={new Date("01/01/1940")}
                                    strictMode={true}
                                />
                                <p className={classes.helpTextSel}>mm/dd/yyyy</p>
                            </div>
                        </div>                       

                        <Input
                            type="text"
                            name="firstName"
                            label={t('prescriptionFName')}
                            isRequired={false}
                            maxLength={30}
                            noInput={true}
                            valueExist={values['firstName']}
                           containerClassName ={'containerClassName'}
                        />
                        <Input
                            type="text"
                            name="lastName"
                            label={t('prescriptionLName')}
                            isRequired={false}
                            maxLength={30}
                            noInput={true}
                            valueExist={values['lastName']}
                           containerClassName ={'containerClassName'}
                        />
                        <Input
                            type="text"
                            name="dob"
                            label={t('prescriptionDOB')}
                            isRequired={false}
                            maxLength={30}
                            noInput={true}
                            valueExist={values['dob']}
                           containerClassName ={'containerClassName'}
                        />
                        <Input
                            type="text"
                            name="pin"
                            label={t('prescriptionaPatientPin')}
                            isRequired={false}
                            maxLength={30}
                            noInput={true}
                            valueExist={values['pin']}
                           containerClassName ={'containerClassName'}
                        />
                       
                        <div className={classes.container}>
                            <div className={classes.label}>
                                <span >{t('homeAddress')}:</span>
                            </div>
                            <div className={ classes.input}>                                
                                <div>
                                    <p className={classes.addressP}>
                                        {values['homeAddress1']}
                                    </p>
                                    <p className={classes.addressP}>
                                        {values['homeAddress2']}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Input
                            type="text"
                            name="homePhone"
                            label={t('homePhone')}
                            isRequired={false}
                            maxLength={30}
                            noInput={true}
                            valueExist={values['homePhone']}
                           containerClassName ={'containerClassName'}
                        />
                        <div className={classes.divider}></div>
                         
                        {selectedFormIsVHA && 
                            <>
                        <Input
                            type="text"
                            name="vhaName"
                            key={'vhaName'}
                            label={t('vhaName')}
                            isRequired={true} 
                            containerClassName ={'containerClassName'}
                            value={values['vhaName']}
                            onChangeHandler={handleOnChange}
                            maxLength={35}
                        />
                        <Input
                            key={'vhaAddress'}
                            value={values['vhaAddress']}
                            type="text"
                            name={'vhaAddress'}
                            label={t('prescriptionAddress')}
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            onChangeHandler={handleOnChange}
                            maxLength={50}
                        />
                        <Input
                            key={'vhaCity'}
                            value={values['vhaCity']}
                            type="text"
                            name={'vhaCity'}
                            label={t('prescriptioncity')}
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            onChangeHandler={handleOnChange}
                            maxLength={30}
                        />
                        <Input
                            key={"vhaState"}
                            type="select"
                            name="vhaState"
                            label="State"
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            onChangeHandler={handleOnChange}
                            options={
                                dropdown?.State
                                    ? [
                                            { value: 'Select a State' } as { value: string },
                                            ...dropdown?.State?.map((item) => ({ value: item.value })),
                                        ]
                                    : []
                            }
                            defaultValue={values['vhaState']}
                        />
                        <div className={classes.container}>
                            <div className={classes.label}>
                                <span className={classes.mandatory}>{t('zip4')}:</span>
                            </div>
                            <div className={ classes.input}>                                
                                <div>
                                    <input
                                        name="vhaZip"
                                        key="vhaZip"
                                        id="vhaZip"
                                        value={values['vhaZip']}
                                        type="text"
                                        size={20}
                                        maxLength={5}
                                        autoComplete="off"
                                        className={classes.inputZip}
                                        onChange={handleOnChange}
                                        
                                    />
                                     - 
                                    <input
                                        name="vhaExt"
                                        id="vhaExt"
                                        value={values['vhaExt']}
                                        type="text"
                                        size={20}
                                        maxLength={4}
                                        autoComplete="off"
                                        className={classes.inputZipExt}
                                        onChange={handleOnChange}
                                    />
                                </div>
                                <p className={classes.helpTextSel}>e.g., xxxxx-xxxx</p>
                            </div>
                        </div>
                        
                        <Input
                            key={'vhaPharmName'}
                            value={values['vhaPharmName']}
                            type="text"
                            name={'vhaPharmName'}
                            label={t('vhaPharmName')}
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            onChangeHandler={handleOnChange}
                            maxLength={30}
                        />
                        
                        <Input
                            key={'vhaPharmPhone'}
                            value={values['vhaPharmPhone']}
                            type="text"
                            name={'vhaPharmPhone'}
                            label={t('vhaPharmPhNo')}
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            helpText={t('helpTextPhone')}
                            onChangeHandler={handleOnChange}
                            maxLength={12}
                        />
                        <Input
                            key={'vhaPhaFax'}
                            value={values['vhaPhaFax']}
                            type="text"
                            name={'vhaPhaFax'}
                            label={t('vhaPharmFax')}
                            isRequired={true}
                            containerClassName ={ 'containerClassName'}
                            helpText={t('helpTextPhone')}
                            onChangeHandler={handleOnChange}
                            maxLength={12}
                        />
                        <Input
                            key={'mckAccNo'}
                            value={values['mckAccNo']}
                            type="text"
                            name={'mckAccNo'}
                            label={t('mckAccNo')}
                            isRequired={false}
                            containerClassName ={ 'containerClassName'}
                            maxLength={10}
                            onChangeHandler={handleOnChange}
                        />
                        <div className={classes.radioBtn}>
                            <input
                                type="radio"
                                value="shipVha"
                                name="shipVha"
                                checked={selectedShipOption === 'shipVha'}
                                onChange={()=>  handleShipOption("shipVha")}
                                className={classes.accentColor}
                            />
                            <span>{t('shipVha')}</span>
                            <input
                                type="radio"
                                value="shipDirect"
                                name="shipDirect"
                                checked={selectedShipOption === 'shipDirect'}
                                onChange={()=> handleShipOption("shipDirect") }
                                className={classes.shipDirect}
                            />
                            <span>{t('shipDirect')}</span>
                        </div>
                        </>
                        }
                        {
                            showShipDirect &&
                            <div>
                                <Input
                                    key={'shipAddress'}
                                    value={values['shipAddress']}
                                    type="text"
                                    name={'shipAddress'}
                                    label={t('shipAddr')}
                                    isRequired={true}
                                    containerClassName ={ 'containerClassName'}
                                    onChangeHandler={handleOnChange}
                                    maxLength={50}
                                />
                                <Input
                                    key={'shipCity'}
                                    value={values['shipCity']}
                                    type="text"
                                    name={'shipCity'}
                                    label={t('prescriptioncity')}
                                    isRequired={true}
                                    containerClassName ={'containerClassName'}
                                    onChangeHandler={handleOnChange}
                                    maxLength={30}
                                />
                                <Input
                                    key={"shipState"}
                                    type="select"
                                    name="shipState"
                                    label="State"
                                    isRequired={true}
                                    containerClassName ={'containerClassName'}
                                    onChangeHandler={handleOnChange}
                                    options={
                                        dropdown?.State
                                            ? [
                                                    { value: 'Select a State' } as { value: string },
                                                    ...dropdown?.State?.map((item) => ({ value: item.value })),
                                                ]
                                            : []
                                    }
                                    defaultValue={ values['shipState']}
                                />
                                <div className={classes.container}>
                                    <div className={classes.label}>
                                        <span className={classes.mandatory}>{t('zip4')}:</span>
                                    </div>
                                    <div className={ classes.input}>                                
                                        <div>
                                            <input
                                                name="shipZip"
                                                id="shipZip"
                                                value={values['shipZip']}
                                                type="text"
                                                size={20}
                                                maxLength={5}
                                                autoComplete="off"
                                                className={classes.inputZip}
                                                onChange={handleOnChange}
                                            />
                                            - 
                                            <input
                                                name="shipExt"
                                                id="shipExt"
                                                value={values['shipExt']}
                                                type="text"
                                                size={20}
                                                maxLength={4}
                                                autoComplete="off"
                                                className={classes.inputZipExt}
                                                onChange={handleOnChange}
                                            />
                                        </div>
                                        <p className={classes.helpTextSel}>e.g., xxxxx-xxxx</p>
                                    </div>
                                </div>
                                <Input
                                    key={'shipPhone'}
                                    value={values['shipPhone']}
                                    type="text"
                                    name={'shipPhone'}
                                    label={t('shipPhNo')}
                                    isRequired={true}
                                    containerClassName ={ 'containerClassName'}
                                    helpText={t('helpTextPhone')}
                                    onChangeHandler={handleOnChange}
                                    maxLength={12}
                                />
                            </div>
                        }
                        
                        <div className={classes.btnNext}>
                            
                            <Button
                                clickFunction={handleNext}
                                className={getBtnClass(selectedProgram.id)}
                                width={107}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.next : buttonContent.en.next}
                            ></Button>
                        </div>
                    </div>
                    {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
                </div>
            </>
        )

};

export default PrescriptionFormDetails;