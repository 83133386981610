import PageBackground from "components/PageBackground";
import { AuthDataType, LogDispenseScreens,Product } from "./logDispenseType";
import classes from "./logDispense.module.scss"
import Button from "components/Button";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import SelectDispenseTable from "./selectDispenseTable";
import { fetchDropdown, fetchValidateAuthorization, getActivePharmacyLocation, getAdditionalPatientData, setLogMultipleDispense } from "store/slices/appSlice";
import { useNavigate } from "react-router-dom";
import ErrorModal from "components/ErrorModal";
import Alert from 'components/Alert';
import { isValidNum } from "utils/regex";
import { t } from "i18next";

const SelectDispense = ({ handleCancelBtn, setScreen, setIsWait }) => {
    const { logMultipleDispense } = useAppSelector((state) => state.app);
    const { multipleDispensesTable,authorizationsDetails } = logMultipleDispense;
    const [data, setData] = useState<AuthDataType[]>(multipleDispensesTable);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { currentUserPharmacy } = useAppSelector((state) => state.login);
    const [alertMessage, setAlertMessage] = useState("");
    const [ErrorPopUp, setErrorPopUp] = useState("");
    const [productOptions, setOptions] = useState<any[]>([]);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [defaultLocation, setDefaultLocation] = useState<any>({});
    const [errorFieldID, setErrorFieldID] = useState('');

    const getProductOptions = () => {
        dispatch(fetchDropdown(["Ndc"])).then((response: any) => {
            if (response.payload.status === 200) {
                setOptions(response?.payload?.data?.data?.Ndc);
            }
        })
    }

    const getPharamcyLocation = () => {
        const payload = {
            channelId: "ext",
            onlineUserName: currentUserPharmacy.onlineUserId,
        }
        dispatch(getActivePharmacyLocation(payload)).then((response: any) => {
            if (response.payload.status === 200) {
                const _locations = response?.payload?.data?.data?.map(x => { 
                    const locationArray = x?.activeLocation?.split('-');
                    const code=locationArray[0];
                    const addressArray=locationArray[1].split(",");
                    return { ...x, value: `${code} - ${addressArray[0]}, ${addressArray[1]}`} 
                });
                const _defaultLocation = _locations[0];
                setDefaultLocation(_defaultLocation);
                setLocationOptions(_locations);                
                setData((prev) => {
                    return prev.map((item) => {
                        return {
                            ...item,
                            location: _defaultLocation
                        };
                    })
                });
            }
        })
    }

    const setUpdateinitialData = (data: any[]) => {
        const logMultipleDispenseinitialData: any[] = [];
        for (let i = 0; i < (20 - data.length); i++)
            logMultipleDispenseinitialData.push({
                id: i + 1,
                checked: false,
                auth_no: { errorMsg: null, auth_no: '' },
                patient_name: { errorMsg: null, patient_name: '' },
                product: [
                    { id: 1, checked: false, product_id: { errorMsg: null, product_id: '' }, quantity: { errorMsg: null, quantity: '' } },
                ],
                authResponse: {},
                product_options: [],
                location: defaultLocation
            });
        return logMultipleDispenseinitialData;
    }

    useEffect(() => {       
        getPharamcyLocation();
        getProductOptions();
    }, [])

    const handleDeleteRow = () => {
        setData((prev: any[]) => {
            const delectParentRow = prev.filter(x => !x.checked);
            const deleted = delectParentRow.map((item) => {
                return {
                    ...item,
                    product: item.product.filter((x) => !x.checked),
                };
            }).filter(x => x.product.length > 0);
            return [...deleted, ...setUpdateinitialData(deleted)]
        });
    }
    const setProductQuantity = (record, product, value) => {
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id)
                    return {
                        ...item,
                        product: item.product.map((el) => {
                            if (el.id === product.id)
                                return {
                                    ...el,
                                    quantity: {
                                        //...el[key],
                                        errorMsg: null,
                                        quantity:
                                            /^\d*\.?\d*$/.test(value) || !value
                                                ? value
                                                : el.quantity.quantity,
                                    },
                                };
                            else return el;
                        }),
                    };
                else return item;
            });
        });
    };
   
    const setProductIdentificationOnchange = (record, product, value) => {
        // const option = record.product_options[e.target.value];
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id) {
                    const _product = item.product.map((el) => {
                        if (el.id === product.id)
                            return {
                                ...el,
                                product_id: {
                                    ...el.product_id,
                                    errorMsg: null,
                                    product_id: value,
                                    productCountryId: value.length < 3 ? undefined :el.product_id.productCountryId
                                }
                            };
                        else return el;
                    });
                    const filledProductCountryId =  _product.find(_p=>_p.product_id.product_id !== "")?.product_id?.productCountryId;
                    return {
                        ...item,
                        product: _product,
                        product_options: productOptions.filter(x => !(_product.find((p) => p.product_id.value === x.value))).filter(x=> filledProductCountryId ? filledProductCountryId === x.productCountryId : true) 

                    };
                }

                else return item;
            });
        });
    };
    const setProductIdentificationOnSelect = (record, product, option, value) => {
        // const option = record.product_options[e.target.value];
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id) {
                    const _product = item.product.map((el) => {
                        if (el.id === product.id)
                            return {
                                ...el,
                                product_id: {
                                    ...el.product_id,
                                    errorMsg: null,
                                    product_id: value,
                                    ...option,
                                },
                                quantity: {
                                    ...el.quantity,
                                    quantity: (el.product_id.productCountryId !== option.productCountryId ? (option.productCountryId === 2 ? "28" : el.quantity.quantity) : el.quantity.quantity)
                                }
                            };
                        else return el;
                    });
                    const filledProductCountryId =  _product.find(_p=>_p.product_id.product_id !== "")?.product_id?.productCountryId;

                    return {
                        ...item,
                        product: _product,
                        product_options: productOptions.filter(x => !(_product.find((p) => p.product_id.value === x.value))).filter(x=> filledProductCountryId ? filledProductCountryId === x.productCountryId : true) 
                    };
                }
                else return item;
            });
        });
    };

    const setRowValues = (record, e, key) => {
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id)
                    return {
                        ...item,
                        [key]: { ...item[key], [key]: e.target.value },
                    };
                else return item;
            });
        });
    };


    const onCheckHandler = (record, e, product, index) => {
        setData((prev) =>
            prev.map((item) => {
                if (record.id === item.id)
                    return {
                        ...item,
                        checked: index === 0 ? e.target.checked : item.checked,
                        product: item.product.map((p) => {
                            if (p.id === product.id)
                                return { ...p, checked: e.target.checked }
                            else return { ...p }
                        })
                    };
                else return item;
            })
        );
    };

    const addSubRowHandler = (record, product) => {
        const id =
            record.product.reduce((max, obj) => {
                return obj.id > max.id ? obj : max;
            }, record.product[0]).id + 1;
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id) {
                    return {
                        ...item, product: [...item.product, {
                            id,
                            product_id: { errorMsg: null, product_id: '' },
                            quantity: { errorMsg: null, quantity: '' },
                        }],

                    };
                }
                // return { ...item, product: [...item.product, { id, product_id: '', quantity: '' }] };
                else return item;
            });
        });

    };


    const onSelectAll = (e) => {
        setData((prev) =>
            prev.map((item) => {
                return { ...item, product: item.product.map((p) => { return { ...p, checked: e.target.checked } }) };
            })
        );
    };
    const setLocation = (record, e) => {
        setData((prev) => {
            return prev.map((item) => {
                if (item.id === record.id)
                    return {
                        ...item,
                        location: locationOptions.find(x => x.value === e?.target?.value)
                    };
                else return item;
            })
        });
    };

    useEffect(() => {
        if (!ErrorPopUp && errorFieldID) {
            const inputElement = document.getElementById(errorFieldID) as HTMLInputElement;
            inputElement?.focus();
            if (inputElement && inputElement.nodeName == 'INPUT') {
                inputElement.select();
            }
        }
    }, [ErrorPopUp, errorFieldID]);

    const IsPreValidation = (filtedData: AuthDataType[]): boolean => {
        let result = false;
        for (let i = 0; i < filtedData.length; i++) {
            const item: AuthDataType = filtedData[i];
            if (!(item.auth_no.auth_no)) {
                setErrorPopUp(t("message_ValidAuthorization"));
                setErrorFieldID(`Authorization_${item.id}`);
                result = false;
                break;
            } else if (!isValidNum(item.auth_no.auth_no)) {
                setErrorPopUp(t("message_ValidAuthorization"));
                setErrorFieldID(`Authorization_${item.id}`);
                result = false;
                break;
            } else if (!(item.product.every(x => x.product_id.product_id))) {
                setErrorPopUp(t("message_ProductNotValid"));
                item.product.forEach((i) => {
                    if (!i.product_id.product_id) {
                        setErrorFieldID(`ProductIdentification_${item.id}_${i.id}`);
                    }
                })
                result = false;
                break;
            } else if(!item.product.every((product) => productOptions.find(option => option.value.toLowerCase() === product?.product_id?.product_id?.toLowerCase()))){
                item.product.forEach((product) => {
                    const _selectedOption = productOptions.find(option => option.value.toLowerCase() === product?.product_id?.product_id?.toLowerCase());
                    if (!_selectedOption) {
                        setErrorPopUp(t("message_ProductNotValid"));
                        setErrorFieldID(`ProductIdentification_${item.id}_${product.id}`);
                    }
                })
                result = false;
                break;
            } else if (!(item.product.every(x => x.quantity.quantity && Number(x.quantity.quantity) > 0 && isValidNum(x.quantity.quantity)))) {
                setErrorPopUp(t("message_ValidQuantity"));
                item.product.forEach((i) => {
                    if (!(i.quantity.quantity && Number(i.quantity.quantity) > 0 && isValidNum(i.quantity.quantity))) {
                        setErrorFieldID(`Quantity_${item.id}_${i.id}`);
                    }
                })
                result = false;
                break;
            }
            else {
                result = true;
            }
        };

        if (filtedData.map(v => v.auth_no.auth_no).length > new Set(filtedData.map(v => v.auth_no.auth_no)).size) {
            setErrorPopUp(t("message_OneOrMoreDuplicate"));
            result = false;
        }
        if (!result) {
            setIsWait(false);
        }

        return result;
    }


    const IsPostValidation = (response) => {
        let result = false;
        let finalObj: AuthDataType[] = ResetOrder();
        for (let i = 0; i < response.length; i++) {
            const authDetails = response[i];
            const authTable = finalObj.find(x => x.auth_no.auth_no === authDetails.authorizationNumber.replace(/[^\d.-]/g, ''));
            finalObj = finalObj.map((item) => {
                if (item.auth_no.auth_no === authDetails.authorizationNumber.replace(/[^\d.-]/g, ''))
                    return {
                        ...item,
                        authResponse: authDetails,
                    };
                else return item;
            });


            if (authDetails?.authvalid || !(authDetails?.dispensationError?.code === 9028 || authDetails?.dispensationError?.code === 9020 || authDetails?.dispensationError?.code === 9027 || authDetails?.dispensationError?.code === 9019)) {
                if (!authTable?.product.every(x => x.product_id.productCountryId === authDetails.authorization.productCountryId)) {
                    setAlertMessage(t("message_ProductNotValid"));
                    result = false;
                    break;
                }
                else {
                    result = true;
                }
            }
            else {
                if (authDetails?.dispensationError?.code === 9020)
                    setAlertMessage(t("message_9020"));
                else if (authDetails?.dispensationError?.code === 9028) {
                    setAlertMessage(t("message_9028"));
                }
                else if (authDetails?.dispensationError?.code === 9027 || authDetails?.dispensationError?.code === 9019)
                    setAlertMessage(t("message_DoNotDispense"));
                result = false;
                break;
            }
        }
        setData(finalObj);

        if (result) {           
            setIsWait(false);
            const authDetails = finalObj.filter((x) => {
                return x.auth_no['auth_no'] !== '';
            });
            if (authDetails.length > 0) {
                dispatch(
                    setLogMultipleDispense({
                        ...logMultipleDispense,
                        authorizationsDetails: authDetails.map((x) => {
                            return { ...x, product_options: [] };
                        }),
                        multipleDispensesTable: finalObj.map((x: AuthDataType) => {
                            return {
                                ...x,
                                product:x.product.map((p:Product)=>{return{...p,quantity:{...p.quantity,quantity:p.quantity.quantity !== "" ? Number(p.quantity.quantity).toLocaleString():p.quantity.quantity}}}),
                                authResponse: {}
                            }
                        }),
                    })
                );
                setScreen(LogDispenseScreens.Review);
            }
        } else {
            setIsWait(false);
        }
    }
    const ResetOrder = () => {
        const filtedData = data.filter(x => x.auth_no.auth_no || x.product.some(x => (x.product_id.product_id !== "" || x.quantity.quantity !== "")));
        const NonfiltedData = data.filter(x => !(x.auth_no.auth_no || x.product.some(x => (x.product_id.product_id !== "" || x.quantity.quantity !== ""))));
        setData([...filtedData, ...NonfiltedData]);
        return [...filtedData, ...NonfiltedData];
    }

    const handleContinue = () => {
        setAlertMessage('');
        setIsWait(true);        
        dispatch(setLogMultipleDispense({ ...logMultipleDispense, multipleDispensesTable: data }));
        const filtedData = ResetOrder().filter(x => x.auth_no.auth_no !== "" || x.product.some(x => (x.product_id.product_id !== "" || x.quantity.quantity !== "")))
        if (IsPreValidation(filtedData)) {
            fetchAuthorizationData(filtedData);
        }
        
    }
    const fetchAuthorizationData = async (validatedData) => {
        const payload = {
            authorization: validatedData.map((item:AuthDataType) => {
                return {
                    authorizationNumber: item.auth_no.auth_no,
                    productName: item.product[0].product_id.product_id.split(" ")[0],
                    productIdentification: item.product.map((x)=>{
                        const selectedProduct = productOptions.find(option => option.value.toLowerCase() === x?.product_id?.product_id?.toLowerCase());
                        return  {
                            quantity: x.quantity.quantity,
                            ndcId: selectedProduct.id
                        }
                    }),
                    pharmacyId: item?.location?.pharmacyId,
                }
            }),            
            channelId: 'ext',
            lookup: 'Y',
        };
        try {
            dispatch(fetchValidateAuthorization({ data: payload })).then((response: any) => {
                if (response.payload?.data?.statusCode === 200) {
                    const _response = response.payload?.data?.data;
                    const payload = {
                        authorizationNumberList: _response.map(x => { return x.authorizationNumber }),
                    };
                    dispatch(getAdditionalPatientData(payload));
                    IsPostValidation(_response);
                } else {
                    navigate('/pharmacy/SystemError');
                }
            });
        } catch (err) { }
    };

    const handleErrorContinue = () => {
        setErrorPopUp('');
    };

    return (
        <PageBackground label={"Log Dispense"} btn={true} handleCancelBtn={handleCancelBtn} handleClick={handleContinue}>
            {ErrorPopUp && <ErrorModal error={ErrorPopUp} handleContinue={handleErrorContinue} />}
            <div className={classes.selectDispense}>
                <div className={classes.header}>
                    <div className={classes.bold}>Before Logging a Dispense, please ensure that you have product in inventory. <br /><br />
                        All Authorization Numbers for female patients of reproductive potential are valid for 7 days from the date of the last pregnancy test. All other Authorization Numbers are valid for 30 days from the date the Authorization Number was obtained.</div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.contentPadding}>
                        <div className={classes.textPadding}>
                            <div className={classes.instructions}>Please enter up to 20 Authorization Numbers and Dispense Details, and then click the Continue button.</div>
                            <div className={`${classes.requiredText} ${classes.bold}`}><span className={classes.requiredText}>Please Note: Upon entering this screen, you have 15 minutes to log your dispenses and click the Continue button, or all work will be lost.</span></div>
                            {alertMessage && (<Alert content={alertMessage} styles={{ margin: '10px 7px 5px 7px' }} />)}
                            <div className={classes.deleteRow}>
                                <input type="checkbox" disabled={true} checked={true} />
                                <Button clickFunction={handleDeleteRow} className="mr10" width={130} height={48} size={18} type="text" buttonname="Delete Row" />
                            </div>
                        </div>
                        <div className={classes.flexContainer}>
                            <SelectDispenseTable
                                data={data}
                                locationOptions={locationOptions}
                                onSelectAll={onSelectAll}
                                onCheckHandler={onCheckHandler}
                                setRowValues={setRowValues}
                                setLocation={setLocation}
                                setProductQuantity={setProductQuantity}
                                setProductIdentificationOnchange={setProductIdentificationOnchange}
                                setProductIdentificationOnSelect={setProductIdentificationOnSelect}
                                addSubRowHandler={addSubRowHandler}                               
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PageBackground>
    )
}

export default SelectDispense;