import Button from 'components/Button';
import classes from './login.module.scss';
import { useState, useEffect } from 'react';
import { persistStore } from 'redux-persist';
import { useNavigate } from 'react-router-dom';
import { store, useAppDispatch, useAppSelector } from 'store';
import {
    handlePrescriber,
    resetApp,
    setLoginPage,
    activePharmacy as ActivePharmacy,
    setPharmacyAuthenticate,
    setAccFormDetails,
} from 'store/slices/appSlice';
import ErrorModal from '../../../components/ErrorModal';
import { Role, RoleByGroup } from 'constants/Config/appConfig';
import {
    authenticateUser,
    setErrorLogin,
    setLogOut,
    setResetUserName,
    setaccessCodeFlag,
    setpopUpTemplate,
    updateActiveSessionAuth,
} from 'store/slices/loginSlice';
import Alert from 'components/Alert';
import { Helmet } from 'react-helmet';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { axiosInstancePharmacy } from 'constants/Config/axiosConfig';
import { congitoResMapper } from 'utils/congitoResMapper';
import indexedDBStore from 'store/indexedDBStore';
import { encryptData } from 'utils/encryptionWrapper';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';
import MigratePopUp from 'components/MigratePopUp';
import { LOGIN_ERR_MSG, MAX_LOGIN_FAILURE } from 'utils/constant';
import { current } from '@reduxjs/toolkit';

const Login = () => {
    const { error, resetUserName } = useAppSelector((state) => state.login);
    const { activePharmacy } = useAppSelector((state) => state.app);
    const { currentUserPharmacy, IsAuthenticatedPharmacy, currentUser} = useAppSelector((state) => state.login);
    const [errorForm, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const routeMaps = [{ route: '/pharmacy/createAccount', nextStep: 'Prescriber_Selection_Sreen' }];
    const checkRoutes = routeMaps.find((path) => path.nextStep === 'Prescriber_Selection_Sreen');
    const path = checkRoutes ? checkRoutes.route : '/prescriber/login';
    const [loginFlag, setLoginFlag] = useState(false);
    const [pharmacyError, setPharmacyError] = useState('');
    const [loader, setLoader] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [showEyeIcon, setShowEyeIcon] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [loginFailCount, setLoginFailCount] = useState(0);
    const { loginPage } = useAppSelector((state) => state.app);
    

    const [values, setValues] = useState({
        username: '',
        password: '',
    });

    const focusField = (name) => {
        const inputElement = document?.getElementById(name) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
    };


    const handleLogin = async (e) => {
        e.preventDefault();
        dispatch(setErrorLogin(''));
        if (values.username.length === 0) {
            setError('Please enter your User Name.');
            return;
        } else if (values.password.length === 0) {
            setError('Please enter your Password.');
            return;
        } else {
            
            setLoader(true);
           
            await dispatch(authenticateUser(values)).then(async (response: any) => {
                if (response?.payload?.message === LOGIN_ERR_MSG) {
                    if (loginFailCount >= MAX_LOGIN_FAILURE - 1) setShowModal(true);
                    setLoginFailCount((prev) => prev + 1);
                }
                if (response?.meta?.requestStatus === 'fulfilled') {
                    dispatch(setResetUserName({ ...resetUserName, pharmacy: values.username }));
                    dispatch(handlePrescriber(Role.Pharmacist));
                    if (response?.payload?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                        navigate('/pharmacy/pharmacyResetSecurity', {
                            replace: true,
                            state: { userName: values.username },
                        });
                    } else {
                        const authRes = congitoResMapper(response?.payload?.AuthenticationResult || {});
                        if (authRes?.currentUser?.groupTag === RoleByGroup.Pharmacist) {
                            indexedDBStore.setItem('pharmacyUser', {
                                currentUser: { ...authRes?.currentUser, isAuthenticated: true },
                                activePharmacy: {},
                            });
                            await axiosInstancePharmacy
                                .post(
                                    '/v1/pharmacy/getActivePharmacy',
                                    encryptData({ onlineUserName: values.username }),
                                    {
                                        headers: {
                                            Authorization: `Bearer ${response?.payload?.AuthenticationResult?.AccessToken}`,
                                            'X-Encrypted': 'true',
                                            'Content-Type': 'text/plain',
                                        },
                                    }
                                )
                                .then((res: any) => {
                                    dispatch(ActivePharmacy(res?.data));
                                    indexedDBStore.setItem('pharmacyUser', {
                                        currentUser: { ...authRes?.currentUser, isAuthenticated: true },
                                        activePharmacy: res?.data,
                                    });
                                    if (res.status === 200) {
                                        dispatch(setPharmacyAuthenticate(true));
                                        navigate('/pharmacy/PharmacyHome', { state: values.username });
                                    } else {
                                        setPharmacyError(res.response.data.data.errorMesssage.messageKey);
                                    }
                                });
                        } else {
                            setPharmacyError(
                                'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.'
                            );
                        }
                    }
                } else {
                    if (
                        response?.payload?.$metadata?.httpStatusCode === 401 ||
                        response?.payload?.$metadata?.httpStatusCode === 403
                    ) {
                        navigate('/prescriber/SystemError');
                    }
                }
            });
            setLoader(false);
        }
    };

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

   
    sessionStorage.setItem('documentTitle', 'REMS Pharmacy Portal');

    const handleOnChange = (e) => {
        errorForm && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const togglePswd = () => {
        setVisible(!isVisible);
    };
    const handleOnBlur = (e) => {
        setShowEyeIcon(false);
        setVisible(false);
    };
    const handleErrorBtn = () => {
        const focusName = errorForm.includes('password') ? 'password' : 'username';
        focusField(focusName);
        setError('');
    };

    const onForgotPswdClick = () => {
        navigate('/pharmacy/pharmacyForgot', { replace: true });
        dispatch(setLoginPage(false));
    };

    useEffect(() => {
        if (error.message || pharmacyError) {
            setValues({
                username: '',
                password: '',
            });
        }
        
    }, [error.message, pharmacyError]);

    useEffect(() => {
      
        if(currentUserPharmacy?.groupTag==RoleByGroup.Pharmacist && IsAuthenticatedPharmacy)
        {
            loginRedirection();
        } 
    }, [currentUserPharmacy,IsAuthenticatedPharmacy]);
    
    const checkActiveSession = async()=>{
        const activeUser =await indexedDBStore.getItem('pharmacyUser')
        if(activeUser?.currentUser?.accessToken ){
            dispatch(updateActiveSessionAuth({data:activeUser?.currentUser,role:"pharmacy" }))
            dispatch(setaccessCodeFlag(true));
            //dispatch(activePharmacy(activeUser?.activePharmacy));
            if(activeUser)
            {
                navigate('/pharmacy/PharmacyHome');
            }
        }
        
       
    }
    const loginRedirection=async()=>{
        const loginState=await indexedDBStore.getItem('pharmacyUser');
        if(IsAuthenticatedPharmacy  && loginState?.currentUser?.accessToken)
        {
           
            setLoader(true);
            await indexedDBStore.setItem('pharmacyUser', { currentUser: { ...currentUserPharmacy, isAuthenticated: true } });
            if (currentUserPharmacy?.userStatus === 'FORCE_CHANGE_PASSWORD') {
                setLoader(false)
                navigate('/pharmacy/pharmacyResetSecurity', { replace: true });
                return;
            }

            await updateUserinfo(currentUserPharmacy);
            
            if((currentUserPharmacy?.groupTag?.toLowerCase() === RoleByGroup.Pharmacist.toLowerCase()))
            {
                
                try{
                    await axiosInstancePharmacy
                    .post(
                        '/v1/pharmacy/getActivePharmacy',
                        encryptData({ onlineUserName: currentUserPharmacy?.onlineUserId }),
                        {
                           
                            headers: {
                                Authorization: `Bearer ${loginState?.currentUser?.accessToken}`,
                                'X-Encrypted': 'true',
                                'Content-Type': 'text/plain',
                            },
                        }
                    )
                    .then((res: any) => {
                       
                        setLoader(false);
                        dispatch(ActivePharmacy(res?.data));
                        indexedDBStore.setItem('pharmacyUser', {
                            currentUser: { ...currentUserPharmacy, isAuthenticated: true },
                            activePharmacy: res?.data,
                        });
                        if (res.status === 200) {
                            if(res?.data?.statusCode=== 200)
                            {
                                dispatch(setPharmacyAuthenticate(true));
                                
                                navigate('/pharmacy/PharmacyHome', { state: currentUserPharmacy?.onlineUserId });
                                setLoader(false);
                            }
                            else{
                                setLoader(false);
                            }
                            
                        } 
                    });
                }
                catch(err){
                   
                    console.error(err);
                    setLoader(false);
                }
                
            }
        }
       
    }
    const updateUserinfo = (currentUser) => {
        const storeData = {
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            officeAddress: currentUser?.userinfo?.address?.formatted,
            city: currentUser?.userinfo['custom:city'],
            state: currentUser?.userinfo['custom:state'],
            zipCode: currentUser?.userinfo['custom:zip_code'],
            phoneNumber: currentUser?.userinfo?.phone_number
                ? currentUser?.userinfo?.phone_number?.replace(/^\+1\s*|\s*/g, '')
                : null,
            email: currentUser?.userinfo?.email,
            userName: currentUser?.userinfo?.username,
            securityQuestion: currentUser?.userinfo['custom:security_question'],
            securityAnswer: currentUser?.userinfo['custom:security_answer'],
        };

        sessionStorage.setItem('accountData', JSON.stringify(storeData));
        dispatch(setAccFormDetails(storeData));
       
         if (currentUser.groupTag?.toLowerCase()?.includes('pharmacist')){
            dispatch(handlePrescriber(Role.Pharmacist));
        }
    };

   

    useEffect(() => {
        const handleBrowserBack = () => {
            if (loginPage) {
                window.onpopstate = null;
                navigate('/pharmacy/login', { replace: true });
            } else {
                window.onpopstate = null;
            }
        };
        window.onpopstate = handleBrowserBack;
    }, []);
    useEffect(() => {
        window.history.pushState(null, window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, window.location.href);
        };
    });
    


    async function fetchData() {
        try {
            const response = await fetch('./resources/popup.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData().then((res) => dispatch(setpopUpTemplate(res?.popup)));
    }, []);

    return (
        <>
            <MigratePopUp showModal={showModal} setShowModal={setShowModal} />
            <div>
                <Helmet>
                    <title>REMS Pharmacy Portal</title>
                </Helmet>
                <div className={classes.loginContentContainer}>
                    <div className={classes.loginForm}>
                        <div className={classes.commonStyles}>
                            <div className={classes.heading1}>Pharmacy Login</div>
                            <div className={classes.toLogin}>To login to your account: </div>
                            {(error.message || pharmacyError) && (
                                <Alert
                                    content={error.message || pharmacyError}
                                    styles={{ fontSize: '11px', margin: '10px 20px 5px 20px' }}
                                />
                            )}
                            <div className={classes.formGroup}>
                                <label className={classes.logincontent} htmlFor="username">
                                    User Name
                                </label>
                                <input
                                    onKeyDown={(e) => e.key === 'Enter' && handleLogin(e)}
                                    type="text"
                                    id="username"
                                    name="username"
                                    onChange={handleOnChange}
                                    maxLength={20}
                                    value={values.username}
                                    className={classes.loginValue}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <label className={classes.logincontent} htmlFor="password">
                                    Password
                                </label>
                                <div className={classes.inputContainer}>
                                    <div
                                        className={classes.passwordContainer}
                                        onBlur={(e) => {
                                            !e.relatedTarget
                                                ? e.target.id === 'password' && setVisible(false)
                                                : e.relatedTarget.id !== 'password' && setVisible(false);
                                        }}
                                    >
                                        <input
                                            onKeyDown={(e) => e.key === 'Enter' && handleLogin(e)}
                                            id="password"
                                            name="password"
                                            onChange={handleOnChange}
                                            type={isVisible ? 'text' : 'password'}
                                            maxLength={20}
                                            value={values.password}
                                        />
                                        {values?.password !== '' && (
                                            <button id="password" className={classes.pswdIcon} onClick={togglePswd}>
                                                {isVisible ? <VscEyeClosed /> : <VscEye />}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.submitbutton}>
                                <div
                                onClick={onForgotPswdClick}
                                onKeyDown={(e) => e.key === 'Enter' && onForgotPswdClick()}
                                tabIndex={0}
                                className={classes.forgotPassword}
                            >
                                Forgot Password?
                            </div>
                                {loader ? (
                                    <div className={classes.loader}>
                                        <Flex align="center" gap="middle">
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                                        </Flex>
                                    </div>
                                ) : (
                                    <Button
                                        className="buttonStyles"
                                        type="text"
                                        clickFunction={handleLogin}
                                        handleKeyPress={true}
                                        buttonname="Login"
                                        width={96}
                                        height={36}
                                        size={14}
                                    ></Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button
                            clickFunction={() => {
                                navigate(path);
                                dispatch(setLoginPage(false));
                            }}
                            className="loginBtn"
                            type="text"
                            buttonname="Create User Account"
                            size={14}
                            width={188}
                            height={36}
                        ></Button>
                    </div>
                    {errorForm && <ErrorModal error={errorForm} handleContinue={handleErrorBtn} />}
                </div>
            </div>
        </>
    );
};

export default Login;
