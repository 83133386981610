import React, {useState, useEffect} from 'react';
import classes from './physicianOfficeDetails.module.scss';
import Input from '../../components/Input';
import {  useSelector } from 'react-redux';
import { RootState,  useAppDispatch } from 'store';
import { useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';
import {changePrescriberDetails} from 'store/slices/appSlice';
import inputStyles from '../../components/Input/Input.module.scss';
import { useTranslation } from 'react-i18next';
import SessionTimeout from 'components/SessionTimeout';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';


const PhysicianOfficeDetails = () =>{

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t,i18n} =useTranslation();
    const {   prescriberDetails } = useAppSelector((state) => state.app);
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);
    const [dropdown, setDropdown] = useState([]);
    const [primaryLocation,setPrimaryLocation] = useState('');
    const {  IsAuthenticated } = useAppSelector(
        (state) => state.login
    );
    const [values, setValues] = useState({
        firstName:'',
        lastName:'',
        pin:'',
        mi:'',
        addressList:[''],
        selectedAddress:'',
        city:'',
        state:'',
        zip:'',
        phone:'',
        ext:'',
        fax:'',
        addressId:""
    });
    
       useEffect(() => {
        if(!IsAuthenticated) { 
            navigate('/prescriber/login', { replace: true });
           }

        }, [IsAuthenticated]);

    useEffect(() => {
        setValues((prev) => ({
            ...prev,
            firstName:prescriberDetails.firstName,
            lastName:prescriberDetails.lastName,
            pin:prescriberDetails.pin,
            mi:prescriberDetails.mi,
            addressList:prescriberDetails.addressList,
            selectedAddress:prescriberDetails.selectedAddress,
            city:prescriberDetails.city,
            state:prescriberDetails.state,
            zip:prescriberDetails.zip,
            phone:prescriberDetails.phone,
            ext:prescriberDetails.ext,
            fax:prescriberDetails.fax,
            addressId:prescriberDetails.addressId,
            prescriberSystemId: prescriberDetails.prescriberSystemId,
            onlineUserid: prescriberDetails?.onlineUserid
        }));
        if(prescriberDetails.addressList.length>0)
        {
            let listAddr:any=[];
            prescriberDetails.addressList.forEach((item)=>{
                if(item.primaryLocation==="Y")
                {
                    setPrimaryLocation(item.prescriberLocationSequence);
                }
                let addrVals={
                    "valueId":item.prescriberLocationSequence,
                    "value":item.address
                }
                listAddr.push(addrVals)
            });
            setDropdown(listAddr);
        }
       
    }, [prescriberDetails]);


    const handleOnChange = (e) => {
    
        const { name, value } = e.target;
        let selectAddr = prescriberDetails.addressList.find((i) => i.prescriberLocationSequence === value.toString())
        setValues((prev) => ({
            ...prev,
            selectedAddress:selectAddr.address,
            city:selectAddr.city,
            state:selectAddr.state,
            zip:selectAddr.zip,
            phone:selectAddr.phone,
            ext:selectAddr.ext,
            fax:selectAddr.fax,
            addressId: value,
        }));
    };

    const handleNext=()=>{
        dispatch(changePrescriberDetails( values));
        
        navigate('/prescriber/patientInformation');
    };

    const callPhysicianOfficeDetailsSpanish = ()=>{
        i18n.changeLanguage('es');
    };
    
   
    return (
        <>
        <SessionTimeout/>
        <div className={classes.mainContainer} >
            <div>
            <div className={classes.headerContainer}>
                   
                   <div className={classes.headerRemsProgram} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProductData.id).headerImage}`)})`
                        }}>
                   </div>
                   {i18n.language === 'en' ? <Button
                                clickFunction={callPhysicianOfficeDetailsSpanish}
                                className={getBtnClass(selectedProductData.id)}
                                width={120}
                                height={45}
                                size={18}
                                type="text"
                                buttonname={buttonContent.es.toSpanish}
                            ></Button> : <></>}
                  
               </div>
               <div className={`${classes['headerDivider_'+selectedProductData.id]}`}></div>
            </div>
            <div className={classes.productHeader}> {t('header')}</div>
            <div style={{ margin: '12px 0 3px 0px' }}>
                <span className={classes.headerNote} >{t('enterText')}</span>
            </div>
            <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}>
                    
            </div>
            <div className={classes.contentContainer} >
                <Input
                    key={'PrescriberFName'}
                    noInput={true}
                    valueExist={values.firstName}
                    type="text"
                    name={'PrescriberFName'}
                    label={t('presFName')}
                    containerClassName ={ 'containerClassName'}
                />
                 <Input
                    key={'PrescriberLName'}
                    noInput={true}
                    valueExist={values.lastName}
                    type="text"
                    name={'PrescriberLName'}
                    label={t('presLName')}
                    containerClassName ={ 'containerClassName'}
                />
                <Input
                    key={'MI'}
                    noInput={true}
                    valueExist={values.mi}
                    type="text"
                    name={'MI'}
                    label={t('mi')}
                    containerClassName ={ 'containerClassName'}
                />
                <Input
                    key={'PIN'}
                    noInput={true}
                    valueExist={values.pin}
                    type="text"
                    name={'PIN'}
                    label={t('pin')}
                    helpText={t('pinText')}
                    containerClassName ={ 'containerClassName'}
                />
                
                <div className={inputStyles.containerPFD}>
                    <div className={inputStyles.label}>
                        <span className={inputStyles.mandatory}>{t('address')}:</span>
                    </div>
                    <div className={inputStyles.input}>
                        <div>
                            <select
                                    
                                    name="address"
                                    id="address"
                                    onChange={handleOnChange}
                                    value={values['addressId']}
                                >        
                                     {dropdown.map((i:any) => (
                                        <option key={i} value={i.valueId}>
                                            {i.value}
                                        </option>
                                    ))} 
                            </select>
                        </div>
                    </div>
                </div>
                <Input
                    key={'City'}
                    noInput={true}
                    valueExist={values.city}
                    type="text"
                    name={'City'}
                    label={t('city')}
                    containerClassName ={ 'containerClassName'}
                />
                <Input
                    key={'State'}
                    noInput={true}
                    valueExist={values.state}
                    type="text"
                    name={'State'}
                    label={t('state')}
                    containerClassName ={ 'containerClassName'}
                />
                <Input
                    key={'Zip'}
                    noInput={true}
                    valueExist={values.zip}
                    type="text"
                    name={'Zip'}
                    label={t('zip')}
                    containerClassName ={ 'containerClassName'}
                />
                
                <Input
                    key={'Phone'}
                    noInput={true}
                    valueExist={values.phone}
                    type="text"
                    name={'Phone'}
                    label={t('phone')}
                    containerClassName ={ 'containerClassName'}
                />              
               
                <Input
                    key={'Fax'}
                    noInput={true}
                    valueExist={values.fax}
                    type="text"
                    name={'Fax'}
                    label={t('fax')}
                    containerClassName ={ 'containerClassName'}
                />
                <div className={classes.extInput}>
                 <Input
                    key={'Ext'}
                    noInput={true}
                    valueExist={values.ext}
                    type="text"
                    name={'Ext'}
                    label={t('ext')}
                    containerClassName ={ 'containerClassName'}
                />
                </div>

                <div className={classes.nxtBtn}>
                    <Button
                                clickFunction={handleNext}
                                className={getBtnClass(selectedProductData.id)}
                                width={125}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.next : buttonContent.en.next}
                            ></Button>
                </div>
            </div>
        </div>
        </>
    );
}

export default PhysicianOfficeDetails;