import ProductBackground from 'components/ProductBackground/productBackground';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import classes from './PatientSurvey.module.scss';
import { PatientSurveyFlowType, PatientSurveyScreens } from './PatientSurveyType';
import { updatePatientSurvey } from 'store/slices/appSlice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'components/Button';
import { buttonContent } from 'assets/objects/buttonContent';
import {ProgramConfig,lenalidomide,pomalidomide,thalomid} from 'constants/Config/programsConfig';
const Disclaimer = ({ setScreen }) => {
    const { t, i18n } = useTranslation();
    const { patientSurvey } = useAppSelector((state) => state.app);
    const [IsIncompleteSurvey, setIncompleteSurvey] = useState<boolean>(false);
    const { conductSurveyResponse, selectedSurveyProduct, eligiableProduct } = useAppSelector(
        (state) => state.app.patientSurvey
    );
    const dispatch = useAppDispatch();
    const productName = selectedSurveyProduct.PatientSurvey.ProductName;
    const { NextEarliestPatientSurveyDate, SurveyDisclaimer } = conductSurveyResponse;
    const disclaimer = SurveyDisclaimer?.replace(
        '[DATE2]',
        moment(new Date(NextEarliestPatientSurveyDate)).format('MMMM DD, YYYY')
    );
    useEffect(() => {
        if (
            patientSurvey.flowType === PatientSurveyFlowType.SearchPage ||
            patientSurvey.flowType === PatientSurveyFlowType.HomePage
        ) {
            if (
                eligiableProduct?.products?.filter((x) => {
                    return x.status === 'eligible';
                }).length > 0
            ) {
                setIncompleteSurvey(true);
            } else {
                setIncompleteSurvey(false);
            }
        }
    }, [eligiableProduct]);

    const handleContinue = () => {
        dispatch(
            updatePatientSurvey({
                ...patientSurvey,
                questionSequence: 1,
                selectedSurveyProduct: {},
                updatedSurveyPayload: {},
            })
        );
        setScreen(PatientSurveyScreens.SelectProduct);
    };

    return (
        <div className={classes.disclaimer}>
            <ProductBackground
                product={selectedSurveyProduct?.PatientSurvey?.ProductName}
                subTitle={t('patientSurveyDisclaimer')}
            >
                <div className={`${classes[i18n.language]} ${classes.bodyContainer}`}>
                    <div>
                        <p className={classes.bold} dangerouslySetInnerHTML={{ __html: disclaimer }}></p>
                        <br />
                        {(productName === ProgramConfig("rems_program_thalomid")?.label || productName === ProgramConfig("rems_program_pomalyst")?.label) ? (
                            <p className={classes.bold}>
                                {t('message_PM0955').replace('{program}', t(productName).toUpperCase())}
                                <span className={classes.legalSymbol}>®</span>.
                            </p>
                        ) : (
                            <p className={classes.bold}>{t('message_PM0955').replace('{program}', t(productName))}. </p>
                        )}
                        <br />
                        {IsIncompleteSurvey ? (
                            <>
                                <p className={classes.bold}>{t('message_PM0176')}</p>
                                <br />
                                <Button
                                    clickFunction={() => handleContinue()}
                                    className={'buttonStyles'}
                                    width={115}
                                    height={45}
                                    size={18}
                                    type="text"
                                    buttonname={
                                        i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue
                                    }
                                ></Button>
                            </>
                        ) : (
                            <p className={classes.bold}>{t('message_PM0175')}</p>
                        )}
                    </div>
                </div>
            </ProductBackground>
        </div>
    );
};

export default Disclaimer;
