import { useAppDispatch, useAppSelector } from 'store';
import classes from './ReportsTable.module.scss';
import { histAuthReportAPI, updateSelectedPatient } from '../../../store/slices/appSlice';
import rightArrow from '../../../assets/images/btn_arrow_right.png';
import { useNavigate } from 'react-router-dom';
import * as ExcelJS from 'exceljs';
import ExportToExcel from '../../../assets/images/excelExport.png';
import moment from 'moment-timezone';
import { useState } from 'react';
import PopupDispenseHistory from '../../PatientDetails/PopupDispenseHistory';

const HistoricalReportsTable = ({ setIsSorted, setFetchReport, fetchReportData, noData, reportInpData, authStatus, apiPayload }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [pharmacyData, setPharmacyData] = useState({});
    const ishistApiCalled = !!authStatus;
    const { currentUser } = useAppSelector((state) => state.login);
    const isNonPrescriber = currentUser?.groupTag && currentUser?.groupTag?.toLowerCase()?.includes('nonprescriber');

    const selectedPatientHandler = (patientData) => {
        const patientDetails = {
            patientId: patientData && patientData.patientId,
            patientExtUniqueId: patientData && patientData.patientUniqueId,
            assocPrescriberId: patientData && patientData.assocPrescriberId,
        };
        dispatch(updateSelectedPatient(patientDetails));
        navigate('/prescriber/patientdetails');
    };

    const statusHeaderMap = {
        Confirmed: 'Confirmation Date',
        Expired: 'Expiration Date',
        Obsolete: 'Cancelled Date'
    }

    const statusDataMap = {
        Confirmed: 'confirmationDate',
        Expired: 'expirationDate',
        Obsolete: 'obsoleteDate'
    }

    const noDataMsg = noData ? 'No Records Available.' : 'No Records Found.';

    const handleExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('HistoricalAuthReport');

        sheet.getRow(1).font = {
            name: 'Arial',
            family: 4,
            size: 10,
            bold: true,
            color: { argb: '#0000FF' },
        };
        sheet.columns = [
            {
                header: 'Patient Name',
                key: 'PatientName',
                width: 20,
            },
            {
                header: 'REMS Program',
                key: 'REMSProgram',
                width: 15,
            },
           
            {
                header: 'Authorization Number',
                key: 'AuthorizationNumber',
                width: 20,
            },
            {
                header: 'Prescriber Name',
                key: 'PrescName',
                width: 20,
            },
            {
                header: authStatus === "Confirmed" ?"Pharmacy Confirmed Rx":authStatus,
                key: 'authStatusDate',
                width: 20,
            },
            {
                header: 'Pharmacy Name',
                key: 'PharmacyName',
                width: 20,
            },
        ];

        fetchReportData?.map((detail) => {
            sheet.addRow({
                PatientName: detail?.patientNameLast + ' ' + detail?.patientNameFirst,
                REMSProgram: detail?.productName,
                AuthorizationNumber:detail?.authorizationNumber.substring(2),
                authStatusDate: authStatus=== "Expired" ? detail?.expirationDate && moment(detail?.expirationDate).format('MMM DD, YYYY') 
                :
                 authStatus=== "Obsolete" ? detail?.obsoleteDate && moment(detail?.obsoleteDate).format('MMM DD, YYYY')  
                :
                detail?.confirmationDate && moment(detail?.confirmationDate).format('MMM DD, YYYY'),              
                PrescName: detail?.assocPrescriberNameLast+' '+detail?.assocPrescriberNameFirst ,
                PharmacyName:  detail?.pharmacyName,
            });
        });
        for (let i = 1; i < 9; i++) {
            sheet.insertRow(i, { PatientName: '' });
        }
        sheet.getColumn('E').alignment = { horizontal: 'left' };
        // add a table to a sheet
        sheet.addTable({
            name: 'MyTable',
            ref: 'A1',
            style: {
                showRowStripes: false,
                showFirstColumn: true,
            },
            columns: [{ name: ' ' }, { name: 'Historical Authorization Report' }],
            rows: [
                ['Date From', reportInpData.startDate],          
                ['Authorization Status', authStatus],
                [''],
                ['REMS Program(s)', reportInpData.programs.join(', ')],
                ['Prescribers', reportInpData.prescribers.join(', ')],
                [''],
            ],
        });
        sheet.getCell('B1').font = {
            name: 'Arial',
            family: 4,
            size: 13,
            color: { argb: 'FF0000FF' },
            bold: true,
        };
        sheet.getCell('B1').fill = {
            type: 'pattern',
            pattern: 'none',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' },
        };
        sheet.getCell('A1').fill = {
            type: 'pattern',
            pattern: 'none',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' },
        };
        sheet.getCell('F1').value = moment().format('MMM DD, YYYY');
        const row = sheet.getRow(1);
        row.height = 30;
        row.alignment = { vertical: 'middle' };
        sheet.getCell('F1').font = {
            bold: true,
        };
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'REMSPortal.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };
    const openPharmacyModal = (item) => {
        const pharmacyLocations = [
            {
                ...item,
                addressLine2:null,
                postalCode: {
                    city: item.pharmacyCity,
                    postalCode1: item.pharmacyPostalCode,
                    state: { stateName: item.pharmacyState },
                },
            },
        ];
        setPharmacyData({ pharmacyName: item?.pharmacyName, pharmacyLocations });
        setOpenModal(true);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const sortByAscDesc = (title, order) => {
        let copyPayload = apiPayload;
        copyPayload.sortByField = (title === 'OBSOLETEDATE') ? 'OBSOLETEDDATE' : title;
        copyPayload.sortByOrder = order;

        setIsSorted({ isSorted: true, content: title, orderby: order });
        const isValidPharmacy = (title !== 'PHARMACYNAME' || fetchReportData.some(item=>item.pharmacyName))
        if(isValidPharmacy){
        dispatch(histAuthReportAPI({ data: copyPayload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                setFetchReport(e.payload?.data?.data);
            }
        });}
    };

    const tableHeader = () => {
        return (<>
            <tr>
                <th>
                    <div className={classes.thContainer}>
                        <label>Patient Name</label>
                        <div className={classes.sortControl}>
                            <a>
                                <span className={`${classes.icon} ${classes.upArrow}`} onClick={() => sortByAscDesc('PATIENTLASTNAME', 'ASC')}></span>
                            </a>
                            &nbsp;
                            <a>
                                <span className={`${classes.icon} ${classes.downArrow}`} onClick={() => sortByAscDesc('PATIENTLASTNAME', 'DESC')}></span>
                            </a>
                        </div>
                    </div>
                </th>

                <th className={classes.pr8px}>
                    <div className={classes.thContainer}>
                        <label>REMS Program</label>
                        <div className={classes.sortControl}>
                            <a>
                                <span className={`${classes.icon} ${classes.upArrow}`} onClick={() => sortByAscDesc('PRODUCTNAME', 'ASC')}></span>
                            </a>
                            &nbsp;
                            <a>
                                <span className={`${classes.icon} ${classes.downArrow}`} onClick={() => sortByAscDesc('PRODUCTNAME', 'DESC')}></span>
                            </a>
                        </div>
                    </div>
                </th>
                <th className={classes.pr8px}>Authorization Number</th>
                <th className={classes.pr8px}>Prescriber</th>
                <th className={classes.pr8px}>
                    <div className={classes.thContainer}>
                        <label>{statusHeaderMap[authStatus]}</label>
                        <div className={classes.sortControl}>
                            <a>
                                <span className={`${classes.icon} ${classes.upArrow}`} onClick={() => sortByAscDesc(statusDataMap[authStatus]?.toUpperCase(), 'ASC')}></span>
                            </a>
                            &nbsp;
                            <a>
                                <span className={`${classes.icon} ${classes.downArrow}`} onClick={() => sortByAscDesc(statusDataMap[authStatus]?.toUpperCase(), 'DESC')}></span>
                            </a>
                        </div>
                    </div>
                </th>
                <th className={`${classes.pr8px} ${classes.colWidth}`}>
                    <div className={classes.thContainer}>
                        <label>Pharmacy Name</label>
                        <div className={classes.sortControl}>
                            <a>
                                <span className={`${classes.icon} ${classes.upArrow}`} onClick={() => sortByAscDesc('PHARMACYNAME', 'ASC')}></span>
                            </a>
                            &nbsp;
                            <a>
                                <span className={`${classes.icon} ${classes.downArrow}`} onClick={() => sortByAscDesc('PHARMACYNAME', 'DESC')}></span>
                            </a>
                        </div>
                    </div>
                </th>
            </tr>
            <tr></tr>
        </>
        );
    };

    const noDataRender = () => {
        return (<table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border={0}
            className={`${classes.reportTable} ${classes.emptyTable}`}
        >
            <tbody>
                <tr className="odd">
                    <td className={classes.noRecordsFound} align="center" colSpan={8}>
                        {noDataMsg}
                    </td>
                </tr>
            </tbody>
        </table>
        );
    }

    return (
        !ishistApiCalled 
        ? (isNonPrescriber && !fetchReportData?.length ? <>{noDataRender()}</> : <></> )
        : <>
            {openModal && <PopupDispenseHistory setOpenModal={setOpenModal} data={pharmacyData} showExt={true}></PopupDispenseHistory>}
            {!fetchReportData?.length ? (
               <>{noDataRender()}</>
            ) : (
                <div className={classes.reportsTableContainer}>
                    <button onClick={handleExcel} className={classes.exprttoExlBtn}>
                        <img src={ExportToExcel} alt="export to Excel" />
                        <p>Export to Excel</p>
                    </button>
                    <div className={`${classes.contentReportsTable} ${classes.reportHistoricalTable}`}>
                        <table width="100%" cellPadding="0" cellSpacing="0" border={0} className={classes.reportTable}>
                            <tbody>
                                {tableHeader()}
                                {fetchReportData?.map((item) => {
                                    return (
                                        <tr key={item.rnum} className={classes.patientDetails}>
                                            <td>
                                                <a
                                                    onClick={() => {
                                                        selectedPatientHandler(item);
                                                    }}
                                                >
                                                    <span>
                                                        {item?.patientNameLast}
                                                        {', '}
                                                        {item?.patientNameFirst}
                                                    </span>
                                                    <img src={rightArrow} alt="right-arrow" />
                                                </a>
                                            </td>
                                            <td>{item?.productName}</td>
                                            <td>{item?.authorizationNumber?.slice(2)}</td>
                                            <td>
                                                <span>
                                                    {item?.assocPrescriberNameLast} {item?.assocPrescriberNameFirst}
                                                </span>
                                            </td>
                                            <td>{moment(item?.[statusDataMap[authStatus]]).format('MMM DD, YYYY')}</td>
                                            <td>
                                                <a onClick={(e) => openPharmacyModal(item)}><span className={classes.underline}>{item.pharmacyName}</span></a>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default HistoricalReportsTable;
