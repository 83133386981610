import { useEffect, useState } from 'react';
import classes from './PatientSurvey.module.scss';
import { PatientSurveyScreens } from './PatientSurveyType';
import Search from './Search';
import SelectProduct from './SelectProduct';
import { useAppDispatch, useAppSelector } from 'store';
import DynamicQues from './DynamicQues';
import RestartSurvey from './RestartSurvey';
import { useTranslation } from 'react-i18next';
import { updatePatientSurvey } from 'store/slices/appSlice';
import Review from './Review';
import Disclaimer from './Disclaimer';
import ErrorScreen from './ErrorScreen';
import { setpopUpTemplate } from 'store/slices/loginSlice';

const PatientSurvey = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const { patientSurvey } = useAppSelector((state) => state.app);
    const { localNavigation } = useAppSelector((state) => state.app.patientSurvey);
    const { popUpTemplate } = useAppSelector((state) => state.login);
    const [screen, setScreen] = useState(localNavigation === 0 ? PatientSurveyScreens.Search : localNavigation);

    useEffect(() => {
        console.info('PatientSurvey');
    }, []);
    useEffect(() => {
        dispatch(
            updatePatientSurvey({
                ...patientSurvey,
                prevNavigation: patientSurvey.localNavigation,
                localNavigation: screen,
            })
        );
    }, [screen]);

    async function fetchData() {
        try {
            const response = await fetch('./resources/popup.json');
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if (popUpTemplate && popUpTemplate.length > 0) return;
        else fetchData().then((res) => dispatch(setpopUpTemplate(res?.popup)));
    }, []);

    return (
        <div className={classes.patientSurvey}>
            {screen === PatientSurveyScreens.Search && <Search setScreen={setScreen} />}
            {screen === PatientSurveyScreens.SelectProduct && <SelectProduct setScreen={setScreen} />}
            {screen === PatientSurveyScreens.Survey && <DynamicQues setScreen={setScreen} />}
            {screen === PatientSurveyScreens.Restart && <RestartSurvey setScreen={setScreen} />}
            {screen === PatientSurveyScreens.Review && <Review setScreen={setScreen} />}
            {screen === PatientSurveyScreens.Disclaimer && <Disclaimer setScreen={setScreen} />}
            {screen === PatientSurveyScreens.Error && <ErrorScreen setScreen={setScreen} />}
        </div>
    );
};

export default PatientSurvey;
