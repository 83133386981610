export const patientInfoFields = [
    {
        name: 'firstName',
        errMsg: 'patientFirstNameError',
    },
    {
        name: 'lastName',
        errMsg: 'patientLastNameError',
    },
    {
        name: 'address',
        errMsg: 'patientAddressError',
    },
    {
        name: 'city',
        errMsg: 'patientCityError',
    },
    {
        name: 'state',
        errMsg: 'patientStateError',
    },
    {
        name: 'zip',
        errMsg: 'patientZipError',
    },
    {
        name: 'phone',
        errMsg: 'patientPhoneError',
    },
    {
        name: 'month',
        errMsg: 'patientDOBError',
    },
    {
        name: 'date',
        errMsg: 'patientDOBError',
    },
    {
        name: 'year',
        errMsg: 'patientDOBError',
    },
    {
        name: 'sex',
        errMsg: 'patientSexError',
    },
    {
        name: 'patientIdNum',
        errMsg: 'patientPatientIdError',
    },
    {
        name: 'diagnosis',
        errMsg: `patientDiagnosisError`,
    },
    {
        name: 'otherDiag',
        errMsg: 'patientOtherDiagnosisError',
    },
    {
        name: 'menstrating',
        errMsg: 'patientRiskError',
    },
    {
        name: 'surgical',
        errMsg: 'patientRiskError',
    },
    {
        name: 'natural',
        errMsg: 'patientRiskError',
    },
];
