import Alert from 'components/Alert';
import Button from 'components/Button';
import ErrorModal from 'components/ErrorModal';
import SessionTimeout from 'components/SessionTimeout';
import { axiosInstance, axiosInstanceSearch, axiosInstance_2, axiosInstance_ORC } from 'constants/Config/axiosConfig';
import { patientInfoFields } from 'constants/patientInformationFields';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { patientInformation, resetpatientPrescriberStore } from 'store/slices/appSlice';
import { PatientDTOUpdate } from 'utils/PatientUpdate';
import {
    isValidAddr,
    isValidCity,
    isValidMI,
    isValidName,
    isValidOtherDiag,
    isValidPatientNum,
    isValidPhoneNumber,
    isValidZip,
} from 'utils/regex';
import { buttonContent, getBtnClass } from '../../assets/objects/buttonContent';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import classes from './PatientInformation.module.scss';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import { ProgramConfig, mainMenu, lenalidomide, pomalidomide, thalomid } from 'constants/Config/programsConfig';
import { reloadFunction } from '../../utils/utilsCommon';
interface dropDownType {
    State?: { id: string; value: string }[] | null;
    'ICD-10': { id: string; value: string }[] | null;
}
const getDateArray = () => {
    return Array.from({ length: 31 }, (_, ind) => (ind + 1).toString().padStart(2, '0'));
};

const entranslations = { Female: 'Femenino', Male: 'Masculino', Yes: 'Sí', No: 'No' };

const PatientInformation = () => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        mi: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        month: '',
        date: '',
        year: '',
        sex: '',
        patientIdNum: '',
        diagnosis: '',
        otherDiag: '',
        menstrating: '',
        surgical: '',
        natural: '',
    });
    const [oldRiskFromReactivate, setOldRiskFromReactivate] = useState<any>({});
    const [servererror, setServerError] = useState('');
    const [patientValidationError, setPatientValidationError] = useState('');
    const [exsitingPatientId, setExistingPatientId] = useState();
    const [riskError, setRiskError] = useState(false);
    const [dropdown, setDropdown] = useState<dropDownType>({ State: [], 'ICD-10': [] });
    const [currentFocus, setCurrentFocus] = useState('');
    const [error, setError] = useState('');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        selectedProgram,
        patientInformation: patientFields,
        role,
        isSubmitAndPrint,
        isAccount,
        patientPrescriberStore,
    } = useSelector((state: RootState) => state.app);
    const { IsAuthenticated } = useSelector((state: RootState) => state.login);
    const { onlineUserId } = useSelector((state: RootState) => state.login.currentUser);

    const fetchDropdown = async () => {
        const reqBody = {
            keys: ['ICD-10'],
        };
        try {
            await fetchDatafromS3json().then((res) => {
                setDropdown({ 'ICD-10': [], State: res?.state });
            });
          
            await axiosInstance.post(`/referencedata`, reqBody).then((response)=>{
                if(response?.status === 200)
                {
                    setDropdown((prev) => ({ ...response.data.data, State: prev?.State }));
                }
                
            })
        } catch (err) {
            console.error(`error fetching dropdown ${err}`);
        }
    };
    useEffect(() => {
        reloadFunction();
    }, []);
    useEffect(() => {
        if (!IsAuthenticated) {
            navigate('/prescriber/login', { replace: true });
        }
    }, [IsAuthenticated]);

    useEffect(() => {
        fetchDropdown();
        scrollToView();
        const newFields = { ...patientFields };
        setExistingPatientId(patientFields.patientId || 0);
        if (Object.keys(patientFields).length) {
            setOldRiskFromReactivate({ oldRisk: newFields.oldRisk, patientId: newFields.patientId });
            delete newFields['oldRisk'];
            delete newFields['patientId'];
            delete newFields['newRisk'];
            delete newFields['riskCategoryId'];
            delete newFields['riskFlag'];
        }
        setValues((prev) => ({
            ...prev,
            ...newFields,
            sex: i18n.language === 'en' ? newFields.sex : entranslations[newFields.sex],
        }));
    }, []);

    const handleOnChange = (e) => {
        servererror && setServerError('');
        patientValidationError && setPatientValidationError('');
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
            otherDiag: name === 'diagnosis' ? '' : name === 'otherDiag' ? value : prev.otherDiag,
        }));
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['state', 'sex', 'month', 'date', 'diagnosis', 'menstrating', 'surgical', 'natural'].includes(currentFocus) &&
            inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    const scrollToView = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            newVals[field] = newVals[field]?.trim();
        });
        setValues(newVals);
        return newVals;
    };

    const checkAddress = async () => {
        const stateId = dropdown?.State?.find((o) => o.value === values.state.trim())?.id;
        if (stateId) {
            const addressPayload = { zipCode: values.zip, city: values.city.toUpperCase().trim(), state: +stateId };
            let response;
            response = await axiosInstanceSearch.post(`/validate/address`, addressPayload);
            if (response?.data?.message === 'Success') {
                return true;
            } else {
                setServerError(t('patientZipCombinationError'));
                scrollToView();
                return false;
            }
        } else {
            scrollToView();
            return false;
        }
    };
    const getEngVal = (val) => {
        const estranslations = { Femenino: 'female', Masculino: 'male', Sí: 'yesLabel', No: 'noLabel' };
        if (i18n.language !== 'en') {
            return t(estranslations[val], { lng: 'en' });
        }
        return val;
    };
    const dobValidation = () => {
        const sysDate = new Date(); //.toISOString().split('T')[0];

        const { month, date, year } = values;
        const dob = month + '/' + date + '/' + year;
        const format = 'MMM/DD/YYYY';
        if (Number(year) <= 1900) {
            setError(t('patientDOBCombinationError'));
            setCurrentFocus('month');
            scrollToView();
        } else if (moment(dob, format) > moment(sysDate) || !moment(dob, format, true).isValid()) {
            setError(t('patientDOBError'));
            setCurrentFocus('month');
            scrollToView();
        } else {
            return true;
        }
    };

    const checkDiagnosisValidation = async () => {
        const resp = await axiosInstance_2.get(
            `/diagnosis?patientGender=${getEngVal(values.sex)}&diagnosisCode=${values.diagnosis.split('-')[0]}`
        );
        if (resp.data.statusCode === 200) {
            return true;
        } else {
            setServerError(t('patientDiagnosissInvalidError'));
            scrollToView();
            return false;
        }
    };

    const checkRiskCategory = async () => {
        const { month, date, year, sex, menstrating, surgical, natural } = values;
        const dob = moment(month, 'MMM').format('MM') + '/' + date + '/' + year;
        const resp = await axiosInstance_ORC.post('/patient/register/validateRisk', {
            gender: getEngVal(sex).charAt(0),
            dob: dob,
            age: null,
            menstruating: sex === t('female') ? getEngVal(menstrating) : null,
            surgicalMenopause: sex === t('female') ? getEngVal(surgical) : null,
            naturalMenopause: sex === t('female') ? getEngVal(natural) : null,
            riskCategoryDescription: null,
            riskCategoryId: null,
            riskInterventionRequired: null,
        });
        if (resp.data.statusCode === 200 && resp.data.data.riskCategoryId !== 0) {
            return {
                riskCategoryId: resp.data.data.riskCategoryId,
                newRisk: resp.data.data.riskCategoryDescription,
                riskFlag: resp.data.data.riskInterventionRequired,
            };
        } else {
            setRiskError(true);
            scrollToView();
            return false;
        }
    };

    const confrimAPIValidations = async () => {
        let isValid = null;
        const isDobValid = dobValidation();
        if (isDobValid) {
            const validAddress = await checkAddress();
            if (validAddress) {
                //validPatient = true; //call patient api
                const validPatient: any =
                    patientFields.flowType && ['Reactivate', 'Reenroll'].includes(patientFields.flowType)
                        ? oldRiskFromReactivate
                        : await patientValidation();
                if (validPatient) {
                    const validDiganosis = await checkDiagnosisValidation();
                    if (validDiganosis) {
                        const validRisk = await checkRiskCategory(); //call risk api
                        if (validRisk) {
                            //return validRisk;
                            return {
                                riskDetails: validRisk,
                                patientDetails: validPatient,
                            };
                        }
                    }
                }
            }
        }
        return isValid;
    };

    const patientValidation = async () => {
        const dob = `${values.date}-${values.month}-${values.year.trim()}`;
        const payload = {
            patientIdentificationNumber: values.patientIdNum.trim(),
            patientNameFirst: values.firstName.trim(),
            patientNameLast: values.lastName.trim(),
            patientDob: dob,
            patientGender: values.sex?.charAt(0),
            productList: [selectedProgram.label],
        };
        let response;
        response = await axiosInstance_2
            .post(`/validate`, payload)
            .then((res) => {
                if (res?.status === 200) {
                    if (res?.data?.data) {
                        return { oldRisk: res?.data?.data?.patientRiskCategory, patientId: res?.data?.data?.patientId };
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    let errorMsg = '';
                    if (
                        error.response.data.data.errorMesssage?.code === 10142 ||
                        error.response.data.data.errorMesssage[0]?.code === 10143
                    ) {
                        let errorMsg_2 = i18n.language === 'es' ? `<br/>${t('errMsg_PV0455_3')}` : ``;
                        errorMsg = `<span> ${t('errMsg_PV0455_1')}${mainMenu(selectedProgram.id)}${t(
                            'errMsg_PV0455_2'
                        )}${errorMsg_2}</span>`;
                    } else if (error.response.data.data.errorMesssage.code === 10141) {
                        errorMsg = `<span> ${t('errMsg_PV0450_1')}${mainMenu(selectedProgram.id)}${t(
                            'errMsg_PV0450_2'
                        )}</span>`;
                    }
                    setPatientValidationError(errorMsg);
                    scrollToView();
                    return false;
                } else {
                    return false;
                }
            });

        return response;
    };

    const checkMaleValue = (label) => {
        return values.sex === t('male') ? t('noLabel') : values[label];
    };

    const checkFlowType = (val) => {
        if (patientFields.flowType) {
            if (['Reactivate', 'Reenroll'].includes(patientFields.flowType)) {
                return t(values['sex']?.toLowerCase()).toUpperCase();
            } else if (patientFields.flowType === 'EditPatient') {
                return values['sex'].charAt(0);
            } else {
                return val;
            }
        } else {
            return val;
        }
    };

    const handleSubmit = async () => {
        await trimValues();
        setServerError('');
        setRiskError(false);
        const fields = patientInfoFields;
        const reqFields = patientInfoFields.filter(
            (item) =>
                !(item.name === 'otherDiag' && values['diagnosis'] !== '000-OTHER') &&
                !(
                    ['surgical', 'natural', 'menstrating'].includes(item.name) &&
                    (values['sex'] === t('male') || values['sex'] === 'Male')
                )
        );

        for (let i of reqFields) {
            if (
                values[i.name]?.trim() === '' ||
                values[i.name] === t('selectState') ||
                values[i.name] === t('selectOne') ||
                values[i.name] === t('selectDiagnosis') ||
                (i.name === 'sex' && !values.sex)
            ) {
                if (i.name === 'diagnosis') {
                    if (patientFields.flowType !== 'EditPatient') {
                        setError(t(i.errMsg));
                        setCurrentFocus(i.name);
                        scrollToView();
                        return;
                    }
                } else {
                    setError(t(i.errMsg));
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (['firstName', 'lastName'].includes(i.name) && !isValidName(values[i.name])) {
                setCurrentFocus(i.name);
                setError(t(i.errMsg));
                scrollToView();
                return;
            } else if (values.mi && !isValidMI(values.mi)) {
                setError(t('patientMIError'));
                setCurrentFocus('mi');
                scrollToView();
                return;
            } else if (i.name === 'address' && !isValidAddr(values.address)) {
                setCurrentFocus(i.name);
                setError(t(i.errMsg));
                scrollToView();
                return;
            } else if (i.name === 'city' && !isValidCity(values.city)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'zip' && !isValidZip(values.zip)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'phone' && !isValidPhoneNumber(values.phone)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'year' && !isValidPatientNum(values.year, 4)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'patientIdNum' && !isValidPatientNum(values.patientIdNum, 9)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'otherDiag' && !isValidOtherDiag(values.otherDiag)) {
                setError(t(i.errMsg));
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else {
                setError('');
            }
        }
        if (patientFields.flowType === 'EditPatient') {
            const validAddress = await checkAddress();
            if (validAddress) {
                const patientDTOData = _.cloneDeep(patientPrescriberStore);
                const PatientDTOUpdateData = await PatientDTOUpdate(
                    patientDTOData,
                    values,
                    dropdown?.State,
                    onlineUserId
                );
                let response;
                response = await axiosInstance_2
                    .post(`/ext/update`, PatientDTOUpdateData?.data)
                    .then((resp) => {
                        dispatch(resetpatientPrescriberStore());
                        navigate('/prescriber/ppafWorkMenu');
                    })
                    .catch((error) => {
                        navigate('/prescriber/SystemError');
                    });
            }
        } else {
            const isValid = await confrimAPIValidations();
            if (isValid) {
                dispatch(
                    patientInformation({
                        ...values,
                        ...isValid.patientDetails,
                        ...isValid.riskDetails,
                        sex:
                            i18n.language === 'en'
                                ? values.sex
                                : Object.keys(entranslations).find((key) => entranslations[key] === values.sex),
                        patientId: exsitingPatientId || isValid.patientDetails.patientId,
                        menstrating: checkMaleValue('menstrating'),
                        surgical: checkMaleValue('surgical'),
                        natural: checkMaleValue('natural'),
                    })
                );
                navigate('/prescriber/patientVerification');
            }
        }
    };

    const showPINhelp = () => {
        const path = 'patientInformation';
        window.open(
            `${window.location.origin}/#/headerHelp?role=${role}&helpRef=${path}&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
            `${window.location.origin}/#/headerHelp?role=${role}&helpRef=${path}&id=${selectedProgram.id}&part=${isSubmitAndPrint}&account=${isAccount}&language=${i18n.language}`,
            'location=yes,height=379,width=808,scrollbars=yes,status=yes'
        );
    };

    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer}>
                <div className={classes.headerContainer}>
                    {selectedProgram.id && (
                        <div
                            className={classes.generalProgramLogo}
                            style={{
                                backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`,
                            }}
                        ></div>
                    )}
                    <div
                        className={classes.headerDivider}
                        style={{
                            borderColor: `${ProgramConfig(selectedProgram.id)?.color}`,
                        }}
                    ></div>
                </div>
                <div className={classes.productHeader}>{t('patientInfoHeader')}</div>
                <div className={classes.headerNote}>
                    {t('patientInfoDesc1')}
                    <span dangerouslySetInnerHTML={{ __html: mainMenu(selectedProgram.id) }}></span>.
                    {t('patientInfoDesc2')}
                </div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                <div className={classes.contentContainer}>
                    {servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                    {patientValidationError && (
                        <div
                            className={AlertClass.container}
                            style={{ margin: '10px 30px 5px 30px' }}
                            dangerouslySetInnerHTML={{ __html: patientValidationError }}
                        ></div>
                    )}
                    {riskError && (
                        <div className={classes.riskError}>
                            {t('patientRiskCategoryError1')}
                            <span dangerouslySetInnerHTML={{ __html: mainMenu(selectedProgram.id) }}></span>
                            {t('patientRiskCategoryError2')}
                        </div>
                    )}
                    <div style={{ paddingTop: 10 }}></div>
                    <Input
                        type={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'hidden'
                                : 'text'
                        }
                        name="firstName"
                        className={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'fontBold'
                                : ''
                        }
                        label={t('firstName')}
                        maxLength={30}
                        onChangeHandler={handleOnChange}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        value={values['firstName']}
                    />
                    <Input
                        type={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'hidden'
                                : 'text'
                        }
                        name="lastName"
                        label={t('lastName')}
                        className={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'fontBold'
                                : ''
                        }
                        onChangeHandler={handleOnChange}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        maxLength={30}
                        value={values['lastName']}
                    />
                    <Input
                        type={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'hidden'
                                : 'text'
                        }
                        name="mi"
                        label={t('mi')}
                        className={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'fontBold'
                                : ''
                        }
                        maxLength={1}
                        onChangeHandler={handleOnChange}
                        isRequired={false}
                        value={values['mi']}
                        style={{ width: 64 }}
                    />
                    <Input
                        type="text"
                        name="address"
                        label={t('address')}
                        maxLength={50}
                        onChangeHandler={handleOnChange}
                        isRequired={true}
                        value={values['address']}
                    />
                    <Input
                        type="text"
                        isRequired={true}
                        name="city"
                        maxLength={30}
                        label={t('city')}
                        onChangeHandler={handleOnChange}
                        value={values['city']}
                    />

                    <Input
                        type="select"
                        name="state"
                        label={t('state')}
                        isRequired={true}
                        onChangeHandler={handleOnChange}
                        value={values['state']}
                        options={
                            dropdown?.State
                                ? [
                                      { value: t('selectState') } as { value: string },
                                      ...dropdown?.State?.map((item) => ({ value: item.value })),
                                  ]
                                : [{ value: t('selectState') } as { value: string }]
                        }
                    />
                    <Input
                        type="text"
                        name="zip"
                        label={t('zip')}
                        isRequired={true}
                        maxLength={5}
                        helpText={t('helpTextState')}
                        onChangeHandler={handleOnChange}
                        value={values['zip']}
                    />
                    <Input
                        type="text"
                        name="phone"
                        label={t('phoneLabel')}
                        maxLength={12}
                        isRequired={true}
                        helpText={t('helpTextPhone')}
                        onChangeHandler={handleOnChange}
                        value={values['phone']}
                    />
                    <div className={inputStyles.container}>
                        <div className={inputStyles.label}>
                            <span
                                className={
                                    patientFields.flowType && patientFields.flowType === 'EditPatient'
                                        ? ''
                                        : inputStyles.mandatory
                                }
                            >
                                {t('DOB')}:
                            </span>
                        </div>
                        <div className={inputStyles.input}>
                            {patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType) ? (
                                <div className="fontBold">
                                    <label className={classes.fontBold}>
                                        {values['month'] + ' ' + values['date'] + ', ' + values['year']}
                                    </label>
                                </div>
                            ) : (
                                <div>
                                    <select
                                        style={{ width: 'auto' }}
                                        name="month"
                                        id="month"
                                        onChange={handleOnChange}
                                        value={values['month'] ? values['month'] : '---'}
                                    >
                                        <option key={t('selectOne')} value={t('selectOne')}>
                                            ---
                                        </option>
                                        {moment.monthsShort().map((i) => (
                                            <option key={i} value={i}>
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        style={{ width: 'auto', margin: '0 5px' }}
                                        name="date"
                                        id="date"
                                        onChange={handleOnChange}
                                        value={values['date'] ? values['date'] : '--'}
                                    >
                                        <option key={t('selectOne')} value={t('selectOne')}>
                                            --
                                        </option>
                                        {getDateArray()?.map((i) => (
                                            <option key={i} value={i}>
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        name="year"
                                        id="year"
                                        onChange={handleOnChange}
                                        maxLength={4}
                                        style={{ width: 60 }}
                                        value={values['year']}
                                        tabIndex={0}
                                    />
                                </div>
                            )}
                            {patientFields.flowType && patientFields.flowType === 'EditPatient' ? (
                                <></>
                            ) : (
                                <p className={inputStyles.helpText}>{t('helpTextDob')}</p>
                            )}
                        </div>
                    </div>
                    <Input
                        type={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'hidden'
                                : 'select'
                        }
                        name="sex"
                        className={
                            patientFields.flowType &&
                            ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                ? 'fontBold'
                                : ''
                        }
                        label={t('sex')}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        onChangeHandler={handleOnChange}
                        options={[{ value: t('selectOne') }, { value: t('female') }, { value: t('male') }]}
                        value={checkFlowType(values['sex'])}
                    />
                    <div className={classes.helpLink_postion}>
                        <Input
                            type={
                                patientFields.flowType &&
                                ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                    ? 'hidden'
                                    : 'text'
                            }
                            name="patientIdNum"
                            className={
                                patientFields.flowType &&
                                ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                    ? 'fontBold'
                                    : ''
                            }
                            maxLength={9}
                            label={t('patientPin')}
                            isRequired={
                                patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true
                            }
                            helpText={
                                patientFields.flowType && patientFields.flowType === 'EditPatient'
                                    ? ''
                                    : t(
                                          patientFields.flowType &&
                                              ['Reactivate', 'Reenroll', 'EditPatient'].includes(patientFields.flowType)
                                              ? 'helpTextPatientPinReadOnly'
                                              : 'helpTextPatientPin'
                                      )
                            }
                            onChangeHandler={handleOnChange}
                            value={values['patientIdNum']}
                        />
                        {!['Reactivate', 'EditPatient', 'Reenroll'].includes(patientFields.flowType) && (
                            <span tabIndex={0} className={classes.helpLink} onClick={showPINhelp}>
                                {t('helpLink')}
                            </span>
                        )}
                    </div>
                    <Input
                        type={patientFields.flowType === 'EditPatient' ? 'hidden' : 'select'}
                        name="diagnosis"
                        label={t('diagnosis')}
                        className={patientFields.flowType === 'EditPatient' ? 'fontBold' : ''}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        onChangeHandler={handleOnChange}
                        style={{ width: 475 }}
                        value={values['diagnosis']}
                        options={
                            dropdown?.['ICD-10']
                                ? [
                                      { value: t('selectDiagnosis') } as { value: string },
                                      ...dropdown?.['ICD-10']?.map((item) => ({ value: item.id + '-' + item.value })),
                                  ]
                                : [{ value: t('selectDiagnosis') } as { value: string }, { value: 'er' }]
                        }
                    />
                    {values['diagnosis'] === '000-OTHER' && (
                        <Input
                            type={patientFields.flowType === 'EditPatient' ? 'hidden' : 'text'}
                            className={patientFields.flowType === 'EditPatient' ? 'fontBold' : ''}
                            name="otherDiag"
                            label={t('otherdiagnosis')}
                            maxLength={50}
                            onChangeHandler={handleOnChange}
                            isRequired={values['diagnosis'].includes('OTHER') ? true : false}
                            value={values['otherDiag']}
                        />
                    )}
                    <Input
                        type={patientFields.flowType === 'EditPatient' ? 'hidden' : 'select'}
                        className={patientFields.flowType === 'EditPatient' ? 'fontBold' : ''}
                        name="menstrating"
                        label={t('menstruateLabel')}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        onChangeHandler={handleOnChange}
                        options={[{ value: t('selectOne') }, { value: t('yesLabel') }, { value: t('noLabel') }]}
                        value={
                            patientFields.flowType === 'EditPatient'
                                ? values['menstrating'].charAt(0)
                                : values['menstrating']
                        }
                        disabled={values['sex'] === t('male')}
                    />
                    <Input
                        type={patientFields.flowType === 'EditPatient' ? 'hidden' : 'select'}
                        className={patientFields.flowType === 'EditPatient' ? 'fontBold' : ''}
                        name="surgical"
                        label={t('surgicalLabel')}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        onChangeHandler={handleOnChange}
                        options={[{ value: t('selectOne') }, { value: t('yesLabel') }, { value: t('noLabel') }]}
                        value={
                            patientFields.flowType === 'EditPatient' ? values['surgical'].charAt(0) : values['surgical']
                        }
                        disabled={values['sex'] === t('male')}
                    />
                    <Input
                        type={patientFields.flowType === 'EditPatient' ? 'hidden' : 'select'}
                        className={patientFields.flowType === 'EditPatient' ? 'fontBold' : ''}
                        name="natural"
                        label={t('naturalLabel')}
                        isRequired={patientFields.flowType && patientFields.flowType === 'EditPatient' ? false : true}
                        onChangeHandler={handleOnChange}
                        options={[{ value: t('selectOne') }, { value: t('yesLabel') }, { value: t('noLabel') }]}
                        value={
                            patientFields.flowType === 'EditPatient' ? values['natural'].charAt(0) : values['natural']
                        }
                        containerStyle={{ gridTemplateColumns: '0.6fr 1.3fr', marginLeft: 38 }}
                        disabled={values['sex'] === t('male')}
                    />
                    <div className={classes.btnMargin}>
                        <Button
                            clickFunction={handleSubmit}
                            className={getBtnClass(selectedProgram.id)}
                            width={125}
                            height={48}
                            size={18}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                        ></Button>
                    </div>
                </div>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
};

export default PatientInformation;
