import PendingEnrollmentModal from 'components/PendingEnrollment';
import { axiosInstanceSearch, axiosInstanceSurvey, axiosInstance_2 } from 'constants/Config/axiosConfig';
import {PROGRAMS,ProgramConfig, ProgramConfigByName} from 'constants/Config/programsConfig';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { changeProgram, updateSelectedPatient, setPatientData, setPatientId, setActionItemInfo, setPrescriptionPaylod, setPrescriberCalendarActionItemPayload, setSurveyInfoFromRoute, changePPAF } from 'store/slices/appSlice';
import downArrow from '../../assets/images/btn_arrow_down.png';
import rightArrow from '../../assets/images/btn_arrow_right.png';
import upArrow from '../../assets/images/btn_arrow_up.png';
import classes from './PrescriptionActionItems.module.scss';
import PrescriberActionItemsPagination from './PrescriberActionItemsPagination';
import EnrollmentModal from 'components/EnrollmentModal';
import { bodyContent } from 'assets/objects/LoginHome';
import { setSurveyDataFromPatientDetails } from '../../store/slices/appSlice';


const ActionItemTable = ({ fields, isReset, setIsReset, patientData, setPatientDatas, errMsg, setErrMsg, tabNumb, filterData }) => {
    const [pendingEnrollment, setPendingEnrollment] = useState(false);
    const programList:any = PROGRAMS;
    const [notEnrolled, setNotEnrolled] = useState(false);
    const [enrollmentMessage, setEnrollmentMessage] = useState<any>('');
    const [totalPages, setTotalPages] = useState<any>(null);
    const { prescriberEnrollment, prescriptionPaylod } = useAppSelector((state) => state.app);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const { patientDataFromCalender = [], calendarActionItemPayload = {} } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails?.prescriberId);
    const initialised = useRef(false);
   const [isReload,SetIsReload]=useState<any>(false);
    if(initialised.current && (isReset || fields.isFiltered||isReload))
    {
        initialised.current = false
        SetIsReload(false);
    }
    const getPgmName = (name) =>{        
        return ProgramConfigByName(name?.toLowerCase().trim()).registeredTradermark ? name?.toUpperCase() : name.charAt(0) + name.slice(1).toLowerCase();
    }
    

    const goToPatientDetails = (row) => {
        dispatch(updateSelectedPatient(row));
        dispatch(setActionItemInfo({ 
            isNavigate: true,
            fields,
            pageNo: prescriptionPaylod?.page,
            authStatusId: Object.entries(calendarActionItemPayload)?.length > 0 
                ? calendarActionItemPayload?.payload?.authorizationStatusIds 
                : prescriptionPaylod?.authorizationStatusIds,
        }));
        navigate('/prescriber/patientdetails');
    };
    const fetchData = async () => {
        const { page, ...payload } = prescriptionPaylod;
        try {
            const resp = await axiosInstanceSearch.post(`/prescription/get-action-item?page=${page}`, payload);
            if (resp?.data?.statusCode === 200) {
                setPatientDatas(resp?.data.data?.content);
                setTotalPages(resp?.data.data?.totalPages)
                // Error scenario
                !resp?.data.data?.content?.length && fields['isFiltered']
                    ? setErrMsg(t('noRecords'))
                    : setErrMsg(t('noPatientRecord'));
            }
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {       
        if (!initialised.current) {
            initialised.current = true;
            if (!patientDataFromCalender?.length &&
                (calendarActionItemPayload && !calendarActionItemPayload?.isFromCalender) &&
                prescriptionPaylod.prescriberNonPrescriberId &&
                Number(tabNumb) === 1
            ) {
                fetchData();
            } else {
                setPatientDatas(patientDataFromCalender);
                if (!patientDataFromCalender?.length) setErrMsg(t('noRecords'));
            }
        }
    }, [prescriptionPaylod, patientDataFromCalender]);

    const navigateToPrescriberSurvery = (data) => {
        if(nonPrescriber){
            dispatch(setSurveyDataFromPatientDetails(data))
            navigate('/prescriber/PrescriberSearch');
        }else{
            dispatch(setSurveyInfoFromRoute(data))
            navigate('/prescriber/prescribersurveyinformation');
        }
    }

    const PSRSurveyAPI = async (row) => {
        const patientResp = await axiosInstance_2.post('/findPatient', {
            patientExternalUniqueId: row?.patientExtUniqueId,
        });

        if (patientResp?.data?.message === 'SUCCESS') {
            dispatch(setPatientData(patientResp?.data?.data));
            dispatch(setPatientId(patientResp?.data?.data?.patientId));
            const payload = {
                Channel: 'EXT',
                PrescriberId: row?.assocPrescriberId,
                PatientId: row?.patientId,
                ProductCountryId: row?.productCountryId,
                LanguageId: i18n.language === 'es' ? 2 : 1,
            };
            await axiosInstanceSurvey
                .post('/v1/surveys/ivr-validate-prescriber-survey-eligibility', payload)
                .then((res: any) => {
                    dispatch(changePPAF(true));
                    if (res?.data?.ResponseStatus === '0') {
                        navigateToPrescriberSurvery(res.data)
                        dispatch(setActionItemInfo({ 
                            isNavigate: true,
                            fields,
                            pageNo: prescriptionPaylod?.page,
                            authStatusId: Object.entries(calendarActionItemPayload)?.length > 0 ? calendarActionItemPayload?.payload?.authorizationStatusIds : prescriptionPaylod?.authorizationStatusIds,
                        }));
                        
                    } else if (res?.data?.ResponseStatus === '1') {
                        if (res?.data?.SurveyIneligibleMessage) {
                            navigateToPrescriberSurvery({surveryErrMessage: res?.data?.SurveyIneligibleMessage})
                        } else {
                            const errorMessage = 'program not active';
                            navigateToPrescriberSurvery({surveryErrMessage:errorMessage})
                        }
                    }
                })
                .catch((error) => {
                    navigate('/prescriber/SystemError');
                });
        }
    };

    let defaultAPIpayloadObj: any =
    calendarActionItemPayload?.isFromCalender ?
        {
            startDate: moment(calendarActionItemPayload?.payloadDate).format('MM/DD/YYYY'),
            endDate: moment(calendarActionItemPayload?.payloadDate).format('MM/DD/YYYY'),
            prescriberIds: calendarActionItemPayload?.prescriberIds,
            loggedInPrescId: !nonPrescriber ? prescriberId : null,
            loggedInNonPrescId: nonPrescriber ? currentUser.nonPrescriberId : null,
            productId: null,
            prescriberLastName: null,
            authorizationNumber: null,
            authorizationStatusIds: calendarActionItemPayload?.authStatusIds?.length > 0 ? calendarActionItemPayload?.authStatusIds : null,
            sortByField: null,
            sortByOrder: null,
            prescriber: !nonPrescriber,
        }
        :
        {
            prescriberNonPrescriberId: !nonPrescriber ? prescriberId : currentUser.nonPrescriberId,
            productId: null,
            authorizationNumber: null,
            authorizationStatus: null,
            assocPrescriberId: null,
            assocPrescriberName: null,
            authorizationStatusIds: null,
            sortByField: null,
            sortByOrder: null,
            page: 0,
            prescriber: !nonPrescriber,
        }

    const handlePenEnrollOutsideClick = (e) => {
        if (e.target.className.indexOf('pendingEnrollment_modalContainer') === 0) {
            setPendingEnrollment(false);
        }
        e.stopPropagation();
    };

    const goToSurvey = async (row) => {
        dispatch(changeProgram(programList?.find((i) => i?.productCountryId === row?.productCountryId)));
        const payload = {
            systemId: prescriptionPaylod.prescriber ? prescriptionPaylod.prescriberNonPrescriberId : row.assocPrescriberId,
            program: row.productName?.split(' ')?.[0],
            enrolmentStatus: 'Active',
        };
        try {
            const resp = await axiosInstanceSearch.post('/prescriberSearch', payload);
            if (!resp.data.data?.length) {
                if (prescriberEnrollment?.filter((ob) => ob?.productCountryId === row?.productCountryId)?.length > 0) {
                    // pending or deactivate
                    setPendingEnrollment(true); 
                } else {
                    // not enrolled for pgm
                    setEnrollmentMessage(bodyContent.programs?.find((pgm) => pgm.name?.split(' ')[0]?.toLowerCase() === row?.productName?.split(' ')[0]?.toLowerCase())?.title?.split('For ')[1]);
                    setNotEnrolled(true);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                PSRSurveyAPI(row);
                
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleEnrollmentCancel = () => {
        setNotEnrolled(false);
    };

    const handleEnrollmentContinue = () => {
        navigate('/prescriber/productRegistration');
    };

    const onHandleContactUs = () => {
        window.open(
            `${window.location.origin}/#/ContactUs`,
            'contactUs',
            'location=yes,height=400,width=820,scrollbars=yes,status=yes'
        );
    };
    const checkToday = (date)=>{
        
        return moment(date).isSame(moment(),'day')
    }
    const authStatusMap = (status, row) => {
      
        const PrescLink = <p tabIndex={0} className={classes.surveyLink} onClick={() => goToSurvey(row)}>Prescriber Survey Required <img src={rightArrow} alt="right-arrow" /> </p>;
        const presSurveyText = <p>Prescriber Survey Required</p>
        switch (status) {
            case 'Active':
                return 'Active-Ready for Pharmacy';
            case 'Expired':
                return <p>Expired- {PrescLink}</p>;
            case 'Confirmed':
                return <>
                    {calendarActionItemPayload?.isFromCalender && !checkToday(row.calendarDate) ? presSurveyText : PrescLink}
                </>;
            case 'Obsolete':
                return <p>Cancelled- {PrescLink}</p>;
            case 'Mismatched':
                return <>
                    <p>Survey-Flagged:</p>
                    <p className={classes.surveyLink} onClick={onHandleContactUs}>Call REMS Call Center <img src={rightArrow} alt="right-arrow" /> </p>
                </>;
            case null:
                return <>{PrescLink}</>; // when Patient active and authstatus is null (after completing PPAF)
            default:
                return status;
        }
    };

    const sortingHandler = (field,order) => {
        SetIsReload(true);
        setIsReset(true);

        const filterPayload =  {
            ...filterData?.payload,
            sortByField: field,
            sortByOrder: order,
        };

        if (calendarActionItemPayload?.isFromCalender) {
            const payloadObj = {
                ...defaultAPIpayloadObj,
                sortByField: field,
                sortByOrder: order,
            };
            dispatch(setPrescriberCalendarActionItemPayload(filterData?.isFilterActive ?{ ...calendarActionItemPayload, payload: filterPayload } : { ...calendarActionItemPayload, payload: payloadObj }))
        } else {
            const payloadObj = {
                ...defaultAPIpayloadObj,
                sortByField: field,
                sortByOrder: order,
            };
            dispatch(setPrescriptionPaylod(filterData?.isFilterActive ? filterPayload : payloadObj));
        }
    }

    const tableHeader = () => {
        return (
            <tr>
                <th>
                    <div className={classes.flex_content}>
                        <label className={classes.wrap_label}>Patient Name</label>
                        <div>
                            <img tabIndex={0} src={upArrow} className={classes.icon} alt="up-arrow" onClick={()=>{sortingHandler('patientlastname','ASC')}} />
                            <img tabIndex={0} src={downArrow} className={classes.icon} alt="down-arrow" onClick={()=>{sortingHandler('patientlastname','DESC')}} />
                        </div>
                    </div>
                </th>
                <th>
                    <div className={classes.flex_content}>
                        <label className={classes.wrap_label}>REMS Program</label>
                        <div>
                            <img tabIndex={0} src={upArrow} className={classes.icon} alt="up-arrow" onClick={()=>{sortingHandler('productname','ASC')}}/>
                            <img tabIndex={0} src={downArrow} className={classes.icon} alt="down-arrow" onClick={()=>{sortingHandler('productname','DESC')}}/>
                        </div>
                    </div>
                </th>
                <th className={classes.nested_column}>
                    <label>Authorization</label>
                    <div className={`${classes.grid_content} ${classes.nested_heading}`}>
                        <div className={classes.flex_content}>
                            <label>Number</label>
                            <div>
                                <img tabIndex={0} src={upArrow} className={classes.icon} alt="up-arrow" onClick={()=>{sortingHandler('authorizationnumber','ASC')}}/>
                                <img tabIndex={0} src={downArrow} className={classes.icon} alt="down-arrow" onClick={()=>{sortingHandler('authorizationnumber','DESC')}}/>
                            </div>
                        </div>
                        <div className={classes.flex_content}>
                            <label>Expires</label>
                            <div>
                                <img tabIndex={0} src={upArrow} className={classes.icon} alt="up-arrow" onClick={()=>{sortingHandler('authorizationexpirationdate','ASC')}}/>
                                <img tabIndex={0} src={downArrow} className={classes.icon} alt="down-arrow" onClick={()=>{sortingHandler('authorizationexpirationdate','DESC')}}/>
                            </div>
                        </div>
                    </div>
                </th>
                <th>
                    <label>
                        <span className={classes.important}>!</span>Status and Action Required
                    </label>
                </th>
                <th>
                    <div className={classes.flex_content}>
                        <label className={classes.wrap_label}>Prescriber Name</label>
                        <div>
                            <img tabIndex={0} src={upArrow} className={classes.icon} alt="up-arrow" onClick={()=>{sortingHandler('assocprescriberlastname','ASC')}}/>
                            <img tabIndex={0} src={downArrow} className={classes.icon} alt="down-arrow" onClick={()=>{sortingHandler('assocprescriberlastname','DESC')}}/>
                        </div>
                    </div>
                </th>
            </tr>
        );
    };

    return (
        <>
            {pendingEnrollment && (
                <PendingEnrollmentModal message="" handleOutsideClick={(e) => handlePenEnrollOutsideClick(e)} />
            )}
            {notEnrolled && (
                <EnrollmentModal
                    message={enrollmentMessage}
                    handleCancel={handleEnrollmentCancel}
                    handleContinue={handleEnrollmentContinue}
                />
            )}
            {patientData && (
                <div className={`${(patientDataFromCalender?.length && patientDataFromCalender.length > 0) ? classes.scroll : ''}`}>
                    <table className={classes.action_table}>
                        <thead>{tableHeader()}</thead>
                        <tbody>
                            {!!patientData?.length ? (
                                patientData?.map((item) => (
                                    <tr>
                                        <td>
                                            <p tabIndex={0} role='button' onClick={() => goToPatientDetails(item)}>
                                                <span className={classes.cursorcls}>{item.patientNameLast + ', '}</span>
                                                <span className={classes.cursorcls}>
                                                    {item.patientNameFirst + ' '}
                                                    <img src={rightArrow} alt="right-arrow" />
                                                </span>
                                            </p>
                                        </td>
                                        <td>{getPgmName(item.productName?.split(' ')?.[0])}</td>
                                        <td>
                                            <div className={classes.grid_content}>
                                                <label>{item.authorizationNumber?.substring(2)}</label>
                                                <label>
                                                    {item.authorizationExpirationDate
                                                        ? moment(item.authorizationExpirationDate).format(
                                                              'MMM DD, YYYY'
                                                          )
                                                        : null}
                                                </label>
                                            </div>
                                        </td>
                                        <td>{authStatusMap(item.authorizationStatus, item)}</td>
                                        <td>{item.assocPrescriberNameLast + ', ' + item.assocPrescriberNameFirst}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} className={classes.empty_data}>
                                        {errMsg}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {/* {(patientDataFromCalender?.length && patientDataFromCalender.length > 0) ? null : <PrescriberActionItemsPagination totalPages = {totalPages} isReset={isReset} setIsReset={setIsReset}/>} */}
            <PrescriberActionItemsPagination totalPages = {totalPages} isReset={isReset} setIsReset={setIsReset} SetIsReload={SetIsReload}/>
        </>
    );
};

export default ActionItemTable;