import {ProgramConfig,lenalidomide,pomalidomide,thalomid,PROGRAMS} from 'constants/Config/programsConfig';

export const headContent = [
    {
        order: 1,
        content: `Welcome to the REMS Programs Administered by Bristol Myers Squibb`,
    },
    {
        order: 2,
        content: `To avoid embryo-fetal exposure, Risk Evaluation and Mitigation Strategy (REMS) programs are
         mandatory for the REMS products ${ProgramConfig('rems_program_thalomid').label?.toUpperCase()}<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span>  (thalidomide), ${pomalidomide.toUpperCase()}<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span>  (pomalidomide),
        REVLIMID<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span>  (${lenalidomide}) and generic lenalidomide. The ${ProgramConfig('rems_program_thalomid').title}<span style="
                        font-size: 0.8em;
                        position: relative;
                        top: -0.5em;
                    ">®</span>  program, ${ProgramConfig('rems_program_lenalidomide').title} program and
        ${ProgramConfig('rems_program_pomalyst').title}<sup>®</sup>  program require prescribers and pharmacists to be certified and patients to enroll and comply
         with all of the requirements for each program.`,
    },
    {
        order: 3,
        content: `If you would like to obtain more information about any of the Bristol Myers Squibb-administered REMS programs, please click on the program name below:`,
    },
    {
        order: 4,
        content: `To complete a REMS related task, please select the appropriate option from below:`,
    },
];
const getPrograms =()=>{
    let prgms =[];
    PROGRAMS.forEach((i)=>{
        prgms.push({
            name:i.title,
            link:i.link
        })
    })
    return prgms;
}
export const bodyContent = {
    programs: getPrograms(),
    tasks: [
        {
            name: 'Prescribers',
            content:
                'The Prescriber Portal for Bristol Myers Squibb-administered REMS Programs can be accessed by clicking on the below button. Please enter your User Name and Password to manage your patients. If you do not have an online account, select Create User Account to establish an account.',
            btnName: 'Prescriber',
            url: '/prescriber/login',
            homeurl: '/prescriber/LoginHome',
        },
        {
            name: 'Patients',
            content:
                'Patients currently enrolled in a Bristol Myers Squibb-administered REMS program are not required to create an online account to complete a survey. Please select Patient Surveys and enter the information requested to begin a survey.',
            btnName: 'Patient',
            url: '/prescriber/patientSurvey?action=patientSurveyAction',
            homeurl: '/prescriber/patientSurvey?action=patientSurveyAction',
        },
        {
            name: 'Pharmacies',
            content:
                'The Pharmacy Portal for Bristol Myers Squibb-administered REMS Programs can be accessed by clicking on the below button. Please enter your Username and Password to begin. If you do not have an online account, please contact your REMS Representative.',
            btnName: 'Pharmacy',
            url: '/pharmacy/login',
            homeurl: '/pharmacy/PharmacyHome',
        },
    ],
};
