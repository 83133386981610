import { formatDate } from 'utils/formatDate';
import { trimPrefix } from 'utils/trimPrefix';

export const getReviewAuthPrintPopUpTemplate = (authData, pharmacy) => {
    const {
        patientNameFirst,
        patientNameLast,
        productName,
        authCreatedDate,
        authorizationNumber,
        authorizationStatus,
        authAge,
        activeStatusExpirationDate,
        patientDob,
        patientRiskCategoryName,
        patientLastSurveyDate,
        patientNextEarliestSurveyDate,
        confirmationNumber,
        dispenseCreatedDate,
        cancellationNumber,
        cancelledDate,
        dispensationSequences,
        pharmacyId,
        patientExternalUniqueId,
        productCountryId,
    } = authData[0];

    const pharmacyDetails = pharmacyId
        ? {
              pharmacyName: pharmacy?.pharmacyName,
              pharmacyInfo: pharmacy?.pharmacyLocations[0],
              pharmacyNumber: pharmacy?.pharmacyExternalId2 ? pharmacy.pharmacyExternalId2 : (pharmacy?.pharmacyExternalId1 ? pharmacy.pharmacyExternalId1 : ''),
          }
        : null;

    const showAuthStatus = (status) => {
        return status.toLowerCase() == 'mismatched'
            ? 'Survey-Flagged'
            : status.toLowerCase() == 'obsolete'
            ? 'Cancelled'
            : status;
    };

    const renderDispenseTable = (data) => {
        let rows;
        if (data && data.length > 0) {
            rows = data.filter((each)=>{ return !each.deactivationDate; }).map((each) => {
                    return `
                    <tr key="${each.dispensationId}">
                        <td>${each.ndcId?.ndcDescription || ''}</td>
                        <td>${each.dispensationQuantity || ''}</td>
                    </tr>
                `;
                })
                .join('');
        } else {
            rows = `<tr class="myLocationsOdd">
						<td class="reviewAuthInfo headerBorderRight pad_T pad_B pad_L">&nbsp;</td>
						<td class="reviewAuthInfo pad_T pad_B pad_L">&nbsp;</td>
					</tr>`;
        }
        return rows;
    };

    const renderPharmacyTable = () => {
        let table;
        if (pharmacyId) {
            table = `
				<table class="reviewAuthPharmacyTable" cellpadding="5" cellspacing="0" border="1">
						<tr>
							<td  class="fixedWidth">${
								pharmacyDetails?.pharmacyName ? pharmacyDetails?.pharmacyName : ' '
							}</td>
						</tr>
						<tr>
							<td class="fixedWidth">
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.addressLine1
											? pharmacyDetails.pharmacyInfo.addressLine1 + ' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.addressLine2
											? pharmacyDetails.pharmacyInfo.addressLine2 + ' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.addressLine3
											? pharmacyDetails.pharmacyInfo.addressLine3 + ' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.addressLine4
											? pharmacyDetails.pharmacyInfo.addressLine4 + ' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.postalCode?.city
											? pharmacyDetails.pharmacyInfo.postalCode.city + ' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.postalCode?.state?.stateName
											? pharmacyDetails.pharmacyInfo.postalCode.state.stateName +
												' '
											: ' '
									}
								</span>
								<span>
									${
										pharmacyDetails?.pharmacyInfo?.postalCode?.postalCode1
											? pharmacyDetails.pharmacyInfo.postalCode.postalCode1 + ' '
											: ' '
									}
								</span>
							</td>
						</tr>
						<tr>
							<td  class="fixedWidth">${
								pharmacyDetails?.pharmacyInfo?.pharmacyPhoneNumber
									? 'Phone:' + pharmacyDetails?.pharmacyInfo?.pharmacyPhoneNumber
									: ' '
							}</td>
						</tr>
						<tr>
							<td  class="fixedWidth">${
								pharmacyDetails?.pharmacyInfo?.pharmacyFaxNumber
									? 'Fax:' + pharmacyDetails?.pharmacyInfo?.pharmacyFaxNumber
									: ' '
							}</td>
						</tr>
					</table>
            `;
        } else {
            table = `<table class="reviewAuthTable reviewAuthPatientRow" width="98%">
						<tbody><tr>
							<td align="right"><a href="javascript: closeLocationPopup()"><span class="icon closePopup"></span></a></td>
						</tr>
					</tbody></table>`;
        }
        return table;
    };

    return `<html>
    <head>
        <title>${window.location.href}</title>
        <style>
            .title {
                font-size: 18px;
                font-weight: bold;
                padding: 10px 0px;
            }

            .label {
                margin-right: 2px;
                font-weight: bold;
            }

            .bigPad_L {
                padding-left: 15px;
            }

            .bigPad_T {
                padding-top: 15px;
            }

            .reviewAuthPharmacyTable {
                width: 120%;
                margin-top: 4px;
            }

            .reviewAuthDispenseTable {
                margin-top: 8px;
            }

            .mainContentContainerBottomBorder .leftCorner {
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
            }

            .reviewAuthPharmacyTable{
                border:none;
                border-collapse: collapse;
            }

            .fixedWidth{
                word-wrap: break-word;
                vertical-align: top;
                text-align: left;
                padding: 3px 3px 3px 0px;
                max-width: 192px;
                border:none;
                border-collapse: collapse;
            }

            .pb-4{
                padding-bottom:4px;
                display:inline-block;
            }

        </style>
    </head>
    <body>
        <table width="836px" cellpadding="0" cellspacing="0" border="0" align="center">
            <colgroup>
                <col width="9px" />
                <col width="818px" />
                <col width="9px" />
            </colgroup>
            <tbody>
                <tr class="mainContentContainerTopBorder">
                    <td><span class="leftCorner"></span></td>
                    <td><span class="middleEdge"></span></td>
                    <td><span class="rightCorner"></span></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <table width="836px" cellpadding="0" cellspacing="0" border="0" class="mainContentContainer">
                            <colgroup>
                                <col width="28px" />
                                <col width="780px" />
                                <col width="28px" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <div class="header">
                                            <span class="icon logo left"></span>
                                            <div class="title right">Review Authorization</div>
                                            <div class="buffer clear"></div>
                                        </div>
                                        <div class="slimPad_B">
                                            <div class="bold">&nbsp;</div>
                                        </div>

                                        <table
                                            width="800px"
                                            height="50px"
                                            cellpadding="5"
                                            cellspacing="0"
                                            border="1"
                                            align="center"
                                            class="reviewAuthTable"
                                        >
                                            <tbody>
                                                <tr valign="middle" class="splitHeader">
                                                    <th align="left" class="value">Patient<br />Name</th>
                                                    <th align="left" class="value">REMS Program</th>

                                                    <th align="left" class="value">Authorization Date</th>

                                                    <th align="left" class="value">Authorization Number</th>
                                                    <th align="left" class="value">Authorization Status</th>

                                                    <th align="left" class="value">Authorization Age</th>

                                                    <th align="left" class="topHeader reviewAuthTableHeader">
                                                        Authorization&nbsp;&nbsp;&nbsp;&nbsp; Expiration Date
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="22%"
                                                    >
                                                        ${patientNameFirst ? patientNameFirst : ' ' + ' '}
                                                        ${patientNameLast ? patientNameLast : ' '}
                                                    </td>
                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="10%"
                                                    >
                                                        ${productName ? productName : ' '}
                                                    </td>

                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="20%"
                                                    >
                                                        ${ authCreatedDate ? formatDate(authCreatedDate) : ' ' }
                                                    </td>
                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="23%"
                                                    >
                                                        ${ authorizationNumber ? trimPrefix(authorizationNumber) : ' ' }
                                                    </td>
                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="20%"
                                                    >
                                                        ${ authorizationStatus ? showAuthStatus(authorizationStatus) : ' ' }
                                                    </td>
                                                    <td
                                                        class="headerBorderRight reviewAuthPatientRow reviewAuthInfo"
                                                        width="20%"
                                                    >
                                                        ${authAge ? Math.abs(authAge) : ' '}
                                                    </td>

                                                    <td class="reviewAuthPatientRow reviewAuthInfo" width="25%">
                                                        <span style="width: 0px"></span>${ activeStatusExpirationDate ?
                                                        formatDate(activeStatusExpirationDate) : ' ' }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                        <div class="buffer"></div>
                                        <div class="buffer"></div>
                                        <table width="800px" cellpadding="0" cellspacing="0" border="1">
                                            <colgroup>
                                                <col width="5px" />
                                                <col width="770px" />
                                                <col width="5px" />
                                            </colgroup>
                                            <tbody>
                                                <tr class="contentContainerTopBorder">
                                                    <td><span class="leftCorner"></span></td>
                                                    <td><span class="middleEdge"></span></td>
                                                    <td><span class="rightCorner"></span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="contentContainer">
                                                        <table
                                                            width="796px"
                                                            cellpadding="10"
                                                            cellspacing="0"
                                                            border="0"
                                                            align="center"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table
                                                                            width="720px"
                                                                            height="50px"
                                                                            cellpadding="0"
                                                                            cellspacing="0"
                                                                            border="0"
                                                                            align="center"
                                                                        >
                                                                            <tbody>
                                                                                <tr valign="top" class="pad_T">
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="pad_T reviewAuthInfo"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Patient's Date of
                                                                                            Birth:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L pad_T reviewAuthInfo"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${ patientDob ?
                                                                                        formatDate(patientDob) : ' ' }
                                                                                    </td>

                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="pad_T reviewAuthInfo"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Confirmation Number:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L pad_T reviewAuthInfo"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${confirmationNumber ?
                                                                                        trimPrefix(confirmationNumber) :
                                                                                        ' '}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr valign="top" class="pad_T">
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Risk Category:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${patientRiskCategoryName ?
                                                                                        patientRiskCategoryName : ' '}
                                                                                    </td>
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Date Confirmation Number
                                                                                            was Obtained:</span
                                                                                        >
                                                                                    </td>

                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${(dispensationSequences && dispensationSequences.length) > 0 ?
                                                                                        formatDate(dispensationSequences[0]?.dispensationDate)
                                                                                        : ' '}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr valign="top" class="pad_T">
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Patient's Last Survey
                                                                                            Date:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${patientLastSurveyDate ?
                                                                                        formatDate(patientLastSurveyDate)
                                                                                        : ' '}
                                                                                    </td>
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Cancellation Number:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${cancellationNumber ?
                                                                                        cancellationNumber : ' '}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr valign="top" class="pad_T">
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Patient's Next Survey
                                                                                            Available On:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${patientNextEarliestSurveyDate
                                                                                        ?
                                                                                        formatDate(patientNextEarliestSurveyDate)
                                                                                        : ' '}
                                                                                    </td>
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Cancellation Date:</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bold bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        ${cancelledDate ?
                                                                                        formatDate(cancelledDate) : ' '}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr valign="top" class="pad_T">
                                                                                    <td class="bigPad_T" colspan="2">
                                                                                        <span
                                                                                            class="bold reviewAuthInfo pad_B"
                                                                                            style="font-weight: bold"
                                                                                        >
                                                                                            Dispense Details</span
                                                                                        >
                                                                                        <div class="buffer"></div>
                                                                                        <table
                                                                                            width="300px"
                                                                                            class="reviewAuthDispenseTable"
                                                                                            cellpadding="5"
                                                                                            cellspacing="0"
                                                                                            border="1"
                                                                                        >
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    valign="top"
                                                                                                    class="splitHeader"
                                                                                                >
                                                                                                    <th
                                                                                                        align="left"
                                                                                                        width="90%"
                                                                                                        class="value pad_T pad_B pad_L"
                                                                                                    >
                                                                                                        Product
                                                                                                        Identification
                                                                                                    </th>
                                                                                                    <th
                                                                                                        align="left"
                                                                                                        width="10%"
                                                                                                        class="topHeader reviewAuthTableHeader pad_T pad_B pad_L"
                                                                                                    >
                                                                                                        Quantity
                                                                                                    </th>
                                                                                                </tr>
                                                                                                <tr></tr>
                                                                                                ${renderDispenseTable(dispensationSequences)}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                    <td
                                                                                        align="right"
                                                                                        width="25%"
                                                                                        class="reviewAuthInfo bigPad_T"
                                                                                    >
                                                                                        <span class="label"
                                                                                            >Pharmacy Location</span
                                                                                        >
                                                                                    </td>
                                                                                    <td
                                                                                        align="left"
                                                                                        class="bigPad_L reviewAuthInfo bigPad_T"
                                                                                        width="25%"
                                                                                    >
                                                                                        <span
                                                                                            class="bold reviewAuthInfo pad_B fixedWidth"
                                                                                        >
                                                                                            
                                                                                            <span class="pb-4"> ${pharmacyDetails?.pharmacyNumber
                                                                                            ?
                                                                                            pharmacyDetails.pharmacyNumber + '-'
                                                                                            : ' '} </span>

                                                                                            <br>

                                                                                             <span>
                                                                                             
                                                                                             	${pharmacyDetails?.pharmacyInfo?.postalCode?.city
                                                                                                        ? pharmacyDetails.pharmacyInfo.postalCode.city+','
                                                                                                        : ' '} 
                                                                                            
                                                                                            </span>

                                                                                            <span>
                                                                                             
                                                                                             	${pharmacyDetails?.pharmacyInfo?.postalCode?.state?.stateCode
                                                                                                        ? pharmacyDetails.pharmacyInfo.postalCode.state.stateCode 
                                                                                                        : ' '} 
                                                                                            
                                                                                            </span>

                                                                                            

                                                                                            </span>
                                                                                        <div class="buffer"></div>
                                                                                        <div
                                                                                            class="review_auth_location none"
                                                                                            id="review_auth_location"
                                                                                        >
                                                                                            ${renderPharmacyTable()}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr class="contentContainerBottomBorder">
                                                    <td><span class="leftCorner"></span></td>
                                                    <td><span class="middleEdge"></span></td>
                                                    <td><span class="rightCorner"></span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table></table>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="mainContentContainerBottomBorder">
                    <td><span class="leftCorner"> ${window.location.href} </span></td>
                    <td><span class="middleEdge"></span></td>
                    <td><span class="rightCorner"></span></td>
                </tr>
            </tbody>
        </table>
    </body>
</html>
`};
