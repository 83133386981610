import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classes from './headerHelp.module.scss';
import { helpContent } from '../../constants/Config/helpKeyConfig';
import { helpContentEs } from 'constants/Config/helpKeyConfigEs';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import DashboardHelpPagesContent from 'components/DashboardHelpPagesContent';

import {ProgramConfig, ProgramConfigIMG,replacePrograms} from 'constants/Config/programsConfig';

const HeaderHelp = () => {
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const role = searchParams.get('role') || '';
    const helpRef = searchParams.get('helpRef');
    const [helpKey, setHelpKey] = useState(''); //key for the help page
    const id = searchParams.get('id') || '';
    const isSubmit = searchParams.get('part') || '';
    const isAccount = searchParams.get('account') || '';
    const isLanguage = searchParams.get('language') || 'en';

    const [isDashboardHelpPage, setIsDashboardHelpPage] = useState(false);

    useEffect(() => {
        const prefix = 'dashboard';
        helpKey && helpKey.startsWith(prefix) ? setIsDashboardHelpPage(true) : setIsDashboardHelpPage(false);
    }, [helpKey]);

    var documentTitle = document.title;
    useEffect(() => {
        document.title = role === 'pharmacist' ? 'REMS Pharmacy Portal' : 'REMS Prescriber Portal';
        return () => {
            document.title = documentTitle;
        };
    }, [role]);

    useEffect(() => {
        if (helpRef === 'manageMyAccount') {
            setHelpKey(role + helpRef);
        } else {
            setHelpKey(helpRef || '');
        }
    }, [role, helpRef]);

    useEffect(() => {}, [i18n.language]);
    //const helpPagelanguage = i18n.language === 'en' ? helpContent : helpContentEs
    const helpPagelanguage = isLanguage === 'en' ? helpContent : helpContentEs;
    const [help] = useState(
        helpKey === 'productSignUp'
            ? helpPagelanguage[isSubmit]
            : isAccount === 'createaccountconfirmation'
            ? helpPagelanguage[isAccount]
            : helpPagelanguage[helpKey]
    );

    const checkContent = (title) => {
        if (title === 'New Prescriber Enrollment page' && role === 'nonPrescriber') {
            return 'New Non-prescriber Enrollment page';
        }
        return title;
    };

    //const replacePrograms=replacePrograms(str)
    return (
        <div className={classes.headerHelp}>
            <div className={classes.layout}>
                <table
                    width="815px"
                    cellPadding="10"
                    cellSpacing="0"
                    border={0}
                    align="center"
                    style={{ padding: `${isDashboardHelpPage ? '10px' : ''}` }}
                >
                    <tbody>
                        <tr>
                            <td>
                                <div
                                    style={{
                                        backgroundColor: '#FFFFFF',
                                        border: '2px solid #7690AB',
                                        padding: '10px 15px',
                                    }}
                                >
                                    <div className={classes.help}>
                                        <div style={{ color: '#193878', paddingBottom: '8px' }}>
                                            {selectedProductData.id.length > 0 ? (
                                                <>
                                                    <div
                                                     className={` ${classes.headerImgCls}`}
                                                     style={{
                                                        backgroundImage:`url(${require(`../../assets/images/${ProgramConfig(selectedProductData.id).headerImage}`)})`
                                                     }}
                                                     ></div>
                                                    <div className={classes.title}>
                                                        REMS {isLanguage !== 'en' ? 'Ayuda' : 'Help'}
                                                    </div>
                                                    <div
                                                        className={`  ${classes.headerDividerRems}` }
                                                        style={{
                                                            borderBottomColor:`${ProgramConfig(selectedProductData.id)?.color}`
                                                        }}
                                                    ></div>
                                                </>
                                            ) : (
                                                <>
                                                    <span
                                                        className={`${classes.icons} ${
                                                            helpPagelanguage[helpKey]?.header.content === 'product' &&
                                                            id
                                                                ? ''
                                                                : classes.logo
                                                        }`}
                                                        style={{
                                                            backgroundImage:
                                                            helpPagelanguage[helpKey]?.header.content === 'product' &&
                                                            id ?
                                                            `url(${require(`../../assets/images/${ProgramConfig(selectedProductData.id).headerImage}`)})`
                                                            : ''
                                                        }}
                                                    ></span>
                                                    <div className={classes.title}>
                                                        REMS {isLanguage !== 'en' ? 'Ayuda' : 'Help'}
                                                    </div>
                                                    <div
                                                        className={`${classes.buffer} `}
                                                        style={{
                                                            paddingLeft: '2px',
                                                            borderBottom:
                                                                helpPagelanguage[helpKey]?.header.content ===
                                                                    'product' && id
                                                                    ? `2px solid ${ProgramConfig(selectedProductData.id)?.color}`
                                                                    : '',
                                                        }}
                                                    ></div>
                                                </>
                                            )}
                                        </div>
                                        <div style={{ padding: '10px' }}>
                                            {isDashboardHelpPage && <DashboardHelpPagesContent tabType={helpKey} />}
                                            <span style={{ fontWeight: 'bold' }}>
                                                {helpPagelanguage[helpKey]?.body?.content.subTitle?.[role]
                                                    ? helpPagelanguage[helpKey]?.body?.content.subTitle?.[role]
                                                    : checkContent(helpPagelanguage[helpKey]?.body?.content.subTitle)}
                                            </span>
                                            
                                         
                                            {helpPagelanguage[helpKey]?.body?.content.additionalLine && (
                                                <>
                                                <br />
                                                <br />
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                    __html: helpPagelanguage[helpKey]?.body?.content.additionalLine?.[role]
                                                        ? helpPagelanguage[helpKey]?.body?.content.additionalLine?.[role]
                                                        : checkContent(helpPagelanguage[helpKey]?.body?.content.additionalLine),
                                                    }}
                                                />
                                                </>
                                            )}

                                            {helpPagelanguage[helpKey]?.body?.content?.customView ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: helpPagelanguage[helpKey]?.body?.content?.customView,
                                                    }}
                                                ></div>
                                            ) : (
                                                <ul>
                                                    {helpPagelanguage[helpKey]?.body?.content.lists.map((i, index) => (
                                                        <li key={index} dangerouslySetInnerHTML={{ __html: replacePrograms(i) }}></li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div
                                            style={{ color: '#476392', textAlign: 'center', padding: '10px' }}
                                            dangerouslySetInnerHTML={{
                                                __html: helpPagelanguage[helpKey]?.footer.content.replace('2024',new Date().getFullYear()),
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HeaderHelp;
