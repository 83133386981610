import React from 'react';
import classes from './logDispense.module.scss';
import Input from 'components/Input';
import { Table } from 'antd';

const ExpandedRowRenderer = (record) => {

    const expandValues = [
        {
            id: 1,
            label: 'Risk Category',
            value: record.riskCategory,
        },
        {
            id: 2,
            label: `Patient's Date of Birth`,
            value: record.patientDOB,
        },
        {
            id: 3,
            label: `Authorization Expiration Date`,
            value: record.authExpiryDate,
        },
    ];

    const columns = [
        {
            title: 'Product Identification',
            dataIndex: 'ProductIdentification',
            key: 'ProductIdentification',
            width: '80%',
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            width: '20%',
        },
    ];

    const data = record.Dispense.map((item, index) => {
        return {key:index, ProductIdentification: item.product_id, Quantity: item.quantity };
    });

    return (
        <div className={classes.expandedRow}>
            <div className={classes.expandedRow_inputs}>
                {expandValues.map((item) => (
                    <div className={classes.expandedRow_inputs_item}>
                        <div className={classes.expandedRow_inputs_label}>{item.label}</div>
                        <span className={classes.expandedRow_inputs_colon}>:</span>
                        <div className={classes.expandedRow_inputs_value}>{item.value}</div>
                    </div>
                ))}
            </div>
            <div className={classes.expandedRow_table}>
                <div className={classes.expandedRow_dispenseDetails}>Dispense Details</div>
                <Table
                    className="dispense_details"
                    size="small"
                    columns={columns}
                    dataSource={data}
                    rowClassName={(record, index) => (index % 2 === 0 ? `table-row-dark` : `table-row-light`)}
                    pagination={false}
                />
            </div>
        </div>
    );
};

export default ExpandedRowRenderer;
