export const getStringBetween = (str, start, end) => {
    const startIndex = str.indexOf(start) + start.length;
    const endIndex = str.indexOf(end, startIndex);
    if (startIndex === -1 || endIndex === -1) {
        return '';
    }
    return str.substring(startIndex, endIndex);
};
export const getURL =(url,hostIdentifier,envVal)=>{
    let envVar=envVal;
    if(envVar=== "")
    {
        hostIdentifier.forEach((i)=>{
            if(url.includes(i))
            {
                envVar = i;
            }
        })
    }
    return envVar;
}
