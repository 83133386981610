import Input from 'components/Input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { changeHelpContent, deletePrescriptionAuthDetails, deletePrescriptionDosage, deletePrescriptionFormDetailsData, setPatientData, setPatientRisk, setPrescriptionFormData } from 'store/slices/appSlice';
import { PomalystTextToPomalidomide } from 'utils/constant';
import {
    axiosInstance_ORC,
    axiosInstance_PDF,
    axiosInstance_PatientViewPPAF,
} from './../../constants/Config/axiosConfig';
import { getPatientReqBody } from './../../utils/patientSave';
import classes from './PrescriberPatientSearch.module.scss';
import Alert from 'components/Alert';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';

const PrescriberPatientSearch = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const record = sessionStorage.getItem('patientInfo') !== null ? JSON.parse(sessionStorage.getItem('patientInfo') || '') : {}
    const navigate = useNavigate();
    const [existingRC, setExistingRC] = useState(null);
    const [isPending, setIspending] = useState(false);
    const [base64Data, setBase64Data] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [pdfData, setPdfData] = useState([]);

    const { patientSearchStore, patientPrescriberStore,prescriptionFormData } = useAppSelector((state) => state.app);

    const getOldRisk = (riskArr) => {
        return riskArr.reduce((max, current) =>
            new Date(moment(current.effectiveDate).format()) > new Date(moment(max.effectiveDate).format()) ? current : max
        );
    };

    const { patientInformation, accFormDetails, selectedProgram, prescriberUniqueID, prescriberDetails } =
        useAppSelector((state) => state.app);

    const { currentUser } = useAppSelector((state) => state.login);

    const selectedLanguage = i18n.language;

    const Prescriber =
        `${accFormDetails?.firstName} ${accFormDetails?.lastName}` ||
        `${currentUser?.firstName} ${currentUser?.lastName}`;

    const product = selectedProgram.id;
    const mi = `${patientInformation?.mi}`;
    const patientPin = `${patientSearchStore?.patientIdNum}`;
    const patientenrollstatus = `${patientPrescriberStore?.data?.patientRegistrationForms?.filter((form) => form.productCountry.productCountryId === selectedProgram.productCountryId)?.[0]?.patientRegStatus[0]?.registrationStatus?.lookupValue}`;
    const dob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`;
    const Patientdob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`.replace(
        '00:00:00',
        ''
    );

    const convertedDate = moment(dob, 'MMM DD,YYYY').format('MM/DD/YYYY');

    const productType = ProgramConfig(product).label; 
       

    useEffect(() => {
        return () => {
            dispatch(changeHelpContent('patientVerification'));
        };
    }, [dispatch]);

    useEffect(() => {
        if (!patientInformation.oldRisk || patientInformation?.oldRisk?.length === 0) {
            setExistingRC(null);
            return;
        } else if (patientInformation?.oldRisk?.length === 1) {
            setExistingRC(patientInformation.oldRisk[0].riskCategoryName);
            dispatch(setPatientRisk(patientInformation.oldRisk[0].riskCategoryName));
        } else {
            const existingRisk = getOldRisk(patientInformation.oldRisk);
            setExistingRC(existingRisk?.riskCategoryName);
            dispatch(setPatientRisk(existingRisk?.riskCategoryName));
        }
    }, []);

    const handleRevise = () => {
        setErrorMessage('')
        const prevRoute = sessionStorage.getItem('prevRoute')||'/prescriber/patientsearch'
        navigate(prevRoute);
    };
    let patientId = patientPrescriberStore?.data?.patientId;
    let remsProgram = selectedProgram.label;
    let registrationStatus =
        patientPrescriberStore?.data?.patientRegistrationForms[0]?.patientRegStatus[0]?.registrationStatus?.lookupValue;
    const viewPPAF = async () => {
     
        const payload = {
            patientId: patientId,
            productName: remsProgram,
            regStatus: registrationStatus,
            responseType: Blob,
        };

        let response = await axiosInstance_PatientViewPPAF
            .post('/find/files/by-patient-and-product', payload)
            .then((res) => {
                if (res?.data?.statusCode === 200) {
                    let pdfFile = res?.data?.data[0]?.fileName;

                    const findFiles = async () => {
                        let resp = await axiosInstance_PDF
                            .get(`/find/files/find-by-file-Key/${pdfFile}`)
                            .then(async (res) => {
                                let pdf: any = res?.data;
                                const binaryArr = atob(pdf);
                                const arrayBuff = new Uint8Array(binaryArr.length);
                                for (let i = 0; i < binaryArr.length; i++) {
                                    arrayBuff[i] = binaryArr.charCodeAt(i);
                                }
                                const blob = new Blob([arrayBuff], { type: 'application/pdf' });
                                const pdfUrl = URL.createObjectURL(blob);

                                const newWindow: any = window.open();
                                newWindow.location.href = pdfUrl;
                                if (newWindow) {
                                    newWindow.document.title = 'productRegistration';
                                    newWindow.document.write(`
                <html>
                <head><title>productRegistration</title></head>
                <body>
                <embed width='100%' height='100%' name='pdf' src="${pdfUrl}" type="application/pdf">
                </body>
                </html>
                `);

                                    newWindow.document.addEventListener('DOMContentLoaded', () => {
                                        URL.revokeObjectURL(pdfUrl);
                                    });
                                }
                            })
                            .catch((error) => {
                                console.error('Unable to fetch pdf files', error);
                            });
                    };
                    findFiles();
                }
            })
            .catch((error) => {
                console.error('45', error?.message);
            });
    };

    const SavePatient = async (data) => {
        try {
            const response = await axiosInstance_ORC.post(`/patient/register`, data);
            if (response.data.statusCode === 200) {
                dispatch(setPatientData(response.data.data));
                navigate('/prescriber/ppafSelection', { replace: true });
                setIspending(false);
            } else {
                setIspending(false);
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`error ${err}`);
            setIspending(false);
            navigate('/prescriber/SystemError');
        }
    };

    const handleSave = () => {
        sessionStorage.removeItem('prevRoute')
        sessionStorage.removeItem('patientInfo')
        if (patientInformation.flowType === 'EditPatient') {
            navigate('/prescriber/patientInformation');
        } else {
            setIspending(true);
            const reqObj = getPatientReqBody({
                ...patientInformation,
                dob: convertedDate,
                prescriberExtUniqueId: currentUser.systemId || prescriberDetails.prescriberSystemId,
                productType,
                menstrating: patientInformation.menstrating === 'No' ? 'N' : 'Y',
                surgical: patientInformation.surgical === 'No' ? 'N' : 'Y',
                natural: patientInformation.natural === 'No' ? 'N' : 'Y',
                productCountryId: selectedProgram?.productCountryId,
                locale: i18n.language,
                action: 'Save',
            });

            SavePatient(reqObj);
        }
    };
    const getProgramsName = (): string => {
       
        return mainMenu(selectedProgram.id);
    };
    const authStatuslang = i18n.language === 'es' ? 1 : 0;
    const routeToPrescriptionForm = () => {
        sessionStorage.removeItem('prevRoute')
        sessionStorage.removeItem('patientInfo')
        dispatch(deletePrescriptionFormDetailsData({}));
        dispatch(deletePrescriptionDosage({}));
        dispatch(deletePrescriptionAuthDetails({}));
        setErrorMessage('')
        const pgmStatus = patientPrescriberStore?.data?.patientRegistrationForms.find(
            (o) => o.productCountry.productCountryId === selectedProgram.productCountryId
        ).patientRegStatus[0]?.registrationStatus?.lookupValue;
        if (pgmStatus !== 'Active') {
            setErrorMessage(t('error_PD0405').replaceAll('{program}', getProgramsName()));
        }else{
            dispatch(setPrescriptionFormData({ ...prescriptionFormData, patientDto:patientPrescriberStore?.data }));
            dispatch(deletePrescriptionFormDetailsData({}))
            dispatch(deletePrescriptionDosage({}));
            dispatch(deletePrescriptionAuthDetails({}));
            sessionStorage.removeItem('patientInfo')
            navigate('/prescriber/prescriptionformdetails');
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
            {/* <div className={classes.header}></div> */}
            <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
            <div className={classes.contentDivider}></div>
            <div className={classes.mainContainer}>
            {errorMessage && (
                        <Alert styles={{ margin: '0 20px', fontSize: '10px' }} content={errorMessage} />
                    )}
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="firstName"
                    label={t('firstName')}
                    valueExist={patientPrescriberStore?.data?.patientNameFirst}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="lastName"
                    label={t('lastName')}
                    valueExist={patientPrescriberStore?.data?.patientNameLast}
                />
                <Input
                    type="text"
                    name="mi"
                    label={t('mi')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientPrescriberStore?.data?.patientMiddleInitial}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="DOB"
                    label={t('DOB')}
                    isRequired={true}
                    valueExist={Patientdob}
                />
                <Input
                    type="text"
                    name="patientIdNum"
                    // maxLength={9}
                    label={t('patientPin')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientPin || ''}
                />

                <Input
                    type="text"
                    name="patientEnrollmentStatus"
                    label={t('patientenrollstatus')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={patientenrollstatus || ''}
                />

                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationStatus"
                    label={t('authorizationStatus')}
                    valueExist={
                        patientPrescriberStore?.data?.surveyData
                            ? patientPrescriberStore?.data?.surveyData[0]?.authorizationStatus?.split('||')[
                                  authStatuslang
                              ]
                            : null
                    }
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationNumber"
                    label={t('authorizationNumber')}
                    valueExist={
                        patientPrescriberStore?.data?.surveyData
                            ? patientPrescriberStore?.data?.surveyData[0]?.authorizationNumber?.substring(2)
                            : ''
                    }
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="dateLastPrescriberSurveyCompleted"
                    label={t('dateLastPrescriberSurveyCompleted')}
                    valueExist={
                        patientPrescriberStore?.data?.surveyData
                            ? moment(patientPrescriberStore?.data?.surveyData[0]?.prescriberSurveyCompletedDate).format(
                                  'MMMM DD, YYYY'
                              ) !== 'Invalid date'
                                ? moment(
                                      patientPrescriberStore?.data?.surveyData[0]?.prescriberSurveyCompletedDate
                                  ).format('MMMM DD, YYYY')
                                : ''
                            : ''
                    }
                />
                {patientInformation.newRisk && existingRC && existingRC !== patientInformation.newRisk && (
                    <p className={classes.risk_warning}>
                        {t('riskChangeLine1')} <strong>{existingRC}.</strong>
                        <br /> {t('riskChangeLine2')} <strong>{patientInformation?.newRisk}.</strong>
                        <br />
                        {t('riskChangeLine3')}{' '}
                    </p>
                )}
                <div className={classes.btn_group}>
                    {(record?.buttonName === 'ViewSubmit' && 'EditPatient') ||
                    (record === '/prescriber/submittedPPAFSearch' && '/prescriber/patientAddressPPAFSearch') ? (
                        <Button
                        clickFunction={viewPPAF}
                        className={getBtnClass(selectedProgram.id)}
                        width={i18n.language === 'es' ? 140 : 186}
                        height={48}
                        size={17}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.vwPpaf : buttonContent.en.vwPpaf}  
                    ></Button>
                    ) : prescriptionFormData.formType ? (
                        <Button
                        clickFunction={routeToPrescriptionForm}
                        className={getBtnClass(selectedProgram.id)}
                        width={188}
                        height={48}
                        size={17}
                        type="text"
                        buttonname={buttonContent.en.writePres}  
                    ></Button>
                    ) : (
                        <Button
                            clickFunction={handleSave}
                            className={getBtnClass(selectedProgram.id)}
                            width={106}
                            height={48}
                            size={17}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}  
                        ></Button>
                    )}
                       
                    <Button
                        clickFunction={handleRevise}
                        className={getBtnClass(selectedProgram.id)}
                        width={256}
                        height={48}
                        size={17}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.searchNewPat : buttonContent.en.searchNewPat}  
                    ></Button>

                </div>
            </div>
        </div>
    );
};

export default PrescriberPatientSearch;
