export const productEnrolmentFields = {
    personalInfo: [
        {
            name: 'degree',
            label: 'Degree',
            type: 'select',
            isRequired: true,
            errMsg: 'Please enter a Medical Degree.',
        },
        {
            name: 'specialty',
            label: 'Specialty',
            type: 'select',
            isRequired: true,
            errMsg: 'Please select a Medical Specialty.',
        },
        {
            name: 'pi_number',
            label: 'Prescriber Identification Number',
            type: 'text',
            isRequired: true,
            maxLength: 10,
            errMsg: 'Please enter a valid Prescriber Identification Number.',
        },
    ],
    primaryOfc: [
        {
            name: 'officeName',
            maxLength: 20,
            label: 'Office Name',
            type: 'text',
            isRequired: true,
            helpText: '',
            errMsg: 'Please enter the Office Name for the Primary Address.',
        },
        {
            name: 'attention',
            maxLength: 20,
            label: 'Attention',
            type: 'text',
            isRequired: true,
            helpText: '',
            errMsg: 'Please enter the Attention information for the Primary Address.',
        },
        {
            name: 'officeAddress',
            label: 'Office Street Address',
            type: 'text',
            isRequired: true,
            helpText: '',
            errMsg: 'Please enter the Street Address for the Primary Address.',
        },
        {
            name: 'city',
            label: 'City',
            type: 'text',
            isRequired: true,
            helpText: '',
            errMsg: 'Please enter a City for the Primary Address.',
        },
        {
            name: 'state',
            label: 'State',
            type: 'select',
            isRequired: true,
            helpText: '',
            errMsg: 'Please select a State for the Primary Address.',
        },
        {
            name: 'zip',
            label: 'Zip',
            type: 'text',
            isRequired: true,
            helpText: 'e.g., xxxxx',
            maxLength: 5,
            errMsg: 'Please enter a Zip Code for the Primary Address.',
        },
        {
            name: 'phone',
            label: 'Phone Number',
            maxLength: 12,
            type: 'text',
            isRequired: true,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            errMsg: 'Please enter a valid Phone Number for the Primary Address.',
        },
        {
            name: 'fax',
            maxLength: 12,
            label: 'Fax',
            type: 'text',
            isRequired: true,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            errMsg: 'Please enter a valid Fax Number for the Primary Address.',
        },
        {
            name: 'email',
            maxLength: 60,
            label: 'Email Address',
            type: 'email',
            isRequired: true,
            helpText: 'yourname@abccompany.com',
            errMsg: 'Please enter an email address for the Primary Address.',
        },
    ],
};
