import PageBackground from 'components/PageBackground';
import classes from './prescriberDashboard.module.scss';
import { Tabs } from 'antd';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';
import searchBtn from '../../assets/images/btn_search_blue.png';
import SessionTimeout from 'components/SessionTimeout';
import { useEffect, useState } from 'react';
import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import {
    clearSelectedPatient,
    searchAuthorization,
    setPrescriberCalendarActionItemPayload,
    setPrescriptionPaylod,
    storeActionType,
    storeCalenderActionitemInfo,
} from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store';
import PrescriberDashboardReviewAuth from 'pages/PrescriberDashboardReviewAuth';
import { getReviewAuthPrintPopUpTemplate } from '../PrescriberDashboardReviewAuth/getReviewAuthPrintPopUpTemplate';
import PrescriberDashboardFindPatient from 'pages/PrescriberDashboardFindPatient';
import { useNavigate, useLocation } from 'react-router-dom';
import PrescriptionActionItems from 'pages/PrescriberDashboardActionItems/PrescriptionActionItems';
import PrescriberDashboardCalender from 'pages/PrescriberDashboardCalender';
import PrescriberDashboardReports from 'pages/PrescriberDashboardReports/PrescriberDashboardReports';
import PrescriberDashboardReportsHome from 'pages/PrescriberDashboardReportsHome';
import { axiosInstanceSearch } from 'constants/Config/axiosConfig';
import {reloadFunction} from '../../utils/utilsCommon';

const PrescriberDashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const noInputStyle = { color: '#000' };
    const { t, i18n } = useTranslation();
    const [authNumber, setAuthNumber] = useState('');
    const [errorForm, setErrorForm] = useState('');
    const [error, setError] = useState(false);
    const dispatch = useAppDispatch();
    const { currentUser } = useAppSelector((state) => state.login);

    const [isAuthData, setIsAuthData] = useState(false);
    const [isFindPatientTab, setIsFindPatientTab] = useState(false);
    const [tabNumb, setTabNumb] = useState(1);
    const [showPrintBtnCalender, setShowPrintBtnCalender] = useState<any>(false);

    const { authorizationData } = useAppSelector((state) => state.app);
    const { pharmacyLocation, prescriptionPaylod = {}, patientDataFromCalender = [], calendarActionItemPayload = {}, actionitemInfo = {} } = useAppSelector((state) => state.app);

    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails.prescriberId);

    useEffect(() => {
        const defaultHash = `prescription-action-items`;
        replaceUrlHash(defaultHash);
        dispatch(storeActionType({}));
    }, []);

    useEffect(() => {
        const calenderActionAPI = async () => {
            try {
                const resp = await axiosInstanceSearch.post(`/prescription/get-calendar-action-item`, calendarActionItemPayload?.payload);
                if (resp?.data?.statusCode === 200) {
                    dispatch(storeCalenderActionitemInfo(resp?.data?.data));
                    setTabNumb(1);
                }
            } catch (err) {
                console.error(err);
            }
        };
        if (calendarActionItemPayload?.isFromCalender) calenderActionAPI();
        if (tabNumb !== 3) setShowPrintBtnCalender(false);
        
    }, [calendarActionItemPayload]);
    useEffect(() => {
        reloadFunction();
    }, []);

    let tabNames = [
        'Prescription Action Items',
        'Find A Patient',
        'Prescriber Calendar',
        'Review Authorization',
        'Reports',
    ];

    const focusField = (name) => {
        const inputElement = document?.getElementById(name) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
    };

    const handleErrorBtn = () => {
        const focusName = 'Authrization_number';
        focusField(focusName);
        setErrorForm('');
    };

    const appendTabTypeToUrl = (tabNumber) => {
        const targetHash = tabNames[tabNumber - 1].toLowerCase().replace(/ /g, '-') || 'Reports';
        replaceUrlHash(targetHash);
    };

    const replaceUrlHash = (hashToBeReplaced) => {
        if (hashToBeReplaced === 'prescription-action-items') {
            if (!patientDataFromCalender?.length) {
                const payloadObj = Object.entries(prescriptionPaylod)?.length > 0 && Number(tabNumb) === 1 ? prescriptionPaylod : {
                    prescriberNonPrescriberId: !nonPrescriber ? prescriberId : currentUser.nonPrescriberId,
                    productId: null,
                    authorizationNumber: null,
                    authorizationStatus: null,
                    assocPrescriberId: null,
                    assocPrescriberName: null,
                    authorizationStatusIds: null,
                    sortByField: 'authorizationnumber',
                    sortByOrder: 'DESC',
                    prescriber: !nonPrescriber,
                    page: 0
                };
                dispatch(setPrescriptionPaylod(payloadObj));
            }
        }
        navigate(`/prescriber/dashboard/${hashToBeReplaced}`);
    };

    const handleTabChange = (tabNumber) => {
        setAuthNumber('');
        setIsAuthData(false);
        setError(false);
        appendTabTypeToUrl(tabNumber);
        dispatch(clearSelectedPatient());
        dispatch(storeActionType({}));
        tabNumber !== 1 && dispatch(setPrescriberCalendarActionItemPayload({}));
        dispatch(storeCalenderActionitemInfo([]));
        tabNumber == 2 ? setIsFindPatientTab(true) : setIsFindPatientTab(false);
        setTabNumb(tabNumber);
    };
    const handleTabClick = (tabNumber) => {
        if(tabNumber == 5){
            navigate('/prescriber/dashboard/reports')
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setIsAuthData(false);
        const onlyNumbersRegx = /^[0-9]+$/;
        setError(false);
        const prescriberNonPrescriberIds = !nonPrescriber
            ? { prescriberId: prescriberId }
            : { nonPrescriberid: currentUser.nonPrescriberId };
        let payload = {
            authorizationNumber: authNumber,
            isPrescriber: !nonPrescriber,
            ...prescriberNonPrescriberIds,
        };
        if (authNumber === '') {
            setErrorForm('Please enter a valid Authorization Number.');
        } else if (!onlyNumbersRegx.test(authNumber)) {
            setError(true);
            setAuthNumber('');
        } else {
            dispatch(searchAuthorization(payload)).then((e: any) => {
                setAuthNumber('');
                if (e.payload?.data?.statusCode !== 200) {
                    setError(true);
                    setIsAuthData(false);
                }
                if (e.payload?.data?.statusCode === 200) {
                    if(e.payload?.data?.data?.length === 0){
                        setError(true);
                        setIsAuthData(false);
                    }else{
                        setIsAuthData(true);
                    }
                }
            });
        }
    };

    const handleOnChange = (e) => {
        const { value } = e.target;
        setAuthNumber(value);
    };

    const printThisPageHandler = () => {
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML = getReviewAuthPrintPopUpTemplate(authorizationData, pharmacyLocation);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };

    const reviewAuthorization = () => {
        return (
            <>
                {error && (
                    <Alert content={'No Records Found.'} styles={{ fontSize: '11px', margin: '10px 20px 5px 20px' }} />
                )}

                <div className={classes.authLabel}>
                    Please enter a valid Authorization Number and select the Search button.
                    {isAuthData && (
                        <span className="printThisPageAnchor" onClick={printThisPageHandler}>
                            Print this page <b>&#x1F892;</b>{' '}
                        </span>
                    )}
                </div>
                <form onSubmit={handleSearch}>
                    <div className={classes.contentAuthContainer}>
                        <Input
                            type="text"
                            name="Authrization_number"
                            maxLength={10}
                            label={t('Authrization_number')}
                            width="65%"
                            onChangeHandler={handleOnChange}
                            value={authNumber}
                            authPage={true}
                        />
                    </div>
                    <div className={classes.buttonWrapper}>
                        <div className="searchButton">
                            <img
                                src={searchBtn}
                                style={{ cursor: 'pointer' }}
                                alt="search-button-for-auth-data"
                                onClick={handleSearch}
                                itemType="submit"
                            />
                        </div>
                    </div>
                </form>
            </>
        );
    };
    const tabItems = tabNames.map((item, i) => {
        const id = String(i + 1);
        return {
            label: item,
            key: id,
            children:
                item === 'Prescription Action Items' ? (
                    <div className={classes.contentContainer}>
                        <PrescriptionActionItems tabNumb={tabNumb} />
                    </div>
                ) : item === 'Find A Patient' ? (
                    <div className={classes.contentContainer}>
                        <PrescriberDashboardFindPatient isFindPatientTab={isFindPatientTab} />
                    </div>
                ) : item === 'Prescriber Calendar' ? (
                    <div className={`${classes.contentContainer} ${classes.prescriberCalender}`}>
                        <PrescriberDashboardCalender 
                            tabNumb={tabNumb} 
                            setShowPrintBtnCalender={setShowPrintBtnCalender} 
                            showPrintBtnCalender={showPrintBtnCalender}
                        />
                    </div>
                ) : item === 'Review Authorization' ? (
                    <div className={classes.contentContainer}>
                        {reviewAuthorization()}
                        {isAuthData && <PrescriberDashboardReviewAuth />}
                    </div>
                ) : item === 'Reports' ? (
                    <div className={classes.contentContainer}>
                        {location.pathname.includes('/expireReport') ? <PrescriberDashboardReports reportType="1"/> : location.pathname.includes('/historicalReport') ? <PrescriberDashboardReports reportType="2"/> : location.pathname.includes('/hiddenPatientReport') ? <PrescriberDashboardReports reportType="3"/>:<PrescriberDashboardReportsHome/>}
                    </div>
                ) : (
                    <></>
                ),
        };
    });

    const switchHeading = () => {
        if(location.pathname.includes('/expireReport')){
            return 'Authorization Expiration Report'
        } else if (location.pathname.includes('/historicalReport')){
            return 'Historical Authorization Report'
        } else if (location.pathname.includes('/hiddenPatientReport')){
            return 'Hidden Patient Report'
        }else {
            return "Prescriber Dashboard"
        }
    }

    return (
        <div style={{ marginTop: '10px' }} className={classes.dashboard}>
            <SessionTimeout />
            <PageBackground label={switchHeading()}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabItems}
                    onChange={handleTabChange}
                    onTabClick={handleTabClick}
                    className={classes.tabsStyling}
                    activeKey={String(tabNumb)}
                />
            </PageBackground>
            {errorForm && <ErrorModal error={errorForm} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default PrescriberDashboard;
