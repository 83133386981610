import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { axiosInstance, axiosInstance_2 } from 'constants/Config/axiosConfig';
import classes from './PrescriptionFormLang.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import Input from '../../components/Input';
import inputStyles from '../../components/Input/Input.module.scss';
import ErrorModal from 'components/ErrorModal';
import { setPrescriptionFormData } from 'store/slices/appSlice';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';
interface dropDownType {
    'ICD-10': { id: string; value: string }[] | null;
}

const PrescriptionFormLang = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const { selectedProgram, prescriptionFormData, prescriptionFormDetailsData } = useSelector((state: RootState) => state.app);
    const [isICD9, setIsICD9] = useState(false);
    const [values, setValues] = useState({
        lang: `${t('selectLang')}`,
        other: '',
        otherPatientDiag: '',
        callTime: '',
        time: 'AM',
        diagCode: `${t('selectDiag')}`,
        ICDcodePre: '',
        riskC: '',
    });
    const noInputStyle = { color: '#000' };
    const getLanguages = ['English', 'Spanish', 'Other'];
    const getTime = ['AM', 'PM'];
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [dropdown, setDropdown] = useState<dropDownType>({ 'ICD-10': [] });
    const [IsCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [updatedPatientDTO, setUpdatedPatientDTO] = useState({});
    const [patientCond, setPatientCond] = useState({});

    const { langDetails: detailFromStore = {}, isAckn = false, patientDto: patientDTO = {}, updatedPatientDTO: upDTO = {} } = prescriptionFormData || {};
    const { payloadData = {} } = prescriptionFormDetailsData || {};
    const patientRisk = patientDTO?.patientRiskCategory?.filter(
        (item) => item?.riskCategoryId?.productCountryId === selectedProgram.productCountryId
            && item.endDate === null
    );

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const fetchDropdown = async (patientCondRecord) => {
        try {
            const response = await axiosInstance.post(`/referencedata`, { keys: ['ICD-10', 'ICD-9'] });
            setDropdown(response.data.data);
            setIsICD9((response.data.data?.['ICD-9']?.filter((i) => i.id !== '000' && i.id === patientCondRecord?.[0]?.diagnosisCode))?.length === 0 ? false : true);
        } catch (err) {
            console.error(`error fetching dropdown ${err}`);
        }
    };

    useEffect(() => {

        const patientDtoCon = patientDTO?.patientConditions?.filter(
            (item) => item?.patientConditionId?.productCountryId === selectedProgram.productCountryId
                && item.patientConditionEndDate === null
        );

        // prepopulate = After coming back to page by clicking back button
        if (Object.entries(detailFromStore)?.length > 0) {
            setValues((prev) => ({
                ...prev,
                lang: detailFromStore?.lang || '',
                other: detailFromStore?.other || '',
                otherPatientDiag: detailFromStore?.otherPatientDiag || '',
                diagCode: detailFromStore?.diagCode || '',
                ICDcodePre: detailFromStore?.diagCode || '',
                riskC: detailFromStore?.riskC || '',
                callTime: detailFromStore?.callTime || '',
                time: detailFromStore?.time || '',
            }));
        }
        // prepopulate = Coming to page for first time
        else {
            // After saveApi Call
            if (Object.entries(payloadData)?.length > 0) {
                
                setValues((prev) => ({
                    ...prev,
                    lang: !(['English', 'Spanish']).includes(payloadData?.languagePreference) ? 'Other' : payloadData?.languagePreference,
                    other: payloadData?.languagePreference || '',
                    otherPatientDiag: payloadData?.otherDiagnosisText || '',
                    callTime: payloadData?.bestCallTime || '',
                    time: payloadData?.bestCallTimeAmpm || 'AM',
                    diagCode: patientDtoCon?.[0]?.diagnosisCode === '000' ? (patientDtoCon?.[0]?.diagnosisCode + '-' + payloadData?.otherDiagnosisCode) : (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisText),
                    ICDcodePre: patientDtoCon?.[0]?.diagnosisCode === '000' ? (patientDtoCon?.[0]?.diagnosisCode + '-' + payloadData?.otherDiagnosisCode) : (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisText),
                    riskC: patientRisk?.[0]?.riskCategoryName || '',
                }));
            }
            // Before saveApi 
            else if (Object.entries(patientDTO)?.length > 0) {

                setValues((prev) => ({
                    ...prev,
                    lang: !['English', 'Spanish'].includes(patientDTO?.language?.languageName) ? 'English' : patientDTO?.language?.languageName,
                    other: patientDTO?.languageOther || '',
                    otherPatientDiag: patientDtoCon?.[0]?.otherDiagnosisText || '',
                    diagCode: patientDtoCon?.[0]?.diagnosisCode === '000' ? (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisCode) : (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisText),
                    ICDcodePre: patientDtoCon?.[0]?.diagnosisCode === '000' ? (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisCode) : (patientDtoCon?.[0]?.diagnosisCode + '-' + patientDtoCon?.[0]?.otherDiagnosisText),
                    riskC: patientRisk?.[0]?.riskCategoryName || '',
                }));
            }
        }

        setPatientCond(patientDtoCon);
        fetchDropdown(patientDtoCon);
        setShowCheckbox(false);

    }, []);

    useEffect(() => {
        setIsCheckBoxChecked(false);
    }, [values.diagCode])
    useEffect(() => {
        reloadFunction();
    }, []);

    const handleChange = (e) => {
        setError('');
        const { name, value } = e.target;
        setShowCheckbox((prev) => name === 'diagCode' ? true : prev);
        setValues((prev) => ({
            ...prev,
            [name]: value,
            other: name === 'lang' ? '' : name === 'other' ? value : prev.other,
            otherPatientDiag: name === 'diagCode' ? '' : name === 'otherPatientDiag' ? value : prev.otherPatientDiag,
        }));
    };

    const fieldValidation = () => {
        const trimVals = { ...values };
        Object.keys(trimVals).forEach((field) => {
            trimVals[field] = trimVals[field].trim();
        });
        setValues(trimVals);

        //validations
        if (trimVals.lang === `${t('selectLang')}`) {
            setError('Please select a preferred language.');
            scrollToView();
            return false;
        }
        else if (trimVals.lang === 'Other' && !trimVals.other) {
            setCurrentFocus('other');
            setError('Please enter another language.');
            scrollToView();
            return false;
        }
        else if (values.lang === 'Other' && !/[a-zA-Z]$/.test(values.other)) {
            setCurrentFocus('other');
            setError('Please enter valid Other language.');
            scrollToView();
            return false;
        }
        else if (trimVals.diagCode === '000-OTHER' && !trimVals.otherPatientDiag) {
            setCurrentFocus('otherPatientDiag');
            setError('Please enter an Other Diagnosis Description.');
            scrollToView();
            return false;
        }
        else if (values.diagCode === '000-OTHER' && !/[a-zA-Z0-9]$/.test(values.otherPatientDiag)) {
            setCurrentFocus('otherPatientDiag');
            setError('Please enter a valid Other Diagnosis Description.');
            scrollToView();
            return false;
        }
        else if (values.callTime !== '' && !/[\-\.:a-zA-Z0-9]$/.test(values.callTime)) {
            setCurrentFocus('callTime');
            setError('Please enter a valid time.');
            scrollToView();
            return false;
        }
        else {
            setError('');
            return true;
        }
    }


    const updateCodeApi = async (patientId, productCountryId, diagnosisCode, fields) => {
        const otherDiagnosisText = diagnosisCode === '000' ? (fields?.otherPatientDiag) : fields?.diagCode?.split('-')[1];
        try {
            const response = await axiosInstance_2.post(`/diagnosis?patientId=${patientId}&productCountryId=${productCountryId}&diagnosisCode=${diagnosisCode}&otherDiagnosisText=${otherDiagnosisText}`);
            setUpdatedPatientDTO(response.data.data);
            dispatch(setPrescriptionFormData({ ...prescriptionFormData, langDetails: values, isAckn: IsCheckBoxChecked, updatedPatientDTO: response.data.data }));
            navigate('/prescriber/prescriptionformpresinfo');
        } catch (err) {
            console.error(`error with updateAPI ${err}`);
        }
    }

    const handleNext = async () => {
        const isReqFieldNotEmpty = fieldValidation();
        if (isReqFieldNotEmpty && isICD9) {
            if (values.diagCode !== values.ICDcodePre) {
                if (values.diagCode === `${t('selectDiag')}`) {
                    setError('Please select a Diagnosis.');
                } else if (values.diagCode !== `${t('selectDiag')}` && !IsCheckBoxChecked) {
                    setError('Please review the acknowledgement and check the box.');
                } else {
                    values.diagCode !== `${t('selectDiag')}` && IsCheckBoxChecked && updateCodeApi(patientDTO?.patientId, selectedProgram.productCountryId, values?.diagCode.split('-')[0], values);
                }
            } else {
                dispatch(setPrescriptionFormData({ ...prescriptionFormData, langDetails: values, isAckn: false }));
                navigate('/prescriber/prescriptionformpresinfo');
            }

        } else if (isReqFieldNotEmpty && !isICD9) {
            if (values.diagCode !== values.ICDcodePre) {
                if (values.diagCode === `${t('selectDiag')}`) {
                    setError('Please select a Diagnosis.');
                } else if (!IsCheckBoxChecked) {
                    setError('Please review the acknowledgement and check the box.');
                } else {
                    IsCheckBoxChecked && updateCodeApi(patientDTO?.patientId, selectedProgram.productCountryId, values?.diagCode.split('-')[0], values);
                }
            } else {
                dispatch(setPrescriptionFormData({ ...prescriptionFormData, langDetails: values, isAckn: false }));
                navigate('/prescriber/prescriptionformpresinfo');
            }
        }
        scrollToView();
    };

    const handlePrev = () => {
        navigate('/prescriber/prescriptionformdetails');
    }

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        ['other', 'callTime', 'otherPatientDiag'].includes(currentFocus) && inputElement.focus();
        ['other', 'callTime', 'otherPatientDiag'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };


    return (
        <>
            <SessionTimeout />
            <div className={classes.mainContainer} ref={containerRef}>
                <div>
                    <div className={classes.headerContainer}>
                        <div className={classes.generalRemsProgram} style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                        }}></div>
                    </div>
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
                <div className={classes.productHeader}>{t('prescriptionFormDetails')}</div>
                <div style={{ margin: '12px 0 3px 0px' }}> <span className={classes.headerNote} >{t('prescFormHeading')}</span> </div>
                <div className={classes.note} dangerouslySetInnerHTML={{ __html: t('fieldsText') }}></div>
                <div className={classes.contentContainer}>
                    <div className={classes.containerPFD}>
                        <div className={inputStyles.label}>
                            <span className={inputStyles.mandatory}>{t('language')}:</span>
                        </div>
                        <div className={inputStyles.input}>

                            <select
                                name="lang"
                                id="lang"
                                onChange={handleChange}
                                value={values.lang}
                            >
                                <option key={t('selectLang')} value={t('selectLang')}>
                                    {t('selectLang')}
                                </option>

                                {getLanguages?.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>

                    {values.lang === 'Other' ?
                        <Input
                            type="text"
                            name="other"
                            label={t('otherLang')}
                            isRequired={true}
                            maxLength={20}
                            onChangeHandler={handleChange}
                            value={values.other}
                        />
                        : ''
                    }

                    <div className={classes.helpLink_postion}>
                        <Input
                            type="text"
                            name="callTime"
                            maxLength={50}
                            label={t('callTime')}
                            onChangeHandler={handleChange}
                            value={values.callTime}
                        />
                        <div className={classes.select_postion}>
                            <select
                                name="time"
                                id="time"
                                onChange={handleChange}
                                value={values.time}
                            >
                                {getTime?.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {(isICD9 && (Object.entries(upDTO)?.length === 0)) && (
                        <div className={classes.iCD9TextCls}>
                            {(patientCond?.[0]?.diagnosisCode + '-' + patientCond?.[0]?.otherDiagnosisText)?.toLocaleUpperCase()}
                        </div>
                    )}

                    <div className={classes.containerPFD}>
                        <div className={inputStyles.label}>
                            <span className={inputStyles.mandatory}>{t('diagnosisCode')}:</span>
                        </div>
                        <div className={inputStyles.input}>
                            <select
                                style={{ width: '100%' }}
                                name="diagCode"
                                id="diagCode"
                                onChange={handleChange}
                                value={values.diagCode}
                            >
                                <option key={t('selectDiag')} value={t('selectDiag')}>
                                    {t('selectDiag')}
                                </option>

                                {dropdown?.['ICD-10'] ?
                                    dropdown?.['ICD-10']?.map((item) => {
                                        return <option key={item.id} value={item.id + '-' + item.value}>
                                            {item.id + '-' + item.value}
                                        </option>
                                    })
                                    : <></>
                                }
                            </select>
                        </div>
                    </div>

                    {(showCheckbox || values.diagCode !== `${t('selectDiag')}`) && values.diagCode !== values.ICDcodePre ?
                        <div className={classes.checkboxCont}>
                            <div className={classes.empty}> </div>
                            <div className={classes.checkboxDiv}>
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        setIsCheckBoxChecked(!IsCheckBoxChecked)
                                    }}
                                    checked={IsCheckBoxChecked}
                                    id="changeDiagCode"
                                    name="changeDiagCode"
                                />
                                <p>{t('checkboxText')}</p>
                            </div>
                        </div>
                        : <></>
                    }

                    {values.diagCode === '000-OTHER' ?
                        <Input
                            width={'320px'}
                            type="text"
                            name="otherPatientDiag"
                            label={t('otherPatientDiag')}
                            isRequired={true}
                            maxLength={30}
                            onChangeHandler={handleChange}
                            value={values.otherPatientDiag}
                            containerStyle={{ gridTemplateColumns: '0.6fr 1.3fr', marginLeft: 38 }}
                        />
                        : ''
                    }

                    <Input
                        type="text"
                        noInputStyle={noInputStyle}
                        labelStyle={noInputStyle}
                        noInput={true}
                        name="riskC"
                        label={t('riskCategory')}
                        valueExist={values.riskC || ''}
                    />

                    <div className={classes.buttonDivCls}>
                        <div className={classes.prevBtn}>
                            <Button
                                clickFunction={handlePrev}
                                className={getBtnClass(selectedProgram.id)}
                                width={112}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.previous : buttonContent.en.previous}
                            ></Button>
                        </div>
                        <div className={classes.nextBtn}>
                            <Button
                                clickFunction={handleNext}
                                className={getBtnClass(selectedProgram.id)}
                                width={112}
                                height={48}
                                size={18}
                                type="text"
                                buttonname={i18n.language === 'es' ? buttonContent.es.next : buttonContent.en.next}
                            ></Button>
                        </div>
                        
                    </div>
                </div>
            </div>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </>
    );
};

export default PrescriptionFormLang;