import { trimPrefix } from 'utils/trimPrefix';
import classes from './generalPatientAuthTable.module.scss';
import { formatDate } from 'utils/formatDate';
import { useAppDispatch } from 'store';
import { updateSelectedPatient } from 'store/slices/appSlice';
import { useNavigate } from 'react-router-dom';

const GeneralPateintDetailsTable = ({ authData }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        patientNameFirst = null,
        patientNameLast = null,
        productName = null,
        authCreatedDate = null,
        authorizationNumber = null,
        authorizationStatus = null,
        authAge = null,
        activeStatusExpirationDate = null,
        patientId=null,
        patientExternalUniqueId=null,
        prescriberId=null,
    } = authData;

    const showAuthStatus = (status) => {
        return status.toLowerCase() == 'mismatched'
            ? 'Survey-Flagged'
            : status.toLowerCase() == 'obsolete'
            ? 'Cancelled'
            : status;
    };

    const selectedPatientHandler = () => {
        const patientDetails ={
            patientId:patientId && patientId,
            patientExtUniqueId:patientExternalUniqueId && patientExternalUniqueId,
            assocPrescriberId:prescriberId && prescriberId
        }
        dispatch(updateSelectedPatient(patientDetails));
        navigate('/prescriber/patientdetails');
    };

    return (
        <div className={classes.generalTable}>
            <table>
                <tr>
                    <th>
                        Patient <br /> Name
                    </th>
                    <th>
                        REMS <br /> Program
                    </th>
                    <th>Authorization Date</th>
                    <th>Authorization Number</th>
                    <th>Authorization Status</th>
                    <th>Authorization Age</th>
                    <th>Authorization&nbsp;&nbsp;&nbsp;&nbsp; Expiration Date</th>
                </tr>
                <tr>
                    <td className={classes.patientName} onClick={selectedPatientHandler}>
                        {patientNameFirst && patientNameLast && (
                            <span>
                                {patientNameFirst + ' ' + patientNameLast}{' '}
                                <span style={{ fontSize: '16px' }}>&#x1F892;</span>{' '}
                            </span>
                        )}
                    </td>
                    <td>{productName && productName}</td>
                    <td>{authCreatedDate && formatDate(authCreatedDate)}</td>
                    <td>{authorizationNumber && trimPrefix(authorizationNumber)}</td>
                    <td>{authorizationStatus && showAuthStatus(authorizationStatus)}</td>
                    <td>{authAge && Math.abs(authAge)}</td>
                    <td>{activeStatusExpirationDate && formatDate(activeStatusExpirationDate)}</td>
                </tr>
            </table>
        </div>
    );
};

export default GeneralPateintDetailsTable;
