// HelpPageURL ContentMapper (Es)
async function fetchData() {
    try {
        const response = await fetch('./static/resources/helpContentSpanish.json');
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

const helpContentDataEs = await fetchData().then((res) =>  res);

export const helpContentEs = {
    prescriberSelection: helpContentDataEs.prescriberSelection,
    createaccount: helpContentDataEs.personalAndAccountInfo,
    createaccountconfirmationprescriber: helpContentDataEs.createAccountConfirmationPrescriber,
    createaccountconfirmationnonprescriber: helpContentDataEs.createAccountConfirmationNonPrescriber,
    createaccountsuccessprescriber: helpContentDataEs.userNameCreationConfirm,
    createaccountsuccessnonprescriber: helpContentDataEs.userNameCreationConfirm,
    Home: helpContentDataEs.chooseProgram,
    LoginHome: helpContentDataEs.prescriberHelp,
    productRegistration: helpContentDataEs.productRegistration,
    productSignUp: helpContentDataEs.productSignUp,
    productSignAndContinue: helpContentDataEs.presDigSignatureConfirm,
    SystemError: helpContentDataEs.SystemError,
    Success: helpContentDataEs.presEnrollmentSuccess,
    forgotPassword: helpContentDataEs.forgotPassword,
    securityVerification: helpContentDataEs.securityVerification,
    forgotPasswordConfirmation: helpContentDataEs.forgotPasswordConfirmation,
    resetSecurity: helpContentDataEs.resetSecQues,
    resetTempPassword: helpContentDataEs.resetTempPassword,
    MainMenu: helpContentDataEs.mainMenu,
    patientVerification: helpContentDataEs.patientVerification,
    resetPasswordConfirmation: helpContentDataEs.resetPasswordConfirmation,
    ppafConfirmation:helpContentDataEs.ppafConfirmation,
    PhysicianOfficeDetails: helpContentDataEs.PhysicianOfficeDetails,
    patientInformation : helpContentDataEs.patientInformation,
    patientagreementform: helpContentDataEs.patientagreementform,
    patientagreementformPrescriberSign : helpContentDataEs.patientagreementformPrescriberSign,
    ppafSelection: helpContentDataEs.ppafSelection,
    PrescriberSearch: helpContentDataEs.physicianSearch,
    physicianSearch: helpContentDataEs.physicianSearch,
    prescriberSearchResult: helpContentDataEs.physicianSearchResult,
    ppafWorkMenu: helpContentDataEs.ppafWorkMenu,
    submittedPPAFSearch: helpContentDataEs.physicianSearch,
    patientAddressPPAFSearch: helpContentDataEs.physicianSearch,
    submittedPpafCreatedSearch: helpContentDataEs.physicianSearch,
    patientInformationEditPatient:helpContentDataEs.patientInformationEditPatient,
    patientSurveySearch:helpContentDataEs.patientSurveySearch,
    patientSurveySurvey:helpContentDataEs.patientSurveySurvey,
    patientSurveySelectProduct:helpContentDataEs.patientSurveySelectProduct,
    patientSurveyReview:helpContentDataEs.patientSurveyReview,
    patientSurveyDisclaimer:helpContentDataEs.patientSurveyDisclaimer,
    patientSurveyRestart:helpContentDataEs.patientSurveyRestart,
    prescribersurveyinformation: helpContentDataEs.prescriberSurveyInformation,
    prescribersurveyconfirmation: helpContentDataEs.prescriberSurveyConfirmation,
    patientSurveyError:helpContentDataEs.patientSurveyError,
    partiallycompletedppafsearchprescriber:helpContentDataEs.partiallycompletedppafsearchprescriber,
    patientcamunda : helpContentDataEs.patientCamunda,
    partiallycompletedppafsearchnonprescriber: helpContentDataEs.physicianSearch,
    patientSearch : helpContentDataEs.partiallycompletedppafsearchprescriber,
    submittedPPAFSearchnonprescriber: helpContentDataEs.physicianSearch,
    prescriberpatientsearchprescriber : helpContentDataEs.patientSearch,
    prescriberpatientsearchnonprescriber : helpContentDataEs.patientSearch,
    submittedPPAFSearchprescriber: helpContentDataEs.partiallycompletedppafsearchprescriber,
    patientaddressppafsearchnonprescriber: helpContentDataEs.physicianSearch,
    patientaddressppafsearchprescriber : helpContentDataEs.partiallycompletedppafsearchprescriber,
    submittedppafcreatedsearchprescriber: helpContentDataEs.partiallycompletedppafsearchprescriber,
    patientreactivation: helpContentDataEs.patientSearch,
    submittedppafcreatedsearchnonprescriber : helpContentDataEs.physicianSearch,
    prescribersurveypatientsearch: helpContentDataEs.patientSearch,
    prescribersurveysearch: helpContentDataEs.partiallycompletedppafsearchprescriber
};
