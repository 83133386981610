import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import indexedDBStore from './indexedDBStore';
import appReducer from './slices/appSlice';
import loginReducer from './slices/loginSlice';
import storageSession from 'redux-persist/lib/storage/session';

const appPersistConfig = {
    key: 'app',
    version: 1,
    //storage: indexedDBStore,
    storage: storageSession,
};

const loginPersistConfig={
    key: 'login',
    version: 1,
    storage:indexedDBStore

}

const reducer = combineReducers({
    app: persistReducer(appPersistConfig,appReducer),
    login: persistReducer(loginPersistConfig,loginReducer),
});

//const persistedReducer = persistReducer(loginPersistConfig, reducer);

export const store = configureStore({
    reducer: reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;