import Alert from 'components/Alert';
import Button from 'components/Button';
import Input from 'components/Input';
import { axiosInstance } from 'constants/Config/axiosConfig';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { patientInformation } from 'store/slices/appSlice';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { getPatientDetailsEditPatient } from 'utils/getPatientDetailsEditPatient';
import classes from './PatientReactivation.module.scss';
import {buttonContent, getBtnClass, getBtnClassDisabled} from '../../assets/objects/buttonContent';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';
 
// import Revise from './../../assets/images/btn_Revise.png';
// import Save from './../../assets/images/btn_Continue_Save.png';
 
const PrescriberPatientSearch = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();
    const [existingRC, setExistingRC] = useState(null);
    const [isPending, setIspending] = useState(false);
    const { patientSearchStore } = useAppSelector((state) => state.app);
    const { patientReactivationStore, patientReactivationSpecialStore } = useAppSelector((state) => state.app);
    const [servererror, setServerError] = useState('');
    const [error, setError] = useState('');
    const [patientValidationError, setPatientValidationError] = useState('');
 
 
 
    const { selectedProgram } = useAppSelector((state) => state.app);
 
    const selectedLanguage = i18n.language;
    const product = selectedProgram.id;
    const mi = `${patientReactivationStore?.mi}`;
    const patientenrollstatus = `${patientReactivationStore?.data?.patientRegistrationForms[0]?.patientRegStatus[0].registrationStatus.lookupValue}`;
    const patientStatus = `${patientReactivationSpecialStore?.data?.patientDTO?.patientRegistrationForms[0]?.patientRegStatus[0].registrationStatus.lookupValue}`;
 
 
    // const patientenrollstatus = Object.keys(patientReactivationStore).length>0? patientReactivationStore?.patientPrescribers[0]?.status:'';
    // const patientenrollstatus = 'Active';
    // if(patientenrollstatus===''||'undefined'||'Deactivate'){
    //     navigate('/prescriber/patientsearch')
    // }
    // const patientenrollstatus = `${'Active'}`;
    // const trimmedDate = patientReactivationStore?.patientDob.replace("00:00:00","")
    const dob = `${patientSearchStore?.month} ${patientSearchStore?.date}, ${patientSearchStore?.year}`.replace("00:00:00", "");
 
    let remsProgram = selectedProgram.label;
 
    const getProgramsName = (): string => {
        return mainMenu(selectedProgram.id);
        
    };
 
 
 
    const localData = patientReactivationStore.statusCode === 200 ?
        (patientReactivationStore?.data) :
        (patientReactivationSpecialStore?.data?.patientDTO);
 
 
    const handleRevise = () => {
        const prevRoute = sessionStorage.getItem('prevRoute')||'/prescriber/patientsearch'
        navigate(prevRoute);
    };
    const handleSave = async () => {
        sessionStorage.removeItem('prevRoute')
        sessionStorage.removeItem('patientInfo')
        if (patientReactivationStore.statusCode === 200) {
            const response = await axiosInstance.post(`/referencedata`, { keys: ['ICD-10'] });
            const infoData = getPatientDetailsEditPatient(patientReactivationStore?.data, response.data.data['ICD-10'], 'Reactivate', i18n.language);
            dispatch(patientInformation({ ...infoData, flowType: 'Reactivate' }));
            navigate('/prescriber/patientInformation');
        }
        else {
            return false;
        }
    };
    const handleOnChange = (e) => {
        servererror && setServerError('');
        patientValidationError && setPatientValidationError('');
        error && setError('');
        const { name, value } = e.target;
 
    };
 
    useEffect(() => {
       
        if (patientReactivationSpecialStore?.statusCode === 204 || patientReactivationSpecialStore?.data?.errorCode === 'PV0450' ) {
            setServerError(t('error_PV0450').replaceAll('{program}', getProgramsName()));
        }
        else {
            console.error("error from response status code 200")
        }
    }, [patientReactivationSpecialStore]);
    const authStatuslang = i18n.language === "es" ? 1 : 0;
 
    return (
        <div className={classes.container}>
            <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
            <div className={classes.productHeader}>{t('patientSearchHeader')}</div>
            <div className={classes.contentDivider}></div>
 
            <div className={classes.mainContainer}>
                <div className={classes.PRErrorContainer} >
                    {servererror && <Alert styles={{ margin: '10px 30px 5px 30px' }} content={servererror} />}
                </div>
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="firstName"
                    label={t('firstName')}
                    valueExist={localData?.patientNameFirst}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="lastName"
                    label={t('lastName')}
                    valueExist={localData?.patientNameLast}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    name="mi"
                    label={t('mi')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={localData?.patientMiddleInitial}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="DOB"
                    label={t('DOB')}
                    isRequired={true}
                    // valueExist={`${patientReactivationStore?.patientDob.trim('')} 00:00:00`}
                    valueExist={dob}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    name="patientIdNum"
                    // maxLength={9}
                    label={t('patientPin')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={localData?.patientExternalUniqueId}
                    onChangeHandler={handleOnChange}
                />
 
                <Input
                    type="text"
                    name="patientEnrollmentStatus"
                    label={t('patientenrollstatus')}
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    valueExist={localData?.patientRegistrationForms[0]?.patientRegStatus[0].registrationStatus.lookupValue}
                    onChangeHandler={handleOnChange}
                />
 
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationStatus"
                    label={t('authorizationStatus')}
                    valueExist={localData?.surveyData ? localData?.surveyData[0]?.authorizationStatus?.split('||')[authStatuslang] : null}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="authorizationNumber"
                    label={t('authorizationNumber')}
                    valueExist={localData?.surveyData ? localData?.surveyData[0]?.authorizationNumber?.substring(2) : ''}
                    onChangeHandler={handleOnChange}
                />
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="dateLastPrescriberSurveyCompleted"
                    label={t('dateLastPrescriberSurveyCompleted')}
                    valueExist={localData?.surveyData ? moment(localData?.surveyData[0]?.prescriberSurveyCompletedDate).format('MMMM DD, YYYY') !== 'Invalid date' ? moment(localData?.surveyData[0]?.prescriberSurveyCompletedDate).format('MMMM DD, YYYY') : '' : ''}
                    onChangeHandler={handleOnChange}
                />
                {/* ||'Pending' */}
                <div className={classes.btn_group}>
                    {patientenrollstatus === 'Active' || patientenrollstatus === 'Pending' || patientenrollstatus === 'Deactivated' ? (
                    <Button
                        clickFunction={handleSave}
                        className={getBtnClass(selectedProgram.id)}
                        width={116}
                        height={48}
                        size={18}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                    ></Button>
                    ) : (
                        <Button
                        className={getBtnClassDisabled()}
                        width={116}
                        height={48}
                        size={18}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                    ></Button>
                        )}
                    <Button
                        clickFunction={handleRevise}
                        className={getBtnClass(selectedProgram.id)}
                        width={266}
                        height={48}
                        size={18}
                        type="text"
                        buttonname={i18n.language === 'es' ? buttonContent.es.searchNewPat : buttonContent.en.searchNewPat}
                    ></Button>
                </div>
            </div>
        </div>
    );
};
 
export default PrescriberPatientSearch;