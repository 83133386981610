import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../../components/Input';
import { useAppDispatch, useAppSelector } from '../../../store';
import PageBackground from '../../../components/PageBackground';
import classes from './PharmacyAccountConfirmation.module.scss';
import {
    changeHelpContentForAccount,
    createUserAccountPharmacy,
    resetUserAcc,
    setPharmacyAuthenticate,
} from '../../../store/slices/appSlice';
import { staticFieldValue } from '../../../constants/createPharmacyAccountFields';
import ErrorModal from '../../../components/ErrorModal';
import Success from '../../../components/Success';
import { accountConfirmationContent } from '../../../assets/objects/accountConfirmContent';
import { axiosInstancePharmacy } from 'constants/Config/axiosConfig';
import { getUserPayload } from '../../../utils/createUser';
import { authenticateUser, setLoginDetails } from 'store/slices/loginSlice';
import SessionTimeout from 'components/SessionTimeout';
import { RoleByGroup } from 'constants/Config/appConfig';

const AccountConfirmation = () => {
    const navigate = useNavigate();
    const accountValues = sessionStorage.getItem('pharmacyAccnt')
        ? JSON.parse(sessionStorage.getItem('pharmacyAccnt') || '')
        : null;
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [savedData, setSavedData] = useState<any>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [next, setNext] = useState(false);
    const { passwordField, accFormDetails, errorCreateAccount } = useAppSelector((state) => state.app);
    const [currentFocus, setCurrentFocus] = useState('');
    const [loader, setLoader] = useState(false);
    const contentToPrint = useRef(null);

    const handlePrint = useReactToPrint({
        documentTitle: 'REMS Pharmacy Portal',
        onBeforePrint: () => console.log('before printing...'),
        onAfterPrint: () => console.log('after printing...'),
        removeAfterPrint: true,
    });

    const validatePharmacy = async () => {
        const reqBody = buildRequestBody();
        const user_payload = getUserPayload({ ...reqBody, role: RoleByGroup.Pharmacist });
        accountValues &&
            dispatch(createUserAccountPharmacy(user_payload)).then(async (res: any) => {
                if (res?.payload?.authResult?.AuthenticationResult?.AccessToken) {
                    dispatch(setLoginDetails({data:res?.payload?.authResult,role:"pharmacy"}));
                    const savePharmacy = async () => {
                        const payload = {
                            accessCode: accFormDetails?.accessCode,
                            ncpdpNumber: accFormDetails?.NCPDPNumber,
                            deaNumber: accFormDetails?.DEANumber,
                            username: accFormDetails?.userName,
                            password: accFormDetails?.password,
                            first_name: accFormDetails?.firstName,
                            last_name: accFormDetails?.lastName,
                            email: accFormDetails?.email,
                            phone_number: accFormDetails?.phoneNumber,
                            address: accFormDetails?.officeAddress, //accFormDetails?.AddressLine1 + '' + accFormDetails?.AddressLine2,
                            city: accFormDetails?.city,
                            state: accFormDetails?.state,
                            zip_code: accFormDetails?.zipCode,
                            country_code: 1,
                            customer_id: null,
                            group_tag: 'GBL-AP-User-GRMP-Pharmacist',
                            language: 'en-US',
                            security_question: accFormDetails?.securityQuestion,
                            security_answer: accFormDetails?.securityAnswer,
                            system_Id: accFormDetails?.accessCode,
                            pharmacy_user_id: accFormDetails?.userName,
                        };

                        await axiosInstancePharmacy
                            .post('/v1/pharmacy/save-pharmacy-contact', payload)
                            /* .then((res) => {
                                console.log('savePharmacyContact', res);
                            }) */
                            .catch((error) => {
                                console.error('error savePharmacyContact', error);
                                setLoader(false);
                            });
                    };
                    await savePharmacy();
                    setLoader(false);
                }
                setLoader(false);
            });
    };

    const buildRequestBody = () => {
        return {
            userName: accFormDetails.userName.trim() || null,
            password: passwordField.password || null,
            firstName: accFormDetails.firstName.trim() || null,
            lastName: accFormDetails.lastName.trim() || null,
            officeAddress: accFormDetails.officeAddress.trim() || null,
            city: accFormDetails.city || null,
            state: accFormDetails.state || null,
            zipCode: accFormDetails.zipCode || null,
            email: accFormDetails.email.trim() || null,
            phoneNumber: accFormDetails.phoneNumber ? `+1${accFormDetails.phoneNumber.replace(/[ -]/g, '')}` : null,
            groupTag: null,
            language: null,
            securityAnswer: accFormDetails.securityAnswer.trim() || null,
            securityQuestion: accFormDetails.securityQuestion || null,
            accessCode: accFormDetails?.accessCode ? accFormDetails?.accessCode : null,
        };
    };

    const handleContinue = async () => {
        setLoader(true);
        validatePharmacy();
        dispatch(setPharmacyAuthenticate(true));
        
    };

    const handleRevise = () => {
        sessionStorage.removeItem('pharmacyAccnt');
        navigate('/pharmacy/createAccount', { replace: true });
    };

    const handleSuccessCtn = () => {
        const values = {
            username: accFormDetails.userName.trim(),
            password: passwordField.password,
        };
        dispatch(authenticateUser(values)).then((response:any) => {
            if (response?.meta?.requestStatus === 'fulfilled') {
                dispatch(setPharmacyAuthenticate(true));
            }
            else{                   
                if(response?.payload?.$metadata?.httpStatusCode=== 401 || response?.payload?.$metadata?.httpStatusCode=== 403)
                {
                    navigate('/prescriber/SystemError');
                }            
            }
        });
        sessionStorage.removeItem('pharmacyAccnt');
        navigate('/pharmacy/PharmacyHome', { replace: true });
    };

    useEffect(() => {
        return () => {
            dispatch(resetUserAcc());
            dispatch(changeHelpContentForAccount(false));
        };
    }, [dispatch]);

    useEffect(() => {
        if (errorCreateAccount.message === 'Success') {
            dispatch(changeHelpContentForAccount(true));
            setNext(true);
        }
    }, [dispatch, errorCreateAccount]);

    useEffect(() => {
        const storedData = sessionStorage.getItem('accountData');
        if (storedData) {
            const item = JSON.parse(storedData);
            setSavedData(item);
        }
    }, []);

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = 'The Page you are looking has unsaved changes';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['securityQuestion'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    const handlePrintBtn = () => {
        handlePrint(null, () => contentToPrint.current);
        // document.title="REMS Pharmacy Portal";
        // window.print();
        // document.title="User Name Creation Review";
    };

    return (
        <>
            <div ref={containerRef} className={classes.acccountConfirmation}>
                {!next ? (
                    <>
                        <SessionTimeout />

                        <PageBackground
                            label="User Name Creation Review"
                            btn={true}
                            handleClick={handleContinue}
                            handleReviseBtn={handleRevise}
                            handlePrintBtn={handlePrintBtn}
                            signOn={false}
                            loader={loader}
                        >
                            <div className={classes.contentContainer}>
                                <div className={classes.firstContainer}>
                                    <div className={classes.subHeader}>
                                        {accountConfirmationContent.title.Pharmacist}
                                    </div>

                                    {staticFieldValue.map((i) => (
                                        <Input
                                            key={i.name}
                                            noInput={true}
                                            valueExist={accFormDetails[i.name] || savedData[i.name]}
                                            type="text"
                                            name={i.name}
                                            label={i.label}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className={classes.secondContainer}>
                                <div ref={contentToPrint}>
                                    <div>
                                        <div
                                            style={{
                                                paddingLeft: '80px',
                                                display: 'block',
                                                fontFamily: 'BMS-Humanity',
                                                fontWeight: 900,
                                            }}
                                        >
                                            <strong style={{ fontWeight: 900 }}></strong> User Name Creation Review{' '}
                                            <br />
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                            <table
                                                style={{
                                                    borderCollapse: 'collapse',
                                                    border: '1px solid #090d10',
                                                    width: '80%',
                                                }}
                                            >
                                                <tbody>
                                                    {staticFieldValue
                                                        .map((i) => (
                                                            <tr>
                                                                <td
                                                                    style={{
                                                                        padding: '7px 5px 7px 100px',
                                                                        textAlign: 'right',
                                                                        border: '1px solid #090d10',
                                                                        width: '35%',
                                                                    }}
                                                                >
                                                                    <strong>{i.label}: </strong>{' '}
                                                                </td>

                                                                <td
                                                                    style={{
                                                                        padding: '7px 80px 7px 5px',
                                                                        textAlign: 'left',
                                                                        border: '1px solid #090d10',
                                                                        width: '65%',
                                                                    }}
                                                                >
                                                                    {accFormDetails[i.name] || savedData[i.name]}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        .slice(0, -1)}
                                                    <tr>
                                                        <td
                                                            style={{
                                                                padding: '7px 5px 7px 100px',
                                                                textAlign: 'right',
                                                                border: '1px solid #090d10',
                                                            }}
                                                        >
                                                            <strong>Your Answer:</strong>
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: '7px 80px 7px 5px',
                                                                textAlign: 'left',
                                                                border: '1px solid #090d10',
                                                            }}
                                                        >
                                                            ..........
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PageBackground>

                        {error && (
                            <ErrorModal error={error} handleContinue={handleErrorBtn} classNamesAccCreation={true} />
                        )}
                    </>
                ) : (
                    <Success label={'User Name Creation Confirmation'} btn={true} handleClick={handleSuccessCtn}>
                        <div style={{ padding: '29px 15px', fontWeight: 'bold' }}>
                            <>{accountConfirmationContent.successMessage.Pharmacist.label}</>
                            <div style={{ padding: '10px 15px', fontWeight: 'bold' }}></div>
                            <>{accountConfirmationContent.successMessage.Pharmacist.subText}</>
                        </div>
                    </Success>
                )}
            </div>
        </>
    );
};
export default AccountConfirmation;
