import { useAppDispatch, useAppSelector } from 'store';
import classes from './ReportsTable.module.scss';
import { updateSelectedPatient } from '../../../store/slices/appSlice';
import rightArrow from '../../../assets/images/btn_arrow_right.png';
import { useNavigate } from 'react-router-dom';
import * as ExcelJS from 'exceljs';
import { useTranslation } from 'react-i18next';
import ExportToExcel from '../../../assets/images/excelExport.png';
import moment from 'moment-timezone';

const ReportsTable = ({ setIsSorted, fetchReportData, noData, reportInpData }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const content = fetchReportData;

    const selectedPatientHandler = (patientData) => {
        const patientDetails = {
            patientId: patientData && patientData.patientId,
            patientExtUniqueId: patientData && patientData.patientExtUniqueId,
            assocPrescriberId: patientData && patientData.assocPrescriberId
        }
        dispatch(updateSelectedPatient(patientDetails));
        navigate('/prescriber/patientdetails');
    };

    const sortByPatientNameAscOrder = (content) => {
        content = content.sort((a, b) => a.patientLastName.localeCompare(b.patientLastName));
        setIsSorted({ isSorted: true, content: content });

    };

    const sortByPatientNameDescOrder = (content) => {
        content = content.sort((a, b) => b.patientLastName.localeCompare(a.patientLastName));
        setIsSorted({ isSorted: true, content: content });

    };
    const sortByAuthDateAsc = (content) => {
        content = content.sort((a, b) => Date.parse(a.authorizationDate) - Date.parse(b.authorizationDate));
        setIsSorted({ isSorted: true, content: content });

    };
    const sortByAuthDateDesc = (content) => {
        content = content.sort((a, b) => Date.parse(b.authorizationDate) - Date.parse(a.authorizationDate));
        setIsSorted({ isSorted: true, content: content });

    };


    const sortByAuthNumAscOrder = (content) => {
        content = content.sort((a, b) => a.authorizationNumber.localeCompare(b.authorizationNumber));
        setIsSorted({ isSorted: true, content: content });
    };

    const sortByAuthNumDescOrder = (content) => {
        content = content.sort((a, b) => b.authorizationNumber.localeCompare(a.authorizationNumber));
        setIsSorted({ isSorted: true, content: content });
    };
    const sortByExpDateAsc = (content) => {
        content = content.sort((a, b) => Date.parse(a.authorizationExpirationDate) - Date.parse(b.authorizationExpirationDate));
        setIsSorted({ isSorted: true, content: content });
    }
    const sortByExpDateDesc = (content) => {
        content = content.sort((a, b) => Date.parse(b.authorizationExpirationDate) - Date.parse(a.authorizationExpirationDate));
        setIsSorted({ isSorted: true, content: content });
    }

    const noDataMsg = noData ? t('noRecordsAvailable') : t('noRecords');


    const handleExcel = () => {

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("AuthNumToExpReport");

        sheet.getRow(1).font = {
            name: "Arial",
            family: 4,
            size: 10,
            bold: true,
            color: { argb: "#0000FF" }
        };
        sheet.columns = [
            {
                header: "Patient Name",
                key: "PatientName",
                width: 20,
            },
            {
                header: "REMS Program",
                key: "REMSProgram",
                width: 15
            },
            {
                header: "Authorization Date",
                key: "AuthorizationDate",
                width: 20,
            },
            {
                header: "Authorization Number",
                key: "AuthorizationNumber",
                width: 20,
            },
            {
                header: "Authorization Status",
                key: "AuthorizationStatus",
                width: 20,
            },
            {
                header: "Authorization Age",
                key: "AuthorizationAge",
                width: 20,
            },
            {
                header: "Authorization Expiration Date",
                key: "AuthExpDate",
                width: 20,
            }, {
                header: "Prescriber Name",
                key: "PrescName",
                width: 20,
            },
        ];

        content?.map((detail) => {
            sheet.addRow({
                PatientName: detail?.patientLastName + ' ' + detail?.patientFirstName,
                REMSProgram: detail?.remsProgram,
                AuthorizationDate: detail?.authorizationDate,
                AuthorizationNumber: detail?.authorizationNumber?.slice(2),
                AuthorizationStatus: detail?.authorizationStatus,
                AuthorizationAge: detail?.authorizationAge,
                AuthExpDate: detail?.authorizationExpirationDate,
                PrescName: detail?.assocPrescriberLastName + ' ' + detail?.assocPrescriberFirstName
            });
        });
        for(let i=1; i<7; i++){
            sheet.insertRow(i, { PatientName: '' });
        }
        sheet.getColumn('F').alignment = { horizontal: 'left' };
        // add a table to a sheet
        sheet.addTable({
            name: 'MyTable',
            ref: 'A1',
            style: {
                showRowStripes: false,
                showFirstColumn: true
            },
            columns: [
                { name: ' ' },
                { name: 'Authorization Numbers to Expire Report' },
            ],
            rows: [
                ['Date From', reportInpData.startDate],
                ['Date To', reportInpData.endDate],
                ['REMS Program(s)', reportInpData.programs.join(', ')],
                ['Prescribers', reportInpData.prescribers.join(', ')],
            ],
        });
        sheet.getCell('B1').font = {
            name: 'Arial',
            family: 4,
            size: 13,
            color: { argb: 'FF0000FF' },
            bold: true
        };
        sheet.getCell('B1').fill = {
            type: 'pattern',
            pattern: 'none',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' }
        };
        sheet.getCell('A1').fill = {
            type: 'pattern',
            pattern: 'none',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' }
        };
        sheet.getCell('G1').value = moment().format('MMM DD, YYYY');
        const row = sheet.getRow(1);
        row.height = 30;
        row.alignment = { vertical: 'middle' };
        sheet.getCell('G1').font = {
            bold: true
        };
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "REMSPortal.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
        });

    }

    const tableHeader = () => {
        return (
            <>
                <tr>
                    <th colSpan={1} rowSpan={2}>
                        <div className={classes.thContainer}>
                            <label>Patient Name</label>
                            <div className={classes.sortControl}>
                                <a onClick={() => sortByPatientNameAscOrder(content)}>
                                    <span className={`${classes.icon} ${classes.upArrow}`}></span>
                                </a>
                                &nbsp;
                                <a onClick={() => sortByPatientNameDescOrder(content)}>
                                    <span className={`${classes.icon} ${classes.downArrow}`}></span>
                                </a>
                            </div>
                        </div>
                    </th>

                    <th colSpan={1} rowSpan={2} className={classes.pr8px}>
                        REMS Program
                    </th>
                    <th className={classes.authorization_column} colSpan={5} rowSpan={1}>
                        Authorization

                    </th>
                    <th colSpan={1} rowSpan={2} className={classes.pr8px}>
                        Prescriber
                    </th>

                </tr>
                <tr>
                    <th className={classes.flex_content} colSpan={1}>
                        <div className={classes.thContainer}>
                            <label>Date</label>
                            <div className={classes.sortControl}>
                                <a onClick={() => sortByAuthDateAsc(content)}>
                                    <span className={`${classes.icon} ${classes.upArrow}`}></span>
                                </a>
                                &nbsp;
                                <a onClick={() => sortByAuthDateDesc(content)}>
                                    <span className={`${classes.icon} ${classes.downArrow}`}></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <th className={classes.flex_content} colSpan={1}>
                        <div className={classes.thContainer}>
                            <label>Number</label>
                            <div className={classes.sortControl}>
                                <a onClick={() => sortByAuthNumAscOrder(content)}>
                                    <span className={`${classes.icon} ${classes.upArrow}`}></span>
                                </a>
                                &nbsp;
                                <a onClick={() => sortByAuthNumDescOrder(content)}>
                                    <span className={`${classes.icon} ${classes.downArrow}`}></span>
                                </a>
                            </div>
                        </div>
                    </th>
                    <th colSpan={1} className={classes.pr8px}>Status</th>
                    <th colSpan={1} className={classes.pr8px}>Age</th>
                    <th className={classes.flex_content} colSpan={1}>
                        <div className={classes.thContainer}>
                            <label>Expiration Date</label>
                            <div className={classes.sortControl}>
                                <a onClick={() => { sortByExpDateAsc(content) }}>
                                    <span className={`${classes.icon} ${classes.upArrow}`}></span>
                                </a>
                                &nbsp;
                                <a onClick={() => { sortByExpDateDesc(content) }}>
                                    <span className={`${classes.icon} ${classes.downArrow}`}></span>
                                </a>
                            </div>
                        </div>
                    </th>




                </tr>
            </>
        );
    };
    if (!content || content.length === 0) {
        return (
            <table width="100%" cellPadding="0" cellSpacing="0" border={0} className={`${classes.reportTable} ${classes.emptyTable}`}>
                <tbody>
                    <tr className="odd">
                        <td className={classes.noRecordsFound} align="center" colSpan={8}>
                            {noDataMsg}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    const date = moment().tz('America/New_York')
    const fdate = date.format('ddd MMM DD HH:mm:ss z YYYY');

    if (content && content.length > 0) {
        return (
            <>
            <div className={classes.reportsTableContainer}>
                <button onClick={handleExcel} className={classes.exprttoExlBtn}>
                    <img src={ExportToExcel} alt="export to Excel" />
                    <p>Export to Excel</p>
                </button>
                <div className={classes.contentReportsTable}>
                    <table width="100%" cellPadding="0" cellSpacing="0" border={0} className={classes.reportTable}>
                        <tbody>
                            {tableHeader()}
                            {content.map((each) => {
                                return (
                                    <tr key={each.assocPrescriberName} className={classes.patientDetails}>
                                        <td colSpan={1}>
                                            <a
                                                onClick={() => {
                                                    selectedPatientHandler(each);
                                                }}
                                            >
                                                <span>
                                                    {each.patientLastName && each.patientLastName}
                                                    {', '}
                                                    <br />
                                                    {each.patientFirstName && each.patientFirstName}
                                                </span>
                                                <img src={rightArrow} alt="right-arrow" />
                                            </a>
                                        </td>
                                        <td colSpan={1}>{each.remsProgram && each.remsProgram}</td>
                                        <td colSpan={1}>
                                            <span>
                                                {each.authorizationDate && each.authorizationDate}
                                            </span>
                                        </td>
                                        <td colSpan={1}>{each.authorizationNumber && each.authorizationNumber?.slice(2)}</td>
                                        <td colSpan={1}>{each.authorizationStatus && each.authorizationStatus}</td>
                                        <td colSpan={1}>{each.authorizationAge && each.authorizationAge}</td>
                                        <td colSpan={1} className={classes.nestedContent}>{each.authorizationExpirationDate && each.authorizationExpirationDate}</td>
                                        <td colSpan={1}>
                                            <span>
                                                {each.assocPrescriberLastName && each.assocPrescriberLastName}
                                                {', '}
                                                <br />
                                                {each.assocPrescriberFirstName && each.assocPrescriberFirstName}
                                            </span>
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        );
    }
};

export default ReportsTable;