import { useEffect, useState } from 'react';
import classes from './PrescriptionActionItems.module.scss';
import { DownOutlined } from '@ant-design/icons';
import { useAppSelector } from 'store';

interface ISelectProps {
    data: any;
    setFields?: any;
    show: boolean;
    setShowDropdown: any;
    statusIds?: any;
    setstatusIds?: any
}
const SelectionDropdown = ({ data, setFields, show, setShowDropdown, statusIds, setstatusIds }: ISelectProps) => {

    const { actionitemInfo = {} } = useAppSelector((state) => state.app);

    const [checkboxValues, setCheckboxValues] = useState<any[]>(
        Object.entries(actionitemInfo)?.length > 0 && actionitemInfo?.isNavigate
            ? actionitemInfo?.fields?.selections
            : []
    );

    useEffect(() => {
        setFields({
            filterBy: 'Status',
            selections: (!!checkboxValues?.length) ? checkboxValues : ''
        });
    }, [checkboxValues?.length]);


    const handelClickDiv = (text) => {
        const statusMappingToId = data?.find((ob) => ob.value === text)?.id;

        if (checkboxValues?.filter((obj) => obj === text)?.length > 0) {
            setCheckboxValues((prev) => prev?.filter((obj) => obj !== text));
            setstatusIds((prev) => prev?.filter((val) => (
                text === "Prescriber Survey Required" ? !statusMappingToId.includes(val) : (val !== statusMappingToId)
            )));
        } else {
            setCheckboxValues([...checkboxValues, text]);
            setstatusIds( text === "Prescriber Survey Required" ?  [...statusIds, ...statusMappingToId] :  [...statusIds, statusMappingToId]);
        }
    };

    return (
        <>
            <div className={classes.checkboxMain}>
                <div
                    className={classes.selectDiv}
                    onClick={() => setShowDropdown(!show)}
                    id='multicheckbox'
                    tabIndex={0}
                    onKeyDown={(e) => e.key === 'Enter' && setShowDropdown(!show)}
                >
                    {
                        !checkboxValues?.length
                            ? 'Select an option'
                            : `${checkboxValues?.length} selected`
                    }
                    <span className={classes.downArwIcon}>
                        <DownOutlined />
                    </span>
                </div>

                {show ?
                    <div className={classes.overlayDiv} >
                        {!!data?.length ?
                            data?.sort((a, b) => a.value?.localeCompare(b.value))
                                ?.map((item) => {
                                    return (
                                        <p key={item.id}
                                            onClick={() => handelClickDiv(item.value)}
                                        >
                                            <input
                                                className={classes.inputCheckbox}
                                                type="checkbox"
                                                onChange={() => handelClickDiv(item.value)}
                                                checked={!!checkboxValues?.length && checkboxValues?.filter((obj) => obj === item?.value)?.length > 0 ? true : false}
                                                id={item.value}
                                                name={item.value}
                                            />
                                            {item.value}
                                        </p>
                                    );
                                }) : <></>
                        }
                    </div> : <></>}
            </div>
        </>
    )
}
export default SelectionDropdown;
