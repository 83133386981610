import React from 'react';
import PageBackground from '../PageBackground';
import classes from './SystemError.module.scss';
import warning from './../../assets/images/img_Warning_Exclamation.png';
import { useAppSelector } from 'store';
import Alert from 'components/Alert';
import { useTranslation } from 'react-i18next';

const SystemError = () => {
    const { EnrollmentStatusError } = useAppSelector((state) => state.login);
    const { t, i18n } = useTranslation();
    const showPV0030Msg = useAppSelector((state) => state.app.showPV0030Msg);

    return (
        <div>
            <PageBackground label={t('SystemErrorTitle')}>
                <div className={classes.bannerContent}>
                    <div className={classes.instructions_bold}>
                        <span>
                            <img className={classes.warning} src={warning} alt="warning_img" />
                        </span>
                        <span>{t('SystemErrorDescription')}</span>
                    </div>
                    {!EnrollmentStatusError ? <div className={classes.mainContent}>
                        <div className={classes.instructions_bold}>
                            {showPV0030Msg ? t('error_PV0030') : t('error_PV0075')}                           
                        </div>
                    </div> : <div className={classes.mainContent}>
                    <Alert content={"<span style='font-weight: bold'> You are currently not active for any Bristol Myers Squibb-administered REMS programs. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.</span>"} styles={{ fontSize: '11px', margin: '10px 20px 5px 20px' }} />
                    </div>}
                </div>
            </PageBackground>
        </div>
    );
};

export default SystemError;
