export const getPrescriberSurveyQuestion = (data) => {
    return data.map((item) => ({
        id: item.QuestionID,
        value: '',
        show: false,
        type: item.AnswerType,
        question: item.QuestionText,
        options: item.PossibleResponses,
        questionSequence: item.QuestionSequence,
        skip: false
    }));
};
