import SessionTimeout from 'components/SessionTimeout';
import Success from 'components/Success';
import { Role } from 'constants/Config/appConfig';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { store, useAppDispatch, useAppSelector } from 'store';
import { resetApp } from 'store/slices/appSlice';
import { setLogOut } from 'store/slices/loginSlice';

const ForgotPasswordConfirmation = () => {
    const resetEmail = sessionStorage.getItem('resetData') || ''
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { role } = useAppSelector((state) => state.app);
    const isPharmacy = role === Role.Pharmacist;

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const handleContinue = () => {
        clearStore();
        dispatch(resetApp());
        dispatch(setLogOut());

        if (isPharmacy) {
            navigate('/pharmacy/login', { replace: true });
        } else {
            navigate('/prescriber/login', { replace: true });
        }
    };

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <>
            <SessionTimeout />
            <Success label="Forgot Password Confirmation" btn={true} handleClick={handleContinue}>
                <p style={{ fontWeight: 'bold' }}>
                    A temporary password will be sent to your email address at {resetEmail}.<br />{isPharmacy && <div style={{marginTop:'15px'}}></div>}
                    Login with your temporary password and reset your password. Please click on the Continue button to
                    go to the login page.
                </p>
                {isPharmacy ? <p style={{ marginTop: '15px', fontWeight: 'bold', marginBottom: '20px' }}>
                    Note: If you have not received your temporary password, please check the Spam folder in your email.
                </p> : <p style={{ marginTop: '30px', fontWeight: 'bold', marginBottom: '20px' }}>
                    Note: If you have not received your temporary password, please check the Spam folder in your email.
                </p> }
            </Success>
        </>
    );
};

export default ForgotPasswordConfirmation;
