import React, { useEffect, useState } from 'react';
import classes from './prescriberSearchResult.module.scss';
import Input from 'components/Input';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SessionTimeout from 'components/SessionTimeout';
import Alert from 'components/Alert';
import { axiosInstanceNewUrl, axiosInstancePrescriber } from 'constants/Config/axiosConfig';
import { changePPAF, deleteIsWritePrescription, selectedPatientProgram, setSurveyInfoFromRoute } from 'store/slices/appSlice';
import { PomalystTextToPomalidomide } from 'utils/constant';
// import Revise from './../../assets/images/btn_Revise.png';
// import Save from './../../assets/images/btn_Continue_Save.png';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { ProgramConfig, mainMenu } from 'constants/Config/programsConfig';

const PatientVerification = () => {
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const noInputStyle = { color: '#000' };
    const navigate = useNavigate();

    const previousRoute = sessionStorage.getItem('prevRoute')||'';
    const { selectedProgram, prescriberDetails,surveyDataFromPatientDetails,isWritePrescription } = useAppSelector(
        (state) => state.app
    );
    const [continueError, setContinueError] = useState('')
    const selectedLanguage = i18n.language;

    const product = selectedProgram.id;

    const handleNewPrescriberSearch = () => {
        navigate(previousRoute);
    };

    const getProgramsName = (): string => {
       
        return mainMenu(product);
    };

    const checkPrescriberStatus = async () => {
        const payload = { prescriberExtUniqueId: prescriberDetails?.pin };
        const findprescriber = await axiosInstancePrescriber.post('v1/prescriber/findprescriber', payload);
        if (findprescriber.status === 200) {
            const enrollmentPayload: any[] = findprescriber.data?.data?.prescriberRegStatuses;;
            if (
                enrollmentPayload?.filter(
                    (x) =>
                        x.registrationStatus.registrationStatus === 'Active' &&
                        x.id.productCountryId.productCountryId === selectedProgram.productCountryId
                ).length > 0
            ) {
                setContinueError('');
                return true;
            } else {
                return false;
            }
        }
    };
    const handleContinue = async () => {
        const isActive = await checkPrescriberStatus()
        if(isActive){
            setContinueError('')
            sessionStorage.removeItem('prevRoute')
            if(previousRoute.includes('physicianSearch')){
                //if navigated from main menu then this
                navigate('/prescriber/PhysicianOfficeDetails');
            }else if(!!Object.keys(surveyDataFromPatientDetails).length){
                const stateData = surveyDataFromPatientDetails.surveryErrMessage ? surveyDataFromPatientDetails.surveryErrMessage : surveyDataFromPatientDetails
            dispatch(setSurveyInfoFromRoute(stateData))
            dispatch(changePPAF(true));
            navigate('/prescriber/prescribersurveyinformation');
            }
            else if(isWritePrescription)
            {
                dispatch(deleteIsWritePrescription(false));
                navigate('/prescriber/prescriptionForm');
            }
            else {
                sessionStorage.setItem('patientInfo',JSON.stringify(previousRoute))
               navigate('/prescriber/patientSearch');
            }
        } else {
            setContinueError(t('error_PD0406').replaceAll('{program}', getProgramsName()));
        }

    };
    return (
        <>
        <SessionTimeout/>
        <div className={classes.container}>
                <div className={classes.headerContainer}>
                    {selectedProgram.id && <div className={classes.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></div>}
                    <div className={classes.headerDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                </div>
            {/* <div className={classes.header}></div> */}
            <div className={classes.productHeader}>{t('prescriberSearchHeader')}</div>
            <div className={classes.contentDivider}>
            <div className={classes.mainContainer}>
            { continueError && <Alert styles={{ margin: '3px 20px 5px 20px', fontSize: '0.67rem', padding: '10px' }} content={continueError} />}
                <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="firstName"
                    label={t('firstName')}
                    valueExist={prescriberDetails?.firstName + ' '}
                    containerStyle={{ gridTemplateColumns: '0.9fr 1.3fr'}}
                />
                 <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="lastName"
                    label={t('lastName')}
                    valueExist={prescriberDetails?.lastName + ' '}
                    containerStyle={{ gridTemplateColumns: '0.9fr 1.3fr'}}
                />
                 <Input
                    type="text"
                    noInputStyle={noInputStyle}
                    labelStyle={noInputStyle}
                    noInput={true}
                    name="prescriber"
                    label={t('pin')}
                    valueExist={prescriberDetails?.pin + ' '}
                    containerStyle={{ gridTemplateColumns: '0.9fr 1.3fr'}}
                />
                <div className={classes.btn_group}>
                   
                    <Button
                            clickFunction={handleContinue}
                            className={getBtnClass(selectedProgram.id)}
                            width={115}
                            height={45}
                            size={16}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.continue : buttonContent.en.continue}
                    ></Button>
                    <Button
                            clickFunction={handleNewPrescriberSearch}
                            className={getBtnClass(selectedProgram.id)}
                            width={i18n.language === 'es' ? 425 : 210}
                            height={45}
                            size={16}
                            type="text"
                            buttonname={i18n.language === 'es' ? buttonContent.es.newPrescSearch : buttonContent.en.newPrescSearch}
                    ></Button>
                </div>
            </div>
            </div>
        </div>
        </>
        
    );
};

export default PatientVerification;
