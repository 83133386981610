import { useRouteError } from 'react-router-dom';
import SystemError from 'components/SystemError';

const RootErrorBoundary = () => {
    let error = useRouteError() as Error;
    return (
        <div>
            <SystemError />
        </div>
    );
};

export default RootErrorBoundary;
