
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { fetchDropdown, setActionItemInfo, setPrescriberCalendarActionItemPayload, setPrescriptionPaylod, storeCalenderActionitemInfo } from "store/slices/appSlice";
import classes from './PrescriptionActionItems.module.scss';
import ErrorModal from "components/ErrorModal";
import SelectionDropdown from "./SelectionDropdown";
import Button from "components/Button";
import { authStatusCustomMapping } from "utils/dashboardStatusMapping";
import { axiosInstanceSearch } from "constants/Config/axiosConfig";
import { ProgramConfig } from "constants/Config/programsConfig";

const dropDowns = [
    'Please Select...',
    'REMS Program',
    'Status',
    'Prescriber Last Name',
    'Authorization Number',
];


const ActionItemFilter = ({ scrollToView, fields, setFields, setIsReset, setFilterData}) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { actionitemInfo = {}, calendarActionItemPayload = {}, prescriptionPaylod = [] } = useAppSelector((state) => state.app);
    const [prodDropdown, setProdDropdown] = useState<any[]>([]);
    const [statusDropdown, setStatusDropdown] = useState<any[]>([]);
    const [selectedFiter, setSelectedFiter] = useState(Object.entries(actionitemInfo)?.length > 0 ? actionitemInfo?.fields?.filterBy : t('PleaseSelect'));
    const [error, setError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [show, setShowDropdown] = useState(false);
    const [statusIds, setstatusIds] = useState<any[]>([]);

    const { currentUser } = useAppSelector((state) => state.login);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails?.prescriberId);

    const hasTextField = (selectedFiter === dropDowns[3] || selectedFiter === dropDowns[4]);

    const getPgmName = (name) => (name?.toUpperCase() === ProgramConfig('rems_program_thalomid').label.toUpperCase() ? name?.toUpperCase() : name);

    useEffect(() => {
        //setting intial value
        setstatusIds([]);
        setFields(() => ({
            isFiltered: false,
            filterBy: selectedFiter,
            selections: selectedFiter === dropDowns[1] ? ProgramConfig('rems_program_lenalidomide').label : ''
        }))
    }, [selectedFiter]);

    useEffect(() => {
        dispatch(fetchDropdown(['Product', 'AuthorizationStatus'])).then((response: any) => {
            if (response?.payload?.status === 200) {
                setProdDropdown(response?.payload?.data?.data?.Product);
                const authArr = authStatusCustomMapping(response?.payload?.data?.data?.AuthorizationStatus, 'actionitem');

                if (calendarActionItemPayload?.isFromCalender) {
                    // removing statusIds if not there in calender-action-API payload
                    setStatusDropdown(authArr?.filter((obj: any) => {
                        if (
                            obj?.id?.length > 1 &&
                            obj.id?.filter(
                                (eachId) => calendarActionItemPayload?.authStatusIds?.includes(Number(eachId))
                            )?.length > 0
                        ) {
                            return obj // incase of PSR
                        } else {
                            return calendarActionItemPayload?.authStatusIds?.includes(Number(obj?.id))
                        }
                    }))
                }
                else setStatusDropdown(authArr);
            }
        });

        if(selectedFiter === dropDowns[0]){
            setFilterData(() => ({
                isFilterActive : false,
                payload : {}
            }));
        }
    }, [calendarActionItemPayload, prescriptionPaylod]);

    useEffect(() => {
        //Retaining Filter -> if navigating to PSR or patient details
        //need to run once after navigating
        if (actionitemInfo?.isNavigate) {
            const payloadField = actionitemInfo?.fields;
            setFields((prev) => payloadField?.filterBy === selectedFiter ? payloadField : prev);
            setstatusIds((prev) => actionitemInfo?.fields?.filterBy === dropDowns[2] ? actionitemInfo?.authStatusId : prev);
        }
    }, []);

    const handleChangeFilterBy = (e) => {
        setShowDropdown(false);
        setSelectedFiter(e.target.value);
        dispatch(setActionItemInfo({ isNavigate: false, fields }));
    };

    const onhandleChange = (key, e) => {
        setShowDropdown(false);
        setFields(() => ({ isFiltered: false, filterBy: key, selections: e.target.value }));
    };

    const getCurrentField = (key) => {
        if (key === dropDowns[1]) {
            return (
                <select
                    value={fields['selections']}
                    onChange={(e) => onhandleChange(key, e)}
                    name="prog"
                    id="prog"
                >
                    {prodDropdown?.sort((a, b) => a.value.localeCompare(b.value))
                        ?.map((obj) => {
                            return <option key={obj.id} value={obj.value}> {getPgmName(obj.value)} </option>
                        })}
                </select>
            );
        }
        else if (key === dropDowns[2]) {
            return (
                <SelectionDropdown
                    data={statusDropdown}
                    setFields={setFields}
                    show={show}
                    setShowDropdown={setShowDropdown}
                    statusIds={statusIds}
                    setstatusIds={setstatusIds}
                />
            );
        }
        else if (hasTextField) {
            return (
                <input
                    id="textbox"
                    name="textbox"
                    value={fields['selections']}
                    onChange={(e) => onhandleChange(key, e)}
                    maxLength={30}
                />
            );
        }
        else return <div> </div>;
    };

    const trimInputfields = () => {
        const copyObj = { ...fields };
        if (hasTextField) {
            copyObj['selections'] = copyObj?.['selections']?.trim();
            setFields(copyObj);
        }
        return copyObj;
    };

    const validation = () => {
        scrollToView();
        const trimVal = trimInputfields();
        if (selectedFiter === t('PleaseSelect'))
            return true;
        else if (!trimVal?.['selections']?.length) {
            if (hasTextField) setCurrentFocus('textbox');
            setError(t('filterErr'));
            return false;
        } else if (hasTextField && !/^[\w\-'._ ]+$/.test(fields['selections'])) {
            setCurrentFocus('textbox');
            setError(t('filterErr'));
            return false;
        } else return true;
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        ['textbox'].includes(currentFocus) && inputElement.focus();
        ['textbox'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    let defaultAPIpayloadObj: any =
        calendarActionItemPayload?.isFromCalender ?
            {
                startDate: moment(calendarActionItemPayload?.payloadDate).format('MM/DD/YYYY'),
                endDate: moment(calendarActionItemPayload?.payloadDate).format('MM/DD/YYYY'),
                prescriberIds: calendarActionItemPayload?.prescriberIds,
                loggedInPrescId: !nonPrescriber ? prescriberId : null,
                loggedInNonPrescId: nonPrescriber ? currentUser.nonPrescriberId : null,
                productId: null,
                prescriberLastName: null,
                authorizationNumber: null,
                authorizationStatusIds: calendarActionItemPayload?.authStatusIds?.length > 0 ? calendarActionItemPayload?.authStatusIds : null,
                sortByField: null,
                sortByOrder: null,
                prescriber: !nonPrescriber,
            }
            :
            {
                prescriberNonPrescriberId: !nonPrescriber ? prescriberId : currentUser.nonPrescriberId,
                productId: null,
                authorizationNumber: null,
                authorizationStatus: null,
                assocPrescriberId: null,
                assocPrescriberName: null,
                authorizationStatusIds: null,
                sortByField: null,
                sortByOrder: null,
                page: 0,
                prescriber: !nonPrescriber,
            }

    const findHandler = async (e) => {
        e.preventDefault();
        setError('');
        setShowDropdown(false);
        const trimVal = trimInputfields();
        const isValid = validation();
        if (isValid) {
            const selectedVal = trimVal?.['selections'];

            if (calendarActionItemPayload?.isFromCalender) {
                const payloadObj = {
                    ...defaultAPIpayloadObj,
                    productId: selectedFiter === dropDowns[1] ? Number(prodDropdown?.find((item) => item?.value === selectedVal)?.id) : null,
                    authorizationNumber: selectedFiter === dropDowns[4] ? selectedVal : null,
                    authorizationStatusIds: selectedFiter === dropDowns[2] ? statusIds : calendarActionItemPayload?.authStatusIds,
                    prescriberLastName: selectedFiter === dropDowns[3] ? selectedVal : null,
                };
                dispatch(setPrescriberCalendarActionItemPayload({ ...calendarActionItemPayload, payload: payloadObj }))
                
                setFilterData(() => ({
                    isFilterActive : true,
                    payload : payloadObj
                }));
            } else {
                const payloadObj = {
                    ...defaultAPIpayloadObj,
                    productId: selectedFiter === dropDowns[1] ? Number(prodDropdown?.find((item) => item?.value === selectedVal)?.id) : null,
                    authorizationNumber: selectedFiter === dropDowns[4] ? selectedVal : null,
                    authorizationStatusIds: selectedFiter === dropDowns[2] ? statusIds : null,
                    assocPrescriberName: selectedFiter === dropDowns[3] ? selectedVal : null,
                };
                dispatch(setPrescriptionPaylod(payloadObj));

                setFilterData(() => ({
                    isFilterActive : true,
                    payload : payloadObj
                }));
            }
            setFields({ ...trimVal, isFiltered: true });
        }
    };

    const resetFormHandler = (e) => {
        e.preventDefault();
        const resetField = { isFiltered: false, filterBy: t('PleaseSelect'), selections: '' }       
        calendarActionItemPayload?.isFromCalender
            ? dispatch(setPrescriberCalendarActionItemPayload({ ...calendarActionItemPayload, payload: defaultAPIpayloadObj }))
            : dispatch(setPrescriptionPaylod(defaultAPIpayloadObj));
        dispatch(setActionItemInfo({ ...actionitemInfo, fields: resetField, pageNo: 0 }));
        setIsReset(true);
        setFields(resetField);
        setSelectedFiter(t('PleaseSelect'));
        setError('');
        setCurrentFocus('');
        setFilterData(() => ({
            isFilterActive : false,
            payload : {}
        }));
    };




    return (
        <>
            <form className={classes.actionFilterDiv} onSubmit={findHandler}>
                <p className={classes.italicText}>Filter by:</p>
                <select
                    onChange={handleChangeFilterBy}
                    value={selectedFiter}
                >
                    {dropDowns.map((str, id) => {
                        return <option key={id} value={str}> {str} </option>
                    })}
                </select>
                {getCurrentField(selectedFiter)}
                <div className={classes.btn}>
                    <Button
                        clickFunction={findHandler}
                        className="buttonStyles"
                        type="text"
                        width={74}
                        height={35}
                        size={14}
                        buttonname="Find"
                    />
                    <Button
                        clickFunction={resetFormHandler}
                        className="buttonStyles"
                        type="text"
                        width={74}
                        height={35}
                        size={14}
                        buttonname="Reset"
                    />
                </div>
            </form>
            <div className={classes.popupDiv}>
                {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            </div>
        </>
    );
};
export default ActionItemFilter;