import { useRef, useState } from 'react';
import classes from './migratePopUp.module.scss';
import Button from 'components/Button';

const MigratePopUp = ({ showModal, setShowModal }) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);

    return (
        showModal && (
            <div className={classes.modalContainer}>
                <dialog ref={modalRef} className={classes.mainContainerAccCreation}>
                    <button className={classes.closeBtn} onClick={() => setShowModal(false)}>
                        X
                    </button>

                    <div>
                        <p>
                            The BMS REMS Patient Safety Portal recently had a system update. If you have been able to
                            successfully login since Monday January 27th or if you are a patient, please disregard the
                            note below.{' '}
                        </p>
                        <h4>Action Required:</h4>
                        <ul>
                            <li className={classes.underLine}>
                                If you were sent a new password, and are still unable to login, please login with the
                                password that was successfully used before the system update (prior to Monday, January
                                27, 2025).
                            </li>
                            <li>
                                If you continue to experience login issues, please call 1-888-423-5436 (Monday through
                                Friday 8:00 AM to 8:00 PM ET).
                            </li>
                        </ul>

                        <p>
                            We appreciate your understanding and patience during this transition. We are working
                            diligently to resolve the issues. Thank you for your collaboration and support.
                        </p>
                        <div className={classes.footerBtn}>
                            <Button
                                className="buttonStyles"
                                type="text"
                                clickFunction={() => setShowModal(false)}
                                handleKeyPress={true}
                                buttonname="OK"
                                width={60}
                                height={36}
                                size={14}
                            ></Button>
                        </div>
                    </div>
                </dialog>
            </div>
        )
    );
};

export default MigratePopUp;
