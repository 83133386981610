import {ProgramConfig, lenalidomide,pomalidomide,thalomid} from 'constants/Config/programsConfig';
export const buttonContent = {
    es: {
        continue: 'Continuar',
        newppaf: 'Nuevo Formulario de acuerdo de paciente-médico/inscripción de paciente',
        savedPPaf: 'Trabajar con formulario de acuerdo de paciente-médico guardado/entregado',
        presSurvey: 'Encuesta de prescriptor ',
        prescSurveySubText:'Presione aquí para la próxima/nueva prescripción',

        stdPrescForm: 'Standard Prescription Form',
        vaPrescription: 'Veterans Health Administration Prescription Form',
        clickMore: 'Presione aquí para más opciones',
        patientSearch:'Buscar paciente',
        signSub:'Firmar y Enviar',
        save:'Guardar',
        print:'Imprimir',
        continueSave:'Continuar/Guardar',
        revise:'Revisar',
        next:'Siguiente',
        patSurvey:'Encuesta del Paciente',
        ppafOnline:'Procesar el formulario de acuerdo de paciente médico por internet',
        faxablePpaf:'Crear un formulario de acuerdo de paciente médico para enviar por FAX',
        partiallyComPpaf:'Trabajar con el formulario de acuerdo de paciente-médico parcialmente completo',
        viewPpaf:'Ver los formularios de acuerdo de paciente-médico entregados',
        editPatAdd:'Editar la dirección del paciente',
        createNewPpaf:'Crear un nuevo formulario de acuerdo de paciente-médico basado en el formulario de paciente-médico enviado',
        subTxtCreateNewPpaf:'(Reactivar el paciente)  (Reactivate Patient)',
        startSurvey:'Comenzar la encuesta',
        toSpanish:'Español',
        searchNewPat:'Buscar paciente nuevo',
        submit:'Enviar',
        startOver:'Comenzar nuevamente',
        vwPpaf:'Ver PPAF',
        prescriberSearch:'Búsqueda de prescriptor que receta',
        newPrescSearch:'Nueva búsqueda de prescriptor que receta',
        clickHereToContinue:"Haga clic aqui para continuar ➔",
        no:"No",
        yes:"Sí",
        dontKnow:"No sé",
        restart:"Volver a empezar la encuesta",
        cancelAndRestartSurvey:"Cancelar y reiniciar la encuesta",
        cancel:"Cancelar",
        previous:'Previous',
        returnPrescDashboard:"Return to Prescriber Dashboard"
    },
    en: {
        continue: 'Continue',
        newppaf: 'New PPAF/Patient Enrollment',
        savedPPaf: 'Work with Saved/ Submitted PPAF Forms',
        presSurvey: 'Prescriber Survey',
        stdPrescForm: 'Standard Prescription Form',
        vaPrescription: 'Veterans Health Administration Prescription Form',
        clickMore: 'Click here for more options',
        clickMorePrescSurvey:'Click here for new/next Rx',
        patientSearch:'Patient Search',
        signSub:'Sign and Submit',
        save:'Save',
        print:'Print',
        continueSave:'Continue/Save',
        revise:'Revise',
        next:'Next',
        patSurvey:'Patient Survey',
        ppafOnline:'Process the PPAF online',
        faxablePpaf:'Create a FAXable PPAF',
        writePres:'Write Prescription',
        partiallyComPpaf:'Work with Partially Completed PPAF',
        viewPpaf:'View Submitted PPAF Forms',
        editPatAdd:'Edit Patient Address',
        createNewPpaf:'Create New PPAF Based on Submitted PPAF',
        subTxtCreateNewPpaf:'Reactive Patient',
        startSurvey:'Start Survey',
        searchNewPat:'Search New Patient',
        submit:'Submit',
        startOver:'Start Over',
        gotoPresDashboard:'Go To Prescriber Dashboard',
        vwPpaf:'View PPAF',
        prescriberSearch:'Prescriber Search',
        newPrescSearch:'New Prescriber Search',
        clickHereToContinue:"Click here to continue ➔",
        no:"No",
        yes:"Yes",
        dontKnow:"Don't know",
        restart:"Restart",
        cancelAndRestartSurvey:"Cancel and Restart Survey",
        cancel:"Cancel",
        previous:'Previous',
        returnPrescDashboard:"Return to Prescriber Dashboard"
    }

};
export const getBtnClass = (selId) => {    
    return ProgramConfig(selId)?.buttonClass;
}
export const getBtnClassDisabled = () => {
    return 'prodBtnDisabled'
}
export const getProdBtnClassDisabled = (selId) => {
    return ProgramConfig(selId)?.disabledButton;
}
