import { useEffect, useRef, useState } from 'react';
import PageBackground from 'components/PageBackground';
import { LogDispenseScreens } from './logDispenseType';
import classes from './logDispense.module.scss';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import ConfirmationTable from './confirmationTable';
import { useNavigate } from 'react-router-dom';
import { resetLogMultipleDispense } from 'store/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'store';
import { logDispenseConfirmation } from 'assets/objects/logDispenseConfirmation';
import Alert from 'components/Alert';
import PrintConfirmationTable from './PrintConfirmationTable/printConfirmationTable';
import moment from 'moment';
import { Inactive_Pharmacy, Inactive_Pharmacy_UI, authStatusMap } from './logDispenseType';
import { getHTMLTemplate } from './getHtmlTemplate';
import { axiosInstanceSurvey } from 'constants/Config/axiosConfig';

const Confirmation = ({ handleCancelBtn, setScreen }) => {
    const printRef = useRef<HTMLDivElement | null>(null);
    const { logMultipleDispense } = useAppSelector((state) => state.app);
    const [print, setPrint] = useState(false);
    const [printData, setPrintData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    let errorMsg = '';

    const ActionMapper = (authNo) => {
        const { authorizationStatus, dispensationErrorMessage } = authNo;
        if (dispensationErrorMessage === Inactive_Pharmacy) {
            errorMsg = Inactive_Pharmacy_UI;
            return { id: 0, status: authStatusMap.get(authorizationStatus), action: 'See Message Above' };
        } else if (dispensationErrorMessage && authorizationStatus === 'Confirmed')
            return { id: 5, status: 'Confirmed', action: 'Contact REMS Call Center' };
        else
            switch (authorizationStatus) {
                case 'Obsolete':
                    return { id: 1, status: 'Authorization Number is no longer valid', action: 'Contact Prescriber' };
                case 'Mismatched':
                    return { id: 2, status: 'Survey-Flagged', action: 'Contact Prescriber' };
                case 'Expired':
                    return { id: 3, status: 'Authorization Number Expired', action: 'Contact Prescriber' };
                case 'Patient Survey Required':
                    return { id: 4, status: 'Patient Survey Required', action: 'Patient Survey Required' };
                case 'ConfirmedOld':
                    return { id: 5, status: 'Confirmed', action: 'Contact REMS Call Center' };
                case 'Confirmed':
                    return {
                        id: 6,
                        status: 'Confirmed',
                        action: 'Please write the confirmation number & date obtained on Rx',
                    };

                default:
                    return { id: 7, status: authorizationStatus, action: 'Contact REMS Call Center' };
            }
    };
    const onHandleContactUs = (actionText) => {
        if (actionText === 'Contact REMS Call Center')
            window.open(
                `${window.location.origin}/#/ContactUs`,
                'contactUs',
                'location=yes,height=400,width=820,scrollbars=yes,status=yes'
            );
    };

    const data = logMultipleDispense.authorizationsDetails.map((item, index) => {
        const authNo = logMultipleDispense.createDispenseData?.find(
            (x) => x.dispensation.authorizationNumber === item.authResponse.authorizationNumber
        );
        const authDetails = ActionMapper(authNo);
        const { id, status, action } = authDetails;
        return {
            key: index,
            checked: false,
            Confirmation: authNo?.dispensation?.confirmationNumber?.slice(2) || null,
            ProductIdentification: item.product.map((el) => (
                <div className={classes.products}>
                    <div>{el.product_id.value}</div>
                </div>
            )),
            Quantity: item.product.map((el) => <div>{Number(el.quantity.quantity)}</div>),
            AuthorizationNumber: (
                <span className={`${id === 6 ? classes.auth_no : classes.error}`}>{item.auth_no.auth_no}</span>
            ),
            AuthorizationStatus: (
                <span style={{ fontWeight: '700' }} className={classes.auth_status}>
                    {status}
                </span>
            ),
            ConfirmationDate: authNo?.dispensation?.dispensationDate
                ? moment(authNo?.dispensation?.dispensationDate).format('MMM DD,YYYY')
                : null,
            ActionRequired: (
                <div className={classes.userAction}>
                    <span className={classes.required}>!</span>
                    <span
                        className={`${
                            id === 5 || id === 7
                                ? classes.links
                                : id === 6
                                ? classes.products
                                : id === 0
                                ? classes.hidePrint
                                : classes.action
                        } ${id === 0 ? classes.hidePrint : id === 6 ? '' : classes.error}`}
                        onClick={() => onHandleContactUs(action)}
                    >
                        {action}
                    </span>
                </div>
            ),
            ActionRequiredPrint: (
                <div className={classes.userAction}>
                    <span style={id === 0 ? { display: 'none' } : { paddingRight: '3px' }} className={classes.required}>
                        !
                    </span>
                    <span
                        style={id === 0 ? { display: 'none' } : {}}
                        className={`${
                            id === 5 || id === 7
                                ? classes.links
                                : id === 6
                                ? classes.products
                                : id === 0
                                ? classes.hidePrint
                                : classes.action
                        } ${id === 0 ? classes.hidePrint : classes.error}`}
                        onClick={() => onHandleContactUs(action)}
                    >
                        {action}
                    </span>
                </div>
            ),
            patientName: `${item.authResponse.authorization.patientNameFirst} ${item.authResponse.authorization.patientNameLast}`,
            patientDOB: moment(item.authResponse.authorization.patientDob).format('MMM DD,YYYY'),
            authExpiryDate: moment(item.authResponse.authorization.activeStatusExpirationDate).format('MMM DD,YYYY'),
            riskCategory: authNo?.dispensation?.productRiskClassCategoryId?.riskClassCategoryId?.lookupValue,
            Dispense: item.product.map((el) => ({ product_id: el.product_id.value, quantity: Number(el.quantity.quantity) })),
            printRow: {
                patientName:
                    `${item.authResponse.authorization.patientNameFirst} ${item.authResponse.authorization.patientNameLast}` ||
                    null,
                dob: moment(item.authResponse.authorization.patientDob).format('MMM DD YYYY') || null,
                riskCategory:
                    authNo?.dispensation?.productRiskClassCategoryId?.riskClassCategoryId?.lookupValue || null,
                authorizationNumber: item.auth_no.auth_no || null,
                authorizationStatus: status || null,
                expirationDate:
                    moment(item.authResponse.authorization.activeStatusExpirationDate).format('MMM DD YYYY') || null,
                confirmationNumber: authNo?.dispensation?.confirmationNumber?.slice(2) || null,
                confirmationDate: authNo?.dispensation?.dispensationDate
                    ? moment(authNo?.dispensation?.dispensationDate).format('MMM DD YYYY')
                    : null,
                actionRequired: action || null,
                productQuantity: item.product.map((el) => ({
                    ndcDescription: el.product_id.value || null,
                    quantity: Number(el.quantity.quantity) || null,
                })),
            },
        };
    });

    const handlePrint = () => {
        setPrint(true);
    };
    
    useEffect(() => {
        if (printRef.current) {
            const printWindow = window.open('', '_blank', 'width=900,height=600');
            if (!printWindow) return;
            const tableHTML = getHTMLTemplate(printRef.current.outerHTML);
            printWindow.document.write(tableHTML);
            printWindow.document.close();
            printWindow.onload = () => {
                printWindow.print();
                //printWindow.close();
                setPrint(false);
            };
        }
    });

    const onFinishHandler = () => {
        dispatch(resetLogMultipleDispense());
        navigate('/pharmacy/PharmacyHome');
    };

    const logNewDispense = () => {
        dispatch(resetLogMultipleDispense());
        setScreen(LogDispenseScreens.SelectDispense);
    };

    const onPrintRow = async () => {
        if (printData.length === 0) return;
        else {
            try {
                const response = await axiosInstanceSurvey.post(
                    '/v1/dispensation/generate-dispensation-pdf',
                    printData
                );
                if (response.data) {
                    sessionStorage.setItem('baseString', JSON.stringify(response.data));
                    sessionStorage.setItem('fileName', JSON.stringify('logdispense'));
                    window.open('/#/pdfViewer', '_blank');
                } else {
                    navigate('/prescriber/SystemError');
                }
            } catch (err) {
                console.error(`Error getting pdf ${err}`);
            }
        }
    };

    return (
        <>
            <PageBackground label={'Log Dispense Confirmation'} dispense={true}>
                <div className={classes.pageHeader}>Log Dispense Confirmation</div>
                <div className={classes.confirmation}>
                    <div className={classes.header}>
                        <div
                            className={classes.bold}
                            dangerouslySetInnerHTML={{ __html: logDispenseConfirmation.body[0].content }}
                        />
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.contentPadding}>
                            <div className={classes.textPadding}>
                                <div className={`${classes.required} ${classes.bold}`}>
                                    <span
                                        className={`${classes.required} ${classes.timeoutText}`}
                                        dangerouslySetInnerHTML={{ __html: logDispenseConfirmation.body[1].content }}
                                    />
                                </div>
                                {errorMsg && (
                                    <div className={classes.alert}>
                                        <Alert content={errorMsg} />
                                    </div>
                                )}
                                <div className={classes.printRow}>
                                    <input
                                        className={classes.printRow_checkbox}
                                        type="checkbox"
                                        disabled={true}
                                        checked={true}
                                    />
                                    <Button
                                        clickFunction={onPrintRow}
                                        className="mr10"
                                        width={110}
                                        height={40}
                                        size={16}
                                        type="text"
                                        buttonname="Print Row"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.confirmTable}>
                            <ConfirmationTable data={data} setPrintData={setPrintData} />
                        </div>
                    </div>
                    <div className={classes.footer_btn}>
                        <div className={classes.btn_group}>
                            <Button
                                clickFunction={onFinishHandler}
                                className="mr10"
                                width={160}
                                height={48}
                                size={18}
                                type="text"
                                buttonname="Finish"
                            />
                            <Button
                                clickFunction={handlePrint}
                                className="mr10"
                                width={120}
                                height={48}
                                size={18}
                                type="text"
                                buttonname="Print"
                            />
                            <Button
                                clickFunction={logNewDispense}
                                className="mr10"
                                width={200}
                                height={48}
                                size={18}
                                type="text"
                                buttonname="Log New Dispense"
                            />
                        </div>

                        <div className={classes.AdobeInformation}>
                            <p>
                                {t('print')}
                                <span className={classes.legalSymbol}>®</span> {t('reader')}
                                <span className={classes.legalSymbol}>®</span>. {t('download')}
                            </p>
                            <a href="https://get.adobe.com/reader/" target="_blank">
                                <span className={classes.getAdobeReaderBtn}></span>
                            </a>
                        </div>
                    </div>
                </div>
            </PageBackground>
            {print && (
                <div className={classes.printTable} ref={printRef}>
                    <PrintConfirmationTable tabData={data} />
                </div>
            )}
        </>
    );
};

export default Confirmation;
