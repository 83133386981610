export const patientSearchFields = [
    {
        name: 'lastName',
        errMsg: 'patientSearchLastName',
    },

    {
        name: 'patientIdNum',
        errMsg: 'patientPatientIdError',
    },

    {
        name: 'month',
        errMsg: 'patientDOBError',
    },
    {
        name: 'date',
        errMsg: 'patientDOBError',
    },
    {
        name: 'year',
        errMsg: 'patientDOBError',
    },
];