import React from 'react';
import classes from './ReviewAuthorization.module.scss';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { setErrorLogin } from 'store/slices/loginSlice';
import { useAppDispatch } from 'store';
import ErrorModal from 'components/ErrorModal';
import { useTranslation } from 'react-i18next';
import searchBtn from '../../../assets/images/btn_search_blue.png';
import ReviewAuthPatientData from '../ReviewAuthPatientData';
import { pharmacyReviewAuth } from 'store/slices/appSlice';
import {reloadFunction} from '../../../utils/utilsCommon';

const ReviewAuthorization = () => {

    const [authnumber, setAuthNumber] = useState('');
    const [errorForm, setError] = useState('');
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState(['']);
    const [servererror, setServerError] = useState('');
    const { t, i18n } = useTranslation();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isAuthData, setIsAuthData] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [apiError, setApiError] = useState(false);

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const emptyRow = () => {
        return (
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        );
    };

    const handleAuthNumber = async (e) => {
        const isDigitsOnly = /^[0-9]+$/.test(authnumber);
        const payload = {
                authorizationNumber: authnumber,
            };

        e.preventDefault();
        dispatch(setErrorLogin(''));
        servererror && setServerError('');
        if (authnumber.length === 0 || (!isDigitsOnly)) {
                    setError('Please enter a valid Authorization Number.');
                    return;
                }     
     
        else{
        dispatch(pharmacyReviewAuth(payload)).then((e: any) => {
            setAuthNumber('');
            if (e.payload?.data?.statusCode !== 200) {
                setApiError(true);
                setAlertContent('No Authorization Number found.');
                setIsAuthData(false);
            }
            if (e.payload?.data?.statusCode === 200) {
                setIsAuthData(true);
            }
            });
            }
        
       
    }
    const handleErrorBtn = () => {
        const focusName =  'authnumber';
        focusField(focusName);
        setError('');
    };

    const focusField = (name) => {
        const inputElement = document?.getElementById(name) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
    };

    const handleOnChange = (e) => {
        setApiError(false);
        errorForm && setError('');
        setAuthNumber(e.target.value);
    };
    useEffect(() => {
        reloadFunction();
    }, []);

    return (
        <div style={{ marginTop: '10px' }} className={classes.reviewAuthorization}>
          {  
          <PageBackground label="Review Authorization"> 
                <>
                    <SessionTimeout />               
                    
                    <div className={classes.selectREMSProgram}>
                        <p>Please enter a valid Authorization Number and select the Search button.</p>
                    </div>
                    <div className={classes.contentContainer}>
                    {apiError && <Alert content={alertContent} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.selectREMSProgram}>
                            <label htmlFor="authnumber">Authorization Number: </label>
                            <input
                                onKeyDown={(e) => e.key === 'Enter' && handleAuthNumber(e)}
                                type="text"
                                id="authnumber"
                                name="authnumber"
                                onChange={handleOnChange}
                                maxLength={10}
                                value={authnumber}
                                 />
                        </div>                   
                    </div>
                    
                    <div className={classes.buttonWrapper}>
                        <div className="searchButton">
                            <img
                                src={searchBtn}
                                style={{ cursor: 'pointer' }}
                                alt="search-button-for-auth-data"
                                onClick={handleAuthNumber}
                                itemType='submit'
                            />
                        </div>
                    </div>
                    {errorForm && <ErrorModal error={errorForm} handleContinue={handleErrorBtn} />}

                    {isAuthData && <ReviewAuthPatientData />}
                             
                </>
            </PageBackground>
            }
        </div>
    );

};

export default ReviewAuthorization;
