
import classes from '../logDispense.module.scss';
import { Table } from 'antd';
import ExpandRow from './expandRow';


const PrintTable = ({ReviewDataSource}) => {

	const reviewColumns: any = [
		{
			title: 'Pharmacy Location',
			dataIndex: 'PharmacyLocation',
			key: 'PharmacyLocation',
		},
		{
			title: 'Authorization #',
			dataIndex: 'Authorization',
			key: 'Authorization',
			width:'20%'
		},
		{
			title: 'Product Identification ',
			dataIndex: 'ProductIdentification',
			key: 'ProductIdentification',
		},
		{
			title: 'Quantity',
			dataIndex: 'Quantity',
			key: 'Quantity',
		},
		{
			title: 'Patient Name',
			dataIndex: 'PatientName',
			key: 'PatientName',
		},
	];

	return (
		<>
			<div>
				<p style={{fontWeight:'bold'}}>Log Dispense Review</p>
				<Table
					
					dataSource={ReviewDataSource}
					columns={reviewColumns}
					expandable={{
						expandedRowRender: ExpandRow,
						defaultExpandAllRows: true,
						expandIconColumnIndex: -1,
						expandIcon: () => null,
					}}
					pagination={false}
				/>
			</div>
		</>
	);
};

export default PrintTable;
