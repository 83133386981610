import moment from 'moment';

export const getHiddenReports = (data) => {
    
    const tableheader = ['Patient Name', 'REMS Program', 'Prescriber Name'];
    const date = moment().tz('America/New_York');
    const fdate = date.format('ddd MMM DD HH:mm:ss z YYYY');
    
    return `
    <html>
        <head>
            <title>${window.location.href}</title>
            <style>
          
            html{
                overflow-y: auto;
            }

            body{
                height: 95vh;
                overflow-y: auto;
                margin:0;
                @media print{
                    height: auto;
                }
            }
                .tablewidth{
                    @media print{
                        width: 719px
                }
            }
                tbody tr th,
                tbody tr td{
                    font - size:14px;
            }
                .header{
                    font-size:2em;
                    transform: translateY(15px);
            }
                .bottom{
                    font - size: 13px;
            }
                .actionTitle {
                    font-size: 16px;
                    margin: 0;
            } 

            th{
                &:first-child{
                    width: 38%;                       
                }
                &:nth-child(2), &:last-child{
                        width: 31% !important;
                }  
            }
            
            tr {
                min-height: 38px;
                max-height: max-content;             
            }
                    
            </style>
        </head>
        <body>
            <div class="header">
                <span class="icon logo left"></span>
                <div align="center">Hidden Patient Report</div>
                <p align="center" class="actionTitle">${fdate}</p>
                <br>
                <div class="buffer clear"></div>
            </div>

            <table width="735px" cellpadding="5" cellspacing="0" style="border-color:black;" border="1" align="center" class="tablewidth">
                <thead>
                    <tr valign="top">
                        ${tableheader.map((item)=>{
                            return `<th align="left">
                            ${item}
                            </th>`}).join('')
                        }
                    </tr>
                </thead>

                <tbody>
                ${!!data?.length ?
                    data?.map((item)=>{
                    return ` 
                    <tr valign="top" class="rowTable" id=${item.rnum}>
                        <td align="left"> ${item?.patientNameLast?.toUpperCase() + ', '} ${item?.patientNameFirst?.toUpperCase() + ' '} </td>
                        <td align="left"> ${item?.productName} </td>
                        <td align="left"> ${item?.assocPrescriberNameLast + ' ' + item?.assocPrescriberNameFirst} </td>
                    </tr>`}).join('')
                    :
                    `<tr valign="top" class="rowTable">
                        <td colspan="14" align="center"> ${data.errMsg} </td>
                    </tr>  `
                } 
                </tbody>
                

            </table>
            <br>
            <br>
            <div class="bottom" align="center">Bristol Myers Squibb</div>
        </body>
    </html>`
        ;

}