import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import ActionItemTable from './ActionItemTable';
import classes from './PrescriptionActionItems.module.scss';
import ActionItemFilter from './ActionItemFilter';
import { setActionItemInfo } from 'store/slices/appSlice';
import { printAction } from './printAction';


const PrescriptionActionItems = ({ tabNumb }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { patientDataFromCalender = [] } = useAppSelector((state) => state.app);
    const [fields, setFields] = useState<any>({ isFiltered: false, filterBy: t('PleaseSelect'), selections: '' });
    const [refereshTab, setRefereshTab] = useState(false);
    const [isReset, setIsReset] = useState<any>(false);
    const [patientData, setPatientDatas] = useState<any>([]);
    const [errMsg, setErrMsg] = useState<any>();
    const scrollToView = () => { 
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [filterData, setFilterData] = useState<any>({ isFilterActive: false, payload: {} }) 

    useEffect(() => {
        scrollToView();
        setRefereshTab(Number(tabNumb) === 1 ? true : false)
        if (Number(tabNumb) !== 1) dispatch(setActionItemInfo({}));
    }, [tabNumb]);

    const printThisPageHandler = () => {
        const data = {
            headings: [
                { name: 'Patient Name' },
                { name: 'REMS Program' },
                { name: 'Authorization', subHeading: ['Number', 'Expires'] },
                { name: 'Status and Action Required' },
                { name: 'Prescriber Name' }
            ],
            patientData,
            errMsg: errMsg,
        };
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML: any = printAction(data);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };

    if (refereshTab) {
      
        return (
            <div>
                <span className={`${classes.findPatientPrintBtn}`} onClick={printThisPageHandler}>
                    {t('printPg')} <b>&#x1F892;</b>
                </span>
                <p className={classes.headingInfo}>{t('prescriptionactioninfo')}</p>
                <ActionItemFilter
                    scrollToView={scrollToView}
                    fields={fields}
                    setFields={setFields}
                    setIsReset={setIsReset}
                    setFilterData = {setFilterData}                
                />
                <ActionItemTable
                    fields={fields}
                    isReset={isReset}
                    setIsReset={setIsReset}
                    patientData={patientData}
                    setPatientDatas={setPatientDatas}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                    tabNumb={tabNumb}
                    filterData = {filterData}
                    
                />
            </div>
        )
    } else {
        return <div></div>
    }
};
export default PrescriptionActionItems;
