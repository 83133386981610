import React from 'react';
import classes from './pinHelp.module.scss'
import Footer from 'components/Footer';

const PinHelp = () => {
  return (
    <div className={classes.layout}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerContainer}>
                        <span className={classes.icon}></span>
                        <div className={classes.title}>REMS Help</div>
                    </div>
                </div>
                <div className={classes.helpContainer}>
                    <div className={classes.bold}>
                        New Prescriber enrollment page
                    </div>
                    <div>
                        <ul>
                            <li>
                            Fields marked with an asterisk (*) are required.
                            </li>
                            <li>
                            The Prescriber Identification Number can be either your DEA number, 
                            Social Security Number or NPI. The Prescriber Identification Number is a required component
                             to ensure your identity is secured throughout the Bristol Myers Squibb-administered REMS program.
                            </li>
                            <li>
                            Details for up to 2 office locations can be included. If you require additional locations
                             to be included in your profile,
                             please contact the REMS Call Center to add locations.
                            </li>
                            <li>
                            The Primary Office Location will be the default listed location throughout the online system. Additional office locations you enter will be accessible through a dropdown menu throughout the website, e.g. patient enrollment.
                            </li>
                            <li>
                            The e-mail address you provide will only be used to contact you regarding issues with your online account and will not be used for marketing purposes. If you need to change the e-mail address on file, please contact the REMS Call Center.
                            </li>
                            <li>
                            Click the Continue button to process the information provided.
                            </li>
                            <li>
                            Please, do not click your browser's Back button as this can cause a page loading or processing error.
                            </li>
                            <li>
                            Do you have a question regarding the REMS Patient Safety website for Bristol Myers Squibb-administered REMS programs? Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
  )
}

export default PinHelp;