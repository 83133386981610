
import classes from './PrescriberDashboardReports.module.scss';
import { useEffect, useRef, useState } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import ErrorModal from 'components/ErrorModal';
import SessionTimeout from 'components/SessionTimeout';
import { useNavigate } from 'react-router-dom';
import {
    getNonPrescAssociation,
    getPrescAssociation,
    hiddenReportAPI,
    histAuthReportAPI,
    runReport,
} from 'store/slices/appSlice';
import { useAppSelector, useAppDispatch } from 'store';
import Button from 'components/Button';
import ReportsTable from './ReportsTable';
import { useReactToPrint } from 'react-to-print';
import { getReportsPrintPopUpTemplate } from './getReportsPrintPopUpTemplate';
import {getReportsPrintHistAuthTemplate} from './getReportsPrintHistAuthTemplate';
import { RoleByGroup } from 'constants/Config/appConfig';
import btnClosePopup from '../../assets/images/btnClosePopup.png';
import HiddenPatientTable from './ReportsTable/HiddenPatientTable';
import HistoricalReportsTable from './ReportsTable/HistoricalReportTable';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getHiddenReports } from './getHiddenReports';
import { PROGRAMS } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';

const PrescriberDashboardReports = ({ reportType }) => {
    const getRemsProgram = ()=>{
        let prgms:any=[];
        PROGRAMS.forEach((i)=>{
            prgms.push({
                id:i.productCountryId,
                name: i.registeredTradermark ? i.label.toUpperCase() : i.label,
                checked:false
            })
        })
        return prgms;
    }
    const remsProgram = getRemsProgram();
   
    const { currentUser } = useAppSelector((state) => state.login);
    const { prescAllDetails } = useAppSelector((state) => state.app);
    const prescriberId = currentUser?.systemId || currentUser.userinfo['custom:prescriber_user_id'];
    const isNonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const currentUserFullName = prescAllDetails?.prescriberNameFirst + ' ' + prescAllDetails?.prescriberNameLast;
    const onlineUserId = currentUser.onlineUserId;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [currentFocus, setCurrentFocus] = useState('');
    const [selectedAuthStatus, setselectedAuthStatus] = useState('');
    const [authStatusAPI, setAuthStatusAPI] = useState('');
    const [apiPayload, setApiPayload] = useState({});
    const [checkedItems, setCheckedItems] = useState(remsProgram);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isHiddenPatientApiCalled, setIsHiddenPatientApiCalled] = useState(false);
    const [error, setError] = useState('');
    const [checkedItemsPrescriber, setCheckedItemsPrescriber] = useState<any>([]);
    const [isAllCheckedPrescriber, setIsAllCheckedPrescriber] = useState(false);
    const [isSortedField, setIsSorted] = useState({ isSorted: false, content:null, orderby: '' });
    const [fetchReport, setFetchReport] =  useState<any>([]);
    const [noData, setNoData] = useState(false);
    const [inputData, setInputData] = useState({});

    useEffect(() => {
        const payload = isNonPrescriber
            ? { onlineUserId: onlineUserId }
            : {
                  prescriberId: prescriberId,
              };

        const currUser = {
            prescriberId:
                currentUser.groupTag == RoleByGroup.NonPrescriber ? prescAllDetails?.prescriberId : prescriberId,
            fullName:
                currentUser.groupTag === RoleByGroup.NonPrescriber
                    ? prescAllDetails?.prescriberNameFirst + ' ' + prescAllDetails?.prescriberNameLast
                    : currentUserFullName,
            checked: false,
        };

        const thunkCaller = isNonPrescriber ? getNonPrescAssociation : getPrescAssociation;

        dispatch(thunkCaller({ data: payload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                const getPrescriberAssociation = () => {
                    return (
                        isNonPrescriber
                            ? e.payload?.data?.data?.associatedPrescribers
                            : e.payload?.data?.data?.parentPrescribers
                    )?.map((item) => ({
                        prescriberId: item?.prescriberId,
                        fullName: item?.firstName + ' ' + item?.lastName,
                        checked: false,
                    }));
                };
                const mergePrescriber = isNonPrescriber
                    ? getPrescriberAssociation()
                    : [currUser, ...getPrescriberAssociation()];
                setCheckedItemsPrescriber(mergePrescriber);
                setNoData(false);
            } else {
                if (!isNonPrescriber) {
                    // isPrescriber, hv no presc/nonpresc associations
                    setCheckedItemsPrescriber([currUser]);
                    setNoData(false);
                } else {
                    setCheckedItemsPrescriber([]);
                    setNoData(true);
                }
            }
        });
    }, []);
    useEffect(() => {
        reloadFunction();
    }, []);

    const handleChange = (event) => {
        setselectedAuthStatus(event.target.value);
    };

    const handleStartDateChange = (e) => {
        if (e?.target === undefined) {
            setStartDate(e);
            return;
        } else {
            const checkDate = moment(e.target.value, 'MM/DD/YYYY').isValid();
            if (checkDate) {
                setStartDate(e.target.value);
            } else {
                setStartDate(null);
                return;
            }
        }
    };

    const handleEndDateChange = (e) => {
        if (e?.target === undefined) {
            setEndDate(e);
            return;
        } else {
            const checkDate = moment(e.target.value, 'MM/DD/YYYY').isValid();
            if (checkDate) {
                setEndDate(e.target.value);
            } else {
                setEndDate(null);
                return;
            }
        }
    };

    const handleSelectAll = () => {
        let newCheckedItems: any = [...checkedItems];
        newCheckedItems = newCheckedItems.map((item) => {
            return { ...item, checked: !isAllChecked };
        });
        setCheckedItems(newCheckedItems);
        setIsAllChecked(!isAllChecked);
    };

    const handleCheckboxChange = (Item) => {
        let newCheckedItems: any = [...checkedItems];
        newCheckedItems = newCheckedItems.map((item) => {
            if (item?.id === Item?.id) {
                return { ...item, checked: !item?.checked };
            }
            return item;
        });
        setCheckedItems(newCheckedItems);
        if (newCheckedItems.some((v) => v.checked === false)) {
            setIsAllChecked(false);
        } else {
            setIsAllChecked(true);
        }
    };

    const handleSelectAllPrescriber = () => {
        let newCheckedItems: any = [...checkedItemsPrescriber];
        newCheckedItems = newCheckedItems.map((item) => {
            return { ...item, checked: !isAllCheckedPrescriber };
        });
        setCheckedItemsPrescriber(newCheckedItems);
        setIsAllCheckedPrescriber(!isAllCheckedPrescriber);
    };

    const handleCheckboxChangePrescriber = (Item) => {
        let newCheckedItems: any = [...checkedItemsPrescriber];
        newCheckedItems = newCheckedItems.map((item) => {
            if (item?.prescriberId === Item?.prescriberId) {
                return { ...item, checked: !item?.checked };
            }
            return item;
        });
        setCheckedItemsPrescriber(newCheckedItems);
        if (newCheckedItems.some((v) => v.checked === false)) {
            setIsAllCheckedPrescriber(false);
        } else {
            setIsAllCheckedPrescriber(true);
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement?.focus();
        inputElement?.select();
        setError('');
        setCurrentFocus('');
    };

    const runReportAPIcall = async () => {
        let payload = {},
            apiName: any;
        const prescIdsArr = checkedItemsPrescriber
            ?.filter((v) => v.checked === true)
            ?.map((v) => Number(v?.prescriberId));

        switch (reportType) {
            case '1':
                apiName = runReport;
                payload = {
                    programCountryIds: checkedItems?.filter((v) => v.checked === true)?.map((v) => v?.id),
                    prescriberIds: prescIdsArr,
                    startDate: moment(startDate).format('MM/DD/YYYY'),
                    endDate: moment(endDate).format('MM/DD/YYYY'),
                };
                break;

            case '2':
                apiName = histAuthReportAPI;
                payload = {
                    "startDate": moment(startDate).format('MM/DD/YYYY'),
                    "endDate": moment(endDate).format('MM/DD/YYYY'),
                    "programCountryIds": checkedItems?.filter(v => v.checked === true)?.map(v => v?.id),
                    "prescriberIds": prescIdsArr,
                    "authorizationStatus": selectedAuthStatus,
                    "sortByField":  'PATIENTLASTNAME',
                    "sortByOrder": 'ASC',
                };
                break;

            case '3':
                apiName = hiddenReportAPI;
                payload = {
                    loggedInPrescId: !isNonPrescriber ? prescriberId : null,
                    loggedInNonPrescId: isNonPrescriber ? currentUser.nonPrescriberId : null,
                    productCountryIds: checkedItems?.filter((v) => v.checked === true)?.map((v) => v?.id),
                    prescriberFilterIds: !!prescIdsArr?.length ? prescIdsArr : null,
                    sortByField: isSortedField?.isSorted ? isSortedField?.content: 'PATIENTLASTNAME',
                    sortByOrder: isSortedField?.isSorted ? isSortedField?.orderby: 'ASC',
                };
                break;
        }

        setApiPayload(payload);
        dispatch(apiName({ data: payload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                setFetchReport(e.payload?.data?.data);
                reportType === '3' && setIsHiddenPatientApiCalled(true);
                reportType === '2' && setAuthStatusAPI(selectedAuthStatus);
            }
        });
    };

    const handleRunReport = () => {
        const checkCheckedItems = checkedItems.some((v) => v.checked === true);
        const checkCheckedPrescriber = checkedItemsPrescriber.some((v) => v.checked === true);
        const currentDate = moment().format('MM/DD/YYYY')
        if (reportType !== '3') {
            if (startDate === null) {
                setError('Please select a date range.');
                setCurrentFocus('datepickerFrom');
                return;
            }
            if (endDate === null) {
                setError('Please select a date range.');
                setCurrentFocus('datepickerTo');
                return;
            }
            if (!moment(startDate).isValid()) {
                setError('Please select a valid date range.');
                setCurrentFocus('datepickerFrom');
                return;
            }
            if (!moment(endDate).isValid()) {
                setError('Please select a valid date range.');
                setCurrentFocus('datepickerTo');
                return;
            }

            if (reportType === '1') {
                if (moment(startDate).isBefore(currentDate)) {
                    setError('Please select a valid date range.');
                    setCurrentFocus('datepickerFrom');
                    return;
                }

                if (moment(endDate).isBefore(startDate)) {
                    setError('Please select a valid date range.');
                    setCurrentFocus('datepickerTo');
                    return;
                }
            } else {
                if (moment(startDate).isAfter(currentDate)) {
                    setError('Please select a valid date range.');
                    setCurrentFocus('datepickerFrom');
                    return;
                }
                if (moment(endDate).isAfter(currentDate) || moment(endDate).isBefore(startDate)) {
                    setError('Please select a valid date range.');
                    setCurrentFocus('datepickerTo');
                    return;
                }
            }
        }
        if (!selectedAuthStatus && reportType === '2') {
            setError('Please select a status.');
            return;
        }

        if (!checkCheckedItems) {
            setError('Please select one or more programs.');
            return;
        }

        if (!checkCheckedPrescriber && reportType !== '3') {
            setError('Please select one or more Prescribers.');
            return;
        }

        runReportAPIcall();
        const selectedData = {
            startDate: moment(startDate).format('MM/DD/YYYY'),
            endDate: moment(endDate).format('MM/DD/YYYY'),
        };
        selectedData['programs'] = checkedItems?.filter((v) => v.checked === true).map((v) => v?.name);
        selectedData['prescribers'] = checkedItemsPrescriber?.filter((v) => v.checked === true).map((v) => v?.fullName);
        setInputData(selectedData);
    };

    const componentRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const printThisPageHandler = () => {
        const printWindow = window.open(window.location.href, '_blank', 'location=yes,width=892,height=502,scrollbars=yes,status=yes');
        if (!printWindow) return;
        let tableHTML: any;
        if(reportType === '2')
        {
             tableHTML = getReportsPrintHistAuthTemplate(fetchReport,authStatusAPI);
        } else if (reportType === '3'){
            tableHTML = getHiddenReports(fetchReport);
        }
        else{
             tableHTML = getReportsPrintPopUpTemplate(fetchReport);
        }
        
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };

    const getTable = () => {
        switch (reportType) {
            case '1':
                return (
                    <ReportsTable
                        setIsSorted={setIsSorted}
                        fetchReportData={fetchReport}
                        noData={noData}
                        reportInpData={inputData}
                    />
                );
            case '2':
                return <HistoricalReportsTable setIsSorted={setIsSorted} setFetchReport={setFetchReport} fetchReportData={fetchReport} noData={noData} reportInpData={inputData} authStatus={authStatusAPI} apiPayload={apiPayload} />;
            case '3':
                return <HiddenPatientTable setIsSorted={setIsSorted} setFetchReport={setFetchReport} fetchReportData={fetchReport} noData={noData} reportInpData={inputData} isApiCalled={isHiddenPatientApiCalled} apiPayload={apiPayload} />;
            default:
                <></>;
        }
    };

    return (
        <div>
            {!noData && (
                <div>
                    <SessionTimeout />
                    <div className={classes.reportContainer}>
                        {fetchReport?.length > 0 && (
                            <span className={`${classes.reportPrintBtn}`} onClick={printThisPageHandler}>
                                Print this page <b>&#x1F892;</b>
                            </span>
                        )}
                        <div className={classes.note}>
                            Fields marked with an <span className={classes.required}>* </span>are required.
                        </div>
                        {reportType !== '3' && (
                            <Row className={classes.formReport}>
                                <div className={classes.date1}>
                                        <>
                                            <CustomDatePicker
                                                selectedDate={startDate}
                                                setSelectedDate={setStartDate}
                                                label={'Date From:'}
                                                id="datepickerFrom"
                                            />
                                        </>
                                </div>
                                {reportType === '2' && (
                                    <>
                                        <span className={classes.mandatory}>Authorization Status:</span>
                                        <select
                                            id="authStatus"
                                            value={selectedAuthStatus}
                                            onChange={handleChange}
                                            className={classes.authStatus}
                                        >
                                            <option value="">Please Select...</option>
                                            <option value="Confirmed">Pharmacy Confirmed Rx</option>
                                            <option value="Expired">Expired</option>
                                            <option value="Obsolete">Cancelled</option>
                                        </select>
                                    </>
                                )}
                                <div style={{ marginLeft: reportType === '2' ? '32px' : '48px' }}>
                                    <CustomDatePicker
                                        selectedDate={endDate}
                                        setSelectedDate={setEndDate}
                                        label={'Date To:'}
                                        id="datepickerTo"
                                    />
                                </div>
                            </Row>
                        )}
                        <Row className={classes.row2}>
                            <>
                                <span className={classes.mandatory}>REMS Program(s):</span>
                                <div
                                    className={classes.selectionBoxProgram}
                                    style={{ height: reportType === '3' ? '100px' : '80px' }}
                                >
                                    {reportType === '3' && (
                                        <label>
                                            <input type="checkbox" checked={isAllChecked} onChange={handleSelectAll} />
                                            Select All
                                        </label>
                                    )}
                                    {checkedItems?.map((item) => (
                                        <div key={item?.id}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={item?.checked}
                                                    onChange={() => handleCheckboxChange(item)}
                                                />
                                                {item?.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </>
                            <>
                                <span className={reportType === '3' ? classes.nonMandatory : classes.mandatory}>
                                    Prescriber(s):{' '}
                                </span>
                                <div className={classes.selectionBox}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={isAllCheckedPrescriber}
                                            onChange={handleSelectAllPrescriber}
                                        />
                                        Select All
                                    </label>
                                    {checkedItemsPrescriber?.map((item) => (
                                        <div key={item?.prescriberId}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={item?.checked}
                                                    onChange={() => handleCheckboxChangePrescriber(item)}
                                                />
                                                {item.fullName}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </>
                        </Row>
                    </div>
                    <div className={classes.mt15}>
                        <Button
                            clickFunction={handleRunReport}
                            className="buttonStyles"
                            type="text"
                            width={130}
                            height={45}
                            size={18}
                            buttonname="Run Report"
                        />
                        {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
                    </div>
                </div>
            )}
            <div className={classes.mt12} ref={componentRef}>
                {getTable()}
            </div>
        </div>
    );
};

export default PrescriberDashboardReports;
