import PageBackground from 'components/PageBackground';
import { LogDispenseScreens } from './logDispenseType';
import classes from './logDispense.module.scss';
import { Table } from 'antd';
import btn_plus from 'assets/images/btn_plus.png';
import btn_minus from 'assets/images/btn_minus.png';
import { useEffect, useRef, useState } from 'react';
import LocationModal from 'components/LocationModal';
import ExpandedPatientRowRenderer from './expandedPatientRowRenderer';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'store';
import { multipleDispenseCreatePayload } from 'data-models/multipleDispenseCreatePayload';
import { createLogDispense, fetchDropdown, getPharmacyByPharmacyId } from 'store/slices/appSlice';
import moment from 'moment';
import { createDefaultMaskGenerator, mask } from 'react-hook-mask';
import PrintTable from './PrintReviewTable/printTable';
import { getHTMLTemplate } from './getHtmlTemplate';
const Review = ({ handleCancelBtn, setScreen, setIsWait }) => {
    const maskGenerator = createDefaultMaskGenerator('9999999999');
    const componentRef = useRef<HTMLDivElement>(null);
    const [modal, setModal] = useState(false);
    const { logMultipleDispense, pharmacyId, patientData } = useAppSelector((state) => state.app);
    const { currentUserPharmacy } = useAppSelector((state) => state.login);
    const dispatch = useAppDispatch();
    const { authorizationsDetails } = logMultipleDispense;
    const [ReviewDataSource, setReviewDataSource] = useState<any[]>([]);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
    const [dropdowns, setOptions] = useState<any[]>([]);
    const [printReview, setPrintReview] = useState(false);
    const [pharmacyLocation, setPharmacyLocation] = useState({});

    const handlePrintBtn = () => {
        setPrintReview(true);
    };

    useEffect(() => {
        if (componentRef.current) {
            const printWindow = window.open(window.location.href, '_blank', 'width=900,height=600');
            if (!printWindow) return;

            const tableHTML = getHTMLTemplate(componentRef.current.outerHTML);
            printWindow.document.write(tableHTML);
            printWindow.document.close();
            printWindow.onload = () => {
                printWindow.print();
                printWindow.close();
                setPrintReview(false);
            };
        }
    });

    const handleReviseBtn = () => {
        setScreen(LogDispenseScreens.SelectDispense);
    };
    const getProductOptions = () => {
        dispatch(fetchDropdown(['DispensationType'])).then((response: any) => {
            if (response.payload.status === 200) {
                setOptions(response?.payload?.data?.data?.DispensationType);
            }
        });
    };
    useEffect(() => {
        getProductOptions();
        const _ReviewDataSource: any[] = authorizationsDetails.map((item: any) => {
            const surveyDate =
                patientData.length &&
                patientData?.find((p) => p.authorizationNumber.includes(item.auth_no.auth_no)).surveyDate;
            const nextSurveyDate =
                patientData.length &&
                patientData?.find((p) => p.authorizationNumber.includes(item.auth_no.auth_no)).nextSurveyDate;

            const locationArray = item.location.activeLocation.split('-');
            const code = locationArray[0];
            const addressArray = locationArray[1].split(",");
            return {
                key: item.id,
                PharmacyLocation: `${code} - ${addressArray[0]}, ${addressArray[1]}`,
                RiskCategoryName: item.authResponse.authorization.patientRiskCategoryName,
                patientDob: moment(item.authResponse.authorization.patientDob).format('MMM DD,YYYY'),
                authExpDate: moment(item.authResponse.authorization.activeStatusExpirationDate).format('MMM DD,YYYY'),
                surveyId: item.authResponse.authorization.surveyId,
                lastSurveyDate: surveyDate ? moment(surveyDate).format('MMM DD,YYYY') : null,
                nextSurveyDate: nextSurveyDate ? moment(nextSurveyDate).format('MMM DD,YYYY') : null,
                pharmacyId: item.location.pharmacyId,
                patientId: item.authResponse.patientId,
                Authorization: item.auth_no.auth_no,
                PatientName: `${item.authResponse.authorization.patientNameFirst} ${item.authResponse.authorization.patientNameLast}`,
                ProductIdentification: item.product.map((x) => {
                    return <p>{x?.product_id?.product_id}</p>;
                }),
                Quantity: item.product.map((x) => {
                    return <p>{Number(x?.quantity?.quantity)}</p>;
                }),
            };
        });
        setReviewDataSource(_ReviewDataSource);
    }, [patientData]);
    const onSubmit = () => {
        try {
            setIsWait(true);
            dispatch(
                createLogDispense(multipleDispenseCreatePayload(authorizationsDetails, dropdowns, pharmacyId,currentUserPharmacy))
            ).then((e: any) => {
                if (e.payload.status !== 200) {
                    // setMessage(['System Issue: Create Dispense API Failed']);

                    setIsWait(false);
                } else if (e.payload.status === 200) {
                    setScreen(LogDispenseScreens.Confirmation);
                    setIsWait(false);
                } else {
                    setIsWait(true);
                }
            });
        } catch (err) {
            setIsWait(false);
        }
    };

    const reviewColumns: any = [
        {
            title: 'Pharmacy Location',
            dataIndex: 'PharmacyLocation',
            key: 'PharmacyLocation',
            render: (text, record) => (
                <a onClick={() => showPopup(record)} className={classes.links}>
                    {text}
                </a>
            ),
        },
        {
            title: 'Authorization #',
            dataIndex: 'Authorization',
            key: 'Authorization',
        },
        {
            title: 'Product Identification ',
            dataIndex: 'ProductIdentification',
            key: 'ProductIdentification',
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
        },
    ];

    const showPopup = (record) => {
        dispatch(getPharmacyByPharmacyId({ id: record.pharmacyId, channelID: 'ext' })).then((response: any) => {
            const pharmacyLocation = response.payload.data.data.pharmacyLocations[0];
            setPharmacyLocation({
                Name: response.payload.data.data.pharmacyName,
                Address: `${pharmacyLocation.addressLine1} ${pharmacyLocation.addressLine2} ${pharmacyLocation.postalCode.city},${pharmacyLocation.postalCode.state.stateCode} ${pharmacyLocation.postalCode.postalCode1}`,
                Phone: mask(pharmacyLocation.pharmacyPhoneNumber, maskGenerator),
                Fax: mask(pharmacyLocation.pharmacyFaxNumber, maskGenerator),
            });
            setModal(true);
        });
    };

    useEffect(() => {
        const element: any = document.querySelector('.ant-table-row-expand-icon-cell');
        element.innerHTML = 'Patient Name';
        element.style = 'width:14%'
    }, []);
    const iterateHandle = () => {
        setModal(false);
    };
    return (
        <>
            <LocationModal
                message={''}
                data={pharmacyLocation}
                handleCancel={iterateHandle}
                handleContinue={iterateHandle}
                isCancelPopUp={modal}
            />
            <PageBackground label={'Log Dispense Review'}>
                <div className={classes.contentContainer}>
                    <div className={classes.bold} style={{ marginBottom: '10px',fontSize:'11px' }}>
                        Please review the details below. If needed, click the Revise button to edit the Log Dispense
                        details.<br/> When finished, click the Confirm button to complete the Log Dispense process.
                    </div>
                    <div className={`${classes.required} ${classes.bold}`} style={{ marginBottom: '10px',fontSize:'11px' }}>
                        
                            Please Note: Upon entering this screen, you have 15 minutes to review your dispenses and
                            click one of the buttons below, or all work will be lost.
                       
                    </div>
                    <div className={classes.review}>
                        <Table
                            size="small"
                            rowClassName={(record, index) => (index % 2 === 0 ? `table-row-dark` : `table-row-light`)}
                            dataSource={ReviewDataSource}
                            columns={reviewColumns}
                            expandable={{
                                expandedRowRender: ExpandedPatientRowRenderer,
                                expandIconColumnIndex: 7,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <div className={classes.expand} onClick={(e) => onExpand(record, e)} style={{display:'inline-flex',textAlign:'left'}}>
                                           
                                         
                                           
                                            <a className={classes.links}>   <img className={classes.expand_icon} src={btn_minus} alt="plus_icon"  />{record.PatientName}</a>
                                        </div>
                                    ) : (
                                        <div className={classes.expand} onClick={(e) => onExpand(record, e)} style={{display:'inline-flex',textAlign:'left'}}>
                                        
                                            
                                            <a className={classes.links} >   <img className={classes.expand_icon}  src={btn_plus} alt="plus_icon"  />{record.PatientName}</a>
                                        </div>
                                    ),
                            }}
                            pagination={false}
                        />

                        
                    </div>
                    
                </div>
                <div className={classes.footer_btn}>
                            <div className={classes.btn_group}>
                                <div>
                                <Button
                                    clickFunction={onSubmit}
                                    className="mr10"
                                    width={160}
                                    height={48}
                                    size={18}
                                    type="text"
                                    buttonname="Confirm"
                                />
                                </div>
                                <div>
                                <Button
                                    clickFunction={handleReviseBtn}
                                    className="mr10"
                                    width={120}
                                    height={48}
                                    size={18}
                                    type="text"
                                    buttonname="Revise"
                                />
                                </div>
                                <div>
                                <Button
                                    clickFunction={handlePrintBtn}
                                    className="mr10"
                                    width={120}
                                    height={48}
                                    size={18}
                                    type="text"
                                    buttonname="Print"
                                />
                                </div>
                                <div>
                                <Button
                                    clickFunction={handleCancelBtn}
                                    className="mr10"
                                    width={120}
                                    height={48}
                                    size={18}
                                    type="text"
                                    buttonname="Cancel"
                                />
                                </div>
                            </div>
                        </div>
            </PageBackground>
            {printReview && (
                <div className={classes.printTable} ref={componentRef}>
                    <PrintTable ReviewDataSource={ReviewDataSource} />
                </div>
            )}
        </>
    );
};

export default Review;
