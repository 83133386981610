export const getFindPatientPrintPopUpTemplate = (content) => {
    
    const patientListTableRenderer = () => {
        const patientRows = () => {
            let rows;
            if (content && content.length > 0) {
                rows = content
                    .map((each) => {
                        return `
                    <tr style="border-color:black;" border="1" class="odd">
                        <td class="startRow">
                            ${each.patientNameLast && each.patientNameLast},&nbsp;
                            ${each.patientNameFirst && each.patientNameFirst}
                        </td>
                        <td colspan="2">${each.patientExtUniqueId && each.patientExtUniqueId}</td>
                        <td colspan="2">
                            ${each.assocPrescriberNameLast && each.assocPrescriberNameLast},&nbsp;
                            ${each.assocPrescriberNameFirst && each.assocPrescriberNameFirst}
                        </td>
                    </tr>
                `;
                    })
                    .join('');
            } else {
                if (content.length === 0) {
                    rows = `<tr class="odd">
                    <td colspan="14" align="center">
                        No Records Found.                  
                    </td>
                </tr>`;
                }
            }

            return rows;
        };

        return `
            <table width="735px" cellpadding="5" cellspacing="0" style="border-color:black;" border="1" align="center" class="dataTable">
                <tbody>
                    <tr valign="top">
                        <th align="left">
                            Patient Name
                        </th>
                        <th align="left" colspan="2">
                            Patient ID
                        </th>
                        <th align="left">
                            Prescriber Name
                        </th>
                    </tr>
                    ${patientRows()}
                </tbody>
            </table>
            `;
    };

    return `
        <html>
            <head>
                <title>${window.location.href}</title>
                <style>
                    tbody tr th, 
                    tbody tr td{
                        font-size:14px;
                    } 
                    .header{
                        font-size:30px;
                        transform: translateY(15px);
                    }
                    .bottom{
                        font-size:13px;
                    }
                </style>
            </head>
            <body>
                <div class="header">
                    <span class="icon logo left"></span>
                    <div align="center">Prescriber Dashboard</div> <br>
                    <div class="buffer clear"></div>
                </div>
                ${patientListTableRenderer()}
                <br>
                <br>
                <div class="bottom" align="center">Bristol Myers Squibb</div>
            </body>
        </html>
        `;
};
