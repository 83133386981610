export const accountConfirmationContent = {
    help: '',
    header: '',
    title:{
        prescriber:'Please select a radio button option below.',
        Nonprescriber:'Please review your information.',
        Pharmacist: 'Please review your account information below and select Continue to create your account.'
    },
    body: [
        {
            order: 1,
            content: {
                label: 'I am a REMS certified prescriber with an Access Code.',
                subText: `The Access Code is supplied by your local REMS Representative. Please enter the numeric Access Code with no spaces or characters (i.e. -,#,*, etc.) in the data entry field. Then click the Continue button.`,
            },
        },
        {
            order: 2,
            content: {
                label: 'I am a certified prescriber for a Bristol Myers Squibb-administered REMS program, but I have not received my Access Code.',
                subText:
                    'Please contact your local REMS Representative or call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.',
            },
        },
        {
            order: 3,
            content: {
                label: 'I am not a certified prescriber for a Bristol Myers Squibb-administered REMS program.',
                subText:
                    'To become a REMS certified prescriber, please click Continue and complete the enrollment form that follows. Upon approval, your local REMS Representative will provide you with your Access Code in order for you to finalize the creation of your Bristol Myers Squibb-administered REMS program Online account.',
            },
        },
    ],
    successMessage:{
        prescriber:'Your user name {0} has been successfully created. Please do not share your password with anyone. Store your password in a safe place. Please select the Continue button below to continue the enrollment process for one of the Bristol Myers Squibb-administered REMS programs. To activate your account, you will require an Access Code from your REMS Representative.',
        prescriberNoAccesscode:'You must obtain your Access Code from your REMS Representative and complete the enrollment process by logging in and entering the code when prompted. Please click the button below to logout.',
        nonprescriber:'You have successfully created and activated your user account. Please do not share your password with anyone. Store your password in a safe place. You can go to the Home page for further options. Please click the button below to Continue.',
        prescriberWithAccessCode:'You have successfully created and activated your user account. Please do not share your password with anyone. Store your password in a safe place. You can go to the Home page for further options. Please click the button below to Continue.',
        Pharmacist:{
            label: 'You have successfully created and activated your user account.',
            subText: 'Please do not share your password with anyone. Store your password in a safe place. You can go to the Home page for further options. Please click the button below to Continue.'
        }
    },
    
    digitalSignature:{
        information:'Please have a REMS certified prescriber enter his/her digital signature (User Name and Password) below to validate your account.',
        Title:'Digital Signature:',
        Terms:'By clicking here, I acknowledge that the use of my User Name/Password constitutes the equivalent of a hand-written signature in the REMS Online system. Further, I acknowledge that my Prescriber association to the “Non-prescriber” requires that the “Non-prescriber” adhere to applicable federal and state laws and regulations relating to Patient Privacy, as well as guidelines for privacy and confidentiality.'
    },
    validationMessage:{
        userName:"Please enter your User Name.",
        password:"Please enter your Password.",
        termsandcondition:"You must accept the signature agreement to continue signing.",
        nonprescriber:"You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.",
        needToActiveProgram:"You are currently not active for any Bristol Myers Squibb-administered REMS programs to validate the Non-prescriber’s account. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET. ",
        inCorrectUsrPass: "You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password."
    }
};
