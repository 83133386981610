import {
    SignUpCommand,
    GetUserCommand,
    InitiateAuthCommand,
    UpdateUserAttributesCommand,
    CognitoIdentityProviderClient,
    RespondToAuthChallengeCommand,
    GlobalSignOutCommand,
    ChangePasswordCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { sha256 } from 'js-sha256';
import { HASHKEY, CLIENT_ID } from 'constants/Config/appConfig';
import { axiosInstancePrescriber } from 'constants/Config/axiosConfig';

export const cognitoClient = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION,
});

export const signIn = async (username: string, password: string) => {
    const params: any = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: CLIENT_ID,
        AuthParameters: {
            USERNAME: username,
            PASSWORD: password,
        },
        ClientMetadata: { 
            USERNAME: username,
            PASSWORD: password
        }
    };
    const MAX_RETRIES = Number(process.env.MAX_RETRIES) || 5
    let retryCount = 0
    while ( retryCount < MAX_RETRIES ){
    try {
        const command = new InitiateAuthCommand(params);
        const result = await cognitoClient.send(command);
        return result;
    } catch (error:any) {
        console.log('cognito err', error)
        const isSocketError = error?.message?.toLowerCase().includes('socket')
        if(!isSocketError){
            console.log('not a socket error')
            throw error
        }
        retryCount++;

        if(retryCount === MAX_RETRIES){
            console.log('Max retry count hit')
            throw error
        }

        await new Promise(resolve => setTimeout(resolve, 2000, retryCount-1))
    }}
};

export const UpdatePassword = async (payload: any) => {
    const accessToken = await signIn(payload.username, payload.temporarypassword);

    const input: any = {
        ClientId: CLIENT_ID,
        ChallengeName: 'NEW_PASSWORD_REQUIRED',
        Session: accessToken?.Session,
        ChallengeResponses: {
            NEW_PASSWORD: payload.newpassword,
            USERNAME: payload.username,
        },
    };

    try {
        const command = new RespondToAuthChallengeCommand(input);
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        throw error;
    }
};
export const UpdateManagePassword = async (payload: any) => {
    const accessToken = sessionStorage.token;
    const input = { 
        PreviousPassword: payload.oldPassword, 
        ProposedPassword:payload.newpassword,
        AccessToken: accessToken, 
    };
    
    try {
        const command = new ChangePasswordCommand(input);
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        throw error;
    }
};

export const logOut = async (token) => {
    const params: any = {
        AccessToken: token,
    };
    try {
        const command = new GlobalSignOutCommand(params);
        const response = await cognitoClient.send(command);
       
        return response;
    } catch (error) {
        console.error('Error signing out: ', error);
        return error;
    }
};

export type singUpType = {
    city: string;
    state: string;
    email: string;
    fName: string;
    lName: string;
    phNum: string;
    grpTag: string;
    zipCode: string;
    country: string;
    address: string;
    password: string;
    userName: string;
    language: string;
    system_Id: string;
    security_answer: string;
    security_question: string;
    prescriber_user_id: string;
};

export const signUp = async ({
    city,
    state,
    email,
    fName,
    lName,
    phNum,
    grpTag,
    zipCode,
    country,
    address,
    password,
    userName,
    language,
    system_Id,
    security_answer,
    security_question,
    prescriber_user_id,
}: singUpType) => {
    const params = {
        ClientId: CLIENT_ID,
        Username: userName,
        Password: password,
        UserAttributes: [
            {
                Name: 'email',
                Value: email,
            },
            {
                Name: 'phone_number',
                Value: phNum,
            },
            {
                Name: 'address',
                Value: address,
            },
            {
                Name: 'given_name',
                Value: fName,
            },
            {
                Name: 'family_name',
                Value: lName,
            },
            {
                Name: 'custom:city',
                Value: city,
            },
            {
                Name: 'custom:state',
                Value: state,
            },
            {
                Name: 'custom:zip_code',
                Value: zipCode,
            },
            {
                Name: 'custom:country_code',
                Value: country,
            },
            // {
            //     Name: 'custom:customer_id',
            //     Value: customerId,
            // },
            {
                Name: 'custom:group_tag',
                Value: grpTag,
            },
            {
                Name: 'custom:language',
                Value: language,
            },
            {
                Name: 'custom:security_question',
                Value: security_question,
            },
            {
                Name: 'custom:security_answer',
                Value: GenerateHashValue(security_answer),
            },
            {
                Name: 'custom:system_Id',
                Value: system_Id,
            },
            {
                Name: 'custom:prescriber_user_id',
                Value: prescriber_user_id,
            },
        ],
    };
    try {
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (error) {
        throw error;
    }
};
export const updateSecurityQuestion = async (token, secQuest, secAns) => {
    const params = {
        UserAttributes: [
            {
                Name: 'custom:security_question',
                Value: secQuest,
            },
            {
                Name: 'custom:security_answer',
                Value: GenerateHashValue(secAns),
            },
        ],
        AccessToken: token,
    };
    try {
        const command = new UpdateUserAttributesCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (err) {
        throw err;
    }
};

export const updateNonPrescSystemId = async (token, systemId) => {
    const params = {
        UserAttributes: [
            {
                Name: 'custom:non_prescriberId',
                Value: systemId.toString(),
            }
        ],
        AccessToken: token,
    };
    try {
        const command = new UpdateUserAttributesCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (err) {
        throw err;
    }
};
export const updateAccessCode = async ({ token, access }) => {
    let prescriberUniqueId = ''
    const userPayload = {
        prescriberId: access,
    };
    const response = await axiosInstancePrescriber
    .post(`/v1/prescriber/findprescriber`, userPayload)
    .then((res) => {
        prescriberUniqueId = res?.data?.data?.prescriberExtUniqueId1
    })

    const params = {
        UserAttributes: [
            {
                Name: 'custom:system_Id',
                Value: access,
            },
            {
                Name: 'preferred_username',
                Value: prescriberUniqueId || '',
            },
        ],
        AccessToken: token,
    };
    try {
        const command = new UpdateUserAttributesCommand(params);
        const response = await cognitoClient.send(command);
        return response;
    } catch (err) {
        throw err;
    }
};

export const getUserDetails = async (token) => {
    const input = {
        AccessToken: token,
    };
    try {
        const command = new GetUserCommand(input);
        const response = await cognitoClient.send(command);
        return response;
    } catch (err) {
        throw err;
    }
};

export const GenerateHashValue = (value) => {
    const key: any = HASHKEY;
    return sha256.hmac(key, value);
};
