import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { Role } from 'constants/Config/appConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { checkAssociation, setAssociationData } from 'store/slices/appSlice';
import { isValidUserName } from 'utils/regex';
import classes from './CreateAssociation.module.scss';
import { reloadFunction } from 'utils/utilsCommon';

const CreateAssociation = () => {
    const navigate = useNavigate();
    const associateType = sessionStorage.getItem('associateType')
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState(false);
    const [userName, setUserName] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const { role } = useAppSelector((state) => state.app);
    const { onlineUserId } = useAppSelector((state) => state.login.currentUser)
    const isPharmacy = role === Role.Pharmacist;
    const [alertContent, setAlertContent] = useState('');

    const handleOnChange = (e) => {
        setApiError(false)
        setUserName(e.target.value);
    };

    const handleContinue = async () => {
        if (!userName.trim()) {
            setError('Please enter your User Name.');
            setCurrentFocus('userName');
            return;
        }
        if (!isValidUserName(userName.trim())) {
            setError('Please enter a valid User Name.');
            setCurrentFocus('userName');
            return;
        }
        if(onlineUserId?.toLowerCase() === userName?.toLowerCase()){
            setApiError(true)
            setAlertContent('Please choose a different User Name to associate with.')
            setUserName('');
            setCurrentFocus('userName');
            return;
        }
        const payload = {
            loggedInUsername: onlineUserId,
            userName: userName.trim(),
            associationType: associateType === 'nonPrescriber' ? "non_presc" : "presc"
        }

        dispatch(checkAssociation(payload)).then((res: any) => {
            if(res?.payload?.status !== 200){
                setAlertContent(res.payload?.response?.data?.message)
                setApiError(true)
            }else{
                //dispatch api response
                const data = {
                    data: res?.payload?.data?.data,
                    state : associateType === 'nonPrescriber' ? "non_presc" : "presc"
                }
                dispatch(setAssociationData(data))
                sessionStorage.removeItem('associateType')
                navigate('/prescriber/associationConfirmation', {
                    replace: true,
                });
            }
        })
    };

    const handleCancel = () => {
        sessionStorage.removeItem('associateType')
        navigate('/prescriber/manageMyAccount');
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label={associateType === 'nonPrescriber' ? "Create Non-prescriber Association" : "Create Prescriber Association"}
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={handleCancel}
            >
                <>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {apiError && <Alert content={alertContent} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.formHeader}>
                    { associateType === 'nonPrescriber' ? "To create an association with a Non-prescriber, please enter the Non-prescriber's User Name." : "To create an association with a Prescriber, please enter the Prescriber's User Name."}
                        </div>
                        <div
                            className={classes.container}
                            style={{
                                margin: '10px 0',
                            }}
                        >
                            <div className={classes.label}>
                                <span className={classes.mandatory}>User Name:</span>
                            </div>
                            <div className={classes.input}>
                                <div className={classes.inputContainer}>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleContinue();
                                        }}
                                        autoComplete={'off'}
                                    >
                                        <input
                                            name={'userName'}
                                            onChange={handleOnChange}
                                            type={'text'}
                                            id={'userName'}
                                            maxLength={20}
                                            size={35}
                                            value={userName}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default CreateAssociation;
