import Input from 'components/Input';
import image_styles from '../PatientEnrollment/PatientAgreementForm.module.scss';
import { useEffect, useState } from 'react';
import { patientForm } from 'constants/patientForm';
import { useAppSelector } from 'store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {reloadFunction} from '../../utils/utilsCommon';

const PatientFormField = (props) => {
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const { patientInformation, patientChecklist: patientCheckListItems } = useAppSelector((state) => state.app);
    const { t, i18n } = useTranslation();

    const translateLabel = (label) => {
        switch (label) {
            case 'Date':
                return 'Fecha';
            case 'Patient Name':
                return 'Nombre del Paciente';
            case 'Address':
                return 'Dirección';
            case 'Phone Number':
                return 'Número de teléfono';
            case 'Patient Identification Number':
                return 'Número de identificación del paciente';
            case 'Date of Birth':
                return 'Fecha de nacimiento';
            case 'Sex':
                return 'Sexo';
            case 'Diagnosis Code':
                return 'Código de diagnóstico';
            case 'AR First Name':
                return 'Nombre de pila de AR';
            case 'AR Last Name':
                return 'AR Apellido';
            case 'Patient First Name':
                return 'Nombre de pila de paciente';
            case 'Patient Last Name':
                return 'Apellido de la paciente';
            case 'Relation to the Patient':
                return 'Relación con el paciente';
            case 'First Name':
                return 'Primer Nombre';
            case 'Last Name':
                return 'Apellido';
            default:
                return label;
        }
    };

    const transLateHelptext = (help) => {
        switch (help) {
            case `Select "Self" if you are the patient and are of legal age. Select "Authorized`:
                return 'Seleccione "Yo mismo" si usted es el paciente y tiene edad legal suficiente.';
            case `Representative" if you are signing the PPAF on behalf of the patient as a Legal`:
                return 'Seleccione "Representante autorizado" si está firmando el PPAF en nombre del';

            case `Guardian, or have Power of Attorney for this patient.`:
                return 'paciente como su Tutor legal, o si tiene un Poder notarial para este paciente.';
            default:
                return help;
        }
    };
    //fetch from store
    const patientSavedData = [
        {
            name: 'date',
            label: 'Date',
            type: 'hidden',
            isRequired: false,
            inputValue: `${moment(new Date()).format('MMM D, YYYY')}`,
        },
        {
            name: 'patientname',
            label: 'Patient Name',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.firstName} ${patientInformation.lastName}`,
        },
        {
            name: 'address',
            label: 'Address',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.address}, ${patientInformation.city}, ${patientInformation.state} ${patientInformation.zip}`,
        },
        {
            name: 'phonenumber',
            label: 'Phone Number',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.phone}`,
        },
        {
            name: 'pres_id_number',
            label: 'Patient Identification Number',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.patientIdNum}`,
        },
        {
            name: 'dob',
            label: 'Date of Birth',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.month} ${patientInformation.date}, ${patientInformation.year}`,
        },
        {
            name: 'sex',
            label: 'Sex',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation?.sex[0]}`,
        },
        {
            name: 'code',
            label: 'Diagnosis Code',
            type: 'hidden',
            isRequired: false,
            inputValue: `${patientInformation.diagnosis}`,
        },
    ];

    const [patientDetails, setPatientDetails] = useState({
        relation: '',
        firstName: '',
        lastName: '',
        checked: isCheckBoxChecked,
    });
    useEffect(()=>{
        props.patientFormData(patientDetails);
    },[patientDetails])
    useEffect(() => {
        reloadFunction();
    }, []);
    const trimValues = () => {
        const newVals = { ...patientDetails }; 
        Object.keys(newVals).forEach((field) => {
            if (!['relation', 'checked'].includes(field)) {
                newVals[field] = newVals[field]?.trim();
            }
        });
        setPatientDetails(newVals);
        return newVals;
    };
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (e.target.name === 'checkbox') {
            setPatientDetails((prev) => ({
                ...prev,
                checked: e.target.checked,
            }));
        } else if (e.target.name !== 'checkbox') {
            setPatientDetails((prev) => ({
                ...prev,
                [name]: value === 'Select One' ? '' : value,
            }));
        }        
    };

    const onBlurHandler = () => trimValues();

    const getLabel = (label) => {
        if (
            (patientDetails.relation === 'Representante Autorizado' ||
                patientDetails.relation === 'Authorized Representative') &&
            label === 'First Name'
        )
            return 'AR First Name';
        else if (
            (patientDetails.relation === 'Representante Autorizado' ||
                patientDetails.relation === 'Authorized Representative') &&
            label === 'Last Name'
        )
            return 'AR Last Name';
        else if (
            (patientDetails.relation === 'Yo mismo' || patientDetails.relation === 'Self') &&
            label === 'First Name'
        )
            return 'Patient First Name';
        else if (
            (patientDetails.relation === 'Yo mismo' || patientDetails.relation === 'Self') &&
            label === 'Last Name'
        )
            return 'Patient Last Name';
        else return label;
    };

    return (
        <>
            {patientSavedData.map((row) => {
                return (
                    <>
                        <Input
                            styleupdate={true}
                            value={row.inputValue}
                            type={row.type}
                            name={row.name}
                            label={i18n.language === 'es' ? translateLabel(row.label) : row.label}
                            // label={translateLabel(row.label)}
                            inputvalue={row.inputValue}
                            isRequired={row.isRequired}
                            className="fontColor"
                        />
                    </>
                );
            })}
            {!props.signSubmitClicked && (
                <>
                    <h4>{t('digSignature')}</h4>
                    {patientForm?.personalInfo?.map((row) => {
                        return (
                            <>
                                <Input
                                    onChangeHandler={handleOnChange}
                                    styleupdate={true}
                                    defaultValue={patientCheckListItems[row.name]}
                                    value={patientDetails[row.name]}
                                    type={row.type}
                                    name={row.name}
                                    label={i18n.language === 'es' ? translateLabel(getLabel(row.label)) : getLabel(row.label)}
                                    // label={translateLabel(getLabel(row.label))}
                                    inputvalue="inputvalue"
                                    isRequired={row.isRequired}
                                    className="fontColor"
                                    onBlurHandler={onBlurHandler}
                                    helpText={i18n.language === 'es' ? transLateHelptext(row.helpText1) : row.helpText1}
                                    helpText2={
                                        i18n.language === 'es' ? transLateHelptext(row.helpText2) : row.helpText2
                                    }
                                    helpText3={
                                        i18n.language === 'es' ? transLateHelptext(row.helpText3) : row.helpText3
                                    }
                                    options={i18n.language === 'en' ? row.option : row.spanishOption}
                                    maxLength={row.maxLength}
                                />
                            </>
                        );
                    })}
                    <div className={image_styles.checkbox_wrapper}>
                        <div className={image_styles.checkbox_content1}>
                            <span className={image_styles.required}>*</span>
                            <input
                                // checked={patientDetails.checked}
                                id="patientCheckbox"
                                name="checkbox"
                                type="checkbox"
                                onChange={handleOnChange}
                            ></input>
                        </div>
                        <div className={image_styles.checkbox_content2}>
                            {t('patientacknowlegment')}                            
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PatientFormField;
