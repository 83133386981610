import moment from 'moment';
const estranslations = { Female: 'Femenino', Male: 'Masculino', Yes: 'Sí', No: 'No' };
//Convert patient register DTO to Patient information State. PatientDOB from camunda comes as DD/MM/YYYY format

export const getPatientDetailsEditPatient = (data, dropDown, flowType, lang) => {
    const oldRiskObj = ['Reactivate','Reenroll'].includes(flowType) ? { oldRisk: data?.patientRiskCategory } : {};
    const getLang = (label) => {
        return lang && lang === 'es' ? estranslations[label] : label;
    };
    const dobArray = data.patientDob.includes('/')
        ? moment(data.patientDob, 'DD/MM/YYYY').format('DD/MMM/YYYY').split('/')
        : data.patientDob.split(' ')[0].split('-');

    const currentPatientCondition =data?.patientConditions?.find(o=> !o.patientConditionEndDate )
    let diagnosisValue;
    if (flowType === 'EditPatient') {
        diagnosisValue =
            currentPatientCondition?.diagnosisCode?.length > 0
                ? currentPatientCondition?.diagnosisCode + '-' + (currentPatientCondition?.diagnosisCode === '000' ? currentPatientCondition?.otherDiagnosisCode:currentPatientCondition?.otherDiagnosisText)
                : '';
            
    } else if (flowType === 'Reenroll') {
        diagnosisValue = '';
    } else {
        const diagnosisCode = dropDown.find((o) => o.id === currentPatientCondition.diagnosisCode);
        diagnosisValue = diagnosisCode ? diagnosisCode.id + '-' + diagnosisCode.value : '';
    }

    const getRiskCriteriaValue = (riskData, riskId) => {
        let riskVal = '';
        riskData.forEach((item) => {
            if (item.riskCriteriaId.riskCriteriaId === riskId) {
                riskVal = item.riskCriteriaValue;
            }
        });
        return riskVal === 'N' ? 'No' : getLang('Yes');
    };
    const menstratingValue =
        data.patientRiskCriteria?.length > 0 && !['Reactivate','Reenroll'].includes(flowType)
            ? data.patientGender === 'F'
                ? getRiskCriteriaValue(data.patientRiskCriteria, 241)
                : ''
            : '';
    const surgicalValue =
        data.patientRiskCriteria?.length > 0 && !['Reactivate','Reenroll'].includes(flowType)
            ? data.patientGender === 'F'
                ? getRiskCriteriaValue(data.patientRiskCriteria, 242)
                : ''
            : '';
    const naturalValue =
        data.patientRiskCriteria?.length > 0 && !['Reactivate','Reenroll'].includes(flowType)
            ? data.patientGender === 'F'
                ? getRiskCriteriaValue(data.patientRiskCriteria, 243)
                : ''
            : '';
    return {
        firstName: data.patientNameFirst,
        lastName: data.patientNameLast,
        mi: data.patientMiddleInitial || '',
        address: data.patientLocations[0].addressLine1,
        city: data.patientLocations[0].postalCode.city,
        state: data.patientLocations[0].postalCode.state.stateCode,
        zip: data.patientLocations[0].postalCode.postalCode1,
        phone: data.patientLocations[0].phoneNumber,
        month: dobArray[1].charAt(0) + dobArray[1].slice(1).toLowerCase(),
        date: dobArray[0],
        year: dobArray[2],
        sex: data.patientGender === 'F' ? 'Female' : 'Male',
        patientIdNum: data.patientExternalUniqueId,
        patientId: data.patientId,
        diagnosis: diagnosisValue,
        otherDiag:
            currentPatientCondition.diagnosisCode === '000' ? currentPatientCondition.otherDiagnosisText : '',
        menstrating: menstratingValue,
        surgical: surgicalValue,
        natural: naturalValue,
        ...oldRiskObj,
    };
};
