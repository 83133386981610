import React from 'react';
import classes from '../logDispense.module.scss';
import { Table } from 'antd';

const ExpandConfirmationRow = (record) => {
    const expandValues = [
        {
            id: 1,
            label: 'Risk Category',
            value: record.riskCategory,
        },
        {
            id: 2,
            label: `Patient's Date of Birth`,
            value: record.patientDOB,
        },
        {
            id: 3,
            label: `Authorization Expiration Date`,
            value: record.authExpiryDate,
        },
    ];

    const columns = [
        {
            title: 'Product Identification',
            dataIndex: 'ProductIdentification',
            key: 'ProductIdentification',
            width: '80%',
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            width: '20%',
        },
    ];

    const data = record.Dispense.map((item, index) => {
        return { key: index, ProductIdentification: item.product_id, Quantity: item.quantity };
    });

    return (
        <div
            style={{
                margin: '0 1%',
                paddingBottom: '2%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    maxWidth: '46%',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '20px',
                }}
            >
                {expandValues.map((item) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ width: '44%', fontWeight: '700' }}>{item.label}</div>
                        <span style={{ marginRight: '2px', fontWeight: '400' }}>:</span>
                        <div style={{ width: '52%' }}>{item.value}</div>
                    </div>
                ))}
            </div>
            <div style={{ minWidth: '53%', display: 'flex', flexDirection: 'column'}}>
                <div style={{ marginBottom: '8px', fontWeight: '700', marginLeft: '7.5%' }}>Dispense Details</div>
                <div>
                    <Table
                        className="dispense_details"
                        size="small"
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    );
};
export default ExpandConfirmationRow;
