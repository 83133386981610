import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import './index.scss';
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import i18n from './internationalization/i18next';
import {PersistGate} from 'redux-persist/es/integration/react';
import { persistStore } from 'redux-persist';
import { getStringBetween } from 'utils/getStringBetween';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
let persistor = persistStore(store)
const prodEnvCheck = window.location.href.includes('prod') || window.location.href.includes('bmsremspatientsafety');

if(prodEnvCheck)
{
    console.log = () => {};
}
root.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
