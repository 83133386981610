const PomalystTextToPomalidomide = 'Pomalidomide';
export const TEXT = {
    LENALIDOMIDE: 'Lenalidomide',
    POMALIDOMIDE: PomalystTextToPomalidomide,//Change to Pomalidomide
    THALOMID: 'Thalomid',
};

export const PRESCRIBERSELECTIONLIST = [
    {
        id: '1',
        label: 'A Prescriber (Healthcare Professional with prescription writing authority)',
        content:
            'In order to complete the creation of an online account, you must be certified for a Bristol Myers Squibb-administered REMS program. For security purposes you will be asked to enter an Access Code that has been provided by your REMS Representative. If you do not have the Access Code, please contact your REMS Representative or the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.',
    },
    {
        id: '2',
        label: 'A Non-prescriber (Healthcare Professional without prescription writing authority)',
        content:
            'In order to complete the creation of an online account, the digital signature of a prescriber with an online account is required to validate a Non-prescriber account. This additional security supports the protection of patient privacy.',
    },
];

export const IdleTimeOfUser = 840000;
