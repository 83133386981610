import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { changePPAF } from 'store/slices/appSlice';
import classes from '../PPAFWorkMenu/PPAFWorkMenu.module.scss';
import { useAppSelector } from '../../store';

import { RoleByGroup } from 'constants/Config/appConfig';
import SessionTimeout from 'components/SessionTimeout';
import PatientSearch from 'pages/PatientSearch/PatientSearch';
import PrescriberSearch from 'pages/PrescriberSearch/PrescriberSearch';

const PrescriberNonPrescriber = () => {
    const { selectedProgram } = useAppSelector((state) => state.app);
    const { currentUser, IsAuthenticated } = useAppSelector(
        (state) => state.login
    );
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const selectedProductData = useSelector((state: RootState) => state.app.selectedProgram);


    const renderCurrentScreen = () => {
        if(IsAuthenticated){
            if(currentUser.groupTag === RoleByGroup.NonPrescriber){
              return  <PrescriberSearch />
            }else {
               return <PatientSearch />
            }
        }
    }

    return (
        <>
        <SessionTimeout/>
        {renderCurrentScreen()}
        </>
    );
};

export default PrescriberNonPrescriber;
