import GeneralPatientAuthData from './GeneralPatientAuthData';
import DetailedPatientAuthData from './DetailedPatientAuthData';
import classes from "./reviewAuthPatientData.module.scss"
import {getReviewAuthPrintPopUpTemplate} from "../../PrescriberDashboardReviewAuth/getReviewAuthPrintPopUpTemplate"
import { useAppDispatch, useAppSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import printBtn from '../../../assets/images/btn_Phar_ReviewAuth_Print.png';
import finishBtn from '../../../assets/images/btn_Phar_ReviewAuth_Finish.png';
import logDispenseBtn from '../../../assets/images/btn_Phar_ReviewAuth_logDispense.png';
import { AuthDataType } from '../logDispense/logDispenseType';
import { setLogMultipleDispense } from 'store/slices/appSlice';


const ReviewAuthPatientData = () => {
    const navigate = useNavigate();
    const { pharmacyLocation } = useAppSelector((state) => state.app);
    const { authorizationData } = useAppSelector((state) => state.app);
    const dispatch = useAppDispatch();
    const { logMultipleDispense } = useAppSelector((state) => state.app);

    const handleFinish = async () => {
        navigate('/pharmacy/PharmacyHome', { replace: true });
    };
    const handlePrint = async () => {
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML = getReviewAuthPrintPopUpTemplate(authorizationData, pharmacyLocation);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };
    const handleLogDispense = async () => {
        dispatch(
            setLogMultipleDispense({
                ...logMultipleDispense,             
                multipleDispensesTable: logMultipleDispense.multipleDispensesTable.map((x:AuthDataType,index) => {                    
                        if(index === 0)
                            return {...x,auth_no:{...x.auth_no, auth_no:authorizationData[0]?.authorizationNumber?.substring(2)}}
                        else
                           return x;
                    
                }),
            })
        );
        navigate('/pharmacy/logdispensehome', { replace: true });
    };

    const isLogDispenseBtnActive = () => {
        if (authorizationData && authorizationData[0] && authorizationData[0].authorizationStatus) {
            return authorizationData[0].authorizationStatus.toLowerCase() === 'active';
        }
        return false;
    };
    if (!authorizationData || authorizationData.length === 0) {
        return;
    } else {
        return (
            <>
                <GeneralPatientAuthData authData={authorizationData[0]} />
                <DetailedPatientAuthData authData={authorizationData[0]}/>
                <div className={classes.bottomRow}>
                <div className={classes.buttonWrapper}>
                    
                        <div onClick={handleFinish}>
                            <img src={finishBtn} alt="active finish button" />
                        </div>                  
                </div>
                <div className={classes.buttonWrapper}>
                    
                        <div onClick={handlePrint}>
                            <img src={printBtn} alt="active print button" />
                        </div>  
                </div>
                <div className={classes.buttonWrapper}>
                    {isLogDispenseBtnActive() ? (
                        <div onClick={handleLogDispense}>
                            <img src={logDispenseBtn} alt="active log dispense button" />
                        </div>  ) : ''}
                </div>
               
            </div>
            </>
        );
    }
};

export default ReviewAuthPatientData;
