import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstanceNewUrl, axiosInstancePrescriber } from '../../constants/Config/axiosConfig';
import { jwtDecode } from 'jwt-decode';
import { signIn, updateAccessCode } from 'utils/authService';
import { cognitoLoginErrMsg, congitoResMapper } from 'utils/congitoResMapper';
import { encryptData } from 'utils/encryptionWrapper';
import { RoleByGroup } from 'constants/Config/appConfig';
const initialState: any = {
    IsAuthenticated: false,
    IsAuthenticatedPharmacy: false,
    accessCodeFlag: false,
    currentUser: {
        onlineUserId: '',
        firstName: '',
        lastName: '',
        groupTag: '',
        systemId: '',
        userStatus: '',
        accessToken: '',
        idToken: '',
        refreshToken: '',
        session: '',
        userinfo: {},
        nonPrescriberId: null,
    },
    currentUserPharmacy: {
        onlineUserId: '',
        firstName: '',
        lastName: '',
        groupTag: '',
        systemId: '',
        userStatus: '',
        accessToken: '',
        idToken: '',
        refreshToken: '',
        session: '',
        userinfo: {},
        nonPrescriberId: null,
    },
    error: {
        message: '',
    },
    popUpTemplate: [],
    closedPopUpTemplate: [],
    EnrollmentStatusError: false,
    updatePharmacyPayload: {},
    resetUserName: { prescriber: '', pharmacy: '' },
};

export const authenticateUser = createAsyncThunk(
    'auth/User',
    async (loginDetails: { username: string; password: string }, { rejectWithValue }) => {
        try {
            const session = await signIn(loginDetails.username, loginDetails.password);
            return session;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSystemId = createAsyncThunk(
    'auth/updateSystemId',
    async (loginDetails: { token: string; access: string }, { rejectWithValue }) => {
        try {
            const session = await updateAccessCode(loginDetails);
            return session;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const digitalSignatureAuth = createAsyncThunk(
    'auth/digitalSign',
    async (loginDetails: any, { rejectWithValue }) => {
        try {
            const session = await signIn(loginDetails.username, loginDetails.password);
            return session;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const loginSlice = createSlice({
    name: 'loginSlice',
    initialState,
    reducers: {
        setErrorLogin: (state, action) => {
            state.error.message = action.payload;
        },
        setaccessCodeFlag: (state, action) => {
            state.accessCodeFlag = action.payload;
        },
        setpopUpTemplate: (state, action) => {
            state.popUpTemplate = action.payload;
        },
        setClosedPopUpTemplate: (state, action) => {
            state.closedPopUpTemplate = action.payload;
        },
        setEnrollmentStatusError: (state, action) => {
            state.EnrollmentStatusError = action.payload;
        },
        setResetUserName: (state, action) => {
            state.resetUserName = action.payload;
        },
        setSysytemID: (state, action) => {
            state.currentUser = {
                ...state.currentUser,
                systemId: action.payload,
            };
        },
        setUpdatePharmacyPayload: (state, action) => {
            state.updatePharmacyPayload = action.payload;
        },
        updateActiveSessionAuth: (state, action) => {
            if (action?.payload?.role === 'prescriber') {
                state.IsAuthenticated = true;
                state.currentUser = {
                    onlineUserId: action?.payload?.data?.onlineUserId || '',
                    firstName: action?.payload?.data?.firstName || '',
                    lastName: action?.payload?.data?.lastName || '',
                    groupTag: action?.payload?.data?.groupTag || '',
                    systemId: action?.payload?.data?.systemId || state.currentUser.systemId || '',
                    userStatus: action?.payload?.data?.userStatus || '',
                    accessToken: action?.payload?.data?.accessToken || '',
                    idToken: action?.payload?.data?.idToken || '',
                    refreshToken: action?.payload?.data?.refreshToken || '',
                    session: action?.payload?.data?.session || '',
                    userinfo:
                        action?.payload?.data?.accessToken && action?.payload?.data?.idToken
                            ? {
                                  ...jwtDecode(action?.payload?.data?.accessToken),
                                  ...jwtDecode(action?.payload?.data?.idToken),
                              }
                            : {},
                };
            } else {
                state.IsAuthenticatedPharmacy = true;
                state.currentUserPharmacy = {
                    onlineUserId: action?.payload?.data?.onlineUserId || '',
                    firstName: action?.payload?.data?.firstName || '',
                    lastName: action?.payload?.data?.lastName || '',
                    groupTag: action?.payload?.data?.groupTag || '',
                    systemId: action?.payload?.data?.systemId || state.currentUser.systemId || '',
                    userStatus: action?.payload?.data?.userStatus || '',
                    accessToken: action?.payload?.data?.accessToken || '',
                    idToken: action?.payload?.data?.idToken || '',
                    refreshToken: action?.payload?.data?.refreshToken || '',
                    session: action?.payload?.data?.session || '',
                    userinfo:
                        action?.payload?.data?.accessToken && action?.payload?.data?.idToken
                            ? {
                                  ...jwtDecode(action?.payload?.data?.accessToken),
                                  ...jwtDecode(action?.payload?.data?.idToken),
                              }
                            : {},
                };
            }

            sessionStorage.setItem('userInfo', JSON.stringify(action?.payload?.data));

            sessionStorage.setItem('token', action?.payload?.data?.accessToken);
        },
        setLogOut: (state) => {
            sessionStorage.setItem('userInfo', '');
            sessionStorage.setItem('token', '');
            sessionStorage.removeItem('pass');
            sessionStorage.removeItem('accountData');
            sessionStorage.removeItem('productaccDetails');
            sessionStorage.removeItem('productSecDetails');
            //return initialState;
            return {
                ...initialState,
                currentUserPharmacy: state.currentUserPharmacy,
                IsAuthenticatedPharmacy: state.IsAuthenticatedPharmacy,
                resetUserName: { ...state.resetUserName, prescriber: '' },
                updatePharmacyPayload:state.updatePharmacyPayload,
                popUpTemplate: state.popUpTemplate,
                closedPopUpTemplate: state.closedPopUpTemplate,
            };
        },
        setLogOutPharmacy: (state) => {
            sessionStorage.setItem('userInfo', '');
            sessionStorage.setItem('token', '');
            sessionStorage.removeItem('pass');
            sessionStorage.removeItem('accountData');
            sessionStorage.removeItem('productaccDetails');
            sessionStorage.removeItem('productSecDetails');
            return {
                ...initialState,
                currentUser: state.currentUser,
                accessCodeFlag: state.accessCodeFlag,
                IsAuthenticated: state.IsAuthenticated,
                EnrollmentStatusError: state.EnrollmentStatusError,
                resetUserName: { ...state.resetUserName, pharmacy: '' },
                popUpTemplate: state.popUpTemplate,
                closedPopUpTemplate: state.closedPopUpTemplate,
            };
        },
        setLoginDetails: (state, action) => {
            const res = congitoResMapper(action?.payload?.data?.AuthenticationResult || {});
            if (action?.payload?.role === 'prescriber') {
                state.IsAuthenticated = true;
                state.currentUser = res?.currentUser || {};
            } else {
                state.IsAuthenticatedPharmacy = true;
                state.currentUserPharmacy = res?.currentUser || {};
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authenticateUser.pending, (state, action) => {
            state.error = { message: '' };
        });
        builder.addCase(authenticateUser.fulfilled, (state, action) => {
            if (Object.keys(action?.payload?.AuthenticationResult || {})?.length > 0) {
                const res = congitoResMapper(action?.payload?.AuthenticationResult || {});
                console.log('cognito res', res);
                sessionStorage.setItem('userInfo', JSON.stringify(res?.currentUser || {}));
                if (res?.currentUser?.groupTag === RoleByGroup.Pharmacist) {
                    console.log('is auth resp', res?.isAuthenticated);
                    state.IsAuthenticatedPharmacy = res?.isAuthenticated;
                    state.currentUserPharmacy = res?.currentUser || {};
                    sessionStorage.setItem('token', action?.payload?.AuthenticationResult?.AccessToken || '');
                } else {
                    state.IsAuthenticated = res?.isAuthenticated;
                    sessionStorage.setItem('token', action?.payload?.AuthenticationResult?.AccessToken || '');
                    state.currentUser = res?.currentUser || {};
                    console.log('current user', res?.currentUser, RoleByGroup.Pharmacist, res?.currentUser?.groupTag);
                }
            } else if (action?.payload?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                const userDets = JSON.parse(action?.payload?.ChallengeParameters?.userAttributes || '{}');
                if (userDets?.['custom:group_tag'] === RoleByGroup.Pharmacist) {
                    state.IsAuthenticatedPharmacy = true;
                    state.currentUserPharmacy = {
                        userinfo: userDets,
                        lastName: userDets?.family_name,
                        firstName: userDets?.given_name,
                        userStatus: 'FORCE_CHANGE_PASSWORD',
                        session: action?.payload?.Session || '',
                        groupTag: userDets?.['custom:group_tag'],
                        onlineUserId: action?.payload?.ChallengeParameters?.USER_ID_FOR_SRP || '',
                    };
                } else {
                    state.IsAuthenticated = true;
                    state.currentUser = {
                        userinfo: userDets,
                        lastName: userDets?.family_name,
                        firstName: userDets?.given_name,
                        userStatus: 'FORCE_CHANGE_PASSWORD',
                        session: action?.payload?.Session || '',
                        groupTag: userDets?.['custom:group_tag'],
                        onlineUserId: action?.payload?.ChallengeParameters?.USER_ID_FOR_SRP || '',
                    };
                }
            } else {
                state.error = { message: 'API failed' };
            }
        });
        builder.addCase(authenticateUser.rejected, (state, action: any) => {
            state.error = { message: cognitoLoginErrMsg(action?.payload?.message) };
        });
        builder.addCase(getNonPrescriberId.fulfilled, (state, action) => {
            state.currentUser.nonPrescriberId = action.payload?.data?.data?.nonPrescriberId;
        });
        builder.addCase(getNonPrescriberId.rejected, (state) => {
            state.currentUser.nonPrescriberId = null;
        });
    },
});
export const getNonPrescriberId = createAsyncThunk('/get/nonPrescriberId', async (payload: any) => {
    //const encryptedData = encryptData(payload);
    const response = await axiosInstancePrescriber.post(`/v1/nonprescriber/find`, payload);
    return response;
});
export const {
    setErrorLogin,
    setLogOut,
    setLoginDetails,
    setaccessCodeFlag,
    setpopUpTemplate,
    setClosedPopUpTemplate,
    setEnrollmentStatusError,
    setSysytemID,
    setUpdatePharmacyPayload,
    updateActiveSessionAuth,
    setResetUserName,
    setLogOutPharmacy
} = loginSlice.actions;
export default loginSlice.reducer;
