import React from 'react';
import classes from './EducationAndCounseling.module.scss';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';

import {PROGRAMS,ProgramConfig, ProgramConfigIMG} from 'constants/Config/programsConfig';

const EducationAndCounseling = () => {
    const urlNavigate = (pdf) => {
        const pdfURL = require(`../../../assets/images/PDF/${pdf}`)
        window.open(pdfURL, '_blank');
    };
    const getData :any ={
        programs:PROGRAMS
        }

    return (
        <div style={{ marginTop: '10px' }} className={classes.educationAndCounseling}>
            {
                <PageBackground pharmacy={true} label="Education and Counseling Checklist for Pharmacies (ECCP)">
                    <>
                        <SessionTimeout />
                        <div className={classes.selectREMSProgram}>
                            <p>
                                Please select one of the REMS programs below. This will allow you to generate an
                                Education and Counseling Checklist for a specific program.
                            </p>
                        </div>
                        <div className={classes.contentContainer}>
                            <div className={classes.contentPadding}>
                                <div className={classes.prgmContainer}>
                                    {/* {images.map((image) => (
                                        <div key={image.id}>
                                            <button
                                                className={`${classes.btn} ${classes[image.id]}`}
                                                onClick={() => urlNavigate(image.pdfURL)}
                                            />
                                        </div>
                                    ))} */}
                                    {getData?.programs?.map((item) => (
                                        <div key={item.name}>
                                           {/*  <button
                                                className={`${classes.btn} ${prgmClass[ProgramConfig(item.id)?.headerImg || ""]}`}
                                                onClick={() => urlNavigate(ProgramConfig(item.id)?.pdf)}
                                            /> */}
                                            <img
                                                tabIndex={0}
                                                onClick={() => urlNavigate(ProgramConfig(item.id)?.pdf)}
                                                src={require(`../../../assets/images/${ProgramConfigIMG(item.id)}`)}
                                                alt={item.name}
                                                className={classes.btn}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                </PageBackground>
            }
        </div>
    );
};

export default EducationAndCounseling;
