import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { resetPswdManageFields } from 'constants/createAccountFormFields';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { resetPasswordDR, resetPasswordManage } from 'store/slices/appSlice';
import { isValidPassword } from 'utils/regex';
import classes from './ResetPswdManage.module.scss';
import { reloadFunction } from 'utils/utilsCommon';

const ResetPswdManage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const { currentUser } = useAppSelector((state) => state.login);
    const { role } = useAppSelector((state) => state.app);

    const [values, setValues] = useState({
        oldPswd: '',
        newPswd: '',
        verifyNewPswd: '',
    });

    const handleOnChange = (e) => {
        error && setError('');
        setApiError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const checkPasswordMatch = () => {
        const { newPswd } = values;
        const { firstName, lastName } = currentUser || {};
        return (
            newPswd.toLowerCase().includes(firstName.toLowerCase()) ||
            newPswd.toLowerCase().includes(lastName.toLowerCase()) ||
            newPswd.toLowerCase().includes(currentUser?.onlineUserId?.toLowerCase())
        );
    };

    const handleContinue = () => {
        for (let i of resetPswdManageFields) {
            if (i.name === 'oldPswd') {
                if (values?.oldPswd?.trim() === '') {
                    setError(`Please enter your valid Old/Temporary Password.`);
                    setCurrentFocus(i.name);
                    return;
                }
            }else if (i.name === 'newPswd') {
                if (!values.newPswd.length) {
                    setError(`Please enter a New Password.`);
                    setCurrentFocus(i.name);
                    return;
                }
                if (!isValidPassword(values.newPswd)) {
                    setError(
                        `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.`
                    );
                    setCurrentFocus(i.name);
                    return;
                }
                if (checkPasswordMatch()) {
                    setError(
                       `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.`
                    );
                    setCurrentFocus(i.name);
                    return;
                }
            } else if (i.name === 'verifyNewPswd') {
                if (!values.verifyNewPswd.length) {
                    setError(`Please enter the Password confirmation.`);
                    setCurrentFocus(i.name);
                    return;
                } else if (!isValidPassword(values.verifyNewPswd)) {
                    setError(
                        `Please enter a valid password confirmation.`
                    );
                    setCurrentFocus(i.name);
                    return;
                } else if (values.newPswd !== values.verifyNewPswd) {
                    setError(`The new passwords you have entered do not match. Please enter your new passwords again.`);
                    setCurrentFocus(i.name);
                    return;
                }
            }
        }
        dispatch(
            /* resetPasswordManage({
                username: currentUser?.onlineUserId,
                oldPassword: values.oldPswd,
                newpassword: values.newPswd,
            }) */
            resetPasswordDR({
                username: currentUser?.onlineUserId,
                previouspassword: values.oldPswd,
                proposedpassword: values.newPswd,
            })
        ).then((e:any) => {
            console.log('reset pswd resp',e)
            if (e.meta?.requestStatus === "fulfilled") {
                
                if(e?.payload?.data?.statusCode=== 200){
                    navigate('/prescriber/manageresetpasswordconfirmation', { replace: true});
                }
                else{
                    setValues({
                        oldPswd: '',
                        newPswd: '',
                        verifyNewPswd: '',
                    });
                    if(e?.payload?.data?.statusCode=== 101)
                    {
                        setApiError(
                            'You have entered a wrong Old/Temporary Password. If you still have problems, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                        );
                        setCurrentFocus('');
                    }
                    else if(e?.payload?.data?.statusCode=== 400)
                    {
                        setApiError(
                            `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.`
                        );
                        setCurrentFocus('');
                    }
                    else if(e?.payload?.data?.statusCode=== 401 || e?.payload?.data?.statusCode=== 403){
                        navigate('/prescriber/SystemError');
                    }
                    else{
                        setApiError(e?.payload?.data?.message);
                        setCurrentFocus('');
                    }
                }
            } else {                
                navigate('/prescriber/SystemError');
            }
        });
    };

    const handleCancel = () => {
        navigate('/prescriber/manageMyAccount', { replace: true });
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        setError('');
        setCurrentFocus('');
    };

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label="Reset Password"
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={handleCancel}
            >
                <>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {apiError && <Alert content={apiError} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.formHeader}>
                            Please enter your details and click the Continue button. If you need assistance, please call
                            the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00
                            PM ET.
                        </div>
                        <div style={{ marginTop: '10px' }} className={classes.container}>
                            <div className={classes.label}>
                                <span>User Name:</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                                {currentUser?.onlineUserId || ''}
                            </div>
                        </div>
                        {resetPswdManageFields.map((i) => {
                            return (
                                <Input
                                    key={i.name}
                                    type={i.type}
                                    name={i.name}
                                    label={i.label}
                                    value={values[i.name]}
                                    maxLength={i.maxLength}
                                    isRequired={i.isRequired}
                                    helpText={i.helpText}
                                    helpTextStyle={{ fontSize: '12px', marginTop: '8px' }}
                                    onChangeHandler={handleOnChange}
                                    labelStyle={{ fontWeight: 'bold' }}
                                />
                            );
                        })}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default ResetPswdManage;
