import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useEffect, useState } from 'react';
import { persistStore } from 'redux-persist';
import { store, useAppDispatch } from 'store';
import { useTranslation } from 'react-i18next';
import { setLogOut } from 'store/slices/loginSlice';
import {
    changePPAF,
    handlePrescriber,
    resetApp,
    resetPatientInformation,
    resetpatientPrescriberStore,
} from 'store/slices/appSlice';
import SessionTimeoutModal from './SessionTimeoutModal';
import { IdleTimeOfUser } from 'constants/Config/textConfig';
import Button from 'components/Button';
import classes from './SessionTimeoutModal.module.scss';
import indexedDBStore from 'store/indexedDBStore';

const SessionTimeout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const [seconds, setSeconds] = useState(60);
    const [showpopup, setshowpopup] = useState(false);
    const [startTimer, setstartTimer] = useState(false);

    const channel = new BroadcastChannel('sessionTimeout');

    useEffect(() => {
        if (startTimer) {
            const timer = setTimeout(() => {
                if (seconds > 0) {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                } else {
                    clearStore();
                    dispatch(resetApp());
                    dispatch(setLogOut());
                    dispatch(resetPatientInformation());
                    dispatch(resetpatientPrescriberStore());
                    dispatch(handlePrescriber(location.pathname.includes('pharmacy') ? 'pharmacist' : 'prescriber'));
                    indexedDBStore.removeItem(`${location?.pathname?.split('/')[1]}User`);
                    i18n.changeLanguage('en');
                    dispatch(changePPAF(false));
                    if (location.pathname.includes('pharmacy')) {
                        navigate('/pharmacy/login', { replace: true });
                    } else {
                        navigate('/prescriber/login', { replace: true });
                    }
                    reset();
                }
            }, 1000);

            return () => clearTimeout(timer);
        } // Cleanup the timeout on component unmount
    }, [seconds, startTimer]);

    useEffect(() => {
        channel.onmessage = (event) => {
            if (event.data.type === 'LOGOUT_USER') {
                setshowpopup(true);
                setSeconds(60);
                setstartTimer(true);
            } else if (event.data.type === 'CONTINUE_HANDLE') {
                setshowpopup(false);
                reset();
                setstartTimer(false);
            }
            return () => channel.close();
        };
    }, []);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = timeInSeconds % 60;
        return minutes == 1 ? '60 seconds' : `${String(remainingSeconds).padStart(2, '0')} seconds`;
    };

    const continueHandle = () => {
        channel.postMessage({ type: 'CONTINUE_HANDLE' });
        setshowpopup(false);
        reset();
        setstartTimer(false);
    };

    const clearStore = async () => {
        const persistor = persistStore(store);
        await persistor.purge();
    };

    const logoutUser = () => {
        channel.postMessage({ type: 'LOGOUT_USER' });
        setshowpopup(true);
        setSeconds(60);
        setstartTimer(true);
    };

    const { reset } = useIdleTimer({
        timeout: IdleTimeOfUser,
        onIdle: logoutUser,
        events: ['keydown', 'mousedown'],
        crossTab: true,
        syncTimers: 200,
        stopOnIdle: true,
        timers: workerTimers,
    });

    return (
        <>
            {showpopup && (
                <SessionTimeoutModal>
                    <div style={{ width: '100%' }}>
                        <div style={{ borderBottom: '1.5px solid #C0C0C0' }}>
                            <p className={classes.timeoutCont}>User Inactivity Timeout</p>
                        </div>
                        <div>
                            <p className={classes.timeoutCont}>
                                You will be logged out in {formatTime(seconds)} due to inactivity.
                                <br />
                                Please select the Continue button to continue working.
                            </p>
                        </div>

                        <div className={classes.ctnBtn}>
                            <Button
                                clickFunction={continueHandle}
                                className="buttonStyles"
                                type="text"
                                width={140}
                                height={40}
                                size={16}
                                buttonname={'Continue'}
                            />
                        </div>
                    </div>
                </SessionTimeoutModal>
            )}
        </>
    );
};

export default SessionTimeout;
