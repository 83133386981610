import SessionTimeout from 'components/SessionTimeout';
import Success from 'components/Success';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import classes from './ResetPswdManagePhar.module.scss';

const ResetPswdManageConfirmationPhar = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { role } = useAppSelector((state) => state.app);

    const handleContinue = () => {
        navigate('/pharmacy/manageAccount', { replace: true });
    };

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };

        window.addEventListener('beforeunload', unloadCallback);

        return () => {
            window.removeEventListener('beforeunload', unloadCallback);
        };
    }, []);

    return (
        <>
            <SessionTimeout />
            <Success label="Reset Password Confirmation" btn={true} handleClick={handleContinue}>
                <p className={classes.confirmationMsg}>
                    Your password has been changed. Press the Continue button below to return to Manage My Account.
                </p>
            </Success>
        </>
    );
};

export default ResetPswdManageConfirmationPhar;