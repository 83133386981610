export const PatientDTOUpdate = (patientDTO,updatedData,state,onlineUserId="system") => {
    //const patientUpdateData = _.cloneDeep(patientDTO);
   
    patientDTO.data.patientLocations[0].phoneNumber = updatedData.phone;
    patientDTO.data.patientLocations[0].addressLine1 = updatedData.address;
    patientDTO.data.patientLocations[0].postalCode.postalCode1 = updatedData.zip;
    patientDTO.data.patientLocations[0].postalCode.city = updatedData.city;
    patientDTO.data.patientLocations[0].postalCode.state.stateId = state.find(o=>o.value ===updatedData.state).id;
    patientDTO.data.patientLocations[0].postalCode.state.stateCode = updatedData.state;
    patientDTO.data.patientLocations[0].modifiedBy = onlineUserId;
    patientDTO.data.patientLocations[0].createdBy = onlineUserId;;
    //patientCondition
    patientDTO.data.patientConditions[0].modifiedBy = onlineUserId;
    patientDTO.data.patientConditions[0].createdBy = onlineUserId;

   return patientDTO;

};