import { PROGRAMS } from "constants/Config/programsConfig";
import { t } from "i18next";
import { AnyArray } from "immer/dist/internal";

export enum PatientSurveyScreens {
    Search = 1,
    SelectProduct = 2,
    Survey = 3,
    Review = 4,
    Confirmation = 5,
    Restart = 6,
    Disclaimer = 7,
    Error=8
}

export enum PossibleResponses {
    Yes = 1,
    No = 2,
    Dont = 3
}
export enum PatientSurveyFlowType {
    SearchPage = 1,
    HomePage = 2,
    PatientEnrollmentPage = 3,
    PrescriberSurveyPage = 4
}

export const patientDetails = [
    {
        name: 'firstName',
        label: 'patientFirstName',
        type: 'text',
        isRequired: true,
        helpText: '',
        maxLength: 30
    },
    {
        name: 'lastName',
        label: 'patientLastName',
        type: 'text',
        isRequired: true,
        helpText: '',
        maxLength: 30
    },
    {
        name: 'patientIdentification',
        label: 'patientIdentificationNumber',
        type: 'text',
        isRequired: true,
        helpText: 'message_PH0040',
        maxLength: 9
    },
];


export const products=() =>{
    let productsAll :any=[];
    PROGRAMS.forEach((i)=>{
       
        productsAll.push({
            name:i.label,
            link:i.patSurveyLink,
            linkText:i.patSurveyLinkText
        });
    })
    return productsAll;
}