
import classes from '../logDispense.module.scss';
import { Table } from 'antd';
import ExpandConfirmationRow from './expandConfirmationRow';
import { logDispenseConfirmation } from 'assets/objects/logDispenseConfirmation';


const PrintConfirmationTable = ({tabData}) => {

	const ConfirmationColumns: any = [
		{
			title: 'Confirmation Number',
			dataIndex: 'Confirmation',
			key: 'Confirmation',
		},
		{
			title: 'Product Identification',
			dataIndex: 'ProductIdentification',
			key: 'ProductIdentification',
		},
		{
			title: 'Authorization Number',
			dataIndex: 'AuthorizationNumber',
			key: 'AuthorizationNumber',
		},
		{
			title: 'Authorization Status',
			dataIndex: 'AuthorizationStatus',
			key: 'AuthorizationStatus',
		},
		{
			title: 'Confirmation Date',
			dataIndex: 'ConfirmationDate',
			key: 'ConfirmationDate',
		},
		{
			title: 'Action Required',
			dataIndex: 'ActionRequiredPrint',
			key: 'ActionRequiredPrint',
		},
		{
	        title: 'Patient Name',
	        dataIndex: 'patientName',
            key: 'PatientName',
	    },
		];

	return (
		<>
			<div style={{width:'90%',margin:'0 auto'}}>
				<p style={{fontWeight:'bold'}}>Log Dispense Confirmation</p>
					<div
					style={{fontWeight:'400',marginBottom:'20px'}}
					dangerouslySetInnerHTML={{ __html: logDispenseConfirmation.body[0].content }}
					/>
					<Table
					size="small"
					className="confirmationTable"
					dataSource={tabData}
					columns={ConfirmationColumns}
					expandable={{
						expandedRowRender: ExpandConfirmationRow,
						defaultExpandAllRows: true,
						expandIconColumnIndex: -1,
						expandIcon: () => null,
					}}
					pagination={false}
					/>
			</div>
		</>
	);
};

export default PrintConfirmationTable;
