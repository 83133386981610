import { isE } from '@rc-component/mini-decimal/es/numberUtil';
import moment from 'moment-timezone';
export const getReportsPrintHistAuthTemplate = (content,isExpiration) => {
    const date = moment().tz('America/New_York');
    const fdate = date.format('ddd MMM DD HH:mm:ss z YYYY');
    const dateHeader = isExpiration === "Expired"? "Expiration" :isExpiration === "Obsolete"? "Cancelled" : "Confirmation";
    
    const patientListTableRenderer = () => {
        const patientRows = () => {
            let rows;
            if (content && content.length > 0) {
                rows = content
                    .map((each) => {
                        return `
                        <tr class="odd">
                        <td class="startRow" style="text-align: left;">
                        ${each.patientNameLast && each.patientNameLast},
                        <br />
                        ${each.patientNameFirst && each.patientNameFirst}
                        </td>
                        <td>
                        ${each.productName
                            && each.productName}
                        </td>
                       
                        <td>
                        ${each.authorizationNumber && each.authorizationNumber.substring(2)}
                        </td>
                       
                        <td>
                        ${each.assocPrescriberNameLast && each.assocPrescriberNameLast}
                        <br />
                        ${each.assocPrescriberNameFirst && each.assocPrescriberNameFirst}
                        </td>
                        <td>
                            ${ isExpiration === "Expired" ? each.expirationDate && moment(each.expirationDate).format('MMM DD, YYYY') : isExpiration === "Obsolete"? each.obsoleteDate && moment(each.obsoleteDate).format('MMM DD, YYYY') : each.confirmationDate && moment(each.confirmationDate).format('MMM DD, YYYY')}
                        </td>
                        <td>
                        ${each.pharmacyName && each.pharmacyName || ''}
                        </td>
                        
                    </tr>

                `;
                    })
                    .join('');
            } else {
                if (content.length === 0) {
                    rows = `<tr class="odd">
                    <td colspan="14" align="center">
                        No Records Found.                  
                    </td>
                </tr>`;
                }
            }

            return rows;
        };

        return `
        <table width="710px" cellpadding="0" cellspacing="0" border="0" align="center">
		<colgroup>
			<col width="9px">
			<col width="700px">
			<col width="9px">
		</colgroup>
		<tbody><tr class="mainContentContainerTopBorder">
			<td><span class="leftCorner"></span></td>
			<td><span class="middleEdge"></span></td>
			<td><span class="rightCorner"></span></td>
		</tr>
		<tr>
			<td colspan="3">
				<table width="710px" cellpadding="0" cellspacing="0" border="0" class="mainContentContainer">
					<tbody><tr>
						<td>&nbsp;</td>
						<td>
							<div class="clear"></div>
							<br>
							<table width="710px" cellpadding="0" cellspacing="0" border="0">
								<tbody><tr>
									<td colspan="3" class="tabbedGradientContainer">
									<table width="710px" cellpadding="0" cellspacing="0" border="0" align="center">
											<tbody><tr>
												<td width="10px">&nbsp;</td>
												<td>
													<table width="710px" cellpadding="5" cellspacing="0" style="border-color:black;" border="1" class="dataTable">
														<thead>
                                                        <tr valign="top" class="splitHeader">
															<th align="left" class="topHeader" rowspan="2">
																Patient Name
															</th>
															<th align="left" class="topHeader" rowspan="2">
																REMS Program
															</th>
															<th align="left" rowspan="2">
																Authorization Number
															</th>
															<th align="left" rowspan="2">
																Prescriber
															</th>
                                                            <th align="left" rowspan="2">
                                                                ${dateHeader} Date
                                                            </th>
                                                            <th align="left" rowspan="2">
																Pharmacy Name
															</th>
														</tr>
														
														</thead>
														<tbody>	
                                                        ${patientRows()}
														
														
													</tbody></table><!--
													 Display pagination bar only more than 1 page 
													
												--></td>
												<td width="10px">&nbsp;</td>
											</tr>
										</tbody></table>
									</td>
								</tr>
								</tbody></table>
							<div class="buffer">&nbsp;</div>
						</td>
						<td>&nbsp;</td>
					</tr>
				</tbody></table>
			</td>
		</tr>
		
	</tbody></table>
            `;
    };

    return `
        <html>
            <head>
                <title>${window.location.href}</title>
                <style>
                html{
                    overflow-y: auto;
                    overflow-x:hidden;
                }
                    tbody tr th, 
                    tbody tr td{
                        font-size:14px;
                    } 
                    .bottom{
                        font-size:13px;
                        margin-bottom:10px;
                    }
                    .date {
                        margin-top: -10px;
                        font-size: 9px;
                        text-align: center;
                    }
                    body{
                        height: 95vh;
                       
                        margin:0;
                        @media print{
                            height: auto;
                        }
                    }
                </style>
            </head>
            <body>
            <div class="header">
            <span class="icon logo left"></span>
            <div align="center" style="font-size: 2em;">Historical Authorization Report</div>
            <div align="center">
                ${fdate}
            </div>
            <div class="buffer clear"></div>
        </div>
        
              <div>  ${patientListTableRenderer()}</div>
           
               
                <div class="bottom" align="center">Bristol Myers Squibb</div>
                <br/>
            </body>
        </html>
        `;
};
