import { useAppDispatch, useAppSelector } from 'store';

import image_styles from '../PatientEnrollment/PatientAgreementForm.module.scss';

import {
    PatientAgreementLn,
    PatientAgreementPol,
    PatientAgreementTh,
    PatientAgreementLen_Spanish,
    PatientAgreementPol_Spanish,
    PatientAgreementTh_Spanish,
} from 'assets/objects/PatientAgreements';
import {
    PatientAgreementLnOld,
    PatientAgreementPolOld,
    PatientAgreementThOld,
    PatientAgreementLen_SpanishOld,
    PatientAgreementPol_SpanishOld,
    PatientAgreementTh_SpanishOld,
} from 'assets/objects/PatientAgreementsOld';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import { useEffect, useRef, useState } from 'react';
import PatientFormField from './PatientFormField';
import { changeHelpContentKey, patientChecklist, patientProgramList, setPatientData } from 'store/slices/appSlice';

import { useTranslation } from 'react-i18next';
import {
    axiosInstancePrescriber,
    axiosInstance_2,
    axiosInstance_ORC,
    axiosInstance_PDF,
} from 'constants/Config/axiosConfig';
import { getPatientReqBody } from 'utils/patientSave';
import Alert from 'components/Alert';
import { useNavigate } from 'react-router-dom';
import { isValidName } from 'utils/regex';
import SessionTimeout from 'components/SessionTimeout';
import AlertClass from '../../components/Alert/Alert.module.scss';
import Button from 'components/Button';
import {buttonContent, getBtnClass} from '../../assets/objects/buttonContent';
import { digitalSignatureAuth } from 'store/slices/loginSlice';
import { authenticateErrCodeMapper, congitoResMapper } from 'utils/congitoResMapper';
import {ProgramConfig,lenalidomide,mainMenu,thalLogoImg,thalWhiteImg} from 'constants/Config/programsConfig';
import moment from 'moment';
import {reloadFunction} from '../../utils/utilsCommon';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PomalystTextToPomalidomide = 'Pomalidomide';
const PatientAgreementForm = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const containerRefDigital = useRef<HTMLDivElement | null>(null);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        patientInformation,
        selectedPatientProgram,
        selectedProgram,
        patientProgramList: patientArray,
        prescriberDetails,
        patientResponse,
        ppafContent,
        patientReactivationStore,
        patientCamundaStore,
    } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const [currentFocus, setCurrentFocus] = useState('');
    const IsES: boolean = i18n.language === 'es';
    const isPomNewContent = ProgramConfig('rems_program_pomalyst')?.label;
    const activePomProduct = 'Pomalidomide';
    const getProduct = (language: string) => {
        if (language === 'es') {
            if (selectedPatientProgram.id === 'rems_program_lenalidomide') return PatientAgreementLen_Spanish;
            else if (selectedPatientProgram.id === 'rems_program_pomalyst'){
                if(isPomNewContent === activePomProduct){
                    return PatientAgreementPol_Spanish;
                } else{
                    return PatientAgreementPol_SpanishOld;
                }
            } 
            else if (selectedPatientProgram.id === 'rems_program_thalomid') return PatientAgreementTh_Spanish;
        } else if (language === 'en') {
            if (selectedPatientProgram.id === 'rems_program_lenalidomide') return PatientAgreementLn;
            else if (selectedPatientProgram.id === 'rems_program_pomalyst'){
                if(isPomNewContent === activePomProduct){
                    return PatientAgreementPol;
                } else{
                    return PatientAgreementPolOld;
                }
            } 
            else if (selectedPatientProgram.id === 'rems_program_thalomid') return PatientAgreementTh;
        }
    };
    const arrgementContent = getProduct(i18n.language);
    const arrgementContent_en = getProduct('en');

    const [prescriberLogin, setprescriberLogin] = useState({
        username: '',
        password: '',
    });
    const [saveMessage, setSaveMessage] = useState('');
    const [error, setError] = useState('');
    const [IsSubmitButton, setIsSubmitButton] = useState(false);
    const [isSaveButton, setIsSaveButton] = useState(false);
    const [digSignatureError, setDigSignatureError] = useState('');
    const [patientValidationError, setPatientValidationError] = useState('');
    const [patientList, setPatientList] = useState<any>({});
    const [IsCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [digitalSignatureError, setdigitalSignatureError] = useState('');
    const patientSavedData = [
        {
            name: 'prescriber_name',
            label: 'Prescriber Name',
            type: 'hidden',
            isRequired: false,
            inputValue: `${prescriberDetails.firstName} ${prescriberDetails.lastName}`,
        },
        {
            name: 'pres_id_number',
            label: 'Prescriber Identification Number',
            type: 'hidden',
            isRequired: false,
            inputValue: `${prescriberDetails.pin}`,
        },
        {
            name: 'address',
            label: 'Address',
            type: 'hidden',
            isRequired: false,
            inputValue: prescriberDetails.selectedAddress
                ? `${prescriberDetails.selectedAddress}, ${prescriberDetails.city}, ${prescriberDetails.state} ${prescriberDetails.zip}`
                : '',
        },
        {
            name: 'phonenumber',
            label: 'Phone Number',
            type: 'hidden',
            isRequired: false,
            inputValue: `${prescriberDetails.phone}`,
        },
        {
            name: 'fax',
            label: 'Fax',
            type: 'hidden',
            isRequired: false,
            inputValue: `${prescriberDetails.fax}`,
        },
    ];
    const userDetails = [
        {
            name: 'username',
            label: 'User Name',
            type: 'text',
            isRequired: true,
            inputValue: prescriberLogin.username,
        },
        {
            name: 'password',
            label: 'Password',
            type: 'password',
            isRequired: true,
            inputValue: prescriberLogin.password,
        },
    ];

    useEffect(() => {
        if (!signSubmitClicked) dispatch(changeHelpContentKey('patientagreementform'));
    }, []);

    const translateLabel = (label) => {
        switch (label) {
            case 'Prescriber Name':
                return 'Nombre del prescriptor:<br> Prescriber Name';
            case 'Prescriber Identification Number':
                return 'Número de identificación del prescriptor:<br> Prescriber Identification Number';
            case 'Address':
                return 'Dirección:<br> Address';
            case 'Phone Number':
                return 'Número de teléfono:<br> Telephone Number';
            case 'Fax':
                return 'Número de Facsímil:<br> Fax';
            case 'User Name':
                return 'Nombre de usuario:<br> User Name';
            case 'Password':
                return 'Contraseña:<br> Password';
            default:
                return label;
        }
    };
    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };
    const scrollToViewBottom = () => {
        setTimeout(() => {
            containerRefDigital?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }, 10);
    };

    const productType = ProgramConfig(selectedPatientProgram.id)?.label

    const patientDataHandler = (data) => {
        setPatientList(data);
    };

    const [signSubmitClicked, setSignSubmitClicked] = useState(false);
    const dob = `${patientInformation?.month} ${patientInformation?.date}, ${patientInformation?.year}`;
    const convertedDate = moment(dob, 'MMM DD,YYYY').format('MM/DD/YYYY');
    const [arrgementCheckboxs, setArrgementCheckBox] = useState<any>([]);
    const [authorizationCheckboxs, setAuthorizationCheckboxs] = useState<any>([]);
    const [statementId, setStatementId] = useState<any>([]);
    const [finalTreatmentArragement, setFinalTreatmentArragement] = useState<boolean>(false);
    useEffect(() => {
        reloadFunction();
    }, []);
    useEffect(() => {
        let arrgementCheckboxs: any[] = [];
        let authorizationCheckboxs: any[] = [];
        ppafContent &&
            ppafContent.length &&
            ppafContent.map((row: any) => {
                if (row.consentStatementText && row.consentStatementText !== null) {
                    if (row.consentStatementSection?.toLowerCase() === 'section 1. patient agreement') {
                        arrgementCheckboxs.push({
                            ...row,
                            text: row.consentStatementText,
                            IsChecked:
                                patientInformation.flowType && patientInformation.flowType === 'WorkPartialCompleted'
                                    ? true
                                    : false,
                            Id: row.consentStatementId,
                        });
                    } else if (row.consentStatementSection?.toLowerCase() === 'section 2. authorization') {
                        authorizationCheckboxs.push({
                            ...row,
                            text: row.consentStatementText,
                            IsChecked:
                                patientInformation.flowType && patientInformation.flowType === 'WorkPartialCompleted'
                                    ? true
                                    : false,
                            Id: row.consentStatementId,
                        });
                    }
                }
            });

        setArrgementCheckBox(arrgementCheckboxs);
        setAuthorizationCheckboxs(authorizationCheckboxs);
        fetchConsentStatementId(arrgementCheckboxs, authorizationCheckboxs);
        if (patientInformation.flowType && patientInformation.flowType === 'WorkPartialCompleted') {
            setSignSubmitClicked(true);
            setFinalTreatmentArragement(true);
            patientDataHandler(patientArray);
            dispatch(changeHelpContentKey('patientagreementformPrescriberSign'));
            setCurrentFocus('username');
            scrollToViewBottom();
        }
    }, []);

    const fetchConsentStatementId = (array1, array2) => {
        const idArray = [...array1.map((obj) => obj.Id, ...array2.map((obj) => obj.Id))];
        setStatementId(idArray);
    };

    const submitApiCall = async (reqObj, apiEndpoint) => {
        try {
            await axiosInstance_ORC
            .post(`/patient/${apiEndpoint}`, reqObj)
            .then((response:any) => {
                    if(apiEndpoint === 'register-submit' && response?.response?.data?.statusCode == '422'){
                        sessionStorage.setItem('ppafConfirm','Failure')
                        setIsSubmitButton(false);
                        navigate('/prescriber/ppafConfirmation');
                    } else {
                    dispatch(setPatientData(response.data.data));
                    if (apiEndpoint === 'register-submit' && response.data.data) {
                        navigate('/prescriber/ppafConfirmation');
                    }
                    setIsSubmitButton(false);
                }})
                .catch((error) => {
                    if(error.statusCode == '422'){
                    sessionStorage.setItem('ppafConfirm','Failure')
                    setIsSubmitButton(false);
                    navigate('/prescriber/ppafConfirmation');
                    }else {
                        setIsSubmitButton(false);
                        navigate('/prescriber/SystemError');
                    }
                });
        } catch (err) {
            setIsSubmitButton(false);
            return false;
        }
    };

    const updateArrgementCheckbox = (id) => {
        const item = arrgementCheckboxs.map((x) => {
            if (x?.Id === id) {
                x.IsChecked = !x.IsChecked;
            }
            return x;
        });
        setArrgementCheckBox(item);
    };

    const updateAuthorizationCheckbox = (id) => {
        const item = authorizationCheckboxs.map((x) => {
            if (x?.Id === id) {
                x.IsChecked = !x.IsChecked;
            }
            return x;
        });
        setAuthorizationCheckboxs(item);
    };

    const trimValues = () => {
        const newVals = { ...patientList };
        Object.keys(newVals).forEach((field) => {
            if (field !== 'relation' && field !== 'checked') {
                newVals[field] = newVals[field]?.trim();
            }
        });
        setPatientList(newVals);
        return newVals;
    };

    const uncheckBoxIndex = arrgementCheckboxs.findIndex((item) => !item.IsChecked);

    const getOldRisk = (riskArr) => {
        return riskArr.reduce((max, current) =>
            new Date(moment(current.effectiveDate).format()) > new Date(moment(max.effectiveDate).format()) ? current : max
        );
    };

    const IsValidatePatientSign = (): boolean => {
        // Validate arrgementCheckboxs - Session 1
        if (
            arrgementCheckboxs.filter((x, index) => {
                return x.IsChecked === false;
            }).length !== 0
        ) {
            setError(t('checklistErrorMsg'));
            if (uncheckBoxIndex >= 10) {
                setCurrentFocus('authorizedcheckbox');
            } else setCurrentFocus(`checkbox-${uncheckBoxIndex}`);
            return false;
        }

        // Validate authorizationCheckboxs - Session 2
        else if (
            authorizationCheckboxs.filter((x, index) => {
                return x.IsChecked === false;
            }).length !== 0
        ) {
            setError(t('checklistErrorMsg'));
            setCurrentFocus('authorizedcheckbox');
            return false;
        }

        // validate patient details
        else if (
            patientList.relation == '' ||
            patientList.relation == 'Seleccionar Uno' ||
            patientList.relation == 'Select One'
        ) {
            setError(t('error_relationtopatient'));
            setCurrentFocus('relation');
            return false;
        } else if (patientList.firstName == '' || !isValidName(patientList.firstName)) {
            if (patientList.relation === 'Self' || patientList.relation === 'Yo mismo') setError(t('error_PV0690'));
            else if (
                patientList.relation === 'Authorized Representative' ||
                patientList.relation === 'Representante Autorizado'
            )
                setError(t('error_ARFirstName'));
            setCurrentFocus('firstName');
            return false;
        } else if (patientList.lastName == '' || !isValidName(patientList.lastName)) {
            if (patientList.relation === 'Self' || patientList.relation === 'Yo mismo') setError(t('error_PV0685'));
            else if (
                patientList.relation === 'Authorized Representative' ||
                patientList.relation === 'Representante Autorizado'
            )
                setError(t('error_ARLastName'));
            setCurrentFocus('lastName');
            return false;
        } else if (!patientList?.checked) {
            setError(t('checkbox_unchecked'));
            setCurrentFocus('patientCheckbox');
            return false;
        } else {
            return true;
        }
    };

    const getRiskCategorySeq = (existingRisk, payloadObj) => {
        if (existingRisk && existingRisk?.riskCategoryName) {
            if (existingRisk?.riskCategoryName !== patientInformation?.newRisk) {
                return null;
            } else {
                return payloadObj.patientRiskCategory[0].riskCategoryId?.riskCategorySeq;
            }
        } else {
            return payloadObj.patientRiskCategory[0].riskCategoryId?.riskCategorySeq;
        }
    };

    const fetchAddIds = () => {
        if (
            patientInformation.flowType &&
            ['Reactivate', 'WorkPartial', 'WorkPartialCompleted'].includes(patientInformation.flowType)
        ) {
            const existingRisk = patientInformation.oldRisk ? getOldRisk(patientInformation.oldRisk) : null;
            const payloadObj =
                patientInformation.flowType === 'Reactivate' ? patientReactivationStore?.data : patientCamundaStore;
            return {
                languageId: payloadObj.language.languageId,
                patientLocationSeq: payloadObj.patientLocations[0].patientLocationSeq,
                patientPrescriberSeq: payloadObj.patientPrescribers[0].patientPrescriberSeq,
                prescriberLocationSeq: prescriberDetails.addressList.find((o) => o.primaryLocation === 'Y')
                    .prescriberLocationSequence,
                patientRegistrationFormSeq: payloadObj.patientRegistrationForms[0].patientRegistrationFormSeq,
                statusLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].registrationStatus?.lookupId,
                reasonLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].callReason?.lookupId,
                changeLookupId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].reasonForChange?.lookupId,
                deactivateLookupId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChange?.lookupId,
                reactivateLookupId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].reactivateReasonChange?.lookupId,
                registrationStatusId: payloadObj.patientRegistrationForms[0].patientRegStatus[0].registrationStatusId,
                deactivateReasonChangeId:
                    payloadObj.patientRegistrationForms[0].patientRegStatus[0].deactivateReasonChangeId,
                conditionId: payloadObj.patientConditions.find(o=> !o.patientConditionEndDate)?.patientConditionId?.conditionId,
                riskCategoryId: patientInformation?.riskCategoryId || payloadObj.patientRiskCategory[0].riskCategoryId.riskCategoryId,
                oldRiskCategory:
                    existingRisk?.riskCategoryName && existingRisk?.riskCategoryName !== patientInformation?.newRisk
                        ? existingRisk?.riskCategoryName
                        : null,
                riskCategorySeq: getRiskCategorySeq(existingRisk, payloadObj),
            };
        } else {
            const selectedAddress = prescriberDetails.selectedAddress;
            const selectedLocationSequence = prescriberDetails.addressList.find(
                (item) => item.address === selectedAddress
            ).prescriberLocationSequence;
            return { prescriberLocationSeq: selectedLocationSequence,
                patientLocationSeq: patientInformation?.flowType === 'Reenroll' ? patientResponse?.patientLocations[0]?.patientLocationSeq :null,
            };
        }
    };

    const submitButtonHandler = async () => {
        trimValues();
        if (IsValidatePatientSign()) {
            dispatch(patientProgramList({ ...patientList, finalTreatmentArragement, modifiedDate: new Date() }));
            //dispatch(patientChecklist(checkedList));
            setSignSubmitClicked(true);
            const reqObj = getPatientReqBody({
                ...patientInformation,
                dob: convertedDate,
                prescriberExtUniqueId: currentUser.systemId || prescriberDetails.prescriberSystemId,
                productType,
                menstrating: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.menstrating === 'No' ? 'N' : 'Y'),
                surgical: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.surgical === 'No' ? 'N' : 'Y'),
                natural: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.natural === 'No' ? 'N' : 'Y'),
                productCountryId: selectedProgram?.productCountryId,
                consentStatementId: statementId,
                signFirstName: patientList.firstName,
                signLastName: patientList.lastName,
                relation: patientList.relation,
                modifiedDate: patientList.relation,
                action: 'Save',
                locale: i18n.language,
                optinPatientMaterials: finalTreatmentArragement,
                ...fetchAddIds(),
            });
            submitApiCall(reqObj, 'register-save');
            dispatch(changeHelpContentKey('patientagreementformPrescriberSign'));
        } else {
            scrollToView();
            setSignSubmitClicked(false);
        }
    };
    // function groupBy<T>(arr: T[], fn: (item: T) => any) {
    //     return arr.reduce<Record<string, T[]>>((prev, curr) => {
    //         const groupKey = fn(curr);
    //         const group = prev[groupKey] || [];
    //         group.push(curr);
    //         return { ...prev, [groupKey]: group };
    //     }, {});
    // }

    const checkYesNo = (value: string) => {
        return value.toLowerCase() === 'no' || value.toLowerCase() === 'n' ? 'N' : 'Y';
    };
    const getSignatory = () => {
        switch (patientArray.relation) {
            case 'Authorized Representative':
                return 'authorizedSignatory';
            case 'Self':
                return 'self';
            case 'Representante Autorizado':
                return 'authorizedSignatory';
            case 'Yo mismo':
                return 'self';
            default:
                return '';
        }
    };
    const handlePrintButton = async () => {
        try {
            const _signatory = patientArray.relation;
            const patenrollstatus = patientCamundaStore?.patientPrescribers?.[0]?.status || '';
            const patientDtoResponse =
                Object.entries(patientResponse)?.length > 0
                    ? patientResponse
                    : (patenrollstatus === 'Partially Completed')
                        ? patientCamundaStore
                        : {};

            let reqBody: any = {
                patientDto: {
                    ...patientDtoResponse,
                    channelId: 'ext',
                    patientRiskCategoryDto: {
                        gender: patientDtoResponse?.patientGender,
                        riskCategory: patientInformation.newRisk,
                        menstruating: checkYesNo(patientInformation.menstrating),
                        naturalMenopause: checkYesNo(patientInformation.natural),
                        riskCategoryDescription: patientInformation.newRisk,
                        surgicalMenopause: checkYesNo(patientInformation.surgical),
                    },
                },
                notificationFax: null,
                notificationEmail: null,
                currentStep: 'patientSignature',
                action: 'print',
                signatory: getSignatory(),
                patientSignature: `${patientArray.firstName} ${patientArray?.lastName}`,
                patientSignatureDate: moment(new Date()).format('YYYY-MM-DD'),
                prescriberSignature: null,
                prescriberSignatureDate: null,
                language: i18n.language,
                receiveEducationalMaterial: finalTreatmentArragement,
            };
            //Hard code//
            //reqBody.patientDto.patientId = reqBody.patientDto.patientId ? reqBody.patientDto.patientId : 243130;
            //reqBody.patientDto.patientPrescribers[0].patientId =  reqBody.patientDto.patientId;
            /**********************/
            const response = await axiosInstance_PDF.post('/file-render/pdf/ppaf', reqBody);
            if (response.data.statusCode === 200) {
                // pdfConverter(response.data.data.pdf);
                sessionStorage.setItem('baseString', JSON.stringify(response.data.data.pdf));
                sessionStorage.setItem('fileName', JSON.stringify('patientPartialPPAF'));

                window.open('/#/pdfViewer', '_blank');
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            console.error(`Error getting pdf ${err}`);
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement?.focus();
        ['firstName', 'lastName', 'username', 'password'].includes(currentFocus) && inputElement?.select();
        setError('');
        setCurrentFocus('');
        setIsSubmitButton(false);
    };
    const handleDigErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement?.focus();
        ['firstName', 'lastName', 'username', 'password'].includes(currentFocus) && inputElement?.select();
        setCurrentFocus('');
        setDigSignatureError('');
        setIsSubmitButton(false);
        // setSignSubmitClicked(true);
    };
    const getProgramsName = (): string => {
      
        return mainMenu(selectedPatientProgram.id);
    };

    const handleSaveButton = () => {
        setIsSaveButton(true);
        const reqObj = getPatientReqBody({
            ...patientInformation,
            dob: convertedDate,
            prescriberExtUniqueId: currentUser.systemId || prescriberDetails.prescriberSystemId,
            productType,
            menstrating: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.menstrating === 'No' ? 'N' : 'Y'),
            surgical: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.surgical === 'No' ? 'N' : 'Y'),
            natural: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.natural === 'No' ? 'N' : 'Y'),
            productCountryId: selectedProgram?.productCountryId,
            signFirstName: patientList.firstName,
            signLastName: patientList.lastName,
            relation: patientList.relation,
            consentStatementId: statementId,
            action: 'Save',
            locale: i18n.language,
            ...fetchAddIds(),
        });
        setTimeout(() => {
            submitApiCall(reqObj, 'register-save');
            setSaveMessage(t('error_PD0450').replaceAll('{program}', getProgramsName()));
            scrollToView();
        }, 6500);
    };

    const updateUserDetails = (e) => {
        const { name, value } = e.target;
        setprescriberLogin((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const restDigitalSignature = () => {
        setprescriberLogin({ username: '', password: '' });
        setIsCheckBoxChecked(false);
        setIsSubmitButton(false);
        scrollToView();
    };
    const checkPatientValidation = async () => {
        const headerPatientValidation = {
            rems_program_thalomid: `<span>THALOMID REMS</span><span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>`,
            rems_program_lenalidomide: `<span>Lenalidomide REMS</span>`,
            rems_program_pomalyst: `<span>Pomalidomide REMS</span>`,
        };
        // const constructDOB = `${
        //     patientInformation.date
        // }-${patientInformation.month.toLocaleUpperCase()}-${patientInformation.year.trim()} 00:00:00`;

        const dob = `${patientInformation.date}-${patientInformation.month}-${patientInformation.year.trim()}`;
        const payload = {
            patientIdentificationNumber: patientInformation.patientIdNum.trim(),
            patientNameFirst: patientInformation.firstName.trim(),
            patientNameLast: patientInformation.lastName.trim(),
            patientDob: dob,
            patientGender: patientInformation.sex.charAt(0),
            productList: [selectedProgram.label],
        };
        let response;
        response = await axiosInstance_2
            .post(`/validate`, payload)
            .then((res) => {
                return res?.status === 200;
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    let errorMsg = '';
                    if (
                        error.response.data.data.errorMesssage?.code === 10142 ||
                        error.response.data.data.errorMesssage[0]?.code === 10143
                    ) {
                        let errorMsg_2 = i18n.language === 'es' ? `<br/>${t('errMsg_PV0455_3')}` : ``;
                        //headerPatientValidation[selectedProgram.id]
                        errorMsg = `<span> ${t('errMsg_PV0455_1')}${ mainMenu(selectedProgram.id)}${t(
                            'errMsg_PV0455_2'
                        )}${errorMsg_2}</span>`;
                    } else if (error.response.data.data.errorMesssage.code === 10141) {
                        
                        setIsSubmitButton(false);
                        sessionStorage.setItem('ppafConfirm','Failure')
                        navigate('/prescriber/ppafConfirmation');
                    }
                    setPatientValidationError(errorMsg);
                    scrollToView();
                    return false;
                } else {
                    return false;
                }
            });

        return response;
    };
    const digitalSignature = async () => {
        try {
            const userInputs=Object.entries(prescriberLogin).reduce((acc,[key,value])=>{
                acc[key]=typeof value==='string' ? value.trim() :value;
                return acc
            },{});
        
            const response: any = await dispatch(digitalSignatureAuth(userInputs));
            const msgCode = authenticateErrCodeMapper(response?.payload?.message);
            if (response?.payload?.AuthenticationResult?.AccessToken) {
                const authRes:any = congitoResMapper(response?.payload?.AuthenticationResult);
                const isValidPatient =
                    patientInformation.flowType === 'Reactivate' ? true : await checkPatientValidation();
                if (isValidPatient) {
                    if (
                        authRes?.currentUser?.systemId === currentUser?.systemId ||
                        authRes?.currentUser?.userinfo?.username === currentUser.userinfo.username ||
                        authRes?.currentUser?.systemId === prescriberDetails.prescriberSystemId.toString()
                    ) {
                        const payload = authRes?.currentUser?.systemId
                            ? {
                                prescriberId: authRes?.currentUser?.systemId,
                            }
                            : {
                                //   onlineUserId: userinfo?.username,
                                prescriberId: prescriberDetails.prescriberSystemId,
                            };
                        const findprescriber = await axiosInstancePrescriber.post(
                            '/v1/prescriber/findprescriber',
                            payload
                        );
                        if (findprescriber.status === 200) {
                            const enrollmentPayload: any[] = findprescriber.data?.data?.prescriberRegStatuses;
                            if (
                                enrollmentPayload?.filter(
                                    (x) =>
                                        x.registrationStatus.registrationStatus === 'Active' &&
                                        x.id.productCountryId.productCountryId === selectedProgram.productCountryId
                                ).length > 0
                            ) {
                                const _prescriberId: number = findprescriber?.data?.data?.prescriberId;
                                //prescriberId = _prescriberId.toString();
                                return true;
                            } else {
                                setdigitalSignatureError(
                                    t('error_PD0406').replaceAll('{program}', getProgramsName())
                                );
                                restDigitalSignature();
                                return false;
                            }
                        } else {
                            // error
                            return false;
                        }
                    } else {
                        setdigitalSignatureError(t('error_PV0292'));
                        restDigitalSignature();
                        return false;
                    }
                }
            } else if (
                msgCode === 100 ||
                msgCode === 101 
                // response.data.statusCode === 102 not coming
            ) {
                setdigitalSignatureError(t('error_PV0050')); 
                restDigitalSignature();
                return false;
            } else if (msgCode === 103) {
                setdigitalSignatureError(t('error_PV0315'));
                restDigitalSignature();
                return false;
            } else {
                setdigitalSignatureError(response?.payload?.message || '');
                restDigitalSignature();
                return false;
            }
        } catch (err) {
            setIsSubmitButton(false);
            return false;
            
        }
    };
    const handleSaveAndSubmit = async () => {       
        setIsSubmitButton(true);
        setdigitalSignatureError('');
        const reqObj = getPatientReqBody({
            ...patientInformation,
            dob: convertedDate,
            prescriberExtUniqueId: currentUser.systemId || prescriberDetails.prescriberSystemId,
            productType,
            menstrating: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.menstrating === 'No' ? 'N' : 'Y'),
            surgical: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.surgical === 'No' ? 'N' : 'Y'),
            natural: patientInformation.sex.charAt(0) === 'M' ? null:(patientInformation.natural === 'No' ? 'N' : 'Y'),
            productCountryId: selectedProgram?.productCountryId,
            consentStatementId: statementId,
            signFirstName: patientList.firstName,
            signLastName: patientList.lastName,
            relation: patientList.relation,
            action: 'SUBMIT',
            locale: i18n.language,
            optinPatientMaterials: finalTreatmentArragement,
            ...fetchAddIds(),
        });
        setSignSubmitClicked(true);
        if (prescriberLogin.username.length === 0) {
            setDigSignatureError(t('error_PV0285'));
            setCurrentFocus('username');
            scrollToView();            
            setIsSubmitButton(false);
            return;
        } else if (prescriberLogin.password.length === 0) {
            setDigSignatureError(t('error_PV0150'));
            setCurrentFocus('password');
            scrollToView();
            setIsSubmitButton(false);
            return;
        } else if (!IsCheckBoxChecked) {
            setDigSignatureError(t('error_PV0290'));
            scrollToView();
            setCurrentFocus('prescriberCheckbox');
            setIsSubmitButton(false);
            return;
        } else if (
            !currentUser.groupTag.includes('NonPrescriber') &&
            prescriberLogin.username.toLowerCase()?.trim() !== currentUser.userinfo?.username.toLowerCase()?.trim()
        ) {
            setdigitalSignatureError(t('error_PV0292'));
            scrollToView();
            restDigitalSignature();
            setIsSubmitButton(false);
        } else if (
            currentUser.groupTag.includes('NonPrescriber') && prescriberDetails.onlineUserid && 
            prescriberLogin.username.toLowerCase()?.trim() !== prescriberDetails?.onlineUserid?.toLowerCase()?.trim()
        ) {
            setdigitalSignatureError(t('error_PV0292')); // change for non Prescriber
            scrollToView();
            restDigitalSignature();
            setIsSubmitButton(false);
        } else {
            const IsValidSignature = await digitalSignature();
            if (IsValidSignature) {                
                submitApiCall(reqObj, 'register-submit');
            } else {
                scrollToView();
                setIsSubmitButton(false);
            }
        }
    };

    const groupBy = (xs, f): any[] => {
        return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };

    const buildCheckBox = (group) => {
        return (
            group &&
            group
                .sort((x) => x.consentStatementSeq - x.consentStatementSeq)
                .map((item1, index) => {
                    return (
                        <div className={image_styles.argreementFormat}>
                            <div
                                style={{
                                    marginTop: index === 0 ? '5px' : '1px',
                                }}
                            >
                                <input
                                    // style={{ border: uncheckBoxIndex ? '1px solid red' : '1px solid blue' }}
                                    disabled={signSubmitClicked}
                                    onChange={(e) => updateArrgementCheckbox(item1.Id)}
                                    className={image_styles.checkboxArragement}
                                    type="checkbox"
                                    // id="efg"
                                    id={`checkbox-${uncheckBoxIndex}`}
                                    checked={item1.IsChecked}
                                ></input>
                            </div>
                            <span
                                className={image_styles.labelArrgement}
                                dangerouslySetInnerHTML={{
                                    __html: t(item1.text) || '',
                                }}
                            ></span>
                        </div>
                    );
                })
        );
    };

    const arrgementGroup = (arg) => {
        const group: any = groupBy(arg, (c) => c.consentStatementGroup);
        return (
            <>
                {group['1-I Understand and Confirm that:'] && buildCheckBox(group['1-I Understand and Confirm that:'])}
                {group['2-I Will:'] && (
                    <>
                        <p>
                            <b>{t('IWill')}</b>
                        </p>
                        {buildCheckBox(group['2-I Will:'])}
                    </>
                )}
                {group['3-I Will not:'] && (
                    <>
                        <p>
                            <b>{t('IWillNot')}</b>
                        </p>
                        {buildCheckBox(group['3-I Will not:'])}
                    </>
                )}
                {group['4-I will tell my child that:'] && (
                    <>
                        <p>
                            <b>{t('IWillTellMyChild')}</b>
                        </p>
                        {buildCheckBox(group['4-I will tell my child that:'])}
                    </>
                )}
                {group['4-I will tell my Child that:'] && (
                    <>
                        <p>
                            <b>{t('IWillTellMyChild')}</b>
                        </p>
                        {buildCheckBox(group['4-I will tell my Child that:'])}
                    </>
                )}
            </>
        );
    };
    const handleTreatmentArragementCheckbox = () => {
        setFinalTreatmentArragement(!finalTreatmentArragement);
    };

    return (
        <>
            <SessionTimeout />
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
            {digSignatureError && <ErrorModal error={digSignatureError} handleContinue={handleDigErrorBtn} />}

            <div
                // ref={containerRef}
                ref={
                    patientInformation?.flowType && patientInformation?.flowType === 'WorkPartialCompleted'
                        ? containerRefDigital
                        : containerRef
                }
                className={`${image_styles.patient_wrapper}  ${image_styles[selectedPatientProgram.id]}`}
            >
                <div className={`${image_styles[i18n.language]}`}>
                    <div></div>
                    <span className={image_styles.generalProgramLogo} style={{
                        backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(selectedProgram.id).headerImage}`)})`
                    }}></span>
                    <div className={image_styles.generalHeaderDivider} style={{
                        borderColor: `${ProgramConfig(selectedProgram.id).color}`
                    }}></div>
                    {arrgementContent?.body && (
                        <>
                            <div
                                className={image_styles.pgm_name}
                                dangerouslySetInnerHTML={{ __html: t(arrgementContent?.body.formHeading) }}
                            ></div>
                            <div
                                className={image_styles.note}
                                dangerouslySetInnerHTML={{ __html: t(arrgementContent?.body.fields) }}
                            ></div>
                            <div className={image_styles.product_wrapper}>
                                {saveMessage && (
                                    <Alert content={saveMessage} styles={{ margin: '10px 20px 5px 20px' }} />
                                )}
                                <div className={image_styles.topnotes}>
                                    <span>{t(arrgementContent?.body.bodynote)}</span>
                                </div>
                                <div className={image_styles.bodyContent}>
                                    {arrgementContent?.body.disclaimers
                                        .filter((x) => {
                                            return x.riskCatergory?.includes(patientInformation.newRisk);
                                        })
                                        .map((item, index) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        dangerouslySetInnerHTML={{ __html: t(item.content) || '' }}
                                                    ></div>
                                                </>
                                            );
                                        })}
                                    {arrgementContent?.body.patientArgeement && (
                                        <>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: t(arrgementContent?.body.patientArgeement.note),
                                                }}
                                            ></span>
                                            <div className={image_styles.productDetailContainer}>
                                                {t(arrgementContent?.body.patientArgeement.header)}
                                            </div>
                                            <div>
                                                <p className={image_styles.bold}>
                                                    {t(arrgementContent?.body.patientArgeement.subtitle)}
                                                </p>
                                            </div>
                                            <div>
                                                {arrgementCheckboxs && arrgementCheckboxs.length ? (
                                                    arrgementGroup(arrgementCheckboxs)
                                                ) : (
                                                    <></>
                                                )}
                                                <div className={image_styles.buffer}></div>
                                            </div>
                                        </>
                                    )}

                                    {arrgementContent?.body.authorization && (
                                        <>
                                            <div className={image_styles.productDetailContainer}>
                                                {t(arrgementContent?.body.authorization.header)}
                                            </div>
                                            {arrgementContent?.body.authorization.disclaimers
                                                .filter((x) => {
                                                    return x.riskCatergory?.includes(patientInformation.newRisk);
                                                })
                                                .map((item, index) => {
                                                    return (
                                                        <>
                                                            <div
                                                                className={image_styles.authorizationSubDetails}
                                                                key={index}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: t(item.content) || '',
                                                                }}
                                                            ></div>
                                                        </>
                                                    );
                                                })}
                                            <div>
                                                {authorizationCheckboxs && authorizationCheckboxs.length ? (
                                                    <>
                                                        {authorizationCheckboxs.map((item1: any, index) => {
                                                            return (
                                                                <div className={image_styles.argreementFormat}>
                                                                    <div>
                                                                        <input
                                                                            disabled={signSubmitClicked}
                                                                            onChange={(e) =>
                                                                                updateAuthorizationCheckbox(item1.Id)
                                                                            }
                                                                            className={image_styles.checkboxArragement}
                                                                            type="checkbox"
                                                                            id={'authorizedcheckbox'}
                                                                            // id={`checkbox-${index}`}
                                                                            checked={item1.IsChecked}
                                                                        ></input>
                                                                    </div>
                                                                    <span
                                                                        className={image_styles.labelArrgement}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: t(item1.text) || '',
                                                                        }}
                                                                    ></span>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            {selectedPatientProgram.id === 'rems_program_lenalidomide' && (
                                                <>
                                                    <div style={{ float: 'right', margin: '19px 76px' }}>
                                                        <div 
                                                        className={`${image_styles.lenalidomide}`}
                                                        style={{
                                                            backgroundImage:`url(${require(`../../assets/images/${ProgramConfig(selectedPatientProgram.id).headerImage}`)})`
                                                         }}
                                                        ></div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                </>
                                            )}
                                            {selectedPatientProgram.id === 'rems_program_pomalyst' && (
                                                
                                                isPomNewContent?.toLowerCase() ==='pomalyst' ? (<div className={image_styles.pom_image_wrapper_pom_old}>
                                                   
                                                    <div 
                                                    className={`${image_styles.new_image}`}
                                                    style={{
                                                        backgroundImage:`url(${require(`../../assets/images/img_bms_logo.png`)})`,
                                                        backgroundPosition: 'center left'
                                                     }}
                                                    ></div>
                                                    <div 
                                                    className={`${image_styles.new_image}`}
                                                    style={{
                                                        backgroundImage:`url(${require(`../../assets/images/img_POMRems_white_1.png`)})`
                                                     }}
                                                    ></div>
                                                    <div 
                                                    className={`${image_styles.new_image}`}
                                                    style={{
                                                        backgroundImage:`url(${require(`../../assets/images/img_Newimid_white_1.png`)})`,
                                                        backgroundSize: '60%',
                                                        backgroundPosition: 'center right'
                                                     }}
                                                    ></div>
                                                </div>):(<div className={image_styles.pom_image_wrapper_pom}>
                                                    <div 
                                                    className={`${image_styles.new_image}`}
                                                    style={{
                                                        backgroundImage:`url(${require(`../../assets/images/${ProgramConfig(selectedPatientProgram.id).headerImage}`)})`
                                                     }}
                                                    ></div>
                                                </div>)
                                            )}
                                            {selectedPatientProgram.id === 'rems_program_thalomid' && (
                                                <div className={image_styles.pom_image_wrapper}>
                                                    <div className={image_styles.bms}></div>
                                                    <div 
                                                    className={`${image_styles.thal_logo_image}`}
                                                    style={{
                                                        backgroundImage:`url(${require(`../../assets/images/${thalLogoImg}`)})`
                                                     }}
                                                    ></div>
                                                    <div 
                                                        className={`${image_styles.thal_white_image}`}
                                                        style={{
                                                            backgroundImage:`url(${require(`../../assets/images/${thalWhiteImg}`)})`
                                                        }}
                                                     ></div>
                                                </div>
                                            )}
                                            <div
                                                className={image_styles.subTextAuthentication}
                                                dangerouslySetInnerHTML={{
                                                    __html: t(arrgementContent?.body.authorization.footer) || '',
                                                }}
                                            ></div>
                                            <div className={image_styles.buffer}></div>
                                        </>
                                    )}
                                    {arrgementContent?.body.authorizationTreatment && (
                                        <>
                                            <div className={image_styles.productDetailContainer}>
                                                {t(arrgementContent?.body.authorizationTreatment.header)}
                                            </div>
                                            {arrgementContent?.body.authorizationTreatment.subcontents
                                                .filter((x) => {
                                                    return x.riskCatergory?.includes(patientInformation.newRisk);
                                                })
                                                .map((item, index) => {
                                                    return (
                                                        <>
                                                            <div
                                                                key={index}
                                                                className={image_styles.treatmentSubcontent}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: t(item.content) || '',
                                                                }}
                                                            ></div>
                                                        </>
                                                    );
                                                })}
                                            <div className={image_styles.treatmentSubcontent}>
                                                <div>
                                                    <input
                                                        disabled={signSubmitClicked}
                                                        // checked={session3Checked}
                                                        className={image_styles.checkboxTreatmentArragement}
                                                        onChange={handleTreatmentArragementCheckbox}
                                                        type="checkbox"
                                                        id="275-CB_1 "
                                                        checked={finalTreatmentArragement}
                                                    ></input>
                                                    <span
                                                        className={image_styles.labelArrgement}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                t(
                                                                    arrgementContent?.body.authorizationTreatment
                                                                        .arrgement.content
                                                                ) || '',
                                                        }}
                                                    ></span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className={image_styles.patient_data}>
                                        <PatientFormField
                                            signSubmitClicked={signSubmitClicked}
                                            patientFormData={patientDataHandler}
                                        />
                                        {!signSubmitClicked && (
                                            <div className={image_styles.button_wrapper}>
                                                <Button
                                                    clickFunction={submitButtonHandler}
                                                    className={getBtnClass(selectedPatientProgram.id)}
                                                    width={190}
                                                    height={45}
                                                    size={18}
                                                    type="text"
                                                    buttonname={i18n.language === 'es' ? buttonContent.es.signSub : buttonContent.en.signSub}
                                                ></Button>
                                            </div>
                                        )}
                                    </div>
                                    {signSubmitClicked && (
                                        <>
                                            <p style={{ margin: '20px 0px' }}>{t('digSignature')}</p>
                                            <h5 className={image_styles.signed_by}>
                                                {`${t('signedby')} ${getSignatory() != 'self' ? '(AR)' : ''} ${
                                                    patientArray.firstName
                                                } ${patientArray.lastName} ${t('on')} ${moment(
                                                    patientArray.modifiedDate
                                                ).format('MMM DD, YYYY')}`}
                                            </h5>
                                        </>
                                    )}
                                    <div>
                                        {IsES ? (
                                            <h4>
                                                {t('prescriberHeading')}
                                                <br /> {t('prescriberHeading_en')}
                                            </h4>
                                        ) : (
                                            <h4>{t('prescriberHeading')}</h4>
                                        )}

                                        <h4>
                                            {IsES ? (
                                                <>
                                                    <span>{`${t('patientRiskCategoryData')} ${
                                                        patientInformation.newRisk
                                                    } `}</span>
                                                    <br />
                                                    <span>{`${t('patientRiskCategoryData_en')} ${
                                                        patientInformation.newRisk
                                                    } `}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <span>{`${t('patientRiskCategoryData')} ${
                                                        patientInformation.newRisk
                                                    } `}</span>
                                                </>
                                            )}
                                        </h4>

                                        {arrgementContent?.body.authorizationTreatment.TermsAndConditon && (
                                            <div className={image_styles.argreementFormat}>
                                                <span
                                                    className={image_styles.labelArrgement}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            t(
                                                                arrgementContent?.body.authorizationTreatment
                                                                    .TermsAndConditon
                                                            ) || '',
                                                    }}
                                                ></span>
                                            </div>
                                        )}
                                        {IsES && arrgementContent_en?.body.authorizationTreatment.TermsAndConditon && (
                                            <div className={image_styles.argreementFormat}>
                                                <span
                                                    className={image_styles.labelArrgement}
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            t(
                                                                arrgementContent_en?.body.authorizationTreatment
                                                                    .TermsAndConditon
                                                            ) || '',
                                                    }}
                                                ></span>
                                            </div>
                                        )}
                                        {patientSavedData.map((row) => {
                                            return (
                                                <>
                                                    <Input
                                                        styleupdate={true}
                                                        value={row.inputValue}
                                                        type={row.type}
                                                        name={row.name}
                                                        label={
                                                            i18n.language === 'es'
                                                                ? translateLabel(row.label)
                                                                : row.label
                                                        }
                                                        inputvalue={row.inputValue}
                                                        isRequired={row.isRequired}
                                                        className="fontColor"
                                                    />
                                                </>
                                            );
                                        })}
                                        {signSubmitClicked && (
                                            <>
                                                <div>
                                                    {IsES ? (
                                                        <h4>
                                                            {t('digSignatureHeading')}
                                                            <br /> {t('digSignatureHeading_en')}
                                                        </h4>
                                                    ) : (
                                                        <h4>{t('digSignatureHeading')}</h4>
                                                    )}
                                                    {userDetails.map((row) => {
                                                        return (
                                                            <Input
                                                                onChangeHandler={updateUserDetails}
                                                                styleupdate={true}
                                                                value={prescriberLogin[row.name]}
                                                                type={row.type}
                                                                name={row.name}
                                                                label={
                                                                    i18n.language === 'es'
                                                                        ? translateLabel(row.label)
                                                                        : row.label
                                                                }
                                                                isRequired={row.isRequired}
                                                                className="fontColor"
                                                                maxLength={20}
                                                            />
                                                        );
                                                    })}

                                                    <div className={image_styles.checkbox_wrapper}>
                                                        <div className={image_styles.checkbox_content1}>
                                                            <span className={image_styles.required}>*</span>
                                                            <input
                                                                checked={IsCheckBoxChecked}
                                                                id={'prescriberCheckbox'}
                                                                name="checkbox"
                                                                type="checkbox"
                                                                onChange={() =>
                                                                    setIsCheckBoxChecked(!IsCheckBoxChecked)
                                                                }
                                                            ></input>
                                                        </div>
                                                        <div
                                                            className={image_styles.checkbox_content2}
                                                            style={{ fontSize: '11px' }}
                                                        >
                                                            <p
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        t('usernameAcknowlege')
                                                                            .replace('{program1}', getProgramsName)
                                                                            .replace(
                                                                                '{program2}',
                                                                                (selectedPatientProgram.id ===
                                                                                    'rems_program_thalomid' || selectedPatientProgram.id === 'rems_program_pomalyst')
                                                                                    ? selectedProgram?.label.toUpperCase()
                                                                                    : selectedProgram?.label.toLowerCase()
                                                                            ) || '',
                                                                }}
                                                            ></p>

                                                            {IsES && (
                                                                <>
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html:
                                                                                t('usernameAcknowlege_en')
                                                                                    .replace(
                                                                                        '{program1}',
                                                                                        getProgramsName
                                                                                    )
                                                                                    .replace(
                                                                                        '{program2}',
                                                                                        (selectedPatientProgram.id ===
                                                                                            'rems_program_thalomid' || selectedPatientProgram.id === 'rems_program_pomalyst')
                                                                                            ? selectedProgram?.label.toUpperCase()
                                                                                            : selectedProgram?.label.toLowerCase()
                                                                                    ) || '',
                                                                        }}
                                                                    ></p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {digitalSignatureError && (
                                                        <Alert
                                                            content={digitalSignatureError}
                                                            styles={{ margin: '10px 50px 5px 50px' }}
                                                        />
                                                    )}
                                                    {patientValidationError && (
                                                        <div
                                                            className={AlertClass.container}
                                                            style={{ margin: '10px 30px 5px 30px' }}
                                                            dangerouslySetInnerHTML={{ __html: patientValidationError }}
                                                        ></div>
                                                    )}
                                                    <div className={image_styles.button_wrapper}>
                                                        <div>
                                                            <Button
                                                                clickFunction={handleSaveAndSubmit}
                                                                className={getBtnClass(selectedPatientProgram.id)}
                                                                width={190}
                                                                height={45}
                                                                size={18}
                                                                type="text"
                                                                buttonname={i18n.language === 'es' ? buttonContent.es.signSub : buttonContent.en.signSub}
                                                                disabled={IsSubmitButton}
                                                            ></Button>
                                                            {  IsSubmitButton && ( <div className={image_styles.loader}>
                                                                <Flex align="center" gap="middle">
                                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                                                </Flex>
                                                            </div>)}
                                                            {IsES && (
                                                                <div className={image_styles.engLabel}>
                                                                    Sign and Submit
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={image_styles.checkbox_content3}>
                                                    <div className={image_styles.SavePrintContainer}>
                                                        {IsSubmitButton && <p>{t('error_PM0830')}</p>}
                                                        <div className={image_styles.actionbutton}>
                                                            <div>
                                                                 <Button
                                                                    clickFunction={handleSaveButton}
                                                                    className={getBtnClass(selectedPatientProgram.id)}
                                                                    width={110}
                                                                    height={45}
                                                                    size={18}
                                                                    type="text"
                                                                    buttonname={i18n.language === 'es' ? buttonContent.es.save : buttonContent.en.save}
                                                                    disabled={isSaveButton}
                                                                ></Button>
                                                                
                                                                {IsES && (
                                                                    <div className={image_styles.engLabel}>Save</div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                {' '}
                                                                  <Button
                                                                        clickFunction={handlePrintButton}
                                                                        className={getBtnClass(selectedPatientProgram.id)}
                                                                        width={110}
                                                                        height={45}
                                                                        size={18}
                                                                        type="text"
                                                                        buttonname={i18n.language === 'es' ? buttonContent.es.print : buttonContent.en.print}
                                                                    ></Button>
                                                                {IsES && (
                                                                    <div className={image_styles.engLabel}>Print</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={image_styles.AdobeInformation}>
                                                        <p>
                                                            {t('print')}
                                                            <span className={image_styles.legalSymbol}>®</span>{' '}
                                                            {t('reader')}
                                                            <span className={image_styles.legalSymbol}>®</span>
                                                            {'. '}
                                                            {t('download')}
                                                        </p>
                                                        {IsES && (
                                                            <p>
                                                                {t('print_en')}
                                                                <span className={image_styles.legalSymbol}>
                                                                    ®
                                                                </span>{' '}
                                                                {t('reader_en')}
                                                                <span className={image_styles.legalSymbol}>®</span>
                                                                {'. '}
                                                                {t('download_en')}
                                                            </p>
                                                        )}
                                                        <a href="https://get.adobe.com/reader/" target="_blank">
                                                            <span className={image_styles.getAdobeReaderBtn}></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default PatientAgreementForm;
