import ProductBackground from 'components/ProductBackground/productBackground';
import classes from './PatientSurvey.module.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PatientSurveyScreens } from './PatientSurveyType';
import { useAppDispatch, useAppSelector } from 'store';
import { updatePatientSurvey } from 'store/slices/appSlice';
import { buttonContent } from 'assets/objects/buttonContent';
import Button from 'components/Button';
const RestartSurvey = ({ setScreen }) => {
    const { t, i18n } = useTranslation();
    const { patientSurvey } = useAppSelector((state) => state.app);
    const { updatedSurveyPayload } = useAppSelector((state) => state.app.patientSurvey);
    const dispatch = useAppDispatch();
    const handleYes = () => {
        dispatch(
            updatePatientSurvey({
                ...patientSurvey,
                questionSequence: 1,
                updatedSurveyPayload: {
                    ...updatedSurveyPayload,
                    PatientSurvey: { ...updatedSurveyPayload.PatientSurvey, QuestionAnswers: [] },
                },
            })
        );
        setScreen(PatientSurveyScreens.Survey);
    };
    const handleNo = () => {
        setScreen(patientSurvey.prevNavigation);
    };

    return (
        <div className={classes.restartSurvey}>
            <ProductBackground
                product={patientSurvey.selectedSurveyProduct.PatientSurvey.ProductName}
                subTitle={t('patientSurveyCancelWarning')}
            >
                <div className={`${classes[i18n.language]} ${classes.bodyContainer}`}>
                    <div className={classes.warningContiner}>
                        <span className={classes.warningIcon}></span>
                        <div className={classes.warningText}>{t('warningText')}</div>
                    </div>
                    <div className={classes.cofirmationbutton}>
                        <Button
                            width={100}
                            height={45}
                            size={18}
                            clickFunction={() => {
                                handleYes();
                            }}
                            buttonname={i18n.language === 'en' ? buttonContent.en.yes : buttonContent.es.yes}
                            type="text"
                            className={'buttonStyles'}
                        />
                        <Button
                            width={100}
                            height={45}
                            size={18}
                            clickFunction={() => {
                                handleNo();
                            }}
                            buttonname={i18n.language === 'en' ? buttonContent.en.no : buttonContent.es.no}
                            type="text"
                            className={'buttonStyles'}
                        />
                        
                    </div>
                </div>
            </ProductBackground>
        </div>
    );
};

export default RestartSurvey;
