import classes from './PatientDetails.module.scss';
import moment from 'moment';
import { useEffect } from 'react';


const PatientSurveyPopup = ({ setOpenModal, surveyInfo }) => {

    useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	const allsurveyDatas = surveyInfo?.map((item) => ({
		id: item?.productCountry?.productCountryId || '',
		name: item?.productCountry?.product?.productName || '',
		lastDate: item?.patientSurveyDate ? moment(item?.patientSurveyDate).format('MMM DD, YYYY') : '',
		nextDate: item?.nextEarliestSurveyDate ? moment(item?.nextEarliestSurveyDate).format('MMM DD, YYYY') : '',
	}));

	return (
		<>
			<div className={classes.modalOverlay} onClick={() => setOpenModal(false)}> </div>
			<dialog className={classes.mainmodalContainer} >
				<div className={classes.whiteInfoContainer}>
					<div className={classes.heading}> Patient Survey Dates</div>
					<div className={classes.bigPaddingSurvey}>
						<button className={classes.closeBtn} onClick={() => setOpenModal(false)}> </button>

						<table className={classes.common_table}>
							<thead>
								<th>Program</th>
								<th>Last Survey Date</th>
								<th>Next Survey Date</th>
							</thead>
							<tbody>
								{allsurveyDatas?.map((pgm) => (
									<tr key={pgm.id}>
										<td>{pgm.name}</td>
										<td>{pgm.lastDate}</td>
										<td>{pgm.nextDate}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
			</dialog>
		</>
	);
};

export default PatientSurveyPopup;
