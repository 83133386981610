import Footer from 'components/Footer';
import Header from 'components/Header';
import { Outlet, useLocation } from 'react-router-dom';
import classes from './layout.module.scss';
import { useEffect } from 'react';

const Layout = (props: any) => {
    const location = useLocation();
    const currentPath = location.pathname.split('/')[2];
    const hideHeaderFooterArray = ['login', 'header'];

    return (
        <>
            <div className={classes.layout}>
                {!hideHeaderFooterArray.includes(currentPath) && <Header />}
                <Outlet />
                <Footer />
            </div>
        </>
    );
};

export default Layout;
