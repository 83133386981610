import { useEffect, useState } from 'react';
import PatientDetailsTable from './PatientDetailsTable';
import PatientFilterOptions from './PatientFilterOptions';
import PatientListPagination from './PatientListPagination';
import classes from './prescriberDashboardFindPatient.module.scss';
import { getFindPatientPrintPopUpTemplate } from './getFindPatientPrintPopUpTemplate';
import { useAppSelector, useAppDispatch } from 'store';
import { getNonPrescAssociation } from 'store/slices/appSlice';
import { reloadFunction } from 'utils/utilsCommon';

const PrescriberDashboardFindPatient = ({ isFindPatientTab }) => {
    const headingString =
        "The patient information listed below can be sorted by Patient Name (Last) or Prescriber Name (Last). Click on the patient's name to view patient demographics, current authorization(s) and a dispense history.";

    const [isFiltered, setIsFiltered] = useState({ isFilterApplied: false, filterPayload: null });

    const [isSorted, setIsSorted] = useState({ isSorted: false, sortByField: null, sortByOrder: null });

    const [nonPrescriberAssociatedPrescribers, setNonPrescriberAssociatedPrescribers] = useState<any>(false);

    const { patientList } = useAppSelector((state) => state.app);
    const { content }: { content: any } = patientList ?? { content: null };

    const dispatch = useAppDispatch();

    const { currentUser } = useAppSelector((state) => state.login);
    const { userInfo } = useAppSelector((state) => state.app);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes("nonprescriber");


    const nonPrescriberAssociationHandler = (payload) => {
        dispatch(getNonPrescAssociation({ data: payload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                setNonPrescriberAssociatedPrescribers(true);
                return;
            } else if(e.payload && e.payload?.response.data && e.payload?.response.data?.statusCode === 400){
                if((e.payload?.response.data?.message == 'The non prescriber was not found') || (e.payload?.response.data?.message == 'No associations was found')){
                    setNonPrescriberAssociatedPrescribers(false);
                }
            } 
        });
    }

    useEffect(()=>{
        if(nonPrescriber){
            if(currentUser.onlineUserId && currentUser.onlineUserId !== ''){
                const payload = {
                    onlineUserId:currentUser.onlineUserId
                }
                nonPrescriberAssociationHandler(payload);
            }else if (userInfo.username && userInfo.username !== ''){
                const payload = {
                    onlineUserId:userInfo.username
                }
                nonPrescriberAssociationHandler(payload);
            }
        }
    },[])
    useEffect(() => {
        reloadFunction();
    }, []);

    const printThisPageHandler = () => {
        const printWindow = window.open(window.location.href, '_blank', 'width=892,height=502');
        if (!printWindow) return;
        const tableHTML: any = getFindPatientPrintPopUpTemplate(content);
        tableHTML && printWindow.document.write(tableHTML);
        printWindow.document.close();
        printWindow.onload = () => {
            printWindow.print();
        };
    };

    return (
        <div className={classes.findAPatient}>
            {isFindPatientTab && (
                <span className={`${classes.findPatientPrintBtn}`} onClick={printThisPageHandler}>
                    Print this page <b>&#x1F892;</b>
                </span>
            )}
            <div className="headingString">
                <p>{headingString}</p>
            </div>
            <div className="filters">
                <PatientFilterOptions
                    isFindPatientTab={isFindPatientTab}
                    setIsFiltered={setIsFiltered}
                    setIsSorted={setIsSorted}
                    isSorted={isSorted}
                />
            </div>
            <div className="patientTable">
                <PatientDetailsTable setIsSorted={setIsSorted} isFiltered={isFiltered}
                {...(nonPrescriber ? { nonPrescriberAssociatedPrescribers } : null)} />
            </div>
            <div className="pagination">
                <PatientListPagination
                    isFiltered={isFiltered}
                    isFindPatientTab={isFindPatientTab}
                    isSorted={isSorted}
                    setIsFiltered={setIsFiltered}
                />
            </div>
        </div>
    );
};
export default PrescriberDashboardFindPatient;
