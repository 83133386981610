import classes from './invalidSearchError.module.scss';

const InvalidSearchError = ({closingPopUpHandler}) => {

    const closePopUpHandler = ()=>{
        closingPopUpHandler(false);
    }

    return (
        <table width="550px" cellPadding="0" cellSpacing="0" border={0} align="center" className={classes.popupDiv} >
            <tbody>
                <tr>
                    <td className={classes.whiteInfoContainer}>
                        <div className={`${classes.pad_T} ${classes.slimPad_B} ${classes.pad_LR}`}>
                            <div className={`${classes.bold} ${classes.left}`}>Error</div>
                            <div className={`${classes.buffer} ${classes.clear}`}></div>
                        </div>
                        <div className={classes.contentDivider}></div>
                        <div className={classes.bigPad}>
                            <div className={classes.bold}>Please correct the following error in the form and resubmit:</div>
                            <ul>
                                <li className={classes.shortMarginTop}>Please enter a value on which to filter.</li>
                            </ul>
                        </div>
                        <div className={`${classes.right} ${classes.pad_B} ${classes.pad_LR}`}>
                            <a
                                onClick={closePopUpHandler}
                            >
                                <span className={`${classes.icon} ${classes.popupContinueBtnSml}`}></span>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default InvalidSearchError;
