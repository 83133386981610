import ProductBackground from "components/ProductBackground/productBackground";
import classes from "./PatientSurvey.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { PatientSurveyScreens, PossibleResponses } from "./PatientSurveyType";
import { useAppDispatch, useAppSelector } from "store";
import { updatePatientSurvey } from "store/slices/appSlice";
import { Navigate, useNavigate } from "react-router";
import Alert from "components/Alert";
import PageBackground from "components/PageBackground";
import moment from "moment";

const ErrorScreen = ({ setScreen }) => {
    const { t, i18n } = useTranslation();
    const { selectedSurveyProduct, businessErrorCode } = useAppSelector((state) => state.app.patientSurvey);
    const [error, setError] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        if (businessErrorCode?.code === 9010) {
            setError(t('error_PD0400'));
        }
        else if (businessErrorCode?.code === 9011) {
            setError(t('error_PD0401'));
        }
        else if (businessErrorCode?.code === 9004) {
            setError(t("error_PM0165").replace("<Date>",moment(businessErrorCode?.messageKey).format('MM/DD/YYYY')));
        } else if (businessErrorCode?.code === 9009 || businessErrorCode?.code === 202 || businessErrorCode?.code === 204) {
            setError(t('error_PD0401'));
        } else if (businessErrorCode?.code === 9000) {
            setError(t('activeEnrollmentError'));
        }
        else {
            navigate('/prescriber/SystemError');
        }
    })

    return (
        <div className={classes.errorScreen}>
            <PageBackground  label={t("patientSurvey")}>
                <div className={classes.error}>
                {error && (<Alert content={error} />)}
                </div>
            </PageBackground>
        </div>

    )
}

export default ErrorScreen;