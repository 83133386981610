/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useSelector } from 'react-redux';
import classes from '../createaccount/createaccount.module.scss';
import image_styles from '../ProductRegistration/ProductRegistration.module.scss';
import styles from './ProductRegSignup.module.scss';
import { RootState, useAppDispatch, useAppSelector,store } from 'store';
import stop from './../../assets/images/img_Stop_Sign.png';
import { useEffect, useRef, useState } from 'react';
import ErrorModal from '../../components/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { productSignupContent } from 'assets/objects/productSignupContent';
import moment from 'moment';
import { axiosInstance_ORC, axiosInstance_PDF } from 'constants/Config/axiosConfig';
import Success from 'components/Success';
import lenalidomide from './../../assets/objects/SuccessContent_lenalidomide';
import pomalyst from './../../assets/objects/SuccessContent_pomalyst';
import thalomid from './../../assets/objects/SuccessContent_thalomid';
import { changeHelpContent, changePPAF, deleteIsWritePrescription, resetApp, resetPatientInformation, resetpatientPrescriberStore, setProductEnrolmentData } from 'store/slices/appSlice';
import { pdfConverter } from 'utils/byteToPdfConvert';
import { isValidPassword, isValidUserName } from 'utils/regex';
import { setRequestBody } from './../../utils/getEnrollmentRequestBody';
import Alert from 'components/Alert';
import SessionTimeout from 'components/SessionTimeout';
import { RoleByGroup } from 'constants/Config/appConfig';
import { PomalystTextToPomalidomide } from 'utils/constant';
import { digitalSignatureAuth, setLogOut } from 'store/slices/loginSlice';
import { authenticateErrCodeMapper, congitoResMapper } from 'utils/congitoResMapper';
import indexedDBStore from 'store/indexedDBStore';
import { ProgramConfig, ProgramConfigByName, ProgramConfigIMG, pomalidomide } from 'constants/Config/programsConfig';
import { reloadFunction } from 'utils/utilsCommon';
import { Flex, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { persistStore } from 'redux-persist';


const ProductRegSignUp = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const prescriberInfoRef = useRef<HTMLDivElement | null>(null);
    const [linkClick, setLinkClick] = useState(false);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [dataList, setDataList] = useState({});
    const [error, setError] = useState('');
    const [errorAlert, setErrorAlert] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [saveddata, setSaveddata] = useState<any>([]);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [loader,setLoader] = useState(false);
    const navigate = useNavigate();
    const [values, setValues] = useState({
        userName: '',
        password: '',
    });
    const dispatch = useAppDispatch();
    const enrolledPrescriberData = useSelector((state: RootState) => state.app.productEnrolmentData);
    const { prescribing_info, id, label } = useSelector((state: RootState) => state.app.selectedProgram);
    const accFormDetails = useSelector((state: RootState) => state.app.accFormDetails);
    const userInfo = JSON.parse(sessionStorage.getItem('accountData') || '[]');
    const ProductSecDetails = JSON.parse(sessionStorage.getItem('productaccSecDetails') || '[]');

    const { currentUser, IsAuthenticated, accessCodeFlag } = useAppSelector((state) => state.login);
    const { prescriberDetails } = useAppSelector((state) => state.app);
    const userName = accFormDetails.userName || currentUser?.userinfo?.username;

    const prescriberId = currentUser?.systemId || '';

    const passwordRegex = /^(?=.*[^a-zA-Z0-9@#]).*$|^\s+$/;

    useEffect(() => {
        const ProductRegisterValues = JSON.parse(sessionStorage.getItem('productaccDetails') || '[]');
        setSaveddata(ProductRegisterValues);
    }, []);

    useEffect(() => {
       reloadFunction();
    }, []);

    const handleErrorBtn = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
        });
        if (currentFocus) {
            const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
            inputElement.focus();
            inputElement.select();
            setCurrentFocus('');
        }
        setError('');
    };

    const prescriberInformation = {
        prescriberNameFirst: accFormDetails?.firstName || null,
        prescriberNameLast: accFormDetails?.lastName || null,
        prescriberMiddleInitial: enrolledPrescriberData[0]?.MI || null,
        degrees: [
            {
                degreeId: null,
                degree: enrolledPrescriberData[0]?.degree || saveddata.degree,
                deactivationDate: null,
            },
        ],
        specialty: {
            specialtyId: null,
            specialtyName: enrolledPrescriberData[0]?.specialty.split('-')[0]?.trim() || null,
            specialtyCode: null,
            description: enrolledPrescriberData[0]?.specialty.split('-')[1].trim() || null,
        },
        prescriberExtUniqueId1: enrolledPrescriberData[0]?.pi_number || null,
        prescriberExtUniqueId2: enrolledPrescriberData[0]?.meNumber || null,
        prescriberEmailAddress: enrolledPrescriberData[0]?.email || null,
        channelId: 'ext',
        products: [
            {
                name: (label === 'Pomalidomide' ? PomalystTextToPomalidomide : label) || null,
                status: 'Active',
            },
        ],
    };
    const primaryOfficeList = {
        prescriberOfficeName: enrolledPrescriberData[0]?.officeName || null,
        attention: enrolledPrescriberData[0]?.attention || null,
        addressLine1: enrolledPrescriberData[0]?.officeAddress || null,
        postalCode: {
            postalCodeId: null,
            postalCode1: enrolledPrescriberData[0]?.zip || null,
            city: enrolledPrescriberData[0]?.city || null,
            state: {
                stateId: null,
                stateCode: enrolledPrescriberData[0]?.state || null,
                stateName: null,
            },
        },

        phoneNumber: enrolledPrescriberData[0]?.phone || null,
        faxNumber: enrolledPrescriberData[0]?.fax || null,
        emailAddress: enrolledPrescriberData[0]?.email || null,
        primaryLocation: 'Y',
    };
    const secondaryOfficeList = {
        prescriberOfficeName: enrolledPrescriberData[0]?.officeName2 || null,
        attention: enrolledPrescriberData[0]?.attention2 || null,
        addressLine1: enrolledPrescriberData[0]?.address2 || null,
        postalCode: {
            postalCodeId: null,
            postalCode1: enrolledPrescriberData[0]?.zip2 || null,
            city: enrolledPrescriberData[0]?.city2 || null,
            state: {
                stateId: null,
                stateCode: enrolledPrescriberData[0]?.state2 || null,
                stateName: '',
            },
        },

        phoneNumber: enrolledPrescriberData[0]?.phone2 || null,
        faxNumber: enrolledPrescriberData[0]?.fax2 || null,
        emailAddress: enrolledPrescriberData[0]?.email2 || null,
        primaryLocation: 'N',
    };

    const requestObj = setRequestBody(
        prescriberInformation,
        primaryOfficeList,
        secondaryOfficeList,

        userName,
        prescriberId,
        (label === 'Pomalidomide' ? PomalystTextToPomalidomide : label) || null,
        prescriberDetails
    );

    const finalOfficeList = enrolledPrescriberData[0]?.showAsterisk
        ? [primaryOfficeList, secondaryOfficeList]
        : [primaryOfficeList];

    const reqPayLoad = {
        prescriber: {
            prescriberInformation: prescriberInformation,
            officeDetailsList: finalOfficeList,
        },
    };
    const Islogout = () => {
        let result = false;
        if (IsAuthenticated) {
            switch (currentUser.groupTag) {
                case RoleByGroup.Prescriber: {
                    if (!accessCodeFlag) {
                        result = false;
                    } else {
                        result = true;
                    }
                    break;
                }
                case RoleByGroup.NonPrescriber: {
                    result = true;
                    break;
                }
            }
        }
        return result;
    };
    const clearStore = async () => {
		const persistor = persistStore(store);
		await persistor.purge();
	};

	const logOutClear =()=>{
		clearStore();
		dispatch(resetApp());
		dispatch(setLogOut());
		dispatch(resetPatientInformation());
		dispatch(resetpatientPrescriberStore());
		dispatch(deleteIsWritePrescription(false));		
		dispatch(changePPAF(false));
	};
    const handleContinue = () => {
        //dispatch(resetApp());
        setErrorAlert('');
        if (Islogout()) {
            dispatch(setProductEnrolmentData(enrolledPrescriberData['0']));
            navigate('/prescriber/LoginHome', { replace: true });
        } else {
            logOutClear();
            navigate('/prescriber/login', { replace: true });
		    indexedDBStore.removeItem('prescriberUser')
        }

        sessionStorage.removeItem('pass');
        sessionStorage.removeItem('accountData');
    };
    const prescriberEnrollApiCall = async (data: any) => {
        try {
            const response = await axiosInstance_ORC.post(`/product/register`, data);
            if (response.data.statusCode === 200) {
                dispatch(changeHelpContent('Success'));
                setIsSuccess(true);
            } else {
                dispatch(changeHelpContent('Success'));
                navigate('/prescriber/SystemError');
            }
        } catch (err: any) {
            if (err?.response?.data?.statusCode === 400) {
                setErrorAlert('Prescriber is already certified for the selected program. To continue the enrollment process, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.');
                containerRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            } else {
                dispatch(changeHelpContent('Success'));
                navigate('/prescriber/SystemError');
            }
        }
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    function getProduct() {
        if (id == 'rems_program_lenalidomide') return lenalidomide;
        else if (id == 'rems_program_pomalyst') return pomalyst;
        else if (id == 'rems_program_thalomid') return thalomid;
    }

    const handleSubmit = async () => {
        setErrorAlert('');
        //dispatch(changeHelpContent('productSignAndContinue'));
        setLoader(true);
        if (!linkClick) {
            setError(
                `You must first view important ${
                    [ProgramConfig('rems_program_pomalyst').label, ProgramConfig('rems_program_lenalidomide').label].includes(enrolledPrescriberData[0]?.label)
                        ? enrolledPrescriberData[0]?.label?.toLowerCase()
                        : enrolledPrescriberData[0]?.label?.toUpperCase()
                } educational materials and safety information before digitally signing.`
            );
            containerRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setLoader(false);
            return;
        }
        if (!isCheckBoxChecked) {
            setError(`You must accept the signature agreement to continue signing.`);
            containerRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setLoader(false);
            return;
        }
        let validUser = true;
        let validPassword = false;

        if (values.userName.trim() !== '') {
            setValues((prev) => ({
                ...prev,
                userName: values.userName.trim(),
            }));
            
        } else if (values.userName.trim().length === 0) {
            setCurrentFocus('userName');
            setError('Please enter your User Name.');
            containerRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            validUser = false;
            setLoader(false);
            return;
        }
        if (values.password) {
            validPassword = true;
            
        } else {
            setCurrentFocus('password');
            setError('Please enter your Password.');
            containerRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
            setLoader(false);
        }
        if (!error && validUser && validPassword) {
            sessionStorage.removeItem('productaccDetails');
            sessionStorage.removeItem('productSecDetails');
            if (userName?.toLowerCase() !== values.userName?.toLowerCase()) {
                setErrorAlert(
                    'The User Name used to digitally sign the enrollment is not the same User Name that was used to create the account. Please enter the correct User Name.'
                );
                prescriberInfoRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
                setIsCheckBoxChecked(false);
                setValues({
                    userName: '',
                    password: '',
                });
                setLoader(false);
            } else if (passwordRegex.test(values.password)) {
                if (values.password.trim() === '') {
                    setError('Please enter your Password.');
                    setValues({
                        userName: values.userName,
                        password: values.password.trim(),
                    });
                    
                } else {
                    setError('Please enter your valid Password.');
                }
                containerRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                setLoader(false);
            } else {
                try {
                    await dispatch(digitalSignatureAuth({
                        username: userName,
                        password: values.password,
                    })).then(async (response: any) => {
                        const msgCode = authenticateErrCodeMapper(response?.payload?.message);
                        const authRes:any = congitoResMapper(response?.payload?.AuthenticationResult);
                        setLoader(false);
                        if (msgCode) {
                            setIsCheckBoxChecked(false);
                            setValues({
                                userName: '',
                                password: '',
                            });
                            switch (msgCode) {
                                case 100:
                                    setErrorAlert('The User Name was not found.');
                                    break;
                                case 101:
                                    setErrorAlert(
                                        'You have entered an invalid User Name and/or Password. Please re-enter your User Name and Password.'
                                    );
                                    break;
                                default:
                                    setErrorAlert(response?.payload?.message);
                                    break;
                            }
                            prescriberInfoRef?.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'end',
                            });
                        } else if (authRes?.currentUser?.accessToken) {
                            dispatch(changeHelpContent('productSignAndContinue'));
                            sessionStorage.setItem('token', authRes?.currentUser?.accessToken);
                            setShowPrintButton(true);
                        } else {
                            setErrorAlert(response?.payload?.message || 'API Failed');
                            prescriberInfoRef?.current?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'end',
                            });
                        }
                    })
                } catch (err: any) {
                    setErrorAlert(err?.data?.message);
                    setLoader(false);
                    dispatch(changeHelpContent('productSignUp'));

                }
            }
        }
    };

    const handlePrint = async () => {
        const reqBody = {
            prescriberId: null,
            productType: label === 'Pomalidomide' ? PomalystTextToPomalidomide : label,
            action: 'Print',
            registrationStatus: 'Pending',
            countryId: 1,
            //(-> case 2 if we have prescriberId then precriberDTO willbe null and vice versa)
            //prescriberDto: reqPayLoad.prescriber,
            prescriberDto: requestObj,
            //prescriberDto: null,
            loggedInUserId: accFormDetails.userName,
            prescriberCommTypeId: 11,
            reasonForChangeId: null,
            prescriberSignatureDate: null,
            initialCreator: null,
            reasonForChange: null,
            deactivateReasonChangeId: null,
            reactivateReasonChangeId: null,
            notification21Days: null,
            notificationEmail: null,
            notificationEmail2: null,
            notificationEmail3: null,
            notificationFax: null,
        };

        try {
            const response = await axiosInstance_PDF.post('/file-render/pdf', reqBody);
            if (response.data.statusCode === 200) {
                sessionStorage.setItem('baseString', JSON.stringify(response.data.data.pdf));
                sessionStorage.setItem('fileName', JSON.stringify('productRegistration'));

                window.open('/#/pdfViewer', '_blank');
            } else {
                navigate('/prescriber/SystemError');
            }
        } catch (err) {
            navigate('/prescriber/SystemError');
        }
    };
    const submitButtonClickHandler = () => {
        if (!submitDisabled) {
            setSubmitDisabled(true);
            prescriberEnrollApiCall(requestObj);
        }
    };

    const showPrescribingInfo = (url) => {
        setLinkClick(true);
        window.open(url, 'url_info', 'location=yes,height=600,width=808,scrollbars=yes,status=yes');
    };
    const toTitleCase = (str) => {
        return str
            ?.toLowerCase()
            .split(' ')
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    };
    useEffect(() => {
        if (enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_lenalidomide').label) {
            const data = productSignupContent.filter((program) => program.order === 1);
            setDataList(data);
        } else if (enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_pomalyst').label) {
            const data = productSignupContent.filter((program) => program.order === 2);
            setDataList(data);
        } else if (enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_thalomid').label) {
            const data = productSignupContent.filter((program) => program.order === 3);
            setDataList(data);
        }
    }, []);

    const getAdobeText = () => (
        <span>
            The Print function requires Adobe<sup>®</sup> Reader<sup>®</sup>. Click here to download it for free.
        </span>
    );

    useEffect(() => {
        return () => {
            dispatch(changeHelpContent('productSignUp'));
        };
    }, [dispatch]);
    return (
        <>
            <SessionTimeout />
            {isSuccess ? (
                <Success
                    label="Confirmation"
                    btn={true}
                    product={true}
                    handleClick={handleContinue}
                    productLogo={getProduct()?.images[0]?.link1}
                    fromComp={'ProductRegSignUpConf'}
                >
                    {getProduct()?.body.map((item) => (
                        <div key={item?.order} dangerouslySetInnerHTML={{ __html: item?.content || '' }}></div>
                    ))}
                </Success>
            ) : (
                <>
                    {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
                    <div className={classes.mainContainer} ref={containerRef}>
                        <div>
                            <div className={classes.headerContainer}>
                                <span className={classes.icon}></span>
                                <div className={classes.programLogo}>
                                    <div className={classes.title}>New Prescriber Enrollment</div>
                                </div>
                            </div>
                        </div>
                        <div className={` ${image_styles.generalProgramLogo}`}
                        style={{
                            backgroundImage: `url(${require(`../../assets/images/${ProgramConfig(enrolledPrescriberData[0]?.image)?.prodRegistrationImage}`)})`
                        }}
                        ></div>
                        <div
                            className={styles.disclaimer_style}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer1 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ paddingTop: '15px', fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer2 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ paddingTop: '15px', fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer3 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer4 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ paddingTop: '15px', fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer5 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer6 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer7 }}
                        ></div>
                        <div
                            className={classes.disclaimer_style}
                            style={{ paddingTop: '15px', fontWeight: 'bold' }}
                            dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer8 }}
                        ></div>
                        {dataList[0]?.disclaimer9 && (
                            <div
                                className={classes.disclaimer_style}
                                style={{ paddingTop: '15px', fontWeight: 'bold' }}
                                dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer9 }}
                            ></div>
                        )}
                        {dataList[0]?.disclaimer10 && (
                            <div
                                className={classes.disclaimer_style}
                                style={{ paddingTop: '15px', fontWeight: 'bold' }}
                                dangerouslySetInnerHTML={{ __html: dataList[0]?.disclaimer10 }}
                            ></div>
                        )}

                        <div style={{ paddingTop: '15px' }}>
                            <ul style={{ padding: '0px 8px 11px 19px' }}>
                                {dataList[0]?.content.map((i) => <li dangerouslySetInnerHTML={{ __html: i }} />)}
                            </ul>
                        </div>
                        <div className={classes.note}>
                            Fields marked with an <span className={classes.required}>*</span> are required.
                        </div>
                        <div className={classes.contentContainer} ref={prescriberInfoRef}>
                            {errorAlert.length > 0 && <Alert content={errorAlert} />}
                            <div className={classes.formHeader}>Prescriber Information</div>
                            <Input
                                type="hidden"
                                name="firstName"
                                label="First Name"
                                inputvalue={accFormDetails?.firstName || userInfo.firstName}
                                isRequired={true}
                                className="fontBold"
                            />
                            <Input
                                type="hidden"
                                name="lastName"
                                label="Last Name"
                                inputvalue={accFormDetails?.lastName || userInfo.lastName}
                                isRequired={true}
                                className="fontBold"
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="MI"
                                label="MI"
                                inputvalue={enrolledPrescriberData[0]?.MI || saveddata.MI}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="degree"
                                label="Degree"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.degree || saveddata.degree}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="specialty"
                                label="Specialty"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.specialty || saveddata.specialty}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="number"
                                label="Prescriber Identification Number"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.pi_number || saveddata.pi_number}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="meNumber"
                                label="ME No"
                                inputvalue={enrolledPrescriberData[0]?.meNumber || saveddata.meNumber}
                            />
                        </div>
                        <div className={classes.contentContainer}>
                            <div className={classes.formHeader}>Primary Office</div>
                            <Input
                                type="hidden"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.officeName || saveddata.officeName}
                                name="officeName"
                                label="Office Name"
                                isRequired={true}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.attention || saveddata.attention}
                                name="attention"
                                label="Attention"
                                isRequired={true}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.officeAddress || saveddata.officeAddress}
                                name="officeAddress"
                                label="Office Street Address"
                                isRequired={true}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.city || saveddata.city}
                                isRequired={true}
                                name="city"
                                label="City"
                            />
                            <Input
                                type="hidden"
                                inputvalue={enrolledPrescriberData[0]?.state || saveddata.state}
                                name="state"
                                label="State"
                                defaultValue="State"
                                isRequired={true}
                                className="fontBold"
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.zip || saveddata.zip}
                                name="zip"
                                label="Zip Code"
                                isRequired={true}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="phone"
                                label="Phone"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.phone || saveddata.phone}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="fax"
                                label="Fax"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.fax || saveddata.fax}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="email"
                                label="Email"
                                isRequired={true}
                                inputvalue={enrolledPrescriberData[0]?.email || saveddata.email}
                            />
                        </div>
                        <div className={classes.contentContainer}>
                            <div className={classes.formHeader}>Secondary Office</div>
                            <Input
                                type="hidden"
                                name="officeName2"
                                label="Office Name"
                                className="fontBold"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                                inputvalue={enrolledPrescriberData[0]?.officeName2 || ProductSecDetails.officeName2}
                            />
                            <Input
                                type="hidden"
                                name="attention2"
                                label="Attention"
                                className="fontBold"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                                inputvalue={enrolledPrescriberData[0]?.attention2 || ProductSecDetails.attention2}
                            />
                            <Input
                                type="hidden"
                                name="address2"
                                label="Office Street Address"
                                className="fontBold"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                                inputvalue={enrolledPrescriberData[0]?.address2 || ProductSecDetails.address2}
                            />
                            <Input
                                type="hidden"
                                inputvalue={enrolledPrescriberData[0]?.city2 || ProductSecDetails.city2}
                                className="fontBold"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                                name="city2"
                                label="City"
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="state2"
                                label="State"
                                inputvalue={enrolledPrescriberData[0]?.state2 || ProductSecDetails.state2}
                                defaultValue="Select a State"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                            />
                            <Input
                                type="hidden"
                                name="zip2"
                                inputvalue={enrolledPrescriberData[0]?.zip2 || ProductSecDetails.zip2}
                                className="fontBold"
                                label="Zip Code"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                            />
                            <Input
                                type="hidden"
                                name="phone2"
                                className="fontBold"
                                inputvalue={enrolledPrescriberData[0]?.phone2 || ProductSecDetails.phone2}
                                label="Phone"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="fax2"
                                inputvalue={enrolledPrescriberData[0]?.fax2 || ProductSecDetails.fax2}
                                label="Fax"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                            />
                            <Input
                                type="hidden"
                                className="fontBold"
                                name="email2"
                                inputvalue={enrolledPrescriberData[0]?.email2 || ProductSecDetails.email2}
                                label="Email"
                                isRequired={enrolledPrescriberData[0]?.showAsterisk}
                            />
                        </div>
                        <div className={classes.contentContainer}>
                            {showPrintButton && (
                                <div>
                                    <p className={styles.digital_sign}>Digital Signature:</p>
                                </div>
                            )}
                            {!showPrintButton && (
                                <div className={styles.formHeader}>
                                    <div className={styles.heading_info}>
                                        <img className={styles.stopIcon} src={stop} />
                                        <div>
                                            {`In order to complete your certification, you must first view important
                            ${
                              
                                ProgramConfigByName(enrolledPrescriberData[0]?.label).registeredTradermark === true || enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_pomalyst').label
                                ?
                                ProgramConfigByName(enrolledPrescriberData[0]?.label)?.label?.toUpperCase()
                                :
                                ProgramConfigByName(enrolledPrescriberData[0]?.label).label?.toLowerCase() 
                              
                            } educational materials and safety information:`}
                                        </div>
                                    </div>

                                    <div
                                        tabIndex={0}
                                        onClick={() => showPrescribingInfo(prescribing_info)}
                                        className={styles.pdfInfoLink}
                                    >
                                        {
                                        enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_lenalidomide').label  ? (
                                            'Prescribing Information'
                                        ) : (
                                            <div className={styles.adobeClickHereToOpenBtn}></div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {!showPrintButton ? (
                                <div>
                                    <div className={styles.signupHeader}>
                                        <p className={styles.digital_sign}>Digital Signature:</p>
                                        {!(enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_lenalidomide').label) && (
                                            <a href="https://get.adobe.com/reader/" target="blank" rel="noopener">
                                                <div className={styles.getAdobe}></div>
                                            </a>
                                        )}
                                    </div>

                                    <div className={styles.digital_wrapper}>
                                        <div className={styles.digital_content}>
                                            <div className={styles.checkbox_wrapper}>
                                                <div className={styles.checkbox_content}>
                                                    <span style={{ color: '#7b0305', marginRight: '4px' }}>*</span>
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => setIsCheckBoxChecked(!isCheckBoxChecked)}
                                                        checked={isCheckBoxChecked}
                                                    />
                                                </div>

                                                {enrolledPrescriberData[0]?.label === 'Thalomid' ? (
                                                    <div style={{ padding: '0px 10px 10px 0px' }}>
                                                        By clicking here, I acknowledge that the use of my User
                                                        Name/Password constitutes the equivalent of a hand-written
                                                        signature in the REMS Patient Safety system. I understand that
                                                        if I fail to comply with all requirements of the THALOMID REMS
                                                        <span
                                                            style={{
                                                                fontSize: '0.8em',
                                                                position: 'relative',
                                                                top: '-0.5em',
                                                            }}
                                                        >
                                                            ®
                                                        </span>{' '}
                                                        program, my prescriptions for THALOMID will not be honored at
                                                        certified pharmacies.{' '}
                                                    </div>
                                                ) : (
                                                    <div style={{ padding: '0px 10px 10px 0px' }}>
                                                        {`By clicking here, I acknowledge that the use of my User Name/Password
                                        constitutes the equivalent of a hand-written signature in the REMS Patient
                                        Safety system. I understand that if I fail to comply with all requirements of
                                        the ${toTitleCase(enrolledPrescriberData[0]?.label) === 'Pomalyst'
                                                                ? `${pomalidomide.toUpperCase()}`
                                                                : toTitleCase(enrolledPrescriberData[0]?.label)} 
                                                                REMS`}
                                                        {toTitleCase(enrolledPrescriberData[0]?.label) === 'Pomalyst' ?
                                                            <span
                                                                style={{
                                                                    fontSize: '0.8em',
                                                                    position: 'relative',
                                                                    top: '-0.5em',
                                                                }}
                                                            >
                                                                ®
                                                            </span> : ''}

                                                        {` program, my prescriptions for ${enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_lenalidomide').label
                                                ? ProgramConfig('rems_program_lenalidomide').label.toLowerCase()
                                                : enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_pomalyst').label
                                                ? ProgramConfig('rems_program_pomalyst').label.toUpperCase()
                                                : enrolledPrescriberData[0]?.label
                                        } will not be
                                        honored at certified pharmacies.`}
                                                    </div>
                                                )}
                                            </div>
                                            <Input
                                                type="text"
                                                name="userName"
                                                label="User Name"
                                                onChangeHandler={onChangeHandler}
                                                isRequired={true}
                                                maxLength={20}
                                                value={values.userName.trim()}
                                                defaultValue={values.userName.trim()}
                                            />
                                            <Input
                                                type="password"
                                                value={values.password.trim()}
                                                defaultValue={values.password.trim()}
                                                name="password"
                                                label="Password"
                                                onChangeHandler={onChangeHandler}
                                                isRequired={true}
                                                maxLength={20}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className={styles.abc}>
                                <div style={{ float: 'left' }} className={styles.contact_information}>
                                    {showPrintButton && (
                                        <div className={styles.signed_by}>
                                            <p className={styles.signed_by_paragraph}>{`Signed by ${
                                                accFormDetails.firstName
                                            } ${accFormDetails.lastName} on ${moment(new Date()).format(
                                                'MMM DD, YYYY'
                                            )}`}</p>
                                        </div>
                                    )}
                                    <span>REMS Call Center</span>
                                    <br></br>
                                    <span>Phone: 1-888-423-5436</span>
                                    <br></br>
                                    <span>Fax: 1-888-432-9325</span>
                                    <br></br>
                                    <a>
                                        {
                                        ProgramConfigByName(enrolledPrescriberData[0]?.label).link
                                      }
                                    </a>
                                    <br></br>
                                    <span>Monday through Friday from 8:00 AM to 8:00 PM ET</span>
                                    <br></br>
                                </div>
                                <div style={{ float: 'right' }}>
                                    {showPrintButton && (
                                        <>
                                            {!(enrolledPrescriberData[0]?.label === ProgramConfig('rems_program_lenalidomide').label) && (
                                                <>
                                                    <p style={{ width: '200px' }}>{getAdobeText()}</p>
                                                    <a
                                                        href="https://get.adobe.com/reader/"
                                                        target="blank"
                                                        rel="noopener"
                                                    >
                                                        <div
                                                            style={{ margin: '18px' }}
                                                            className={styles.getAdobe}
                                                        ></div>
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                            {submitDisabled && (
                                <div style={{ margin: '10px' }}>
                                    <p> Please wait while your submission is processed.</p>
                                </div>
                            )}
                            {showPrintButton && (
                                <div className={styles.submit_print_button}>
                                    <Button
                                        clickFunction={submitButtonClickHandler}
                                        className={!submitDisabled ? 'buttonStyles' : 'disabledButton'}
                                        type="text"
                                        buttonname={!submitDisabled ? 'Submit' : ''}
                                        width={119}
                                        height={48}
                                        size={18}
                                        disabled={submitDisabled}
                                    />
                                    <Button
                                        clickFunction={handlePrint}
                                        className="sub_print_button"
                                        type="text"
                                        buttonname="Print"
                                        width={119}
                                        height={48}
                                        size={18}
                                    />
                                </div>
                            )}
                        </div>
                        {!showPrintButton && (
                            
                              <>
                              <div className={styles.buttonStyles_signOn}>
                                <Button
                                  clickFunction={handleSubmit}
                                  className='buttonStyles'
                                  type="text"
                                  width={216}
                                  height={48}
                                  size={18}
                                  buttonname="Sign and Continue"
                                  disabled={loader}
                              />
                              </div>
                              { loader && ( <div className={styles.loader}>
                                  <Flex align="center" gap="middle">
                                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                  </Flex>
                              </div>)}
                              </>
                           
                          
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default ProductRegSignUp;
