import { useAppDispatch, useAppSelector } from 'store';
import classes from './patientDetailsTable.module.scss';
import { updateSelectedPatient } from '../../../store/slices/appSlice';
import rightArrow from '../../../assets/images/btn_arrow_right.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PatientDetailsTable = ({ setIsSorted, nonPrescriberAssociatedPrescribers = null, isFiltered }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { patientList } = useAppSelector((state) => state.app);
    const { content }: { content: any } = patientList ?? { content: null };
    const [isLoading, setIsLoading] = useState(true);
    const { prescriberDashboardFindPatientIsLoading } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const prescriberId = useAppSelector((state) => state.app.prescAllDetails.prescriberId);
    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const prescriberNonPrescriberId = !nonPrescriber ? prescriberId : currentUser.nonPrescriberId;


    const selectedPatientHandler = (patientDetails) => {
        dispatch(updateSelectedPatient({...patientDetails,nonPrescriber}));
        navigate('/prescriber/patientdetails');
    };

    const sortByPatientNameAscOrder = () => {
        setIsSorted({ isSorted: true, sortByField: 'PatientNameLast', sortByOrder: 'ASC' });
    };

    const sortByPatientNameDescOrder = () => {
        setIsSorted({ isSorted: true, sortByField: 'PatientNameLast', sortByOrder: 'DESC' });
    };

    const sortByPrescriberNameAscOrder = () => {
        setIsSorted({ isSorted: true, sortByField: 'AssocPrescriberNameLast', sortByOrder: 'ASC' });
    };

    const sortByPrescriberNameDescOrder = () => {
        setIsSorted({ isSorted: true, sortByField: 'AssocPrescriberNameLast', sortByOrder: 'DESC' });
    };

    const tableHeader = () => {
        return (
            <tr className="firstRow">
                <th align="left">Patient Name</th>
                <th align="right" className={classes.sortControl}>
                    <a onClick={sortByPatientNameAscOrder}>
                        <span className={`${classes.icon} ${classes.upArrow}`}></span>
                    </a>
                    &nbsp;
                    <a onClick={sortByPatientNameDescOrder}>
                        <span className={`${classes.icon} ${classes.downArrow}`}></span>
                    </a>
                </th>
                <th align="left" colSpan={2}>
                    Patient ID
                </th>
                <th align="left">Prescriber Name</th>
                <th align="right" className={classes.sortControl}>
                    <a onClick={sortByPrescriberNameAscOrder}>
                        <span className={`${classes.icon} ${classes.upArrow}`}></span>
                    </a>
                    &nbsp;
                    <a onClick={sortByPrescriberNameDescOrder}>
                        <span className={`${classes.icon} ${classes.downArrow}`}></span>
                    </a>
                </th>
            </tr>
        );
    };

    useEffect(() => {
        if (nonPrescriberAssociatedPrescribers === false) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [nonPrescriberAssociatedPrescribers, isFiltered]);

    useEffect(() => {
        if (content && content.length > 0) {
            setIsLoading(false);
        }
    }, [content, isFiltered])

    let errorMessage =
        nonPrescriberAssociatedPrescribers !== null
            ? !nonPrescriberAssociatedPrescribers
                ? 'A prescriber must add you as an associate within his/her My Account section in order to view patients.'
                : 'No Records Found.'
            : 'No Records Found.';

    const getTableContent = () => {
        if (prescriberDashboardFindPatientIsLoading) {
            return null;
        }else{
            if (!content || content.length === 0) {
                const msgToShow = isLoading ? 'You currently do not have any patients to view.' : errorMessage;
                return (
                    <table width="580px" cellPadding="0" cellSpacing="0" border={0} className={classes.patientTable}>
                        <tbody>
                            {tableHeader()}
                            <tr className="odd">
                                <td className={classes.noRecordsFound} align="center" colSpan={7}>
                                    {msgToShow}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            } else {
                return (
                    <table width="580px" cellPadding="0" cellSpacing="0" border={0} className={classes.patientTable}>
                        <tbody>
                            {tableHeader()}
                            {content.map((each) => {
                                return (
                                    <tr key={each.patientExtUniqueId} className={classes.patientDetails}>
                                        <td colSpan={2}>
                                            <a
                                                onClick={() => {
                                                    selectedPatientHandler(each);
                                                }}
                                            >
                                                <span>
                                                    {each.patientNameLast && each.patientNameLast.trim()}
                                                    {', '}
                                                    {each.patientNameFirst && each.patientNameFirst.trim()}
                                                </span>
                                                <img src={rightArrow} alt="right-arrow" />
                                            </a>
                                        </td>
                                        <td colSpan={2}>{each.patientExtUniqueId && each.patientExtUniqueId}</td>
                                        <td colSpan={2}>
                                            <span>
                                                {each.assocPrescriberNameLast && each.assocPrescriberNameLast.trim()}
                                                {', '}
                                                {each.assocPrescriberNameFirst && each.assocPrescriberNameFirst.trim()}
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }
        }
    };

    return (<>{getTableContent()}</>);
};
export default PatientDetailsTable;