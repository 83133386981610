import { bodyContent, headContent } from 'assets/objects/LoginHome';
import PageBackground from 'components/PageBackground';
import PendingEnrollmentModal from 'components/PendingEnrollment';
import { PROGRAMS, ProgramConfig, ProgramConfigIMG } from 'constants/Config/programsConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';

import classes from './loginHome.module.scss';
import moment from 'moment';
import SessionTimeout from 'components/SessionTimeout';
import EnrollmentModal from 'components/EnrollmentModal';
import PopUp from 'components/PopUp';
import i18n from 'internationalization/i18next';
import {
    changePPAF,
    changeProgram,
    fetchCurrentPrescriber,
    handlePrescriber,
    popUpNotification,
    prescriberHomeThunk,
    saveNotification,
    selectedPatientProgram,
    setAccFormDetails,
    setActiveEnrollments,
    setPrescriberCalendarActionItemPayload,
    setPrescriptionFormData,
    storeActionType,
    storeCalenderActionitemInfo,
} from 'store/slices/appSlice';
import {
    getNonPrescriberId,
    setClosedPopUpTemplate,
    setaccessCodeFlag,
    updateActiveSessionAuth,
} from 'store/slices/loginSlice';
import Button from 'components/Button';
import indexedDBStore from 'store/indexedDBStore';
import { Role } from 'constants/Config/appConfig';
const programs: any = PROGRAMS;
const LoginHome = () => {
    const navigate = useNavigate();
    const [enrollmentMessage, setEnrollmentMessage] = useState('');
    const [pendingEnrollment, setPendingEnrollment] = useState('');
    const [enrollmentModal, setEnrollmentModal] = useState('');
    const [filtered, setFiltered] = useState<any>([]);
    const [finalCopy, setFinalCopy] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const [showAcknowledge, setShowAcknowledge] = useState(true);
    const [programID, setProgramID] = useState('');
    const dispatch = useAppDispatch();
    const { prescriberEnrollment } = useAppSelector((state) => state.app);
    const { currentUser, IsAuthenticated, popUpTemplate, closedPopUpTemplate, currentUserPharmacy } = useAppSelector((state) => state.login);
    const { popUpData } = useAppSelector((state) => state.app);
    sessionStorage.setItem('token', currentUser.accessToken);
    
    const urlNavigate = (item, id) => {
        setProgramID(id);
        const itemTitle = item.title.split('For ')[1];
        setEnrollmentMessage(itemTitle);
        const selectedProdName = item.name.toLowerCase().split(' rems')[0].trim();
        if (prescriberEnrollment.length > 0) {
            if (prescriberEnrollment[0] !== '') {
                let prescriberEnrollmentStatus = '';
                prescriberEnrollment.forEach((item) => {
                    if (item['productName'].toLowerCase() === selectedProdName.toLowerCase()) {
                        prescriberEnrollmentStatus = item['status'];
                    }
                });
                if (currentUser.groupTag.includes('NonPrescriber')) {
                    const pId = id;
                    dispatch(changeProgram(programs.find((i) => i.id === pId)));
                    dispatch(changePPAF(true));
                    navigate('/prescriber/MainMenu');
                } else if (
                    prescriberEnrollmentStatus.toLowerCase() === 'pending' ||
                    prescriberEnrollmentStatus.toLowerCase() === 'deactivated'
                ) {
                    setPendingEnrollment('true');
                } else if (prescriberEnrollmentStatus.toLowerCase() === 'active') {
                    const pId = id;
                    dispatch(changeProgram(programs.find((i) => i.id === pId)));
                    dispatch(changePPAF(true));
                    navigate('/prescriber/MainMenu');
                } else {
                    setEnrollmentModal('true');
                }
            }
        } else {
            setEnrollmentModal('true');
        }

        /* window.open(
			`https://${item.link}`,
			item.name,
			'location=0,status=1,scrollbars=1,resizable=yes, top=60,left=40,width=' +
			window.screen.availWidth * (11 / 12) +
			',height=600'
		); */
        dispatch(selectedPatientProgram(item));
        //navigate('/prescriber/patientagreementform');
    };
    const handlePenEnrollOutsideClick = (e) => {
        if (e.target.className.indexOf('pendingEnrollment_modalContainer') === 0) {
            setPendingEnrollment('');
        }
        e.stopPropagation();
    };

    const nonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');

    const updateUserinfo = (currentUser) => {
        const storeData = {
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            officeAddress: currentUser?.userinfo?.address?.formatted,
            city: currentUser?.userinfo['custom:city'],
            state: currentUser?.userinfo['custom:state'],
            zipCode: currentUser?.userinfo['custom:zip_code'],
            phoneNumber: currentUser?.userinfo?.phone_number
                ? currentUser?.userinfo?.phone_number?.replace(/^\+1\s*|\s*/g, '')
                : null,
            email: currentUser?.userinfo?.email,
            userName: currentUser?.userinfo?.username,
            securityQuestion: currentUser?.userinfo['custom:security_question'],
            securityAnswer: currentUser?.userinfo['custom:security_answer'],
        };

        sessionStorage.setItem('accountData', JSON.stringify(storeData));
        dispatch(setAccFormDetails(storeData));
        if (currentUser.groupTag?.toLowerCase()?.includes('nonprescriber')) {
            dispatch(handlePrescriber(Role.NonPrescriber));
        } else {
            dispatch(handlePrescriber(Role.Prescriber));
        }
    };
    useEffect(() => {
        if (nonPrescriber) {
            if (currentUser.onlineUserId && currentUser.onlineUserId !== '') {
                const payload = {
                    userId: currentUser.onlineUserId,
                };
                dispatch(getNonPrescriberId(payload));
            }
        }
        //check is session is active
        const checkActiveSession = async () => {
            const activeUser = await indexedDBStore.getItem('prescriberUser');
            if (activeUser?.currentUser?.accessToken) {
                dispatch(setaccessCodeFlag(true));
                dispatch(updateActiveSessionAuth({ data: activeUser?.currentUser, role:"prescriber" }));
                if (!currentUser?.accessToken) {
                    updateUserinfo(activeUser?.currentUser);
                }
            }
        };
        checkActiveSession();
    }, []);

    const handleEnrollmentCancel = () => {
        setEnrollmentModal('');
    };

    const handleEnrollmentContinue = () => {
        dispatch(changeProgram(programs.find((i) => i.id === programID)));
        navigate('/prescriber/productRegistration');
    };

    const handlePrescriberDashBtn = () => {
        navigate('/prescriber/dashboard');
    };

    const handlePrescriberManBtn = () => {
        navigate('/prescriber/manageMyAccount');
    };

    useEffect(() => {
        if(!IsAuthenticated) { 
            navigate('/prescriber/login', { replace: true });
        }

        
        if (IsAuthenticated) {
            i18n.changeLanguage('en');

            const payload = currentUser?.systemId
                ? {
                      prescriberId: currentUser.userinfo['custom:prescriber_user_id'] || currentUser?.systemId,
                  }
                : {
                      onlineUserId: currentUser.userinfo?.username,
                  };
            dispatch(fetchCurrentPrescriber(payload));
        }
    }, [IsAuthenticated]);

    useEffect(() => {
        dispatch(prescriberHomeThunk('prescriberHome'));
    }, [dispatch]);

    useEffect(() => {
        let productNames: any[] = [];
        prescriberEnrollment.forEach((product) => {
            if (product.status === 'Active') {
                productNames.push(product.productName);
            }
        });
        dispatch(setActiveEnrollments(productNames));
        const payload = {
            username: currentUser.userinfo?.username,
            groupTag: currentUser.userinfo ? currentUser.userinfo['custom:group_tag'] : ''
           
        };

        prescriberEnrollment.some((item) => typeof item === 'object' && item.productName) &&
            dispatch(popUpNotification(payload));
        dispatch(setPrescriptionFormData({}));
        dispatch(storeActionType({}));
        dispatch(storeCalenderActionitemInfo([]));
        dispatch(setPrescriberCalendarActionItemPayload({}));
    }, [prescriberEnrollment]);

    useEffect(() => {
        if (popUpData && popUpTemplate && popUpData.length > 0 && popUpTemplate.length > 0) {
            const filteredArray = popUpTemplate.filter((itemA) =>
                popUpData.some((itemB) => itemB.functionName === itemA.name)
            );
            setFiltered(filteredArray);
        }
    }, [popUpData, popUpTemplate]);

    const iterateHandle = (value, acknowledged) => {
        let findIndex = filtered.findIndex((x) => x.name === value.name);
        if (findIndex !== -1) {
            let shallowCopy = JSON.parse(JSON.stringify(filtered));
            shallowCopy[findIndex].closed = value.closed;
            dispatch(setClosedPopUpTemplate([...shallowCopy]));
            setFiltered([...shallowCopy]);
            setModal(false);
        }
        if (acknowledged && currentUser.userinfo) {
            const payload = {
                celgeneId:
                    currentUser.userinfo['custom:group_tag'] === 'GBL-AP-User-GRMP-NonPrescriber'
                        ? 0
                        : currentUser?.systemId
                        ? currentUser?.systemId
                        : '',
                userName: currentUser.userinfo?.username,
                groupTag: currentUser.userinfo ? currentUser.userinfo['custom:group_tag'] : '',
                functionName: value.name,
                countryId: {
                    countryId: 1,
                },
                languageId: {
                    languageId: 1,
                },
            };
            dispatch(saveNotification(payload));
        }
    };

    const getPayloadForPopUp = (arr) => {
        if (arr.some((value) => value.closed === 'false')) {
            let FinalArray = arr.find((value) => value.closed === 'false');
            let Data = popUpData.filter((value) => value.functionName === FinalArray.name);
            if (Data.length > 0 && Data[0].acknowledgementFlag === 'Y') {
                setShowAcknowledge(true);
            } else {
                setShowAcknowledge(false);
            }
            setFinalCopy([FinalArray]);
            setModal(true);
        }
    };

    useEffect(() => {
        if (closedPopUpTemplate.length > 0) {
            getPayloadForPopUp(closedPopUpTemplate);
        } else {
            getPayloadForPopUp(filtered);
        }
    }, [filtered, closedPopUpTemplate]);

    return (
        <div style={{ marginTop: '10px' }} className={classes.loginHome}>
            <PageBackground label="Home">
                <>
                    <SessionTimeout />
                    {finalCopy &&
                        finalCopy[0] &&
                        finalCopy.map((value) => {
                            return (
                                <PopUp
                                    pop={value}
                                    iterateHandle={iterateHandle}
                                    modal={modal}
                                    acknowledge={showAcknowledge}
                                />
                            );
                        })}
                    {pendingEnrollment && (
                        <PendingEnrollmentModal message="" handleOutsideClick={(e) => handlePenEnrollOutsideClick(e)} />
                    )}
                    {enrollmentModal && (
                        <EnrollmentModal
                            message={enrollmentMessage}
                            handleCancel={handleEnrollmentCancel}
                            handleContinue={handleEnrollmentContinue}
                        />
                    )}
                    <div className={classes.contentContainer}>
                        <div className={classes.contentPadding}>
                            <div className={classes.textPadding}>
                                {headContent.slice(0, 3).map((item) => (
                                    <p
                                        key={item.order}
                                        dangerouslySetInnerHTML={{ __html: headContent[0].content }}
                                    ></p>
                                ))}
                            </div>
                            <div className={classes.flexContainer}>
                                {bodyContent?.programs?.map((item, index) => (
                                    <div key={item.name} className={classes.programContainer}>
                                        <div
                                            className={classes.programTitle}
                                            dangerouslySetInnerHTML={{ __html: item?.title }}
                                        ></div>
                                        <img
                                            onClick={() => urlNavigate(item, programs[index].id)}
                                            src={require(`../../assets/images/${ProgramConfigIMG(item.id)}`)}
                                            alt={item.name}
                                        />
                                        <p
                                            className={classes.prodInfoText}
                                            dangerouslySetInnerHTML={{ __html: item.content1 }}
                                        ></p>
                                        <p
                                            className={classes.prodInfoText}
                                            dangerouslySetInnerHTML={{ __html: item.content2 }}
                                        ></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={classes.buffer}>&nbsp;</div>
                    <div className={classes.contentContainer}>
                        <div className={classes.contentPadding}>
                            <div className={classes.textPadding}>
                                {bodyContent?.gradientContainer
                                    ?.slice(0, 3)
                                    .map((item) => (
                                        <p key={item.order} dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                    ))}
                            </div>
                        </div>

                        <div className={classes.actionbuttons}>
                            <Button
                                clickFunction={handlePrescriberDashBtn}
                                className="mr12"
                                width={198}
                                height={34}
                                size={14}
                                type="text"
                                buttonname="Prescriber Dashboard"
                            ></Button>
                            <Button
                                clickFunction={handlePrescriberManBtn}
                                className="mr10"
                                width={198}
                                height={34}
                                size={14}
                                type="text"
                                buttonname="Manage My Account"
                            ></Button>
                        </div>
                    </div>
                </>
            </PageBackground>
        </div>
    );
};

export default LoginHome;
