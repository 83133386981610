import { useRef } from "react";
import classes from "./waitScreen.module.scss";
export type WaitScreenProps = {
    label?: string;
};

const ReviewWaitScreen = ({ label }: WaitScreenProps) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);
    const defaultText: string = "Please wait while your submission is processed. Processing may take up to two minutes to confirm the dispenses.";
    return (
        <div className={classes.modalContainer}>
            <dialog ref={modalRef} className={classes.mainContainer}>
                <div className={classes.whiteInfoContainer}>
                    <div className={classes.content}>
                        <div className={classes.instructions}>
                            {label ? label : defaultText}
                        </div>
                    </div>
                </div>
            </dialog>
        </div>

    )
}

export default ReviewWaitScreen;