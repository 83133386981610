import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/Input';
import classes from './createaccount.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    resetAccValidation,
    setAccFormDetails,
    setAccountPwdDetails,
    handlePrescriber,
} from '../../../store/slices/appSlice';
import { createAccFormFields, requiredLabels } from '../../../constants/createPharmacyAccountFields';
import ErrorModal from '../../../components/ErrorModal';
import { Role, RoleByGroup } from 'constants/Config/appConfig';
import Alert from '../../../components/Alert';
import {
    isValidPassword,
    isValidEmail,
    deaAlphaNumeric,
    isValidName,
    isValidUserName,
    isValidSystemId,
} from '../../../utils/regex';
import { accountContent } from '../../../assets/objects/createAccountContent';
import PageBackground from 'components/PageBackground';
import { axiosInstanceCognitoUrl, axiosInstancePharmacyUnsecure, axiosInstanceValidatePharmacyUnsecure } from 'constants/Config/axiosConfig';
import { axiosInstancePharmacy } from 'constants/Config/axiosConfig';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import { reloadFunction } from 'utils/utilsCommon';

interface dropDownType {
    SecurityQuestion?: { id: string; value: string }[] | null;
}

const CreatePharmacyAccount = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { accFormDetails, errorValidation, errorCreateAccount } = useAppSelector((state) => state.app);
    const [dropdown, setDropdown] = useState<dropDownType>({ SecurityQuestion: [] });
    const [currentFocus, setCurrentFocus] = useState('');
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        accessCode: '',
        userName: '',
        NCPDPNumber: '',
        DEANumber: '',
        password: '',
        verifyPassword: '',
        email: '',
        securityQuestion: '',
        securityAnswer: '',
    });

    const [error, setError] = useState('');
    const [errorMessage, seterrorMessage] = useState('');

    useEffect(() => {
        setValues((prev) => ({ ...prev, ...accFormDetails }));
        const existing = sessionStorage.getItem('accountData');
        if (existing) {
            setValues((prev) => ({ ...prev, ...JSON.parse(existing) }));
        }
    }, [accFormDetails]);

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        if (errorValidation.message === 'Validation Error') {
            setValues((prev) => ({ ...prev, password: '', verifyPassword: '' }));
        }
    }, [errorValidation]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    const fetchDropdown = async () => {
        fetchDatafromS3json().then((res) => {
            setDropdown({ SecurityQuestion: res?.securityQuestion });
        });
    };
    useEffect(() => {
        sessionStorage.setItem('role', Role.Pharmacist);
        dispatch(handlePrescriber(sessionStorage.getItem('role')));
        fetchDropdown();
    }, []);

    const handleOnChange = (e) => {
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const scrollToView = () => {
        containerRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const checkPasswordMatch = () => {
        const { password, firstName, lastName, userName } = values;
        return (
            password.toLowerCase().includes(firstName.toLowerCase()) ||
            password.toLowerCase().includes(lastName.toLowerCase()) ||
            password.toLowerCase().includes(userName.toLowerCase())
        );
    };
    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            if (!['password', 'verifyPassword', 'securityQuestion'].includes(field)) {
                newVals[field] = newVals[field].trim();
            }
        });
        setValues(newVals);
        return newVals;
    };
    const handleSubmit = async () => {
        trimValues();
        for (let i of requiredLabels) {
            if (
                 values[i.name].trim() === '' ||
                (!['password', 'verifyPassword'].includes(i.name) && values[i.name].trim() === '')
            ) {
                 if (
                    i.name === 'NCPDPNumber' &&
                    values.NCPDPNumber.trim().length == 0 &&
                    values.DEANumber.trim().length == 0
                ) {
                    setError('Please enter a valid NCPDP Number and/or DEA Number.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
                if(i.name === 'NCPDPNumber' &&  values['DEANumber'].trim() !== '' ){
                    setError('')
                    continue
                }
                if(i.name === 'DEANumber' &&  values['NCPDPNumber'].trim() !== '' ){
                    setError('')
                    continue
                }
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (['firstName', 'lastName'].includes(i.name) && !isValidName(values[i.name])) {
                setCurrentFocus(i.name);
                setError(i.errMsg);
                scrollToView();
                return;
            } else if (i.name === 'accessCode' && !isValidSystemId(values[i.name])) {
                setCurrentFocus(i.name);
                setError(i.errMsg);
                scrollToView();
                return;
            } else if (
                i.name === 'NCPDPNumber' &&
                values.NCPDPNumber.trim().length > 0 &&
                !isValidSystemId(values.NCPDPNumber.trim())
            ) {
                setError('Please enter a valid NCPDP number.');
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (
                i.name === 'DEANumber' &&
                values.DEANumber.trim().length > 0 &&
                !deaAlphaNumeric(values.DEANumber.trim())
            ) {
                setError('Please enter a valid DEA number.');
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'userName') {
                if (values.userName.trim().length < 4 || values.userName.trim().length > 20) {
                    setError('User name must be between 4 and 20 characters.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                } else if (!isValidUserName(values.userName.trim())) {
                    setError('Please enter a valid User Name.');
                    setCurrentFocus(i.name);
                    scrollToView();
                    return;
                }
            } else if (i.name === 'password' && (!isValidPassword(values.password) || checkPasswordMatch())) {
                setError(
                    `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name.`
                );
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'verifyPassword' && (!isValidPassword(values.verifyPassword) || checkPasswordMatch()) )  {
                setError(
                    `You have entered a password that does not meet the password guidelines. Please choose a password eight (8) or more characters in length, with at least one capital letter, one lowercase letter, and one numeric. Passwords cannot contain your first name, last name, or user name.`
                );
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } 
            else if (i.name === 'verifyPassword' && values.password !== values.verifyPassword) {
                setError(`The new passwords you have entered do not match. Please enter your new passwords again.`);
                scrollToView();
                setCurrentFocus(i.name);
                return;
            } else if (i.name === 'email' && !isValidEmail(values.email.trim())) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                scrollToView();
                return;
            } else if (i.name === 'securityQuestion' && values.securityQuestion.includes('Select a question')) {
                setError(i.errMsg);
                scrollToView();
                setCurrentFocus(i.name);
                return;
            } else if (
                i.name === 'securityAnswer' &&
                (values.securityAnswer.includes('<') || values.securityAnswer.includes('>'))
            ) {
                setError('Please enter a valid Security Answer');
                scrollToView();
                setCurrentFocus(i.name);
                return;
            } else {
                setError('');
            }
        }
        const { password, verifyPassword, ...data } = trimValues();
        sessionStorage.setItem('pass', password);

        dispatch(
            setAccountPwdDetails({
                password: values.password,
            })
        );

        // const stateId = dropdown.State?.find((o) => o.value === values.state)?.id;
        // dispatch(checkPharAccountDetailsValidation({ values }));
 
           const Validatepharmacy = { accessCode: values.accessCode, ncpdpNumber:values.NCPDPNumber || null, deaNumber: values.DEANumber.toUpperCase() || null};
            try {
                const response: any = await axiosInstanceValidatePharmacyUnsecure.post(`validate-pharmacy`, Validatepharmacy);
               if (response.data?.message === 'Success') {
                    const updatedData = {
                    ...data,
                    city: response.data.data.pharmacyLocations[0].postalCode.city || '',
                    state: response.data.data.pharmacyLocations[0].postalCode.state.stateCode || '',
                    zipCode: response.data.data.pharmacyLocations[0].postalCode.postalCode1 || '' ,
                    AddressLine1: response.data.data.pharmacyLocations[0].addressLine1,
                    AddressLine2: response.data.data.pharmacyLocations[0].addressLine2,
                    phoneNumber: response.data.data.pharmacyLocations[0].pharmacyPhoneNumber,
                    officeAddress:response.data.data.pharmacyLocations[0].addressLine1 + ' ' +response.data.data.pharmacyLocations[0].addressLine2
                };
              
                dispatch(setAccFormDetails(updatedData))
                    try {
                        // const res = await axiosInstanceNewUrl.post(`authentication-api/v1/validateUsername`, {
                        const res = await axiosInstanceCognitoUrl.post(`/ValidateUsername`, {
                            username: values.userName,
                            group_tag: RoleByGroup.Pharmacist
                        });
                        if(res.data.statusCode === 502){
                            sessionStorage.setItem('pharmacyAccnt',JSON.stringify(values))
                            navigate('/pharmacy/CreateAccountConfirmation', { replace: true });
                        }else if (res.data.isUserValid){
                            seterrorMessage('The user name you have chosen is taken. Please enter a different user name.')
                        }
                        return;
                    } catch (err: any) {
                        seterrorMessage(err.res.data.message)
                        return;
                    }
                } else if (response.data.statusCode === 400) {
                    let error = response.data.message
                    seterrorMessage(error)
                    return
                } else {
                    navigate('/prescriber/SystemError');
                    return
                }
            } catch (err: any) {
                seterrorMessage('API Failed')
                return
            }
    };

    useEffect(() => {
            if (errorValidation.message === 'Success') {
            dispatch(resetAccValidation());
            sessionStorage.setItem('pharmacyAccnt',JSON.stringify(values))
            navigate('/pharmacy/CreateAccountConfirmation', { replace: true });
        } else if (errorMessage) {
            setValues((prev) => ({ ...prev, password: '', verifyPassword: '' }));
            scrollToView();
        } else if (errorCreateAccount.message !== 'Success' && errorCreateAccount.message !== null) {
            scrollToView();
        }
    }, [dispatch, errorMessage, navigate, errorCreateAccount]);

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        !['securityQuestion'].includes(currentFocus) && inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    return (
        <>
            <PageBackground label="Create User Name and Password" btn={true} handleClick={handleSubmit}>
                <>
                    {
                        <div ref={containerRef} style={{ margin: '12px 0 0 0' }}>
                            Please enter your first name, last name, and the Access Code that you received from your
                            REMS Representative. If you need further assistance, please contact your REMS
                            Representative.
                        </div>
                    }
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>

                    <div className={classes.contentContainer}>
                        {errorMessage && (
                            <Alert
                                content={ errorMessage }
                                styles={{ margin: '10px 20px 5px 20px' }}
                            />
                        )}
                        <div className={classes.formHeader}>{accountContent.body[0].content.title}</div>
                        <div className={classes.AccountContainer}>
                            <div>
                                {createAccFormFields.personalInfo.map((i) => {
                                    return (
                                        <Input
                                            key={i.name}
                                            type={i.type}
                                            name={i.name}
                                            label={i.label}
                                            // helpText={i.helpText}
                                            maxLength={i.maxLength}
                                            isRequired={i.isRequired}
                                            onChangeHandler={handleOnChange}
                                            value={values[i.name]}
                                            defaultValue={accFormDetails[i.name]}
                                        />
                                    );
                                })}
                            </div>
                            <div>
                                {createAccFormFields.systemID.map((i) => {
                                    return (
                                        <Input
                                            key={i.name}
                                            type={i.type}
                                            name={i.name}
                                            label={i.label}
                                            // helpText={i.helpText}
                                            maxLength={i.maxLength}
                                            isRequired={i.isRequired}
                                            onChangeHandler={handleOnChange}
                                            value={values[i.name]}
                                            defaultValue={accFormDetails[i.name]}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className={classes.AccountContainerfooter}>
                            {createAccFormFields.personalId.map((i) => {
                                if (i.name === 'NCPDPNumber') {
                                    return (
                                        <>
                                            <Input
                                                key={i.name}
                                                type={i.type}
                                                name={i.name}
                                                label={i.label}
                                                maxLength={i.maxLength}
                                                isRequired={true}
                                                onChangeHandler={handleOnChange}
                                                value={values[i.name]}
                                                defaultValue={accFormDetails[i.name]}
                                                containerStyle={{ width: '377px'}}
                                            />
                                            <span style={{ marginTop: '5px', marginLeft: '-35px' }}>-and/or-</span>
                                        </>
                                    );
                                }
                                if (i.name === 'DEANumber') {
                                    return (
                                        <Input
                                            key={i.name}
                                            type={i.type}
                                            name={i.name}
                                            label={i.label}
                                            maxLength={i.maxLength}
                                            isRequired={false}
                                            onChangeHandler={handleOnChange}
                                            value={values[i.name]}
                                            defaultValue={accFormDetails[i.name]}
                                            containerStyle={{ marginLeft: '-65px'}}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className={classes.contentContainer}>
                        <div className={classes.formHeader}>{accountContent.body[1].content.title}</div>
                        <div className={classes.formSubHeader}>{accountContent.body[1].content?.subHeader}</div>
                        {createAccFormFields.primaryOfc.map((i) => {
                            if (i.name === 'securityQuestion') {
                                return (
                                    <Input
                                        key={i.name}
                                        type="select"
                                        isRequired={true}
                                        name="securityQuestion"
                                        options={
                                            dropdown?.SecurityQuestion
                                                ? [
                                                      { value: 'Select a question...' } as { value: string },
                                                      ...dropdown?.SecurityQuestion?.map((item) => ({
                                                          value: item.value,
                                                      })),
                                                  ]
                                                : []
                                        }
                                        label="Security Question"
                                        style={{ width: '268px' }}
                                        onChangeHandler={handleOnChange}
                                        defaultValue={accFormDetails[i.name] || values[i.name]}
                                        containerStyle={{ width: '800px', 'margin-left':'-95px'}}
                                    />
                                );
                            } else {
                                return (
                                    <Input
                                        key={i.name}
                                        type={i.type}
                                        name={i.name}
                                        label={i.label}
                                        helpText={i.helpText}
                                        maxLength={i.maxLength}
                                        isRequired={i.isRequired}
                                        onChangeHandler={handleOnChange}
                                        defaultValue={accFormDetails[i.name]}
                                        value={values[i.name]}
                                        containerStyle={{ width: '800px', 'margin-left':'-95px'}}
                                    />
                                );
                            }
                        })}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </>
    );
};

export default CreatePharmacyAccount;
