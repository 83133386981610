import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageBackground from 'components/PageBackground';
import classes from './ForgotPassword.module.scss';
import ErrorModal from 'components/ErrorModal';
import { isValidUserName } from 'utils/regex';
import Alert from 'components/Alert';
import { useAppDispatch, useAppSelector } from 'store';
import { checkUserExists, handlePrescriber } from 'store/slices/appSlice';
import SessionTimeout from 'components/SessionTimeout';
import { Role } from 'constants/Config/appConfig';
import { reloadFunction } from 'utils/utilsCommon';
import MigratePopUp from 'components/MigratePopUp';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location=useLocation();
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const [userName, setUserName] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [showModal, setShowModal] = useState(true);
    const { role } = useAppSelector((state) => state.app);
    const isPharmacy = role === Role.Pharmacist;
    const [alertContent, setAlertContent] = useState('');


    const handleOnChange = (e) => {
        setUserName(e.target.value);
    };

    const handleContinue = () => {
        if (!userName.trim()) {
            setError('Please enter your User Name.');
            setCurrentFocus('userName');
            return;
        }
        if (!isValidUserName(userName.trim())) {
            setError('Please enter a valid User Name.');
            setCurrentFocus('userName');
            return;
        }
        const payload = {
            userName: userName.trim(),
        };
        dispatch(checkUserExists(payload)).then((e) => {
            if (e.payload?.statusCode !== 200) {
                setApiError('not an existing user');
                setError('');
                setCurrentFocus('');
                setUserName('');
                setAlertContent(
                    'You have entered an incorrect user name. Please try again. If you feel that the name you have entered is correct, but you cannot proceed to the next screen, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                );
            } else {
                if (isPharmacy) {
                    if (
                        e.payload?.groupTag?.toLowerCase()?.includes(Role.Pharmacist) &&
                        e.payload?.userStatus === 'Active'
                    ) {
                        sessionStorage.setItem('resetData',userName.trim())
                        navigate('/pharmacy/pharmacySecurity', {
                            replace: true,
                        });
                    } else {
                        setApiError('not an existing user');
                        e.payload?.groupTag?.toLowerCase()?.includes(Role.Pharmacist) && e.payload?.userStatus !== 'Active'
                            ? setAlertContent(
                                  'We’re sorry your account has been locked due to unsuccessful login attempts; or one (1) year of inactivity. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                              )
                            : setAlertContent(
                                  'You have entered an incorrect user name. Please try again. If you feel that the name you have entered is correct, but you cannot proceed to the next screen, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                              );
                        setUserName('');
                        setError('');
                        setCurrentFocus('');
                    }
                } else {
                    if (
                        e.payload?.groupTag?.toLowerCase()?.includes(Role.Pharmacist) ||
                        e.payload?.userStatus !== 'Active'
                    ) {
                        setApiError('not an existing user');
                        !e.payload?.groupTag?.toLowerCase()?.includes(Role.Pharmacist) && e.payload?.userStatus !== 'Active'
                            ? setAlertContent(
                                  'We’re sorry your account has been locked due to unsuccessful login attempts; or one (1) year of inactivity. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                              )
                            : setAlertContent(
                                  'You have entered an incorrect user name. Please try again. If you feel that the name you have entered is correct, but you cannot proceed to the next screen, please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.'
                              );
                        setUserName('');
                        setError('');
                        setCurrentFocus('');
                    } else {
                        sessionStorage.setItem('resetData',userName.trim())
                        navigate('/prescriber/securityVerification', {
                            replace: true,
                        });
                    }
                }
            }
        });
    };

    const handleCancel = () => {
        if (isPharmacy) {
            navigate('/pharmacy/login');
        } else {
            navigate('/prescriber/login');
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        inputElement.select();
        setError('');
        setCurrentFocus('');
    };

    useEffect(() => {
        dispatch(handlePrescriber(location.pathname.includes('pharmacy') ? 'pharmacist' : 'prescriber'));
    }, [location]);

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <>
        <MigratePopUp showModal={showModal} setShowModal={setShowModal} />
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label="Forgot Password"
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={handleCancel}
            >
                <>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {apiError && <Alert content={alertContent} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.formHeader}>
                            Please enter your details and click the Continue button.
                        </div>
                        <div
                            className={classes.container}
                            style={{
                                margin: '10px 0',
                            }}
                        >
                            <div className={classes.label}>
                                <span className={classes.mandatory}>User Name:</span>
                            </div>
                            <div className={classes.input}>
                                <div className={classes.inputContainer}>
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleContinue();
                                        }}
                                        autoComplete={'off'}
                                    >
                                        <input
                                            name={'userName'}
                                            onChange={handleOnChange}
                                            type={'text'}
                                            id={'userName'}
                                            maxLength={20}
                                            size={35}
                                            value={userName}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
        </>
        
    );
};

export default ForgotPassword;
