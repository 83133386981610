import React, { useRef } from 'react';
import classes from './pendingEnrollment.module.scss';
import Button from 'components/Button';

interface ModalProps {
    message: string;
    handleOutsideClick: (e) => void;
    prescriberValidationMsg?:string;
}

const PendingEnrollmentModal: React.FC<ModalProps> = ({ handleOutsideClick, message,prescriberValidationMsg }) => {
    const modalRef = useRef<HTMLDialogElement | null>(null);

    return (
        <div className={classes.modalContainer} onClick={handleOutsideClick}>
            <dialog ref={modalRef} className={classes.mainContainer}  >
                <div className={classes.whiteInfoContainer}>
                    
                    <div className={classes.bigPad}>
                        <b>
                            {prescriberValidationMsg ? (
                                <>
                                <div dangerouslySetInnerHTML={{ __html: prescriberValidationMsg }}></div>
                                </>
                            ):(
                                <>
                                    <div>You are currently not active for the selected program.</div>
                                    <div>Please contact the REMS Call Center at 1-888-423-5436.</div>
                                </>
                            )}
                            
                        </b>
                        
                    </div>
                    
                </div>
            </dialog>
        </div>
    );
};

export default PendingEnrollmentModal;
