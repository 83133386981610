import React, { useCallback, useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import classes from './ManageAccount.module.scss';
import SessionTimeout from 'components/SessionTimeout';
import PageBackground from 'components/PageBackground';
import RightArrow from '../../assets/images/btn_arrow_right.png';
import { RoleByGroup } from 'constants/Config/appConfig';
import PromptModal from 'components/PromptModal';
import { getPrescAssociation, getNonPrescAssociation, removeAssociation } from 'store/slices/appSlice';
import { Association, NonPrescAssociation, PatientPrescriber } from './ManageAssociationData';
import { programOrder, ProgramConfigByName } from 'constants/Config/programsConfig';
import { useTranslation } from 'react-i18next';

let tabNames = [
    'My Non-prescriber associations',
    'Prescribers who can see my patients',
    'Prescribers whose patients I can see',
];
const desiredOrder =programOrder();

const PrescriberManageAccount = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { prescAllDetails, productEnrolmentData, accFormDetails } = useAppSelector((state) => state.app);
    const { currentUser } = useAppSelector((state) => state.login);
    const isPrescriber = currentUser?.groupTag === RoleByGroup.Prescriber;
    const prescriberId = currentUser?.systemId || currentUser.userinfo['custom:prescriber_user_id'];
    const onlineUserId = currentUser.onlineUserId;
    const [showPopup, setShowpopup] = useState(false);
    const [promptContent, setPromptContent] = useState('');
    const [removedItem, setRemovedItem] = useState({ id: -1, tab: '', nonPrescriberId: 0, prescriberId: 0 });
    const [nonPrescassociations, setNonPrescassociations] = useState<NonPrescAssociation[]>([]);
    const [prescAssociations, setPrescassociations] = useState<Association[]>([]);
    const [parentPrescribers, setParentPrescribers] = useState<Association[]>([]);
    const [nonprescLoginAssociations, setNonprescLoginAssociations] = useState<PatientPrescriber[]>([]);
    const [nonPrescriberId, setNonPrescriberId] = useState(Number);
    const [currentTab, setCurrentTab] = useState<string>("1");
    const { associationData } = useAppSelector((state) => state.app);
    const { t } = useTranslation();

    const payload = {
        prescriberId: prescriberId,
    };
    useEffect(() => {
        fetchAssosciationData();
        
    }, []);

    const fetchAssosciationData = () => {
        if (isPrescriber) {
            setTimeout(() => {
                dispatch(getPrescAssociation({ data: payload })).then((e: any) => {
                    console.log("pres ass response",e,e.payload)
                    if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                        setPrescassociations(e.payload?.data.data.associatedPrescribers);
                        setNonPrescassociations(e.payload?.data.data.associatedNonPrescribers);
                        setParentPrescribers(e.payload?.data.data?.parentPrescribers)
                    } else if (e.payload && e.payload?.response.data && e.payload?.response.data?.statusCode === 400) {
                        if (
                            e.payload?.response.data?.message == 'The non prescriber was not found' ||
                            e.payload?.response.data?.message == 'No associations was found'
                        ) {
                            setPrescassociations([]);
                            setNonPrescassociations([]);
                            setParentPrescribers([]);
                        } else {
                            console.log('eerorr',e.payload?.response.data)
                            navigate('/prescriber/SystemError');
                        }
                    } else {
                        navigate('/prescriber/SystemError');
                    }
                });
            }, 1000);
            
        } else {
            const payloadNonpresc = {
                onlineUserId: onlineUserId || accFormDetails?.userName,
            };

            dispatch(getNonPrescAssociation({ data: payloadNonpresc })).then((e: any) => {
                if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                    setNonprescLoginAssociations(e.payload?.data.data.associatedPrescribers);
                    setNonPrescriberId(e.payload?.data.data.nonPrescriberId);
                } else if (e.payload && e.payload?.response.data && e.payload?.response.data?.statusCode === 400) {
                    if (
                        e.payload?.response.data?.message == 'The non prescriber was not found' ||
                        e.payload?.response.data?.message == 'No associations was found'
                    ) {
                        setNonprescLoginAssociations([]);
                    } else {
                        navigate('/prescriber/SystemError');
                    }
                } else {
                    navigate('/prescriber/SystemError');
                }
            });
        }
    };

    const removeAssociationFun = (item1, associationType) => {
        const removePayload = {
            removeAssociationType: associationType,
            prescriberId: associationType === 'prescriberAssociation' ? (item1.tab === 'Parent-Prescribers' ? item1.prescriberId : Number(prescriberId)) : null,
            nonPrescriberId: associationType === 'nonprescriberAssociation' ? Number(nonPrescriberId) : null,
            associatedPrescriberId: item1.prescriberId ? (item1.tab === 'Parent-Prescribers' ? Number(prescriberId) :  item1.prescriberId) : null, 
            associatedNonPrescriberId: item1.nonPrescriberId ? item1.nonPrescriberId : null,
            createdByUser: currentUser.onlineUserId,
            reasonForChange: null,
        };
        dispatch(removeAssociation({ data: removePayload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                if (e.payload?.data?.data) {
                    if(item1.tab === 'Parent-Prescribers'){
                        setCurrentTab("3")
                    }
                    fetchAssosciationData();
                }
            } else {
                navigate('/prescriber/SystemError');
            }
        });
    };

    const tabItems = tabNames.map((item, i) => {
        const id = String(i + 1);
        const removeAsstn = (item1, ind, tab) => {
            setPromptContent(
                'Are you sure you would like to remove the ' +
                    tab.replace("Parent-Prescribers","Prescriber") +
                    ' Association for ' +
                    item1.firstName +
                    ' ' +
                    item1.lastName + '?'
            );
            setRemovedItem((prev) => ({
                ...prev,
                id: ind,
                nonPrescriberId: item1.nonPrescriberId ? item1.nonPrescriberId : 0,
                prescriberId: item1.prescriberId ? item1.prescriberId : 0,
                tab: tab,
            }));
            setShowpopup(true);
        };

        return {
            label: item,
            key: id,
            children:
                item === 'My Non-prescriber associations' ? (
                    <>
                        <div className={`${classes.contentContainer} ${classes.pl5p}`}>
                            <div>
                                {nonPrescassociations ? <span>{nonPrescassociations.length}</span> : <></>}{' '}
                                Non-prescribers associated with my account:
                            </div>
                            <div className={`${classes.dFlex} ${classes.alignCenter}`}>
                                <div className={`${classes.borderBx} ${classes.width48p}`}>
                                    {nonPrescassociations.map((item, i) => (
                                        <div className={classes.dFlex} key={item.nonPrescriberId}>
                                            <span className={classes.mr5}>
                                                {item.firstName} {item.lastName} &nbsp;
                                            </span>
                                            <span className={classes.mr5}> | &nbsp; </span>
                                            <div
                                                className={classes.clickLinks}
                                                onClick={() => removeAsstn(item, i, 'Non-prescriber')}
                                            >
                                                Remove <img src={RightArrow} alt="right_arrow" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={classes.width45p}>
                                    The Non-prescribers listed here are currently associated with you and can view your
                                    patients and their corresponding personal health information within their respective
                                    dashboards. To remove an association, please click the "Remove" link.
                                </div>
                            </div>
                            <div className={classes.width49p}>
                                <div
                                    className={classes.clickLinks}
                                    onClick={() => {
                                    sessionStorage.setItem('associateType','nonPrescriber')
                                        navigate('/prescriber/createAssociation');
                                    }}
                                >
                                    Create Association with a new Non-prescriber{' '}
                                    <img src={RightArrow} alt="right_arrow" />
                                </div>
                            </div>
                        </div>
                    </>
                ) : item === 'Prescribers who can see my patients' ? (
                    <div className={`${classes.contentContainer} ${classes.pl5p}`}>
                        <div>
                            {prescAssociations ? <span>{prescAssociations.length}</span> : <></>} Prescribers who can
                            see my patients:
                        </div>
                        <div className={`${classes.dFlex} ${classes.alignCenter}`}>
                            <div className={`${classes.borderBx} ${classes.width48p}`}>
                                {prescAssociations.map((item, i) => (
                                    <div style={{ display: 'flex' }} key={item.prescriberId}>
                                        <span className={classes.mr5}>
                                            {item.firstName} {item.lastName} &nbsp;
                                        </span>
                                        <span className={classes.mr5}> | &nbsp; </span>
                                        <div
                                            className={classes.clickLinks}
                                            onClick={() => removeAsstn(item, i, 'Prescriber')}
                                        >
                                            Remove <img src={RightArrow} alt="right_arrow" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={classes.width45p}>
                                The prescribers listed here are currently associated with you and can view your patients
                                and their corresponding personal health information within their respective dashboards.
                                To remove an association, please click the "Remove" link.
                            </div>
                        </div>
                        <div className={classes.width49p}>
                            <div
                                className={classes.clickLinks}
                                onClick={() => {
                                    sessionStorage.setItem('associateType','prescriber')
                                    navigate('/prescriber/createAssociation');
                                }}
                            >
                                Create Association with a new Prescriber <img src={RightArrow} alt="right_arrow" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`${classes.contentContainer} ${classes.pl5p}`}>
                        <div>
                            {parentPrescribers ? <span>{parentPrescribers.length}</span> : <></>} Prescribers whose
                            patients I can see:
                        </div>
                        <div className={`${classes.dFlex} ${classes.alignCenter}`}>
                            <div className={`${classes.borderBx} ${classes.width48p}`}>
                                {parentPrescribers?.map((item, i) => (
                                    <div style={{ display: 'flex' }} key={item.prescriberId}>
                                        <span className={classes.mr5}>
                                            {item.firstName} {item.lastName} &nbsp;
                                        </span>
                                        <span className={classes.mr5}> | &nbsp; </span>
                                        <div
                                            className={classes.clickLinks}
                                            onClick={() => removeAsstn(item, i, 'Parent-Prescribers')}
                                        >
                                            Remove <img src={RightArrow} alt="right_arrow" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={classes.width45p}>
                                You are currently associated with the prescribers listed here. Patients for these
                                prescribers will be included in your Prescriber Dashboard view. To remove an
                                association, please click the "Remove" link next to the corresponding prescriber.
                            </div>
                        </div>
                    </div>
                ),
        };
    });

    const handleAdd = () => {
        // route to login home and disable already enrolled program
        navigate('/prescriber/Home');
    };

    const handleReset = () => {
        navigate('/prescriber/manageresetpassword');
    };

    const handlePopupContinue = () => {
        if (removedItem.tab === 'Prescriber' || removedItem.tab === 'Non-prescriber' || removedItem.tab === "Parent-Prescribers") {
            removeAssociationFun(removedItem, 'prescriberAssociation');
        }
        if (removedItem.tab === 'nonprescLogin') {
            removeAssociationFun(removedItem, 'nonprescriberAssociation');
        }

        setShowpopup(false);
    };

    
    const getEnrolledProgram = useCallback(() => {
        const programs = JSON.parse(JSON.stringify(prescAllDetails?.products));
        const sortedPrograms = programs?.sort(
            (a, b) => desiredOrder.indexOf(a?.name?.toLowerCase()) - desiredOrder.indexOf(b?.name?.toLowerCase())
        );
    
        return sortedPrograms;
    }, [prescAllDetails?.products]);
    const removeAsstnNonPresc = (item1, ind, tab) => {
        if (nonprescLoginAssociations.length === 1) {
            setPromptContent(
                t('atleastOneAssociation')
            );
        } else {
            setPromptContent(
                'Are you sure you would like to remove the ' +
                    tab +
                    ' Association for ' +
                    item1.firstName +
                    ' ' +
                    item1.lastName+'?'
            );
        }
        setRemovedItem((prev) => ({
            ...prev,
            id: ind,
            nonPrescriberId: item1.nonPrescriberId ? item1.nonPrescriberId : 0,
            prescriberId: item1.prescriberId ? item1.prescriberId : 0,
            tab: 'nonprescLogin',
        }));
        setShowpopup(true);
        // removeAssociationFun(item1, 'nonprescriberAssociation');
    };
    return (
        <div style={{ marginTop: '10px' }} className={classes.managePresAcc}>
            <SessionTimeout />
            {showPopup && (
                <PromptModal
                    handleCancel={() => {
                        setShowpopup(false);
                    }}
                    handleContinue={handlePopupContinue}
                    content={promptContent}
                />
            )}
            {isPrescriber ? (
                <PageBackground label="Manage My Account - Prescriber">
                    <div className={classes.mainContainer}>
                        <div>
                            <div className={classes.headerStyle} style={{ marginTop: '0' }}>
                                My Certification
                            </div>
                            <div className={classes.whiteBoxCont}>
                                <div style={{ display: 'flex', height: '90%' }}>
                                    <div
                                        className={classes.leftBx}
                                        style={{
                                            marginRight: '2%',
                                        }}
                                    >
                                        <div>
                                            <div className={classes.blackBoldText} style={{ marginBottom: '10px' }}>
                                                My Certified Prescriber Information:
                                            </div>
                                            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                                I am certified as:{' '}
                                                <span className={classes.blackBoldText}>{`${
                                                    prescAllDetails?.prescriberNameFirst || ''
                                                } ${prescAllDetails?.prescriberNameLast || ''} ${
                                                    productEnrolmentData?.degree || ''
                                                }`}</span>
                                            </div>
                                            <div>
                                                Prescriber Identification Number:{' '}
                                                <span className={classes.blackBoldText}>
                                                    {prescAllDetails?.prescriberExtUniqueId1 || ''}
                                                </span>
                                            </div>
                                            <div>
                                                User Name:{' '}
                                                <span className={classes.blackBoldText}>
                                                    {currentUser?.userinfo['cognito:username'] || ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.leftBx} style={{ justifyContent: 'center' }}>
                                        <div className={classes.certifyBox}>
                                            I am currently certified in the following Bristol Myers Squibb-administered
                                            REMS Programs:
                                            <div style={{ marginTop: '5px' }}>
                                                {getEnrolledProgram()?.map((i) => (
                                                    <div key={i.name} className={classes.blackBoldText}>
                                                       
                                                        {ProgramConfigByName(i?.name)?.title}
                                                        {ProgramConfigByName(i?.name)?.registeredTradermark=== true ?<sup>®</sup>:<></>}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div style={{ width: '49%', marginRight: '2%', marginTop: '5%' }}>
                                        <div className={classes.clickLinks} onClick={handleReset}>
                                            Reset my password <img src={RightArrow} alt="right_arrow" />
                                        </div>
                                    </div>
                                    <div style={{ width: '49%',marginTop:'5%' }}>
                                        {getEnrolledProgram().length !== 3 ? (
                                            <div className={classes.clickLinks} onClick={handleAdd}>
                                                Certify for an additional REMS Program{' '}
                                                <img src={RightArrow} alt="right_arrow" />
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={classes.headerStyle}>My Associations</div>
                            <Tabs
                                defaultActiveKey={associationData?.state === 'presc' ? "2" : currentTab}
                                type="card"
                                size="small"
                                items={tabItems}
                                className={classes.tabsStyling}
                            />
                        </div>
                    </div>
                </PageBackground>
            ) : (
                <PageBackground label="Manage My Account - Non-prescriber">
                    <div className={classes.mainContainer} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <div className={classes.minwidth40p}>
                            <div className={classes.headerStyle} style={{ marginTop: '0' }}>
                                My Account
                            </div>
                            <div className={classes.whiteBoxContNoH}>
                                <div className={classes.Bx}>
                                    <div className={classes.blackBoldText} style={{ marginBottom: '10px' }}>
                                        My Non-prescriber Information:
                                    </div>
                                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                        I am logged in as:{' '}
                                        <span className={classes.blackBoldText}>{`${currentUser?.firstName || ''} ${
                                            currentUser?.lastName || ''
                                        }`}</span>
                                    </div>
                                    <div>
                                        User Name:{' '}
                                        <span className={classes.blackBoldText}>{currentUser?.userinfo['cognito:username'] || ''}</span>
                                    </div>
                                    <div
                                        className={classes.clickLinks}
                                        style={{ marginTop: '5%' }}
                                        onClick={handleReset}
                                    >
                                        Reset my password <img src={RightArrow} alt="right_arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '58%',
                                marginLeft: '10px',
                            }}
                        >
                            <div className={classes.headerStyle} style={{ marginTop: '0' }}>
                                My Prescriber Associations
                            </div>
                            <div className={classes.whiteBoxContNoH}>
                                <div
                                    className={classes.Bx}
                                    style={{
                                        marginRight: '2%',
                                    }}
                                >
                                    <div className={classes.blackBoldText} style={{ marginBottom: '10px' }}>
                                        Prescribers
                                    </div>
                                    <div>
                                        I am associated with {nonprescLoginAssociations.length} Prescriber Accounts:
                                    </div>

                                    <div className={`${classes.borderBx}`}>
                                        {nonprescLoginAssociations.length > 0 ? (
                                            <>
                                                {nonprescLoginAssociations.map((item, i) => (
                                                    <div style={{ display: 'flex' }} key={item.prescriberId}>
                                                        <span className={classes.mr5}>
                                                            {item.firstName} {item.lastName} &nbsp;
                                                        </span>
                                                        <span className={classes.mr5}> | &nbsp; </span>
                                                        <div
                                                            className={classes.clickLinks}
                                                            onClick={() =>
                                                                removeAsstnNonPresc(item, i, 'Non-prescriber')
                                                            }
                                                        >
                                                            Remove <img src={RightArrow} alt="right_arrow" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>You must associate with at least one prescriber</>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageBackground>
            )}
        </div>
    );
};

export default PrescriberManageAccount;
