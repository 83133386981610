export const savePrescriptionFormPayload = (
    selectedProgram,
    prescriptionFormData,
    prescriptionFormDetailsData,
    prescriberDetails,
    values,
    dosageDrpDwn,
    prescAllDetails,
    prescriptionAuthDetails
) => {


    const { payloadData } = prescriptionFormDetailsData || {};
    const { formType, langDetails, patientDto, prescLicenseDetails, stateCodes, updatedPatientDTO = {} } = prescriptionFormData || {};

    const patientDtoCon = (Object.entries(updatedPatientDTO)?.length > 0 ? updatedPatientDTO : patientDto)?.patientConditions?.filter(
        (item) => item?.patientConditionId?.productCountryId === selectedProgram.productCountryId
            && item.patientConditionEndDate === null
    );
    const dosage1 = dosageDrpDwn?.find((item) => item.dosageDetails === values?.dosageMg1);
    const dosage2 = values?.dosage2 && dosageDrpDwn?.find((item) => item.dosageDetails === values?.dosageMg2);

    const selectedLocation = prescAllDetails?.prescriberLocations?.find((obj) => obj?.id?.prescriberLocationSequence === Number(prescLicenseDetails?.addressId))
    const info = {
        channelId: "ext",
        prescriptionId: null,
        patientId: patientDto?.patientId || null,
        prescriberId: prescriberDetails?.prescriberSystemId || null,
        pharmacyId: null,
        prescription_form_date: null,
        productCountryId: {
            productCountryId: selectedProgram.productCountryId,
            riskManagementProgram: null,
            riskManagementIndicator: null,
            dispenseHoldCheck: null,
            productSiteUrl: null,
            product: {
                productId: selectedProgram.productCountryId,
                productCode: null,
                productName: null,
                productTradeName: null,
                productGenericName: null,
                comsOpProductId: null,
                deactivationDate: null,
                productGroup: null,
                productStatus: null
            }
        },
        patientOtherPhoneNo: null,
        patientOtherExtn: null,
        languagePreference: langDetails?.lang === "Other" ? (langDetails?.other) : langDetails?.lang,
        bestCallTime: langDetails?.callTime || null,
        bestCallTimeAmpm: langDetails?.callTime ? langDetails?.time : null,
        patientAllergies: null,
        otherMedications: null,
        shippingAddress1: prescriptionFormDetailsData?.shipAddress,
        shippingAddress2: null,
        shippingAddress3: null,
        shippingAddress4: null,
        shippingCity: prescriptionFormDetailsData?.shipCity,
        shippingStateId: {
            stateId: Number(stateCodes.find((item) => item.value === prescriptionFormDetailsData?.shipState)?.id),
            stateCode: prescriptionFormDetailsData?.shipState
        },
        shippingPostalCode: prescriptionFormDetailsData?.shipZip,
        prescriberLicenseNo: prescLicenseDetails?.stateLicNumPresc,
        prescriberOfficeContactName: prescriberDetails?.officeName,
        prescriberOfficePhoneNo: prescriberDetails?.phone,
        prescriberOfficeExtn: null,
        prescriberPhoneExtn: prescLicenseDetails?.ext,
        vaName: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaName : null,
        vaPharmacistName: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaPharmName : null,
        vaPharmacistPhoneNumber: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaPharmPhone : null,
        vaPharmacistFaxNumber: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaPhaFax : null,
        prescriptionTypeId: {
            lookupId: formType === "VHAForm" ? 219 : 218,
            lookupCategory: "prescription_type",
            lookupCode: formType === "VHAForm" ? "VA PRESCRIPTION FORM" : "STANDARD PRESCRIPTION FORM",
            lookupValue: formType === "VHAForm" ? "VA PRESCRIPTION FORM" : "STANDARD PRESCRIPTION FORM",
        },

        prescriberLocationId: selectedLocation?.id?.prescriberLocationSequence || null,

        conditionId: {
            conditionId: patientDtoCon?.[0]?.patientConditionId?.conditionId,
            conditionName: patientDtoCon?.[0]?.diagnosisCode === '000' ? patientDtoCon?.[0]?.otherDiagnosisCode:patientDtoCon?.[0]?.otherDiagnosisText,
            countryId: {
                countryId: 1,
                countryName: "United States",
                countryCode: "US"
            },
            languageId: {
                languageId: 1,
                iso_639_1_code: "en",
                languageName: "English",
                otherLanguage: null
            }
        },

        otherDiagnosisCode: langDetails?.diagCode?.split('-')[0] === '000' ? langDetails?.diagCode?.split('-')[1] : null,
        otherDiagnosisText: langDetails?.diagCode?.split('-')[0] === '000' ? langDetails?.otherPatientDiag : langDetails?.diagCode?.split('-')[1],
        shippingPhoneNumber: prescriptionFormDetailsData?.shipPhone,
        vhaShippingAddress1: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaAddress : null,
        vhaShippingAddress2: null,
        vhaShippingCity: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaCity : null,
        vhaShippingStateId: formType === "VHAForm"? {
            stateId:  Number(stateCodes.find((item) => item.value === prescriptionFormDetailsData?.vhaState)?.id) ,
            stateCode:  prescriptionFormDetailsData?.vhaState
        }: null,
        vhaShippingPostalCode: formType === "VHAForm" ? prescriptionFormDetailsData?.vhaZip : null,
        vhaMckAccountNumber: formType === "VHAForm" ? prescriptionFormDetailsData?.mckAccNo : null,
        vhaShippingType: formType === "VHAForm" ? prescriptionFormDetailsData?.shipVHAorDirect : null,
        prescriptionRxDate: prescriptionFormDetailsData?.dateRxNeeded || null,
        brandedPrescription: null,

        substitutionAllowed: values?.dispensigInfo === "substitution" ? 'Y' : 'N',
        authorizationNumber: prescriptionAuthDetails?.authNo || null,
        authorizationMessage: null,   
        confirmationNumber: prescriptionAuthDetails?.payload?.[0]?.confirmationNumber || null,
        commTypeId: payloadData?.commTypeId || null,
        prescriptionDosage:
            (values?.dosage2) ?
                (
                    !values?.dosage1 ?
                        ([{
                            //only dosage 2
                            prescriptionSequenceId: null,
                            dosageId: {
                                dosageId: dosage2?.dosageId,
                                dosageStrength: dosage2?.dosageStrength,
                                dosageDetails: dosage2?.dosageDetails,
                                countryId: {
                                    countryId: 1,
                                    countryName: "United States",
                                    countryCode: "US"
                                },
                                languageId: {
                                    languageId: 1,
                                    iso_639_1_code: "en",
                                    languageName: "English",
                                    otherLanguage: null
                                }
                            },
                            quantity: values?.qty2,
                            directions: values?.directions,
                            languageId: {
                                languageId: 1,
                                iso_639_1_code: "en",
                                languageName: "English",
                                otherLanguage: null
                            }
                        }])
                        :
                        ( //both dosage1 and dosage2
                            [{
                                prescriptionSequenceId: null,
                                dosageId: {
                                    dosageId: dosage1?.dosageId,
                                    dosageStrength: dosage1?.dosageStrength,
                                    dosageDetails: dosage1?.dosageDetails,
                                    countryId: {
                                        countryId: 1,
                                        countryName: "United States",
                                        countryCode: "US"
                                    },
                                    languageId: {
                                        languageId: 1,
                                        iso_639_1_code: "en",
                                        languageName: "English",
                                        otherLanguage: null
                                    }
                                },
                                quantity: values?.qty1,
                                directions: values?.directions,
                                languageId: {
                                    languageId: 1,
                                    iso_639_1_code: "en",
                                    languageName: "English",
                                    otherLanguage: null
                                }
                            },
                            {
                                prescriptionSequenceId: null,
                                dosageId: {
                                    dosageId: dosage2?.dosageId,
                                    dosageStrength: dosage2?.dosageStrength,
                                    dosageDetails: dosage2?.dosageDetails,
                                    countryId: {
                                        countryId: 1,
                                        countryName: "United States",
                                        countryCode: "US"
                                    },
                                    languageId: {
                                        languageId: 1,
                                        iso_639_1_code: "en",
                                        languageName: "English",
                                        otherLanguage: null
                                    }
                                },
                                quantity: values?.qty2,
                                directions: values?.directions,
                                languageId: {
                                    languageId: 1,
                                    iso_639_1_code: "en",
                                    languageName: "English",
                                    otherLanguage: null
                                }
                            }
                            ])
                )

                :
                // only dosage1
                ([{
                    prescriptionSequenceId: null,
                    dosageId: {
                        dosageId: dosage1?.dosageId,
                        dosageStrength: dosage1?.dosageStrength,
                        dosageDetails: dosage1?.dosageDetails,
                        countryId: {
                            countryId: 1,
                            countryName: "United States",
                            countryCode: "US"
                        },
                        languageId: {
                            languageId: 1,
                            iso_639_1_code: "en",
                            languageName: "English",
                            otherLanguage: null
                        }
                    },
                    quantity: values?.qty1,
                    directions: values?.directions,
                    languageId: {
                        languageId: 1,
                        iso_639_1_code: "en",
                        languageName: "English",
                        otherLanguage: null
                    }
                }])


    }

    return info;
}