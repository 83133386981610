import {ProgramConfig,lenalidomide,pomalidomide,thalomid} from 'constants/Config/programsConfig';
const registerIcon = '<span style="font-size: 0.8em;position: relative;top: -0.5em;">®</span>'
const pom = '(pomalidomide)'
export const productSignupContent = [
    {
        order: 1,
        title: ``,
        disclaimer1: `${ProgramConfig('rems_program_lenalidomide').title} Prescriber Enrollment Form`,
        disclaimer2:
            `All prescribers <span style="text-decoration: underline">must</span> be certified to prescribe ${lenalidomide}. To become certified the prescriber must:`,
        disclaimer3:
            `1. Complete the Prescriber Enrollment Form, which is required for ${ProgramConfig('rems_program_lenalidomide').title} certification.`,
        disclaimer4:
            '2. Agree to steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer5:
            `${ProgramConfig('rems_program_lenalidomide').label} is contraindicated in pregnant females and females capable of becoming pregnant. Females of`,
        disclaimer6:
            `reproductive potential may be treated with ${lenalidomide} provided adequate precautions are taken to avoid`,
        disclaimer7: 'pregnancy.',
        disclaimer8:
            'Please review the steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer9: `When prescribing ${lenalidomide}, I agree to:`,
        content: [
            `Provide patient counseling on the benefits and risks of ${lenalidomide} therapy, including Boxed WARNINGS
                <li>Submit a completed ${ProgramConfig('rems_program_lenalidomide').title} Patient-Physician Agreement Form for each new patient</li>
                <li>Provide contraception and emergency contraception counseling with each new prescription prior to and during ${lenalidomide} treatment</li>
                <li>Provide scheduled pregnancy testing for females of reproductive potential and verify negative pregnancy test<br/>results prior to writing a new prescription or subsequent prescriptions</li>
                <li>Report any pregnancies in female patients or female partners of male patients prescribed ${lenalidomide}<br/>immediately to ${ProgramConfig('rems_program_lenalidomide').title}</li>
                <li>Complete a mandatory and confidential <span style="text-decoration: underline">prescriber</span> survey online or by telephone for all patients and obtain a new authorization number for each prescription written. The authorization number and patient risk category must then<br/>be written on each prescription  </li>
                <li>Facilitate female patient compliance with an initial mandatory confidential <span style="text-decoration: underline">patient</span> survey online or by telephone</li>
                <li>Prescribe no more than a 4-week (28-day) supply, with no automatic refills or telephone prescriptions</li>
                <li>Contact a ${ProgramConfig('rems_program_lenalidomide').title} certified pharmacy to fill the prescription</li>
                <li>Remind patients to return all lenalidomide capsules to ${ProgramConfig('rems_program_lenalidomide').title} or their ${lenalidomide} prescriber, or to<br/>the pharmacy that dispensed the ${lenalidomide} to them</li>
                <li>Return to ${ProgramConfig('rems_program_lenalidomide').title} all ${lenalidomide} capsules that are returned by patients. Shipping fees will be paid<br/>by ${ProgramConfig('rems_program_lenalidomide').title}. To arrange returns, call ${ProgramConfig('rems_program_lenalidomide').title} at 1-888-423-5436</li>
                <li>Re-enroll patients in the ${ProgramConfig('rems_program_lenalidomide').title} program if ${lenalidomide} is required and previous therapy with<br/>${lenalidomide} has been discontinued for 12 consecutive months</li>
                `,
        ],
    },
    {
        order: 2,
        title: ``,
        disclaimer1: `${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} Prescriber Enrollment Form`,
        disclaimer2:
            `All prescribers <span style="text-decoration: underline">must</span> be certified to prescribe ${pomalidomide.toUpperCase()}${registerIcon} ${pom}. To become certified the prescriber must:`,
        disclaimer3:
            `1. Complete the Prescriber Enrollment Form, which is required for ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} certification.`,
        disclaimer4:
            '2. Agree to steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer5:
            `${ProgramConfig('rems_program_pomalyst').label.toUpperCase()} is contraindicated in pregnant females and females capable of becoming pregnant. Females of`,
        disclaimer6:
            `reproductive potential may be treated with ${pomalidomide.toUpperCase()} provided adequate precautions are taken to avoid`,
        disclaimer7: 'pregnancy.',
        disclaimer8:
            'Please review the steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer9: `${ProgramConfig('rems_program_pomalyst').label.toUpperCase()} is only available under a restricted distribution program, ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}.`,
        disclaimer10: `When prescribing ${pomalidomide.toUpperCase()}${registerIcon} ${pom}, I agree to:`,
        content: [
            `Provide patient counseling on the benefits and risks of ${pomalidomide.toUpperCase()} therapy, including Boxed Warnings
            <li>
            Submit a completed ${pomalidomide.toUpperCase()}${registerIcon} ${pom} Patient-Physician Agreement Form for each new patient
            </li>
            <li>
            Provide contraception and emergency contraception counseling with each new prescription prior to and during ${pomalidomide.toUpperCase()} treatment
            </li>
            <li>
            Provide scheduled pregnancy testing for females of reproductive potential and verify negative pregnancy test<br/>results prior to writing a new prescription or subsequent prescriptions
            </li>
            <li>
            Report any pregnancies in female patients or female partners of male patients prescribed ${pomalidomide.toUpperCase()}<br/>immediately to Bristol Myers Squibb Safety (or the REMS Call Center)
            </li>
            <li>
            Complete a mandatory and confidential <span style="text-decoration: underline">prescriber</span> survey online or by telephone for all patients and obtain a new authorization number for each prescription written. The authorization number and patient risk category must then<br/>be written on each prescription
            </li>
            <li>
            Facilitate female patient compliance with an initial mandatory confidential <span style="text-decoration: underline">patient</span> survey online or by telephone
            </li>
            <li>
            Prescribe no more than a 4-week (28-day) supply, with no automatic refills or telephone prescriptions
            </li>
            <li>
            Contact a ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} certified pharmacy to fill the prescription</li>
            <li>
            Remind patients to return all ${pomalidomide.toUpperCase()} capsules to ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()} or their ${pomalidomide.toUpperCase()} prescriber, or to<br/>the pharmacy that dispensed the ${pomalidomide.toUpperCase()} to them
            </li>
            <li>
            Return to ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()} all ${pomalidomide.toUpperCase()} capsules that are returned by patients. Shipping fees will be paid<br/>by ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}. To arrange returns, call the REMS Call Center
            </li>
            <li>
            Re-enroll patients in the ${ProgramConfig('rems_program_pomalyst').title.toUpperCase()}${registerIcon} program if ${pomalidomide.toUpperCase()} is required and previous therapy with<br/>${pomalidomide.toUpperCase()} has been discontinued for 12 consecutive months
            </li>
            `,
        ],
    },
    {
        order: 3,
        title: ``,
        disclaimer1: `${ProgramConfig('rems_program_thalomid').title}${registerIcon} Prescriber Enrollment Form`,
        disclaimer2:
            `All prescribers <span style="text-decoration: underline">must</span> be certified to prescribe ${thalomid?.toUpperCase()}${registerIcon} (thalidomide). To become certified the prescriber must:`,
        disclaimer3: `1. Complete the Prescriber Enrollment Form, which is required for ${ProgramConfig('rems_program_thalomid').title}${registerIcon} certification.`,
        disclaimer4:
            '2. Agree to steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer5:
            `${thalomid?.toUpperCase()} is contraindicated in pregnant females and females capable of becoming pregnant. Females of`,
        disclaimer6:
            `reproductive potential may be treated with ${thalomid?.toUpperCase()} provided adequate precautions are taken to avoid`,
        disclaimer7: 'pregnancy.',
        disclaimer8:
            'Please review the steps below that <span style="text-decoration: underline">must</span> be followed with every patient.',
        disclaimer9: `${thalomid?.toUpperCase()}  is only available under a restricted distribution program, ${ProgramConfig('rems_program_thalomid').title}.`,
        disclaimer10: `When prescribing ${thalomid?.toUpperCase()}${registerIcon} (thalidomide), I agree to:`,
        content: [
            `
            Provide patient counseling on the benefits and risks of ${thalomid?.toUpperCase()} therapy, including Boxed Warnings
            <li>Submit a completed ${thalomid?.toUpperCase()}${registerIcon} (thalidomide) Patient-Physician Agreement Form for each new patient</li>
            <li>Provide contraception and emergency contraception counseling with each new prescription prior to and during ${thalomid?.toUpperCase()} treatment</li>
            <li>Provide scheduled pregnancy testing for females of reproductive potential and verify negative pregnancy test<br/>results prior to writing a new prescription or subsequent prescriptions</li>
            <li>Report any pregnancies in female patients or female partners of male patients prescribed ${thalomid?.toUpperCase()}<br/>immediately to Bristol Myers Squibb Safety (or the REMS Call Center)</li>
            <li>Complete a mandatory and confidential <span style="text-decoration: underline">prescriber</span> survey online or by telephone for all patients and obtain a new authorization number for each prescription written. The authorization number and patient risk category must then<br/>be written on each prescription</li>
            <li>Facilitate female patient compliance with an initial mandatory confidential <span style="text-decoration: underline">patient</span> survey online or by telephone</li>
            <li>Prescribe no more than a 4-week (28-day) supply, with no automatic refills or telephone prescriptions</li>
            <li>Contact a ${ProgramConfig('rems_program_thalomid').title}${registerIcon} certified pharmacy to fill the prescription</li>
            <li>Remind patients to return all THALOMID capsules to ${ProgramConfig('rems_program_thalomid').title} or their ${thalomid?.toUpperCase()} prescriber, or to<br/>the pharmacy that dispensed the ${thalomid?.toUpperCase()} to them</li>
            <li>Return to ${ProgramConfig('rems_program_thalomid').title} all ${thalomid?.toUpperCase()} capsules that are returned by patients. Shipping fees will be paid<br/>by ${ProgramConfig('rems_program_thalomid').title}. To arrange returns, call the REMS Call Center</li>
            <li>Re-enroll patients in the ${ProgramConfig('rems_program_thalomid').title}${registerIcon} program if ${thalomid?.toUpperCase()} is required and previous therapy with<br/>${thalomid?.toUpperCase()} has been discontinued for 12 consecutive months</li>
            `,
        ],
    },
];
