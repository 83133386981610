import Alert from 'components/Alert';
import ErrorModal from 'components/ErrorModal';
import Input from 'components/Input';
import PageBackground from 'components/PageBackground';
import SessionTimeout from 'components/SessionTimeout';
import { Role } from 'constants/Config/appConfig';
import { securityVerificationFields } from 'constants/createAccountFormFields';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { validateAndCreateOneTimePwd } from 'store/slices/appSlice';
import { fetchDatafromS3json } from 'utils/metaDataFetch';
import {GenerateHashValue} from 'utils/authService';
import { isValidEmail } from 'utils/regex';
import classes from './ForgotPassword.module.scss';
import { reloadFunction } from 'utils/utilsCommon';

interface dropDownType {
    SecurityQuestion?: { id: string; value: string }[] | null;
}

const SecurityVerification = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const resetUserName = sessionStorage.getItem('resetData') || ''
    const [error, setError] = useState('');
    const [apiError, setApiError] = useState('');
    const [currentFocus, setCurrentFocus] = useState('');
    const [dropdown, setDropdown] = useState<dropDownType>({ SecurityQuestion: [] });
    const [values, setValues] = useState({
        email: '',
        securityQuestion: '',
        securityAnswer: '',
    });
    const { role } = useAppSelector((state) => state.app);
    const isPharmacy = role === Role.Pharmacist;
    

    const handleOnChange = (e) => {
        error && setError('');
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const trimValues = () => {
        const newVals = { ...values };
        Object.keys(newVals).forEach((field) => {
            if (!['securityQuestion'].includes(field)) {
                newVals[field] = newVals[field].trim();
            }
        });
        setValues(newVals);
        return newVals;
    };

    const errorMessages = (erroCode)=>{
        switch (erroCode){
            case 90002:
                return 'The security question selected is incorrect.';
            case 90003:
                return 'The response to your personal verification question is incorrect. Please try again.';
            case 90004:
                return 'The email address you entered does not match the email address on file. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.';
            case 90005:
                return 'Security question and answer incorrect.';
            case 90006:
                return 'Security question, answer and email incorrect.';
            case 90007:
                return 'Security question and email incorrect.';
            case 90008:
                return 'Security answer and email incorrect.';
            default:
                return 'Error';
        }
    }

    const handleContinue = () => {
        trimValues();
        for (let i of securityVerificationFields) {
            if (values[i.name] === '' || values[i.name].includes('Select a Question')) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                return;
            } else if (i.name === 'email' && !isValidEmail(values.email.trim())) {
                setError(i.errMsg);
                setCurrentFocus(i.name);
                return;
            } else if (
                i.name === 'securityAnswer' &&
                (values.securityAnswer.includes('<') || values.securityAnswer.includes('>'))
            ) {
                setError('Please enter a valid Security Answer');
                setCurrentFocus(i.name);
                return;
            }
        }
        dispatch(
            validateAndCreateOneTimePwd({
                username: resetUserName,
                email: values.email,
                security_question: values.securityQuestion,
                security_answer: GenerateHashValue(values.securityAnswer),
            })
        ).then((e) => {
            if (e.payload?.data?.statusCode === 200 && e.payload?.data?.message === 'Successful') {
                sessionStorage.setItem('resetData',values.email)
                if (isPharmacy) {
                    navigate('/pharmacy/pharmacyConfirmation', {
                        replace: true,
                        state: { userName: resetUserName, email: values.email },
                    });
                } else {
                    navigate('/prescriber/forgotPasswordConfirmation', {
                        replace: true,
                        state: { userName: resetUserName, email: values.email },
                    });
                }
            } else {        
                
                if (e.payload?.data?.message?.includes('lock')) {
                    setApiError(`We're sorry your account has been locked due to unsuccessful verification attempts. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.`)
                }
                else if (e.payload?.data?.message?.includes('testing')) {
                    setApiError(
                        `We're sorry your account has been locked due to unsuccessful verification attempts. Please call the REMS Call Center at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.`
                    );
                }
                else{
                    setApiError(errorMessages(e.payload?.data?.statusCode));
                }
                setError('');
                setCurrentFocus('');
                setValues({
                    email: '',
                    securityQuestion: 'Select a Question',
                    securityAnswer: '',
                });
            }
        }).catch((error)=>{
            console.error('error handleContinue:',error)
            if(isPharmacy){
                navigate('/pharmacy/SystemError');
                return
            }
            else{
                navigate('/prescriber/SystemError');
                return
            }
        });
    };

    const handleCancel = () => {
        if (isPharmacy) {
            navigate('/pharmacy/login');
        } else {
            navigate('/prescriber/login');
        }
    };

    const handleErrorBtn = () => {
        const inputElement = document?.getElementById(currentFocus) as HTMLInputElement;
        inputElement.focus();
        setError('');
        setCurrentFocus('');
    };

    const fetchDropdown = async () => {
        fetchDatafromS3json().then((res) => {
            setDropdown({ SecurityQuestion: res?.securityQuestion });
        });
    };

    useEffect(() => {
        fetchDropdown();
    }, []);

    useEffect(() => {
        reloadFunction();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
        <div className={classes.fgtPswdContainer}>
            <SessionTimeout />
            <PageBackground
                label="Security Verification"
                btn={true}
                handleClick={handleContinue}
                handleCancelBtn={handleCancel}
            >
                <>
                    <div className={classes.note}>
                        Fields marked with an <span className={classes.required}>*</span> are required.
                    </div>
                    <div className={classes.contentContainer}>
                        {apiError && <Alert content={apiError} styles={{ margin: '10px 20px 5px 20px' }} />}
                        <div className={classes.formHeader}>
                            Please enter your details and click the Continue button. A temporary password will be sent
                            to the Email address you provide. If you forgot the answer, please call the REMS Call Center
                            at 1-888-423-5436 for assistance Monday through Friday 8:00 AM to 8:00 PM ET.
                        </div>
                        <div style={{ marginTop: '10px' }} className={classes.container}>
                            <div className={classes.label}>
                                <span>User Name:</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                                {resetUserName}
                            </div>
                        </div>

                        {securityVerificationFields.map((i) => {
                            if (i.name === 'securityQuestion') {
                                return (
                                    <Input
                                        key={i.name}
                                        type="select"
                                        isRequired={true}
                                        name="securityQuestion"
                                        value={values.securityQuestion}
                                        options={
                                            dropdown?.SecurityQuestion
                                                ? [
                                                    { value: 'Select a Question...' } as { value: string },
                                                    ...dropdown?.SecurityQuestion?.map((item) => ({
                                                        value: item.value,
                                                    })),
                                                ]
                                                : []
                                        }
                                        label="Security Question"
                                        labelStyle={{ fontWeight: 'bold' }}
                                        width={'268px'}
                                        onChangeHandler={handleOnChange}
                                    />
                                );
                            } else {
                                return (
                                    <Input
                                        key={i.name}
                                        type={i.type}
                                        name={i.name}
                                        label={i.label}
                                        value={values[i.name]}
                                        maxLength={i.maxLength}
                                        isRequired={i.isRequired}
                                        width={i.name === 'email' ? '260px' : '268px'}
                                        onChangeHandler={handleOnChange}
                                        labelStyle={{ fontWeight: 'bold' }}
                                    />
                                );
                            }
                        })}
                    </div>
                </>
            </PageBackground>
            {error && <ErrorModal error={error} handleContinue={handleErrorBtn} />}
        </div>
    );
};

export default SecurityVerification;
