import React from 'react';
import SystemError from 'components/SystemError';

const Error = () => {
    return (
        <div>
            <SystemError/>
        </div>
    );
};

export default Error;
