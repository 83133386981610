import {PROGRAMS ,ProgramConfig, ProgramConfigByName} from 'constants/Config/programsConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import {
    changePPAF,
    changeProgram,
    patientInformation,
    patientReactivationStore,
    selectedPatientProgram,
    storeActionType,
} from 'store/slices/appSlice';
import { getPatientDetailsEditPatient } from 'utils/getPatientDetailsEditPatient';
import classes from './PatientDetails.module.scss';
import PopupHideUnhide from './PopupHideUnhide';
import { useAppSelector } from '../../store';
import { Role, RoleByGroup } from 'constants/Config/appConfig';	

const PatientEnrollments = ({ patientDto, refdata, getPatientName, APIDetails }) => {	
	const { currentUser } = useAppSelector((state) => state.login);
    const programList : any = PROGRAMS;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { programs, 'ICD-10': ICDCode } = refdata;
    const [pgmVisibility, setPgmVisibility] = useState<any[]>([]);
    const [hideUnhideModal, setShowHideUnhideModal] = useState(false);
    const { actionTypes } = useSelector((state: RootState) => state.app);
    useEffect(() => {
        if (APIDetails.length > 0) {
            const actionPgm = patientDto?.patientRegistrationForms?.map((item) => ({
                id: item.productCountry.productCountryId,
                isVisible: APIDetails?.find((obj) => obj?.productCountryId === item?.productCountry.productCountryId)?.actionFlag === 'Y' ? false : true,
            }));
            setPgmVisibility(actionPgm);
        }
        else {
            const pgms = patientDto?.patientRegistrationForms?.map((item) => ({
                id: item.productCountry.productCountryId,
                isVisible: true,
            }));
            setPgmVisibility(pgms);
        }
    }, [patientDto, APIDetails]);

    const getDiagnosis = (id) => {
        const diagArr = patientDto?.patientConditions.filter(
            (obj) => obj.patientConditionId.productCountryId === id && !obj.patientConditionEndDate
        ) || [];
        const diagnosisObj = diagArr?.[0] || {};

        if(diagArr?.length === 1){
        if (diagnosisObj && diagnosisObj.diagnosisCode && diagnosisObj.otherDiagnosisText) {
            return diagnosisObj.diagnosisCode + ' - ' + (diagnosisObj.diagnosisCode === '000' ? diagnosisObj.otherDiagnosisCode : diagnosisObj.otherDiagnosisText);
        } else {
            return 'Call REMS Call Center';
        }
        } else return 'Call REMS Call Center';
    };
    const getPgmName = (pgmName) => {
        if (pgmName) {
            const pgm = pgmName?.split(' REMS');
            return (<>{ProgramConfigByName(pgm[0])?.title}{ProgramConfigByName(pgm[0])?.registeredTradermark=== true ?<span className={classes.registered}>®</span>:<></>}</>)
        }
    };

    const enrolledPgms = patientDto?.patientRegistrationForms?.map((item) => ({
        id: item.productCountry.productCountryId,
        status: item.patientRegStatus[0].registrationStatus.lookupValue,
        name: getPgmName(item.productCountry.riskManagementProgram),
        diagnosis: getDiagnosis(item.productCountry.productCountryId),
    }));

    const pendingPrograms = programs
        ?.filter((pgm) => !enrolledPgms?.find((obj) => obj.id === pgm.productCountryId) && pgm.status === 'Active')
        .sort((a, b) => a.productName.localeCompare(b.productName));

    const enrollNewPgm = async (productCountryId) => {
        const selectedPgm = programList.find((i) => i.productCountryId === +productCountryId);
        dispatch(changeProgram(selectedPgm));
        const infoData = getPatientDetailsEditPatient(patientDto, ICDCode, 'Reenroll', 'en');
        dispatch(patientInformation({ ...infoData, flowType: 'Reenroll' }));
        dispatch(selectedPatientProgram({ ...selectedPgm, name: selectedPgm?.label + ' REMS' }));
        dispatch(patientReactivationStore({ data: patientDto }));
        dispatch(changePPAF(true));
        if (currentUser.groupTag === RoleByGroup.NonPrescriber) {
            navigate('/prescriber/physicianSearch');
        } else {
            navigate('/prescriber/PhysicianOfficeDetails');
        }
    };

    const isPgmVisible = (id) => pgmVisibility.find((obj) => obj.id === id)?.isVisible;

    const toggleBtn = (id, buttonName) => {
        setShowHideUnhideModal(true);
        dispatch(storeActionType({ ...actionTypes, btnType: buttonName, progId:id }));
    };

    return (<>
        {hideUnhideModal && (
            <PopupHideUnhide
                pName={getPatientName}
                setShowHideUnhideModal={setShowHideUnhideModal}
                pgmVisibility={pgmVisibility}
                setPgmVisibility={setPgmVisibility}
                getAPIDetails={APIDetails} />
        )}
        <div className={classes.patient_enroll_info}>
            <p className={classes.sub_title}>Patient Enrollments</p>
            <table className={classes.common_table}>
                <thead>
                    <th>Hide/Unhide</th>
                    <th>REMS Program</th>
                    <th>Enrollment Status</th>
                    <th>Diagnosis</th>
                </thead>
                <tbody>
                    {enrolledPgms?.map((pgm) => (
                        <tr key={pgm.id}>
                            <td className={classes.toggle_btn}>
                                <button
                                    tabIndex={0}
                                    onClick={() => toggleBtn(pgm.id, 'Hide')}
                                    disabled={!isPgmVisible(pgm.id)}
                                    className={`${classes.btn} ${classes['hide_' + (isPgmVisible(pgm.id) ? 'enable' : 'disable')]
                                        }`}
                                ></button>
                                <button
                                    tabIndex={0}
                                    onClick={() => toggleBtn(pgm.id, 'Unhide')}
                                    disabled={isPgmVisible(pgm.id)}
                                    className={`${classes.btn} ${classes['unhide_' + (isPgmVisible(pgm.id) ? 'disable' : 'enable')]
                                        }`}
                                ></button>
                            </td>
                            <td>{pgm.name}</td>
                            <td>{pgm.status}</td>
                            <td>{pgm.diagnosis}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <p className={classes.sub_title}>Hide/Unhide this patient, by program, from the Prescriber Dashboard</p>
            <div className={classes.pending_enrol_info}>
                <p>Enroll patient for:</p>
                {pendingPrograms?.map((pgm) => (
                    <a
                        tabIndex={0}
                        key={pgm.productCountryId}
                        className={classes.program_names}
                        onClick={() => enrollNewPgm(pgm.productCountryId)}
                    >
                        {getPgmName(pgm.productName)}
                        <span className={classes.btn_arrow} />
                    </a>
                ))}
            </div>
        </div>
    </>
    );
};

export default PatientEnrollments;
