import moment from 'moment';
export const printCalenderAction = (calendarDynamicData) => {
	const divideIntoWeeks = (calendarDynamicData) => {
		const weeks = [];
		if (calendarDynamicData.length !== 35) {
			throw new Error('Input array must contain exactly 35 objects');
		}
		for (let i = 0; i < 5; i++) {
			const week = calendarDynamicData.slice(i * 7, i * 7 + 7);
			weeks.push(week);
		}
		return weeks;
	}
const selectedVal=(obj)=>{
    if(obj.selected)
    {
        return `<span style="color:#cc0000">${obj.count && obj.count}</span>`
    }
    else{
        return `<span >${obj.count && obj.count}</span>`
    }
    
}
	const renderDynamicCalender = () => {
		let rows;
		if(calendarDynamicData && calendarDynamicData.length){
			rows = divideIntoWeeks(calendarDynamicData).map((each)=>{
				return `
							<tr class="calendarCell even">
						${each.map((obj)=>{                       
							return `
								<td
						>
							<div
								style="
									border: 1px solid
										black;
								"
								align="center"
							>
								<div
									style="
										position: relative;
										width: 80px;
										height: 22px;
									"
								>
									<div
										style="
											position: absolute;
											top: 2px;
											left: 2px;
											width: 25px;
											height: 18px;
											border: 1px
												solid
												black;
										"
										class="dateDiv"
										align="center"
									>
										${obj.number && obj.number}
									</div>
								</div>
								<div
									style="
										width: 80px;
										height: 22px;
									"
								>
                                ${selectedVal(obj)}
                               
                                </div>
								<div
									style="
										width: 80px;
										height: 20px;
									"
								></div>
							</div>
						</td>
							`	
						}).join('')
						}
					</tr>
			
						`
			}).join('');
		}
		return rows;
	}

return (`
            <html>
            <head>
                <title>${window.location.href}</title>
                <style>
                    /* Add your CSS styles here if needed */
                </style>
            </head>
            <body>

	              <table width="815px" cellpadding="0" cellspacing="0" border="0" align="center">
            <colgroup>
                <col width="9px" />
                <col width="730px" />
                <col width="9px" />
            </colgroup>
            <tbody>
                <tr class="mainContentContainerTopBorder">
                    <td><span class="leftCorner"></span></td>
                    <td><span class="middleEdge"></span></td>
                    <td><span class="rightCorner"></span></td>
                </tr>
                <tr>
                    <td colspan="3">
                        <table width="750px" cellpadding="0" cellspacing="0" border="0" class="mainContentContainer">
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <div class="header">
                                            <span class="icon logo left"></span>
                                            <div align="center" style="font-size: 2em">Prescriber Dashboard</div>
                                            <div align="center">Prescriber Calendar</div>
                                            <div class="buffer clear"></div>
                                        </div>
                                        <div class="clear"></div>
                                        <br />
                                        <div>
                                            <table
                                                width="710px"
                                                cellpadding="0"
                                                cellspacing="0"
                                                border="0"
                                                align="center"
                                            >
                                                <colgroup>
                                                    <col width="5px" />
                                                    <col width="700px" />
                                                    <col width="5px" />
                                                </colgroup>

                                                <tbody>
                                                    <tr class="contentContainerTopBorder">
                                                        <td><span class="leftCorner"></span></td>
                                                        <td><span class="middleEdge"></span></td>
                                                        <td><span class="rightCorner"></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" class="contentContainer">
                                                            <table
                                                                width="610px"
                                                                cellpadding="0"
                                                                cellspacing="0"
                                                                align="left"
                                                            >
                                                                <colgroup>
                                                                    <col width="20px" />
                                                                    <col width="0px" />
                                                                    <col width="585px" />
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <table
                                                                                cellpadding="2"
                                                                                cellspacing="1"
                                                                                align="center"
                                                                            >
                                                                                <tbody>
                                                                                    <tr height="38px">
                                                                                        <td width="30px">
                                                                                            <div
                                                                                                style="
                                                                                                    width: 66px;
                                                                                                    height: 25px;
                                                                                                "
                                                                                            ></div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr height="40px">
                                                                                        <td>
                                                                                            <div
                                                                                                style="
                                                                                                    position: relative;
                                                                                                    transform: rotate(
                                                                                                        -90deg
                                                                                                    );
                                                                                                    height: 63px !important;
                                                                                                    widht: 66px !important;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                class="vertical-text"
                                                                                            >
                                                                                                <div
                                                                                                    style="
                                                                                                        position: absolute;
                                                                                                        bottom: 0px;
                                                                                                        width: 66px;
                                                                                                        height: 35px;
                                                                                                        border: 1px
                                                                                                            solid black;
                                                                                                        text-align: center;
                                                                                                    "
                                                                                                    class="vHeaderCellDiv"
                                                                                                >
                                                                                                    Week 1
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr height="40px">
                                                                                        <td>
                                                                                            <div
                                                                                                style="
                                                                                                    position: relative;
                                                                                                    transform: rotate(
                                                                                                        -90deg
                                                                                                    );
                                                                                                    height: 63px !important;
                                                                                                    widht: 66px !important;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                class="vertical-text"
                                                                                            >
                                                                                                <div
                                                                                                    style="
                                                                                                        position: absolute;
                                                                                                        bottom: 0px;
                                                                                                        width: 66px;
                                                                                                        height: 35px;
                                                                                                        border: 1px
                                                                                                            solid black;
                                                                                                        text-align: center;
                                                                                                    "
                                                                                                    class="vHeaderCellDiv"
                                                                                                >
                                                                                                    Week 2
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr height="40px">
                                                                                        <td>
                                                                                            <div
                                                                                                style="
                                                                                                    position: relative;
                                                                                                    transform: rotate(
                                                                                                        -90deg
                                                                                                    );
                                                                                                    height: 63px !important;
                                                                                                    widht: 66px !important;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                class="vertical-text"
                                                                                            >
                                                                                                <div
                                                                                                    style="
                                                                                                        position: absolute;
                                                                                                        bottom: 0px;
                                                                                                        width: 66px;
                                                                                                        height: 35px;
                                                                                                        border: 1px
                                                                                                            solid black;
                                                                                                        text-align: center;
                                                                                                    "
                                                                                                    class="vHeaderCellDiv"
                                                                                                >
                                                                                                    Week 3
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr height="40px">
                                                                                        <td>
                                                                                            <div
                                                                                                style="
                                                                                                    position: relative;
                                                                                                    transform: rotate(
                                                                                                        -90deg
                                                                                                    );
                                                                                                    height: 63px !important;
                                                                                                    widht: 66px !important;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                class="vertical-text"
                                                                                            >
                                                                                                <div
                                                                                                    style="
                                                                                                        position: absolute;
                                                                                                        bottom: 0px;
                                                                                                        width: 66px;
                                                                                                        height: 35px;
                                                                                                        border: 1px
                                                                                                            solid black;
                                                                                                        text-align: center;
                                                                                                    "
                                                                                                    class="vHeaderCellDiv"
                                                                                                >
                                                                                                    Week 4
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr height="40px">
                                                                                        <td>
                                                                                            <div
                                                                                                style="
                                                                                                    position: relative;
                                                                                                    transform: rotate(
                                                                                                        -90deg
                                                                                                    );
                                                                                                    height: 63px !important;
                                                                                                    widht: 66px !important;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                class="vertical-text"
                                                                                            >
                                                                                                <div
                                                                                                    style="
                                                                                                        position: absolute;
                                                                                                        bottom: 0px;
                                                                                                        width: 66px;
                                                                                                        height: 35px;
                                                                                                        border: 1px
                                                                                                            solid black;
                                                                                                        text-align: center;
                                                                                                    "
                                                                                                    class="vHeaderCellDiv"
                                                                                                >
                                                                                                    Week 5
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                        <td></td>
                                                                        <td>
                                                                            <table
                                                                                width="585px"
                                                                                cellpadding="1"
                                                                                cellspacing="1"
                                                                                align="center"
                                                                                height="40px"
                                                                                class="calendarTable"
                                                                            >
                                                                                <colgroup>
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                    <col width="80px" />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr
                                                                                        class="calHorizontalHeader"
                                                                                        ;=""
                                                                                        style="text-align: center"
                                                                                    >
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Monday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Tuesday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Wednesday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Thursday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Friday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Saturday
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="calHeaderCell">
                                                                                            <div
                                                                                                style="
                                                                                                    border: 1px solid
                                                                                                        black;
                                                                                                    height: 30px;
                                                                                                    font-weight: bold;
                                                                                                "
                                                                                                align="center"
                                                                                            >
                                                                                                Sunday
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>

                                                                                    ${renderDynamicCalender()}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr class="contentContainerBottomBorder">
                                                        <td><span class="leftCorner"></span></td>
                                                        <td><span class="middleEdge"></span></td>
                                                        <td><span class="rightCorner"></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="buffer">&nbsp;</div>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <div align="center">Bristol Myers Squibb</div>
        </body>
    </html>
	`)
};
