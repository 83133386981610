export const createAccFormFields = {
    personalInfo: [
        {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid First Name.',
            maxLength: 30,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Last Name.',
            maxLength: 30,
        },
        {
            name: 'officeAddress',
            label: 'Office Address',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter a valid Office Address.',
            maxLength: 50,
        },
        {
            name: 'city',
            label: 'City',
            type: 'text',
            isRequired: true,
            maxLength: 30,
            errMsg: 'Please enter a valid City.',
        },
        {
            name: 'state',
            label: 'State',
            type: 'select',
            isRequired: true,
            errMsg: 'Please select a State.',
        },
        {
            name: 'zipCode',
            label: 'Zip',
            type: 'text',
            isRequired: true,
            helpText: 'e.g., xxxxx',
            maxLength: 5,
            errMsg: 'Please enter a valid Zip Code.',
        },
        {
            name: 'phoneNumber',
            label: 'Phone Number',
            type: 'text',
            isRequired: false,
            helpText: 'e.g., xxx-xxx-xxxx OR xxx xxx xxxx OR xxxxxxxxxx',
            maxLength: 12,
            errMsg: 'Please enter a Phone Number.',
        },
    ],
    primaryOfc: [
        {
            name: 'userName',
            maxLength: 20,
            label: 'User Name',
            type: 'text',
            isRequired: true,
            errMsg: 'Please enter your User Name.',
            helpText:
                'The Username and Password you choose will be required to digitally sign required REMS documents in the online system. Your user name must be between four (4) and twenty (20) characters in length.',
        },
        {
            name: 'password',
            maxLength: 20,
            label: 'Password',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter your Password.',
            helpText:
                'Please choose a password that is eight (8) or more characters in length. Passwords must include at least one (1) upper case letter (e.g. A-Z), (1) lower case letter (e.g. a-z) and (1) number. Please note: passwords cannot contain your first name, last name, or user name.',
        },
        {
            name: 'verifyPassword',
            maxLength: 20,
            label: 'Verify Password',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter the Password confirmation.',
        },
        {
            name: 'email',
            maxLength: 60,
            label: 'Email Address',
            type: 'text',
            isRequired: true,
            helpText: 'yourname@abccompany.com',
            errMsg: 'Please enter a valid Email Address.',
        },
        {
            name: 'securityQuestion',
            label: 'Security Question',
            type: 'select',
            isRequired: true,
            errMsg: 'Please select a Security Question.',
        },
        {
            name: 'securityAnswer',
            maxLength: 50,
            label: 'Your Answer',
            type: 'password',
            isRequired: true,
            errMsg: 'Please enter your Security Answer.',
        },
    ],
};

export const securityVerificationFields = [
    {
        name: 'securityQuestion',
        label: 'Security Question',
        type: 'select',
        isRequired: true,
        errMsg: 'Please select a Security Question.',
    },
    {
        name: 'securityAnswer',
        maxLength: 50,
        label: 'Your Answer',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter your Security Answer.',
    },
    {
        name: 'email',
        maxLength: 60,
        label: 'Email',
        type: 'text',
        isRequired: true,
        helpText: 'yourname@abccompany.com',
        errMsg: 'Please enter a valid Email Address.',
    },
];

export const resetSecurityFields = [
    {
        name: 'securityQuestion',
        label: 'Security Question',
        type: 'select',
        isRequired: true,
        errMsg: 'Please select a Security Question.',
    },
    {
        name: 'securityAnswer',
        maxLength: 50,
        label: 'Your Answer',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter your Security Answer.',
    }
];

export const resetTempPswdFields = [
    {
        name: 'tempPswd',
        maxLength: 20,
        label: 'Temporary Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter your Old/Temporary Password',
        
    },
    {
        name: 'newPswd',
        maxLength: 20,
        label: 'New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a New Password.',
        helpText:
            'Please choose a password that is eight (8) or more characters in length. Passwords must include at least one (1) upper case letter (e.g. A-Z), (1) lower case letter (e.g. a-z) and (1) number. Please note: passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.',
    },
    {
        name: 'verifyNewPswd',
        maxLength: 20,
        label: 'Verify New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a Verify New Password.',
    },
];
export const resetPswdManageFields = [
    {
        name: 'oldPswd',
        maxLength: 20,
        label: 'Old Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter your Old Password.',
        
    },
    {
        name: 'newPswd',
        maxLength: 20,
        label: 'New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a New Password.',
        helpText:
            'Please choose a password that is eight (8) or more characters in length. Passwords must include at least one (1) upper case letter (e.g. A-Z), (1) lower case letter (e.g. a-z) and (1) number. Please note: passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.',
    },
    {
        name: 'verifyNewPswd',
        maxLength: 20,
        label: 'Verify New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a Verify New Password.',
    },
];
export const resetPswdManageFieldsPhar = [
    {
        name: 'currentPswd',
        maxLength: 20,
        label: 'Current Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter your Current Password.',
        
    },
    {
        name: 'newPswd',
        maxLength: 20,
        label: 'New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a New Password.',
        helpText:
            'Please choose a password that is eight (8) or more characters in length. Passwords must include at least one (1) upper case letter (e.g. A-Z), (1) lower case letter (e.g. a-z) and (1) number. Please note: passwords cannot contain your first name, last name, or user name. The new password must not be any of the last five (5) passwords used.',
    },
    {
        name: 'verifyNewPswd',
        maxLength: 20,
        label: 'Verify New Password',
        type: 'password',
        isRequired: true,
        errMsg: 'Please enter a Verify New Password.',
    },
];

export const requiredLabels = [
    ...createAccFormFields.personalInfo.filter((i) => i.isRequired),
    ...createAccFormFields.primaryOfc.filter((i) => i.isRequired),
].map((i) => ({ name: i.name, label: i.label, errMsg: i.errMsg }));

export const staticFieldValue = [...createAccFormFields.personalInfo, ...createAccFormFields.primaryOfc].filter(
    (i) => i.name !== 'password' && i.name !== 'verifyPassword'
);
