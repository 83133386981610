import { useAppDispatch, useAppSelector } from 'store';
import { useState } from 'react';
import classes from './ReportsTable.module.scss';
import { hiddenReportAPI, storeActionType, updateSelectedPatient } from '../../../store/slices/appSlice';
import rightArrow from '../../../assets/images/btn_arrow_right.png';
import { useNavigate } from 'react-router-dom';
import * as ExcelJS from 'exceljs';
import ExportToExcel from '../../../assets/images/excelExport.png';
import moment from 'moment-timezone';
import Button from 'components/Button';
import PopupHideUnhide from 'pages/PatientDetails/PopupHideUnhide';




const HiddenPatientTable = ({ setIsSorted, setFetchReport, fetchReportData, noData, reportInpData, isApiCalled, apiPayload }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const content = fetchReportData;
    const tableheader = ['Unhide', 'Patient Name', 'REMS Program', 'Prescriber Name'];
    const { currentUser } = useAppSelector((state) => state.login);
    const isNonPrescriber = currentUser?.groupTag && currentUser.groupTag.toLowerCase().includes('nonprescriber');
    const [hideUnhideModal, setShowHideUnhideModal] = useState(false);
    const [patientName, setPatientName] = useState('');
    const [prescPatientFilterId, setPrescPatientFilterId] = useState({});
    const [pgmVisibility, setPgmVisibility] = useState<any[]>([]);


    const selectedPatientHandler = (row) => {
        handleUpdateSelectedPatient(row)
        navigate('/prescriber/patientdetails');
    };

    const handleUpdateSelectedPatient = (row) => {
        const patientDetails = {
            patientId: row && row?.patientId,
            patientExtUniqueId: row && row?.patientIdExternalUniqueId,
            assocPrescriberId: row && row?.assocPrescriberId
        }
        dispatch(updateSelectedPatient(patientDetails));
    }

    const sortByAscDesc = (title, order) => {

        const sortfieldMap = {
            'Patient Name': 'PATIENTLASTNAME',
            'REMS Program': 'PRODUCTNAME',
            'Prescriber Name': 'ASSOCPRESCRIBERLASTNAME',
        };
        let copyPayload = { ...apiPayload };
        copyPayload.sortByField = sortfieldMap[title];
        copyPayload.sortByOrder = order;

        setIsSorted({ isSorted: true, content: sortfieldMap[title], orderby: order });

        dispatch(hiddenReportAPI({ data: copyPayload })).then((e: any) => {
            if (e.payload && e.payload?.data && e.payload?.data?.statusCode === 200) {
                setFetchReport(e.payload?.data?.data);
            }
        });
    };

    const getPatientName = () => {
        return patientName.toUpperCase();
    };

    const handleClickUnhide = async (each) => {
        const actionPgm = [{
            id: each?.productCountryId,
            isVisible: each?.patientUnhideIndicator === 'Y' ? false : true,
        }];
        handleUpdateSelectedPatient(each);
        dispatch(storeActionType({
            btnType: 'Unhide',
            fromComp: 'HiddenReport',
            progId: each?.productCountryId,
            hiddenRepPayload: apiPayload
        }));
        setPgmVisibility(actionPgm);
        setPatientName(each?.patientNameFirst + ', ' + each?.patientNameLast);
        setPrescPatientFilterId({
            productCountryId: each?.productCountryId,
            nonPrescriberPatientFilterId: isNonPrescriber ? (each?.nonPrescPatientFilterId) : null,
            prescPatientFilterId: isNonPrescriber ? null : (each?.prescPatientFilterId),
        });
        setShowHideUnhideModal(true);
    }


    const handleExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('HiddenPatientReport');

        sheet.getRow(1).font = {
            name: 'Arial',
            family: 4,
            size: 10,
            bold: true,
            color: { argb: '#000' },
        };
        sheet.columns = [
            {
                header: 'Patient Name',
                key: 'PatientName',
                width: 20,
            },
            {
                header: 'REMS Program',
                key: 'REMSProgram',
                width: 15,
            },
            {
                header: 'Prescriber Name',
                key: 'PrescName',
                width: 20,
            },
        ];

        content?.map((detail) => {
            sheet.addRow({
                PatientName: (detail?.patientNameLast + ' ' + detail?.patientNameFirst)?.toUpperCase(),
                REMSProgram: detail?.productName,
                PrescName: detail?.assocPrescriberNameLast + ' ' + detail?.assocPrescriberNameFirst,
            });
        });
        for (let i = 1; i < 5; i++) {
            sheet.insertRow(i, { PatientName: '' });
        }
        sheet.getColumn('E').alignment = { horizontal: 'left' };
        // add a table to a sheet
        sheet.addTable({
            name: 'MyTable',
            ref: 'A1',
            style: {
                showRowStripes: false,
                showFirstColumn: true,
            },
            columns: [
                { name: ' Hidden Patient' },
                { name: 'Report' },
            ],
            rows: [
                ['REMS Program(s)', reportInpData.programs.join(', ')],
                ['Prescribers', reportInpData.prescribers.join(', ')],
            ],

        });
        sheet.getCell('A1').font = {
            name: 'Arial',
            family: 4,
            size: 14,
            color: { argb: 'FF0000FF' },
            bold: true,
        };
        sheet.getCell('B1').font = {
            name: 'Arial',
            family: 4,
            size: 14,
            color: { argb: 'FF0000FF' },
            bold: true,
        };
        sheet.getCell('A1').fill = {
            type: 'pattern',
            pattern: 'none',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' },
        };
        sheet.getCell('B1').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFFFF' },
            bgColor: { argb: 'FFFFFFFF' },
        };
        sheet.getCell('C1').value = moment().format('MMM DD, YYYY');
        const row = sheet.getRow(1);
        row.height = 30;
        row.alignment = { vertical: 'middle' };
        sheet.getCell('C1').font = {
            bold: true,
        };
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'REMSPortal.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const noDataMsg = noData && isNonPrescriber ? 'No Records Available.' : 'No Records Found.';


    if (
        (!content?.length && isApiCalled) ||
        (!content?.length && noData && isNonPrescriber)
    ) {
        return (
            <table width="100%" cellPadding="0" cellSpacing="0" border={0} className={`${classes.reportTable} ${classes.emptyTable}`}>
                <tbody>
                    <tr className="odd">
                        <td className={classes.noRecordsFound} align="center" colSpan={8}>
                            {noDataMsg}
                        </td>
                    </tr>
                </tbody>
            </table>
        );

    } else if (isApiCalled && content && content?.length > 0) {
        return <>
            <div className={classes.popupDiv}>
                {hideUnhideModal && (
                    <PopupHideUnhide
                        pName={getPatientName}
                        setShowHideUnhideModal={setShowHideUnhideModal}
                        pgmVisibility={pgmVisibility}
                        setPgmVisibility={setPgmVisibility}
                        getAPIDetails={[prescPatientFilterId]}
                        setFetchReport={setFetchReport}
                    />
                )}
            </div>
            <div className={classes.reportsTableContainer}>
                <button onClick={handleExcel} className={classes.exprttoExlBtn}>
                    <img src={ExportToExcel} alt="export to Excel" />
                    <p>Export to Excel</p>
                </button>
                <div className={classes.contentReportsTable}>
                    <table width="100%" cellPadding="0" cellSpacing="0" border={0} className={classes.reportTable}>
                        <tbody>
                            <tr>
                                {tableheader?.map((title, index) => {
                                    return <th style={{ padding: '8px' }} >
                                        <div className={classes.thContainer} key={index}>

                                            <label>{title}</label>

                                            {title !== 'Unhide' && (
                                                <div className={classes.sortControl}>
                                                    <a onClick={() => sortByAscDesc(title, 'ASC')}>
                                                        <span className={`${classes.icon} ${classes.upArrow}`}></span>
                                                    </a>
                                                    &nbsp;
                                                    <a onClick={() => sortByAscDesc(title, 'DESC')}>
                                                        <span className={`${classes.icon} ${classes.downArrow}`}></span>
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                })}
                            </tr>
                            <tr></tr>

                            {content?.map((each) => {
                                return (
                                    <tr key={each?.rnum} className={classes.patientDetails}>
                                        <td colSpan={1}>
                                            <Button
                                                clickFunction={() => { handleClickUnhide(each) }}
                                                className='buttonStyles'
                                                type="text"
                                                width={65}
                                                height={30}
                                                size={12}
                                                buttonname="Unhide"
                                            />
                                        </td>
                                        <td colSpan={1}>
                                            <a onClick={() => selectedPatientHandler(each)}>
                                                <span> {each?.patientNameLast && each?.patientNameFirst ? each?.patientNameLast + ', ' + each?.patientNameFirst : ''}</span>
                                                <img src={rightArrow} alt="right-arrow" />
                                            </a>
                                        </td>
                                        <td colSpan={1}>{each?.productName && each?.productName}</td>
                                        <td colSpan={1}>
                                            {each?.assocPrescriberNameFirst && each?.assocPrescriberNameLast ? each?.assocPrescriberNameLast + ' ' + each?.assocPrescriberNameFirst : ''}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }
    else <> </>
};

export default HiddenPatientTable;